import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Icon, H3 } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import classes from "./StickyHeader.module.scss";
import SocialMedia from "../../SocialMedia/SocialMedia";
import theme from "../../../../configs/theme";
import ApplyBtn from "../../SingleMissionBody/LeftSlide/ApplyBtn";

@inject("sessionStore")
@observer
class StickyHeader extends Component {
  render() {
    const {
      id,
      title,
      place,
      businessSectors,
      i18n,
      loading,
      disable
    } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <H3 className={classes.title}>{title}</H3>
          <div className={classes.info}>
            <div className={classes.infoItem}>
              <Icon
                type="location"
                width={14}
                height={16}
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
              {place}
            </div>
            <div className={classes.infoItem}>
              <Icon
                type="briefcase"
                width={19}
                height={16}
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
              {businessSectors.join(" ")}
            </div>
            <div className={classes.social}>
              <SocialMedia id={id} />
            </div>
          </div>
          <ApplyBtn loading={loading} id={id} disable={disable} style={100} />
        </div>
      </div>
    );
  }
}

StickyHeader.wrappedComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  place: PropTypes.string,
  businessSectors: PropTypes.arrayOf(PropTypes.string),
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    setBackRef: PropTypes.func,
    authToken: PropTypes.string,
    jobOrderReferer: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

StickyHeader.defaultProps = {
  title: "",
  place: "",
  businessSectors: []
};

export default withI18n()(withRouter(StickyHeader));
