import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { t, Trans } from "@lingui/macro";
import { Button, Col, H2, Row } from "cf-neo-ui";
import domaineExpertise from "../../../configs/domaineExpertise";

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconColor1: "black",
      iconColor2: "white"
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { history, missionsSearchStore, i18n } = this.props;
    const { iconColor1, iconColor2 } = this.state;
    const { changeEmploymentType, addCategory } = missionsSearchStore;

    return (
      <div className={classes.section3}>
        <H2 className={classes.mainText}>
          <Trans>
            Des projets à la hauteur de votre expertise, sans effort de
            recherche
          </Trans>
        </H2>

        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigCol}
        >
          <Col xl={7} lg={7} md={12} sm={12} xs={6}>
            <div className={classes.textInside}>
              <Trans>
                Intéressé(e) par des offres en,{" "}
                <strong>
                  {" "}
                  Direction Financière externalisé, Analyse financière,{" "}
                </strong>
                <br />
                <strong>
                  Management de Transition, Comptabilité, Contrôle de gestion,
                  Consulting, AMOA,
                </strong>
                <br />
                nous vous présentons des projets sélectionnés pour votre
                expertise près de chez vous.
              </Trans>
            </div>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={6}
              className={classes.blockButton}
            >
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={6}
                className={classes.section3}
              >
                <Button
                  size="medium"
                  icon="chevron-right"
                  iconTop="calc(50% - 10.5px)"
                  iconPosition="right"
                  iconColor={iconColor1}
                  variant="quaternary"
                  onMouseEnter={() => this.setState({ iconColor1: "white" })}
                  onMouseLeave={() => this.setState({ iconColor1: "black" })}
                  classNameText={
                    "min-width: 262px; background: white; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid white;&:hover { background: linear-gradient(43deg, #202123 0%, #3D3F42 100%); color: white;}; > span { margin: 0px;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                  }
                  onClick={() => {
                    history.push(
                      "/missions?categories=Finance&employmentType=Contract"
                    );
                    changeEmploymentType("Contract");
                    addCategory(domaineExpertise(i18n, "Finance", "key"));
                  }}
                >
                  <Trans>Mission Freelance Finance</Trans>
                </Button>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={6}
                className={classes.section3}
              >
                <Button
                  size="medium"
                  icon="chevron-right"
                  iconTop="calc(50% - 10.5px)"
                  iconPosition="right"
                  iconColor={iconColor2}
                  onMouseEnter={() => this.setState({ iconColor2: "black" })}
                  onMouseLeave={() => this.setState({ iconColor2: "white" })}
                  variant="quaternary"
                  classNameText={
                    "min-width: 240px; background: linear-gradient(43deg, #202123 0%, #3D3F42 100%); background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px;border: 2px solid white;&:hover { background: white; color: black;}; > span { margin: 0px;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                  }
                  onClick={() => {
                    history.push(
                      "/missions?categories=Finance&employmentType=Permanent"
                    );
                    changeEmploymentType("Permanent");
                    addCategory(domaineExpertise(i18n, "Finance", "key"));
                  }}
                >
                  <Trans>Emploi Finance en CDI</Trans>
                </Button>
              </Col>
            </Col>
          </Col>

          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            xs={6}
            className={classes.secImag2}
          >
            <picture>
              <source type="images/png" srcSet="/assets/images/rect.png" />
              <img
                className={classes.projectsImgSec2}
                src="/assets/images/rect.png"
                alt="illustration_graphique_finance"
              />
            </picture>
          </Col>
        </Col>
      </div>
    );
  }
}

Section2.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section2));
