import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Button, Col, H1, H2, Icon, Row, Textarea } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";

const data = [
  {
    category: "Freelance Finance",
    titles: [
      {
        text: <Trans>Retrouvez les offres recommandées pour vous</Trans>
      },
      {
        text: <Trans>Postulez en un clic et suivre votre candidature</Trans>
      },
      {
        text: <Trans>Créez un CV partageable</Trans>
      },
      {
        text: <Trans>Gérez vos Feuilles de temps</Trans>
      },
      {
        text: <Trans>Retrouvez vos contrats</Trans>
      }
    ],
    imageIndex: 0,
    images: [
      {
        url: "/assets/images/freelance_finance_mobile.png"
      }
    ]
  },
  {
    category: <Trans>Salariés Finance</Trans>,
    titles: [
      {
        text: <Trans>Créez un CV partageable</Trans>
      },
      {
        text: <Trans>Retrouvez les offres recommandées pour vous</Trans>
      },
      {
        text: <Trans>Postulez en un clic et suivre votre candidature</Trans>
      },
      {
        text: <Trans>Bénéficiez de contenus et avantages exclusifs</Trans>
      }
    ],
    imageIndex: 1,
    images: [
      {
        url: "/assets/images/salarié_finance.png"
      }
    ]
  }
];

@inject("sessionStore", "appStore", "modalStore", "postANeedStore")
@observer
class Section6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
  }
  componentDidMount() {
    //window.scroll(0, 0);
  }
  handleClick(index) {
    this.setState({
      index: index
    });
  }
  textChangeHandler(text) {
    return text;
  }
  render() {
    const { history } = this.props;
    return (
      <div className={classes.mainDiv} style={{ paddingTop: "5%" }}>
        <H2 className={classes.mainText}>
          <Trans>Profitez de notre plateforme de Talent Management</Trans>
        </H2>
        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={6}
            className={classes.bigColSec6}
          >
            <Col
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={6}
              className={classes.listSec3}
            >
              {data.map((item, index) => {
                return (
                  <div
                    className={classes.liCateg}
                    style={{
                      fontWeight: index == this.state.index ? "bold" : "normal",
                      color: index == this.state.index ? "white" : "grey"
                    }}
                  >
                    <h3
                      className={classes.itemCategory}
                      onClick={() => this.handleClick(index)}
                    >
                      <Trans>{item.category} </Trans>
                    </h3>
                  </div>
                );
              })}
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className={classes.section6}
            >
              {data[this.state.index].titles.map((title, indexImage) => {
                return (
                  <div className={classes.categoryTiltes}>
                    <div className={classes.categoryTiltes2}>
                      <div className={classes.iconPadding}>
                        <Icon type="lock" size="large" filled />
                      </div>
                      <div>
                        <p>
                          {" "}
                          <Trans>{title.text}</Trans>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              {data[this.state.index].images.map((image, indexImage) => {
                return <img src={image.url} />;
              })}
            </Col>
          </Col>
        </Row>

        <Col
          xl={8}
          lg={8}
          md={12}
          sm={8}
          xs={6}
          className={classes.sectionButton}
        >
          <Button
            size="medium"
            variant="quaternary"
            classNameText={
              "height:60px; background: linear-gradient(76deg, #8d0417,#d3354a);background-size: 101% 101%; color: #ffffff;> span { margin: 0px;;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
            }
            className={classes.buttonToMindquestPlateformClient}
            onClick={() => {
              history.push("/signup?type=consultant");
            }}
          >
            <Trans>Découvrir la plateforme Mindquest pour les talents</Trans>
          </Button>
        </Col>
      </div>
    );
  }
}
Section6.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section6));
