import { t } from "@lingui/macro";
// import monthName from "../../../../../utils/monthName";

const clientNotisMessages = (i18n, month, year, placementID) => {
  const m = month.toString();
  const y = year.toString();
  const pID = placementID.toString();
  return {
    OPEN: {
      title: i18n._(t`Timesheet Ouvert`),
      message: i18n._(
        t`Le Timesheet ${m}/${y} de contrat numero: ${pID} est ouvert au dashboard de votre consultant`
      ),
      buttonLabel: i18n._(t`Consulter le timesheet`)
    },
    SUBMITTED: {
      title: i18n._(t`Timesheet Soumis`),
      message: i18n._(
        t`Le Timesheet du mois de ${m}/${y} pour le contrat ${pID} attend votre validation`
      ),
      buttonLabel: i18n._(t`Consulter le timesheet`)
    },
    REPORT_GENERATED: {
      title: i18n._(t`Compte-Rendu Généré`),
      message: i18n._(
        t`Suite à la validation du Timesheet, le compte-rendu de mois ${m}/${y} pour le contrat ${pID} est disponible`
      ),
      buttonLabel: i18n._(t`Consulter le compte-rendu`)
    },
    REMINDER: {
      title: i18n._(t`Rappel`),
      message: i18n._(
        t`Pensez à remplir votre Timesheet du contrat numéro: ${pID} de ${m}/${y}`
      ),
      buttonLabel: i18n._(t`Consulter le timesheet`)
    }
  };
};

export default clientNotisMessages;
