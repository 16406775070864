import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link, Prompt, withRouter } from "react-router-dom";
import { Button, Col, IconCircle, Row, SegmentedControl } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import runtimeVars from "../../../configs/runTimeVars";
import Routes from "../../layout/routes";
import MonthVue from "../components/MonthVue";
import ThemeVue from "../components/ThemeVue";
import TypeVue from "../components/TypeVue";
// import classes from "../../timesheet-single-page/conclusion-section/ConclusionSection.module.scss";
import AddThemePopUp from "../pop-ups/AddThemePopUp";
import MoreInformationPopUp from "../pop-ups/MoreInformationPopUp";
import DeleteRangePopUp from "../pop-ups/DeleteRangePopUp";
import AddTimeTrackedPopUp from "../pop-ups/AddTimeManuallyPopUp";
import DeleteThemePopUp from "../pop-ups/DeleteThemePopUp";
import LimitOfThemesPopUp from "../pop-ups/LimitOfThemesPopUp";
import DayDetailsPopUp from "../pop-ups/DayDetailsPopUp";
import { UPDATE_TIMESHEET } from "../mutations";
import { Mutation } from "react-apollo";
import publicIp from "public-ip";
import ValidateTimesheetPopUp from "../pop-ups/ValidateTimesheetPopUp";
import RejectTimesheetPopUp from "../pop-ups/RejectTimesheetPopUp";
import TimesheetValidatedSuccessfullyPopUp from "../pop-ups/TimesheetValidatedSuccessfullyPopUp";
import TimesheetRejectedSuccessfullyPopUp from "../pop-ups/TimesheetRejectedSuccessfullyPopUp";
import TimesheetSentSuccessfullyPopUp from "../pop-ups/TimesheetSentSuccessfullyPopUp";
import TimesheetSavedSuccessfullyPopUp from "../pop-ups/TimesheetSavedSuccessfullyPopUp";
import MessageSentSuccessfullyPopUp from "../pop-ups/MessageSentSuccessfullyPopUp";
import SendCandidateTimesheetPopUp from "../pop-ups/SendCandidateTimesheetPopUp";
import AddMessagePopUp from "../pop-ups/AddMessagePopUp";
import AlertHolidayPopUp from "../pop-ups/AlertHolidayPopUp";
import AlertHolidayToConfirmPopUp from "../pop-ups/AlertHolidayToConfirmPopUp";
import HeaderForDesktop from "../components/HeaderForDesktop";
import { TimesheetChat } from "cf-neo-ui";
@inject("newTimesheetStore", "sessionStore")
@observer
class Mobile extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    moment.locale(runtimeVars.APP_LANG);
    this.state = {
      ...initProps,
      tab: props.tab || 1,
      displayMessage: false
    };
    publicIp
      .v4()
      .then(res => {
        this.ip = res;
      })
      .catch(() => {});
  }

  componentDidMount() {
    window.onbeforeunload = this.handleBeforeUnload;
  }
  componentWillUnmount() {
    window.onbeforeunload = null;
  }
  handleBeforeUnload = e => {
    const { newTimesheetStore } = this.props;
    const { isEdited } = newTimesheetStore;
    if (isEdited) {
      const message = i18n._(
        t`Les modifications que vous avez apportées ne seront peut-être pas enregistrées.`
      );
      e.returnValue = message;
      return message;
    }
  };

  isTimesheetValid = () => {
    return true;
  };

  onChangeSegmentedControl = () => {
    this.setState({ tab: SegmentedControl.getSelectedTab() });
  };
  TranslateConversationDetails(conversation) {
    const { i18n } = this.props;
    const types = {
      comment: i18n._(t`Commentaire`),
      purchaseOrder: i18n._(t`Numéro de bon de commande`),
      tasks: i18n._(t`Tâches`),
      projects: i18n._(t`Projet`)
    };
    for (let i = 0; i < conversation.length; i++) {
      const obj = conversation[i];
      const objDetails = obj.details;
      for (let j = 0; j < objDetails.length; j++) {
        const detail = objDetails[j];
        if (detail.type in types) {
          detail.field = types[detail.type];
        }
      }
    }
    return conversation;
  }
  render() {
    const { tab } = this.state;

    const { newTimesheetStore, sessionStore, i18n } = this.props;
    const {
      openClosePopUps,
      themes,
      timesheetPopUps,
      timesheet,
      workPlan,
      canSave,
      notReady,
      editable,
      conversation,
      isEdited
    } = newTimesheetStore;
    const { account } = sessionStore;

    const {
      addTimeManually,
      addTheme,
      moreInformation,
      addMessage,
      deleteRange,
      deleteTheme,
      limitOfThemes,
      dayDetails,
      sendCandidateTimesheet,
      rejectTimesheet,
      validateTimesheet,
      timesheetValidatedSuccessfully,
      timesheetRejectedSuccessfully,
      timesheetSentSuccessfully,
      timesheetSavedSuccessfully,
      messageSentSuccessfully,
      alertHoliday,
      alertHolidayToConfirm
    } = timesheetPopUps;

    return (
      <div>
        <Prompt
          when={isEdited}
          message={(location, action) => {
            return i18n._(
              t`Les modifications que vous avez apportées ne seront peut-être pas enregistrées.`
            );
          }}
        />
        <HeaderForDesktop
          device="mobile"
          displayChat={e => {
            this.setState({ displayMessage: e });
          }}
        />
        {this.state.displayMessage && (
          <TimesheetChat
            style={{ position: "relative" }}
            width="100%"
            right="0px"
            messages={this.TranslateConversationDetails(conversation)}
            send={v => console.log(v)}
            unlimitedChat={true}
            getComment={v => console.log(v)}
            disabled={false}
            withInputSection={false}
            withHeader={true}
          />
        )}
        {!this.state.displayMessage && (
          <>
            {account.role == "CANDIDATE" && editable("Candidate") && (
              <div
                style={{ margin: "10px auto 20px auto", textAlign: "center" }}
              >
                <IconCircle
                  type="plus"
                  dimension={42}
                  innerColor="#FFFFFF"
                  innerColor2="#FFFFFF"
                  color="#8D0417"
                  color2="#D3354A"
                  iconWidth={24}
                  iconHeight={24}
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => {
                    const { newTimesheetStore } = this.props;
                    const { openClosePopUps } = newTimesheetStore;
                    openClosePopUps("addTimeManually");
                  }}
                />
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#242424",
                    textAlign: "center",
                    marginTop: "12px"
                  }}
                >
                  <Trans>Ajouter des heures</Trans>
                </div>
              </div>
            )}

            <SegmentedControl
              defaultTab={1}
              onClick={this.onChangeSegmentedControl}
              style={{ marginBottom: "50px", marginTop: "10px" }}
              SegmentText1={i18n._(t`Vue mois`)}
              SegmentText2={i18n._(t`Vue projet`)}
              SegmentText3={i18n._(t`Vue type`)}
            />
            <div style={{ paddingBottom: "130px" }}>
              {tab === 1 && <MonthVue />}
              {tab === 2 && <ThemeVue key={themes.length} device="mobile" />}
              {tab === 3 && <TypeVue />}
            </div>

            <>
              {account.role === "CLIENT" ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: "44px",
                    left: "16px",
                    right: "16px",
                    zIndex: 10
                  }}
                >
                  <Row>
                    <Col>
                      <Button
                        variant="secondary"
                        onClick={() => openClosePopUps("rejectTimesheet")}
                        disabled={["open", "rejected", "approved"].includes(
                          timesheet.status
                        )}
                      >
                        <Trans>Rejeter</Trans>
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        onClick={() => openClosePopUps("validateTimesheet")}
                        disabled={
                          ["open", "rejected", "approved"].includes(
                            timesheet.status
                          ) || notReady
                        }
                      >
                        <Trans>Valider</Trans>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    bottom: "44px",
                    left: "16px",
                    right: "16px",
                    zIndex: 10
                  }}
                >
                  <Row>
                    <Col>
                      <Mutation
                        mutation={UPDATE_TIMESHEET}
                        variables={{
                          id: timesheet.id,
                          action: "draft",
                          ip: this.ip,
                          workPlan,
                          themes
                        }}
                        onError={errors => {
                          errors.graphQLErrors.forEach(({ message, data }) => {
                            if (message === "UNAUTHENTICATED") {
                              logout();
                              clearSearch();
                            }
                            if (data && data.isCustomError) {
                              this.onErrorHandler(message);
                            }
                          });
                        }}
                      >
                        {(mutation, { loading }) => (
                          <Button
                            // className={classes.action}
                            variant="secondary"
                            disabled={
                              loading ||
                              ["pending", "approved"].includes(
                                timesheet.status
                              ) ||
                              !canSave
                            }
                            onClick={() => {
                              if (this.isTimesheetValid()) {
                                return mutation();
                              }
                              return null;
                            }}
                          >
                            <Trans>Enregistrer</Trans>
                          </Button>
                        )}
                      </Mutation>
                    </Col>
                    <Col>
                      <Button
                        // className={classes.action}
                        variant="primary"
                        disabled={
                          ["pending", "approved"].includes(timesheet.status) ||
                          !canSave
                        }
                        onClick={() =>
                          openClosePopUps("sendCandidateTimesheet")
                        }
                      >
                        <Trans>Envoyer</Trans>
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </>

            <div
              style={{
                background: "#F2F2F2",
                mixBlendMode: "normal",
                bottom: "0",
                height: "130px",
                left: "0",
                position: "absolute",
                right: "0",
                clipPath: "polygon(0 40%, 100% 0, 100% 100%, 0% 100%)"
              }}
            />
            {moreInformation && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <MoreInformationPopUp device="mobile" />
              </div>
            )}
            {addMessage && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <AddMessagePopUp device="mobile" />
              </div>
            )}
            {deleteRange && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <DeleteRangePopUp device="mobile" />
              </div>
            )}
            {addTimeManually && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <AddTimeTrackedPopUp device="mobile" />
              </div>
            )}
            {addTheme && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <AddThemePopUp device="mobile" />
              </div>
            )}
            {deleteTheme && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <DeleteThemePopUp device="mobile" />
              </div>
            )}
            {limitOfThemes && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <LimitOfThemesPopUp device="mobile" />
              </div>
            )}
            {dayDetails && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <DayDetailsPopUp device="mobile" />
              </div>
            )}
            {sendCandidateTimesheet && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)",
                  overflow: "scroll"
                }}
              >
                <SendCandidateTimesheetPopUp device="mobile" />
              </div>
            )}
            {rejectTimesheet && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <RejectTimesheetPopUp device="mobile" />
              </div>
            )}
            {validateTimesheet && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <ValidateTimesheetPopUp device="mobile" />
              </div>
            )}
            {timesheetValidatedSuccessfully && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <TimesheetValidatedSuccessfullyPopUp device="mobile" />
              </div>
            )}
            {timesheetRejectedSuccessfully && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <TimesheetRejectedSuccessfullyPopUp device="mobile" />
              </div>
            )}
            {timesheetSentSuccessfully && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <TimesheetSentSuccessfullyPopUp device="mobile" />
              </div>
            )}
            {alertHoliday && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <AlertHolidayPopUp device="mobile" />
              </div>
            )}
            {alertHolidayToConfirm && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <AlertHolidayToConfirmPopUp device="mobile" />
              </div>
            )}
            {timesheetSavedSuccessfully && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <TimesheetSavedSuccessfullyPopUp device="mobile" />
              </div>
            )}
            {messageSentSuccessfully && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                  left: 0,
                  zIndex: 99999,
                  boxShadow: "0px -4px 4px rgba(136, 136, 136, 0.25)"
                }}
              >
                <MessageSentSuccessfullyPopUp device="mobile" />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

Mobile.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  newTimesheetStore: PropTypes.shape({
    timesheetPopUps: PropTypes.shape({
      addTheme: PropTypes.string,
      addTimeManually: PropTypes.string,
      moreInformation: PropTypes.string,
      addMessage: PropTypes.string,
      deleteRange: PropTypes.string,
      deleteTheme: PropTypes.string,
      limitOfThemes: PropTypes.string,
      dayDetails: PropTypes.string,
      sendTimesheet: PropTypes.string,
      rejectTimesheet: PropTypes.string,
      validateTimesheet: PropTypes.string,
      timesheetValidatedSuccessfully: PropTypes.string,
      timesheetRejectedSuccessfully: PropTypes.string,
      timesheetSentSuccessfully: PropTypes.string,
      timesheetSavedSuccessfully: PropTypes.string,
      messageSentSuccessfully: PropTypes.string
    }),
    canSave: PropTypes.bool,
    notReady: PropTypes.bool,
    openClosePopUps: PropTypes.func,
    editable: PropTypes.func,
    themes: PropTypes.shape({}),
    workPlan: PropTypes.shape({}),
    normalHours: PropTypes.number,
    specialHours: PropTypes.number,
    totalHours: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    timesheetPopUp: PropTypes.number,
    changeIsVisible2: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({ width: PropTypes.number }).isRequired
};

export default withI18n()(withRouter(Mobile));
