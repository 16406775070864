import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Mutation } from "react-apollo";
import {
  Icon,
  FormCard,
  Button,
  Row,
  Col,
  Container,
  ImportFile,
  FormGroup,
  Validation,
  TextInput,
  Spinner,
  toaster
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import "./styles.scss";
import validate from "../../utils/validators";
import { CREATE_OPPORTUNITY, CREATE_OPPORTUNITY_FROM_FILE } from "./mutations";

@inject("appStore", "postANeedStore")
@observer
class myNeed extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.submit = this.submit.bind(this);
    this.state = {
      ...initProps,
      isJobTitleValid: true,
      jobTitleValidationMessage: "",
      isValid: false
    };
  }

  jobTitleChangeHandler = e => {
    const { postANeedStore } = this.props;
    const { jobDescriptionFile } = postANeedStore;
    postANeedStore.changeJobTitle(e.target.value);
    const valid = this.validateJobTitle(e.target.value);
    if (valid && jobDescriptionFile.name) this.setState({ isValid: true });
    else this.setState({ isValid: false });
  };

  validateJobTitle(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isJobTitleValid: res.isValid,
      jobTitleValidationMessage: res.message
    });
    return res.isValid;
  }

  submit() {
    const { history } = this.props;
    history.push(Routes.HbLayoutRoutes.ConfirmPostANeed.path);
  }

  handleUpload(fileUploaded) {
    const { postANeedStore } = this.props;
    postANeedStore.changeJobDescriptionFile(fileUploaded);
    const valid = this.validateJobTitle(postANeedStore.jobTitle);
    if (valid && postANeedStore.jobDescriptionFile.name)
      this.setState({ isValid: true });
    else this.setState({ isValid: false });
  }

  render() {
    const { i18n, postANeedStore } = this.props;
    const { jobDescriptionFile, jobTitle } = postANeedStore;
    const { isJobTitleValid, jobTitleValidationMessage, isValid } = this.state;

    return (
      <div className="session signup-profile">
        <br />
        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Mon besoin</Trans>
            </h1>
          </Col>
        </Row>
        <br />
        <FormCard className="formCard no-gutter">
          <Container>
            <Row>
              <Col className="center form-card-content">
                <div
                  className="timer center"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <Icon
                    type="download"
                    size="tiny"
                    color="#FA324A"
                    color2="#BF142A"
                    filled={false}
                  />
                  <br />
                </div>
                <div className="center-txt text-bold">
                  <Trans>Uploader ma fiche de poste</Trans>
                  <br />
                  <div className="text-gray">
                    <Trans>Un responsable de compte vous recontactera</Trans>
                  </div>
                  <br />
                </div>
                <div className="center" style={{ width: "100%" }}>
                  <div className="center" style={{ width: "100%" }}>
                    <FormGroup>
                      <label className="form-label">
                        <Trans>Intitulé</Trans>
                        {/* <span className="asterisc">*</span> */}
                      </label>
                      <Validation
                        errorMessage={jobTitleValidationMessage}
                        valid={isJobTitleValid}
                      >
                        <TextInput
                          className="form-input"
                          placeholder={i18n._(
                            t`Ex:Architecte Java, Consultant SAP`
                          )}
                          iconColor="#D3354A"
                          value={jobTitle}
                          onChange={this.jobTitleChangeHandler}
                        />
                      </Validation>
                    </FormGroup>
                  </div>
                  <br />
                  <ImportFile
                    defaultAvatar="/defaultAvatar.webp"
                    fileName={jobDescriptionFile.name}
                    onChange={(e, fileUploaded) =>
                      this.handleUpload(e, fileUploaded)
                    }
                    accept=".doc,.docx,.pdf"
                    withDropFile
                    dropFileProps={{
                      text: i18n._(t`...ou deposer votre fiche de poste ici`),
                      style: { height: "200px" },
                      onFilesAccepted: files => this.handleUpload(files[0])
                    }}
                    maxSize={1e7}
                    message1={i18n._(
                      t`Ce format de fichier n’est pas pris en charge. Veuillez choisir l’une des options suivantes :`
                    )}
                    message2={i18n._(
                      t`Nous vous recommandons fortement d’utiliser le format Word.`
                    )}
                    message3={`${i18n._(
                      t`Taille maximale du fichier à télécharger`
                    )} `}
                    message4={`${i18n._(
                      t`La taille du fichier est trop petite, veuillez sélectionner un fichier de taille supérieur à`
                    )} `}
                    textButton={`${i18n._(t`Fermer`)} `}
                  >
                    <Trans>Importer mon fichier</Trans>
                  </ImportFile>
                </div>

                <br />
              </Col>
            </Row>
          </Container>
        </FormCard>
        <br />
        <div className="next-buttons">
          <Mutation
            mutation={CREATE_OPPORTUNITY_FROM_FILE}
            variables={{ title: jobTitle, file: jobDescriptionFile }}
            onCompleted={this.submit}
            onError={() =>
              toaster.error({
                title: i18n._(t`Erreur de création de besoin`),
                description: i18n._(
                  t`Un problème est survenu lors de la création de besoin`
                )
              })
            }
          >
            {(mutation, { loading }) => (
              <Button
                icon="chevron-right"
                disabled={!isValid || loading}
                onClick={mutation}
              >
                {loading ? (
                  <Spinner type="pointed-circular" color="#FFFFFF" size={12} />
                ) : (
                  <Trans>soumettre</Trans>
                )}
              </Button>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

myNeed.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  postANeedStore: PropTypes.shape({
    changeJobDescriptionFile: PropTypes.func,
    jobDescriptionFile: PropTypes.object,
    changeJobTitle: PropTypes.func,
    jobTitle: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(myNeed));
