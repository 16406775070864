import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Trans } from "@lingui/macro";
import { Button, Col, H2, H3, H4 } from "cf-neo-ui";

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section7 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { i18n, history } = this.props;
    const { key } = this.state;

    const active =
      typeof window !== "undefined" &&
      window.location.search.substring(
        window.location.search.lastIndexOf("?") + 1
      );
    return (
      <div>
        <H2 className={classes.mainTextSec5}>
          <Trans>Nos domaines d’expertise et communautés</Trans>
        </H2>
        {/* images part */}
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.bigColSec7Consultant}
        >
          {/* first 2 images */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/grouppresentation.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/grouppresentation.png"
                  alt="illustration_Strategie_Management"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Stratégie et management</Trans>
              </H3>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/smartphone.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/smartphone.png"
                  alt="illustration_Digital_performances"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Digital & IT Finance</Trans>
              </H3>
            </Col>
          </Col>
          {/* second 2 images */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/problemsolving.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/problemsolving.png"
                  alt="illustration_transformation_organisation"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Conseil & Audit</Trans>
              </H3>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/financial.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/financial.png"
                  alt="illustration_Risque_Finance_règlementaire"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Comptabilité & Finance</Trans>
              </H3>
            </Col>
          </Col>
        </Col>

        {/* first block */}
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigCol}
          style={{ paddingBottom: "0%" }}
        >
          {/* the 2 first box in first block */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>
                    {" "}
                    Direction <br /> financière{" "}
                  </Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    {" "}
                    <Trans> Directeur financier </Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    <Trans>CFO</Trans>
                  </strong>

                  <br />
                  <strong>
                    <Trans> Chief Financial Officer</Trans>
                  </strong>

                  <br />
                  <strong>
                    <Trans> DAF externalisé </Trans>
                  </strong>

                  <br />
                  <strong>
                    {" "}
                    <Trans>RAF externalisé </Trans>
                  </strong>

                  <br />
                  <strong>
                    {" "}
                    <Trans>DAF à temps partagé </Trans>{" "}
                  </strong>

                  <br />
                  <strong>
                    <Trans>RAF à temps partagé</Trans>
                  </strong>
                </span>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  {" "}
                  <Trans>Comptabilité</Trans>{" "}
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    <Trans>Comptable Freelance</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>Expert Comptable indépendant</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>Consolideur indépendant</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>Trésorier</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>Cash manager</Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    <Trans>Directeur comptable</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>Directeur consolidation</Trans>
                  </strong>
                </span>
              </div>
            </Col>
          </Col>
          {/* the 2 second box in first block */}

          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>
                    {" "}
                    Contrôle de <br /> gestion{" "}
                  </Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    <Trans>Contrôleur de gestion Freelance</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>Contrôle de gestion IT</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>Contrôleur de Gestion</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans>FP&A</Trans>
                  </strong>
                </span>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>Audit Risque</Trans> <br />
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    <Trans> Actuaire</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    <Trans> Auditeur interne </Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Auditeur financier </Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Analyst crédit</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Risk Manager </Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Compliance Officer </Trans>
                  </strong>
                </span>
              </div>
            </Col>
          </Col>
        </Col>

        {/* Second block */}
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigCol}
          style={{ paddingTop: "0%" }}
        >
          {/* the 2 first box in second block */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>
                    Corporate <br /> finance
                  </Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    <Trans>Analyst financier</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> FP&A Manager</Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>Analyst M&A</Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans> Responsable communicaiton financière </Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>IFRS specialist </Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans> Consultant Restructuring </Trans>
                  </strong>
                  <br />
                </span>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  {" "}
                  <Trans>Paie</Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    <Trans>Gestionnaire de Paie</Trans>{" "}
                  </strong>
                  <br />
                  GP
                  <br />
                  <strong>
                    {" "}
                    <Trans> Payroll manager </Trans>
                  </strong>
                </span>
              </div>
            </Col>
          </Col>

          <Col
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={6}
            className={classes.widgetBodyLastBox}
          >
            <div className={classes.box}>
              <H4 className={classes.boxFirst}>
                <Trans>
                  IT <br /> Finance{" "}
                </Trans>
              </H4>
              <span className={classes.boxSecond}>
                <strong>
                  {" "}
                  <Trans>Data analyst Finance </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant SAP FI CO </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Data Scientist </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Chef de projet Finance / IT </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant AMAO SAP </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant Cegid </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant SAGE </Trans>
                </strong>
              </span>
            </div>
          </Col>
        </Col>

        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigColSec7}
        >
          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
            <Button
              size="medium"
              variant="quaternary"
              classNameText={
                "background: #ffffff; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid #979797;> span { margin: 0px;;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
              }
              onClick={() => history.push("/signup?type=enterprise")}
            >
              <Trans>Recruter un talent CDI ou freelance</Trans>
            </Button>
          </Col>
        </Col>
      </div>
    );
  }
}

Section7.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section7));
