import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  HeaderV2,
  ItemMenu,
  MenuDashBoard,
  MenuDashBoardV2,
  Select,
  SwitchButton3
} from "cf-neo-ui";
import ResponsiveHeaderV2 from "cf-neo-ui/lib/header-v2/src/ResponsiveHeaderV2";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import Routes from "../routes";
import classes from "./styles.module.scss";
import { LOGOUT_MUTATION } from "./mutation";
import menuItems from "../../../configs/menuItems";
import HeaderFr from "./headerFR";
import HeaderEn from "./headerEn";
import { ApolloConsumer } from "react-apollo";

@inject("sessionStore", "appStore", "postANeedStore", "candidatesSearchStore")
@observer
class CFHeader extends Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;

    this.state = {
      canSearch: false,
      lastFirstChoiceOption: "",
      lastSecondChoiceOption: "",
      firstWasUpdated: false,
      secondWasUpdated: false,
      scrolledDistance: 0,
      placeholderText: [
        i18n._(t`Je suis`),
        i18n._(t`IT ou Finance`),
        i18n._(t`Je cherche`),
        i18n._(t`Freelance ou CDI`)
      ],
      options: [
        {
          selectOption: [
            { value: "Candidat", text: i18n._(t`Candidat`) },
            {
              value: "Une entreprise / Un recruteur",
              text: i18n._(t`Une entreprise / Un recruteur`)
            },
            { value: "Un apprenant", text: i18n._(t`Un apprenant`) }
          ],
          placeholder: "Je suis",
          checked: false
        }
      ]
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    const scrolledDistance = window.scrollY;
    this.setState({ scrolledDistance });
  };
  render() {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    const { scrolledDistance } = this.state;

    /* test if we are in the missions/:id */
    if (typeof window !== "undefined") {
      if (
        window.location.pathname.match(/\/missions\/([^/]+)/) ||
        window.location.pathname.match(/\/candidates\/([^/]+)/)
      ) {
        if (scrolledDistance < 447) {
          if (currentLanguage === "fr") {
            return (
              <ApolloConsumer>
                {client => <HeaderFr apolloClient={client} />}
              </ApolloConsumer>
            );
          } else {
            return (
              <ApolloConsumer>
                {client => <HeaderEn apolloClient={client} />}
              </ApolloConsumer>
            );
          }
        } else {
          return null;
        }
      }
    }

    return currentLanguage == "fr" ? (
      <ApolloConsumer>
        {client => <HeaderFr apolloClient={client} />}
      </ApolloConsumer>
    ) : (
      <ApolloConsumer>
        {client => <HeaderEn apolloClient={client} />}
      </ApolloConsumer>
    );
  }
}

CFHeader.wrappedComponent.propTypes = {
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    logout: PropTypes.func,
    account: PropTypes.shape({
      role: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    authToken: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    reset: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number,
    changeLanguage: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  client: PropTypes.shape({
    cache: PropTypes.shape({
      reset: PropTypes.func
    })
  }).isRequired
};

export default withApollo(withI18n()(withRouter(CFHeader)));
