import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Button, Col, H2, Icon, Row, Textarea } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";

const data = [
  {
    category: "Freelance Finance",
    titles: [
      {
        text: (
          <Trans>Postez vos offres et suivez vos recutements en ligne</Trans>
        )
      },
      {
        text: (
          <Trans>Retrouvez les candidats sélectionnés par nos équipes </Trans>
        )
      },
      {
        text: <Trans>Validez les feuilles de timagesemps en un clic</Trans>
      },
      {
        text: <Trans>Créez des viviers de talents</Trans>
      },
      {
        text: <Trans>Retrouvez vos contrats</Trans>
      }
    ],
    imageIndex: 0,
    images: [
      {
        url: "/assets/images/phoneclient.png"
      }
    ]
  },
  {
    category: "Salariés Finance",
    titles: [
      {
        text: <Trans>Créer un CV partageable</Trans>
      },
      {
        text: <Trans>Retrouver les offres recommandées pour vous</Trans>
      },
      {
        text: <Trans>Postulez en un clic et suivre votre candidature</Trans>
      },
      {
        text: <Trans>Bénéficiez de contenus et avantages exclusifs</Trans>
      }
    ],
    imageIndex: 1,
    images: [
      {
        url: "/assets/images/salarié_finance.png"
      }
    ]
  }
];

@inject("sessionStore", "appStore", "modalStore", "postANeedStore")
@observer
class Section6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }
  handleClick(index) {
    this.setState({
      index: index
    });
  }
  textChangeHandler(text) {
    return text;
  }
  render() {
    const { i18n, missionsSearchStore, history } = this.props;
    const active =
      typeof window !== "undefined" &&
      window.location.search.substring(
        window.location.search.lastIndexOf("?") + 1
      );
    return (
      <div className={classes.mainDiv}>
        <div className={classes.mainText}>
          <H2 className={classes.mainText}>
            <Trans>Profitez de notre plateforme de Talent Management</Trans>
          </H2>
        </div>
        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={6}
            className={classes.bigCol}
          >
            <Col xl={8} lg={8} md={8} sm={6} xs={6}>
              {data[this.state.index].titles.map((title, indexImage) => {
                return (
                  <div className={classes.categoryTiltes}>
                    <div className={classes.categoryTiltes2}>
                      <div className={classes.iconPadding}>
                        <Icon type="lock" size="large" filled />
                      </div>
                      <Trans>
                        <p>{title.text}</p>
                      </Trans>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              {data[this.state.index].images.map((image, indexImage) => {
                return <img src={image.url} />;
              })}
            </Col>
          </Col>
        </Row>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <Button
            size="medium"
            variant="quaternary"
            className={classes.buttonToMindquestPlateform6}
            classNameText={
              "height:53px; background: linear-gradient(76deg, #8d0417,#d3354a);background-size: 101% 101%; color: #ffffff;> span { margin: 0px;;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
            }
            onClick={() => {
              history.push("/signup?type=client");
            }}
          >
            <Trans>
              Découvrir la plateforme Mindquest pour les entreprises
            </Trans>
          </Button>
        </Col>
      </div>
    );
  }
}
Section6.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section6));
