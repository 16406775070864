import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { IconCircle, H5 } from "cf-neo-ui";
import { withI18n } from "@lingui/react/index";
import { I18nProvider } from "@lingui/react";
import { Trans } from "@lingui/macro";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import { inject } from "mobx-react";
import classes from "./Share.module.scss";
import urls from "../../../../utils/urls";
import theme from "../../../../configs/theme";
import catalogEn from "../../../../locales/en/messages";

const catalogs = {
  en: catalogEn
};

@inject("appStore")
class Share extends PureComponent {
  render() {
    const { id, appStore, title } = this.props;
    const { currentLanguage } = appStore;
    let sharedUrl = urls.singleBlog(id, currentLanguage, title);

    return (
      <div className={classes.social}>
        <I18nProvider language={currentLanguage} catalogs={catalogs}>
          <H5 className={classes.header}>
            <Trans>Partager l’article</Trans>
          </H5>
          <TwitterShareButton className={classes.socialItem} url={sharedUrl}>
            <IconCircle
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
              type="twitter"
              dimension={42}
              innerColor="#fff"
              iconWidth={26.5}
              iconHeight={21}
            />
          </TwitterShareButton>
          <LinkedinShareButton className={classes.socialItem} url={sharedUrl}>
            <IconCircle
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
              type="linked-in"
              dimension={42}
              innerColor="#fff"
              iconWidth={22.16}
              iconHeight={20.95}
            />
          </LinkedinShareButton>
          <FacebookShareButton className={classes.socialItem} url={sharedUrl}>
            <IconCircle
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
              type="facebook"
              dimension={42}
              innerColor="#fff"
              iconWidth={11.43}
              iconHeight={21}
            />
          </FacebookShareButton>
        </I18nProvider>
      </div>
    );
  }
}

Share.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};
export default withI18n()(Share);
