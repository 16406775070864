import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Trans } from "@lingui/macro";
import { Col, Button, H2 } from "cf-neo-ui";
import domaineExpertise from "../../../configs/domaineExpertise";

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconColor1: "black",
      iconColor2: "white"
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { missionsSearchStore, history, i18n } = this.props;
    const { iconColor1, iconColor2 } = this.state;
    const { changeEmploymentType, addCategory } = missionsSearchStore;
    return (
      <div className={classes.section3}>
        <H2 className={classes.mainText}>
          <Trans>Votre parcours est unique, notre accompagnement aussi</Trans>
        </H2>
        <Col xl={12} lg={12} md={12} sm={12} xs={6} className={classes.bigCol}>
          {/* image */}
          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            xs={6}
            className={classes.bigColDesktop}
          >
            <picture>
              <source type="images/png" srcSet="/assets/images/ranking.png" />
              <img
                className={classes.projectsImg}
                src="/assets/images/ranking.png"
                alt="illustration_croissance_finance"
              />
            </picture>
          </Col>
          {/* first block text */}
          <Col xl={7} lg={7} md={12} sm={12} xs={6}>
            <div className={classes.textInside}>
              <Trans>
                Chez Mindquest, nous sommes spécialisés dans{" "}
                <strong>l’accompagnement professionel d’experts </strong>en CDI
                ou en Freelance, sur les domaines de l’IT et les métiers de la
                Finance.
              </Trans>
              <br></br>
              <br></br>

              <Trans>
                {" "}
                Nous nous différencions par un service d’excellence qui allie{" "}
                <strong>
                  expertise relationnelle, compréhension métier et plateforme
                  digitale{" "}
                </strong>
                pour mettre en relation les profils de candidats{" "}
                <strong>hautement qualifiés</strong> et les clients nécessitant
                des compétences.
              </Trans>
            </div>
          </Col>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.bigColSec3}
        >
          {/* second block text */}
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={6}
            className={classes.noPaddingLeft}
          >
            <div className={classes.textInside}>
              <Trans>
                Chaque offre d’emploi est gérée par un duo de professionnels :
                un responsable de comptes connaissant les entreprises, leur
                secteur et leurs enjeux et un spécialiste en acquisition de
                talents, experts sur un domaine de compétences.
              </Trans>
              <br></br>
              <br></br>

              <Trans>
                {" "}
                Ces derniers travaillent ensemble pour fournir un service
                d’accompagnement complet aux entreprises et aux candidats, de la
                publication de l’offre de mission et de la recherche d’emploi à
                l’offboarding.
              </Trans>
            </div>
          </Col>
          {/* button */}
          <Col
            xl={7}
            lg={7}
            md={7}
            sm={7}
            xs={6}
            className={classes.blockButton}
          >
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={6}
              className={classes.section3}
            >
              <Button
                size="medium"
                variant="quaternary"
                classNameText={
                  "min-width: 261px; background: white; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid white;&:hover { background: linear-gradient(43deg, #202123 0%, #3D3F42 100%); color: white;}; > span { margin: 0px;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                }
                icon="chevron-right"
                iconTop="calc(50% - 10.5px)"
                iconColor={iconColor1}
                onMouseEnter={() => this.setState({ iconColor1: "white" })}
                onMouseLeave={() => this.setState({ iconColor1: "black" })}
                radius={0}
                onClick={() => {
                  history.push(
                    "/missions?categories=Finance&employmentType=Contract"
                  );
                  changeEmploymentType("Contract");
                  addCategory(domaineExpertise(i18n, "Finance", "key"));
                }}
              >
                <Trans>Mission Freelance Finance</Trans>
              </Button>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={6}
              className={classes.section3}
            >
              <Button
                size="medium"
                variant="quaternary"
                classNameText={
                  "min-width: 270px; background: linear-gradient(43deg, #202123 0%, #3D3F42 100%); background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px;border: 2px solid white;&:hover { background: white; color: black;}; > span { margin: 0px;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                }
                icon="chevron-right"
                iconTop="calc(50% - 10.5px)"
                iconColor={iconColor2}
                onMouseEnter={() => this.setState({ iconColor2: "black" })}
                onMouseLeave={() => this.setState({ iconColor2: "white" })}
                onClick={() => {
                  history.push(
                    "/missions?categories=Finance&employmentType=Permanent"
                  );
                  changeEmploymentType("Permanent");
                  addCategory(domaineExpertise(i18n, "Finance", "key"));
                }}
              >
                <Trans>Emploi Finance en CDI</Trans>
              </Button>
            </Col>
          </Col>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigColHide}
        >
          <picture>
            <source type="images/png" srcSet="/assets/images/ranking.png" />
            <img
              className={classes.projectsImg}
              src="/assets/images/ranking.png"
              alt="illustration_croissance_finance"
            />
          </picture>
        </Col>
      </div>
    );
  }
}

Section3.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section3));
