import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { t, Trans } from "@lingui/macro";
import { GET_FINANCE_SPECIALTIES } from "../../missions-page/queries";
import { Query } from "react-apollo";
import { Button, Col, H2, H3, H4, InputSkeleton } from "cf-neo-ui";
import LocationWidget from "../components/widgets/LocationWidget";
import SkillsWidget from "../components/widgets/SkillsWidget";

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section7 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      skills: [],
      location: {}
    };
    this.addSkill = this.addSkill.bind(this);
    this.closeTagHandler = this.closeTagHandler.bind(this);
    this.selectLocationHandler = this.selectLocationHandler.bind(this);
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  selectLocationHandler = (address, formattedAddress) => {
    this.setState({ location: formattedAddress });
  };

  addSkill(val) {
    const { skills } = this.state;
    skills.push(val);
    this.setState({ skills: skills });
  }

  closeTagHandler(val) {
    const { skills } = this.state;
    const { missionsSearchStore } = this.props;
    var arr = skills.slice();
    const index = arr.indexOf(val);
    if (index > -1) {
      arr.splice(index, 1);
    }
    this.setState({ skills: arr });
    missionsSearchStore.removeSkill(val);
    missionsSearchStore.setSkillOptions();
  }

  clickHandler() {
    const { skills, location } = this.state;
    const { missionsSearchStore, history } = this.props;
    missionsSearchStore.initLocation();
    missionsSearchStore.initSkills();
    skills?.forEach(value => {
      missionsSearchStore.addSkill(value);
    });

    missionsSearchStore.setSkillOptions();
    const active =
      typeof window !== "undefined" &&
      window.location.search.substring(
        window.location.search.lastIndexOf("?") + 1
      );
    if (Object.keys(location).length !== 0) {
      missionsSearchStore.addLocation(location);
    }

    history.push(`/missions?${active}`);
  }

  render() {
    const { i18n, history } = this.props;
    const { key, skills } = this.state;

    const active =
      typeof window !== "undefined" &&
      window.location.search.substring(
        window.location.search.lastIndexOf("?") + 1
      );
    return (
      <div className={classes.section3}>
        <H2 className={classes.mainText}>
          <Trans>Votre communauté, notre expertise</Trans>
        </H2>
        {/* images part */}
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.bigColSec7Consultant}
        >
          {/* first 2 images */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/grouppresentation.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/grouppresentation.png"
                  alt="illustration_Strategie_Management"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Stratégie et management</Trans>
              </H3>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/smartphone.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/smartphone.png"
                  alt="illustration_Digital_performances"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Digital & IT Finance</Trans>
              </H3>
            </Col>
          </Col>
          {/* second 2 images */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/problemsolving.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/problemsolving.png"
                  alt="illustration_transformation_organisation"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Conseil & Audit</Trans>
              </H3>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={3}
              xs={3}
              className={classes.widgetBody}
            >
              <picture>
                <source
                  type="images/png"
                  srcSet="/assets/images/financial.png"
                />
                <img
                  className={classes.imgSec7}
                  src="/assets/images/financial.png"
                  alt="illustration_Risque_Finance_règlementaire"
                />
              </picture>
              <H3 className={classes.textImg}>
                <Trans>Comptabilité & Finance</Trans>
              </H3>
            </Col>
          </Col>
        </Col>

        {/* first block */}
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigCol}
          style={{ paddingBottom: "0%" }}
        >
          {/* the 2 first box in first block */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>
                    {" "}
                    Direction <br /> financière{" "}
                  </Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    {" "}
                    <Trans> Directeur financier </Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    <Trans> CFO </Trans>
                  </strong>
                  <br />
                  <strong>
                    <Trans> Chief Financial Officer </Trans>
                  </strong>
                  <br />
                  <strong>
                    <Trans> DAF externalisé</Trans>
                  </strong>
                  <br />
                  <strong>
                    <Trans> RAF externalisé</Trans>
                  </strong>
                  <br />
                  <strong>
                    <Trans> DAF à temps partagé </Trans>
                  </strong>
                  <br />
                  <strong>
                    <Trans> RAF à temps partagé</Trans>
                  </strong>
                </span>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  {" "}
                  <Trans>Comptabilité</Trans>{" "}
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    {" "}
                    <Trans>Comptable Freelance</Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>Expert Comptable indépendant</Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>Consolideur indépendant</Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>Trésorier</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans>Cash manager</Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>Directeur comptable</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans>Directeur consolidation</Trans>
                  </strong>
                </span>
              </div>
            </Col>
          </Col>
          {/* the 2 second box in first block */}

          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>
                    {" "}
                    Contrôle de <br /> gestion{" "}
                  </Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    {" "}
                    <Trans>Contrôleur de gestion Freelance</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans>Contrôle de gestion IT</Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>Contrôleur de Gestion</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans>FP&A</Trans>
                  </strong>
                </span>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>Audit Risque</Trans> <br />
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    {" "}
                    <Trans> Actuaire</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Auditeur interne </Trans>{" "}
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans> Auditeur financier </Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Analyst crédit</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Risk Manager </Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> Compliance Officer </Trans>
                  </strong>
                </span>
              </div>
            </Col>
          </Col>
        </Col>

        {/* Second block */}
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigCol}
          style={{ paddingTop: "0%" }}
        >
          {/* the 2 first box in second block */}
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.widgetBodyImagesConsultant}
          >
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  <Trans>
                    Corporate <br /> finance
                  </Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    {" "}
                    <Trans>Analyst financier</Trans>
                  </strong>{" "}
                  <br />
                  <strong>
                    {" "}
                    <Trans> FP&A Manager</Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>Analyst M&A</Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans> Responsable communicaiton financière </Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans>IFRS specialist </Trans>
                  </strong>
                  <br />
                  <strong>
                    {" "}
                    <Trans> Consultant Restructuring </Trans>
                  </strong>
                  <br />
                </span>
              </div>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={6}
              className={classes.widgetBody}
            >
              <div className={classes.box}>
                <H4 className={classes.boxFirst}>
                  {" "}
                  <Trans>Paie</Trans>
                </H4>
                <span className={classes.boxSecond}>
                  <strong>
                    <Trans>Gestionnaire de Paie</Trans>{" "}
                  </strong>
                  <br />
                  GP
                  <br />
                  <strong>
                    {" "}
                    <Trans> Payroll manager </Trans>
                  </strong>
                </span>
              </div>
            </Col>
          </Col>

          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={6}
            className={classes.widgetBodyLastBox}
          >
            <div className={classes.box}>
              <H4 className={classes.boxFirst}>
                <Trans>
                  IT <br /> Finance{" "}
                </Trans>
              </H4>
              <span className={classes.boxSecond}>
                <strong>
                  {" "}
                  <Trans>Data analyst Finance </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant SAP FI CO </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Data Scientist </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Chef de projet Finance / IT </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant AMAO SAP </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant Cegid </Trans>
                </strong>
                <br />
                <strong>
                  {" "}
                  <Trans>Consultant SAGE </Trans>
                </strong>
              </span>
            </div>
          </Col>
        </Col>

        {/* search part */}
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigColSec7}
        >
          <Col
            xl={7}
            lg={8}
            md={8}
            sm={8}
            xs={6}
            className={classes.noPaddingLeftSec1}
          >
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={6}
              className={classes.blockinputs}
            >
              <Col
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={6}
                className={classes.widgetBodySection1}
              >
                <Query query={GET_FINANCE_SPECIALTIES}>
                  {({ loading, error, data }) => {
                    if (loading) return <InputSkeleton />;
                    if (error) return `${i18n._(t`Erreur`)} !`;
                    const { specialtiesFinance: specialtiesData } = data;
                    return (
                      <SkillsWidget
                        key={key}
                        skills={skills}
                        addSkill={this.addSkill}
                        closeTagHandler={this.closeTagHandler}
                        options={specialtiesData.map(item => item.name)}
                      />
                    );
                  }}
                </Query>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={6}
                className={classes.widgetBodySection1}
              >
                <LocationWidget
                  selectLocationHandler={this.selectLocationHandler}
                  key={key}
                />
              </Col>
              <Col
                xl={4}
                lg={5}
                md={5}
                sm={12}
                xs={6}
                className={classes.widgetBodySection1}
              >
                <Button
                  size="medium"
                  icon="search"
                  iconPosition="right"
                  iconColor="white"
                  variant="quaternary"
                  iconTop="calc(50% - 10.5px)"
                  onClick={() => this.clickHandler()}
                  classNameText={
                    "height: 53px; width: 100%; background: #242424; background-size: 101% 101%;color: #FFF; box-shadow: 0px 4px 4px 0px rgba(136, 136, 136, 0.25);border-radius: 5px;> span { font-family: Montserrat;  margin: 0px;;font-size: 16px;font-style: normal;font-weight: 600;line-height: 26px;letter-spacing: 0.3px;}"
                  }
                >
                  <Trans>Chercher une offre</Trans>
                </Button>
              </Col>
            </Col>
          </Col>
        </Col>
      </div>
    );
  }
}

Section7.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section7));
