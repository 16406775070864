import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import Location from "../../../../components/location/location";

@inject("missionsSearchStore")
@observer
class LocationWidget extends Component {
  render() {
    const { selectLocationHandler } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <Location
          getLocation={selectLocationHandler}
          iconColor2="black"
          iconColor="black"
          iconPosition="left"
        />
      </div>
    );
  }
}

LocationWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.PropTypes.shape({
    cityLabels: PropTypes.shape({}),
    addLocation: PropTypes.func,
    removeLocation: PropTypes.func,
    setRadius: PropTypes.func
  }).isRequired,
  selectLocationHandler: PropTypes.func
};

export default LocationWidget;
