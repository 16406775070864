import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { Divider, H4, P, List, TextSkeleton } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./MissionDescription.module.scss";
import ApplyBtn from "../LeftSlide/ApplyBtn";
import { inject, observer } from "mobx-react";

/* character string used to distinguish lists */
const UL_SEP = "*";

@inject("appStore")
@observer
class MissionDescription extends PureComponent {
  id = 0;

  formatText = text => {
    const res = [];
    const splitted = text.split("\n");

    for (let i = 0; i < splitted.length; i += 1) {
      splitted[i] = splitted[i].trim();
      if (splitted[i].substr(0, UL_SEP.length) === UL_SEP) {
        const li = [splitted[i].slice(UL_SEP.length)];
        for (let j = i + 1; j < splitted.length; j += 1) {
          if (splitted[j].substr(0, UL_SEP.length) !== UL_SEP) {
            i = j - 1;
            break;
          }
          li.push(splitted[j].slice(UL_SEP.length));
          if (j + 1 === splitted.length) i = j;
        }
        res.push({
          type: "ul",
          content: li
        });
      } else {
        res.push({
          type: "p",
          content: splitted[i]
        });
      }
    }
    return res;
  };

  displayText = text => {
    const formattedText = this.formatText(text);
    return (
      <div>
        {formattedText.map(item => {
          if (item.type === "ul") {
            this.id += 1;
            return (
              <List className={classes.list} key={this.id}>
                {item.content.map(li => {
                  this.id += 1;
                  return <List.Item key={this.id}>{li}</List.Item>;
                })}
              </List>
            );
          }
          this.id += 1;
          return (
            <P className={classes.description} key={this.id}>
              {item.content}
            </P>
          );
        })}
      </div>
    );
  };

  loadingSkeletons = () => (
    <div className={classes.missionDescription}>
      {[1, 2, 3, 4].map(i => (
        <section className={classes.skeletonSection} key={i}>
          <TextSkeleton width="200px" heading className={classes.header} />
          <TextSkeleton width="100%" lines={3} className={classes.header} />
        </section>
      ))}
      <section>
        <TextSkeleton width="100%" />
      </section>
    </div>
  );

  render() {
    const { loading, content, customText7, id, disable, appStore } = this.props;
    const isMobile = appStore.width <= 550;
    return loading ? (
      this.loadingSkeletons()
    ) : (
      <div className={classes.missionDescription}>
        {content.context !== "" && (
          <section>
            <h2 className={classes.header}>
              <Trans>Context</Trans>
            </h2>
            <Divider />
            {this.displayText(content.context)}
          </section>
        )}
        {content.mission && (
          <section>
            <h2 className={classes.header}>
              <Trans>Mission</Trans>
            </h2>
            <Divider />
            {this.displayText(content.mission)}
          </section>
        )}
        {content.requiredProfile && (
          <section>
            <h2 className={classes.header}>
              <Trans>Profil recherché</Trans>
            </h2>
            <Divider />
            {this.displayText(content.requiredProfile)}
          </section>
        )}
        {content.benefits && (
          <section>
            <h2 className={classes.header}>
              <Trans>Avantages</Trans>
            </h2>
            <Divider />
            {this.displayText(content.benefits)}
          </section>
        )}

        {content.contact && !customText7?.includes("Caroline G") && (
          <section>
            <h2 className={classes.header}>
              <Trans>Contacts</Trans>
            </h2>
            <Divider />
            {this.displayText(content.contact)}
          </section>
        )}
        <ApplyBtn
          loading={loading}
          id={id}
          customText7={customText7}
          disable={disable}
          style={isMobile ? 100 : 25}
        />
      </div>
    );
  }
}

MissionDescription.propTypes = {
  loading: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    context: PropTypes.string,
    mission: PropTypes.string,
    requiredProfile: PropTypes.string,
    benefits: PropTypes.string,
    contact: PropTypes.string
  }).isRequired,
  customText7: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MissionDescription;
