import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import {
  Col,
  FormGroup,
  IconCircle,
  MultiDateRangePicker,
  Row,
  SelectNewVersion,
  SwitchButton3,
  TextInputV2,
  Validation
} from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import "../styles.scss";

const oneGroupData2 = i18n => {
  return {
    groupA: [
      { text: i18n._(t`Normal`), value: "normal" },
      { text: i18n._(t`Supplémentaire`), value: "extra" },
      { text: i18n._(t`Astreinte`), value: "penalty" }
    ]
  };
};

@inject("newTimesheetStore", "appStore")
@observer
class AddPeriodForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: null,
      theme: "N/A",
      place: "on-site",
      hours: 0,
      minutes: 0,
      type: "normal",
      clicked: false,
      isValidPeriod: "",
      isValidType: "",
      isHoursValid: true,
      isValidHours: "",
      isMinutesValid: true,
      isValidMinutes: "",
      dayOrHours: "days",
      periods: null
    };

    this.dataPreparation = this.dataPreparation.bind(this);
  }

  dataPreparation = () => {
    const { newTimesheetStore, i18n } = this.props;
    const {
      themes,
      themeToPreselect,
      changeThemeToPreselect
    } = newTimesheetStore;

    const themesList = [];
    themes.map(theme => {
      theme.name &&
        themesList.push({
          text: theme.name,
          value: theme.name,
          selected:
            themeToPreselect && theme.name == themeToPreselect ? true : false,
          icon: { type: "dot", color: theme.color }
        });
    });

    themesList.push({
      text: i18n._(t`Ajouter projet`),
      value: "ADD_NEW_THEME",
      icon: { type: "plus", color: "#242424" },
      last: true
    });

    return themesList;
  };

  onSelectThemeFunc = opt => {
    // Note: Variable destructuring is preferred before if-else logic for global variables
    const { newTimesheetStore } = this.props;
    const {
      openClosePopUps,
      themes,
      changeThemeToPreselect,
      LIMIT_THEMES
    } = newTimesheetStore;
    if (opt.value === "ADD_NEW_THEME") {
      changeThemeToPreselect(true);
      if (themes && themes.length === LIMIT_THEMES)
        openClosePopUps("limitOfThemes");
      else openClosePopUps("addTheme");
    } else {
      changeThemeToPreselect(opt.value);
    }
  };

  onSelectTypeFunc = opt => {
    this.setState({ type: opt.value, isValidType: "" });
  };

  onChangeHours = e => {
    const { i18n } = this.props;
    if (e.target.value > 24) {
      this.setState({
        hours: e.target.value,
        isHoursValid: false,
        isValidHours: i18n._(t`Max 24h`)
      });
    } else {
      this.setState({
        hours: e.target.value,
        isValidHours: "",
        isHoursValid: true
      });
    }
  };

  onChangeMinutes = e => {
    const { i18n } = this.props;
    if (e.target.value > 60) {
      this.setState({
        minutes: e.target.value,
        isMinutesValid: false,
        isValidMinutes: i18n._(t`Max 60m`)
      });
    } else {
      this.setState({
        minutes: e.target.value,
        isMinutesValid: true,
        isValidMinutes: ""
      });
    }
  };

  addPeriod = e => {
    const { newTimesheetStore, i18n } = this.props;
    const {
      addPeriod,
      changeThemeToPreselect,
      themeToPreselect
    } = newTimesheetStore;
    const {
      periods,
      theme,
      place,
      hours,
      minutes,
      type,
      clicked,
      isHoursValid,
      isMinutesValid,
      dayOrHours
    } = this.state;

    let valid = true;
    if (!periods) {
      this.setState({ isValidPeriod: i18n._(t`Ce champs est requis`) });
      valid = false;
    }
    if (hours == 0 && minutes == 0 && dayOrHours != "days") {
      this.setState({
        isHoursValid: false,
        isValidHours: i18n._(t`Ce champs est requis`)
      });
      valid = false;
    } else {
      if (!isHoursValid || !isMinutesValid) {
        valid = false;
      }
    }
    if (!type) {
      this.setState({ type: "normal", isValidType: "" });
    }
    if (dayOrHours === "days") {
      this.setState({ hours: 8 });
      valid = true;
    }

    if (valid && periods && periods.length > 0) {
      periods.forEach(period => {
        const { startDate, endDate } = period;

        addPeriod({
          start: startDate,
          end: endDate,
          hours:
            dayOrHours === "days"
              ? parseInt(8, 10) || 0
              : parseInt(hours, 10) || 0,
          minutes: parseInt(minutes, 10) || 0,
          themeName: themeToPreselect || theme,
          // themeName: theme || themeToPreselect,=> bug neos-4334
          type: type, // Utilisez le type de la période sélectionnée
          description: "",
          place
        });
      });
      this.setState({
        periods: null,
        theme: "N/A",
        place: "on-site",
        hours: 0,
        minutes: 0,
        type: null,
        clicked: !clicked
      });
      changeThemeToPreselect("N/A");
    } else {
      console.log("selectedPeriods is empty");
    }
  };

  render() {
    const { newTimesheetStore, i18n, appStore } = this.props;
    const { themes, timesheet, themeToPreselect } = newTimesheetStore;
    const {
      isValidPeriod,
      place,
      isValidType,
      clicked,
      isValidHours,
      isHoursValid,
      isValidMinutes,
      isMinutesValid,
      dayOrHours
    } = this.state;
    const { currentLanguage } = appStore;
    const _ = React;
    const themesList = this.dataPreparation();
    return (
      <div style={{ padding: "0 15px" }}>
        <div
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            color: "#000000",
            marginBottom: "21px",
            marginLeft: "-15px"
          }}
        >
          <Trans>Ajouter des heures</Trans>
        </div>
        <form key={clicked}>
          <Row>
            <Col xs={6} sm={12} md={10} lg={10} xl={10}>
              <Row style={{ marginBottom: "18px" }}>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="period">
                      <Trans>Plage de date</Trans>
                    </label>
                    <Validation
                      errorMessage={isValidPeriod}
                      valid={isValidPeriod == ""}
                    >
                      <MultiDateRangePicker
                        placeholder={i18n._(t`Aujourd’hui`)}
                        onChange={v => {
                          this.setState({ periods: v, isValidPeriod: "" });
                        }}
                        locale={currentLanguage}
                        year={timesheet.year}
                        month={timesheet.month}
                        isDatePicker={true}
                        acceptText={i18n._(t`Accepter`)}
                        resetText={i18n._(t`Réinitialiser `)}
                        warningMsg={i18n._(
                          t`Week-end et Jours fériés seront déselectionnés de la plage`
                        )}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="theme">
                      <Trans>Projets</Trans>
                    </label>
                    <Validation errorMessage="" valid={true}>
                      <SelectNewVersion
                        key={themes.length}
                        options={{ groupA: themesList }}
                        placeholder={i18n._(t`Sélectionner`)}
                        onSelect={this.onSelectThemeFunc}
                        valid={true}
                        disabled={false}
                        isProject={true}
                      />
                    </Validation>
                  </FormGroup>
                </Col>

                <Col
                  lg={4}
                  xl={4}
                  style={{
                    padding: "0 6px"
                  }}
                >
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="format">
                      {dayOrHours === "days"
                        ? i18n._(t`Jour plein`)
                        : i18n._(t`Heures`)}
                    </label>
                    <Validation errorMessage="" valid={true}>
                      <SwitchButton3
                        defaultTab={dayOrHours === "days" ? 1 : 2}
                        SegmentText1={i18n._(t`Jour plein`)}
                        SegmentText2={i18n._(t`Heures`)}
                        onClick={() => {
                          this.setState({
                            hours: 0,
                            dayOrHours:
                              SwitchButton3.getSelectedTab() === 1
                                ? "days"
                                : "hours"
                          });
                        }}
                        style={{ marginBottom: "5px" }}
                      />
                    </Validation>
                  </FormGroup>
                  {dayOrHours === "hours" && (
                    <Col lg={12} xl={12} style={{ padding: "0 6px" }}>
                      <Row>
                        <Col lg={6} xl={6} style={{ marginRight: "inherit" }}>
                          <FormGroup>
                            <Validation
                              errorMessage={isValidHours}
                              valid={isHoursValid}
                            >
                              <TextInputV2
                                placeholder="00h"
                                type="number"
                                min="0"
                                max="24"
                                onChange={this.onChangeHours}
                              />
                            </Validation>
                          </FormGroup>
                        </Col>
                        <Col lg={6} xl={6}>
                          <FormGroup>
                            <Validation
                              errorMessage={isValidMinutes}
                              valid={isMinutesValid}
                            >
                              <TextInputV2
                                placeholder="00m"
                                type="number"
                                min="0"
                                max="60"
                                onChange={this.onChangeMinutes}
                              />
                            </Validation>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Col>
              </Row>
              <Row
                style={
                  dayOrHours == "days"
                    ? { marginBottom: "-20px", marginTop: "0px" }
                    : { marginBottom: "-20px", marginTop: "-30px" } //old val 40
                }
              >
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="type">
                      <Trans>Type d’heures effectuées</Trans>
                    </label>
                    <Validation errorMessage="" valid={true}>
                      <SelectNewVersion
                        options={oneGroupData2(i18n)}
                        placeholder={i18n._(t`Normal`)}
                        onSelect={this.onSelectTypeFunc}
                        valid={true}
                        disabled={false}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }}>
                  <FormGroup>
                    <label className="timesheet-form-label" htmlFor="format">
                      <Trans>Format</Trans>
                    </label>
                    <Validation errorMessage="" valid={true}>
                      <SwitchButton3
                        defaultTab={place === "on-site" ? 1 : 2}
                        SegmentText1={i18n._(t`Présentiel`)}
                        SegmentText2={i18n._(t`Télétravail`)}
                        onClick={() => {
                          this.setState({
                            place:
                              SwitchButton3.getSelectedTab() === 1
                                ? "on-site"
                                : "remote"
                          });
                        }}
                        style={{ marginBottom: "50px" }}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col xs={6} sm={12} md={2} lg={2} xl={2}>
                  <div style={{ margin: "auto" }}>
                    <IconCircle
                      type="plus"
                      dimension={46}
                      innerColor="#FFFFFF"
                      innerColor2="#FFFFFF"
                      color="#8D0417"
                      color2="#D3354A"
                      iconWidth={24}
                      iconHeight={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.addPeriod();
                      }}
                    />
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "10px",
                        lineHeight: "14px",
                        color: "#242424",
                        textAlign: "center",
                        marginTop: "14px"
                      }}
                    >
                      <Trans> Ajouter </Trans>
                    </div>
                  </div>
                </Col>
                <Col lg={4} xl={4} style={{ padding: "0 6px" }} />
              </Row>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

AddPeriodForm.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    themes: PropTypes.array,
    timesheet: PropTypes.shape({}),
    addTheme: PropTypes.func,
    openClosePopUps: PropTypes.func,
    addPeriod: PropTypes.func,
    LIMIT_THEMES: PropTypes.number
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};
export default withApollo(withI18n()(withRouter(AddPeriodForm)));
