import { observable, action } from "mobx";

const defaultValues = {
  isVisible: false,
  poolExist: false,
  errorPassword: false,
  isVisibleCreatePool: false,
  isVisible2: false,
  isVisibleListPools: false,
  toDelete: false,
  toDeleteCollaborator: false,
  isVisibleContactRequest: false,
  bottomIsVisible: false,
  timesheetPopUp: 0,
  experiencePopUp: false,
  modalType: "",
  signatairePopUp: false,
  signatairePopUp2: false,
  isProfileActivationPopUpVisible: false
};

export default class ModalStore {
  @observable isVisible;

  @observable isVisibleCreatePool;

  @observable poolExist;

  @observable errorPassword;

  @observable isVisible2;

  @observable isVisibleListPools;

  @observable toDelete;

  @observable toDeleteCollaborator;

  @observable isVisibleContactRequest;

  @observable bottomIsVisible;

  @observable postANeedFromFooter;

  @observable modalType;

  @observable timesheetPopUp;

  @observable experiencePopUp;

  @observable signatairePopUp;

  @observable signatairePopUp2;

  @observable isProfileActivationPopUpVisible;

  @observable paramsChangeEmailPopUp;

  @observable paramsDeleteAccountPopUp;

  @observable paramsConfirmationPasswordPopUp;

  @observable paramsSuccessPopUp;

  @observable poolListToShow;

  @observable candidateModalToShow;

  constructor(initialState = defaultValues) {
    this.isVisible = initialState.isVisible || false;
    this.isVisibleCreatePool = initialState.isVisibleCreatePool || false;
    this.poolExist = initialState.poolExist || false;
    this.errorPassword = initialState.errorPassword || false;
    this.isVisible2 = initialState.isVisible2 || false;
    this.isVisibleListPools = initialState.isVisibleListPools || false;
    this.toDelete = initialState.toDelete || false;
    this.toDeleteCollaborator = initialState.toDeleteCollaborator || false;
    this.isVisibleContactRequest =
      initialState.isVisibleContactRequest || false;
    this.bottomIsVisible = initialState.bottomIsVisible || false;
    this.modalType = initialState.modalType || "";
    this.timesheetPopUp = initialState.timesheetPopUp || 0;
    this.experiencePopUp = initialState.experiencePopUp || false;
    this.signatairePopUp = initialState.signatairePopUp || false;
    this.signatairePopUp2 = initialState.signatairePopUp2 || false;
    this.isProfileActivationPopUpVisible =
      initialState.isProfileActivationPopUpVisible || false;
    this.postANeedFromFooter = initialState.postANeedFromFooter || false;
    this.paramsChangeEmailPopUp = initialState.paramsChangeEmailPopUp || false;
    this.paramsSuccessPopUp = initialState.paramsSuccessPopUp || false;
    this.paramsDeleteAccountPopUp =
      initialState.paramsDeleteAccountPopUp || false;
    this.paramsConfirmationPasswordPopUp =
      initialState.paramsConfirmationPasswordPopUp || false;
    this.poolListToShow = false;
    this.candidateModalToShow = false;
  }

  @action
  changeIsVisible = isVisible => {
    this.isVisible = isVisible;
  };
  @action
  changePoolExist = poolExist => {
    this.poolExist = poolExist;
  };
  @action
  changeErrorPassword = errorPassword => {
    this.errorPassword = errorPassword;
  };

  @action
  changeIsVisibleCreatePool = isVisibleCreatePool => {
    if (isVisibleCreatePool) {
      this.isVisibleCreatePool = true;
      this.candidateModalToShow = this.isVisible2;
      this.isVisible2 = false;
      this.poolListToShow = this.isVisibleListPools;
      this.isVisibleListPools = false;
    } else {
      this.isVisibleListPools = this.poolListToShow;
      this.isVisible2 = this.candidateModalToShow;
      this.isVisibleCreatePool = false;
    }
  };

  @action
  changeIsVisibleListPools = isVisibleListPools => {
    this.isVisibleListPools = isVisibleListPools;
  };

  @action
  changeIsVisible2 = isVisible2 => {
    this.isVisible2 = isVisible2;
  };

  @action
  changetoDelete = toDelete => {
    this.toDelete = toDelete;
  };
  @action
  changetoDeleteCollaborator = toDeleteCollaborator => {
    this.toDeleteCollaborator = toDeleteCollaborator;
  };

  @action
  changeIsVisibleContactRequest = isVisibleContactRequest => {
    this.isVisibleContactRequest = isVisibleContactRequest;
  };

  @action
  changeBottomIsVisible = bottomIsVisible => {
    this.bottomIsVisible = bottomIsVisible;
  };

  @action
  changeModalType = modalType => {
    this.modalType = modalType;
  };

  @action
  changeTimesheetPopUp = timesheetPopUp => {
    this.timesheetPopUp = timesheetPopUp;
  };

  @action
  changeExperiencePopUp = experiencePopUp => {
    this.experiencePopUp = experiencePopUp;
  };

  @action
  changeSignatairePopUp = signatairePopUp => {
    this.signatairePopUp = signatairePopUp;
  };

  @action
  changeSignatairePopUp2 = signatairePopUp2 => {
    this.signatairePopUp2 = signatairePopUp2;
  };

  @action
  changeProfileActivationPopUpVisibility = isProfileActivationPopUpVisible => {
    this.isProfileActivationPopUpVisible = isProfileActivationPopUpVisible;
  };

  @action
  changePostANeedFromFooter = postANeedFromFooter => {
    this.postANeedFromFooter = postANeedFromFooter;
  };

  @action
  changeParamsChangeEmailPopUp = paramsChangeEmailPopUp => {
    this.paramsChangeEmailPopUp = paramsChangeEmailPopUp;
  };

  @action
  changeParamsDeleteAccountPopUp = paramsDeleteAccountPopUp => {
    this.paramsDeleteAccountPopUp = paramsDeleteAccountPopUp;
  };

  @action
  changeParamsConfirmationPasswordPopUp = paramsConfirmationPasswordPopUp => {
    this.paramsConfirmationPasswordPopUp = paramsConfirmationPasswordPopUp;
  };

  @action
  changeParamsSuccessPopUp = paramsSuccessPopUp => {
    this.paramsSuccessPopUp = paramsSuccessPopUp;
  };

  @action
  reset = () => {
    this.isVisible = false;
    this.isVisibleCreatePool = false;
    this.poolExist = false;
    this.errorPassword = false;
    this.isVisible2 = false;
    this.isVisibleListPools = false;
    this.toDelete = false;
    this.toDeleteCollaborator = false;
    this.bottomIsVisible = false;
    this.modalType = "";
    this.timesheetPopUp = 0;
    this.experiencePopUp = false;
    this.signatairePopUp = false;
    this.signatairePopUp2 = false;
    this.postANeedFromFooter = false;
    this.isProfileActivationPopUpVisible = false;
    this.paramsChangeEmailPopUp = false;
    this.paramsDeleteAccountPopUp = false;
    this.paramsConfirmationPasswordPopUp = false;
    this.paramsSuccessPopUp = false;
  };
}
