import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Mutation, Query } from "react-apollo";
import {
  Divider,
  SourcerContactCard,
  toaster,
  Modal,
  ToastCard
} from "cf-neo-ui";
import { withI18n } from "@lingui/react/index";
import { t } from "@lingui/macro";
import { CREATE_MISSION_APPLICATION } from "../../mutations";
import { USER_APPLICATIONS_QUERY } from "../../query";
import Routes from "../../../layout/routes";
import routes from "../../../layout/routes/hbLayoutRoutes";
import classes from "./LeftSlide.module.scss";
import DistanceMatrix from "./distance-matrix/DistanceMatrix";

@inject("sessionStore")
@observer
class LeftSlide extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
    this.state = {
      isVisible: false,
      title: "",
      description: "",
      applied: false
    };
  }

  isBtnDisabled = userApplications => {
    const { id, disable } = this.props;
    return disable || !!userApplications.length;
  };

  linkedInIconHandler = url => {
    window.open(url, "_blank");
  };

  toLogin = () => {
    const { id, sessionStore, history } = this.props;
    sessionStore.setBackRef(id);
    history.push(`${Routes.HbLayoutRoutes.Login.path}?origin=apply`);
  };

  contact = () => {
    const { sessionStore, sourcer, history } = this.props;
    sessionStore.setContactPageEmail({
      email: sourcer.email,
      name: sourcer.name
    });
    history.push(routes.contact.path);
  };

  mutationCompleted = () => {
    this.setState({ applied: true });
    // return (
    //   <Query query={USER_APPLICATIONS_QUERY} variables={{ active: true }}>
    //     {({ loading, error: queryError, data }) => {
    //       if (queryError) return null;
    //       if (loading) return null;
    //       const { userApplications } = data;
    //     }}
    //   </Query>
    // );
  };

  displaySourcerCard = i18n => {
    const {
      sessionStore,
      sourcer,
      id,
      loading: loadingGeneral,
      customText7
    } = this.props;
    const { applied } = this.state;
    let iconClickHandler = null;
    const variables = applied
      ? { active: true }
      : { jobOrderId: parseInt(id, 10) };
    if (sourcer.linkedIn) {
      iconClickHandler = () => this.linkedInIconHandler(sourcer.linkedIn);
    }

    if (sessionStore.authToken && sessionStore.account.role === "CANDIDATE") {
      return loadingGeneral ? null : (
        <Query
          query={USER_APPLICATIONS_QUERY}
          variables={{ ...variables }}
          fetchPolicy="network-only"
        >
          {({ loading, error: queryError, data }) => {
            if (queryError) return null;
            if (loading) return null;

            const { userApplications } = data;
            if (applied) {
              const { sessionStore, i18n } = this.props;
              const num = (userApplications && userApplications.length) || 0;
              const remainingApplications = Math.max(0, 3 - num);
              let toastDescription = i18n._(
                t`Vous pouvez suivre vos candidatures dans votre profil`
              );

              if (remainingApplications >= 0) {
                toastDescription += `${i18n._(
                  t`Il vous reste ${remainingApplications}/3 candidatures possibles.Lorsqu'un emploi pour lequel vous avez postulé est fermé, vous recevez une candidature supplémentaire`
                )}`;
              }
              toaster.success({
                title: i18n._(t`Candidature créée avec succès`),
                description: toastDescription,
                id: "application_success"
              });
              sessionStore.setJobOrderReferer(null);
            }
            return (
              <Mutation
                mutation={CREATE_MISSION_APPLICATION}
                onCompleted={() => this.mutationCompleted()}
                onError={error => {
                  const toastObj = {
                    title: i18n._(t`Erreur de création de candidature`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de votre candidature`
                    )
                  };
                  if (
                    error.graphQLErrors[0].message ===
                    "Vous avez déjà postulé pour cette mission"
                  ) {
                    toastObj.title = i18n._(
                      t`Vous avez déjà postulé pour cette mission`
                    );
                    toastObj.description = i18n._(
                      t`Vous pouvez suivre vos candidatures dans votre profil`
                    );
                  } else if (
                    error.graphQLErrors[0].message ===
                    'Unexpected error value: "Unauthorized"'
                  ) {
                    toastObj.title = i18n._(t`Accès non autorisé`);
                    toastObj.description = i18n._(
                      t`Vous devez compléter votre inscription pour postuler à cette offre`
                    );
                  } else if (
                    error.graphQLErrors[0].message ===
                      "Application more than 3" ||
                    'Unexpected error value: "Application more than 3"'
                  ) {
                    toastObj.title = i18n._(
                      t`Les candidatures sont limitées à 3 postes ouverts simultanément`
                    );
                    toastObj.description = i18n._(
                      t`Lorsqu'un emploi pour lequel vous avez postulé est fermé, vous pouvez postuler une nouvelle fois`
                    );
                  }

                  this.setState({
                    title: toastObj.title,
                    description: toastObj.description,
                    isVisible: true
                  });
                  //toaster.error(toastObj);
                }}
                ignoreResults
                refetchQueries={[
                  {
                    query: USER_APPLICATIONS_QUERY,
                    variables: { active: true }
                  }
                ]}
              >
                {createMissionApplication =>
                  customText7?.includes("Caroline G") ? (
                    <SourcerContactCard
                      avatar="/mq.png"
                      showButton={true}
                      textButton={false}
                      onApply={() => {
                        createMissionApplication({
                          variables: {
                            jobOrderId: id,
                            origin:
                              sessionStore.jobOrderReferer ||
                              "website navigation"
                          }
                        });
                        sessionStore.setIsItDisable(true);
                      }}
                      applyButtonDisabled={this.isBtnDisabled(userApplications)}
                      applyButtonLabel={
                        this.isBtnDisabled(userApplications)
                          ? i18n._(t`Postulé`)
                          : i18n._(t`Postuler`)
                      }
                    />
                  ) : (
                    <SourcerContactCard
                      avatar={sourcer.avatar}
                      name={sourcer.name}
                      position={sourcer.position}
                      email={sourcer.email}
                      emailClick={this.contact}
                      phoneNumber={sourcer.phoneNumber}
                      description={sourcer.description}
                      onApply={() => {
                        createMissionApplication({
                          variables: {
                            jobOrderId: id,
                            origin:
                              sessionStore.jobOrderReferer ||
                              "website navigation"
                          }
                        });
                      }}
                      applyButtonDisabled={this.isBtnDisabled(userApplications)}
                      onIconClick={iconClickHandler}
                      applyButtonLabel={
                        this.isBtnDisabled(userApplications)
                          ? i18n._(t`Postulé`)
                          : i18n._(t`Postuler`)
                      }
                      showButton={true}
                      textButton={false}
                    />
                  )
                }
              </Mutation>
            );
          }}
        </Query>
      );
    }
    return loadingGeneral ? null : customText7?.includes("Caroline G") ? (
      <SourcerContactCard
        avatar="/mq.png"
        onApply={this.toLogin}
        showButton={true}
        textButton={false}
        applyButtonLabel={i18n._(t`Postuler`)}
        applyButtonDisabled={
          sessionStore.authToken && sessionStore.account.role === "CLIENT"
        }
      />
    ) : (
      <SourcerContactCard
        avatar={sourcer.avatar}
        name={sourcer.name}
        position={sourcer.position}
        email={sourcer.email}
        emailClick={this.contact}
        phoneNumber={sourcer.phoneNumber}
        description={sourcer.description}
        showButton={true}
        textButton={false}
        onApply={this.toLogin}
        onIconClick={iconClickHandler}
        applyButtonLabel={i18n._(t`Postuler`)}
        applyButtonDisabled={
          sessionStore.authToken && sessionStore.account.role === "CLIENT"
        }
      />
    );
  };

  render() {
    const { id, i18n, className, history } = this.props;

    const { isVisible, title, description } = this.state;
    return (
      <div className={[classes.slideBar, className].join(" ")}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          position="fixed"
        >
          <ToastCard
            title={title}
            description={description}
            expiresIn={0}
            isVisible={isVisible}
            closable
            intent="error"
            showButton
            buttonText={i18n._(t`j'ai compris`)}
            onClose={() => {
              this.setState({ isVisible: false });
              history.push("/login");
            }}
          />
        </Modal>

        <section>{this.displaySourcerCard(i18n)}</section>
        <Divider />
        <section>
          <DistanceMatrix missionId={id} />
        </section>
      </div>
    );
  }
}

LeftSlide.wrappedComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  sourcer: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    description: PropTypes.string,
    linkedIn: PropTypes.string
  }).isRequired,
  customText7: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({
      role: PropTypes.string
    }),
    jobOrderReferer: PropTypes.string,
    setBackRef: PropTypes.func,
    setContactPageEmail: PropTypes.func,
    setJobOrderReferer: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  className: PropTypes.string
};

LeftSlide.defaultProps = {
  className: ""
};

export default withI18n()(withRouter(LeftSlide));
