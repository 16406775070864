import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import { PopUp, Icon, Button } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";

@inject("newTimesheetStore")
@observer
class AlertHolidayToConfirmPopUp extends Component {
  confirm = e => {
    const { newTimesheetStore } = this.props;
    const { addPeriod, periodToStore } = newTimesheetStore;
    if (periodToStore) {
      addPeriod(periodToStore);
    }
  };

  render() {
    const { newTimesheetStore, device, i18n } = this.props;
    const {
      openClosePopUps,
      timesheet,
      changePeriodToStore
    } = newTimesheetStore;
    const country = i18n._(t`${timesheet.country.name}`);
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => {
          openClosePopUps("alertHolidayToConfirm");
          changePeriodToStore(null);
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: device === "mobile" ? "100%" : "342px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              textAlign: "center",
              marginTop: "27px"
            }}
          >
            <Trans>Jour férié sélectionné</Trans>
          </div>
          <div
            style={{
              marginTop: "35px",
              textAlign: "center"
            }}
          >
            <Icon
              type="info"
              width={37}
              color="#8d0417"
              color2="#d3354a"
              filled
            />
          </div>
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "20px",
              color: "#242424",
              textAlign: "center",
              marginTop: "25px"
            }}
          >
            <Trans>Attention, le jour séléctionné est férié en </Trans>
            {country}
            .
            <br />
            <Trans>
              Pour indiquer que vous avez effectivement travaillé ce jour et à
              votre TJM habituel, cliquez sur continuer.{" "}
            </Trans>
            <br />
            <Trans>
              Si vous avez travaillé à un taux majoré (astreintes, heures
              supplémentaires majorées...), merci d'utiliser l'espace heures et
              astreintes.
            </Trans>
          </div>
          <br />
          <div style={{ textAlign: "center", marginTop: "5%" }}>
            {" "}
            <Button
              size="small"
              variant="secondary"
              onClick={() => {
                openClosePopUps("alertHolidayToConfirm");
                changePeriodToStore(null);
              }}
            >
              <Trans>Annuler</Trans>
            </Button>
            <Button
              variant="primary"
              size="small"
              style={{ marginLeft: "25px" }}
              onClick={() => {
                this.confirm();
              }}
            >
              <Trans>Confirmer</Trans>
            </Button>
          </div>
        </div>
      </PopUp>
    );
  }
}

AlertHolidayToConfirmPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    addPeriodd: PropTypes.func,
    periodToStore: PropTypes.shape({})
  }).isRequired,
  device: PropTypes.string
};
export default withI18n()(AlertHolidayToConfirmPopUp);
