import runtimeVars from "../configs/runTimeVars";
import formatBlogTitle from "../containers/blog/blog-single-page/utils/formatBlogTitle";

//  TODO solve circle import & use src/containers/layout/routes/
const BlogLayoutRoutes = {
  Blog: { path: "/blog" },
  // new blog decisionMakers
  DecisionMakers: { path: "/blog/it-decision-makers" },
  TalentStrategy: { path: "/blog/it-decision-makers/talent-strategy" },
  RecruitITConsultant: {
    path: "/blog/it-decision-makers/hiring-an-it-consultant"
  },
  Risks: { path: "/blog/it-decision-makers/tips-and-errors-to-avoid" },
  Legal: { path: "/blog/it-decision-makers/legal" },
  Dsi: { path: "/blog/it-decision-makers/dsi-challenges" },
  // new blog consultantIt
  freelanceTech: { path: "/blog/freelance-tech" },
  CandidateAdvices: { path: "/blog/candidate-advices" },
  FinancePro: { path: "/blog/finance-professionals" },
  BecomeFreelancer: { path: "/blog/freelance-tech/become-freelancer" },
  jobAppAdviceEn: { path: "/blog/freelance-tech/job-application-advice" },
  jobSearchAdviceEn: { path: "/blog/freelance-tech/job-search-advice" },
  tipsDailyEn: {
    path: "/blog/freelance-tech/tips-for-your-daily-life-as-a-freelancer"
  },
  UmbrellaCompanyEn: { path: "/blog/freelance-tech/umbrella-company" },
  DailyFreelance: { path: "/blog/freelance-tech/daily-freelance" },
  Finance: { path: "/blog/freelance-tech/finance" },
  Career: {
    path: "/blog/freelance-tech/growing-your-career-permanent-and-freelance"
  },
  AdminAndLegal: { path: "/blog/freelance-tech/admin-and-legal" },
  Accounting: { path: "/blog/freelance-tech/accounting" },
  Taxation: { path: "/blog/freelance-tech/taxtation-and-retirement" },
  WagePortage: { path: "/blog/freelance-tech/wage-portage" },
  OurPartners: { path: "/blog/freelance-tech/our-partners" },
  // new blog buisinessFile
  BuisinessFile: { path: "/blog/job-description" },
  Developpement: { path: "/blog/job-description/web-developper" },
  Infra: { path: "/blog/job-description/it-infrastructure" },
  Erp: { path: "/blog/job-description/erp-and-sap" },
  ProjectManagement: { path: "/blog/job-description/project-manager" },
  BusinessIntelligence: {
    path: "/blog/job-description/data-and-business-intelligence"
  },
  Digital: { path: "/blog/job-description/digital" },
  //new blog aboutus
  AboutUs: { path: "/blog/about-us" },
  actuality: { path: "/blog/about-us/actuality" },
  pressRelease: { path: "/blog/about-us/press-release" },
  temoignagesAboutUS: { path: "/blog/about-us/testimonials" },
  captainLogAboutUS: { path: "/blog/about-us/captains-log" },
  podcastAboutUS: { path: "/blog/about-us/podcast" },
  //new blog webinars
  Webinars: { path: "/blog/resources" },
  WebconsultantIT: { path: "/blog/resources/content-for-it-consultants" },
  topTenEn: { path: "/blog/tech-magazine/top-10-experts-articles" },
  techmagazineEn: { path: "/blog/tech-magazine" },
  pressReviewEn: { path: "/blog/tech-magazine/pressreview" },
  cybersecurityEn: { path: "/blog/tech-magazine/cybersecurity" },
  Replay: { path: "/blog/resources/webinars-and-replay" },
  webmakersIT: { path: "/blog/resources/content-for-it-makers" },
  PressReview: { path: "/blog/resources/press-review" },
  PressReviewDsi: { path: "/blog/resources/press-review/dsi" },
  PressReviewDevelppement: {
    path: "/blog/resources/press-review/development"
  },
  PressReviewBusinessIntelligence: {
    path: "blog/resources/press-review/business-intelligence"
  },
  PressReviewCybersecurity: {
    path: "/blog/resources/press-review/cybersecurity"
  },
  PressReviewRecrutement: {
    path: "/blog/resources/press-review/recruitment-and-it"
  },
  PressReviewCloud: { path: "/blog/resources/press-review/cloud" },
  PressReviewErp: { path: "/blog/resources/press-review/data-and-erp" },

  //old
  SingleBlog: { path: "/blog/news/:id/:title" },
  News: { path: "/blog/news" },
  AdvicesConsultant: { path: "/blog/advices/consultant" },
  AdvicesEnterprise: { path: "/blog/advices/enterprise" },
  Decryption: { path: "/blog/decryption" },
  SuccessStories: { path: "/blog/stories" },
  Search: { path: "/blog/search" },
  Filter: { path: "/blog/filter" },
  Resources: { path: "/blog/resources/:id" },
  "tips-and-tricks": {
    path: "/blog/career-spa/tips-and-tricks"
  },
  "its-who-is-who": {
    path: "/blog/career-spa/its-who-is-who"
  },
  "business-lounge": {
    path: "/blog/business-lounge"
  },
  Newsstand: {
    path: "/blog/kiosk/Newsstand"
  },
  "captains-log": {
    path: "/blog/kiosk/captains-log"
  },
  "interesting-reads": {
    path: "/blog/kiosk/interesting-reads"
  },
  "horizon-2050": {
    path: "/blog/kiosk/horizon-2050"
  },
  "behind-screen": {
    path: "/blog/behind-screen"
  }
};

const HbfLayoutRoutes = {
  Home: { path: "/" },
  consultant: { path: "/consultant" },
  client: { path: "/enterprise" },
  MissionsPage: { path: "/missions" },
  candidates: { path: "/candidates" },
  about: { path: "/about" },
  financeConsultant: { path: "/talent-finance" },
  financeClient: { path: "/service-entreprise-finance" },
  SingleMission: { path: "/missions/:id" },
  SingleCandidate: { path: "/candidates/:id" },
  PublicProfil: { path: "/talent/:id" },
  sourcerOffers: { path: "/offers/:id" }
};

const HbLayoutRoutes = {
  Login: { path: "/login" },
  SignUp: { path: "/signup" },
  AccountCreation: { path: "/signup-create-account" },
  MyProfile: {
    path: "/signup-my-profile"
  },
  MyJob: { path: "/signup-my-job" },
  MyExperience: {
    path: "/signup-my-experience"
  },
  MySearch: {
    path: "/signup-my-search"
  },
  MyInformation: {
    path: "/signup-my-information"
  },
  Confirm: { path: "/signup-thanks" },
  ResetPassword: { path: "/reset-password" },
  contact: { path: "/contact" },
  PrivacyPolicy: { path: "/privacy-policy" },
  termsOfUse: { path: "/terms-and-conditions-of-use" },
  siteMap: { path: "/site-map" },
  MyNeed: { path: "/my-need/post-a-need" },
  Offer: { path: "/my-need/offer" },
  Offer2: { path: "/my-need/offer2" },
  DesiredProfile: { path: "/my-need/desired-profile" },
  ConfirmPostANeed: { path: "/my-need/thanks" },
  calledByAdvisor: { path: "/called-by-advisor" }
};
const LpbLayoutRoutes = {
  Dashboard: { path: "/dashboard" },
  DashboardCompany: { path: "/dashboard/company" },
  MyProfile: {
    path: "/dashboard/my-profile"
  },
  MyProfileResumeUploader: {
    path: "/dashboard/my-profile/CV-upload"
  },
  MyCertification: {
    path: "/dashboard/my-formation/certification"
  },
  MyFormation: {
    path: "/dashboard/my-formation/formation"
  },
  MyProfileResumeDownloader: {
    path: "/dashboard/my-profile/CV-download"
  },
  MyInformation: {
    path: "/dashboard/my-information"
  },
  MyParams: {
    path: "/dashboard/my-settings"
  },
  MyEducation: {
    path: "/dashboard/my-formation"
  },
  MyJob: {
    path: "/dashboard/my-job"
  },
  MyExperience: {
    path: "/dashboard/my-experience"
  },
  MySearch: {
    path: "/dashboard/my-search"
  },
  missionsDashboard: {
    path: "/dashboard/missions"
  },
  SingleTimesheet: { path: "/timesheet/:id" }
};
let currentLanguage = "en";
if (typeof window != "undefined")
  currentLanguage = localStorage.getItem("language");
const baseUrl =
  currentLanguage == "fr"
    ? runtimeVars.FRONTEND_BASE_FR_URL
    : runtimeVars.FRONTEND_BASE_URL;

const singleMission = id => {
  const { path } = HbfLayoutRoutes.SingleMission;
  return baseUrl + path.replace(":id", id);
};

const singleTimesheet = id => {
  const { path } = LpbLayoutRoutes.SingleTimesheet;
  return baseUrl + path.replace(":id", id);
};

const SingleCandidate = id => {
  const { path } = HbfLayoutRoutes.SingleCandidate;
  return baseUrl + path.replace(":id", id);
};

const PublicProfil = id => {
  const { path } = HbfLayoutRoutes.PublicProfil;
  return baseUrl + path.replace(":id", id);
};

const missions = () => {
  const { path } = HbfLayoutRoutes.MissionsPage;
  return baseUrl + path;
};

const candidates = () => {
  const { path } = HbfLayoutRoutes.candidates;
  return baseUrl + path;
};

const sourcerOffers = id => {
  const { path } = HbfLayoutRoutes.sourcerOffers;
  return baseUrl + path.replace(":id", id);
};

const consultant = () => {
  const { path } = HbfLayoutRoutes.consultant;
  return baseUrl + path;
};

const calledByAdvisor = () => {
  const { path } = HbLayoutRoutes.calledByAdvisor;
  return baseUrl + path;
};

const contact = () => {
  const { path } = HbLayoutRoutes.contact;
  return baseUrl + path;
};

const privacyPolicy = () => {
  const { path } = HbLayoutRoutes.PrivacyPolicy;
  return baseUrl + path;
};

const termsOfUse = () => {
  const { path } = HbLayoutRoutes.termsOfUse;
  return baseUrl + path;
};

const siteMap = () => {
  const { path } = HbLayoutRoutes.siteMap;
  return baseUrl + path;
};

const home = () => {
  return baseUrl;
};

const client = () => {
  const { path } = HbfLayoutRoutes.client;
  return baseUrl + path;
};

const about = () => {
  const { path } = HbfLayoutRoutes.about;
  return baseUrl + path;
};

const financeConsultant = () => {
  const { path } = HbfLayoutRoutes.financeConsultant;
  return baseUrl + path;
};

const financeClient = () => {
  const { path } = HbfLayoutRoutes.financeClient;
  return baseUrl + path;
};

const login = () => {
  const { path } = HbLayoutRoutes.Login;
  return baseUrl + path;
};

const AccountCreation = () => {
  const { path } = HbLayoutRoutes.AccountCreation;
  return baseUrl + path;
};

const signUp = () => {
  const { path } = HbLayoutRoutes.SignUp;
  return baseUrl + path;
};

const postANeed = () => {
  const { path } = HbLayoutRoutes.Offer;
  return baseUrl + path;
};

const blog = () => {
  const { path } = BlogLayoutRoutes.Blog;
  return baseUrl + path;
};

const singleBlog = (id, language, title) => {
  const { path } = BlogLayoutRoutes.SingleBlog;
  let extra = language && language == "fr" ? "?language=fr" : "";
  const formattedTitle = formatBlogTitle(title);
  let url =
    baseUrl + path.replace(":id", id).replace(":title", formattedTitle) + extra;
  return url;
};

const careerSpa = () => {
  return `${baseUrl}/career-spa`;
};

const tipsTricks = () => {
  const { path } = BlogLayoutRoutes["tips-and-tricks"];
  return baseUrl + path;
};

const iTsWho = () => {
  const { path } = BlogLayoutRoutes["its-who-is-who"];
  return baseUrl + path;
};

const businessLoung = () => {
  const { path } = BlogLayoutRoutes["business-lounge"];
  return baseUrl + path;
};

const kiosk = () => {
  return `${baseUrl}/kiosk`;
};

const Newsstand = () => {
  const { path } = BlogLayoutRoutes.Newsstand;
  return baseUrl + path;
};
const captainsLog = () => {
  const { path } = BlogLayoutRoutes["captains-log"];
  return baseUrl + path;
};
const interestingReads = () => {
  const { path } = BlogLayoutRoutes["interesting-reads"];
  return baseUrl + path;
};
const horizon2050 = () => {
  const { path } = BlogLayoutRoutes["horizon-2050"];
  return baseUrl + path;
};
const behindscreen = () => {
  const { path } = BlogLayoutRoutes["behind-screen"];
  return baseUrl + path;
};

const newsBlog = () => {
  const { path } = BlogLayoutRoutes.News;
  return baseUrl + path;
};

const advices = () => {
  return `${baseUrl}/advices`;
};

const advicesConsultant = () => {
  const { path } = BlogLayoutRoutes.AdvicesConsultant;
  return baseUrl + path;
};

const advicesEnterprise = () => {
  const { path } = BlogLayoutRoutes.AdvicesEnterprise;
  return baseUrl + path;
};

const decisionMakers = () => {
  const { path } = BlogLayoutRoutes.DecisionMakers;
  return baseUrl + path;
};
const TalentStrategy = () => {
  const { path } = BlogLayoutRoutes.TalentStrategy;
  return baseUrl + path;
};
const RecruitITConsultant = () => {
  const { path } = BlogLayoutRoutes.RecruitITConsultant;
  return baseUrl + path;
};
const Risks = () => {
  const { path } = BlogLayoutRoutes.Risks;
  return baseUrl + path;
};
const Legal = () => {
  const { path } = BlogLayoutRoutes.Legal;
  return baseUrl + path;
};
const Dsi = () => {
  const { path } = BlogLayoutRoutes.Dsi;
  return baseUrl + path;
};
const freelanceTech = () => {
  const { path } = BlogLayoutRoutes.freelanceTech;
  return baseUrl + path;
};
const candidateAdvices = () => {
  const { path } = BlogLayoutRoutes.CandidateAdvices;
  return baseUrl + path;
};
const financeProfessionals = () => {
  const { path } = BlogLayoutRoutes.FinancePro;
  return baseUrl + path;
};
const buisinessFile = () => {
  const { path } = BlogLayoutRoutes.BuisinessFile;
  return baseUrl + path;
};
const Developpement = () => {
  const { path } = BlogLayoutRoutes.Developpement;
  return baseUrl + path;
};
const Infra = () => {
  const { path } = BlogLayoutRoutes.Infra;
  return baseUrl + path;
};
const Erp = () => {
  const { path } = BlogLayoutRoutes.Erp;
  return baseUrl + path;
};
const ProjectManagement = () => {
  const { path } = BlogLayoutRoutes.ProjectManagement;
  return baseUrl + path;
};
const BusinessIntelligence = () => {
  const { path } = BlogLayoutRoutes.BusinessIntelligence;
  return baseUrl + path;
};
const Digital = () => {
  const { path } = BlogLayoutRoutes.Digital;
  return baseUrl + path;
};
const aboutUs = () => {
  const { path } = BlogLayoutRoutes.AboutUs;
  return baseUrl + path;
};
const actuality = () => {
  const { path } = BlogLayoutRoutes.actuality;
  return baseUrl + path;
};
const pressRelease = () => {
  const { path } = BlogLayoutRoutes.pressRelease;
  return baseUrl + path;
};
const temoignagesAboutUS = () => {
  const { path } = BlogLayoutRoutes.temoignagesAboutUS;
  return baseUrl + path;
};
const captainLogAboutUS = () => {
  const { path } = BlogLayoutRoutes.captainLogAboutUS;
  return baseUrl + path;
};
const podcastAboutUS = () => {
  const { path } = BlogLayoutRoutes.podcastAboutUS;
  return baseUrl + path;
};
const webinars = () => {
  const { path } = BlogLayoutRoutes.Webinars;
  return baseUrl + path;
};
const webconsultantIT = () => {
  const { path } = BlogLayoutRoutes.WebconsultantIT;
  return baseUrl + path;
};
const BecomeFreelancer = () => {
  const { path } = BlogLayoutRoutes.BecomeFreelancer;
  return baseUrl + path;
};
const UmbrellaCompanyEn = () => {
  const { path } = BlogLayoutRoutes.UmbrellaCompanyEn;
  return baseUrl + path;
};
const tipsDailyEn = () => {
  const { path } = BlogLayoutRoutes.tipsDailyEn;
  return baseUrl + path;
};
const jobSearchAdviceEn = () => {
  const { path } = BlogLayoutRoutes.jobSearchAdviceEn;
  return baseUrl + path;
};
const jobAppAdviceEn = () => {
  const { path } = BlogLayoutRoutes.jobAppAdviceEn;
  return baseUrl + path;
};
const DailyFreelance = () => {
  const { path } = BlogLayoutRoutes.DailyFreelance;
  return baseUrl + path;
};
const Finance = () => {
  const { path } = BlogLayoutRoutes.Finance;
  return baseUrl + path;
};
const Career = () => {
  const { path } = BlogLayoutRoutes.Career;
  return baseUrl + path;
};
const AdminAndLegal = () => {
  const { path } = BlogLayoutRoutes.AdminAndLegal;
  return baseUrl + path;
};
const Accounting = () => {
  const { path } = BlogLayoutRoutes.Accounting;
  return baseUrl + path;
};
const WagePortage = () => {
  const { path } = BlogLayoutRoutes.WagePortage;
  return baseUrl + path;
};
const Taxation = () => {
  const { path } = BlogLayoutRoutes.Taxation;
  return baseUrl + path;
};
const OurPartners = () => {
  const { path } = BlogLayoutRoutes.OurPartners;
  return baseUrl + path;
};

const replay = () => {
  const { path } = BlogLayoutRoutes.Replay;
  return baseUrl + path;
};
const webmakersIT = () => {
  const { path } = BlogLayoutRoutes.webmakersIT;
  return baseUrl + path;
};
const pressReview = () => {
  const { path } = BlogLayoutRoutes.PressReview;
  return baseUrl + path;
};
const pressReviewDsi = () => {
  const { path } = BlogLayoutRoutes.PressReviewDsi;
  return baseUrl + path;
};
const pressReviewDevelppement = () => {
  const { path } = BlogLayoutRoutes.PressReviewDevelppement;
  return baseUrl + path;
};
const pressReviewBusinessIntelligence = () => {
  const { path } = BlogLayoutRoutes.PressReviewBusinessIntelligence;
  return baseUrl + path;
};
const pressReviewCybersecurity = () => {
  const { path } = BlogLayoutRoutes.PressReviewCybersecurity;
  return baseUrl + path;
};
const pressReviewRecrutement = () => {
  const { path } = BlogLayoutRoutes.PressReviewRecrutement;
  return baseUrl + path;
};
const pressReviewErp = () => {
  const { path } = BlogLayoutRoutes.PressReviewErp;
  return baseUrl + path;
};
const topTenEn = () => {
  const { path } = BlogLayoutRoutes.topTenEn;
  return baseUrl + path;
};
const techmagazineEn = () => {
  const { path } = BlogLayoutRoutes.techmagazineEn;
  return baseUrl + path;
};
const cybersecurityEn = () => {
  const { path } = BlogLayoutRoutes.cybersecurityEn;
  return baseUrl + path;
};
const pressReviewEn = () => {
  const { path } = BlogLayoutRoutes.pressReviewEn;
  return baseUrl + path;
};

const pressReviewCloud = () => {
  const { path } = BlogLayoutRoutes.PressReviewCloud;
  return baseUrl + path;
};
const decryption = () => {
  const { path } = BlogLayoutRoutes.Decryption;
  return baseUrl + path;
};

const successStories = () => {
  const { path } = BlogLayoutRoutes.SuccessStories;
  return baseUrl + path;
};

const search = () => {
  const { path } = BlogLayoutRoutes.Search;
  return baseUrl + path;
};

const resources = id => {
  const { path } = BlogLayoutRoutes.Resources;
  return baseUrl + path.replace(":id", id);
};

const dashboard = () => {
  const { path } = LpbLayoutRoutes.Dashboard;
  return baseUrl + path;
};
const myProfile = () => {
  const { path } = LpbLayoutRoutes.MyProfile;
  return baseUrl + path;
};
const myResumeUploader = () => {
  const { path } = LpbLayoutRoutes.MyProfileResumeUploader;
  return baseUrl + path;
};
const myResumeDownloader = () => {
  const { path } = LpbLayoutRoutes.MyProfileResumeDownloader;
  return baseUrl + path;
};
const myDetails = () => {
  const { path } = LpbLayoutRoutes.MyInformation;
  return baseUrl + path;
};

const myParams = () => {
  const { path } = LpbLayoutRoutes.MyParams;
  return baseUrl + path;
};

const myJob = () => {
  const { path } = LpbLayoutRoutes.MyJob;
  return baseUrl + path;
};
const myExperience = () => {
  const { path } = LpbLayoutRoutes.MyExperience;
  return baseUrl + path;
};
const myTraining = () => {
  const { path } = LpbLayoutRoutes.MyEducation;
  return baseUrl + path;
};
const mySearch = () => {
  const { path } = LpbLayoutRoutes.MySearch;
  return baseUrl + path;
};

const dashboardCompany = () => {
  const { path } = LpbLayoutRoutes.DashboardCompany;
  return baseUrl + path;
};

const missionsDashboard = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};

const recommendedMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};
const appliedMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};
const favouriteMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};
const alertMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};

const urls = {
  home,
  webconsultantIT,
  cybersecurityEn,
  pressReviewEn,
  topTenEn,
  techmagazineEn,
  captainLogAboutUS,
  podcastAboutUS,
  TalentStrategy,
  RecruitITConsultant,
  Risks,
  Legal,
  Dsi,
  BecomeFreelancer,
  jobAppAdviceEn,
  jobSearchAdviceEn,
  tipsDailyEn,
  UmbrellaCompanyEn,
  DailyFreelance,
  financeConsultant,
  financeClient,
  Career,
  AdminAndLegal,
  Accounting,
  Taxation,
  WagePortage,
  OurPartners,
  webmakersIT,
  replay,
  pressReview,
  pressReviewDsi,
  pressReviewDevelppement,
  pressReviewBusinessIntelligence,
  pressReviewCybersecurity,
  pressReviewRecrutement,
  pressReviewErp,
  pressReviewCloud,
  myProfile,
  myResumeUploader,
  myResumeDownloader,
  myDetails,
  myParams,
  myJob,
  myExperience,
  myTraining,
  mySearch,
  client,
  consultant,
  singleMission,
  singleTimesheet,
  SingleCandidate,
  PublicProfil,
  missions,
  sourcerOffers,
  contact,
  privacyPolicy,
  termsOfUse,
  siteMap,
  about,
  Finance,
  signUp,
  login,
  blog,
  singleBlog,
  "career-spa": careerSpa,
  "tips-and-tricks": tipsTricks,
  "its-who-is-who": iTsWho,
  "business-lounge": businessLoung,
  kiosk,
  Newsstand,
  "captains-log": captainsLog,
  "interesting-reads": interestingReads,
  "horizon-2050": horizon2050,
  "behind-screen": behindscreen,
  newsBlog,
  decryption,
  decisionMakers,
  freelanceTech,
  buisinessFile,
  Developpement,
  Infra,
  Erp,
  ProjectManagement,
  BusinessIntelligence,
  Digital,
  aboutUs,
  temoignagesAboutUS,
  pressRelease,
  actuality,
  webinars,
  advices,
  advicesConsultant,
  advicesEnterprise,
  search,
  successStories,
  resources,
  dashboard,
  dashboardCompany,
  postANeed,
  candidates,
  missionsDashboard,
  recommendedMissions,
  appliedMissions,
  favouriteMissions,
  alertMissions,
  AccountCreation,
  calledByAdvisor,
  financeProfessionals,
  candidateAdvices
};

export default urls;
