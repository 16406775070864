import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { t, Trans } from "@lingui/macro";
import { GET_FINANCE_SPECIALTIES } from "../../missions-page/queries";
import { Query } from "react-apollo";
import { Button, Col, InputSkeleton } from "cf-neo-ui";
import LocationWidget from "../components/widgets/LocationWidget";
import SkillsWidget from "../components/widgets/SkillsWidget";
import { H1 } from "cf-neo-ui/lib/typography";

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      skills: [],
      location: {}
    };
    this.addSkill = this.addSkill.bind(this);
    this.closeTagHandler = this.closeTagHandler.bind(this);
    this.selectLocationHandler = this.selectLocationHandler.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  selectLocationHandler = (address, formattedAddress) => {
    this.setState({ location: formattedAddress });
  };

  addSkill(val) {
    const { skills } = this.state;
    skills.push(val);
    this.setState({ skills: skills });
  }

  closeTagHandler(val) {
    const { skills } = this.state;
    const { missionsSearchStore } = this.props;
    var arr = skills.slice();
    const index = arr.indexOf(val);
    if (index > -1) {
      arr.splice(index, 1);
    }
    this.setState({ skills: arr });
    missionsSearchStore.removeSkill(val);
    missionsSearchStore.setSkillOptions();
  }

  clickHandler() {
    const { skills, location } = this.state;
    const { missionsSearchStore, history } = this.props;
    missionsSearchStore.initLocation();
    missionsSearchStore.initSkills();
    skills?.forEach(value => {
      missionsSearchStore.addSkill(value);
    });

    missionsSearchStore.setSkillOptions();
    const active =
      typeof window !== "undefined" &&
      window.location.search.substring(
        window.location.search.lastIndexOf("?") + 1
      );

    if (Object.keys(location).length !== 0) {
      missionsSearchStore.addLocation(location);
    }

    history.push(`/missions?${active}`);
  }

  render() {
    const { i18n, history } = this.props;
    const { key, skills } = this.state;

    const active =
      typeof window !== "undefined" &&
      window.location.search.substring(
        window.location.search.lastIndexOf("?") + 1
      );
    return (
      <div>
        <H1 className={classes.mainTextSec1}>
          <Trans>
            Valorisez votre expertise en finance, suivez le chemin de la
            réussite
          </Trans>
        </H1>

        <Col xl={12} lg={12} md={12} sm={12} xs={6} className={classes.bigCol}>
          <Col
            xl={7}
            lg={8}
            md={8}
            sm={8}
            xs={6}
            className={classes.noPaddingLeftSec1}
          >
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={6}
              className={classes.blockinputs}
            >
              <Col
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={6}
                className={classes.widgetBodySection1}
              >
                <Query query={GET_FINANCE_SPECIALTIES}>
                  {({ loading, error, data }) => {
                    if (loading) return <InputSkeleton />;
                    if (error) return `${i18n._(t`Erreur`)} !`;
                    const { specialtiesFinance: specialtiesData } = data;
                    return (
                      <SkillsWidget
                        key={key}
                        skills={skills}
                        addSkill={this.addSkill}
                        closeTagHandler={this.closeTagHandler}
                        options={specialtiesData.map(item => item.name)}
                      />
                    );
                  }}
                </Query>
              </Col>
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={6}
                className={classes.widgetBodySection1}
              >
                <LocationWidget
                  selectLocationHandler={this.selectLocationHandler}
                  key={key}
                />
              </Col>
              <Col
                xl={4}
                lg={5}
                md={5}
                sm={12}
                xs={6}
                className={classes.widgetBodySection1}
              >
                <Button
                  size="medium"
                  icon="search"
                  iconPosition="right"
                  iconColor="white"
                  variant="quaternary"
                  iconTop="calc(50% - 10.5px)"
                  onClick={() => this.clickHandler()}
                  classNameText={
                    "height: 53px; width: 100%; background: #242424; background-size: 101% 101%;color: #FFF; box-shadow: 0px 4px 4px 0px rgba(136, 136, 136, 0.25);border-radius: 5px;> span { font-family: Montserrat;  margin: 0px;;font-size: 16px;font-style: normal;font-weight: 600;line-height: 26px;letter-spacing: 0.3px;}"
                  }
                >
                  <Trans>Chercher une offre</Trans>
                </Button>
              </Col>
            </Col>
            <Col
              xl={9}
              lg={9}
              md={9}
              sm={9}
              xs={6}
              className={classes.blockButtonSec1}
            >
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={3.3}
                className={classes.noPaddingLeft}
              >
                <Button
                  size="medium"
                  variant="quaternary"
                  classNameText={
                    "height: 60px; background: transparent; background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px;border: 2px solid white;&:hover { background: white; color: black;}; > span {font-family: Montserrat; margin: 0px; font-size: 13px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                  }
                  onClick={() => history.push("/signup?type=consultant")}
                >
                  <Trans>Créer un compte candidat</Trans>
                </Button>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={2.7}>
                <Button
                  size="medium"
                  variant="quaternary"
                  classNameText={
                    "height: 60px; background: transparent; background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px;border: 2px solid white; &:hover { background: white; color: black;}; > span { font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                  }
                  onClick={() => history.push("/login")}
                >
                  <Trans>Se connecter</Trans>
                </Button>
              </Col>
            </Col>
          </Col>
          <Col xl={5} lg={5} md={4} sm={4} xs={6}>
            <picture>
              <source type="images/png" srcSet="/assets/images/rectangle.png" />
              <img
                className={classes.imgSec}
                src="/assets/images/rectangle.png"
                alt="Logo_Mindquest_Finance_3D"
              />
            </picture>
          </Col>
        </Col>
      </div>
    );
  }
}

Section1.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section1));
