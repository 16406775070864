import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { t, Trans } from "@lingui/macro";
import { Button, Col, H2, TestimonyCard } from "cf-neo-ui";
@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section8 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { history } = this.props;
    return (
      <div className={classes.section3}>
        <H2 className={classes.mainText}>
          <Trans>Témoignages</Trans>
        </H2>
        <Col xl={12} lg={12} md={12} sm={12} xs={6} className={classes.bigCol}>
          <TestimonyCard
            listChoice={[
              {
                nameChoice: "Talents",
                listTestimony: [
                  {
                    name: "Guissé",
                    nbStars: 5,
                    commentaire: <Trans>Parfait</Trans>
                  },
                  {
                    name: "Christine",
                    nbStars: 5,
                    commentaire: (
                      <Trans>
                        Une vraie préparation, un accompagnement en entretien
                        qui permet d'être serein et rassurant face au client, de
                        grands sourires et surtout beaucoup de bienveillance.
                      </Trans>
                    )
                  },
                  {
                    name: "Papa Abdoul",
                    nbStars: 5,
                    commentaire: <Trans>Service parfait.</Trans>
                  },
                  {
                    name: "Murielle",
                    nbStars: 5,
                    commentaire: (
                      <Trans>
                        Je suis très contente de l'agilité de vos services et du
                        sens des missions/métiers pour coller aux besoins
                        clients et aux capacités prestataires.
                      </Trans>
                    )
                  },
                  {
                    name: "Abou Ly",
                    nbStars: 5,
                    commentaire: <Trans>Service très bon.</Trans>
                  },
                  {
                    name: "Été",
                    nbStars: 5,
                    commentaire: (
                      <Trans>
                        Processus de recrutement est clair et le suivi est très
                        bien fait.
                      </Trans>
                    )
                  },
                  {
                    name: "Ibrahim",
                    nbStars: 5,
                    commentaire: (
                      <Trans>Mieux que Mindquest je n'ai pas vu.</Trans>
                    )
                  }
                ]
              }
            ]}
          ></TestimonyCard>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          className={classes.colbuttonToBlog}
          xs={6}
        >
          <Button
            size="medium"
            variant="quaternary"
            classNameText={
              "height: 60px; background: linear-gradient(76deg, #8d0417,#d3354a);background-size: 101% 101%; color: #ffffff;> span { margin: 0px;;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
            }
            className={classes.buttonToBlog}
            onClick={() => {
              history.push("/blog/news/25492");
            }}
          >
            <Trans>Découvrir les success stories de notre communauté</Trans>
          </Button>
        </Col>
      </div>
    );
  }
}

Section8.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section8));
