import { t } from "@lingui/macro";
import runtimeVars from "./runTimeVars";

/**
 * configure only static header pages
 * for seeOffers any string entered will be concatenated by the sourcer name
 * pass null to any props you would like it to take the default values stores in "utils/editMetaTags"
 * */

const ADDRESS = runtimeVars.FRONTEND_BASE_URL;
const staticPagesMetaTags = i18n => ({
  home: {
    title: i18n._(
      t`Mindquest - Connecter les meilleurs talents aux meilleures offres IT`
    ),
    description: i18n._(
      t`Avec Mindquest, trouvez votre prochaine offre Tech & IT en CDI ou Freelance et recrutez les meilleurs consultants Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest.jpg`
    },
    keywords: i18n._(
      t`Club Freelance, Freelance informatique, consultants Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP`
    )
  },
  consultant: {
    title: i18n._(
      t`Mindquest - Trouvez votre prochain job, et accélérez dans votre carrière IT`
    ),
    description: i18n._(
      t`Avec Mindquest, trouver votre prochaine opportunité Tech & IT en CDI ou freelance (Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/rejoignez-mindquest.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/rejoignez-mindquest.jpg`
    },
    keywords: i18n._(
      t`Freelance informatique, consultant IT, mission freelance, mission sap, mission développement, mission data`
    )
  },
  notFound: {
    title: i18n._(t`Mindquest - Not Found`),
    description: i18n._(t`Not Found`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: i18n._(t`Not found`)
  },
  client: {
    title: i18n._(
      t`Mindquest - Trouvez le talent idéal pour résoudre vos défis IT`
    ),
    description: i18n._(
      t`Avec Mindquest, recrutez les meilleurs experts IT en CDI ou freelance (Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/rejoignez-mindquest-postez-un-besoin.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/rejoignez-mindquest-postez-un-besoin.jpg`
    },
    keywords: i18n._(
      t`Club Freelance, talent management, freelance informatique, trouver un freelance`
    )
  },
  talentPool: {
    title: i18n._(t`Mindquest`),
    description: i18n._(
      t`Avec Mindquest, recrutez les meilleurs experts IT en CDI ou freelance (Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/rejoignez-mindquest-postez-un-besoin.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/rejoignez-mindquest-postez-un-besoin.jpg`
    },
    keywords: i18n._(
      t`Club Freelance, talent management, freelance informatique, trouver un freelance`
    )
  },
  missions: {
    title: i18n._(
      t`Mindquest - Trouvez votre prochain job IT en CDI ou freelance`
    ),
    description: i18n._(
      t`Consultez nos offres CDI ou missions freelance pour consultants tech et trouvez votre prochain job IT : Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle...`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-trouver-des-missions.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-trouver-des-missions.jpg`
    },
    keywords: i18n._(
      t`missions freelance, Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle`
    )
  },
  calledByAdvisor: {
    title: i18n._(
      t`Mindquest - Etre recontacté par l'un de nos expert en recrutement`
    ),
    description: i18n._(
      t`Soyez recontacté par l'un de nos expert en recrutement : un expert Mindquest vous appellera pour échanger sur votre besoin et vous accompagner dans la création de votre compte entreprise.`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  sourcerOffers: {
    title: i18n._(t`Talent Community Specialist`),
    description: i18n._(
      t`Découvrez toutes les offres de votre Talent Community Speciallist`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: i18n._(
      t`missions, Talent Community Specialist, Freelance informatique`
    )
  },
  contact: {
    title: i18n._(t`Mindquest - Nous contacter`),
    description: i18n._(
      t`Vous avez un besoin en compétences informatiques (talent it, consultant informatique) ? Vous cherchez une mission ou un job ? N’hésitez pas à nous contacter.`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-contact.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-contact.jpg`
    },
    keywords: i18n._(t`contact, club freelance, freelance informatique`)
  },
  privacyPolicy: {
    title: i18n._(t`Mindquest - Notre politique de confidentialité`),
    description: i18n._(
      t`Votre partenaire pour donner de l’élan à vos projets ou à votre carrière Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. Découvrez notre politique de confidentialité.`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-privacy-policy.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-privacy-policy.jpg`
    },
    keywords: i18n._(t`club freelance, privacy policy`)
  },
  siteMap: {
    title: i18n._(t`Mindquest - Plan du site`),
    description: i18n._(
      t`Votre partenaire pour donner de l’élan à vos projets ou à votre carrière Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. Découvrez notre politique de confidentialité.`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: i18n._(t`club freelance, privacy policy`)
  },
  termsOfUse: {
    title: i18n._(t`Mindquest - Nos conditions Générales d'Utilisation`),
    description: null,
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-privacy-policy.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-privacy-policy.jpg`
    },
    keywords: i18n._(
      t`Votre partenaire pour donner de l’élan à vos projets ou à votre carrière Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. Découvrez nos Conditions Générales d'Utilisation.`
    )
  },
  financeConsultant: {
    title: i18n._(t`Emploi Finance Freelance ou CDI - Mindquest Finance`),
    description: i18n._(
      t`Accélérez sur votre carrière Finance en freelance ou en CDI avec Mindquest. Obtenez les offres d'emploi ou de mission finance à forte valeur ajouté.`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl:
        "https://mindquest.io/assets/images/openGraph/openGraph-facebook/mindquest.jpg",
      linkedInOpenGraphImageUrl:
        "https://mindquest.io/assets/images/openGraph/openGraph-facebook/mindquest.jpg"
    },
    keywords: i18n._(
      t`Club Freelance, talent management, freelance informatique, trouver un freelance`
    )
  },
  financeClient: {
    title: i18n._(
      t`Recruter un expert financier en Freelance ou CDI - Mindquest Finance`
    ),
    description: i18n._(
      t`Les meilleurs talents Finance en freelance ou en CDI avec Mindquest, cabinet de recrutement finance. Rencontrez les candidats les plus qualifiés`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl:
        "https://mindquest.io/assets/images/openGraph/openGraph-facebook/mindquest.jpg",
      linkedInOpenGraphImageUrl:
        "https://mindquest.io/assets/images/openGraph/openGraph-facebook/mindquest.jpg"
    },
    keywords: i18n._(
      t`Club Freelance, talent management, freelance informatique, trouver un freelance`
    )
  },
  about: {
    title: i18n._(
      t`Mindquest - Découvrez le match parfait entre entreprises et talents IT`
    ),
    description: i18n._(
      t`Mindquest connecte les talents aux meilleures offres IT et accompagne les entreprises dans le recrutement du talent idéal pour résoudre les défis IT. Talents CDI ou consultants freelance.`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-about.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-about.jpg`
    },
    keywords: i18n._(
      t`Club Freelance, talent management, freelance informatique, trouver un freelance`
    )
  },
  dashboard: {
    title: i18n._(t`Mindquest | Dashboard`),
    description: i18n._(t`user dashboard`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  resume: {
    title: i18n._(t`Mindquest | Mon CV`),
    description: i18n._(t`user dashboard`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  details: {
    title: i18n._(t`Mindquest | Mes Coordonnées`),
    description: i18n._(t`user dashboard`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  params: {
    title: i18n._(t`Mindquest | Mes Paramètres`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  job: {
    title: i18n._(t`Mindquest | Mon métier`),
    description: i18n._(t`user dashboard`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  experience: {
    title: i18n._(t`Mindquest | Mon expérience`),
    description: i18n._(t`user dashboard`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  training: {
    title: i18n._(t`Mindquest | Ma formation`),
    description: i18n._(t`user dashboard`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  search: {
    title: i18n._(t`Mindquest | Ma recherche`),
    description: i18n._(t`user dashboard`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  recommendedMissions: {
    title: i18n._(t`Mindquest | Offres recommandées`),
    description: null,
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  appliedMissions: {
    title: i18n._(t`Mindquest | Mes candidatures`),
    description: null,
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  favouriteMissions: {
    title: i18n._(t`Mindquest | Favoris`),
    description: null,
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  alertMissions: {
    title: i18n._(t`Mindquest | Mes alertes`),
    description: null,
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  dashboardCompany: {
    title: i18n._(t`Dashboard Enterprise`),
    description: i18n._(t`Acceder à votre espace entreprise`),
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: i18n._(
      t`dashboard, club freelance, entreprise, talents, recrutement, `
    )
  },
  candidates: {
    title: i18n._(
      t`Mindquest - Trouvez votre futur talent IT avec notre algorithme SMATCH`
    ),
    description: i18n._(
      t`Utilisez notre algorithme SMATCH pour recruter les meilleurs talents Tech & IT en CDI ou Freelance (Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...).`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-candidates.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-candidates.jpg`
    },
    keywords: null
  },
  signUp: {
    consultant: {
      title: i18n._(t`Mindquest - Créer un compte talent`),
      description: i18n._(
        t`Rejoindre la communauté Mindquest - Créer un compte talent pour être connecter aux meilleurs offres IT, en CDI ou freelance - Le match parfait entre entreprises et talents IT.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/talent-join-the-quest.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/talent-join-the-quest.jpg`
      },
      keywords: i18n._(
        t`sign up, club freelance, communauté, compte, Freelances IT`
      )
    },
    client: {
      title: i18n._(t`Mindquest - Créer un compte entreprise`),
      description: i18n._(
        t`Rejoindre la communauté Mindquest - Créer un compte entreprise pour trouver le talent idéal pour résoudre les défis IT - Le match parfait entre entreprises et talents IT.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/rejoignez-mindquest-postez-un-besoin.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/rejoignez-mindquest-postez-un-besoin.jpg`
      },
      keywords: i18n._(
        t`sign up, club freelance, communauté, compte, Freelances IT`
      )
    }
  },
  login: {
    title: i18n._(t`Mindquest - Se connecter à mon espace Mindquest`),
    description: i18n._(
      t`Connectez-vous à votre compte Mindquest pour trouver votre prochaine offre Tech & IT en CDI ou Freelance ou recruter les meilleurs consultants Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...`
    ),
    openGraphImage: {
      facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-login.jpg`,
      linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-login.jpg`
    },
    keywords: i18n._(t`login, club freelance, account, connexion`)
  },
  signUpThanks: {
    title: i18n._(t`Congrats`),
    description: null,
    openGraphImage: {
      facebookOpenGraphImageUrl: null,
      linkedInOpenGraphImageUrl: null
    },
    keywords: null
  },
  blog: {
    main: {
      title: i18n._(
        t`Restez connectés avec le secteur de Tech & IT - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez nos actualités, nos fiches métiers, notre sélection d'articles pour talents et consultants IT, décideurs et recruteurs IT et tous nos webinars et ressources pour recruter le profil ou trouver la mission informatique idéal.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-fr.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-fr.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    news: {
      title: i18n._(t`Club Freelance Insights | L’actualité des talents IT`),
      description: i18n._(
        t`Retrouvez toute l’actualité de Club Freelance et notre sélection d’articles pour les Freelances informatiques et décideurs IT.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-nos-actualités.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-nos-actualités.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    decryption: {
      title: i18n._(
        t`Club Freelance Insights | Décryptages des métiers de l’IT`
      ),
      description: i18n._(
        t`Découvrez les tendances et évolution des métiers de l’informatique & du digital à l’heure du future of work. Fiches métiers détaillées et analyses.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    advicesConsultant: {
      title: i18n._(
        t`Conseils Pratiques pour Talents Tech & IT - Mindquest Connect`
      ),
      description: i18n._(
        t`Découvrez tous nos conseils pour talent et consultants IT et points de vue d’experts pour réussir dans votre activité dans la tech et l'informatique et décrocher les meilleures opportunités en freelance ou CDI.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-advices-consultant.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-advices-consultant.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, Conseils pratiques, consultant IT, freelance informatique`
      )
    },
    advicesEnterprise: {
      title: i18n._(t`Conseils Pratiques pour entreprise - Mindquest Connect`),
      description: i18n._(
        t`Découvrez tous nos conseils pratiques pour décideurs IT afin de travailler efficacement avec des Talents tech & IT.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-advices-enterprise.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-advices-enterprise.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, Conseils pratiques, entreprise IT, freelance informatique`
      )
    },
    successStories: {
      title: i18n._(t`Club Freelance Insights | Success `),
      description: i18n._(
        t`Découvrez les tendances et évolution des métiers de l’informatique & du digital à l’heure du future of work. Fiches métiers détaillées et analyses.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    search: {
      title: i18n._(t`Rechercher un article sur le blog - Mindquest Connect`),
      description: i18n._(
        t`Recherchez un article sur le blog parmi notre sélection de contenus, actualités, conseil pratiques et ressources pour Talents et Décideurs IT.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-recherche.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-recherche.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, recherche, freelance informatique`
      )
    },
    // new blog
    DecisionMakers: {
      title: i18n._(
        t`Restez connectés aux actualités et derniers conseils pour Décideurs IT - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT (Stratégie des talents, Recruter un consultant informatique, Risques & erreurs à éviter, Légal, Enjeux DSI...).`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-it-decision-makers.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-it-decision-makers.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    TalentStrategy: {
      title: i18n._(
        t`Articles Décideurs IT - La stratégie des talents - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur la stratégie des talents sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-it-decision-makers-talent-strategy.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-it-decision-makers-talent-strategy.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    RecruitITConsultant: {
      title: i18n._(
        t`Articles Décideurs IT - Recruter un consultant informatique - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur le recrutement de consultants et talents informatiques sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    Risks: {
      title: i18n._(
        t`Articles Décideurs IT - Les risques & erreurs à éviter - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur les risques et les erreurs à éviter sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    Legal: {
      title: i18n._(t`Articles Décideurs IT - Légal - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur le légal sur notre blog Mindquest Connect`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-it-decision-makers-legal.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-it-decision-makers-legal.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    Dsi: {
      title: i18n._(t`Articles Décideurs IT - Enjeux DSI - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur les enjeux DSI sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-dsi-challenges.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-dsi-challenges.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    freelanceTech: {
      title: i18n._(
        t`Restez connectés aux actualités et derniers conseils pour Consultants Tech & IT - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et talents en freelance dans l'informatique.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-job-description-web-developper.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-job-description-web-developper.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    BecomeFreelancer: {
      title: i18n._(
        t`Articles Consultants IT - Devenir freelance - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants IT sur comment devenir freelance sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-freelance-tech-devenir-freelance.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-freelance-tech-devenir-freelance.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    DailyFreelance: {
      title: i18n._(
        t`Articles Consultants IT - Quotidien de freelance - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants IT sur le quotidien d'un freelance sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-freelance-tech-quotidien-de-freelance.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-freelance-tech-quotidien-de-freelance.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Finance: {
      title: i18n._(
        t`Articles Consultants IT - Se financer - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur comment se financer sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-freelance-tech-se-financer.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-freelance-tech-se-financer.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Career: {
      title: i18n._(
        t`Articles Consulants IT - Développer sa carrière : CDI & freelance - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants IT sur le développement de sa carrière en CDI ou freelance sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-freelance-tech-carreer.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-freelance-tech-carreer.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    AdminAndLegal: {
      title: i18n._(
        t`Articles Consultants IT - Admin & Légal freelance - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur l'admin & le légal sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-freelance-tech-admin-_-légal.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-freelance-tech-admin-_-légal.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Accounting: {
      title: i18n._(
        t`Articles Consultants IT - Comptabilité en freelance - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants IT sur la comptabilité en freelance sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-freelance-tech-comptabilité.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-freelance-tech-comptabilité.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Taxation: {
      title: i18n._(
        t`Articles Consultants IT - Fiscalité, impôts & retraite en freelance - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur la fiscalité, les impôts à la retraite sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-freelance-tech-fiscalité-impots-_-retraite.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-freelance-tech-fiscalité-impots-_-retraite.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    WagePortage: {
      title: i18n._(
        t`Articles Consultants IT - Portage salarial en freelance - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur le portage salarial sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-portage-salarial.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-portage-salarial.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    OurPartners: {
      title: i18n._(
        t`Articles Consultants IT - Nos partenaires - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur nos partenaires sur notre blog Mindquest Connect.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-nos-partenaires.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-nos-partenaires.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    jobSearchAdviceEn: {
      title: i18n._(t`Job search advice -  Mindquest Connect`),
      description: i18n._(
        t`Find all the news from Mindquest and our advice on IT recruitment and Job search advice.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    jobAppAdviceEn: {
      title: i18n._(t`Job application advice -  Mindquest Connect`),
      description: i18n._(
        t`Find all the latest news from Mindquest and our selection of articles about job application advices for IT Consultants.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    tipsDailyEn: {
      title: i18n._(
        t`Tips for your daily life as a freelancer - Mindquest Connect`
      ),
      description: i18n._(
        t`Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer .`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-it-decision-makers-tips-and-errors-to-avoid.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-it-decision-makers-tips-and-errors-to-avoid.jpg`
      },
      keywords: i18n._(
        t`Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks`
      )
    },
    BuisinessFile: {
      title: i18n._(t`Nos fiches métiers - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers tech & IT (Développement, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Developpement: {
      title: i18n._(t`Fiches métiers Développement - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers du Développement.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Infra: {
      title: i18n._(t`Fiches métiers Infrastructure IT - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers d'Infrastructure IT.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Erp: {
      title: i18n._(t`Fiches métiers ERP & SAP - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers d'ERP & SAP.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-job-description-erp-and-sap.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-job-description-erp-and-sap.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    ProjectManagement: {
      title: i18n._(t`Fiches métiers Project Management - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers de Project Management.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-job-description-project-manager.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-job-description-project-manager.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    BusinessIntelligence: {
      title: i18n._(
        t`Fiches métiers Data & Business Intelligence - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers de Data & Business Intelligence.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-job-description-data-and-business-intelligence.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-job-description-data-and-business-intelligence.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Digital: {
      title: i18n._(t`Fiches métiers Digital - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers du Digital.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-job-description-digital.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-job-description-digital.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    AboutUs: {
      title: i18n._(t`On parle de nous - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaître nos actualités, nos communiqués de presse et nos témoignages.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-about-us.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-about-us.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    actuality: {
      title: i18n._(t`On parle de nous - Nos actualités - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaitre les dernières actualités de notre marque et nos derniers services...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-nos-actualités.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-nos-actualités.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    pressRelease: {
      title: i18n._(
        t`On parle de nous - Communiqués de Presse - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaitre les dernières actualités de notre marque et nos derniers communiqués de presse.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-communiqués-de-presse.png`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-communiqués-de-presse.png`
      },
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    temoignagesAboutUS: {
      title: i18n._(t`On parle de nous - Témoignages - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaitre les dernières actualités de notre marque et les derniers témoignagnes de nos collaborateurs.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-témoignages.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-témoignages.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    podcastAboutUS: {
      title: i18n._(t`Club Freelance Insights | Podcast`),
      description: i18n._(
        t`Découvrez les tendances et évolution des métiers de l’informatique & du digital à l’heure du future of work. Fiches métiers détaillées et analyses.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    captainLogAboutUS: {
      title: i18n._(t`Captains’s Log - Mindquest Connect`),
      description: i18n._(
        t`Find all the news from Mindquest on our Captains's Log`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    Webinars: {
      title: i18n._(t`Webinars & Ressources - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos sélections d'articles pour Webinars & Ressources.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    WebconsultantIT: {
      title: i18n._(
        t`Webinars & Ressources - Contenu pour Consultants IT - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-contenu-pour-consultants-it.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-contenu-pour-consultants-it.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    webmakersIT: {
      title: i18n._(
        t`Webinars & Ressources - Contenu pour Décideurs IT - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toutes nos ressources et contenus pour décideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-contenu-pour-décideurs-it.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-contenu-pour-décideurs-it.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    Replay: {
      title: i18n._(t`Webinars & Ressources - Replays - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez tous les replays de nos webinars pour décideurs et consultants IT présentés par Mindquest et ses partenaires comme l'Expert-Comptable.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-and-replays.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-and-replays.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReview: {
      title: i18n._(t`Revues de Presse : DSI - Mindquest Connect`),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReviewDsi: {
      title: i18n._(
        t`Webinars & Ressources - Revues de Presse : DSI - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-revue-de-presse-dsi.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-revue-de-presse-dsi.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReviewDevelppement: {
      title: i18n._(
        t`Webinars & Ressources - Revues de Presse : Développement - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-revue-de-presse-développement.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-revue-de-presse-développement.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReviewBusinessIntelligence: {
      title: i18n._(
        t`Webinars & Ressources - Revues de Presse : Intelligence Artificielle - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-revue-de-presse-intelligence-artificielle.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-revue-de-presse-intelligence-artificielle.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReviewCybersecurity: {
      title: i18n._(
        t`Webinars & Ressources - Revues de Presse : Cybersécurité - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-revue-de-presse-cybersécurité.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-revue-de-presse-cybersécurité.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReviewRecrutement: {
      title: i18n._(
        t`Webinars & Ressources - Revues de Presse : Recrutement & IT - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-revue-de-presse-recrutement-_-it.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-revue-de-presse-recrutement-_-it.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReviewErp: {
      title: i18n._(
        t`Webinars & Ressources - Revues de Presse : Data & ERP - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-revue-de-presse-data-_-erp.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-revue-de-presse-data-_-erp.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    PressReviewCloud: {
      title: i18n._(
        t`Webinars & Ressources - Revues de Presse : Cloud - Mindquest Connect`
      ),
      description: i18n._(
        t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-webinars-revue-de-presse-cloud.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-webinars-revue-de-presse-cloud.jpg`
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    techmagazineEn: {
      title: i18n._(t`Our Tech Magazine -  Mindquest Connect`),
      description: i18n._(
        t`Discover all the latest news from Mindquest and our selection of articles for Tech Magazine (Cybersecurity, ERP &SAP, Artificial Intelligence, Digital).`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    pressReviewEn: {
      title: i18n._(t`Press review - Mindquest Connect`),
      description: i18n._(
        t`Find all the latest news from Mindquest and our selection of press reviews on our Tech Magazine`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    cybersecurityEn: {
      title: i18n._(t`Cybersecurity Content - Mindquest Connect`),
      description: i18n._(
        t`Find all the latest news from Mindquest and our selection of articles about Cybersecurity on our Tech Magazine.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    topTenEn: {
      title: i18n._(t`TOP 10 experts' articles -  Mindquest Connect`),
      description: i18n._(
        t`Find all the latest Mindquest news and our selection of Tech Magazine articles with the top 10 IT experts lists.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    CandidateAdvices: {
      title: i18n._(
        t`Nos articles pour candidats à la recherche d'opportunités`
      ),
      description: i18n._(
        `Retrouvez toute l'actualité de Mindquest et notre sélection d'articles de conseils pour les candidats à la recherche d'opportunités professionnelles`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: ""
    },
    FinancePro: {
      title: i18n._(t`Nos articles pour talents et experts de la finance`),
      description: i18n._(
        `Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour talents et experts de la finance à la recherche d'emploi dans la finance`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: null,
        linkedInOpenGraphImageUrl: null
      },
      keywords: ""
    }
  },
  blogEn: {
    main: {
      title: i18n._(
        t`Stay connected with the Tech & IT sector - Mindquest Connect`
      ),
      description: i18n._(
        t`Discover our news, our job descriptions, our selection of articles for IT talents and consultants, decision-makers and IT recruiters, and all our webinars and resources to recruit the ideal IT profile or find the ideal IT assignment.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-fr.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-fr.jpg`
      },
      keywords: i18n._(
        t`Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi`
      )
    },
    search: {
      title: i18n._(
        t`Search for a blog post from our selection of content, news, practical advice and resources for IT Talent and Decision Makers.`
      ),
      description: i18n._(
        t`Find a blog post from our selection of content, news, how-to's and resources for IT Talent and Decision Makers.`
      ),
      openGraphImage: {
        facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-recherche.jpg`,
        linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-recherche.jpg`
      },
      keywords: i18n._(
        t`blog, club freelance, recherche, freelance informatique`
      )
    },
    advices: {
      consultant: {
        title: i18n._(
          t`Practical Tips for Tech & IT Talent - Mindquest Connect`
        ),
        description: i18n._(
          t`Discover all our tips for IT talent and consultants and expert views to succeed in your tech and IT business and find the best freelance or permanent opportunities.`
        ),
        openGraphImage: {
          facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-advices-consultant.jpg`,
          linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-advices-consultant.jpg`
        },
        keywords: i18n._(
          t`Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi`
        )
      },
      enterprise: {
        title: i18n._(
          t`Practical Tips for IT decision maker - Mindquest Connect`
        ),
        description: i18n._(
          t`Discover all our practical tips for IT decision makers to effectively work with tech & IT talent.`
        ),
        openGraphImage: {
          facebookOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-facebook/mindquest-blog-advices-enterprise.jpg`,
          linkedInOpenGraphImageUrl: `${ADDRESS}/assets/images/openGraph/openGraph-linkedIn/mindquest-blog-advices-enterprise.jpg`
        },
        keywords: i18n._(
          t`Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi`
        )
      }
    }
  }
});

export default staticPagesMetaTags;
