import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Row, Col } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import $ from "jquery";
import FreelanceCard from "../../../components/FreelanceCard";
import EntrepriseCard from "../../../components/EntrepriseCard";
import "./style.scss";
import classes from "./CardSection.module.scss";
import carouselConfig from "./CarouselConfig";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

@inject("appStore")
@observer
class CardSection extends Component {
  state = {
    render: false
  };

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    const { render } = this.state;
    if (!render) return null;

    const { appStore } = this.props;
    const { currentLanguage } = appStore;

    if (typeof window !== "undefined") {
      window.$ = $;
      window.jQuery = $;
    }

    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    return (
      <div className={classes.card}>
        <Row>
          {currentLanguage == "fr" ? (
            <video
              src="/assets/finance_banner_fr.mp4"
              alt="Mindquest"
              className={classes.banner_cf_to_mq}
              width={"100%"}
              height={"100%"}
              autoPlay
              loop
              muted
            />
          ) : (
            <video
              src="/assets/banner_new_mq_en.mp4"
              alt="Mindquest"
              className={classes.banner_cf_to_mq}
              width={"100%"}
              height={"100%"}
              autoPlay
              loop
              muted
            />
          )}
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} noGutter>
            <Fade>
              <h1 className={classes.text}>
                <Trans>Le talent idéal pour résoudre les défis IT.</Trans>
              </h1>
              <h2 className={classes.subtext}>
                <Trans>
                  Nous connectons les meilleurs experts aux meilleures offres
                  pour un match parfait entre entreprises et talents
                  informatiques.
                </Trans>
              </h2>
            </Fade>
          </Col>
        </Row>

        {appStore.width < 840 ? (
          <div className={classes.carousel}>
            <OwlCarousel {...carouselConfig}>
              <div className="item" key={this.key}>
                <FreelanceCard />
              </div>
              <div className="item" key={this.key}>
                <EntrepriseCard />
              </div>
            </OwlCarousel>
          </div>
        ) : (
          <Row className={classes.row}>
            <div className={classes.cardleft}>
              <FreelanceCard />
            </div>

            <div className={classes.cardright}>
              <EntrepriseCard />
            </div>
          </Row>
        )}
      </div>
    );
  }
}

export default withI18n()(CardSection);
