import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Button, Col, H2, H3, Icon } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
const data = [
  {
    company: <Trans>Portage salarial</Trans>,
    images: [
      {
        alt: "logo_partenaire_portify",
        url: "/assets/images/potify.png",
        link:
          "https://www.orus.eu/partnerships/mindquest?utm_source=Mindquest&utm_medium=Partner&utm_campaign=Blog"
      },
      {
        alt: "logo_partenaire_axessio",
        url: "/assets/images/axessio.png",
        link:
          "https://www.orus.eu/partnerships/mindquest?utm_source=Mindquest&utm_medium=Partner&utm_campaign=Blog"
      },
      {
        alt: "logo_partenaire_coalise",
        url: "/assets/images/coalise.png",
        link:
          "https://www.orus.eu/partnerships/mindquest?utm_source=Mindquest&utm_medium=Partner&utm_campaign=Blog"
      }
    ]
  },
  {
    company: <Trans>Assurance</Trans>,
    images: [
      {
        alt: "logo_partenaire_orus",
        url: "/assets/images/orus.png",
        link:
          "https://www.orus.eu/partnerships/mindquest?utm_source=Mindquest&utm_medium=Partner&utm_campaign=Blog"
      }
    ]
  },
  {
    company: <Trans>Banque freelance</Trans>,
    images: [
      {
        alt: "logo_partenaire_blank",
        url: "/assets/images/blank.png",
        link:
          "https://landing.blank.app/mindquest?utm_medium=partnership&utm_source=emailing&utm_campaign=mindquest"
      },
      {
        alt: "logo_partenaire_mon_petit_placement",
        url: "/assets/images/petitplacement.png"
      }
    ]
  },
  {
    company: <Trans>Comptabilité / Juridique</Trans>,
    images: [
      {
        alt: "logo_partenaire_expert_comptable",
        url: "/assets/images/lexpertcomptable.png",
        link: "https://get.l-expert-comptable.com/club-freelance"
      },
      {
        alt: "logo_partenaire_legalstart",
        url: "/assets/images/legalstart.png",
        link: "https://visite.mindquest.io/partenariat-legalstart"
      },
      {
        alt: "logo_partenaire_indy",
        url: "/assets/images/indy.png",
        link: "https://www.indy.fr/l/mindquest"
      }
    ]
  },
  // {
  //   company: <Trans>Juridique</Trans>,
  //   images: [
  //     {
  //       alt: "logo_partenaire_expert_comptable",
  //       url: "/assets/images/lexpertcomptable.png"
  //     },
  //     {
  //       alt: "logo_partenaire_legalstart",
  //       url: "/assets/images/legalstart.png"
  //     },
  //     { alt: "logo_partenaire_indy", url: "/assets/images/indy.png" }
  //   ]
  // }
  {
    company: <Trans>Productivité</Trans>,
    images: [
      { alt: "logo_partenaire_canva", url: "/assets/images/canva.png" },
      { alt: "logo_partenaire_wework", url: "/assets/images/wework.png" }
    ]
  }
];
@inject("sessionStore", "appStore", "modalStore", "postANeedStore")
@observer
class Section4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }
  handleClick(index) {
    this.setState({
      index: index
    });
  }
  handleClickImage(link) {
    window.open(link);
  }
  render() {
    const { appStore } = this.props;

    const { currentLanguage } = appStore;

    return (
      <div className={classes.section3}>
        <H2 className={classes.mainText}>
          <Trans>Un réseau de partenaires pour un accompagnement complet</Trans>
        </H2>
        <div className={classes.textInside}>
          <Trans>
            Nous avons créé un écosystème complet de partenaires au service des
            talents et des entreprises pour un accompagnement personnalisé de A
            à Z.
          </Trans>
        </div>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.bigColsec4}
        >
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={6}
            id="bigCol"
            className={classes.bigCol}
          >
            <ul className={classes.ulStyle}>
              {data.map((item, index) => {
                return (
                  <li
                    className={classes.section4Li}
                    onClick={() => this.handleClick(index)}
                  >
                    <div>
                      <Icon size="small" type="lock" color="white" />
                    </div>
                    <H3
                      className={classes.itemCompany}
                      style={{
                        fontWeight:
                          index == this.state.index ? "bold" : "normal"
                      }}
                    >
                      <Trans>{item.company} </Trans>
                    </H3>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={6}
            className={classes.bigColsec}
          >
            <div className={classes.imageContainer}>
              {data[this.state.index].images.map((image, indexImage) => {
                return (
                  <div className={classes.firstimage}>
                    <img
                      className={classes.imgEntreprise}
                      alt={image?.alt}
                      src={image?.url}
                      onClick={() => {
                        this.handleClickImage(image?.link);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </Col>

        <Col
          xl={7}
          lg={7}
          md={7}
          sm={7}
          xs={6}
          className={classes.widgetBodySec5}
        >
          <Button
            size="medium"
            variant="quaternary"
            classNameText={
              "width: 100%;margin-top: -9%; margin-left: 5%;letter-spacing: 0.3px; background: #ffffff; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid #979797; >span {font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
            }
            icon="chevron-right"
            iconTop="calc(50% - 10.5px)"
            iconColor="black"
            iconColor2="black"
            color="white"
            radius={0}
            onClick={() =>
              window.open("https://visite.mindquest.io/nos-partenaires")
            }
          >
            <Trans>Bénéficier des offres partenaires</Trans>
          </Button>
        </Col>

        <div className={classes.textInside}>
          <Trans>
            Dites-nous ce que vous souhaitez, notre bot vous dirige vers le bon
            partenaire
          </Trans>
        </div>
        {currentLanguage === "fr" ? (
          <iframe
            className={classes.iframeStyle}
            srcdoc=' 
              <html> 
              <head> 
              <script src="https://cdn.jsdelivr.net/npm/@tripetto/runner">
              </script> <script src="https://cdn.jsdelivr.net/npm/@tripetto/runner-chat">
              </script> <script src="https://cdn.jsdelivr.net/npm/@tripetto/studio"></script> </head> 
              <body> <div id="tripetto-z8jsj9"></div>
              <script src="https://cdn.jsdelivr.net/npm/@tripetto/runner"></script>
              <script src="https://cdn.jsdelivr.net/npm/@tripetto/runner-classic"></script>
              <script src="https://cdn.jsdelivr.net/npm/@tripetto/studio"></script>
              <script>
              TripettoStudio.form({
                  runner: TripettoClassic,
                  token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoieWQyWWVOZzZDZGtmVVI1Y0hNcklIZldwamtPLzVCMzJLTFJKSmNKYmdCZz0iLCJkZWZpbml0aW9uIjoiWEMvSUJNL2hIOG9lUExoc3pjZUpuWXorWVYvNGRBY3lkanZwREtyOWgvRT0iLCJ0eXBlIjoiY29sbGVjdCJ9.BXCWDQ2yYt0InHzNvbVm5CeeDb90GlPd72KvcCOL7Rs",
                  element: "tripetto-z8jsj9"
              });
              </script>
              </body> 
              </html>'
          ></iframe>
        ) : (
          <iframe
            className={classes.iframeStyle}
            srcdoc=' 
              <html> 
              <head> 
              <div id="tripetto-1asz4qe"></div>
              <script src="https://cdn.jsdelivr.net/npm/@tripetto/runner"></script>
              <script src="https://cdn.jsdelivr.net/npm/@tripetto/runner-autoscroll"></script>
              <script src="https://cdn.jsdelivr.net/npm/@tripetto/studio"></script>
              <script>
              TripettoStudio.form({
                  runner: TripettoAutoscroll,
                  token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoieWQyWWVOZzZDZGtmVVI1Y0hNcklIZldwamtPLzVCMzJLTFJKSmNKYmdCZz0iLCJkZWZpbml0aW9uIjoib0JRbkFQaW03ZmEvN0x4RHFKcmQyNS85bldHdHFqaXdlcTh6WjB4eTJWTT0iLCJ0eXBlIjoiY29sbGVjdCJ9.u8xzomREvvm1Wso4DD_qAdN7XSPZpoXapzMfCdjfEaU",
                  element: "tripetto-pru1i6"
              });
              </script>
              </body> 
              </html>'
          ></iframe>
        )}
      </div>
    );
  }
}

Section4.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section4));
