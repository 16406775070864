import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { Mutation, Query } from "react-apollo";
import moment from "moment";
import { inject, observer } from "mobx-react";

import SingleMissionHeader from "./SingleMissionHeader/SingleMissionHeader";
import SingleMissionBody from "./SingleMissionBody/SingleMissionBody";
import StickyHeaderManager from "./SingleMissionStickyHeader/StickyHeaderManager";
import SingleMissionQuery from "./query";
import { ADD_TO_OPEN_RECENT } from "./mutations";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import defaultSourcer from "./defaultValues";
import MutateOnDidMount from "../../components/mutateOnDidMount";
import DeletedPage from "../../components/error-pages/DeletedPage";
import NotFoundPage from "../../components/error-pages/NotFoundPage";
import Routes from "../layout/routes";
import ldJson from "./ldJson";
import runtimeVars from "../../configs/runTimeVars";

@inject("sessionStore")
@observer
class SingleMission extends Component {
  componentDidMount() {
    const { sessionStore } = this.props;
    window.scroll(0, 0);
    sessionStore.setJobOrderReferer(document.referrer);
  }

  componentDidMount() {
    // Extraire les paramètres de l'URL
    const searchParams = new URLSearchParams(window.location.search);
    const entityId = searchParams.get("EntityID");
    // Si un EntityID est trouvé, rediriger vers l'URL avec l'ID de l'offre d'emploi
    if (entityId) {
      window.location.href = `${window.location.origin}/missions/${entityId}`;
    }
  }

  formatDuration = (duration, startDate) => {
    const { i18n } = this.props;
    let start = "";
    let dur = "";

    if (duration) {
      if (duration.months) dur = `${duration.months} ${i18n._(t`mois`)}`;
      else if (duration.days) dur = `${duration.days} ${i18n._(t`jours`)}`;
    }

    if (startDate && typeof startDate === "number") {
      start = moment(startDate).format("DD MMM");
    }
    const startSentence = start ? `${i18n._(t`à partir de`)} ${start}` : "";
    const withDurationCase =
      dur && startSentence ? ` (${startSentence})` : startSentence;
    return `${dur}${withDurationCase}`;
  };

  buildHeaderProps = (loading, data) => {
    if (loading) return { loading: true };
    const { i18n } = this.props;
    const mission = data.jobOrder;
    const tags = [...mission.categories, ...mission.skills];
    const duration =
      mission.employmentType.toLowerCase() === "permanent"
        ? i18n._(t`Permanent`)
        : this.formatDuration(mission.duration, mission.startDate) ||
          i18n._(t`Non précis`);

    let workType = i18n._(t`Non précis`);
    if (mission.customText1) {
      if (mission.customText1.toLowerCase() === "full time")
        workType = i18n._(t`Temps plein`);
      if (mission.customText1.toLowerCase() === "part time")
        workType = i18n._(t`Temps partiel`);
    }

    return {
      loading: false,
      title: mission.title || i18n._(t`Non précis`),
      place: mission.address.city || i18n._(t`Non précis`),
      duration,
      categories: tags.length ? tags : [i18n._(t`Non précis`)],
      businessSectors: mission.businessSectors.length
        ? mission.businessSectors
        : [i18n._(t`Non précis`)],
      workType
    };
  };

  buildBodyProps = (loading, data) => {
    const { sessionStore } = this.props;
    if (loading) return { loading: true };
    const { i18n } = this.props;
    const mission = data.jobOrder;
    sessionStore.setContactPageMission({
      id: mission.id,
      title: mission.title
    });
    const defaults = defaultSourcer(i18n);
    /* in case of multiple sourcers, we choose the last one */
    const sourcer = mission.sourcers[mission.sourcers.length - 1];

    let sourcerDescription = defaults.description;
    if (runtimeVars.APP_LANG.toLowerCase() === "fr") {
      if (sourcer.customText3 && sourcer.customText3 !== "0")
        sourcerDescription = sourcer.customText3;
    } else if (sourcer.customText2 && sourcer.customText2 !== "0")
      sourcerDescription = sourcer.customText2;
    return {
      loading: false,
      content: {
        context: mission.customTextBlock1 || "",
        mission: mission.customTextBlock2 || "",
        requiredProfile: mission.customTextBlock5 || "",
        benefits: mission.customTextBlock3 || "",
        contact: mission.customTextBlock4 || ""
      },
      sourcer: {
        id: sourcer.id,
        avatar: (sourcer && sourcer.headShot) || defaults.avatar,
        name: (sourcer && sourcer.firstName) || defaults.name,
        position: (sourcer && sourcer.occupation) || defaults.position,
        email: (sourcer && sourcer.email) || defaults.email,
        phoneNumber: (sourcer && sourcer.phone) || defaults.phoneNumber,
        description: sourcerDescription,
        linkedIn: (sourcer && sourcer.customText1) || defaults.linkedIn
      },
      customText7: mission.customText7
    };
  };

  render() {
    const { match, sessionStore, i18n } = this.props;
    if (isNaN(match.params.id))
      return (
        <NotFoundPage
          message={i18n._(
            t`Nous sommes désolé, la mission que vous cherchez n’existe pas ou n’est plus disponible`
          )}
          href={Routes.HbfLayoutRoutes.MissionsPage.path}
        />
      );
    return (
      <div>
        <Query
          query={SingleMissionQuery}
          variables={{ id: parseInt(match.params.id, 10) }}
        >
          {({ loading, error, data }) => {
            if (error || (data && !data.jobOrder))
              return (
                <DeletedPage
                  message={i18n._(
                    t`Nous sommes désolé, la mission que vous cherchez n’existe pas ou n’est plus disponible`
                  )}
                  href={Routes.HbfLayoutRoutes.MissionsPage.path}
                />
              );
            const headerProps = this.buildHeaderProps(loading, data);
            const bodyProps = this.buildBodyProps(loading, data);
            return (
              <div>
                {!loading &&
                  metaTags(
                    urls.singleMission(data.jobOrder.id),
                    data.jobOrder.title,
                    bodyProps.content.context,
                    null, //data.jobOrder.correlatedCustomTextBlock1,
                    null,
                    null,
                    ldJson(i18n, data.jobOrder)
                  )}
                <SingleMissionHeader {...headerProps} id={match.params.id} />
                <StickyHeaderManager {...headerProps} id={match.params.id} />
                <SingleMissionBody {...bodyProps} id={match.params.id} />
                {/* <BlogCards /> */}
              </div>
            );
          }}
        </Query>
        <Mutation
          mutation={ADD_TO_OPEN_RECENT}
          onCompleted={() => {}}
          onError={() => {}}
          variables={{ jobOrderId: match.params.id }}
        >
          {addToOpenRecent => {
            if (sessionStore.authToken)
              return <MutateOnDidMount mutate={addToOpenRecent} />;
            return null;
          }}
        </Mutation>
      </div>
    );
  }
}

SingleMission.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    setJobOrderReferer: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(SingleMission));
