import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { ThemeBlock, ItemMenuNew, Icon } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import AddThemeForMobile from "./AddThemeForMobile";
import locals from "../../../helpers/locals";
import { Trans, t } from "@lingui/macro";
import convertTodays from "../../../utils/convertTodays";

@inject("newTimesheetStore", "appStore", "sessionStore")
@observer
class ThemeVue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: ""
    };
  }

  onClickEdit = (e, key) => {};

  onClickDelete = themeName => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps, changeThemeToDelete } = newTimesheetStore;
    changeThemeToDelete(themeName);
    openClosePopUps("deleteTheme");
  };

  onEditThemeName = (oldThemeName, newThemeName) => {
    const { newTimesheetStore, i18n } = this.props;
    const { changeTheme } = newTimesheetStore;
    let result = changeTheme(oldThemeName, newThemeName);
    if (!result)
      this.setState({
        errorMessage: i18n._(t`Ce nom de projet existe déjà !`)
      });
    else {
      this.setState({ errorMessage: "" });
    }
    return result;
  };

  onClickLoggedHoursCard = data => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps, changeRangeDetails } = newTimesheetStore;

    changeRangeDetails(data);
    openClosePopUps("moreInformation");
  };

  onclickOnMailReplayIcon = data => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps, changeRangeDetails } = newTimesheetStore;
    changeRangeDetails(data);
    openClosePopUps("addMessage");
  };

  addNewTheme = () => {
    const { newTimesheetStore } = this.props;
    const { openClosePopUps, themes, LIMIT_THEMES } = newTimesheetStore;

    if (themes && themes.length === LIMIT_THEMES)
      openClosePopUps("limitOfThemes");
    else openClosePopUps("addTheme");
  };

  render() {
    const {
      newTimesheetStore,
      device,
      appStore,
      sessionStore,
      i18n
    } = this.props;
    const { account } = sessionStore;
    const { periods, timesheet, editable } = newTimesheetStore;
    const { currentLanguage } = appStore;
    const { errorMessage } = this.state;

    return (
      <div>
        {device === "mobile" &&
          account.role == "CANDIDATE" &&
          editable("CANDIDATE") && <AddThemeForMobile />}
        {device === "mobile" &&
          account.role == "CANDIDATE" &&
          editable("CANDIDATE") && (
            <button
              style={{
                position: "relative",
                cursor: "pointer",
                width: "100%",
                border: "0.3px solid #96081C",
                borderRadius: "5px",
                background: "#FFFFFF",
                padding: "10px"
              }}
              onClick={() => this.addNewTheme()}
            >
              <Icon
                type="plus"
                width={16}
                height={16}
                color="#96081C"
                color2="#96081C"
              />
              <span
                style={{
                  marginLeft: "7px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  color: "#242424",
                  width: "100%"
                }}
              >
                <Trans>Ajouter un nouveau projet</Trans>
              </span>
            </button>
          )}
        {periods && periods.length
          ? periods.map((theme, theme_index) => {
              const ranges = [];
              let normalHours = 0;
              let specialHours = 0;
              if (theme.ranges && theme.ranges.length) {
                theme.ranges.map((range, range_index) => {
                  if (range.type === "normal")
                    normalHours = normalHours + range.total;
                  if (range.type === "special")
                    specialHours = specialHours + range.total;

                  const rangeObj = {
                    type: range.type,
                    period:
                      range.start !== range.end
                        ? `${range.start} ${
                            locals[currentLanguage].months.short[
                              timesheet.month - 1
                            ]
                          } - ${range.end} ${
                            locals[currentLanguage].months.short[
                              timesheet.month - 1
                            ]
                          }`
                        : `${range.start} ${
                            locals[currentLanguage].months.short[
                              timesheet.month - 1
                            ]
                          }`,
                    hours: `${Math.floor(range.total / 60)}h${range.total %
                      60}m`,
                    days: `(${convertTodays(range.total, i18n)})`,
                    message: range.description
                  };
                  ranges.push({
                    ...rangeObj,
                    withMailReplyIcon: account.role === "CLIENT",
                    showMessage: account.role === "CLIENT" && rangeObj.message,
                    details: {
                      ...range,
                      themeName: theme.themeName,
                      themeColor: theme.themeColor,
                      hours: rangeObj.hours,
                      days: `(${convertTodays(rangeObj.hours, i18n)})`,
                      period: rangeObj.period,
                      range_index
                    }
                  });
                });
              }
              return (
                <div
                  style={{ position: "none", margin: "10px 0" }}
                  key={"theme-" + theme_index}
                >
                  <ThemeBlock
                    periods={ranges}
                    itemColor={theme.themeColor}
                    themeColor={theme.themeColor}
                    themeName={theme.themeName}
                    errorMessage={errorMessage}
                    messageForEmptyTheme={i18n._(
                      t`Aucune date n'est associée à ce projet`
                    )}
                    normalHours={
                      normalHours
                        ? `${Math.floor(normalHours / 60)}h${normalHours % 60}m`
                        : ""
                    }
                    specialHours={
                      specialHours
                        ? `${Math.floor(specialHours / 60)}h${specialHours %
                            60}m`
                        : ""
                    }
                    withThreePoints={
                      account.role === "CLIENT"
                        ? false
                        : editable("CANDIDATE") && theme.themeName !== "N/A"
                    }
                    onEditThemeName={this.onEditThemeName}
                    onClickLoggedHoursCard={data =>
                      this.onClickLoggedHoursCard(data)
                    }
                    onclickOnMailReplayIcon={data =>
                      this.onclickOnMailReplayIcon(data)
                    }
                  >
                    <ItemMenuNew
                      label={i18n._(t`Renommer`)}
                      value="EDIT"
                      onClick={this.onClickEdit}
                    />
                    <ItemMenuNew
                      label={i18n._(t`Supprimer`)}
                      value="DELETE"
                      onClick={() => this.onClickDelete(theme.themeName)}
                    />
                  </ThemeBlock>
                </div>
              );
            })
          : i18n._(t`Aucun projet n'est associé à ce Timesheet.`)}
      </div>
    );
  }
}

ThemeVue.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    changeRangeDetails: PropTypes.func,
    changeThemeToDelete: PropTypes.func,
    editable: PropTypes.func,
    themes: PropTypes.array,
    periods: PropTypes.array,
    workPlan: PropTypes.array,
    timesheet: PropTypes.shape({}),
    deleteTheme: PropTypes.func,
    LIMIT_THEMES: PropTypes.number
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }).isRequired,
  device: PropTypes.string,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};
export default withApollo(withI18n()(withRouter(ThemeVue)));
