import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { t, Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import { Button, toaster, H2, TextInput, Modal, Col } from "cf-neo-ui";
import Articles from "../components/blog-cards-display/BlogCardsDisplay";
import { Mutation, withApollo } from "react-apollo";
import { SUB_TO_NEWSLETTER_FINANCE } from "../../layout/blog-layout/mutations";
import { GET_USER_NEWSLETTER_SUBSCRIPTION } from "../../layout/blog-layout/queries";
import FinanceModal from "../../layout/blog-layout/news-banner/FinanceModal";
import ConnectedAndSubscribedUserModal from "../../layout/blog-layout/news-banner/ConnectedAndSubscribedUserModal";

import { GET_USER_INFO } from "../../../components/hoc/queries";
import {
  latestPostsQuery,
  categoriesRefEn,
  categoriesRefFr
} from "../../blog/query";
const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section10 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      validate: false,
      valid: false,
      showModal: false,
      showIsConnectedAndSubscribedModal: false,
      toasterId: "nl_consultant",
      receiveNewsletterTalent: false,
      receiveNewsletterClient: false
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  register = mutation => {
    this.setState({ showModal: false });

    const input = {
      email: this.email,
      language: localStorage.getItem("language")
    };

    return mutation({ variables: input });
  };

  changeHandler = e => {
    this.email = e.target.value;
    this.setState({ validate: false });
  };

  subscribe = async () => {
    const { client, i18n } = this.props;
    const valid = EMAIL_REG.test(this.email);
    if (valid) {
      const { loading, error, data } = await client.query({
        query: GET_USER_NEWSLETTER_SUBSCRIPTION,
        variables: {
          email: this.email,
          language: localStorage.getItem("language")
        },
        fetchPolicy: "network-only"
      });
      if (!loading && !error) {
        const { getUserNewsLetterSubscriptions } = data;
        const {
          receiveNewsletterTalent,
          receiveNewsletterClient
        } = getUserNewsLetterSubscriptions;
        this.setState({
          receiveNewsletterClient:
            getUserNewsLetterSubscriptions.receiveNewsletterClient,
          receiveNewsletterTalent:
            getUserNewsLetterSubscriptions.receiveNewsletterTalent
        });
        if (receiveNewsletterTalent && receiveNewsletterClient)
          this.setState({ showIsConnectedAndSubscribedModal: true });
        else this.setState({ showModal: true });
      }
    } else {
      toaster.warning({
        title: i18n._(t`Avertissement`),
        description: i18n._(t`Veillez entrer un email valide`)
      });

      this.setState({ validate: true, valid });
    }
  };

  focusHandler = e => {
    const { sessionStore } = this.props;
    if (sessionStore.authToken && sessionStore.email) {
      e.target.value = sessionStore.email;
      this.email = sessionStore.email;
    }
  };

  closeModal = () => {
    this.setState({ showIsConnectedAndSubscribedModal: false });
  };
  render() {
    const { appStore, i18n, sessionStore } = this.props;
    const { currentLanguage } = appStore;
    const {
      validate,
      valid,
      showModal,
      showIsConnectedAndSubscribedModal,
      toasterId
    } = this.state;
    const { authToken } = sessionStore;

    let categoriesRef = categoriesRefFr;
    if (currentLanguage === "en") categoriesRef = categoriesRefEn;

    const styles = {
      color: "white",
      borderColor: "#dadee3"
    };
    if (validate && !valid) {
      styles.color = "red";
      styles.borderColor = "red";
    }
    return (
      <div>
        <H2 className={classes.mainText}>
          <Trans>Notre vision du marché</Trans>
        </H2>
        <div>
          <Query
            query={latestPostsQuery}
            variables={{
              page: 1,
              perPage: 2,
              categoryIds: categoriesRef.FinancePro,
              language: currentLanguage
            }}
          >
            {({ data }) => {
              if (data && data.posts.length) {
                return (
                  <div style={{ marginBottom: "30px" }}>
                    <Articles articles={data.posts} />
                  </div>
                );
              }
              return (
                <div
                  style={{
                    marginBottom: "30px",
                    "font-size": "larger",
                    color: "white",
                    marginTop: "30px"
                  }}
                >
                  <Trans>Pas de ressources actuellement.</Trans>
                </div>
              );
            }}
          </Query>
        </div>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.newsletter}
        >
          <Col xl={4} lg={4} md={4} sm={4} xs={6} className={classes.nopadding}>
            <TextInput
              style={styles}
              onChange={this.changeHandler}
              onEnterPressed={this.subscribe}
              placeholder={i18n._(t`Email`)}
              spellCheck="false"
              onFocus={this.focusHandler}
              kind="editable"
              classNameText={"border: 1px solid #fff;background: transparent;"}
            />
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={6} className={classes.sec9}>
            {" "}
            <Button
              size="medium"
              variant="quaternary"
              classNameText={
                "height: 53px; background: linear-gradient(76deg, #8d0417,#d3354a);background-size: 101% 101%; color: #ffffff;> span { margin: 0px;;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
              }
              onClick={this.subscribe}
            >
              <Trans>S’abonner à la newsletter</Trans>
            </Button>
          </Col>
        </Col>
        <Modal
          centred
          isVisible={showIsConnectedAndSubscribedModal}
          withCloseButton={false}
          position="fixed"
        >
          <ConnectedAndSubscribedUserModal close={this.closeModal} />
        </Modal>
        {showModal && (
          <Modal
            centred
            isVisible={showModal}
            onClose={() => this.setState({ showModal: false })}
          >
            <Mutation
              mutation={SUB_TO_NEWSLETTER_FINANCE}
              onCompleted={({ SubToNewsletterFinance }) => {
                if (SubToNewsletterFinance.status === "ok")
                  toaster.success({
                    id: toasterId,
                    title: i18n._(t`Merci`),
                    description: i18n._(
                      t`Vous êtes désormais abonné à la newsletter de Mindquest`
                    )
                  });
                if (SubToNewsletterFinance.status === "error")
                  toaster.error({
                    title: i18n._(t`Erreur`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de l’abonnement`
                    )
                  });
              }}
              onError={() =>
                toaster.error({
                  title: i18n._(t`Erreur`),
                  description: i18n._(
                    t`Un problème est survenu lors de la création de l’abonnement`
                  )
                })
              }
              ignoreResults
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
            >
              {subToNewsletter => (
                <FinanceModal register={() => this.register(subToNewsletter)} />
              )}
            </Mutation>
          </Modal>
        )}
      </div>
    );
  }
}

Section10.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(withApollo(Section10)));
