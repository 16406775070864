import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import {
  RadioGroup,
  Radio,
  Col,
  Collapse,
  InputSkeleton,
  Button,
  Icon,
  toaster
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Plural, t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./SideFilters.module.scss";
import DateWidget from "./widgets/DateWidget";
import CategoriesWidget from "./widgets/CategoriesWidget";
import EmploymentTypeWidget from "./widgets/EmploymentTypeWidget";
import OnSiteWidget from "./widgets/OnSiteWidget";
import SkillsWidget from "./widgets/SkillsWidget";
import BusinessSectorWidget from "./widgets/BusinessSectorWidget";
import SearchBar from "./widgets/search-bar/SearchBar";
import LocationWidget from "./widgets/LocationWidget";
import SpecialtiesWidget from "./widgets/SpecialtiesWidget";
import {
  GET_CATEGORIES,
  GET_SKILLS_CATEGORIES,
  GET_BUSINESS_SECTORS,
  GET_SPECIALTIES
} from "../../queries";
import Routes from "../../../layout/routes";
import * as queryString from "query-string";
import { withRouter } from "react-router-dom";
import domaineExpertise from "../../../../configs/domaineExpertise";

@inject("missionsSearchStore", "sessionStore", "appStore")
@observer
class SideFilters extends Component {
  constructor(props) {
    super(props);
    const queryObj = queryString.parse(props.location.search);
    this.state = {
      isEdit: queryObj.action === "edit",
      key: 0
    };
  }

  clearFilters = () => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.clearFilters();
    let searchInput = document.getElementById("mi_sb");
    searchInput.value = "";
    const { key } = this.state;
    this.setState({ key: key + 1 });
  };

  createAlertHandler = () => {
    const { missionsSearchStore, sessionStore, history, i18n } = this.props;
    const { canOpenAlertModal, setAlertModal } = missionsSearchStore;
    const { authToken, setBackRef } = sessionStore;

    if (authToken) {
      if (canOpenAlertModal) {
        setAlertModal();
      } else {
        toaster.warning({
          title: i18n._(t`Filtres Vide`),
          description: i18n._(
            t`Veuillez selectionner au moin un filtre pour créer une alerte`
          )
        });
      }
    } else {
      setBackRef("missions");
      history.push(Routes.HbLayoutRoutes.Login.path);
    }
  };

  render() {
    const { i18n, missionsSearchStore, sessionStore, appStore } = this.props;
    const { width } = appStore;
    const { key } = this.state;
    const {
      isSideFilterOpen,
      skills,
      categories,
      specialties,
      cityLabels,
      onSite,
      businessSectors,
      employmentType
    } = missionsSearchStore;
    const { account } = sessionStore;
    const { isEdit } = this.state;
    let isDisabled;
    if (account) {
      if (account.role === "CLIENT") isDisabled = true;
    } else isDisabled = false;
    return (
      <Col
        noGutter
        lg={3}
        md={3}
        sm={6}
        xs={6}
        className={`${classes.sideFilter} ${
          isSideFilterOpen ? classes.active : ""
        }`}
      >
        <aside>
          <div className={classes.alertWidget}>
            <div style={{ float: "left", width: "100%" }}>
              <div className={classes.respTitle}>
                <Trans>Critères</Trans>
              </div>
              <div className={classes.closeBtn}>
                <Icon
                  type="close"
                  width={12}
                  height={11}
                  onClick={missionsSearchStore.setSideFilter}
                />
              </div>
            </div>
            <Button
              // icon="bell"
              onClick={this.createAlertHandler}
              id="cta_mi_fi_alert"
              disabled={isDisabled}
              size="small"
              style={{ fontSize: "13px", fontWeight: "bold" }}
            >
              {isEdit ? (
                <span style={{ margin: "0 -17px" }}>
                  <Trans>Modifier mon alerte avec ces critères</Trans>
                </span>
              ) : (
                <span style={{ margin: "0 -17px" }}>
                  <Trans>Créer une alerte sur ces critères</Trans>
                </span>
              )}
            </Button>
            <>
              {width >= 825 && width <= 1206 ? (
                <picture className="tooltip">
                  <img src="/assets/images/tooltip.webp" alt="tooltip" />
                  <div className="right">
                    <Trans>
                      Recevez par email les futures opportunités selon un ou
                      plusieurs critères configurés.
                    </Trans>
                  </div>
                  <i />
                </picture>
              ) : (
                <picture className="tooltip2">
                  <img src="/assets/images/tooltip.webp" alt="tooltip" />
                  <div className="right">
                    <Trans>
                      Recevez par email les futures opportunités selon un ou
                      plusieurs critères configurés.
                    </Trans>
                  </div>
                  <i />
                </picture>
              )}
            </>

            {missionsSearchStore.printResultsCount && (
              <p className="center">
                <span style={{ color: "#C40B24" }}>
                  {`${missionsSearchStore.resultsCount} `}
                </span>
                <Plural
                  value={missionsSearchStore.resultsCount}
                  one={i18n._(t`offre disponible`)}
                  other={i18n._(t`offres disponibles`)}
                />
              </p>
            )}
            <div className={classes.clearAll}>
              <span
                onClick={this.clearFilters}
                style={{
                  color: "gray",
                  textDecorationLine: "underline",
                  cursor: "pointer"
                }}
              >
                <Trans>Réinitialiser</Trans>
                <svg
                  style={{ verticalAlign: "-0.275em" }}
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15pt"
                  height="15pt"
                  viewBox="0 0 96 96"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0,96) scale(0.1,-0.1)"
                    fill="gray"
                    stroke="none"
                  >
                    <path
                      d="M375 860 c-14 -16 -33 -20 -108 -22 -73 -2 -92 -6 -101 -20 -8 -13
-8 -23 0 -35 10 -17 36 -18 314 -18 278 0 304 1 314 18 8 12 8 22 0 35 -9 14
-28 18 -101 20 -75 2 -94 6 -108 22 -15 17 -31 20 -105 20 -74 0 -90 -3 -105
-20z"
                    />
                    <path
                      d="M200 405 l0 -276 25 -24 24 -25 231 0 231 0 24 25 25 24 0 276 0 275
-280 0 -280 0 0 -275z m237 93 l43 -42 44 43 c31 31 50 42 67 39 42 -6 37 -44
-12 -94 l-43 -44 43 -44 c31 -31 42 -50 39 -67 -6 -42 -44 -37 -94 12 l-44 43
-44 -43 c-31 -31 -50 -42 -67 -39 -42 6 -37 44 12 94 l43 44 -42 43 c-84 86
-31 139 55 55z"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <Collapse title={i18n._(t`Préciser la recherche`)}>
            <div className={classes.widgetBody}>
              <SearchBar onDeleteTag={() => this.setState({ key: key + 1 })} />
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Type de Contrat`)} used={!!employmentType}>
            <div className={classes.widgetBody}>
              <EmploymentTypeWidget key={key} />
            </div>
          </Collapse>

          <Collapse
            title={i18n._(t`Domaine d’expertise`)}
            used={!!(categories && categories.length)}
          >
            <div className={classes.widgetBody}>
              <Query query={GET_CATEGORIES}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { categories } = data;
                  return (
                    <CategoriesWidget
                      key={key}
                      options={categories.map(item =>
                        domaineExpertise(i18n, item.name)
                      )}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
          <Collapse
            title={i18n._(t`Spécialités`)}
            used={!!(specialties && specialties.length)}
          >
            <div className={classes.widgetBody}>
              <Query query={GET_SPECIALTIES}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { specialties } = data;
                  return (
                    <SpecialtiesWidget
                      key={key}
                      options={specialties.map(item => item.name)}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
          <Collapse
            title={i18n._(t`Compétence`)}
            used={!!(skills && skills.length)}
          >
            <div className={classes.widgetBody}>
              <Query query={GET_SKILLS_CATEGORIES}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;
                  //this function allow us to extract from each categories it's skills
                  //adding before each SAP categorie skills SAP string
                  //📌 notice : category SAP is tested by id if the id changes it may affect the current work
                  const arrays_of_arrays_skills = data?.categories?.map(
                    category =>
                      category?.id === 2000096
                        ? category?.skills?.map(
                            skill => `_${category?.name} ${skill?.name}`
                          )
                        : category?.skills?.map(skill => `${skill?.name}`)
                  );
                  // it return an array of arrays
                  // befor passing the skill to the autocomplete UI we need to flat the array
                  const skills = arrays_of_arrays_skills?.flat();
                  //final result it will be and array of string with all the results that we need
                  return <SkillsWidget key={key} options={skills} />;
                }}
              </Query>
            </div>
          </Collapse>
          <Collapse
            title={i18n._(t`Localisation`)}
            used={!!(cityLabels && cityLabels.length)}
          >
            <div className={classes.widgetBody}>
              <LocationWidget key={key} />
              <p style={{ display: "none" }} className={classes.widgetSubtitle}>
                <Trans>Lieu de travail</Trans>
              </p>
              <RadioGroup
                style={{ display: "none" }}
                className={classes.collapseRadioGroup}
                name="localisation"
                defaultSelected={3}
              >
                <Radio label={i18n._(t`Régie`)} value={1} />
                <Radio label={i18n._(t`Remote`)} value={2} />
                <Radio label={i18n._(t`Indifférent`)} value={3} />
              </RadioGroup>
            </div>
          </Collapse>
          <DateWidget key={key} />
          <Collapse title={i18n._(t`Remote/Régie`)} used={!!onSite}>
            <div className={classes.widgetBody}>
              <OnSiteWidget key={key} />
            </div>
          </Collapse>
          <Collapse
            title={i18n._(t`Secteur`)}
            used={!!(businessSectors && businessSectors.length)}
          >
            <div className={classes.widgetBody}>
              <Query query={GET_BUSINESS_SECTORS}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { businessSectors } = data;
                  return (
                    <BusinessSectorWidget
                      key={key}
                      options={businessSectors.map(item => item.name)}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
        </aside>
      </Col>
    );
  }
}

SideFilters.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.object,
    setBackRef: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    printResultsCount: PropTypes.bool,
    canOpenAlertModal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    setAlertModal: PropTypes.func,
    setSideFilter: PropTypes.func,
    resultsCount: PropTypes.number,
    clearFilters: PropTypes.func,
    isSideFilterOpen: PropTypes.bool
  }).isRequired
};

export default withI18n()(withRouter(SideFilters));
