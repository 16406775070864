import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, MissionStatus, Pagination } from "cf-neo-ui";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import FaveUnFave from "../../components/fave-unfave";
import Routes from "../layout/routes/index";
import { USER_APPLICATIONS_QUERY } from "./queries";
import classes from "./styles.module.scss";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import { isOnePage, setUrgent } from "../../utils/helpers";
import formatMissionDuration from "../../utils/formatMissionDuration";
import getContractType from "../../utils/getContractType";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";

@inject("appStore")
@observer
class MyMissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skip: 0,
      fromWithinMissions: props.fromWithinMissions
    };
  }

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 6;
    if (width >= 768) return 4;
    return 2;
  };

  widthChangeHandler = () => {
    const { appStore } = this.props;
    appStore.changeWidth();
    this.setState({ pageSize: this.setPageSize(appStore.width) });
  };

  renderPagination = data => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: data.length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };

    return <Pagination {...paginationProps} />;
  };

  toDetails = url => {
    const { history } = this.props;
    history.push(url);
  };

  getApplicationStatus = application => {
    const { i18n } = this.props;
    switch (application.status) {
      case "New Lead":
        return {
          href: `${Routes.HbfLayoutRoutes.MissionsPage.path}/${application.jobOrder.id}`,
          progressMessage: i18n._(t`À l’étude`),
          progressColor: "#B9C40B",
          onShowDetails: () =>
            this.toDetails(
              `${Routes.HbfLayoutRoutes.MissionsPage.path}/${application.jobOrder.id}`
            )
        };
      case "Submitted":
        return {
          href: `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`,
          progressMessage: i18n._(t`Ouverte`),
          progressColor: "#0B51C4",
          onShowDetails: () =>
            this.toDetails(
              `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`
            )
        };
      case "CV Sent":
        return {
          href: `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`,
          progressMessage: i18n._(t`CV envoyé`),
          progressColor: "#0B51C4",
          onShowDetails: () =>
            this.toDetails(
              `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`
            )
        };
      case "Interview":
        return {
          href: `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`,
          progressMessage: i18n._(t`Entretien`),
          progressColor: "#0B51C4",
          onShowDetails: () =>
            this.toDetails(
              `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`
            )
        };
      case "Placement":
        return {
          href: `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`,
          progressMessage: i18n._(t`Placement`),
          progressColor: "#0BC46E",
          onShowDetails: () =>
            this.toDetails(
              `${Routes.LpbLayoutRoutes.Missions.path}/${application.jobOrder.id}`
            )
        };
      case "Rejected":
        return {
          href: `${Routes.HbfLayoutRoutes.MissionsPage.path}/${application.jobOrder.id}`,
          progressMessage: i18n._(t`Rejetée`),
          progressColor: "#C40B24",
          onShowDetails: () =>
            this.toDetails(
              `${Routes.HbfLayoutRoutes.MissionsPage.path}/${application.jobOrder.id}`
            )
        };
      default:
        return {
          href: `${Routes.HbfLayoutRoutes.MissionsPage.path}/${application.jobOrder.id}`,
          onShowDetails: () =>
            this.toDetails(
              `${Routes.HbfLayoutRoutes.MissionsPage.path}/${application.jobOrder.id}`
            )
        };
    }
  };

  onNoApplications = () => {
    const { fromWithinMissions } = this.state;
    const { history } = this.props;
    return (
      !fromWithinMissions &&
      history.replace({ search: `?active=recommendation` })
    );
  };

  render() {
    const { i18n } = this.props;
    const { skip, pageSize, currentPage } = this.state;
    const meta = staticMetaTags(i18n).appliedMissions;
    return (
      <>
        {metaTags(
          urls.appliedMissions(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <Query
          query={USER_APPLICATIONS_QUERY}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <LoadingMissions
                  cardsCount={4}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={6}
                  colClassName={classes.favedMissionsCard}
                  containerClassName={classes.marTop}
                />
              );

            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );

            const { userApplications } = data;
            return (
              <div>
                <Container className={classes.marTop}>
                  <Row>
                    {userApplications.slice(skip, pageSize * currentPage).map(
                      application =>
                        application.jobOrder && (
                          <Col
                            key={application.id}
                            lg={3}
                            md={4}
                            sm={6}
                            xs={6}
                            className={classes.favedMissionsCard}
                          >
                            {application.jobOrder && (
                              <FaveUnFave missionId={application.jobOrder.id}>
                                <MissionStatus
                                  title={application.jobOrder.title}
                                  location={application.jobOrder.address.city}
                                  duration={formatMissionDuration(
                                    application.jobOrder.duration,
                                    i18n,
                                    application.jobOrder.employmentType
                                  )}
                                  contractType={getContractType(
                                    i18n,
                                    application.jobOrder.employmentType
                                  )}
                                  field={application.jobOrder.customText1}
                                  urgent={setUrgent(
                                    application.jobOrder.customText10
                                  )}
                                  {...this.getApplicationStatus(application)}
                                />
                              </FaveUnFave>
                            )}
                          </Col>
                        )
                    )}
                  </Row>
                </Container>
                {isOnePage(pageSize, userApplications.length) ? null : (
                  <div className={classes.pagination}>
                    {this.renderPagination(userApplications)}
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </>
    );
  }
}

MyMissions.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  fromWithinMissions: PropTypes.bool
};

MyMissions.defaultProps = {
  fromWithinMissions: false
};

export default withI18n()(withRouter(MyMissions));
