import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  TextInput,
  toaster,
  Modal,
  IconCircle,
  Icon
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Mutation, withApollo } from "react-apollo";
import { t, Trans } from "@lingui/macro";
import AboutWidget from "./AboutWidget";
import LinkWidget from "./LinkWidget";
import configs from "../../../configs/club-freelance-globals";
import { links1, links2, links3 } from "./links";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { GET_USER_NEWSLETTER_SUBSCRIPTION } from "../blog-layout/queries";
import { SUB_TO_NEWSLETTER } from "./mutations";
import ConnectedAndSubscribedUserModal from "../blog-layout/news-banner/ConnectedAndSubscribedUserModal";
import { GET_USER_INFO } from "../../../components/hoc/queries";
import ConfirmModal from "../blog-layout/news-banner/ConfirmModal";
import styles from "./footer.module.scss";
import { generateKey } from "../../../utils/helpers";
import domaineExpertise from "../../../configs/domaineExpertise";

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("appStore", "sessionStore", "missionsSearchStore")
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
    this.email = "";
    this.state = {
      valid: false,
      showModal: false,
      showIsConnectedAndSubscribedModal: false,
      toasterId: "nl_consultant",
      receiveNewsletterTalent: false,
      receiveNewsletterClient: false
    };
  }
  openPage = provider => {
    window.open(configs.socialMedia[provider], "_blank");
  };

  focusHandler = e => {
    const { sessionStore } = this.props;
    if (sessionStore.authToken && sessionStore.email) {
      e.target.value = sessionStore.email;
      this.email = sessionStore.email;
    }
  };

  changeHandler = e => {
    this.email = e.target.value;
  };

  subscribe = async () => {
    const { client, i18n } = this.props;
    const valid = EMAIL_REG.test(this.email);
    if (valid) {
      const { loading, error, data } = await client.query({
        query: GET_USER_NEWSLETTER_SUBSCRIPTION,
        variables: {
          email: this.email,
          language: localStorage.getItem("language")
        },
        fetchPolicy: "network-only"
      });
      if (!loading && !error) {
        const { getUserNewsLetterSubscriptions } = data;
        const {
          receiveNewsletterTalent,
          receiveNewsletterClient
        } = getUserNewsLetterSubscriptions;
        this.setState({
          receiveNewsletterClient:
            getUserNewsLetterSubscriptions.receiveNewsletterClient,
          receiveNewsletterTalent:
            getUserNewsLetterSubscriptions.receiveNewsletterTalent
        });
        if (receiveNewsletterTalent && receiveNewsletterClient)
          this.setState({ showIsConnectedAndSubscribedModal: true });
        else this.setState({ showModal: true });
      }
    } else {
      toaster.warning({
        title: i18n._(t`Avertissement`),
        description: i18n._(t`Veuillez entrer un email valide`)
      });

      this.setState({ valid });
    }
  };

  register = (talent, client, mutation) => {
    this.setState({ showModal: false });
    const list = [];
    if (talent) list.push("talent");
    if (client) list.push("client");
    const input = {
      email: this.email,
      language: localStorage.getItem("language"),
      list
    };

    if (list.includes("client") && list.includes("talent"))
      this.setState({ toasterId: "nl_consultant_client" });
    else if (list.includes("client")) this.setState({ toasterId: "nl_client" });
    else if (list.includes("talent"))
      this.setState({ toasterId: "nl_consultant" });

    return mutation({ variables: input });
  };

  closeModal = () => {
    this.setState({ showIsConnectedAndSubscribedModal: false });
  };
  // handleClick = (field, contract) => {
  //   const { missionsSearchStore, i18n } = this.props;
  //   const { changeEmploymentType, addCategory } = missionsSearchStore;
  //   const employmentType = contract === "Contract" ? "Contract" : "Freelance";
  //   changeEmploymentType("Contract");
  //   if (field === "Finance") {
  //     addCategory(domaineExpertise(i18n, "Finance", "key"));
  //   }
  // };

  render() {
    const { i18n, appStore, sessionStore, missionsSearchStore } = this.props;
    const { changeEmploymentType, addCategory } = missionsSearchStore;
    const { currentLanguage } = appStore;
    const { authToken } = sessionStore;
    const {
      showModal,
      showIsConnectedAndSubscribedModal,
      toasterId,
      receiveNewsletterTalent,
      receiveNewsletterClient
    } = this.state;

    return (
      <>
        <section className={styles.footer}>
          <footer>
            <div className={styles.newsletter}>
              <Trans>Newsletter</Trans>
            </div>
            <div className={styles.followTitle}>
              <div className={styles.follow}>
                <div className={styles.emailBtn}>
                  <TextInput
                    placeholder={i18n._(t`Email`)}
                    type="email"
                    spellCheck="false"
                    className={styles.emailInput}
                    onChange={this.changeHandler}
                    onEnterPressed={this.subscribe}
                    onFocus={this.focusHandler}
                  />
                  <button onClick={this.subscribe}>
                    <Trans>S'abonner</Trans>
                  </button>
                </div>
                <div className={styles.social}>
                  <Icon
                    type="squared-linkedin"
                    width={35}
                    height={35}
                    color="#ffffff"
                    color2="#585858"
                    onClick={() => this.openPage("linkedIn")}
                  />
                  <Icon
                    type="squared-twitter"
                    width={35}
                    height={35}
                    color="#ffffff"
                    color2="#585858"
                    onClick={() =>
                      currentLanguage === "fr"
                        ? this.openPage("twitter")
                        : this.openPage("twitter_en")
                    }
                  />
                </div>
                <span className="linkedin"></span>
                <span className="twitter"></span>
              </div>
              {appStore.width > 768 && (
                <div className={styles.title}>
                  <Trans>
                    Le match parfait entre les entreprises et les Talents IT ou
                    Finance
                  </Trans>
                </div>
              )}
            </div>
            <div className={styles.containerServices}>
              <div className={styles.item}>
                <span className={styles.title} to="/about">
                  <Trans>À propos</Trans>
                </span>
                <Link to="/about">
                  <Trans>Services et offres</Trans>
                </Link>
                <a
                  key={generateKey()}
                  href={
                    currentLanguage === "en"
                      ? "https://www.welcometothejungle.com/en/companies/club-freelance"
                      : "https://www.welcometothejungle.com/fr/companies/club-freelance"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Nous rejoindre</Trans>
                </a>
                <Link to="/contact">
                  <Trans>Nous contacter</Trans>
                </Link>
              </div>
              <div className={styles.item}>
                <span className={styles.title}>
                  <Trans>Recrutement IT</Trans>
                </span>
                <Link
                  to="/missions?employmentType=Contract"
                  onClick={() => {
                    changeEmploymentType("Contract");
                  }}
                >
                  <Trans>Trouver une mission Freelance IT</Trans>
                </Link>
                <Link
                  to="/missions?employmentType=Permanent"
                  onClick={() => {
                    changeEmploymentType("Permanent");
                  }}
                >
                  <Trans>Trouver un emploi IT / Digital</Trans>
                </Link>
                <Link to="/consultant">
                  <Trans>Services pour les talents IT</Trans>
                </Link>
                <Link to="/candidates">
                  <Trans>Recruter un talent IT</Trans>
                </Link>
                <Link to="/signup?type=enterprise">
                  <Trans>Soumettre un besoin en recrutement IT</Trans>
                </Link>
                <Link
                  to={
                    currentLanguage === "fr"
                      ? "/blog/it-decision-makers"
                      : "/blog/it-decision-makers"
                  }
                >
                  <Trans>Ressources pour les talents IT</Trans>
                </Link>
              </div>
              <div className={styles.item}>
                <span className={styles.title}>
                  <Trans>Recrutement Finance</Trans>
                </span>
                <Link
                  to="/missions?categories=Finance"
                  onClick={() => {
                    changeEmploymentType("Contract");
                    addCategory(domaineExpertise(i18n, "Finance", "key"));
                  }}
                >
                  <Trans>Trouver une mission Freelance Finance</Trans>
                </Link>
                <Link
                  to="/missions?categories=Finance"
                  onClick={() => {
                    changeEmploymentType("Permanent");
                    addCategory(domaineExpertise(i18n, "Finance", "key"));
                  }}
                >
                  <Trans>Trouver un emploi Finance</Trans>
                </Link>
                <Link to="/talent-finance">
                  <Trans>Services pour les talents Finance</Trans>
                </Link>
                <Link to="/signup?type=enterprise">
                  <Trans>Recruter un talent Finance</Trans>
                </Link>
                <Link to="/service-entreprise-finance">
                  <Trans>Soumettre un besoin en recrutement</Trans>
                </Link>
                <Link to="/service-entreprise-finance">
                  <Trans>Services pour les entreprises</Trans>
                </Link>
              </div>
              <div className={styles.item}>
                <span className={styles.title}>
                  <Trans>Ressources</Trans>
                </span>
                <Link to="/blog">
                  <Trans>Blog</Trans>
                </Link>
                <Link
                  to={currentLanguage === "fr" ? "/blog/it-resources" : "/blog"}
                >
                  <Trans>Livre blancs & Webinars</Trans>
                </Link>
                <Link to="/blog/job-description">
                  <Trans>Fiches métiers</Trans>
                </Link>
                <a
                  key={generateKey()}
                  href={
                    currentLanguage === "en"
                      ? "https://mindquest.sleekplan.app/changelog"
                      : "https://mindquest.eb-help.com/help/collections/guide-utilistateurs"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Produit</Trans>
                </a>
                <a
                  key={generateKey()}
                  href="https://mindquest.eb-help.com/help"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Help Center</Trans>
                </a>
              </div>
              {currentLanguage === "fr" && (
                <div className={styles.item}>
                  <span className={styles.title}>
                    <Trans>Partenaires</Trans>
                  </span>
                  <a
                    key={generateKey()}
                    href="https://visite.mindquest.io/nos-partenaires-portage-salarial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Trans>Partenaires portage salarial</Trans>
                  </a>
                  <a
                    key={generateKey()}
                    href="https://visite.mindquest.io/nos-partenaires"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Trans>Partenaires Freelance</Trans>
                  </a>
                </div>
              )}
            </div>
            <div className={styles.privacyPolicy}>
              <Link to="/privacy-policy">
                <Trans>Politique de confidentialité</Trans>
              </Link>
              <Link to="/terms-and-conditions-of-use">
                <Trans>CGU</Trans>
              </Link>
              <Link to="/site-map">
                <Trans>Plan du site</Trans>
              </Link>
            </div>
          </footer>
        </section>

        <Modal
          centred
          isVisible={showIsConnectedAndSubscribedModal}
          withCloseButton={false}
          position="fixed"
        >
          <ConnectedAndSubscribedUserModal close={this.closeModal} />
        </Modal>
        {showModal && (
          <Modal
            centred
            isVisible={showModal}
            onClose={() => this.setState({ showModal: false })}
          >
            <Mutation
              mutation={SUB_TO_NEWSLETTER}
              onCompleted={({ subToNewsletter }) => {
                if (subToNewsletter.status === "ok")
                  toaster.success({
                    id: toasterId,
                    title: i18n._(t`Merci`),
                    description: i18n._(
                      t`Vous êtes désormais abonné à la newsletter de Mindquest`
                    )
                  });
                if (subToNewsletter.status === "error")
                  toaster.error({
                    title: i18n._(t`Erreur`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de l’abonnement`
                    )
                  });
              }}
              onError={() =>
                toaster.error({
                  title: i18n._(t`Erreur`),
                  description: i18n._(
                    t`Un problème est survenu lors de la création de l’abonnement`
                  )
                })
              }
              ignoreResults
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
            >
              {subToNewsletter => (
                <ConfirmModal
                  receiveNewsletterTalent={receiveNewsletterTalent}
                  receiveNewsletterClient={receiveNewsletterClient}
                  register={(talent, client) =>
                    this.register(talent, client, subToNewsletter)
                  }
                />
              )}
            </Mutation>
          </Modal>
        )}
      </>
    );
  }
}

Footer.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    email: PropTypes.string,
    isMapsScriptReady: PropTypes.bool
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    changeEmploymentType: PropTypes.func,
    employmentType: PropTypes.string
  }).isRequired
};

export default withI18n()(withApollo(Footer));
