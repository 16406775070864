import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Prompt } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  SegmentedControl,
  Row,
  Col,
  MonthlyTimesheetNew,
  TotalHours,
  Modal,
  Chat,
  TimesheetChat
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import runtimeVars from "../../../configs/runTimeVars";
import ThemeVue from "../components/ThemeVue";
import TypeVue from "../components/TypeVue";
import AddPeriodForm from "../components/AddPeriodForm";
import AddTheme from "../components/AddTheme";
import AddThemePopUp from "../pop-ups/AddThemePopUp";
import TitleForTypeView from "../components/TitleForTypeView";
import MoreInformationPopUp from "../pop-ups/MoreInformationPopUp";
import DeleteRangePopUp from "../pop-ups/DeleteRangePopUp";
import DeleteThemePopUp from "../pop-ups/DeleteThemePopUp";
import LimitOfThemesPopUp from "../pop-ups/LimitOfThemesPopUp";
import DayDetailsPopUp from "../pop-ups/DayDetailsPopUp";
import HeaderForDesktop from "../components/HeaderForDesktop";
import RejectTimesheetPopUp from "../pop-ups/RejectTimesheetPopUp";
import ValidateTimesheetPopUp from "../pop-ups/ValidateTimesheetPopUp";
import TimesheetValidatedSuccessfullyPopUp from "../pop-ups/TimesheetValidatedSuccessfullyPopUp";
import TimesheetRejectedSuccessfullyPopUp from "../pop-ups/TimesheetRejectedSuccessfullyPopUp";
import TimesheetSentSuccessfullyPopUp from "../pop-ups/TimesheetSentSuccessfullyPopUp";
import MessageSentSuccessfullyPopUp from "../pop-ups/MessageSentSuccessfullyPopUp";
import AlertHolidayPopUp from "../pop-ups/AlertHolidayPopUp";
import AlertHolidayToConfirmPopUp from "../pop-ups/AlertHolidayToConfirmPopUp";
import TimesheetSavedSuccessfullyPopUp from "../pop-ups/TimesheetSavedSuccessfullyPopUp";
import SendTimesheetPopUp from "../pop-ups/SendTimesheetPopUp";
import SendCandidateTimesheetPopUp from "../pop-ups/SendCandidateTimesheetPopUp";
import AddMessagePopUp from "../pop-ups/AddMessagePopUp";
import convertTodays from "../../../utils/convertTodays";
@inject("newTimesheetStore", "sessionStore", "modalStore", "appStore")
@observer
class Desktop extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    moment.locale(runtimeVars.APP_LANG);
    this.state = {
      ...initProps,
      isFirstTime: true,
      isFirstTime2: true,
      tab: props.tab || 1,
      showChat: false
    };
  }

  componentDidMount() {
    window.onbeforeunload = this.handleBeforeUnload; // Prompt when leaving the page
    const { newTimesheetStore } = this.props;
    const { isEdited } = newTimesheetStore;

    if (isEdited) {
      window.history.pushState(null, null, window.location.pathname); // Prevent URL change
    }
  }
  componentDidUpdate = () => {
    window.onbeforeunload = this.handleBeforeUnload; // Ensure prompt on page change
    const { newTimesheetStore } = this.props;
    const { isEdited } = newTimesheetStore;
    if (isEdited) {
      window.history.pushState(null, null, window.location.pathname);
    }
  };
  componentWillUnmount() {
    window.onbeforeunload = null; // Clean up the beforeunload event listener
  }
  handleBeforeUnload = e => {
    e.preventDefault();
    const message = ""; //necessary for some browsers
    e.returnValue = message;
    return message;
  };

  onChangeSegmentedControl = () =>
    this.setState({ tab: SegmentedControl.getSelectedTab() });

  toHoursAndMinutes = totalMinutes => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${this.padToTwoDigits(hours)}h${this.padToTwoDigits(minutes)}m`;
  };
  padToTwoDigits = num => num.toString().padStart(2, "0");

  TranslateConversationDetails(conversation) {
    const { i18n } = this.props;
    const types = {
      comment: i18n._(t`Commentaire`),
      tasks: i18n._(t`Tâches`),
      projects: i18n._(t`Projet`)
    };
    for (let i = 0; i < conversation.length; i++) {
      const obj = conversation[i];
      const objDetails = obj.details;
      for (let j = 0; j < objDetails.length; j++) {
        const detail = objDetails[j];
        if (detail.type in types) {
          detail.field = types[detail.type];
        }
      }
    }
    return conversation;
  }
  DeleteAllDaysOfTimesheet() {
    const { newTimesheetStore } = this.props;
    let { deleteAllDays } = newTimesheetStore;
    deleteAllDays();
  }

  render() {
    const { tab, showChat } = this.state;

    const { appStore, newTimesheetStore, sessionStore, i18n } = this.props;
    const { currentLanguage } = appStore;
    const { account } = sessionStore;
    const {
      timesheetPopUps,
      themes,
      workPlan,
      normalHours,
      specialHours,
      totalHours,
      getDayDetails,
      openClosePopUps,
      timesheet,
      holidays,
      conversation,
      warnings,
      isEdited
    } = newTimesheetStore;

    const {
      addTheme,
      moreInformation,
      addMessage,
      deleteRange,
      deleteTheme,
      limitOfThemes,
      dayDetails,
      sendTimesheet,
      sendCandidateTimesheet,
      rejectTimesheet,
      validateTimesheet,
      timesheetValidatedSuccessfully,
      timesheetRejectedSuccessfully,
      timesheetSentSuccessfully,
      timesheetSavedSuccessfully,
      messageSentSuccessfully,
      alertHoliday,
      alertHolidayToConfirm
    } = timesheetPopUps;

    const finalWorkPlan = [];
    workPlan?.map(element => {
      // const res = finalWorkPlan.find(
      //   el => el.day === element.day && el.theme.name === element.theme.name
      // );
      // if (!res)
      finalWorkPlan.push(element);
    });

    const warningLabel = {
      MAX_TIME_PER_DAY: i18n._(t`MAX_TIME_PER_DAY`),
      MAX_NORMAL_TIME_PER_DAY: i18n._(t`MAX_NORMAL_TIME_PER_DAY`),
      MAX_SPECIAL_TIME_PER_DAY: i18n._(t`MAX_SPECIAL_TIME_PER_DAY`),
      MAX_THEMES_PER_DAY: i18n._(t`MAX_THEMES_PER_DAY`),
      ABNORMAL_TIME_PER_DAY: i18n._(t`ABNORMAL_TIME_PER_DAY`)
    };
    const warningsToShow = warnings.reduce((acc, warning) => {
      const index = acc.findIndex(e => e.day == warning.day);
      if (index == -1) {
        acc.push({
          day: warning.day,
          warning: `- ${warningLabel[warning.warning]} (${Math.floor(
            warning.number / 60
          )}h${warning.number % 60}m)`,
          critical: warning.critical
        });
      } else {
        const newDay = {
          day: acc[index].day,
          warning: acc[index].warning.includes(warningLabel[warning.warning])
            ? acc[index].warning
            : acc[index].warning +
              "\n" +
              `- ${warningLabel[warning.warning]} (${Math.floor(
                warning.number / 60
              )}h${warning.number % 60}m)`,
          critical: warning.critical ? true : acc[index].critical
        };
        acc[index] = newDay;
      }
      return acc;
    }, []);

    return (
      <div>
        <Prompt
          when={isEdited}
          message={() => {
            window.history.pushState(null, null, window.location.pathname);
            return i18n._(
              t`Les modifications que vous avez apportées ne seront peut-être pas enregistrées.`
            );
          }}
        />
        <Modal
          centred
          isVisible={addTheme}
          withCloseButton={false}
          position="absolute"
        >
          <AddThemePopUp />
        </Modal>
        <Modal
          centred
          isVisible={moreInformation}
          withCloseButton={false}
          position="absolute"
        >
          <MoreInformationPopUp />
        </Modal>
        <Modal
          centred
          isVisible={addMessage}
          withCloseButton={false}
          position="absolute"
        >
          <AddMessagePopUp />
        </Modal>
        <Modal
          centred
          isVisible={deleteRange}
          withCloseButton={false}
          position="absolute"
        >
          <DeleteRangePopUp />
        </Modal>
        <Modal
          centred
          isVisible={deleteTheme}
          withCloseButton={false}
          position="absolute"
        >
          <DeleteThemePopUp />
        </Modal>
        <Modal
          centred
          isVisible={limitOfThemes}
          withCloseButton={false}
          position="absolute"
        >
          <LimitOfThemesPopUp />
        </Modal>
        <Modal
          centred
          isVisible={dayDetails}
          withCloseButton={false}
          position="absolute"
        >
          <DayDetailsPopUp />
        </Modal>
        <Modal
          centred
          isVisible={sendTimesheet}
          withCloseButton={false}
          position="absolute"
        >
          <SendTimesheetPopUp />
        </Modal>
        <Modal
          centred
          isVisible={sendCandidateTimesheet}
          withCloseButton={false}
          position="absolute"
        >
          <SendCandidateTimesheetPopUp />
        </Modal>
        <Modal
          centred
          isVisible={rejectTimesheet}
          withCloseButton={false}
          position="absolute"
        >
          <RejectTimesheetPopUp />
        </Modal>
        <Modal
          centred
          isVisible={validateTimesheet}
          withCloseButton={false}
          position="absolute"
        >
          <ValidateTimesheetPopUp />
        </Modal>
        <Modal
          centred
          isVisible={timesheetValidatedSuccessfully}
          withCloseButton={false}
          position="absolute"
        >
          <TimesheetValidatedSuccessfullyPopUp />
        </Modal>
        <Modal
          centred
          isVisible={alertHoliday}
          withCloseButton={false}
          position="absolute"
        >
          <AlertHolidayPopUp />
        </Modal>
        <Modal
          centred
          isVisible={alertHolidayToConfirm}
          withCloseButton={false}
          position="absolute"
        >
          <AlertHolidayToConfirmPopUp />
        </Modal>
        <Modal
          centred
          isVisible={timesheetRejectedSuccessfully}
          withCloseButton={false}
          position="absolute"
        >
          <TimesheetRejectedSuccessfullyPopUp />
        </Modal>
        <Modal
          centred
          isVisible={timesheetSentSuccessfully}
          withCloseButton={false}
          position="absolute"
        >
          <TimesheetSentSuccessfullyPopUp />
        </Modal>
        <Modal
          centred
          isVisible={timesheetSavedSuccessfully}
          withCloseButton={false}
          position="absolute"
        >
          <TimesheetSavedSuccessfullyPopUp />
        </Modal>
        <Modal
          centred
          isVisible={messageSentSuccessfully}
          withCloseButton={false}
          position="absolute"
        >
          <MessageSentSuccessfullyPopUp />
        </Modal>
        <Row>
          <HeaderForDesktop device="desktop" />
        </Row>
        <Row style={{ padding: "20px 15px 0 15px" }}>
          <Col xs={6} sm={12} md={12} lg={8} xl={8}>
            {account.role === "CANDIDATE" &&
              ["open", "rejected"].includes(timesheet.status) && (
                <AddPeriodForm />
              )}
            <MonthlyTimesheetNew
              key="workPlan"
              local={currentLanguage === "en" ? "gb" : "fr"}
              month={timesheet.month}
              year={timesheet.year}
              content={
                sessionStore.account.role === "CANDIDATE" &&
                ["open", "rejected"].includes(timesheet.status) &&
                i18n._(t`Utilisez les champs ci-dessus pour ajouter des heures`)
              }
              iconTooltip="precise hours"
              defaultSelectedDays={finalWorkPlan}
              warnings={warningsToShow}
              getSelectedDay={day => {
                if (day) {
                  getDayDetails(day);
                  openClosePopUps("dayDetails");
                }
              }}
              holidays={holidays}
              isCandidateandCanDelete={
                sessionStore.account.role === "CANDIDATE" &&
                ["open", "rejected"].includes(timesheet.status)
              }
              deleteAllDays={() => this.DeleteAllDaysOfTimesheet()}
              deletedtext={i18n._(t`Effacer tout`)}
            />
          </Col>
          <Col xs={6} sm={12} md={12} lg={4} xl={4}>
            <TotalHours
              style={{ margin: "20px auto" }}
              key={workPlan}
              totalHours={String(this.toHoursAndMinutes(totalHours))}
              totalDays={`${String(convertTodays(totalHours, i18n))}`}
              normalHours={String(this.toHoursAndMinutes(normalHours))}
              normalDays={`${String(convertTodays(normalHours, i18n))}`}
              specialHours={String(this.toHoursAndMinutes(specialHours))}
              specialDays={`${String(convertTodays(specialHours, i18n))}`}
              totalHoursPerDay={`${i18n._(t`8h/j`)}`}
              normalHoursPerDay={`${i18n._(t`8h/j`)}`}
              specialHoursPerDay={`${i18n._(t`8h/j`)}`}
              totalHoursText={i18n._(t`Heures totales`)}
              normalHoursText={i18n._(t`Heures normales`)}
              specialHoursText={i18n._(t`Heures spéciales`)}
              normalHoursColor="#C47E87"
              specialHoursColor="#96081C"
            />

            <SegmentedControl
              defaultTab={1}
              onClick={this.onChangeSegmentedControl}
              style={{ marginBottom: "20px" }}
              SegmentText1={i18n._(t`Vue projet`)}
              SegmentText2={i18n._(t`Vue type`)}
            />
            <div style={{ height: "60vh" }}>
              {tab === 1 && (
                <div className="scroll-container">
                  <AddTheme />
                  <ThemeVue key={themes.length} device="desktop" />
                </div>
              )}
              {tab === 2 && (
                <div className="scroll-container">
                  <TitleForTypeView />
                  <TypeVue />
                </div>
              )}

              <div className="timesheetComponent">
                {showChat && (
                  <TimesheetChat
                    messages={this.TranslateConversationDetails(conversation)}
                    send={v => console.log(v)}
                    unlimitedChat={true}
                    getComment={v => console.log(v)}
                    disabled={false}
                    withInputSection={false}
                    headerText={i18n._(t`comments`)}
                    withHeader={true}
                  ></TimesheetChat>
                )}
                <Chat
                  ChatFct={() => {
                    this.setState({ showChat: !showChat });
                  }}
                  titleChat={i18n._(t`Commentaires et annotations`)}
                ></Chat>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

Desktop.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  newTimesheetStore: PropTypes.shape({
    timesheet: PropTypes.shape({}),
    timesheetPopUps: PropTypes.shape({
      addTheme: PropTypes.bool,
      addMessage: PropTypes.bool,
      moreInformation: PropTypes.bool,
      deleteRange: PropTypes.bool,
      deleteTheme: PropTypes.bool,
      limitOfThemes: PropTypes.bool,
      dayDetails: PropTypes.bool,
      sendTimesheet: PropTypes.bool,
      sendCandidateTimesheet: PropTypes.bool,
      rejectTimesheet: PropTypes.bool,
      validateTimesheet: PropTypes.bool,
      timesheetValidatedSuccessfully: PropTypes.bool,
      timesheetRejectedSuccessfully: PropTypes.bool,
      timesheetSentSuccessfully: PropTypes.bool,
      timesheetSavedSuccessfully: PropTypes.bool,
      messageSentSuccessfully: PropTypes.bool
    }),
    openClosePopUps: PropTypes.func,
    getDayDetails: PropTypes.func,
    themes: PropTypes.shape({}),
    conversation: PropTypes.shape({}),
    workPlan: PropTypes.shape({}),
    warnings: PropTypes.shape({}),
    normalHours: PropTypes.number,
    specialHours: PropTypes.number,
    totalHours: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    timesheetPopUp: PropTypes.number,
    changeIsVisible2: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Desktop));
