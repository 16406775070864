import gql from "graphql-tag";

export const USER_FAVED_MISSIONS_QUERY = gql`
  query UserFavedMissions {
    userFavedMissions {
      id
    }
  }
`;

export const GET_CATEGORIES = gql`
  {
    categories {
      name
    }
  }
`;

export const GET_BUSINESS_SECTOR = gql`
  {
    businessSector {
      name
    }
  }
`;
export const GET_SKILLS = gql`
  {
    skills {
      name
    }
  }
`;

export const GET_SKILLS_CATEGORIES = gql`
  {
    categories {
      id
      name
      skills {
        id
        name
      }
    }
  }
`;

export const GET_BUSINESS_SECTORS = gql`
  {
    businessSectors {
      name
    }
  }
`;

export const GET_SPECIALTIES = gql`
  {
    specialties {
      name
    }
  }
`;
export const GET_FINANCE_SPECIALTIES = gql`
  {
    specialtiesFinance {
      name
    }
  }
`;
