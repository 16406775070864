import { Trans, t } from "@lingui/macro";
import roundDownDays from "./roundDownDays";
const convertTodays = (totalMinutes, i18n) => {
  const totals = roundDownDays(totalMinutes);
  var days = totals.toString().split(".")[0];
  const hoursString = totals.toString().split(".")[1];
  const hours = parseFloat(0 + "." + hoursString);
  days =
    hours >= 0.75
      ? parseInt(days) + 1
      : hours > 0
      ? parseInt(days) + 0.5
      : parseInt(days) + 0;

  // Customize the term for "days" based on the number of days
  const daysTerm = days <= 1 ? i18n._(t`jour`) : i18n._(t`jours`);

  return `${padToTwoDigits(days)} ${daysTerm}`;
};

const padToTwoDigits = num => num.toString().padStart(2, "0");

export default convertTodays;
