import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  Col,
  Row,
  DateRangePicker,
  SelectNewVersion,
  SwitchButton3,
  TextInputV2,
  Validation,
  FormGroup,
  Button,
  PopUp,
  MultiDateRangePicker
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";

const oneGroupData2 = i18n => {
  return {
    groupA: [
      { text: i18n._(t`Normal`), value: "normal" },
      { text: i18n._(t`Supplémentaire`), value: "extra" },
      { text: i18n._(t`Astreinte`), value: "penalty" }
    ]
  };
};

@inject("newTimesheetStore", "appStore")
@observer
class AddTimeManuallyPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      periods: null,
      theme: "N/A",
      format: "on-site",
      hours: 8,
      minutes: 0,
      type: "normal",
      message: "",
      isValidTime: "",
      isValidPeriod: "",
      isValidType: "",
      isValidHours: "",
      isHoursValid: true,
      isValidMinutes: "",
      isMinutesValid: true,
      dayOrHours: "days"
    };

    this.dataPreparation = this.dataPreparation.bind(this);
  }

  validate = () => {
    const {
      periods,
      theme,
      format,
      hours,
      minutes,
      type,
      message,
      isMinutesValid,
      isHoursValid
    } = this.state;
    const { newTimesheetStore, i18n } = this.props;
    const {
      addPeriod,
      openClosePopUps,
      changeThemeToPreselect,
      themeToPreselect
    } = newTimesheetStore;

    let valid = true;

    if (!periods) {
      this.setState({ isValidPeriod: i18n._(t`Ce champs est requis`) });
      valid = false;
    }
    if (hours == 0 && minutes == 0) {
      this.setState({
        isHoursValid: false,
        isValidHours: i18n._(t`Ce champs est requis`)
      });
      valid = false;
    } else {
      if (!isHoursValid || !isMinutesValid) {
        valid = false;
      }
    }
    if (!type) {
      this.setState({ type: "normal" });
    }

    if (valid && periods && periods.length > 0) {
      periods.forEach(period => {
        const { startDate, endDate } = period;
        addPeriod({
          start: startDate,
          end: endDate,
          hours: parseInt(hours, 10),
          minutes: parseInt(minutes, 10),
          themeName: theme || themeToPreselect,
          type: type, // Utilisez le type de la période sélectionnée
          description: message,
          place: format
        });
      });
      this.setState({
        periods: null,
        theme: "N/A",
        place: "on-site",
        hours: 8,
        minutes: 0,
        type: null
      });
      openClosePopUps("addTimeManually");
      changeThemeToPreselect(null);
    }
  };

  dataPreparation = () => {
    const { newTimesheetStore, i18n } = this.props;
    const { themes, themeToPreselect } = newTimesheetStore;
    const themesList = [];
    themes.map(theme => {
      theme.name &&
        themesList.push({
          text: theme.name,
          value: theme.name,
          selected:
            themeToPreselect && theme.name == themeToPreselect ? true : false,
          icon: { type: "dot", color: theme.color }
        });
    });

    themesList.push({
      text: i18n._(t`Ajouter un nouveau projet`),
      value: "ADD_NEW_THEME",
      icon: { type: "plus", color: "#242424" },
      last: true
    });

    return themesList;
  };

  onSelectThemeFunc = opt => {
    // Note: Variable destructuring is preferred before if-else logic for global variables

    const { newTimesheetStore } = this.props;
    const {
      openClosePopUps,
      themes,
      changeThemeToPreselect,
      LIMIT_THEMES
    } = newTimesheetStore;
    if (opt.value === "ADD_NEW_THEME") {
      changeThemeToPreselect(true);
      if (themes && themes.length === LIMIT_THEMES)
        openClosePopUps("limitOfThemes");
      else openClosePopUps("addTheme");
    } else {
      this.setState({ theme: opt.value });
      changeThemeToPreselect(opt.value);
    }
  };

  onSelectTypeFunc = opt => {
    this.setState({ type: opt.value, isValidType: "" });
  };

  keyDownfn = () => {
    console.log("A key was clicked");
  };

  enterKeyPressedfn = () => {
    console.log("Enter key was pressed");
  };

  escapeKeyPressedfn = () => {
    console.log("escape key was pressed");
  };

  onIconClicked = () => {
    console.log("the icon was clicked");
  };

  onChangeHours = e => {
    const { i18n } = this.props;
    if (e.target.value > 24) {
      this.setState({
        hours: e.target.value,
        isHoursValid: false,
        isValidHours: i18n._(t`Max 24h`)
      });
    } else {
      this.setState({
        hours: e.target.value,
        isValidHours: "",
        isHoursValid: true
      });
    }
  };

  onChangeMinutes = e => {
    const { i18n } = this.props;
    if (e.target.value > 60) {
      this.setState({
        minutes: e.target.value,
        isMinutesValid: false,
        isValidMinutes: i18n._(t`Max 60m`)
      });
    } else {
      this.setState({
        minutes: e.target.value,
        isMinutesValid: true,
        isValidMinutes: ""
      });
    }
  };

  render() {
    const {
      format,
      message,
      isValidPeriod,
      isValidTime,
      isValidType,
      isValidHours,
      isHoursValid,
      isValidMinutes,
      isMinutesValid,
      dayOrHours
    } = this.state;
    const { newTimesheetStore, device, i18n, appStore } = this.props;
    const { openClosePopUps, themes, timesheet } = newTimesheetStore;
    const { currentLanguage } = appStore;
    const themesList = this.dataPreparation();
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => openClosePopUps("addTimeManually")}
      >
        <div
          style={{
            position: "relative",
            height: "500px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "6px"
            }}
          >
            <Trans>Ajouter heures</Trans>
          </div>
          <form>
            <Row style={{ marginBottom: "3px" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                <FormGroup>
                  <label
                    className="timesheet-form-label"
                    htmlFor="user_first_name"
                  >
                    <Trans>Projets</Trans>
                  </label>
                  <Validation errorMessage="" valid={true}>
                    <SelectNewVersion
                      key={themes.length}
                      options={{ groupA: themesList }}
                      placeholder={i18n._(t`Sélectionner un projet`)}
                      onSelect={this.onSelectThemeFunc}
                      valid={true}
                      disabled={false}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col lg={12} xl={12} md={12} sm={6} xs={6}>
                <FormGroup>
                  <label
                    className="timesheet-form-label"
                    htmlFor="user_first_name"
                  >
                    <Trans>Date</Trans>
                  </label>
                  <Validation
                    errorMessage={isValidPeriod}
                    valid={isValidPeriod == ""}
                  >
                    <MultiDateRangePicker
                      placeholder={i18n._(t`Aujourd’hui`)}
                      onChange={v => {
                        this.setState({ periods: v, isValidPeriod: "" });
                      }}
                      locale={currentLanguage}
                      year={timesheet.year}
                      month={timesheet.month}
                      acceptText={i18n._(t`Accepter`)}
                      resetText={i18n._(t`Réinitialiser `)}
                      warningMsg={i18n._(
                        t`Week-end et Jours fériés seront déselectionnés de la plage`
                      )}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                <FormGroup>
                  <label className="timesheet-form-label" htmlFor="format">
                    {dayOrHours === "days"
                      ? i18n._(t`Jour plein`)
                      : i18n._(t`Heures`)}
                  </label>
                  <Validation errorMessage="" valid={true}>
                    <SwitchButton3
                      defaultTab={dayOrHours === "days" ? 1 : 2}
                      SegmentText1={i18n._(t`Jour plein`)}
                      SegmentText2={i18n._(t`Heures`)}
                      onClick={() => {
                        this.setState({
                          hours: 8,
                          dayOrHours:
                            SwitchButton3.getSelectedTab() === 1
                              ? "days"
                              : "hours"
                        });
                      }}
                      style={{ marginBottom: "5px" }}
                    />
                  </Validation>
                </FormGroup>

                {dayOrHours === "hours" && (
                  <Col
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    xs={6}
                    style={{ padding: "0px" }}
                  >
                    <Row>
                      <Col
                        lg={6}
                        xl={6}
                        md={6}
                        sm={6}
                        xs={3}
                        style={{ marginRight: "inherit" }}
                      >
                        <FormGroup>
                          <Validation
                            errorMessage={isValidHours}
                            valid={isHoursValid}
                          >
                            <TextInputV2
                              placeholder="08h"
                              type="number"
                              min="0"
                              max="24"
                              onChange={this.onChangeHours}
                            />
                          </Validation>
                        </FormGroup>
                      </Col>
                      <Col lg={6} xl={6} md={6} sm={6} xs={3}>
                        <FormGroup>
                          <Validation
                            errorMessage={isValidMinutes}
                            valid={isMinutesValid}
                          >
                            <TextInputV2
                              placeholder="00m"
                              type="number"
                              min="0"
                              max="60"
                              onChange={this.onChangeMinutes}
                            />
                          </Validation>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Col>
              <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                <FormGroup>
                  <label
                    className="timesheet-form-label"
                    htmlFor="user_first_name"
                  >
                    <Trans>Type d’heures effectuées</Trans>
                  </label>
                  <Validation errorMessage="" valid={true}>
                    <SelectNewVersion
                      options={oneGroupData2(i18n)}
                      placeholder={i18n._(t`Normal`)}
                      onSelect={this.onSelectTypeFunc}
                      valid={true}
                      disabled={false}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                <FormGroup>
                  <label
                    className="timesheet-form-label"
                    htmlFor="user_first_name"
                  >
                    <Trans>Format</Trans>
                  </label>
                  <Validation errorMessage="" valid={true}>
                    <SwitchButton3
                      defaultTab={format === "on-site" ? 1 : 2}
                      SegmentText1={i18n._(t`Présentiel`)}
                      SegmentText2={i18n._(t`Télétravail`)}
                      onChange={() => {
                        this.setState({
                          format:
                            SwitchButton3.getSelectedTab() === 1
                              ? "on-site"
                              : "remote"
                        });
                      }}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={6}>
                <FormGroup>
                  <label
                    style={{
                      position: "relative",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#242424"
                    }}
                  >
                    <Trans>Remarques</Trans>
                  </label>
                  <Validation errorMessage="" valid={true}>
                    <textarea
                      className={styles.textArea}
                      id="addMessage"
                      placeholder={i18n._(t`Sur quoi avez-vous travaillé ?`)}
                      value={message}
                      onChange={e => this.setState({ message: e.target.value })}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            </Row>

            <Button
              variant="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                this.validate();
              }}
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                letterSpacing: "3px",
                width: "100%"
              }}
            >
              <Trans>Ajouter</Trans>
            </Button>
          </form>
        </div>
      </PopUp>
    );
  }
}

AddTimeManuallyPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    themes: PropTypes.shape
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(AddTimeManuallyPopUp)));
