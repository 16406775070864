function formatBlogTitle(blogTitle) {
  if (!blogTitle) return;
  
  // Replace common HTML entities and special characters
  const titleWithEntitiesReplaced = blogTitle
    .replace(/&rsquo;/g, "'")
    .replace(/’/g, "'")
    .replace(/'/g, "-");

  // Replace accented characters with non-accented equivalents
  const titleWithoutAccents = titleWithEntitiesReplaced
    .replace(/[àáâãäå]/g, "a")
    .replace(/[ç]/g, "c")
    .replace(/[èéêë]/g, "e")
    .replace(/[ìíîï]/g, "i")
    .replace(/[ñ]/g, "n")
    .replace(/[òóôõö]/g, "o")
    .replace(/[ùúûü]/g, "u")
    .replace(/[ýÿ]/g, "y");

  const urlFriendlyTitle = encodeURIComponent(
    titleWithoutAccents
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "") // Supprime les caractères spéciaux sauf les tirets
  );

  return urlFriendlyTitle;
}

export default formatBlogTitle;
