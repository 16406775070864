import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { t, Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import { Button, Col, H2 } from "cf-neo-ui";
import Books from "../../../components/testimonial-carousel";
import {
  latestPostsQuery,
  categoriesRefEn,
  categoriesRefFr
} from "../../blog/query";
import decode from "../../../utils/Utf8Text";

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section11 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }
  toResources = post => {
    window.open(post.download_link, "_blank");
    // history.push(`/blog/resources/${id}`);
  };
  postsToBooks = posts => {
    const { i18n } = this.props;
    const books = [];
    posts.map(post =>
      books.push({
        id: post.id,
        image: post.featured_image2_url
          ? post.featured_image2_url
          : post.featured_media && post.featured_media.source_url,
        name: "",
        job: "",
        paragraph: decode(post.title),
        buttonText: i18n._(t`Accéder à la ressource`),
        onClickButton: () => this.toResources(post)
      })
    );
    return books;
  };

  render() {
    const { appStore, history } = this.props;
    const { currentLanguage } = appStore;

    const { key } = this.state;
    let categoriesRef = categoriesRefFr;
    if (currentLanguage === "en") categoriesRef = categoriesRefEn;
    const active =
      typeof window !== "undefined" &&
      window.location.search.substring(
        window.location.search.lastIndexOf("?") + 1
      );
    return (
      <div className={classes.sec10bot}>
        <H2 className={classes.mainText}>
          <Trans>
            Nos ressources pour les experts finance et les entreprises qui
            recrutent
          </Trans>
        </H2>

        <Query
          query={latestPostsQuery}
          variables={{
            page: 1,
            perPage: 4,
            categoryIds: categoriesRef.resources,
            language: currentLanguage
          }}
        >
          {({ data }) => {
            if (data && data.posts.length) {
              return (
                <div style={{ marginBottom: "30px" }}>
                  <Books
                    testimonials={this.postsToBooks(data.posts)}
                    pLines={2}
                  />
                </div>
              );
            }
            return (
              <div
                style={{
                  marginBottom: "30px",
                  "font-size": "larger",
                  color: "white",
                  marginTop: "30px"
                }}
              >
                <Trans>Il n’y a pas encore de ressources.</Trans>
              </div>
            );
          }}
        </Query>

        <Button
          size="medium"
          variant="quaternary"
          classNameText={
            " background: linear-gradient(76deg, #8d0417,#d3354a);background-size: 101% 101%; color: #ffffff;> span { margin: 0px;;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
          }
          className={classes.buttonToBlogSec11}
          onClick={() => {
            history.push("/blog");
          }}
        >
          <Trans>Découvrir toutes les ressources</Trans>
        </Button>
      </div>
    );
  }
}

Section11.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section11));
