import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Trans } from "@lingui/macro";
import { Button, Col } from "cf-neo-ui";
import { H1 } from "cf-neo-ui/lib/typography";

@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { history, appStore } = this.props;
    const { currentLanguage } = appStore;
    let baseURL;
    let callURL;
    if (typeof window !== "undefined") {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      baseURL = url.origin + "/";
    }
    callURL = `${baseURL}?cal=marine-danjou`;

    return (
      <div>
        <H1 className={classes.mainTextSec1}>
          <Trans>Vos projets Finance méritent l’excellence</Trans>
        </H1>

        <Col xl={12} lg={12} md={12} sm={12} xs={6} className={classes.bigCol}>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={6}
            className={classes.blockinputscenter}
          >
            <Col
              xl={12}
              lg={12}
              md={10}
              sm={10}
              xs={6}
              className={classes.blockinputs}
            >
              <Col
                xl={6}
                lg={5}
                md={5}
                sm={12}
                xs={6}
                className={classes.widgetBody}
              >
                <Button
                  size="medium"
                  variant="quaternary"
                  classNameText={
                    "height: 60px; width: 100%; background: #ffffff; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid #979797; > span { margin: 0px; font-family: Montserrat;font-size: 13px;font-style: normal;font-weight: 600;line-height: 26px;letter-spacing: 0.3px;}"
                  }
                  onClick={() => history.push("/signup?type=enterprise")}
                >
                  <Trans>Recruter un talent CDI ou Freelance</Trans>
                </Button>
              </Col>
              <Col
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={6}
                className={classes.widgetBody}
              >
                <Button
                  size="medium"
                  variant="quaternary"
                  classNameText={
                    "height: 60px; background: transparent ; background-size: 101% 101%;color: #FFF; box-shadow: 0px 4px 4px 0px rgba(136, 136, 136, 0.25);border-radius: 5px;&:hover { background: white; color: black;}; > span { margin: 0px;font-family: Montserrat;font-size: 13px;font-style: normal;font-weight: 600;line-height: 26px;letter-spacing: 0.3px;}"
                  }
                  //onClick={() => window.open("/?cal=marine-danjou", "_blank")}
                  onClick={() => window.location.replace(callURL)}
                  // onClick={() => history.push("/?cal=marine-danjou")}
                >
                  <Trans>Prendre un RDV</Trans>
                </Button>
              </Col>
              <Col
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={6}
                className={classes.widgetBody}
              >
                <Button
                  size="medium"
                  variant="quaternary"
                  icon="whatsapp"
                  iconPosition="left"
                  iconTop="calc(50% - 15.5px)"
                  width="30"
                  height="30"
                  iconColor="white"
                  classNameText={
                    "height: 60px; width: 100%; background: #25D366 ; background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px; > span {font-family: Montserrat;  margin: 0px;margin-left: 38px; ;font-size: 16px;font-style: normal;font-weight: 600;line-height: 26px;letter-spacing: 0.3px;}"
                  }
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send/?phone=33757903093&text&type=phone_number&app_absent=0",
                      "_blank"
                    )
                  }
                >
                  <Trans>Contacter via Whatsapp</Trans>
                </Button>
              </Col>
            </Col>

            <Col
              xl={9}
              lg={9}
              md={9}
              sm={9}
              xs={6}
              className={classes.blockButtonSec1}
            >
              <Col
                xl={7}
                lg={7}
                md={7}
                sm={7}
                xs={3.3}
                className={classes.buttonSec1}
              >
                <Button
                  size="medium"
                  variant="quaternary"
                  classNameText={
                    "height: 60px; background: transparent; background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px;border: 2px solid white;&:hover { background: white; color: black;}; > span {font-family: Montserrat; margin: 0px; font-size: 13px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                  }
                  onClick={() => history.push("/signup?type=enterprise")}
                >
                  <Trans>Créer un compte client</Trans>
                </Button>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={2.7}
                className={classes.buttonSec1}
              >
                <Button
                  size="medium"
                  variant="quaternary"
                  classNameText={
                    "height: 60px; background: transparent; background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px;border: 2px solid white; &:hover { background: white; color: black;}; > span { font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
                  }
                  onClick={() => history.push("/login")}
                >
                  <Trans>Se connecter</Trans>
                </Button>
              </Col>
            </Col>
          </Col>
          <Col xl={5} lg={5} md={5} sm={5} xs={6} className={classes.imgSec1}>
            <picture>
              <source type="images/png" srcSet="/assets/images/rectangle.png" />
              <img
                className={classes.imgSec}
                src="/assets/images/rectangle.png"
                alt="Logo_Mindquest_Finance_3D"
              />
            </picture>
          </Col>
        </Col>
      </div>
    );
  }
}

Section1.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section1));
