/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function(n, ord) {
      if (ord) return n == 1 ? "one" : "other";
      return n >= 0 && n < 2 ? "one" : "other";
    }
  },
  messages: {
    '"Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin"':
      '"Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin"',
    "'' au vivier le": "' au vivier le",
    "'' du vivier le": "' du vivier le",
    "'' le": "' le",
    "(8h/jours)": "(8h/jour)",
    "(Vivier par d\xE9faut)": "(Vivier par d\xE9faut)",
    "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process;":
      "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process;",
    "(where you are a client) candidates for the purpose of arranging interviews and engagements;":
      "(where you are a client) candidates for the purpose of arranging interviews and engagements;",
    "* Ce commentaire sera visible par le client":
      "* Ce commentaire sera visible par le client",
    "* Ce commentaire sera visible par le consultant":
      "* Ce commentaire sera visible par le consultant",
    ", mis \xE0 jour le": ", mis \xE0 jour le",
    "...ou deposer votre fiche de poste ici":
      "...ou d\xE9poser ma fiche de poste ici",
    "...ou simplement deposer votre CV ici": "...ou d\xE9poser mon CV ici",
    "1 min": "1 min",
    "1)   Definition of cookies": "1)   D\xE9finition des cookies",
    "1) Definition of cookies": "1)   D\xE9finition des cookies",
    "1.   Definitions": "1.   D\xE9finitions",
    "1. Definitions": "1.   D\xE9finitions",
    "1.5 mois de d\xE9lai de recrutement moyen en CDI":
      "1.5 mois de d\xE9lai de recrutement moyen en CDI",
    "10. Amendments to the Personal Data Protection Policy":
      "10. Modifications de la Politique de protection des Donn\xE9es \xE0 caract\xE8re personnel",
    "100 000": "100 000",
    "130 Clients grand compte nous font confiance, ce n\u2019est pas un hasard":
      "130 Clients grand compte nous font confiance, ce n\u2019est pas un hasard",
    "1st Party": "1st Party",
    "2 \xE8me signataire": "2 \xE8me signataire",
    "2)   Managing cookies": "2)   Gestion des cookies",
    "2) Managing cookies": "2)   Gestion des cookies",
    "2.   General rules on the collection and processing of personal data":
      "2.   R\xE8gles g\xE9n\xE9rales applicables en mati\xE8re de collecte et de traitement de donn\xE9es \xE0 caract\xE8re personnelles",
    "2. General rules on the collection and processing of personal data":
      "2. R\xE8gles g\xE9n\xE9rales applicables en mati\xE8re de collecte et de traitement de donn\xE9es \xE0 caract\xE8re personnelles",
    "3 RD Party": "3rd Party",
    "3)   Cookies used by the company":
      "3)   Cookies utilis\xE9s par Club Freelance",
    "3) Cookies used by the company":
      "3)   Cookies utilis\xE9s par Club Freelance",
    "3.   Nature of the collection of personal data":
      "3.   Nature de la collecte des Donn\xE9es \xE0 caract\xE8re personnel",
    "3. Nature of the collection of personal data":
      "3. Nature de la collecte des Donn\xE9es \xE0 caract\xE8re personnel",
    "30 jours": "30 jours",
    "4.   Purpose of the collection of personal data":
      "4.   Finalit\xE9 de la collecte des Donn\xE9es \xE0 caract\xE8re personne",
    "4. Purpose of the collection of personal data":
      "4.   Finalit\xE9 de la collecte des Donn\xE9es \xE0 caract\xE8re personne",
    "46% De nos talents Freelances sont des seniors":
      "46% De nos talents Freelances sont des seniors",
    "5.   Recipient (s) of the collection of personal data":
      "5.   Destinataire(s) de la collecte des Donn\xE9es \xE0 caract\xE8re personnel",
    "5. Recipient (s) of the collection of personal data":
      "5. Destinataire(s) de la collecte des Donn\xE9es \xE0 caract\xE8re personnel",
    "6.   Storage period for personal data":
      "6.   Dur\xE9e de conservation des Donn\xE9es \xE0 caract\xE8re personnel",
    "6. Storage period for personal data":
      "6.   Dur\xE9e de conservation des Donn\xE9es \xE0 caract\xE8re personnel",
    "7.   Exercise of the rights of data subjects":
      "7.   Exercice des droits des Personnes concern\xE9es",
    "7. Exercise of the rights of data subjects":
      "7.   Exercice des droits des Personnes concern\xE9es",
    "72% De nos talents Freelances sont prolong\xE9s \xE0 l\u2019issue de leur mission":
      "72% De nos talents Freelances sont prolong\xE9s \xE0 l\u2019issue de leur mission",
    "8.   Transfer of personal data outside the European Union":
      "8.   Transfert de Donn\xE9es \xE0 caract\xE8re personnel hors de l\u2019Union Europ\xE9enne",
    "8. Transfer of personal data outside the European Union":
      "8.   Transfert de Donn\xE9es \xE0 caract\xE8re personnel hors de l\u2019Union Europ\xE9enne",
    "8h/j": "8h/j",
    "8h/jour": "8h/jour",
    "8h/jours": "8h/jours",
    "9.   Links to other websites and social media":
      "9.   Liens vers d\u2019autres sites internet et r\xE9seaux sociaux",
    "9. Links to other websites and social media":
      "9.   Liens vers d\u2019autres sites internet et r\xE9seaux sociaux",
    "<0>  Club FREELANCE LIMITED </0>, a company registered in England and Wales under number 9050953, with  registered  office  at  Biscuit  Factory,  100  Drummond  Rd,  London  SE16 4DG, United Kingdom (\u2018the <1> Company</1>\u2019) attaches great importance to the protection of personal data which it has to collect and process in the course of its activity from both the users of its services and the platform it operates.":
      "<0>  CLUB FREELANCE LIMITED </0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG,  Royaume-Uni.  (ci-apr\xE8s  la  \xAB<1> Soci\xE9t\xE9</1> \xBB)  attache  une  grande  importance  \xE0  la  protection des donn\xE9es \xE0 caract\xE8re personnel qu\u2019elle a \xE0 collecter et traiter dans le cadre de son activit\xE9 tant aupr\xE8s des utilisateurs de ses services et de la plateforme qu\u2019elle exploite.",
    "<0> Club FREELANCE LIMITED </0>, a company registered in England and Wales under number 9050953, with registered office at Biscuit Factory, 100 Drummond Rd, London SE16 4DG, United Kingdom (\u2018the <1> Company</1>\u2019) attaches great importance to the protection of personal data which it has to collect and process in the course of its activity from both the users of its services and the platform it operates.":
      "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG,  Royaume-Uni.  (ci-apr\xE8s  la  \xAB<1> Soci\xE9t\xE9</1> \xBB)  attache  une  grande  importance  \xE0  la  protection des donn\xE9es \xE0 caract\xE8re personnel qu\u2019elle a \xE0 collecter et traiter dans le cadre de son activit\xE9 tant aupr\xE8s des utilisateurs de ses services et de la plateforme qu\u2019elle exploite.",
    "<0> Plateforme </0>  d\xE9signe  la  plateforme  de  mise  en  relation  accessible  en  ligne  \xE0 l\u2019adresse <1>https://mindquest.io</1> , inclut notamment le site internet pr\xE9cit\xE9, l\u2019ensemble des services pouvant y \xEAtre propos\xE9s, logiciels applicatifs prot\xE9g\xE9s ou non par tout droit de propri\xE9t\xE9 intellectuelle.":
      "<0> Plateforme </0>  d\xE9signe  la  plateforme  de  mise  en  relation  accessible  en  ligne  \xE0 l\u2019adresse <1>https://mindquest.io</1> , inclut notamment le site internet pr\xE9cit\xE9, l\u2019ensemble des services pouvant y \xEAtre propos\xE9s, logiciels applicatifs prot\xE9g\xE9s ou non par tout droit de propri\xE9t\xE9 intellectuelle.",
    "<0> Plateforme </0> d\xE9signe la plateforme de mise en relation accessible en ligne \xE0 l\u2019adresse <1>https://mindquest.io</1> , inclut notamment le site internet pr\xE9cit\xE9, l\u2019ensemble des services pouvant y \xEAtre propos\xE9s, logiciels applicatifs prot\xE9g\xE9s ou non par tout droit de propri\xE9t\xE9 intellectuelle.":
      "<0> Plateforme </0> d\xE9signe la plateforme de mise en relation accessible en ligne \xE0 l\u2019adresse <1>https://mindquest.io</1> , inclut notamment le site internet pr\xE9cit\xE9, l\u2019ensemble des services pouvant y \xEAtre propos\xE9s, logiciels applicatifs prot\xE9g\xE9s ou non par tout droit de propri\xE9t\xE9 intellectuelle.",
    "<0>A partir {0}</0>": function(a) {
      return ["<0>A partir ", a("0"), "</0>"];
    },
    "<0>Assignment</0> means  the  period  during  which  Talent  renders  services  as  a  Freelancer  or  an employee of an umbrella company.":
      "<0>Assignment</0> means  the  period  during  which  Talent  renders  services  as  a  Freelancer  or  an employee of an umbrella company.",
    "<0>Assignment</0> means the period during which Talent renders services as a Freelancer or an employee of an umbrella company.":
      "<0>Assignment</0> means the period during which Talent renders services as a Freelancer or an employee of an umbrella company.",
    "<0>Back to website</0>": "<0>Back to Mindquest</0>",
    "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG,  Royaume-Uni, ayant  pour  num\xE9ro  individuel  d\u2019identification  TVA  britannique  GB19 4905473.":
      "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG,  Royaume-Uni, ayant  pour  num\xE9ro  individuel  d\u2019identification  TVA  britannique  GB19 4905473.",
    "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG, Royaume-Uni, ayant pour num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473.":
      "<0>CLUB FREELANCE LIMITED</0>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond road, London SE16 4DG, Royaume-Uni, ayant pour num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473.",
    "<0>Ce num\xE9ro est manquant ou erron\xE9 ? </0><1>Je le sugg\xE8re</1>":
      "<0>Ce num\xE9ro est manquant ou erron\xE9 ? </0><1>Je le sugg\xE8re</1>",
    "<0>Client  (s)</0>   d\xE9signe(nt)  toute  personne  morale  ou  physique  ayant cr\xE9\xE9  un  compte  et  pouvant proposer  une  offre  d\u2019emploi  ou  une  mission  dont les  Utilisateurs  r\xE9f\xE9renc\xE9s  et  inscrits  sur  la Plateforme peuvent postuler.":
      "<0>Client  (s)</0>   d\xE9signe(nt)  toute  personne  morale  ou  physique  ayant cr\xE9\xE9  un  compte  et  pouvant proposer  une  offre  d\u2019emploi  ou  une  mission  dont les  Utilisateurs  r\xE9f\xE9renc\xE9s  et  inscrits  sur  la Plateforme peuvent postuler.",
    "<0>Client (s)</0>  means any legal or natural person that has created an account and can offer a job offer or assignment for which users registered on the Platform can apply.":
      "<0>Client  (s)</0>  d\xE9signe(nt)  toute  personne  morale  ou  physique  ayant  cr\xE9\xE9  un  compte  et pouvant proposer une offre d\u2019emploi ou une mission dont les Utilisateurs r\xE9f\xE9renc\xE9s et inscrits sur la Plateforme peuvent postuler.",
    "<0>Client (s)</0> d\xE9signe(nt) toute personne morale ou physique ayant cr\xE9\xE9 un compte et pouvant proposer une offre d\u2019emploi ou une mission dont les Utilisateurs r\xE9f\xE9renc\xE9s et inscrits sur la Plateforme peuvent postuler.":
      "<0>Client (s)</0> d\xE9signe(nt) toute personne morale ou physique ayant cr\xE9\xE9 un compte et pouvant proposer une offre d\u2019emploi ou une mission dont les Utilisateurs r\xE9f\xE9renc\xE9s et inscrits sur la Plateforme peuvent postuler.",
    "<0>Client (s)</0> means any legal or natural person that has created an account and can offer a job offer or assignment for which users registered on the Platform can apply.":
      "<0>Client  (s)</0>  d\xE9signe(nt)  toute  personne  morale  ou  physique  ayant  cr\xE9\xE9  un  compte  et pouvant proposer une offre d\u2019emploi ou une mission dont les Utilisateurs r\xE9f\xE9renc\xE9s et inscrits sur la Plateforme peuvent postuler.",
    "<0>Naviguer horizentalement ou basculez votre \xE9cran en position paysage pour consulter toutes les statistiques</0>":
      "<0>Naviguer horizentalement ou basculez votre \xE9cran en position paysage pour consulter toutes les statistiques</0>",
    "<0>Quand as tu rejoins Mindquest ?</0><1/>J'ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J'accompagne les experts finance et les entreprises dans la recherche des meilleures opportunit\xE9s et des meilleurs talents. Je m'assure de trouver la bonne expertise pour solutionner un besoin client.":
      "<0>Quand as tu rejoins Mindquest ?</0><1/>J'ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J'accompagne les experts finance et les entreprises dans la recherche des meilleures opportunit\xE9s et des meilleurs talents. Je m'assure de trouver la bonne expertise pour solutionner un besoin client.",
    "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance dans la recherche des meilleures opportunit\xE9s. En collaboration avec le reponsable de compte, je m\u2019assure de trouver la bonne expertise pour solutionner un besoin client.":
      "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance dans la recherche des meilleures opportunit\xE9s. En collaboration avec le reponsable de compte, je m\u2019assure de trouver la bonne expertise pour solutionner un besoin client.",
    "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance dans la recherche des meilleures opportunit\xE9s. En collaboration avec le reponsable de compte, je m\u2019assure de trouver la bonne expertise pour un solutioner un besoin client.":
      "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance dans la recherche des meilleures opportunit\xE9s. En collaboration avec le reponsable de compte, je m\u2019assure de trouver la bonne expertise pour un solutioner un besoin client.",
    "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance et les entreprises dans la recherche des meilleures opportunit\xE9s et des meilleurs talents. Je m\u2019assure de trouver la bonne expertise pour solutionner un besoin client.":
      "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance et les entreprises dans la recherche des meilleures opportunit\xE9s et des meilleurs talents. Je m\u2019assure de trouver la bonne expertise pour solutionner un besoin client.",
    "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance et les entreprises dans la recherche des meilleures opportunit\xE9s et des meilleurs talents. Je m\u2019assure de trouver la bonne expertise pour un solutioner un besoin client.":
      "<0>Quand as tu rejoins Mindquest ?</0><1/>J\u2019ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone<2/><3/><4>Quel est ton m\xE9tier au quotidien ?</4><5/>J\u2019accompagne les experts finance et les entreprises dans la recherche des meilleures opportunit\xE9s et des meilleurs talents. Je m\u2019assure de trouver la bonne expertise pour un solutioner un besoin client.",
    "<0>Talent (s)</0> d\xE9signe(nt) toute personne candidate \xE0 un emploi en CDI ou d\xFBment inscrite \xE0 tout registre  du commerce  et  des  soci\xE9t\xE9s  ou  aupr\xE8s  de  toute  chambre  des  m\xE9tiers  ou  consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients et \xEAtre ainsi r\xE9f\xE9renc\xE9s sur la Plateforme.":
      "<0>Talent (s)</0> d\xE9signe(nt) toute personne candidate \xE0 un emploi en CDI ou d\xFBment inscrite \xE0 tout registre  du commerce  et  des  soci\xE9t\xE9s  ou  aupr\xE8s  de  toute  chambre  des  m\xE9tiers  ou  consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients et \xEAtre ainsi r\xE9f\xE9renc\xE9s sur la Plateforme.",
    "<0>Talent (s)</0> d\xE9signe(nt) toute personne candidate \xE0 un emploi en CDI ou d\xFBment inscrite \xE0 tout registre du commerce et des soci\xE9t\xE9s ou aupr\xE8s de toute chambre des m\xE9tiers ou consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients et \xEAtre ainsi r\xE9f\xE9renc\xE9s sur la Plateforme.":
      "<0>Talent (s)</0> d\xE9signe(nt) toute personne candidate \xE0 un emploi en CDI ou d\xFBment inscrite \xE0 tout registre du commerce et des soci\xE9t\xE9s ou aupr\xE8s de toute chambre des m\xE9tiers ou consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients et \xEAtre ainsi r\xE9f\xE9renc\xE9s sur la Plateforme.",
    "<0>Timesheet  Manager</0>  d\xE9signe  l\u2019application  mise  en  place  au  sein  de  la  Plateforme  par  Club Freelance permettant  aux  Clients  de  valider  les  comptes-rendus  d\u2019activit\xE9  remplis  par  le(s) Utilisateur(s) durant une mission.":
      "<0>Timesheet  Manager</0>  d\xE9signe  l\u2019application  mise  en  place  au  sein  de  la  Plateforme  par  Club Freelance permettant  aux  Clients  de  valider  les  comptes-rendus  d\u2019activit\xE9  remplis  par  le(s) Utilisateur(s) durant une mission.",
    "<0>Timesheet Manager</0> d\xE9signe l\u2019application mise en place au sein de la Plateforme par Club Freelance permettant aux Clients de valider les comptes-rendus d\u2019activit\xE9 remplis par le(s) Utilisateur(s) durant une mission.":
      "<0>Timesheet Manager</0> d\xE9signe l\u2019application mise en place au sein de la Plateforme par Club Freelance permettant aux Clients de valider les comptes-rendus d\u2019activit\xE9 remplis par le(s) Utilisateur(s) durant une mission.",
    "<0>Utilisateurs</0>  d\xE9signe(nt)  les  Clients  (s)  et/ou  Consultant(s)  r\xE9f\xE9renc\xE9(s)  et  inscrit(s)  sur  la Plateforme.":
      "<0>Utilisateurs</0>  d\xE9signe(nt)  les  Clients  (s)  et/ou  Consultant(s)  r\xE9f\xE9renc\xE9(s)  et  inscrit(s)  sur  la Plateforme.",
    "<0>Utilisateurs</0> d\xE9signe(nt) les Clients (s) et/ou Consultant(s) r\xE9f\xE9renc\xE9(s) et inscrit(s) sur la Plateforme.":
      "<0>Utilisateurs</0> d\xE9signe(nt) les Clients (s) et/ou Consultant(s) r\xE9f\xE9renc\xE9(s) et inscrit(s) sur la Plateforme.",
    "<0>Vos collaborateur </0>": "<0>Vos collaborateur </0>",
    "<0>www.ovh.com</0>": "<0>www.ovh.com</0>",
    "<0>{0}</0>": function(a) {
      return ["<0>", a("0"), "</0>"];
    },
    "<0>\xC0 partir de {0}</0>": function(a) {
      return ["<0>\xC0 partir de ", a("0"), "</0>"];
    },
    "<0>\u2018Controller\u2019</0>  means the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing; where the purposes and means of such processing are determined by Union or Member State law, the controller may be designated or the specific criteria for its designation may be provided for by Union or Member State law. In the present case, the Company is the controller.":
      "\xAB  <0>Responsable  du  traitement</0>  \xBB  d\xE9signe  la  personne  physique  ou  morale,  l'autorit\xE9 publique,  le  service  ou  un  autre  organisme  qui,  seul  ou  conjointement  avec  d'autres, d\xE9termine les finalit\xE9s et les moyens du traitement; lorsque les finalit\xE9s et les moyens de ce traitement sont d\xE9termin\xE9s par le droit de l'Union ou le droit d'un \xC9tat membre, le responsable du traitement peut \xEAtre d\xE9sign\xE9 ou les crit\xE8res sp\xE9cifiques applicables \xE0 sa d\xE9signation peuvent \xEAtre pr\xE9vus par le droit de l'Union ou par le droit d'un \xC9tat membre. En l\u2019esp\xE8ce, la Soci\xE9t\xE9 est le Responsable de traitement.",
    "<0>\u2018Controller\u2019</0> means the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing; where the purposes and means of such processing are determined by Union or Member State law, the controller may be designated or the specific criteria for its designation may be provided for by Union or Member State law. In the present case, the Company is the controller.":
      "\xAB  <0>Responsable  du  traitement</0>  \xBB  d\xE9signe  la  personne  physique  ou  morale,  l'autorit\xE9 publique,  le  service  ou  un  autre  organisme  qui,  seul  ou  conjointement  avec  d'autres, d\xE9termine les finalit\xE9s et les moyens du traitement; lorsque les finalit\xE9s et les moyens de ce traitement sont d\xE9termin\xE9s par le droit de l'Union ou le droit d'un \xC9tat membre, le responsable du traitement peut \xEAtre d\xE9sign\xE9 ou les crit\xE8res sp\xE9cifiques applicables \xE0 sa d\xE9signation peuvent \xEAtre pr\xE9vus par le droit de l'Union ou par le droit d'un \xC9tat membre. En l\u2019esp\xE8ce, la Soci\xE9t\xE9 est le Responsable de traitement.",
    "<0>\u2018Personal data\u2019</0> means any information relating to an identified or identifiable natural person (\u2018data <1>subject</1>\u2019).":
      "\xAB <0>Donn\xE9es  \xE0  caract\xE8re  personnel</0>  \xBB  d\xE9signent  toute  information  se  rapportant  \xE0  une personne   physique   identifi\xE9e   ou   identifiable   (ci-apr\xE8s   d\xE9nomm\xE9e   \xAB   <1>Personne concern\xE9e</1> \xBB).",
    "<0>\u2018Processing\u2019</0>    means  any  operation  or  set  of  operations  which  is  performed  upon personal  data  or  sets  of  personal  data, whether  or  not  by  automatic  means,  such  as collection,   recording,   organisation,   structuring,   storage, adaptation   or   alteration, retrieval,  consultation,  use,  disclosure  by  transmission, dissemination  or  otherwise making available, alignment or combination, restriction, erasure or destruction.":
      "<0>\xAB  Traitement  \xBB</0>   d\xE9signe  toute  op\xE9ration  ou  tout  ensemble  d'op\xE9rations  effectu\xE9es  ou non \xE0 l'aide de proc\xE9d\xE9s automatis\xE9s et appliqu\xE9es \xE0 des donn\xE9es ou des ensembles de donn\xE9es \xE0 caract\xE8re personnel, telles que la collecte, l'enregistrement, l'organisation, la structuration,   la   conservation,   l'adaptation   ou   la   modification,   l'extraction,   la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme  de  mise  \xE0  disposition,  le  rapprochement  ou  l'interconnexion,  la  limitation, l'effacement ou la destruction.",
    "<0>\u2018Processing\u2019</0> means any operation or set of operations which is performed upon personal data or sets of personal data, whether or not by automatic means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.":
      "\xAB  <0>Traitement </0> \xBB  d\xE9signe  toute  op\xE9ration  ou  tout  ensemble  d'op\xE9rations  effectu\xE9es  ou non \xE0 l'aide de proc\xE9d\xE9s automatis\xE9s et appliqu\xE9es \xE0 des donn\xE9es ou des ensembles de donn\xE9es \xE0 caract\xE8re personnel, telles que la collecte, l'enregistrement, l'organisation, la structuration,   la   conservation,   l'adaptation   ou   la   modification,   l'extraction,   la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme  de  mise  \xE0  disposition,  le  rapprochement  ou  l'interconnexion,  la  limitation, l'effacement ou la destruction.",
    "<0>\u201CTalent\u201D</0>   means  any  person  applying  for  a  permanent  job,  or  duly  registered  in  any trade and company register or employed by an umbrella company and wishes to offer his  services to Clients.":
      "<0>\xAB Talents \xBB</0>  d\xE9signe(nt)  toute  personne  candidate  \xE0  un  emploi  en  CDI  ou  d\xFBment inscrite  \xE0  tout  registre  du  commerce  et  des  soci\xE9t\xE9s  ou  aupr\xE8s  de  toute  chambre  des m\xE9tiers ou consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients.",
    "<0>\u201CTalent\u201D</0> means any person applying for a permanent job, or duly registered in any trade and company register or employed by an umbrella company and wishes to offer his services to Clients.":
      "\xAB <0> Talents </0> \xBB  d\xE9signe(nt)  toute  personne  candidate  \xE0  un  emploi  en  CDI  ou  d\xFBment inscrite  \xE0  tout  registre  du  commerce  et  des  soci\xE9t\xE9s  ou  aupr\xE8s  de  toute  chambre  des m\xE9tiers ou consid\xE9r\xE9e comme salari\xE9 port\xE9 ou et qui souhaite proposer ses services \xE0 des Clients.",
    "A ce titre, il est \xE9galement pr\xE9vu que la Plateforme permettre la publication pseudonymis\xE9e des profils des Talents.":
      "A ce titre, il est \xE9galement pr\xE9vu que la Plateforme permettre la publication pseudonymis\xE9e des profils des Talents.",
    "A cookie is a small file that is uploaded to a device (telephone, computer, etc.) when the user accesses an Internet site. Cookies are used by many websites, while others make it possible to remember user preferences, the language in which the website is displayed, to record the user\u2019s basket on a shopping website, etc.":
      "Un  cookie  est  un  petit  fichier  qui  est  t\xE9l\xE9charg\xE9  sur  un  appareil  (t\xE9l\xE9phone,  ordinateur,  etc.) lorsque l\u2019utilisateur acc\xE8de \xE0 un Site Internet. Les cookies sont utilis\xE9s par de nombreux sites Internet  et  permettent  en  autres  de  se  rappeler  des  pr\xE9f\xE9rences  des  utilisateurs,  la  langue d\u2019affichage du site Internet, enregistrer le panier de l\u2019utilisateur sur un site Internet d\u2019achat, etc.",
    "A copy of your driving license and/or passport/identity card; Photograph;":
      "A copy of your driving license and/or passport/identity card; Photograph;",
    "A d\xE9faut, elles entendent soumettre leur litige \xE0 la comp\xE9tence du tribunal comp\xE9tent selon la l\xE9gislation applicable.":
      "A d\xE9faut, elles entendent soumettre leur litige \xE0 la comp\xE9tence du tribunal comp\xE9tent selon la l\xE9gislation applicable.",
    "A fin de faciliter les recherches pensez \xE0 indiquer dans votre fiche de poste:":
      "Afin de faciliter les recherches pensez \xE0 indiquer dans votre fiche de poste :",
    "A l'\xE9coute et excellente qualification du besoin.":
      "A l'\xE9coute et excellente qualification du besoin.",
    "A l'\xE9coute et excellente qualification du besoin.\"":
      "A l'\xE9coute et excellente qualification du besoin.\"",
    "A l\u2019\xE9tude": "\xC0 l\u2019\xE9tude",
    "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.":
      "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.",
    "A propos": "A propos",
    "A propos de Mindquest": "A propos de Mindquest",
    "A tout le moins, il est recommand\xE9 de souscrire une police d\u2019assurance couvrant les dommages qui peuvent \xEAtre caus\xE9s en France.":
      "A tout le moins, il est recommand\xE9 de souscrire une police d\u2019assurance couvrant les dommages qui peuvent \xEAtre caus\xE9s en France.",
    ABNORMAL_TIME_PER_DAY: "Une journ\xE9e avec plus ou moins que la normale",
    "ARTICLE 1.    Objet": "ARTICLE 1.    Objet",
    "ARTICLE 1. Objet": "ARTICLE 1. Objet",
    "ARTICLE 10.Propri\xE9t\xE9 intellectuelle":
      "ARTICLE 10.Propri\xE9t\xE9 intellectuelle",
    "ARTICLE 11. Assurances": "ARTICLE 11. Assurances",
    "ARTICLE 12.  Liens vers d\u2019autres sites internet et r\xE9seaux sociaux":
      "ARTICLE 12.  Liens vers d\u2019autres sites internet et r\xE9seaux sociaux",
    "ARTICLE 12. Liens vers d\u2019autres sites internet et r\xE9seaux sociaux":
      "ARTICLE 12. Liens vers d\u2019autres sites internet et r\xE9seaux sociaux",
    "ARTICLE 13.   Dur\xE9e": "ARTICLE 13.   Dur\xE9e",
    "ARTICLE 13. Dur\xE9e": "ARTICLE 13. Dur\xE9e",
    "ARTICLE 14.   R\xE9siliation, suspension":
      "ARTICLE 14.   R\xE9siliation, suspension",
    "ARTICLE 14. R\xE9siliation, suspension":
      "ARTICLE 14. R\xE9siliation, suspension",
    "ARTICLE 15.  Divers": "ARTICLE 15.  Divers",
    "ARTICLE 15. Divers": "ARTICLE 15. Divers",
    "ARTICLE 16.   Loi applicable et juridiction comp\xE9tente":
      "ARTICLE 16.   Loi applicable et juridiction comp\xE9tente",
    "ARTICLE 16. Loi applicable et juridiction comp\xE9tente":
      "ARTICLE 16. Loi applicable et juridiction comp\xE9tente",
    "ARTICLE 2.    D\xE9finitions": "ARTICLE 2.    D\xE9finitions",
    "ARTICLE 2. D\xE9finitions": "ARTICLE 2. D\xE9finitions",
    "ARTICLE 3.   Description de la Plateforme":
      "ARTICLE 3.   Description de la Plateforme",
    "ARTICLE 3. Description de la Plateforme":
      "ARTICLE 3. Description de la Plateforme",
    "ARTICLE 4.    Modalit\xE9s d\u2019acc\xE8s et inscription \xE0 la Plateforme":
      "ARTICLE 4.    Modalit\xE9s d\u2019acc\xE8s et inscription \xE0 la Plateforme",
    "ARTICLE 4. Modalit\xE9s d\u2019acc\xE8s et inscription \xE0 la Plateforme":
      "ARTICLE 4. Modalit\xE9s d\u2019acc\xE8s et inscription \xE0 la Plateforme",
    "ARTICLE 5.  Obligations des Utilisateurs":
      "ARTICLE 5.  Obligations des Utilisateurs",
    "ARTICLE 5. Obligations des Utilisateurs":
      "ARTICLE 5. Obligations des Utilisateurs",
    "ARTICLE 6.   Responsabilit\xE9": "ARTICLE 6.   Responsabilit\xE9",
    "ARTICLE 6. Responsabilit\xE9": "ARTICLE 6. Responsabilit\xE9",
    "ARTICLE 7.  Relation entre Talents et Clients":
      "ARTICLE 7.  Relation entre Talents et Clients",
    "ARTICLE 7. Relation entre Talents et Clients":
      "ARTICLE 7. Relation entre Talents et Clients",
    "ARTICLE 8.   Evaluation des Utilisateurs":
      "ARTICLE 8.   Evaluation des Utilisateurs",
    "ARTICLE 8. Evaluation des Utilisateurs":
      "ARTICLE 8. Evaluation des Utilisateurs",
    "ARTICLE 9.   Garantie relative \xE0 la mise \xE0 disposition de la Plateforme":
      "ARTICLE 9.   Garantie relative \xE0 la mise \xE0 disposition de la Plateforme",
    "ARTICLE 9. Garantie relative \xE0 la mise \xE0 disposition de la Plateforme":
      "ARTICLE 9. Garantie relative \xE0 la mise \xE0 disposition de la Plateforme",
    AUTH_TOKEN: "AUTH_TOKEN",
    "About us": "About us",
    "About us - Mindquest Connect": "About us - Mindquest Connect",
    "Acceder \xE0 votre espace entreprise":
      "Acc\xE9der \xE0 votre espace entreprise",
    Accepter: "Accepter",
    "Account Managers locaux": "Account Managers locaux",
    Accueil: "Accueil",
    "Acc\xE8s non autoris\xE9": "Acc\xE8s non autoris\xE9",
    "Acc\xE9der au guide pour plus d\u2019information":
      "Acc\xE9der au guide pour plus d\u2019information",
    "Acc\xE9der \xE0 la ressource": "En savoir plus",
    "Acc\xE9der \xE0 mes viviers": "Acc\xE9der \xE0 mes viviers",
    "Acc\xE9der \xE0 mon espace client": "Acc\xE9der \xE0 mon espace client",
    "Acc\xE9der \xE0 mon espace perso": "Acc\xE9der \xE0 mon espace perso",
    "Acc\xE9der \xE0 mon espace personel":
      "Acc\xE9der \xE0 mon espace personel",
    "Acc\xE9l\xE9rez sur votre carri\xE8re Finance en freelance ou en CDI avec Mindquest. Obtenez les offres d'emploi ou de mission finance \xE0 forte valeur ajout\xE9.":
      "Obtenez les offres d'emploi ou de missions Finance \xE0 forte valeur ajout\xE9 et acc\xE9l\xE9rez sur votre carri\xE8re Finance en Freelance ou en CDI avec Mindquest",
    "Acc\xE9l\xE9rez votre processus de recutrement d\xE8s maintenant":
      "Acc\xE9l\xE9rez votre processus de recrutement d\xE8s maintenant",
    Actions: "Actions",
    "Activer mon profil pseudonymis\xE9 pour les d\xE9cideurs IT":
      "Activer mon profil pseudonymis\xE9 pour les d\xE9cideurs IT",
    "Activit\xE9 sur toutes les offres": "Activit\xE9 sur toutes les offres",
    "Activit\xE9 sur vos offres": "Activit\xE9 sur vos offres",
    "Activit\xE9s principales (optionnel)":
      "Activit\xE9s principales (optionnel)",
    Actuaire: "Actuaire",
    "Actual and potential candidates:": "Actual and potential candidates:",
    Actualité: "Actualit\xE9",
    "Actualit\xE9s, conseils pratiques, d\xE9cryptages, et points de vue d\u2019experts pour freelances informatiques et d\xE9cideurs IT en qu\xEAte de r\xE9ussite.":
      "Actualit\xE9s, conseils pratiques, d\xE9cryptages, et points de vue d\u2019experts pour talents et d\xE9cideurs IT en qu\xEAte de r\xE9ussite.",
    "Adequate, relevant and limited to what is necessary;":
      "Ad\xE9quates, pertinentes et limit\xE9es \xE0 ce qui est n\xE9cessaire;",
    "Admin & L\xE9gal": "Admin & L\xE9gal",
    "Admin & l\xE9gal": "Admin & l\xE9gal",
    Adresse: "Adresse",
    "Adresse email": "Adresse email",
    "Adresse email non valide": "Adresse email non valide",
    "Adresse \xE9lectronique :<0>contact@mindquest.io</0>":
      "Adresse \xE9lectronique :<0>contact@mindquest.io</0>",
    "Advertising cookies": "Cookies de publicit\xE9",
    "Afficher uniquement les profils publiques":
      "Afficher uniquement les profils publiques",
    "Afin d'organiser un entretien avec le candidat, pourriez-vous nous donner des cr\xE9neaux de disponibilit\xE9?":
      "Afin d'organiser un entretien avec le candidat, pourriez-vous nous donner des cr\xE9neaux de disponibilit\xE9 ?",
    "Afin de mieux adapter nos recherches merci de nous indiquer les raisons de ce refus en commentaire?":
      "Afin de mieux adapter nos recherches, pourriez-vous nous indiquer les raisons de ce refus en commentaire ?",
    Ajouter: "Ajouter",
    "Ajouter 1 jour complet": "Ajouter 1 jour complet",
    "Ajouter Club Freelance \xE0 l\u2019\xE9cran d\u2019accueil":
      "Ajouter Club Freelance \xE0 l\u2019\xE9cran d\u2019accueil",
    "Ajouter des heures": "Ajouter des heures",
    "Ajouter heures": "Ajouter heures",
    "Ajouter le code postal permettra de mettre en avant les profils les plus proches":
      "Ajouter le code postal permettra de mettre en avant les profils les plus proches",
    "Ajouter le nom de votre vivier": "Ajouter le nom du vivier",
    "Ajouter les environnements": "Ajouter les environnements",
    "Ajouter mon CV": "Ajouter mon CV",
    "Ajouter projet": "Ajouter projet",
    "Ajouter quand m\xEAme": "Ajouter quand m\xEAme",
    "Ajouter th\xE8me": "Ajouter th\xE8me",
    "Ajouter un collaborateur": "Ajouter un collaborateur",
    "Ajouter un commentaire": "Ajouter un commentaire",
    "Ajouter un commentaire pour indiquer votre activit\xE9 au client":
      "Ajouter un commentaire pour indiquer votre activit\xE9 au client",
    "Ajouter un commentaire...": "Ajouter un commentaire...",
    "Ajouter un message": "Ajouter un message",
    "Ajouter un nouveau projet": "Ajouter un nouveau projet",
    "Ajouter un nouveau th\xE8me": "Ajouter un nouveau th\xE8me",
    "Ajouter un vivier": "Ajouter un vivier",
    "Ajouter une Certification": "Ajouter une Certification",
    "Ajouter une Formation": "Ajouter une Formation",
    "Ajouter une annotation:": "Ajouter une annotation:",
    "Ajouter une exp\xE9rience": "Ajouter une exp\xE9rience",
    "Ajouter une ligne": "Ajouter une ligne",
    "Ajouter une nouvelle plage": "Ajouter une nouvelle plage",
    "Ajouter vos Comp\xE9tences": "Ajouter vos Comp\xE9tences",
    "Ajouter vos soft skills": "Ajouter vos soft skills",
    "Ajouter votre/vos Zone(s) g\xE9ographique(s) souhait\xE9e(s)":
      "Ajouter votre/vos Zone(s) g\xE9ographique(s) souhait\xE9e(s)",
    "Ajouter \xE0 mes viviers": "Ajouter \xE0 mes viviers",
    "Ajouter \xE0 un vivier": "Ajouter \xE0 un vivier",
    "Ajoutez 1 jour complet (8h pr\xE9sentielles pour ce jour) en utilisant le bouton ci-dessous":
      "Ajoutez 1 jour complet (8h pr\xE9sentielles pour ce jour) en utilisant le bouton ci-dessous",
    "Ajoutez 1 jour complet (8h pr\xE9sentielles pour ce jourrr) en utilisant le bouton ci-dessous":
      "Ajoutez 1 jour complet (8h pr\xE9sentielles pour ce jourrr) en utilisant le bouton ci-dessous",
    "Ajoutez l'email du signataire principal...":
      "Ajoutez l'email du signataire principal...",
    "Ajoutez l'email du signataire secondaire...":
      "Ajoutez l'email du signataire secondaire...",
    "Ajoutez le nom du nouveau projet": "Ajoutez le nom du nouveau projet",
    "Ajoutez le nom du nouveau th\xE8me": "Ajoutez le nom du nouveau th\xE8me",
    "Ajoutez un nom de projet pour retrouver facilement les offres associ\xE9es":
      "Ajoutez un nom de projet pour retrouver facilement les offres associ\xE9es",
    "Ajoutez vos derni\xE8res exp\xE9riences pour nous aider \xE0 vous trouver un job qui vous correspond. N\u2019oubliez pas de renseigner vos r\xE9f\xE9rences pour un process plus rapide":
      "Ajoutez vos derni\xE8res exp\xE9riences pour nous aider \xE0 vous trouver une opportunit\xE9 qui vous correspond. N\u2019oubliez pas de renseigner vos r\xE9f\xE9rences pour un process plus rapide.",
    "Ajoutez votre": "Ajoutez votre",
    "Ajoutez votre commentaire puis validez":
      "Ajoutez votre commentaire puis validez",
    "Ajoutez votre {0}": function(a) {
      return ["Ajoutez votre ", a("0")];
    },
    Alerte: "Alerte",
    "Alerte !": "Alerte !",
    "Alerte Mission": "Alerte Mission",
    "Alerte Suppression": "Alerte Suppression",
    "Alerte cr\xE9\xE9e avec succ\xE8s": "Alerte cr\xE9\xE9e avec succ\xE8s",
    All: "Tout",
    "All the endpoints of the company are protected with antivirus and firewalls.":
      "All the endpoints of the company are protected with antivirus and firewalls.",
    Allemand: "Allemand",
    Alphabétique: "Alphab\xE9tique",
    "An insider's look at the tech industry through interviews with expert from all areas of it.":
      "An insider's look at the tech industry through interviews with expert from all areas of it.",
    "An insider's look at the tech industry through interviews with expert from all areas of it..":
      "An insider's look at the tech industry through interviews with expert from all areas of it..",
    "Analyse les tendances et \xE9volutions du monde des talents tech & IT.":
      "Analyse les tendances et \xE9volutions du monde des talents tech & IT.",
    "Analyst M&A": "Analyst M&A",
    "Analyst cr\xE9dit": "Analyst cr\xE9dit",
    "Analyst financier": "Analyst financier",
    Anglais: "Anglais",
    Annuler: "Annuler",
    "Annuler le filtrage par projet": "Annuler le filtrage par projet",
    "Any beneficiaries of a data transfer.":
      "Les \xE9ventuels b\xE9n\xE9ficiaires d\u2019un transfert de donn\xE9es.",
    Appels: "Appels",
    "Apple Safari": "Apple Safari",
    "Application de filtre": "Application de filtre",
    Appliquer: "Appliquer",
    "Applying for jobs through a job aggregator, which may then redirect you to the Club Freelance website;":
      "Applying for jobs through a job aggregator, which may then redirect you to the Club Freelance website;",
    "Apr\xE8s l\u2019importation, Vos donn\xE9es seront remplac\xE9es par les donn\xE9es LinkedIn. Voulez vous continuer?":
      "Apr\xE8s l\u2019importation, vos donn\xE9es seront remplac\xE9es par les donn\xE9es LinkedIn. Voulez vous continuer?",
    Arabe: "Arabe",
    Arabic: "Arabe",
    "Articles Consulants IT - Develop your career: permanent & freelance - Mindquest Connect":
      "Articles Consulants IT - Develop your career: permanent & freelance - Mindquest Connect",
    "Articles Consulants IT - D\xE9velopper sa carri\xE8re : CDI & freelance - Mindquest Connect":
      "Articles Consulants IT - D\xE9velopper sa carri\xE8re : CDI & freelance - Mindquest Connect",
    "Articles Consultants IT - Admin & L\xE9gal freelance - Mindquest Connect":
      "Articles Consultants IT - Admin & L\xE9gal freelance - Mindquest Connect",
    "Articles Consultants IT - Comptabilit\xE9 en freelance - Mindquest Connect":
      "Articles Consultants IT - Comptabilit\xE9 en freelance - Mindquest Connect",
    "Articles Consultants IT - Devenir freelance - Mindquest Connect":
      "Articles Consultants IT - Devenir freelance - Mindquest Connect",
    "Articles Consultants IT - Fiscalit\xE9, imp\xF4ts & retraite en freelance - Mindquest Connect":
      "Articles Consultants IT - Fiscalit\xE9, imp\xF4ts & retraite en freelance - Mindquest Connect",
    "Articles Consultants IT - Nos partenaires - Mindquest Connect":
      "Articles Consultants IT - Nos partenaires - Mindquest Connect",
    "Articles Consultants IT - Portage salarial en freelance - Mindquest Connect":
      "Articles Consultants IT - Portage salarial en freelance - Mindquest Connect",
    "Articles Consultants IT - Quotidien de freelance - Mindquest Connect":
      "Articles Consultants IT - Quotidien de freelance - Mindquest Connect",
    "Articles Consultants IT - Se financer - Mindquest Connect":
      "Articles Consultants IT - Se financer - Mindquest Connect",
    "Articles D\xE9cideurs IT - Enjeux DSI - Mindquest Connect":
      "Articles D\xE9cideurs IT - Enjeux DSI - Mindquest Connect",
    "Articles D\xE9cideurs IT - La strat\xE9gie des talents - Mindquest Connect":
      "Articles D\xE9cideurs IT - La strat\xE9gie des talents - Mindquest Connect",
    "Articles D\xE9cideurs IT - Les risques & erreurs \xE0 \xE9viter - Mindquest Connect":
      "Articles D\xE9cideurs IT - Les risques & erreurs \xE0 \xE9viter - Mindquest Connect",
    "Articles D\xE9cideurs IT - L\xE9gal - Mindquest Connect":
      "Articles D\xE9cideurs IT - L\xE9gal - Mindquest Connect",
    "Articles D\xE9cideurs IT - Recruter un consultant informatique - Mindquest Connect":
      "Articles D\xE9cideurs IT - Recruter un consultant informatique - Mindquest Connect",
    "Articles qui peuvent vous int\xE9resser":
      "Articles qui peuvent vous int\xE9resser",
    "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.":
      "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.",
    Assurance: "Assurance",
    Astreinte: "Astreinte",
    Astreintes: "Astreintes",
    "Attention !": "Attention !",
    "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en":
      "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en ",
    "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en {plCountry}.":
      "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9",
    Au: "Au",
    "Aucun compte ne correspond \xE0 cet email":
      "Aucun compte ne correspond \xE0 cet email",
    "Aucun contact indiqu\xE9": "Aucun contact indiqu\xE9",
    "Aucun itin\xE9raire n\u2019a pu \xEAtre trouv\xE9 entre l\u2019origine et la destination.":
      "Aucun itin\xE9raire n\u2019a pu \xEAtre trouv\xE9 entre l\u2019origine et la destination",
    "Aucun projet n'est associ\xE9 \xE0 ce Timesheet.":
      "Aucun projet n'est associ\xE9 \xE0 ce Timesheet.",
    "Aucun pr\xE9paiements":
      "Facturation uniquement en cas de recrutement r\xE9ussi",
    "Aucun r\xE9sultat pour votre filtre.<0/>":
      "Aucun r\xE9sultat pour votre filtre.<0/>",
    "Aucun r\xE9sultat pour votre recherche. Essayez avec d\u2019autres mots-cl\xE9s.":
      "Aucun r\xE9sultat pour votre recherche. Essayez avec d\u2019autres mots-cl\xE9s.",
    "Aucun th\xE8me n'est associ\xE9 \xE0 ce Timesheet.":
      "Aucun th\xE8me n'est associ\xE9 \xE0 ce Timesheet.",
    "Aucune date n'est associ\xE9e \xE0 ce projet":
      "Aucune date n'est associ\xE9e \xE0 ce projet",
    "Aucune date n'est associ\xE9e \xE0 ce th\xE8me":
      "Aucune date n'est associ\xE9e \xE0 ce th\xE8me",
    "Aucune donn\xE9e \xE0 exporter": "Aucune donn\xE9e \xE0 exporter",
    "Audit Risque": "Audit Risque",
    "Auditeur financier": "Auditeur financier",
    "Auditeur interne": "Auditeur interne",
    "Aujourd\u2019hui": "Aujourd\u2019hui",
    Autoentrepreneu: "Autoentrepreneu",
    Autoentrepreneur: "Autoentrepreneur",
    Autre: "Autre",
    "Autres comp\xE9tences du candidat": "Autres comp\xE9tences du candidat",
    "Aux  fins  de  permettre  aux  Utilisateurs  d\u2019utiliser  la  Plateforme, la  Soci\xE9t\xE9  octroie  aux Utilisateurs  une  licence  d\u2019utilisation  strictement limit\xE9e  \xE0  l\u2019objet  des  pr\xE9sentes  et  en  France uniquement.":
      "Aux  fins  de  permettre  aux  Utilisateurs  d\u2019utiliser  la  Plateforme, la  Soci\xE9t\xE9  octroie  aux Utilisateurs  une  licence  d\u2019utilisation  strictement limit\xE9e  \xE0  l\u2019objet  des  pr\xE9sentes  et  en  France uniquement.",
    "Aux fins de permettre aux Utilisateurs d\u2019utiliser la Plateforme, la Soci\xE9t\xE9 octroie aux Utilisateurs une licence d\u2019utilisation strictement limit\xE9e \xE0 l\u2019objet des pr\xE9sentes et en France uniquement.":
      "Aux fins de permettre aux Utilisateurs d\u2019utiliser la Plateforme, la Soci\xE9t\xE9 octroie aux Utilisateurs une licence d\u2019utilisation strictement limit\xE9e \xE0 l\u2019objet des pr\xE9sentes et en France uniquement.",
    "Avant de soumettre votre Timesheet": "Avant de soumettre votre Timesheet",
    "Avant de soumettre votre Timesheet {0} au client, veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois.": function(
      a
    ) {
      return [
        "Avant de soumettre votre Timesheet ",
        a("0"),
        " au client, veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois."
      ];
    },
    Avantages: "Avantages",
    "Avec Mindquest, recrutez les meilleurs experts IT en CDI ou freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)":
      "Avec Mindquest, recrutez les meilleurs experts IT en CDI ou freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)",
    "Avec Mindquest, trouver votre prochaine opportunit\xE9 Tech & IT en CDI ou freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)":
      "Avec Mindquest, trouver votre prochaine opportunit\xE9 Tech & IT en CDI ou freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...)",
    "Avec Mindquest, trouvez votre prochaine offre Tech & IT en CDI ou Freelance et recrutez les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...":
      "Avec Mindquest, trouvez votre prochaine opportunit\xE9 IT et Finance en CDI ou Freelance et recrutez les meilleurs consultants dans l'informatique et la finance",
    "Avec des bureaux \xE0 Londres, Paris et Barcelone, Mindquest s\u2019appuie sur son exp\xE9rience ant\xE9rieure et son expertise dans l\u2019industrie des freelances informatiques \u2013 en tant que Club Freelance \u2013 pour aider les entreprises et les experts des technologies informatiques \xE0 r\xE9ussir leurs projets et faire prosp\xE9rer le march\xE9 informatique mondial.":
      "Avec des bureaux \xE0 Londres, Paris et Barcelone, Mindquest s\u2019appuie sur son exp\xE9rience ant\xE9rieure et son expertise dans l\u2019industrie des freelances informatiques \u2013 en tant que Club Freelance \u2013 pour aider les entreprises et les experts des technologies informatiques \xE0 r\xE9ussir leurs projets et faire prosp\xE9rer le march\xE9 informatique mondial.",
    Avertissement: "Avertissement",
    "Avis \xE9quipe": "Avis \xE9quipe",
    "Bank account details your company;": "Bank account details your company;",
    "Banque freelance": "Banque freelance",
    Barcelone: "Barcelone",
    "Bas\xE9s au sein de notre Sourcing Hub \xE0 Barcelone, nos Talent Community Specialists sont en contact permanent avec les meilleurs profils tech et IT. Ils sont en mesure d\u2019identifier et d\u2019accompagner les meilleurs talents dans toute l\u2019Europe.":
      "Bas\xE9s au sein de notre Sourcing Hub \xE0 Barcelone, nos Talent Community Specialists sont en contact permanent avec les meilleurs profils tech et IT. Ils sont en mesure d\u2019identifier et d\u2019accompagner les meilleurs talents dans toute l\u2019Europe.",
    "Bas\xE9s localement et sp\xE9cialis\xE9s par secteur d\u2019activit\xE9, nos Key Account Managers accompagnent leurs entreprises clientes dans la recherche des meilleures talents pour leurs projets IT.":
      "Bas\xE9s localement, nos Key Account Managers accompagnent leurs entreprises clientes dans la recherche des meilleurs talents pour leurs projets IT.",
    "Be in the know": "Pour ne manquer aucun article",
    "Behind the Screens": "Behind the Screens",
    "Besoin d\u2019un partenaire pour le recrutement de vos ressources IT ?":
      "Besoin d'un partenaire pour le recrutement de vos ressources IT ou Finance?",
    'Bien que la tendance \xE9volue, le travail en r\xE9gie est toujours la norme dans de nombreuses entreprises. Choissiez "indifferent" pour ne manquer aucune opportunit\xE9.':
      'Bien que la tendance \xE9volue, le travail en r\xE9gie est toujours la norme dans de nombreuses entreprises. Choissiez "indiff\xE9rent" pour ne manquer aucune opportunit\xE9.',
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour confirmer votre inscription":
      "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour confirmer votre inscription",
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour finaliser votre inscription et acc\xE9der \xE0 votre Dashboard":
      "Bienvenue au club, un email vous a \xE9t\xE9 envoy\xE9 pour finaliser votre inscription et acc\xE9der \xE0 votre Dashboard",
    "Bienvenue au club, Vous pouvez mainetenant acc\xE9der \xE0 votre Dashboard":
      "Bienvenue au club, Vous pouvez mainetenant acc\xE9der \xE0 votre Dashboard",
    "Bienvenue au club, Vous pouvez maintenant acc\xE9der \xE0 votre Dashboard":
      "Bienvenue au club, Vous pouvez maintenant acc\xE9der \xE0 votre Dashboard",
    "Bienvenue au club, un Key Account Manager va vous recontacter dans les plus brefs d\xE9lais pour faire le point sur vos recherches":
      "Bienvenue au club, un Key Account Manager va vous recontacter dans les plus brefs d\xE9lais pour faire le point sur vos recherches",
    "Blah Blah Blah Bloo Bloo Blah Bloo Bloo":
      "Blah Blah Blah Bloo Bloo Blah Bloo Bloo",
    Blog: "Ressources",
    "Bon de commande": "Bon de commande",
    "Bon \xE0 savoir": "Bon \xE0 savoir",
    "Bonne qualit\xE9 de profils et bonne r\xE9activit\xE9 ! Merci.":
      "Bonne qualit\xE9 de profils et bonne r\xE9activit\xE9 ! Merci.",
    "Bouche \xE0 oreille": "Bouche \xE0 oreille",
    "Brief profil": "Brief profil",
    "Business Lounge": "Business Lounge",
    "Business development": "Business development",
    "B\xE9nificier des offres partenaires":
      "B\xE9nificier des offres partenaires",
    "B\xE9n\xE9ficier des offres partenaires":
      "B\xE9n\xE9ficier des offres partenaires",
    "B\xE9n\xE9ficiez de contenus et avantages exclusifs":
      "B\xE9n\xE9ficiez de contenus et avantages exclusifs",
    "B\xE9n\xE9ficiez d\u2019offres s\xE9lectionn\xE9es pour votre profil.":
      "B\xE9n\xE9ficiez d\u2019offres s\xE9lectionn\xE9es pour votre profil.",
    CDI: "CDI",
    "CDI / Freelance": "CDI / Freelance",
    "CF Admin peut d\xE9sormais valider les Timesheets du contrat {placementId}": function(
      a
    ) {
      return [
        "CF Admin peut d\xE9sormais valider les Timesheets du contrat ",
        a("placementId")
      ];
    },
    "CF Admin peut d\xE9sormais valider vos Timesheets":
      "CF Admin peut d\xE9sormais valider vos Timesheets",
    "CF Sourceur": "CF Sourceur",
    CFO: "CFO",
    CGU: "CGU",
    "CGU et mentions l\xE9gales": "CGU et mentions l\xE9gales",
    "CONFIDENTIALITY POLICY": "POLITIQUE DE CONFIDENTIALITE",
    "CRA & commentaires": "CRA & commentaires",
    "CRM / ATS": "CRM /ATS",
    "CRM Software": "CRM / ATS",
    CV: "CV",
    "CV envoy\xE9": "CV envoy\xE9",
    "CV envoy\xE9s": "CV envoy\xE9s",
    "CV s\xE9lectionn\xE9s": "CV s\xE9lectionn\xE9s",
    Calendrier: "Calendrier",
    Candidat: "Candidat",
    "Candidate Data": "Candidate Data",
    "Candidate and Client data": "Candidate and Client data",
    Candidates: "Candidates",
    Candidats: "Candidats",
    Candidature: "Candidature",
    "Candidature cr\xE9\xE9e avec succ\xE8s":
      "Candidature cr\xE9\xE9e avec succ\xE8s",
    Candidatures: "Candidatures",
    "Capitalised terms have the following definition. Terms have the same definition regardless of whether they are used in singular or plural.":
      "Les  termes  employ\xE9s  avec  une  majuscule  ont  la  d\xE9finition  qui leur  est  donn\xE9e  ci-apr\xE8s.  Les termes ont la m\xEAme d\xE9finition qu\u2019ils soient employ\xE9s au singulier ou au pluriel.",
    "Captain's Log": "Captain's Log",
    "Captains\u2019s Log": "Captains\u2019s Log",
    "Captains\u2019s Log - Mindquest Connect":
      "Captains\u2019s Log - Mindquest Connect",
    "Career History;": "Career History;",
    "Career Spa": "Career Spa",
    "Cash manager": "Cash manager",
    Cdi: "CDI",
    "Ce champ est requis": "Ce champ est requis",
    "Ce champ ne contient pas la m\xEAme valeur":
      "Ce champ ne contient pas la m\xEAme valeur",
    "Ce champs est requis": "Ce champ est requis",
    "Ce champs ne contient pas la m\xEAme valeur":
      "Ce champ ne contient pas la m\xEAme valeur",
    'Ce consultant a \xE9t\xE9 ajouter \xE0 votre vivier "Mes recherches de Talents IT".':
      'Ce consultant a \xE9t\xE9 ajouter \xE0 votre vivier "Mes recherches de Talents IT".',
    'Ce consultant a \xE9t\xE9 ajout\xE9 \xE0 votre vivier "Mes recherches de Talents IT".':
      'Ce consultant a \xE9t\xE9 ajout\xE9 \xE0 votre vivier "Mes recherches de Talents IT".',
    'Ce consultant a \xE9t\xE9 supprim\xE9 de votre vivier "Mes recherches de Talents IT".':
      'Ce consultant a \xE9t\xE9 supprim\xE9 de votre vivier "Mes recherches de Talents IT".',
    "Ce contact est d\xE9j\xE0 pr\xE9sent sur votre listing de collaboration. N'h\xE9sitez pas \xE0 renvoyer votre demande de collaboration en cliquant sur":
      "Ce contact est d\xE9j\xE0 pr\xE9sent sur votre listing de collaboration. N'h\xE9sitez pas \xE0 renvoyer votre demande de collaboration en cliquant sur",
    "Ce format de fichier n\u2019est pas pris en charge. Veuillez choisir l\u2019une des options suivantes :":
      "Ce format de fichier n\u2019est pas pris en charge. Veuillez choisir l\u2019une des options suivantes :",
    "Ce nom de projet existe d\xE9j\xE0 !":
      "Ce nom de projet existe d\xE9j\xE0 !",
    "Ce nom de th\xE8me existe d\xE9j\xE0 !":
      "Ce nom de th\xE8me existe d\xE9j\xE0 !",
    "Ce nom existe d\xE9j\xE0 !": "Ce nom existe d\xE9j\xE0 !",
    "Ce profil m'int\xE9resse": "Ce profil m'int\xE9resse",
    "Ce profil n'est pas publique": "Ce profil n'est pas publique",
    "Ce profil vous int\xE9resse ?": "Ce profil vous int\xE9resse ?",
    "Ce site utilise des cookies pour offrir une meilleure exp\xE9rience de navigation. D\xE9couvrez comment nous utilisons les cookies dans notre":
      "Ce site utilise des cookies pour offrir une meilleure exp\xE9rience de navigation. D\xE9couvrez comment nous utilisons les cookies dans notre",
    'Ce talent existe d\xE9j\xE0 dans votre vivier par d\xE9faut "Mes recherches de talent IT"':
      'Ce talent existe d\xE9j\xE0 dans votre vivier par d\xE9faut "Mes recherches de talent IT"',
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1/>":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1/>",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherhche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherhche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>",
    "Certaines donn\xE9es n'ont pas \xE9t\xE9 enregistr\xE9es seront perdues. Etes-vous s\xFBr de vouloir passer \xE0 l\xE9tape suivante ?":
      "Certaines donn\xE9es n'ont pas \xE9t\xE9 enregistr\xE9es seront perdues. Etes-vous s\xFBr de vouloir passer \xE0 l\xE9tape suivante ?",
    Certifications: "Certifications",
    "Ces derniers travaillent ensemble pour fournir un service d\u2019accompagnement complet aux entreprises et aux candidats, de la publication de l\u2019offre de mission et de la recherche d\u2019emploi \xE0 l\u2019offboarding.":
      "Ces derniers travaillent ensemble pour fournir un service d\u2019accompagnement complet aux entreprises et aux candidats, de la publication de l\u2019offre de mission et de la recherche d\u2019emploi \xE0 l\u2019offboarding.",
    "Ces mesures ne donnent pas droit \xE0 une quelconque indemnit\xE9 au b\xE9n\xE9fice de l\u2019Utilisateur.":
      "Ces mesures ne donnent pas droit \xE0 une quelconque indemnit\xE9 au b\xE9n\xE9fice de l\u2019Utilisateur.",
    "Ces statistiques correspondent aux actions r\xE9alis\xE9es par nos \xE9quipes sur chaque offre ajout\xE9e sur la periode s\xE9lectionn\xE9e.":
      "Ces statistiques correspondent aux actions r\xE9alis\xE9es par nos \xE9quipes sur chaque offre ajout\xE9e sur la p\xE9riode s\xE9lectionn\xE9e.",
    "Ces statistiques correspondent \xE0 l\u2019ensemble des actions r\xE9alis\xE9es par nos \xE9quipes sur la p\xE9riode s\xE9lectionn\xE9e":
      "Ces statistiques correspondent \xE0 l\u2019ensemble des actions r\xE9alis\xE9es par nos \xE9quipes sur la p\xE9riode s\xE9lectionn\xE9e",
    "Cet email est associ\xE9 \xE0 un compte consultant dans mindquest et ne peut pas ajout\xE9 en tant que collaborateur":
      "Cet email est associ\xE9 \xE0 un compte Mindquest consultant. Il ne peut donc pas \xEAtre ajout\xE9 en tant que collaborateur.",
    "Cette adresse email est d\xE9j\xE0 associ\xE9e \xE0 un compte existant. Vous pouvez supprimer l'un de vos deux compte ou <0>contacter le support technique</0> pour les fusionner":
      "Cette adresse email est d\xE9j\xE0 associ\xE9e \xE0 un compte existant. Vous pouvez supprimer l'un de vos deux compte ou <0>contacter le support technique</0> pour les fusionner",
    "Cette annotation sera visible par le canidat dans le module de chat mais pas sur le compte-rendu de Timesheet":
      "Cette annotation sera visible par le candidat dans le module de chat mais pas sur le compte-rendu de Timesheet",
    "Cette annotation sera visible par le client et dans le module de chat mais pas sur le compte-rendu de Timesheet":
      "Cette annotation sera visible par le client et dans le module de chat mais pas sur le compte-rendu de Timesheet",
    "Cette date ne peut pas \xEAtre dans le future":
      "Cette date ne peut pas \xEAtre dans le future",
    "Cette langue sera utilis\xE9e essentiellement pour les e-mails.":
      "Cette langue sera utilis\xE9e essentiellement pour les e-mails.",
    "Cette offre est ferm\xE9e et la disponibilit\xE9 de ce profil n'est plus d'actualit\xE9":
      "Cette offre est ferm\xE9e et la disponibilit\xE9 de ce profil n'est plus d'actualit\xE9",
    "Champ requis": "Champ requis",
    "Champs requis": "Champ requis",
    "Changement de l'adresse email": "Changement de l'adresse email",
    "Changement du mot de passe": "Changement du mot de passe",
    "Changement en cours": "Changement en cours",
    "Changer votre mot de passe": "Changer votre mot de passe",
    "Chaque Utilisateur reconnait et accepte qu\u2019il est seul responsable et ma\xEEtre des contenus qu\u2019il publie par le biais de la Plateforme ainsi que des propos qui y sont \xE9chang\xE9s.":
      "Chaque Utilisateur reconnait et accepte qu\u2019il est seul responsable et ma\xEEtre des contenus qu\u2019il publie par le biais de la Plateforme ainsi que des propos qui y sont \xE9chang\xE9s.",
    "Chaque offre de mission est prise en charge par un bin\xF4me de deux experts, qui entretiennent des relations de long-terme avec entreprises et consultants":
      "Chaque offre de mission est prise en charge par un bin\xF4me de deux experts, qui entretiennent des relations de long-terme avec entreprises et candidats",
    "Chaque offre d\u2019emploi est g\xE9r\xE9e par un duo de professionnels : un responsable de comptes connaissant les entreprises, leur secteur et leurs enjeux et un sp\xE9cialiste en acquisition de talents, experts sur un domaine de comp\xE9tences.":
      "Chaque offre d\u2019emploi est g\xE9r\xE9e par un duo de professionnels : un responsable de comptes connaissant les entreprises, leur secteur et leurs enjeux et un sp\xE9cialiste en acquisition de talents, experts sur un domaine de comp\xE9tences.",
    Chargement: "Chargement",
    "Chat avec candidat": "Chat avec candidat",
    "Chef de projet Finance / IT": "Chef de projet Finance / IT",
    "Chercher des comp\xE9tences": "Chercher des comp\xE9tences",
    "Chercher des sp\xE9cialit\xE9s": "Chercher des m\xE9tiers",
    "Chercher un document...": "Chercher un document...",
    "Chercher un talent...": "Chercher un talent...",
    "Chercher un vivier...": "Chercher un vivier...",
    "Chercher une offre": "Chercher une offre",
    "Chercher une offre...": "Chercher une offre...",
    "Chez Mindquest, nous sommes sp\xE9cialis\xE9s dans <0>l\u2019accompagnement professionel d\u2019experts </0>en CDI ou en Freelance, sur les domaines de l\u2019IT et les m\xE9tiers de la Finance.":
      "Chez Mindquest, nous sommes sp\xE9cialis\xE9s dans <0>l\u2019accompagnement professionel d\u2019experts </0>en CDI ou en Freelance, sur les domaines de l\u2019IT et les m\xE9tiers de la Finance.",
    "Chief Financial Officer": "Chief Financial Officer",
    Chinese: "Chinois",
    Chinois: "Chinois",
    "Choisissez au moins un domaine": "Choisissez au moins un domaine",
    "Choisissez au moins un secteur d\u2019activit\xE9":
      "Choisissez au moins un secteur d\u2019activit\xE9",
    "Choisissez au moins une sp\xE9cialit\xE9":
      "Choisissez au moins un m\xE9tier",
    "Choisissez entre deux et dix comp\xE9tences":
      "Choisissez entre deux et dix comp\xE9tences",
    "Choisissez entre une et dix comp\xE9tences":
      "Choisissez entre une et dix comp\xE9tences",
    "Choisissez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choisissez le mod\xE8le adapt\xE9 \xE0 votre situation",
    "Choissiez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choissiez le mod\xE8le adapt\xE9 \xE0 votre situation",
    "Click here for whatever": "Click here for whatever",
    Client: "Client",
    "Client data": "Client data",
    "Client n'est pas encore inscrit": "Client n'est pas encore inscrit",
    Clients: "Clients",
    "Clients grands comptes qui nous font confiance":
      "Clients grands comptes qui nous font confiance",
    "Cliquez i\xE7i pr\xE9ciser le nombre d\u2019heures travaill\xE9s":
      "Cliquez ici  pour pr\xE9ciser le nombre d\u2019heures travaill\xE9es",
    'Cliquez sur le cadre "Mots-cl\xE9s indispensables" ou "Mots-cl\xE9s importants" puis s\xE9lectionnez les mots-cl\xE9s IT souhait\xE9s':
      'Cliquez sur le cadre "Mots-cl\xE9s indispensables" ou "Mots-cl\xE9s importants" puis s\xE9lectionnez les mots-cl\xE9s IT souhait\xE9s',
    Cloud: "Cloud",
    "Club Freelance": "Mindquest",
    "Club Freelance - La communaut\xE9 des Freelances Informatique":
      "Mindquest - La communaut\xE9 des Talents IT",
    "Club Freelance Iberica": "Club Freelance Iberica",
    "Club Freelance Insights | Admin & l\xE9gal":
      "Mindquest Connect | Admin & l\xE9gal",
    "Club Freelance Insights | Captain's Log":
      "Club Freelance Insights | Captain's Log",
    "Club Freelance Insights | Cloud": "Mindquest Connect | Cloud",
    "Club Freelance Insights | Communiqu\xE9s de presse":
      "Mindquest Connect | Communiqu\xE9s de presse",
    "Club Freelance Insights | Comptabilit\xE9":
      "Mindquest Connect | Comptabilit\xE9",
    "Club Freelance Insights | Conseils Pratiques pour D\xE9cideurs IT":
      "Mindquest Connect | Conseils Pratiques pour D\xE9cideurs IT",
    "Club Freelance Insights | Conseils Pratiques pour D\xE9cideurs Informatiques":
      "Mindquest Connect | Conseils Pratiques pour D\xE9cideurs Informatiques",
    "Club Freelance Insights | Conseils Pratiques pour Freelances Informatiques":
      "Mindquest Connect | Conseils Pratiques pour Talents IT",
    "Club Freelance Insights | Consultant IT":
      "Mindquest Connect | Consultant IT",
    "Club Freelance Insights | Contenu pour consultants IT":
      "Mindquest Connect | Contenu pour consultants IT",
    "Club Freelance Insights | Contenu pour d\xE9cideurs IT":
      "Mindquest Connect | Contenu pour d\xE9cideurs IT",
    "Club Freelance Insights | Cybersecurity":
      "Mindquest Connect | Cybersecurity",
    "Club Freelance Insights | Cybers\xE9curit\xE9":
      "Mindquest Connect | Cybers\xE9curit\xE9",
    "Club Freelance Insights | DSI": "Mindquest Connect | DSI",
    "Club Freelance Insights | Data & Business Intelligence":
      "Mindquest Connect | Data & Business Intelligence",
    "Club Freelance Insights | Data & ERP": "Mindquest Connect | Data & ERP",
    "Club Freelance Insights | Devenir freelance":
      "Mindquest Connect | Devenir freelance",
    "Club Freelance Insights | Digital": "Mindquest Connect | Digital",
    "Club Freelance Insights | D\xE9cideur IT":
      "Mindquest Connect | D\xE9cideur IT",
    "Club Freelance Insights | D\xE9cryptages des m\xE9tiers de l\u2019IT":
      "Mindquest Connect | D\xE9cryptage des m\xE9tiers de l\u2019IT",
    "Club Freelance Insights | D\xE9veloppement":
      "Mindquest Connect | D\xE9veloppement",
    "Club Freelance Insights | D\xE9velopper sa carri\xE8re : CDI & freelance":
      "Mindquest Connect | D\xE9velopper sa carri\xE8re : CDI & freelance",
    "Club Freelance Insights | ERP & SAP": "Mindquest Connect | ERP & SAP",
    "Club Freelance Insights | Enjeux DSI": "Mindquest Connect | Enjeux DSI",
    "Club Freelance Insights | Fiches m\xE9tiers":
      "Mindquest Connect | Fiches m\xE9tiers",
    "Club Freelance Insights | Fiscalit\xE9, imp\xF4ts & retraite":
      "Mindquest Connect | Fiscalit\xE9, imp\xF4ts & retraite",
    "Club Freelance Insights | Infra": "Mindquest Connect | Infra",
    "Club Freelance Insights | Intelligence artificielle":
      "Mindquest Connect | Intelligence artificielle",
    "Club Freelance Insights | Job application advice":
      "Mindquest Connect | Job application advice",
    "Club Freelance Insights | Job search advice":
      "Mindquest Connect | Job search advice",
    "Club Freelance Insights | Le blog des talents IT":
      "Mindquest Connect | Le blog des talents IT",
    "Club Freelance Insights | L\xE9gal": "Mindquest Connect | L\xE9gal",
    "Club Freelance Insights | L\u2019actualit\xE9 des talents IT":
      "Mindquest Connect | L\u2019actualit\xE9 des talents IT",
    "Club Freelance Insights | Nos actualit\xE9s":
      "Mindquest Connect | Nos actualit\xE9s",
    "Club Freelance Insights | Nos partenaires":
      "Mindquest Connect | Nos partenaires",
    "Club Freelance Insights | On parle de nous":
      "Mindquest Connect | On parle de nous",
    "Club Freelance Insights | Podcast": "Club Freelance Insights | Podcast",
    "Club Freelance Insights | Portage salarial":
      "Mindquest Connect | Portage salarial",
    "Club Freelance Insights | Press review":
      "Mindquest Connect | Press review",
    "Club Freelance Insights | Project Management":
      "Mindquest Connect | Project Management",
    "Club Freelance Insights | Quotidien de freelance":
      "Mindquest Connect | Quotidien de freelance",
    "Club Freelance Insights | Rechercher un article":
      "Mindquest Connect | Rechercher un article",
    "Club Freelance Insights | Recrutement & IT":
      "Mindquest Connect | Recrutement & IT",
    "Club Freelance Insights | Recruter un consultant informatique":
      "Mindquest Connect | Recruter un consultant informatique",
    "Club Freelance Insights | Revues de presse":
      "Mindquest Connect | Revues de presse",
    "Club Freelance Insights | Risques & erreurs \xE0 \xE9viter":
      "Mindquest Connect | Risques & erreurs \xE0 \xE9viter",
    "Club Freelance Insights | Se financer": "Mindquest Connect | Se financer",
    "Club Freelance Insights | Strat\xE9gie des talents":
      "Mindquest Connect | Strat\xE9gie des talents",
    "Club Freelance Insights | Success": "Mindquest Connect | Success",
    "Club Freelance Insights | TOP 10 experts' articles":
      "Mindquest Connect | TOP 10 experts' articles",
    "Club Freelance Insights | Tech Magazine":
      "Mindquest Connect | Tech Magazine",
    "Club Freelance Insights | Tips for your daily life as a freelancer":
      "Mindquest Connect | Tips for your daily life as a freelancer",
    "Club Freelance Insights | T\xE9moignages":
      "Mindquest Connect | T\xE9moignages",
    "Club Freelance Insights | Umbrella company":
      "Mindquest Connect | Umbrella company",
    "Club Freelance Insights | Webinars & Ressources":
      "Mindquest Connect | Webinars & Ressources",
    "Club Freelance Insights | Webinars & replay":
      "Mindquest Connect | Webinars & replay",
    "Club Freelance Insights | cybersecurityEn":
      "Mindquest Connect | cybersecurityEn",
    "Club Freelance Insights | jobAppAdviceEn":
      "Mindquest Connect | jobAppAdviceEn",
    "Club Freelance Insights | pressReview": "Mindquest Connect | pressReview",
    "Club Freelance Insights | techmagazineEn":
      "Mindquest Connect | techmagazineEn",
    "Club Freelance Insights | topTenEn": "Mindquest Connect | topTenEn",
    "Club Freelance Limited": "Club Freelance Limited",
    "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.":
      "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.",
    "Club Freelance Limited is a staffing and sourcing company specialized in IT.":
      "Mindquest is a brand owned by Club Freelance Limited, a staffing and sourcing company specialized in IT.",
    "Club Freelance SAS": "Club Freelance SAS",
    "Club Freelance est une soci\xE9t\xE9 de placement de consultants ind\xE9pendants dans la technologie et l\u2019informatique.":
      "Mindquest est une soci\xE9t\xE9 de services de recrutement informatique qui associe des entreprises et des professionnels de l\u2019IT pour mener \xE0 bien des projets informatiques critiques, quel que soit le type de contrat.",
    "Club Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.":
      "Club Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.",
    "Club Freelance | Cr\xE9er un compte": "Mindquest | Cr\xE9er un compte",
    "Club Freelance | Entourez-vous des meilleurs Talents Informatiques":
      "Mindquest | Entourez-vous des meilleurs Talents Informatiques",
    "Club Freelance | Expert du sourcing IT digitalis\xE9":
      "Mindquest | Expert du sourcing IT digitalis\xE9",
    "Club Freelance | Mon profil": "Mindquest | Mon profil",
    "Club Freelance | Nous contacter": "Mindquest | Nous contacter",
    "Club Freelance | Partenaire des entreprises & freelances IT":
      "Mindquest | Partenaire des entreprises & talent IT",
    "Club Freelance | Recherche des candidats":
      "Mindquest | Recherche des candidats",
    "Club Freelance | Se connecter": "Mindquest | Se connecter",
    "Club Freelance | Votre prochaine mission Freelance Informatique":
      "Mindquest | Votre prochaine mission Freelance Informatique",
    "Club Freelance's Mission Control Center  \u2014 Interesting reads":
      "Mindquest Connect  \u2014 Interesting reads",
    "Club Freelance's Mission Control Center \u2014 Behind the screen":
      "Mindquest Connect \u2014 Behind the screen",
    "Club Freelance's Mission Control Center \u2014 Business Lounge":
      "Mindquest Connect \u2014 Business Lounge",
    "Club Freelance's Mission Control Center \u2014 Captain's Log":
      "Mindquest Connect \u2014 Captain's Log",
    "Club Freelance's Mission Control Center \u2014 Career Tips & Tricks":
      "Mindquest Connect \u2014 Career Tips & Tricks",
    "Club Freelance's Mission Control Center \u2014 Horizon 2050":
      "Mindquest Connect \u2014 Horizon 2050",
    "Club Freelance's Mission Control Center \u2014 IT's Who is Who":
      "Mindquest Connect \u2014 IT's Who is Who",
    "Club Freelance's Mission Control Center \u2014 MCC: IT's resource hub":
      "Mindquest Connect \u2014 MC: IT's resource hub",
    "Club Freelance's Mission Control Center \u2014 Newsstand":
      "Mindquest Connect \u2014 Newsstand",
    "Club Freelance's Mission Control Center\u2014 Search all articles":
      "Mindquest Connect\u2014 Search all articles",
    "Club Freelance,": "Mindquest,",
    "Club Freelance, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP":
      "Mindquest, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge":
      "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements":
      "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech":
      "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads":
      "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi":
      "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search":
      "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search",
    "Club Freelance, talent management, freelance informatique, trouver un freelance":
      "Mindquest, talent management, freelance informatique, trouver un freelance",
    Club_Freelance: "Mindquest",
    "Co-fondateur": "Co-fondateur",
    "Code postal*": "Code postal*",
    "Collaborateur non-inscrit": "Collaborateur non-inscrit",
    "Collaborateur(s)": "Collaborateur(s)",
    "Collaborateurs \xE0 temps plein": "Collaborateurs \xE0 temps plein",
    Collaboratif: "Collaboratif",
    "Collaboration Existante": "Collaboration Existante",
    "Collaboration sur le vivier": "Collaboration sur le vivier",
    Collaborators: "Collaborateurs",
    "Collected for specified, explicit and legitimate purposes;":
      "Collect\xE9es pour des finalit\xE9s d\xE9termin\xE9es, explicites et l\xE9gitimes;",
    "Coller votre code ici": "Coller votre code ici",
    "Collez ici le contenu de votre fiche de poste...":
      "Collez ici le contenu de votre fiche de poste...",
    "Collez l\u2019URL de votre profil Linkedin pour pr\xE9remplir votre profil":
      "Collez l\u2019URL de votre profil Linkedin pour pr\xE9remplir votre profil",
    "Comment avez-vous entendu parlez de nous":
      "Comment avez-vous entendu parlez de nous",
    "Comment \xE7a marche ?": "Comment \xE7a marche ?",
    Commentaire: "Commentaire",
    Commentaires: "Commentaires",
    "Commentaires et annotations": "Commentaires et annotations",
    "Communaut\xE9s Finance": "Communaut\xE9s Finance",
    "Communaut\xE9s IT": "Communaut\xE9s IT",
    "Communiqu\xE9s de presse": "Communiqu\xE9s de presse",
    "Company information available on local company registrars;":
      "Company information available on local company registrars;",
    "Compliance Officer": "Compliance Officer",
    "Compl\xE9ter mon profil": "Compl\xE9ter mon profil",
    Comptabilité: "Comptabilit\xE9",
    "Comptabilit\xE9 & Finance": "Comptabilit\xE9 & Finance",
    "Comptabilit\xE9 / Juridique": "Comptabilit\xE9 / Juridique",
    "Comptable Freelance": "Comptable Freelance",
    "Compte non verifi\xE9": "Compte non verifi\xE9",
    "Compte non v\xE9rifi\xE9": "Compte non v\xE9rifi\xE9",
    "Compte-Rendu G\xE9n\xE9r\xE9": "Compte-Rendu G\xE9n\xE9r\xE9",
    "Compte-rendu G\xE9n\xE9r\xE9": "Compte-rendu G\xE9n\xE9r\xE9",
    "Compte-rendu d'activit\xE9 (obligatoire)":
      "Compte-rendu d'activit\xE9 (obligatoire)",
    "Compte-rendus": "Compte-rendus",
    "Comptes rendus": "Comptes rendus",
    Compétence: "Comp\xE9tence",
    "Comp\xE9tence sugg\xE9r\xE9e": "Comp\xE9tence sugg\xE9r\xE9e",
    Compétences: "Comp\xE9tences",
    "Comp\xE9tences de la recherche": "Comp\xE9tences de la recherche",
    "Conditions G\xE9n\xE9rales d'Utilisation et Mentions L\xE9gales":
      "Conditions G\xE9n\xE9rales d'Utilisation et Mentions L\xE9gales",
    "Conditions G\xE9n\xE9rales d\u2019Utilisation":
      "Conditions G\xE9n\xE9rales d\u2019Utilisation",
    "Conditions G\xE9n\xE9rales d\u2019Utilisation - Mindquest":
      "Conditions G\xE9n\xE9rales d\u2019Utilisation - Mindquest",
    "Conditions G\xE9n\xE9rales d\u2019Utilisation et mentions l\xE9gales":
      "Conditions G\xE9n\xE9rales d\u2019Utilisation et mentions l\xE9gales",
    Confidentialité: "Confidentialit\xE9",
    "Confiez-nous votre prochain recrutement ?": "Trouver un talent",
    Configuration: "Configuration",
    "Confirmation mot de passe": "Confirmation mot de passe",
    "Confirmed (3 - 7)": "Confirmed (3 - 7)",
    "Confirmed (3-7)": "Confirmed (3-7)",
    Confirmer: "Confirmer",
    "Confirmer la suppression": "Confirmer la suppression",
    "Confirmer le nouveau mot de passe": "Confirmer le nouveau mot de passe",
    "Confirmer votre mot de passe": "Confirmer votre mot de passe",
    "Confirmez le nouveau mot de passe": "Confirmez le nouveau mot de passe",
    "Confirmez votre mot de passe": "Confirmez votre mot de passe",
    Confirmé: "Confirm\xE9",
    Congrats: "Congrats",
    "Connect with the latest news in the IT and Tech sector":
      "Abonnez-vous \xE0 notre newsletter et recevez les derni\xE8res nouvelles du secteur IT",
    "Connectez-vous \xE0 votre compte Mindquest pour trouver votre prochaine offre Tech & IT en CDI ou Freelance ou recruter les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...":
      "Connectez-vous \xE0 votre compte Mindquest pour trouver votre prochaine offre Tech & IT en CDI ou Freelance ou recruter les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...",
    "Connectez-vous \xE0 votre espace personnel Mindquest":
      "Connectez-vous \xE0 votre espace personnel Mindquest",
    "Connectez-vous \xE0 votre profil Club Freelance":
      "Connectez-vous \xE0 votre profil Mindquest",
    Connexion: "Connexion",
    "Connexion ou se connecter": "Connexion ou se connecter",
    "Conseil & Audit": "Conseil & Audit",
    "Conseils Candidat": "Conseils Candidat",
    "Conseils Candidat -  Mindquest Connect":
      "Conseils Candidat -  Mindquest Connect",
    "Conseils Pratiques pour Talents Tech & IT - Mindquest Connect":
      "Conseils Pratiques pour Talents Tech & IT - Mindquest Connect",
    "Conseils Pratiques pour entreprise - Mindquest Connect":
      "Conseils Pratiques pour entreprise - Mindquest Connect",
    "Conseils pratiques": "Conseils pratiques",
    "Conseils pratiques Consultant": "Conseils pratiques Consultant",
    "Conseils pratiques Entreprise": "Conseils pratiques Entreprise",
    "Conseils pratiques Talents IT": "Conseils pratiques Talents IT",
    "Consolideur ind\xE9pendant": "Consolideur ind\xE9pendant",
    Consultant: "Consultant",
    "Consultant AMAO SAP": "Consultant AMAO SAP",
    "Consultant AWS Cloud Devops": "Consultant AWS Cloud Devops",
    "Consultant Cegid": "Consultant Cegid",
    "Consultant IT": "Freelance informatique / tech",
    "Consultant It": "Consultant It",
    "Consultant Restructuring": "Consultant Restructuring",
    "Consultant SAGE": "Consultant SAGE",
    "Consultant SAP FI CO": "Consultant SAP FI CO",
    "Consultants IT": "Consultants IT",
    "Consultants IT - Recevez un contenu sp\xE9cifique aux consultants du secteur Tech & IT, tous les mois : articles, veilles, s\xE9lection d'opportunit\xE9s, nos avantages Mindquest, etc.":
      "Consultants IT - Recevez un contenu sp\xE9cifique aux consultants du secteur Tech & IT, tous les mois : articles, veilles, s\xE9lection d'opportunit\xE9s, nos avantages Mindquest, etc.",
    "Consulter le Timesheet": "Consulter le Timesheet",
    "Consulter le compte-rendu": "Consulter le compte-rendu",
    "Consulter le timesheet": "Consulter le timesheet",
    "Consulter les guides": "Consulter les guides",
    "Consulter notre <0>politique de confidentialit\xE9 </0> et nos <1>conditions g\xE9n\xE9rales d\u2019utilisations </1>":
      "Consulter notre <0>politique de confidentialit\xE9 </0> et nos <1>conditions g\xE9n\xE9rales d\u2019utilisations </1>",
    "Consulter notre <0>politique de confidentialit\xE9s </0> et nos <1>conditions g\xE9n\xE9rales d\u2019utilisation </1>":
      "Consulter notre <0>politique de confidentialit\xE9s </0> et nos <1>conditions g\xE9n\xE9rales d\u2019utilisation </1>",
    'Consultez et g\xE9rez vos viviers de talents au sein de la rubrique "Talents" de votre Dashbord.':
      'Consultez et g\xE9rez vos viviers de talents au sein de la rubrique "Talents" de votre Dashbord.',
    "Consultez nos offres CDI ou missions freelance pour consultants tech et trouvez votre prochain job IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle...":
      "Consultez nos offres de missions pour freelances Informatiques et freelances dans la Finance ainsi que nos opportunit\xE9s en CDI (SAP, Data, Cloud...)",
    "Consultez vos emails pour finaliser l\u2019inscription":
      "Veuillez consulter vos emails pour finaliser l\u2019inscription",
    Contact: "Contact",
    "Contact de r\xE9f\xE9rences": "Contact de r\xE9f\xE9rences",
    "Contact me if you are interested !": "Contact me if you are interested !",
    "Contact me to join our community!": "Contact me to join our community!",
    "Contact us": "Contact us",
    Contacter: "Contacter",
    "Contacter Marine pour un besoin": "Contacter Marine pour un besoin",
    "Contacter Nous": "Contactez-nous",
    "Contacter notre \xE9quipe Mindquest":
      "Contacter notre \xE9quipe Mindquest",
    "Contacter via Whatsapp": "Contacter via Whatsapp",
    'Contactez notre \xE9quipe "Legal"': 'Contactez notre \xE9quipe "Legal"',
    "Contactez nous": "Contactez-nous",
    Contacts: "Contacts",
    "Contenu orient\xE9 aux candidats":
      "Contenu \xE0 destination des talents IT",
    "Contenu orient\xE9 aux entreprises":
      "Contenu \xE0 destination des entreprises",
    "Contenu pour client": "Contenu pour client",
    "Contenu pour consultant": "Contenu pour consultant",
    "Contenu pour consultants IT": "Contenu pour consultant IT",
    "Contenu pour d\xE9cideurs IT": "Contenu pour d\xE9cideurs IT",
    "Contenu pour les d\xE9cideurs IT": "Contenu pour les d\xE9cideurs IT",
    Context: "Contexte",
    "Contexte de recrutement": "Contexte de recrutement",
    "Contexte projet": "Contexte projet",
    Continuer: "Continuer",
    "Continuer ma recherche": "Continuer ma recherche",
    "Continuer sans accepter": "Continuer sans accepter",
    Contrats: "Contrats",
    "Contrats reconduits par nos clients":
      "Contrats reconduits par nos clients",
    "Contr\xF4le de <0/> gestion": "Contr\xF4le de <0/> gestion",
    "Contr\xF4le de gestion IT": "Contr\xF4le de gestion IT",
    "Contr\xF4leur de Gestion": "Contr\xF4leur de Gestion",
    "Contr\xF4leur de gestion Freelance": "Contr\xF4leur de gestion Freelance",
    "Cookie of advertising": "Cookie de publicit\xE9",
    "Cookies policy": "Cookies policy",
    "Cookies strictly necessary": "Cookies strictement n\xE9cessaires",
    "Cookies vary according to their origin and lifespan.":
      "Les cookies varient en fonction de leur provenance et de leur dur\xE9e de vie.",
    Coopter: "Coopter",
    "Copier et coller l'URL de votre profil LinkedIn":
      "Copier et coller l'URL de votre profil LinkedIn",
    "Corporate <0/> finance": "Corporate <0/> finance",
    Critères: "Crit\xE8res",
    "Cr\xE9ation de Club Freelance": "Cr\xE9ation de Club Freelance",
    Créer: "Cr\xE9er",
    "Cr\xE9er mon compte entreprise et rejoindre Mindquest":
      "Cr\xE9er mon compte entreprise et rejoindre Mindquest",
    "Cr\xE9er mon compte talent et rejoindre Mindquest":
      "Cr\xE9er mon compte talent et rejoindre Mindquest",
    "Cr\xE9er mon espace personnel Talent":
      "Cr\xE9er mon espace personnel Talent",
    "Cr\xE9er un CV partageable": "Cr\xE9er un CV partageable",
    "Cr\xE9er un compte": "Cr\xE9er un compte",
    "Cr\xE9er un compte candidat": "Cr\xE9er un compte candidat",
    "Cr\xE9er un compte candidat (talent)":
      "Cr\xE9er un compte candidat (talent)",
    "Cr\xE9er un compte client": "Cr\xE9er un compte client",
    "Cr\xE9er un compte entreprise (recruteur)":
      "Cr\xE9er un compte entreprise (recruteur)",
    "Cr\xE9er un mot de passe": "Cr\xE9er un mot de passe",
    "Cr\xE9er un nouveau mot de passe": "Cr\xE9er un nouveau mot de passe",
    "Cr\xE9er un vivier": "Cr\xE9er un vivier",
    "Cr\xE9er une alerte sur ces crit\xE8res":
      "Cr\xE9er une alerte sur ces crit\xE8res",
    "Cr\xE9er une offre": "Cr\xE9er une offre",
    "Cr\xE9er votre compte": "Cr\xE9er votre compte",
    "Cr\xE9ez des viviers de talents": "Cr\xE9ez des viviers de talents",
    "Cr\xE9ez un CV partageable": "Cr\xE9ez un CV partageable",
    "Cr\xE9ez un nouveau mot de passe": "Cr\xE9ez un nouveau mot de passe",
    "Cr\xE9ez votre profil Club Freelance en seulement 3 minutes":
      "Vous cr\xE9ez votre profil Mindquest en seulement 3 minutes",
    "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.":
      "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.",
    "Cyber security": "Cyber security",
    "Cybersecurity Content - Mindquest Connect":
      "Cybersecurity Content - Mindquest Connect",
    Cybersécurité: "Cybers\xE9curit\xE9",
    "DAF externalis\xE9": "DAF externalis\xE9",
    "DAF \xE0 temps partag\xE9": "DAF \xE0 temps partag\xE9",
    DSI: "DSI",
    "Daily rate Range;": "Daily rate Range;",
    "Dans  le  cadre  des  services  propos\xE9s  et  mis  \xE0  disposition  par  la  Soci\xE9t\xE9  par  le  biais  de la Plateforme, les Talents s\u2019engagent \xE0 ne pas d\xE9marcher les Clients directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "Dans  le  cadre  des  services  propos\xE9s  et  mis  \xE0  disposition  par  la  Soci\xE9t\xE9  par  le  biais  de la Plateforme, les Talents s\u2019engagent \xE0 ne pas d\xE9marcher les Clients directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.",
    "Dans l'hypoth\xE8se o\xF9 l\u2019Utilisateur fournirait des donn\xE9es fausses ou inexactes, la Soci\xE9t\xE9 sera en droit de suspendre ou de proc\xE9der \xE0 la fermeture de son compte et de lui refuser l'acc\xE8s \xE0 tout service fourni par la Soci\xE9t\xE9.":
      "Dans l'hypoth\xE8se o\xF9 l\u2019Utilisateur fournirait des donn\xE9es fausses ou inexactes, la Soci\xE9t\xE9 sera en droit de suspendre ou de proc\xE9der \xE0 la fermeture de son compte et de lui refuser l'acc\xE8s \xE0 tout service fourni par la Soci\xE9t\xE9.",
    "Dans le cadre des services propos\xE9s et mis \xE0 disposition par la Soci\xE9t\xE9 par le biais de la Plateforme, les Talents s\u2019engagent \xE0 ne pas d\xE9marcher les Clients directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "Dans le cadre des services propos\xE9s et mis \xE0 disposition par la Soci\xE9t\xE9 par le biais de la Plateforme, les Talents s\u2019engagent \xE0 ne pas d\xE9marcher les Clients directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.",
    "Dans l\u2019hypoth\xE8se o\xF9 l\u2019une des clauses des pr\xE9sentes serait d\xE9clar\xE9e nulle et non avenue pour quelque  raison  que  ce  soit, cela  ne  saurait  en  aucun  cas  affecter  la  validit\xE9  et  le  respect des autres clauses.":
      "Dans l\u2019hypoth\xE8se o\xF9 l\u2019une des clauses des pr\xE9sentes serait d\xE9clar\xE9e nulle et non avenue pour quelque  raison  que  ce  soit, cela  ne  saurait  en  aucun  cas  affecter  la  validit\xE9  et  le  respect des autres clauses.",
    "Dans l\u2019hypoth\xE8se o\xF9 l\u2019une des clauses des pr\xE9sentes serait d\xE9clar\xE9e nulle et non avenue pour quelque raison que ce soit, cela ne saurait en aucun cas affecter la validit\xE9 et le respect des autres clauses.":
      "Dans l\u2019hypoth\xE8se o\xF9 l\u2019une des clauses des pr\xE9sentes serait d\xE9clar\xE9e nulle et non avenue pour quelque raison que ce soit, cela ne saurait en aucun cas affecter la validit\xE9 et le respect des autres clauses.",
    "Dans un rayon de": "Dans un rayon de",
    "Dans un rayon max de XX km": "Dans un rayon max de XX km",
    Dashboard: "Dashboard",
    "Dashboard Enterprise": "Dashboard Enterprise",
    "Data  on  the  legal  structure  of  Talent:  name  of  the  company,  identification  number, legal representative of the company and legal documents.":
      "Donn\xE9es relatives \xE0 la structure juridique du Talent : nom de la soci\xE9t\xE9, num\xE9ro SIREN, repr\xE9sentant l\xE9gal de la soci\xE9t\xE9 et documents l\xE9gaux.",
    "Data  on  your  economic  and  socio-occupational  situation:  professional  experience, academic  training,  bank  details,  Curriculum  Vitae,  references  to  former  clients or employers, current and envisaged remuneration;":
      "Donn\xE9es  relatives  \xE0  votre  situation  \xE9conomique  et  socioprofessionnelle :  exp\xE9riences professionnelles, formation acad\xE9mique,  RIB, Curriculum Vitae, r\xE9f\xE9rences d\u2019anciens clients ou employeurs, r\xE9mun\xE9ration actuelle et envisag\xE9e;",
    "Data & Business Intelligence job descriptions - Mindquest Connect":
      "Data & Business Intelligence job descriptions - Mindquest Connect",
    "Data & ERP": "Data & ERP",
    "Data & business Intelligence": "Data & business Intelligence",
    "Data Science & BI": "Data Science & BI",
    "Data Science & Bi": "Data Science & BI",
    "Data Scientist": "Data Scientist",
    "Data analyst Finance": "Data analyst Finance",
    "Data on recruitment needs.":
      "Donn\xE9es relatives aux besoins de recrutement.",
    "Data on the legal structure of Talent: name of the company, identification number, legal representative of the company and legal documents.":
      "Donn\xE9es relatives \xE0 la structure juridique du Talent : nom de la soci\xE9t\xE9, num\xE9ro SIREN, repr\xE9sentant l\xE9gal de la soci\xE9t\xE9 et documents l\xE9gaux.",
    "Data on your economic and socio-occupational situation: professional experience, academic training, bank details, Curriculum Vitae, references to former clients or employers, current and envisaged remuneration;":
      "Donn\xE9es  relatives  \xE0  votre  situation  \xE9conomique  et  socioprofessionnelle :  exp\xE9riences professionnelles, formation acad\xE9mique,  RIB, Curriculum Vitae, r\xE9f\xE9rences d\u2019anciens clients ou employeurs, r\xE9mun\xE9ration actuelle et envisag\xE9e;",
    "Data subjects are therefore invited to read this document carefully to find out and understand the company\u2019s practices regarding the processing of users\u2019 personal data.":
      "Les  Personnes  concern\xE9es  sont  ainsi  invit\xE9es  \xE0  lire  attentivement  le  pr\xE9sent  document  pour conna\xEEtre  et  comprendre  les  pratiques  de  la  Soci\xE9t\xE9  quant  aux  traitements  des  Donn\xE9es  \xE0 caract\xE8re personnel que la Soci\xE9t\xE9 met en \u0153uvre.",
    "Data subjects may be required to provide the Company, in its capacity as Controller within the meaning of the GDPR, with information and personal data relating to them.":
      "Les  Personnes  concern\xE9es  peuvent  \xEAtre  amen\xE9es  \xE0  fournir  \xE0  la  Soci\xE9t\xE9,  en  sa  qualit\xE9  de Responsable  de  traitement  au  sens  du  RGPD,  des  informations  et  Donn\xE9es  \xE0  caract\xE8re personnel les concernant.",
    Date: "Date",
    "Date de d\xE9but": "Date de d\xE9but",
    "Date de d\xE9marrage": "Date de d\xE9marrage",
    "Date de fin": "Date de fin",
    "Date de modification": "Date de modification",
    "Date de publication": "Date de publication",
    "Date d\u2019ajout": "Date d\u2019ajout",
    "Date of birth;": "Date of birth;",
    "De  fa\xE7on  g\xE9n\xE9rale  de  manquer  \xE0  toutes  obligations  l\xE9gales  ou  r\xE9glementaires  qui s\u2019imposeraient de droit et de fait \xE0 eux.":
      "De  fa\xE7on  g\xE9n\xE9rale  de  manquer  \xE0  toutes  obligations  l\xE9gales  ou  r\xE9glementaires  qui s\u2019imposeraient de droit et de fait \xE0 eux.",
    "De  la  m\xEAme  mani\xE8re,  les  Clients  s\u2019engagent  \xE0  ne  pas  d\xE9marcher  les  Talents  directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "De  la  m\xEAme  mani\xE8re,  les  Clients  s\u2019engagent  \xE0  ne  pas  d\xE9marcher  les  Talents  directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.",
    "De d\xE9nigrer la Plateforme et / ou la Soci\xE9t\xE9 et;":
      "De d\xE9nigrer la Plateforme et / ou la Soci\xE9t\xE9 et;",
    "De fa\xE7on g\xE9n\xE9rale de manquer \xE0 toutes obligations l\xE9gales ou r\xE9glementaires qui s\u2019imposeraient de droit et de fait \xE0 eux.":
      "De fa\xE7on g\xE9n\xE9rale de manquer \xE0 toutes obligations l\xE9gales ou r\xE9glementaires qui s\u2019imposeraient de droit et de fait \xE0 eux.",
    "De la m\xEAme mani\xE8re, les Clients s\u2019engagent \xE0 ne pas d\xE9marcher les Talents directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.":
      "De la m\xEAme mani\xE8re, les Clients s\u2019engagent \xE0 ne pas d\xE9marcher les Talents directement en dehors de la Plateforme et ce, afin d\u2019\xE9viter le recours \xE0 la Soci\xE9t\xE9.",
    "De porter atteinte aux droits de propri\xE9t\xE9 intellectuelle de la Soci\xE9t\xE9 et au prestataire;":
      "De porter atteinte aux droits de propri\xE9t\xE9 intellectuelle de la Soci\xE9t\xE9 et au prestataire;",
    "De tenter d\u2019obtenir l\u2019acc\xE8s non autoris\xE9 au syst\xE8me informatique de la Plateforme ou de se  livrer  \xE0  toute activit\xE9  perturbant,  diminuant  la  qualit\xE9  ou  interf\xE9rant  avec  les performances ou d\xE9t\xE9riorant les fonctionnalit\xE9s de la Plateforme;":
      "De tenter d\u2019obtenir l\u2019acc\xE8s non autoris\xE9 au syst\xE8me informatique de la Plateforme ou de se  livrer  \xE0  toute activit\xE9  perturbant,  diminuant  la  qualit\xE9  ou  interf\xE9rant  avec  les performances ou d\xE9t\xE9riorant les fonctionnalit\xE9s de la Plateforme;",
    "De tenter d\u2019obtenir l\u2019acc\xE8s non autoris\xE9 au syst\xE8me informatique de la Plateforme ou de se livrer \xE0 toute activit\xE9 perturbant, diminuant la qualit\xE9 ou interf\xE9rant avec les performances ou d\xE9t\xE9riorant les fonctionnalit\xE9s de la Plateforme;":
      "De tenter d\u2019obtenir l\u2019acc\xE8s non autoris\xE9 au syst\xE8me informatique de la Plateforme ou de se livrer \xE0 toute activit\xE9 perturbant, diminuant la qualit\xE9 ou interf\xE9rant avec les performances ou d\xE9t\xE9riorant les fonctionnalit\xE9s de la Plateforme;",
    "De vendre, copier, reproduire, louer, pr\xEAter, distribuer, transf\xE9rer ou conc\xE9der une sous-licence,  tout  ou  partie, des  contenus  figurant  sur  la  Plateforme  ou  de  d\xE9compiler, d\xE9sosser, d\xE9sassembler, modifier, tenter de d\xE9couvrir tout code source ou utiliser tout logiciel activant ou comprenant tout ou partie de la Plateforme;":
      "De vendre, copier, reproduire, louer, pr\xEAter, distribuer, transf\xE9rer ou conc\xE9der une sous-licence,  tout  ou  partie, des  contenus  figurant  sur  la  Plateforme  ou  de  d\xE9compiler, d\xE9sosser, d\xE9sassembler, modifier, tenter de d\xE9couvrir tout code source ou utiliser tout logiciel activant ou comprenant tout ou partie de la Plateforme;",
    "De vendre, copier, reproduire, louer, pr\xEAter, distribuer, transf\xE9rer ou conc\xE9der une sous-licence, tout ou partie, des contenus figurant sur la Plateforme ou de d\xE9compiler, d\xE9sosser, d\xE9sassembler, modifier, tenter de d\xE9couvrir tout code source ou utiliser tout logiciel activant ou comprenant tout ou partie de la Plateforme;":
      "De vendre, copier, reproduire, louer, pr\xEAter, distribuer, transf\xE9rer ou conc\xE9der une sous-licence, tout ou partie, des contenus figurant sur la Plateforme ou de d\xE9compiler, d\xE9sosser, d\xE9sassembler, modifier, tenter de d\xE9couvrir tout code source ou utiliser tout logiciel activant ou comprenant tout ou partie de la Plateforme;",
    "Demande de modification": "Demande de modification",
    "Demande par email renvoy\xE9e": "Demande par email renvoy\xE9e",
    "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:":
      "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:",
    "Derni\xE8re mise \xE0 jour : 1er f\xE9vrier 2022":
      "Derni\xE8re mise \xE0 jour : 1er f\xE9vrier 2022",
    "Des competences specifiques trouvees rapidement et efficacement.":
      "Des competences specifiques trouvees rapidement et efficacement.",
    "Des comp\xE9tences specifiques trouv\xE9es rapidement et efficacement.":
      "Des comp\xE9tences specifiques trouv\xE9es rapidement et efficacement.",
    "Des hommes et des algorithmes pour un matching parfait entre <0>entreprises</0> et <1>freelances IT</1>":
      "Donnez de l\u2019\xE9lan \xE0 vos <0>projets IT</0> et \xE0 votre <1>carri\xE8re</1> dans l\u2019informatique ou la technologie",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances en informatique. Nous accompagnons les meilleurs talents et les entreprises les plus innovantes.":
      "Donnez de l\u2019\xE9lan \xE0 vos <0>projets IT</0> et \xE0 votre <1>carri\xE8re</1> dans l\u2019informatique ou la technologie",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances informatique.":
      "Des hommes et des algorithmes pour un matching parfait entre entreprises et Talents IT.",
    "Des projets \xE0 la hauteur de votre expertise, sans effort de recherche":
      "Des projets \xE0 la hauteur de votre expertise, sans effort de recherche",
    "Des services": "Nos services",
    "Description de l'offre": "Description de l'offre",
    "Description de la mission": "Description de la mission",
    "Design Graphique & UX/UI": "Design Graphique & UX/UI",
    "Details of your contact details: postal address, e-mail address, mobile phone number;":
      "Donn\xE9es relatives \xE0 vos coordonn\xE9es : adresse postale, adresse de courrier \xE9lectronique, num\xE9ro de t\xE9l\xE9phone mobile;",
    "Devenez apporteur d\u2019affaire et faites-vous r\xE9mun\xE9rer":
      "Devenez apporteur d\u2019affaire et faites-vous r\xE9mun\xE9rer",
    "Devenir Freelance": "Devenir Freelance",
    Digital: "Digital",
    "Digital & IT Finance": "Digital & IT Finance",
    "Digital job descriptions - Mindquest Connect":
      "Digital job descriptions - Mindquest Connect",
    "Directeur comptable": "Directeur comptable",
    "Directeur consolidation": "Directeur consolidation",
    "Directeur de la publication : Thomas Delfort":
      "Directeur de la publication : Thomas Delfort",
    "Directeur financier": "Directeur financier",
    "Direction <0/> financi\xE8re": "Direction <0/> financi\xE8re",
    "Discover all our practical tips for IT decision makers to effectively work with tech & IT talent.":
      "Discover all our practical tips for IT decision makers to effectively work with tech & IT talent.",
    "Discover all our tips for IT talent and consultants and expert views to succeed in your tech and IT business and find the best freelance or permanent opportunities.":
      "Discover all our tips for IT talent and consultants and expert views to succeed in your tech and IT business and find the best freelance or permanent opportunities.",
    "Discover all the latest news about Mindquest and our tech & IT jobs (Development, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).":
      "Discover all the latest news about Mindquest and our tech & IT jobs (Development, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).",
    "Discover all the latest news from Mindquest and our selection of articles for Tech Magazine (Cybersecurity, ERP &SAP, Artificial Intelligence, Digital).":
      "Discover all the latest news from Mindquest and our selection of articles for Tech Magazine (Cybersecurity, ERP &SAP, Artificial Intelligence, Digital).",
    "Discover our news, our job descriptions, our selection of articles for IT talents and consultants, decision-makers and IT recruiters, and all our webinars and resources to recruit the ideal IT profile or find the ideal IT assignment.":
      "Discover our news, our job descriptions, our selection of articles for IT talents and consultants, decision-makers and IT recruiters, and all our webinars and resources to recruit the ideal IT profile or find the ideal IT assignment.",
    "Disponibile \xE0 partir de:": "Disponible \xE0 partir de :",
    Disponibilité: "Disponibilit\xE9",
    "Disponibilit\xE9 \xE0 confirmer": "Disponibilit\xE9 \xE0 confirmer",
    Disponible: "Disponible",
    "Disponible \xE0 partir de:": "Disponible \xE0 partir de:",
    Distance: "Distance",
    "Dites-nous ce que nous pouvons faire pour vous.":
      "Dites-nous ce que nous pouvons faire pour vous.",
    "Dites-nous ce que vous souhaitez, notre bot vous dirige vers le bon partenaire":
      "Dites-nous ce que vous souhaitez, notre bot vous dirige vers le bon partenaire",
    Documents: "Documents",
    "Doit contenir au moins 8 caract\xE8res":
      "Doit contenir au moins 8 caract\xE8res",
    "Doit contenir au moins un caract\xE8re sp\xE9cial !*#@...":
      "Doit contenir au moins un caract\xE8re sp\xE9cial !*#@...",
    "Doit contenir au moins un chiffre": "Doit contenir au moins un chiffre",
    "Doit contenir au moins une majuscule":
      "Doit contenir au moins une majuscule",
    "Doit contenir au moins une minuscule":
      "Doit contenir au moins une minuscule",
    "Domaine d\u2019expertise": "Domaine d\u2019expertise",
    "Domaine, Comp\xE9tences": "Domaine, Comp\xE9tences",
    "Domaines d'expertise": "Domaines d'expertise",
    "Domaines d\u2019expertise": "Domaines d\u2019expertise",
    Du: "Du",
    "Du contenu": "Du contenu",
    Duration: "Dur\xE9e",
    Dutch: "N\xE9erlandais",
    "D\xE8s lors que l\u2019Utilisateur continue d\u2019acc\xE9der et d\u2019utiliser la Plateforme, il sera r\xE9put\xE9 acceptent les conditions g\xE9n\xE9rales ainsi modifi\xE9es.":
      "D\xE8s lors que l\u2019Utilisateur continue d\u2019acc\xE9der et d\u2019utiliser la Plateforme, il sera r\xE9put\xE9 acceptent les conditions g\xE9n\xE9rales ainsi modifi\xE9es.",
    "D\xE9cideur / Recruteur": "D\xE9cideur / Recruteur",
    "D\xE9cideur IT": "D\xE9cideur IT",
    "D\xE9cideur/Recruteur": "D\xE9cideur/Recruteur",
    "D\xE9cideurs IT": "D\xE9cideurs IT",
    "D\xE9cideurs IT - Recevez le meilleur du contenu tech & IT, tous les mois : articles, veilles, s\xE9lection de profils IT ainsi que les derni\xE8res actualit\xE9s du secteur.":
      "D\xE9cideurs IT - Recevez le meilleur du contenu tech & IT, tous les mois : articles, veilles, s\xE9lection de profils IT ainsi que les derni\xE8res actualit\xE9s du secteur.",
    "D\xE9couvrez Club Freelance": "D\xE9couvrez Club Freelance",
    "D\xE9couvrez Mission Control Center : tous nos conseils pour les professionnels de l'IT":
      "D\xE9couvrez Mission Control Center : tous nos conseils pour les professionnels de l'IT",
    "D\xE9couvrez les m\xE9tiers de l\u2019informatique et du digital d\xE9crypt\xE9s":
      "D\xE9couvrez nos analyses sur l'\xE9volution du march\xE9 des comp\xE9tences IT",
    "D\xE9couvrez les tendances et \xE9volution des m\xE9tiers de l\u2019informatique & du digital \xE0 l\u2019heure du future of work. Fiches m\xE9tiers d\xE9taill\xE9es et analyses.":
      "D\xE9couvrez les tendances et \xE9volution des m\xE9tiers de l\u2019informatique & du digital \xE0 l\u2019heure du future of work. Fiches m\xE9tiers d\xE9taill\xE9es et analyses.",
    "D\xE9couvrez nos mod\xE8les de facture, de CV et template de note de frais":
      "D\xE9couvrez nos mod\xE8les de facture, de CV et template de note de frais",
    "D\xE9couvrez nos ressources pour les entreprises":
      "D\xE9couvrez nos ressources pour les entreprises",
    "D\xE9couvrez notre service de recrutement freelance":
      "D\xE9couvrez notre service de recrutement IT en freelance",
    "D\xE9couvrez notre \xE9quipe": "D\xE9couvrez notre \xE9quipe",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour recruter les meilleurs talents et travailler efficacement avec des freelances IT.":
      "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour recruter les meilleurs talents et travailler efficacement avec des Talents IT.",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 de freelance IT et d\xE9crocher les meilleures missions.":
      "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 de freelance IT et d\xE9crocher les meilleures missions.",
    "D\xE9couvrez tous nos conseils pour talent et consultants IT et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 dans la tech et l'informatique et d\xE9crocher les meilleures opportunit\xE9s en freelance ou CDI.":
      "D\xE9couvrez tous nos conseils pour talent et consultants IT et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 dans la tech et l'informatique et d\xE9crocher les meilleures opportunit\xE9s en freelance ou CDI.",
    "D\xE9couvrez tous nos conseils pour travailler efficacement avec les Freelances IT":
      "D\xE9couvrez tous nos conseils pour travailler efficacement avec des Talents IT",
    "D\xE9couvrez tous nos conseils pour une activit\xE9 de Freelance r\xE9ussie":
      "D\xE9couvrez tous nos conseils pour d\xE9velopper votre activit\xE9 de freelance IT",
    "D\xE9couvrez tous nos conseils pratiques pour d\xE9cideurs IT afin de travailler efficacement avec des Talents tech & IT.":
      "D\xE9couvrez tous nos conseils pratiques pour d\xE9cideurs IT afin de travailler efficacement avec des Talents tech & IT.",
    "D\xE9couvrez toutes les offres de votre Talent Community Speciallist":
      "D\xE9couvrez toutes les offres de votre Talent Community Speciallist",
    Découvrir: "D\xE9couvrir",
    "D\xE9couvrir Club Freelance": "D\xE9couvrir Mindquest",
    "D\xE9couvrir la plateforme Mindquest pour les entreprises":
      "D\xE9couvrir la plateforme Mindquest pour les entreprises",
    "D\xE9couvrir la plateforme Mindquest pour les talents":
      "D\xE9couvrir la plateforme Mindquest pour les talents",
    "D\xE9couvrir les fonctionnalit\xE9s":
      "D\xE9couvrir les fonctionnalit\xE9s",
    "D\xE9couvrir les ressources": "D\xE9couvrir les ressources",
    "D\xE9couvrir les rubriques": "D\xE9couvrir les rubriques",
    "D\xE9couvrir les success stories de notre communaut\xE9":
      "D\xE9couvrir les success stories de notre communaut\xE9",
    "D\xE9couvrir nos offres": "D\xE9couvrir nos offres",
    "D\xE9couvrir nos partenaires": "D\xE9couvrir nos partenaires",
    "D\xE9couvrir ses offres ouvertes": "D\xE9couvrir ses offres ouvertes",
    "D\xE9couvrir toutes les ressources": "D\xE9couvrir toutes les ressources",
    "D\xE9crivez en quelques mots votre activit\xE9 du mois (ou par jour selon la demande du client)":
      "D\xE9crivez en quelques mots votre activit\xE9 du mois (ou par jour selon la demande du client)",
    Décryptage: "D\xE9cryptage",
    Décryptages: "D\xE9cryptages",
    "D\xE9lai moyen pour recevoir une shortlist de candidats qualifi\xE9s":
      "D\xE9lai moyen pour recevoir une shortlist de candidats qualifi\xE9s",
    Démarrage: "D\xE9marrage",
    "D\xE9ploiement international": "D\xE9ploiement international",
    "D\xE9poser un projet": "D\xE9poser un projet de recrutement\u202F",
    "D\xE9scription de la mission": "Description du poste",
    Désolé: "D\xE9sol\xE9",
    "D\xE9sol\xE9, aucun r\xE9sultat trouv\xE9.":
      "D\xE9sol\xE9, aucun r\xE9sultat trouv\xE9.",
    "D\xE9sol\xE9, pas de candidats pour ces crit\xE8res":
      "D\xE9sol\xE9, pas de candidats pour ces crit\xE8res",
    "D\xE9sol\xE9, pas de missions pour ces crit\xE8res":
      "D\xE9sol\xE9, pas de missions pour ces crit\xE8res",
    "D\xE9taillez-votre activit\xE9 du mois (visible par le client)":
      "D\xE9taillez-votre activit\xE9 du mois (visible par le client)",
    "D\xE9tails de l'offre": "D\xE9tails de l'offre",
    Développement: "D\xE9veloppement",
    "D\xE9veloppement Logiciels & Syst\xE8mes d'Exploitation":
      "D\xE9veloppement Logiciels & Syst\xE8mes d'Exploitation",
    "D\xE9veloppement Mobile": "D\xE9veloppement Mobile",
    "D\xE9veloppement Web": "D\xE9veloppement Web",
    "D\xE9velopper sa carri\xE8re: CDI & freelance":
      "D\xE9velopper sa carri\xE8re: CDI & freelance",
    "D\u2019utiliser la Plateforme de toute mani\xE8re ill\xE9gale, pour toute finalit\xE9 ill\xE9gale ou de toute mani\xE8re incompatible avec les CGU;":
      "D\u2019utiliser la Plateforme de toute mani\xE8re ill\xE9gale, pour toute finalit\xE9 ill\xE9gale ou de toute mani\xE8re incompatible avec les CGU;",
    "D\u2019utiliser la Plateforme \xE0 des fins abusives en y introduisant volontairement des virus ou tout autre programme malveillant et de tenter d\u2019acc\xE9der de mani\xE8re non autoris\xE9e \xE0 la Plateforme;":
      "D\u2019utiliser la Plateforme \xE0 des fins abusives en y introduisant volontairement des virus ou tout autre programme malveillant et de tenter d\u2019acc\xE9der de mani\xE8re non autoris\xE9e \xE0 la Plateforme;",
    "E-commerce": "E-commerce",
    "E-mail": "E-mail",
    EN: "EN",
    "ERP & SAP": "ERP & SAP",
    "ERP & SAP job description - Mindquest Connect":
      "ERP & SAP job description - Mindquest Connect",
    "ERP / EDI": "ERP / EDI",
    "ERP supply": "ERP supply",
    EURL: "EURL",
    "Each  individual  concerned  shall  have  the  right  to  lodge  a  complaint  with  a  competent supervisory authority.":
      "Chaque Personne concern\xE9e dispose enfin du droit d\u2019introduire une r\xE9clamation aupr\xE8s d\u2019une autorit\xE9 de contr\xF4le comp\xE9tente.",
    "Each individual concerned shall have the right to lodge a complaint with a competent supervisory authority.":
      "Chaque Personne concern\xE9e dispose enfin du droit d\u2019introduire une r\xE9clamation aupr\xE8s d\u2019une autorit\xE9 de contr\xF4le comp\xE9tente.",
    "Echec de modification du mot de passe.":
      "Echec de modification du mot de passe.",
    Editer: "Editer",
    Editeur: "Editeur",
    "Education & Qualifications;": "Education & Qualifications;",
    "Effacer tous": "Effacer tous",
    "Effacer tout": "Effacer tout",
    "Elles cessent le jour o\xF9 le compte de l\u2019Utilisateur est supprim\xE9 pour quelque motif que ce soit.":
      "Elles cessent le jour o\xF9 le compte de l\u2019Utilisateur est supprim\xE9 pour quelque motif que ce soit.",
    Email: "Email",
    "Email Envoy\xE9": "Email envoy\xE9",
    "Email d\xE9j\xE0 associ\xE9 \xE0 un compte existant":
      "Email d\xE9j\xE0 associ\xE9 \xE0 un compte existant",
    "Email envoy\xE9 avec succ\xE9es": "Email envoy\xE9 avec succ\xE9es",
    "Email invalide": "Email invalide",
    "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.":
      "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.",
    "Embedded Sytems": "Syst\xE8mes Embarqu\xE9s",
    "Emploi Finance Freelance ou CDI - Mindquest Finance":
      "Trouver un emploi dans la Finance en Freelance ou CDI - Mindquest Finance",
    "Emploi Finance en CDI": "Emploi Finance en CDI",
    "Emplois IT en CDI": "Emplois IT en CDI",
    Employabilité: "Employabilit\xE9",
    En: "En",
    "En attente": "En attente",
    "En aucun cas, cette licence n\u2019autorise les Utilisateurs \xE0 reproduire, copier, dupliquer, modifier, transformer, traduire les diff\xE9rents \xE9l\xE9ments composant la Plateforme.":
      "En aucun cas, cette licence n\u2019autorise les Utilisateurs \xE0 reproduire, copier, dupliquer, modifier, transformer, traduire les diff\xE9rents \xE9l\xE9ments composant la Plateforme.",
    "En aucun cas, cette \xE9valuation ne pourra \xEAtre utilis\xE9e contre les Consultants.":
      "En aucun cas, cette \xE9valuation ne pourra \xEAtre utilis\xE9e contre les Consultants.",
    "En aucun cas, la Soci\xE9t\xE9 n\u2019intervient pour assurer un r\xF4le de mod\xE9rateur \xE0 cet \xE9gard.":
      "En aucun cas, la Soci\xE9t\xE9 n\u2019intervient pour assurer un r\xF4le de mod\xE9rateur \xE0 cet \xE9gard.",
    "En cas de litige les parties feront leurs meilleurs efforts pour trouver un accord amiable.":
      "En cas de litige les parties feront leurs meilleurs efforts pour trouver un accord amiable.",
    "En cas de non-respect de cette obligation, la Soci\xE9t\xE9 pourra suspendre l\u2019acc\xE8s \xE0 la Plateforme pour une dur\xE9e qu\u2019elle d\xE9terminera ou y mettre d\xE9finitif sans pr\xE9judice de toute demande de dommages-int\xE9r\xEAts qu\u2019elle pourrait solliciter devant toute juridiction comp\xE9tente.":
      "En cas de non-respect de cette obligation, la Soci\xE9t\xE9 pourra suspendre l\u2019acc\xE8s \xE0 la Plateforme pour une dur\xE9e qu\u2019elle d\xE9terminera ou y mettre d\xE9finitif sans pr\xE9judice de toute demande de dommages-int\xE9r\xEAts qu\u2019elle pourrait solliciter devant toute juridiction comp\xE9tente.",
    "En cas de non-respect des conditions de ladite licence d\u2019utilisation, les Utilisateurs s\u2019exposent \xE0 des sanctions et des poursuites sur le fondement de la contrefa\xE7on notamment.":
      "En cas de non-respect des conditions de ladite licence d\u2019utilisation, les Utilisateurs s\u2019exposent \xE0 des sanctions et des poursuites sur le fondement de la contrefa\xE7on notamment.",
    "En chargeant votre CV, vos informations seront pr\xE9remplies. Un CV au format Word offrira de meilleurs r\xE9sultats":
      "En chargeant votre CV, vos informations seront pr\xE9remplies. Un CV au format Word offrira de meilleurs r\xE9sultats",
    "En cliquant sur le bouton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login":
      "En cliquant sur le bouton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login",
    "En cliquant sur le button ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login":
      "En cliquant sur le boutton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login",
    "En construction": "En construction",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Mindquest de votre retour.",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Mindquest de votre retour.",
    "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Mindquest de votre retour.",
    "En outre, il est pr\xE9cis\xE9 que les marques, logotypes, signes et tout autre \xE9l\xE9ment de la Plateforme appartiennent \xE0 la Soci\xE9t\xE9 et ne sauraient \xEAtre librement reproduits par un Utilisateur.":
      "En outre, il est pr\xE9cis\xE9 que les marques, logotypes, signes et tout autre \xE9l\xE9ment de la Plateforme appartiennent \xE0 la Soci\xE9t\xE9 et ne sauraient \xEAtre librement reproduits par un Utilisateur.",
    "En outre, la Soci\xE9t\xE9 garantit qu\u2019il met en \u0153uvre tous les moyens \xE0 sa disposition pour assurer la s\xE9curit\xE9 et l\u2019int\xE9grit\xE9 de la Plateforme ainsi que des donn\xE9es qui y sont stock\xE9es.":
      "En outre, la Soci\xE9t\xE9 garantit qu\u2019il met en \u0153uvre tous les moyens \xE0 sa disposition pour assurer la s\xE9curit\xE9 et l\u2019int\xE9grit\xE9 de la Plateforme ainsi que des donn\xE9es qui y sont stock\xE9es.",
    "En outre, la plateforme permet aux Talents en mission de faire valider chaque mois leur compte-rendu d\u2019activit\xE9 par le Client gr\xE2ce \xE0 l\u2019application Timesheet Manager. A ce titre, la Soci\xE9t\xE9 se r\xE9serve la possibilit\xE9 de cr\xE9er un identifiant unique relatif \xE0 un Utilisateur, de sorte \xE0 permettre la validation des comptes-rendus.":
      "En outre, la plateforme permet aux Talents en mission de faire valider chaque mois leur compte-rendu d\u2019activit\xE9 par le Client gr\xE2ce \xE0 l\u2019application Timesheet Manager. A ce titre, la Soci\xE9t\xE9 se r\xE9serve la possibilit\xE9 de cr\xE9er un identifiant unique relatif \xE0 un Utilisateur, de sorte \xE0 permettre la validation des comptes-rendus.",
    "En outre, les Consultants s\u2019engagent \xE0 informer la Soci\xE9t\xE9 de tout d\xE9marchage ou sollicitation de la part de Clients avec lesquels ils auraient \xE9t\xE9 mis en relation dans le cadre de l\u2019utilisation de la Plateforme.":
      "En outre, les Consultants s\u2019engagent \xE0 informer la Soci\xE9t\xE9 de tout d\xE9marchage ou sollicitation de la part de Clients avec lesquels ils auraient \xE9t\xE9 mis en relation dans le cadre de l\u2019utilisation de la Plateforme.",
    "En rejetant le timesheet, celui-ci sera renvoy\xE9 au consultant pour modification. Merci d'indiquer en commentaire la raison de ce rejet de la fa\xE7on la plus pr\xE9cise possible.":
      "En rejetant le timesheet, celui-ci sera renvoy\xE9 au consultant pour modification. Merci d'indiquer en commentaire la raison de ce rejet de la fa\xE7on la plus pr\xE9cise possible.",
    "En savoir plus": "En savoir plus",
    "En transport en commun": "En transport en commun",
    "En voiture": "En voiture",
    "Encore quelques instants...": "Encore quelques instants...",
    "Encore une petite \xE9tape avant de recevoir la newsletter des professionnels de la finance":
      "Encore une petite \xE9tape avant de recevoir la newsletter des professionnels de la finance",
    "Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.":
      "Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.",
    "Encryption technology is in use to protect the integrity of the personal data we process.":
      "Encryption technology is in use to protect the integrity of the personal data we process.",
    Energy: "Energy",
    EngageBay: "EngageBay",
    English: "Anglais",
    "Enjeux DSI": "Enjeux DSI",
    "Enlever de favoris": "Enlever des favoris",
    "Enregistrement de la feuille de temps":
      "Enregistrement de la feuille de temps",
    "Enregistrement effectu\xE9 avec succ\xE8s":
      "Enregistrement effectu\xE9 avec succ\xE8s",
    Enregistrer: "Enregistrer",
    "Enregistrer en brouillon": "Enregistrer en brouillon",
    "Enregistrer en favori": "Enregistrer en favori",
    "Enregistrer en favoris": "Enregistrer en favoris",
    "Enter your email address": "Votre email",
    "Entering a competition through a social media channel such as Facebook or Twitter.":
      "Entering a competition through a social media channel such as Facebook or Twitter.",
    "Entering your details on the Club Freelance website or registering an account with us;":
      "Entering your details on Mindquest website or registering an account with us;",
    "Entering your personal details into a Club Freelance microsite; or":
      "Entering your personal details into Mindquest microsite; or",
    Entreprise: "Entreprise",
    "Entreprise ?": "Entreprise ?",
    Entreprise_: "Entreprises",
    Entreprises: "Entreprises",
    "Entrer adresse mail": "Entrer adresse mail",
    "Entrer le mot de passe actuel": "Entrer le mot de passe actuel",
    "Entrer les informations de mon entreprise":
      "Entrer les informations de mon entreprise",
    "Entrer mon email": "Entrer mon email",
    "Entrer un email valid": "Entrez un email valide",
    "Entrer un mot de passe": "Entrez un mot de passe",
    "Entrer une adresse email": "Entrer une adresse email",
    "Entrer votre email": "Entrez votre email",
    "Entrer votre mot de passe": "Entrez votre mot de passe",
    Entretien: "Entretien",
    Entretiens: "Entretiens",
    "Entrez ce m\xEAme mot de passe": "Entrez ce m\xEAme mot de passe",
    "Entrez ici vos mots-cl\xE9s IT": "Entrez ici vos mots-cl\xE9s IT",
    "Entrez le mot de passe actuel": "Entrez le mot de passe actuel",
    "Entrez un mot de passe": "Entrez un mot de passe",
    "Entrez votre nouvelle adresse email":
      "Entrez votre nouvelle adresse email",
    "Environements technique": "Environements technique",
    "Environnement technique": "Environnement technique",
    Envoyer: "Envoyer",
    "Envoyer Timesheet": "Envoyer Timesheet",
    Erreur: "Erreur",
    "Erreur c\xF4t\xE9 de serveur. Essayez plus tard":
      "Erreur c\xF4t\xE9 de serveur. Essayez plus tard",
    "Erreur de cr\xE9ation de besoin":
      "Erreur : votre besoin n'a pas \xE9t\xE9 cr\xE9\xE9, veuillez r\xE9essayer",
    "Erreur de cr\xE9ation de candidature":
      "Erreur : votre candidature n'a pas \xE9t\xE9 cr\xE9e veuillez r\xE9essayer",
    "Erreur de cr\xE9ation d\u2019alerte":
      "Erreur de cr\xE9ation d\u2019alerte",
    "Erreur non identifi\xE9e": "Erreur non identifi\xE9e",
    "Erreur!": "Erreur !",
    Error: "Erreur",
    Espagnol: "Espagnol",
    "Essayez la d\xE9tection automatique":
      "Essayez la d\xE9tection automatique",
    "Et remplir mon profil \xE0 la main": "Et remplir mon profil \xE0 la main",
    "Et trouvez les meilleurs consultants en informatique pour vos projets":
      "Et acc\xE9l\xE9rez sur vos projets IT & Finance en mobilisant les meilleurs talents",
    Etablissement: "Etablissement",
    "Etape suivante": "\xC9tape suivante",
    "Etre recontact\xE9 par l'un de nos expert en recrutement":
      "Etre recontact\xE9 par l'un de nos expert en recrutement",
    "Ever wondered what it is that your colleagues in other areas of IT actually do?":
      "Ever wondered what it is that your colleagues in other areas of IT actually do?",
    "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.":
      "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.",
    "Evolutions des Jobs SAP": "Evolution des Jobs SAP",
    "Ex : Architecte Java, Consultant SAP":
      "Ex : Architecte Java, Consultant SAP",
    "Ex:Architecte Java, Consultant SAP":
      "Ex : Architecte Java, Consultant SAP",
    Experiences: "Experiences",
    Expert: "Expert",
    "Expert (10+)": "Expert (10+)",
    "Expert Comptable ind\xE9pendant": "Expert Comptable ind\xE9pendant",
    Expertise: "Domaines d\u2019expertise",
    "Expired token": "Expired token",
    "Exporter en CSV": "Exporter en CSV",
    Expérience: "Exp\xE9rience",
    Expériences: "Exp\xE9riences",
    "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;":
      "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;",
    "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.":
      "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.",
    "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.":
      "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.",
    "FP&A": "FP&A",
    "FP&A Audit": "FP&A Audit",
    "FP&A Manager": "FP&A Manager",
    FR: "FR",
    "FREELANCE ?": "Candidats ?",
    Favoris: "Favoris",
    Fermer: "Fermer",
    "Fiches m\xE9tiers": "Fiches m\xE9tiers",
    "Fiches m\xE9tiers Data & Business Intelligence - Mindquest Connect":
      "Fiches m\xE9tiers Data & Business Intelligence - Mindquest Connect",
    "Fiches m\xE9tiers Digital - Mindquest Connect":
      "Fiches m\xE9tiers Digital - Mindquest Connect",
    "Fiches m\xE9tiers D\xE9veloppement - Mindquest Connect":
      "Fiches m\xE9tiers D\xE9veloppement - Mindquest Connect",
    "Fiches m\xE9tiers ERP & SAP - Mindquest Connect":
      "Fiches m\xE9tiers ERP & SAP - Mindquest Connect",
    "Fiches m\xE9tiers Infrastructure IT - Mindquest Connect":
      "Fiches m\xE9tiers Infrastructure IT - Mindquest Connect",
    "Fiches m\xE9tiers Project Management - Mindquest Connect":
      "Fiches m\xE9tiers Project Management - Mindquest Connect",
    "Fichier g\xE9n\xE9r\xE9 avec succ\xE8s":
      "Fichier g\xE9n\xE9r\xE9 avec succ\xE8s",
    "Fichiers M\xE9tiers": "Fiches M\xE9tiers",
    "Filtrer par projet": "Filtrer par projet",
    "Filtrer par statut": "Filtrer par statut",
    "Filtres S\xE9lectionn\xE9s :": "Filtres S\xE9lectionn\xE9s :",
    "Filtres Vide": "Filtres Vide",
    Filtré: "Filtr\xE9",
    Finance: "Finance",
    "Finance Client": "Finance Client",
    "Finance Consultant": "Finance Consultant",
    "Find a blog post from our selection of content, news, how-to's and resources for IT Talent and Decision Makers.":
      "Find a blog post from our selection of content, news, how-to's and resources for IT Talent and Decision Makers.",
    "Find all the latest Mindquest news and our interviews of top IT experts with our Podcast Mission Control Center.":
      "Find all the latest Mindquest news and our interviews of top IT experts with our Podcast Mission Control Center.",
    "Find all the latest Mindquest news and our interviews on top IT experts with our Podcast Mission Control Center.":
      "Find all the latest Mindquest news and our interviews on top IT experts with our Podcast Mission Control Center.",
    "Find all the latest Mindquest news and our job descriptions about the skills, missions and specificities of digital jobs.":
      "Find all the latest Mindquest news and our job descriptions about the skills, missions and specificities of digital jobs.",
    "Find all the latest Mindquest news and our selection of Tech Magazine articles on the top 10 IT experts lists.":
      "Find all the latest Mindquest news and our selection of Tech Magazine articles on the top 10 IT experts lists.",
    "Find all the latest Mindquest news and our selection of Tech Magazine articles with the top 10 IT experts lists.":
      "Find all the latest Mindquest news and our selection of Tech Magazine articles with the top 10 IT experts lists.",
    "Find all the latest Mindquest news and our selection of articles for IT consultants on developing your career on a permanent or freelance basis on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT consultants on developing your career on a permanent or freelance basis on our blog Mindquest Connect.",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers (Talent strategy, Recruiting an IT consultant, Risks & mistakes to avoid, Legal, IT issues...).":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers (Talent strategy, Recruiting an IT consultant, Risks & mistakes to avoid, Legal, IT issues...).",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers on CIO issues on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on CIO issues on our blog Mindquest Connect.",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers on recruiting IT consultants and talent on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on recruiting IT consultants and talent on our blog Mindquest Connect.",
    "Find all the latest Mindquest news and our selection of articles for IT decision-makers on risks and mistakes to avoid on our blog Mindquest Connect.":
      "Find all the latest Mindquest news and our selection of articles for IT decision-makers on risks and mistakes to avoid on our blog Mindquest Connect.",
    "Find all the latest news about Mindquest and our selection of articles to find out about our news, press releases and testimonials in the About Us section.":
      "Find all the latest news about Mindquest and our selection of articles to find out about our news, press releases and testimonials in the About Us section.",
    "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of Project Management jobs.":
      "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of Project Management jobs.",
    "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of the Data & Business Intelligence professions.":
      "Find all the latest news from Mindquest and our job descriptions about the skills, missions, and specificities of the Data & Business Intelligence professions.",
    "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of ERP & SAP jobs.":
      "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of ERP & SAP jobs.",
    "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of IT Infrastructure jobs.":
      "Find all the latest news from Mindquest and our job descriptions on the skills, missions and particularities of IT Infrastructure jobs.",
    "Find all the latest news from Mindquest and our selection of articles about Cybersecurity on our Tech Magazine.":
      "Find all the latest news from Mindquest and our selection of articles about Cybersecurity on our Tech Magazine.",
    "Find all the latest news from Mindquest and our selection of articles about cybersecurity on our Tech Magazine":
      "Find all the latest news from Mindquest and our selection of articles about cybersecurity on our Tech Magazine",
    "Find all the latest news from Mindquest and our selection of articles about job application advices for IT Consultants.":
      "Find all the latest news from Mindquest and our selection of articles about job application advices for IT Consultants.",
    "Find all the latest news from Mindquest and our selection of articles for IT Consultants about growing your career as permanent & freelance.":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer.",
    "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer .":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer .",
    "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer.":
      "Find all the latest news from Mindquest and our selection of articles for IT Consultants about tips for your daily life as a freelancer.",
    "Find all the latest news from Mindquest and our selection of articles for Tech Magazine":
      "Find all the latest news from Mindquest and our selection of articles for Tech Magazine",
    "Find all the latest news from Mindquest and our selection of articles for consultants and freelance IT talent.":
      "Find all the latest news from Mindquest and our selection of articles for consultants and freelance IT talent.",
    "Find all the latest news from Mindquest and our selection of articles for it Consultants":
      "Find all the latest news from Mindquest and our selection of articles for it Consultants",
    "Find all the latest news from Mindquest and our selection of articles for our news":
      "Find all the latest news from Mindquest and our selection of articles for our news",
    "Find all the latest news from Mindquest and our selection of articles on our Tech Magazine.":
      "Find all the latest news from Mindquest and our selection of articles on our Tech Magazine.",
    "Find all the latest news from Mindquest and our selection of press reviews on our Tech Magazine":
      "Find all the latest news from Mindquest and our selection of press reviews on our Tech Magazine",
    "Find all the news from Mindquest about on our Captains's Log":
      "Find all the news from Mindquest about on our Captains's Log",
    "Find all the news from Mindquest and our advice on IT recruitment and Job Hunting.":
      "Find all the news from Mindquest and our advice on IT recruitment and Job Hunting.",
    "Find all the news from Mindquest and our advice on IT recruitment and Job search advice.":
      "Find all the news from Mindquest and our advice on IT recruitment and Job search advice.",
    "Find all the news from Mindquest on our Captains's Log":
      "Find all the news from Mindquest on our Captains's Log",
    "Find freelancer": "Trouver un talent",
    "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.":
      "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.",
    "Fiscalit\xE9, imp\xF4ts & retraite": "Fiscalit\xE9, imp\xF4ts & retraite",
    "For Clients, the following data are collected:":
      "S\u2019agissant des Clients, les donn\xE9es suivantes sont collect\xE9es:",
    "For Talents, the following data are collected:":
      "S\u2019agissant des Talents, les donn\xE9es suivantes sont collect\xE9es:",
    "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.":
      "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.",
    Format: "Format",
    "Format d\u2019image invalide. Formats recommand\xE9s : .jpeg .png":
      "Format d\u2019image invalide. Formats recommand\xE9s : .jpeg .png",
    "Format non valide": "Format non valide",
    "Formation et Certifications": "Formation et Certifications",
    "Formez vos \xE9quipes avec Mindquest et Groupe Y":
      "Formez vos \xE9quipes avec Mindquest et Groupe Y",
    Fr: "Fr",
    Français: "Fran\xE7ais",
    Freelance: "Freelance",
    "Freelance / Salari\xE9": "Freelance / Salari\xE9",
    "Freelance informatique, consultant IT, mission freelance, mission sap, mission d\xE9veloppement, mission data":
      "Freelance informatique, consultant IT, mission freelance, mission sap, mission d\xE9veloppement, mission data",
    "Freelance ou CDI": "Freelance ou CDI",
    "Freelance/Contract": "Freelance/Contract",
    FreelanceType: "Freelance",
    Freelances: "Candidats",
    French: "Fran\xE7ais",
    "Full day": "Full day",
    "F\xE9licitations!": "F\xE9licitations !",
    "F\xE9licitations!! Tous les champs obligatoires de votre profil sont remplis, vous pouvez maintenant g\xE9n\xE9rer votre CV Club Freelance.":
      "F\xE9licitations!! Tous les champs obligatoires de votre profil sont remplis, vous pouvez maintenant g\xE9n\xE9rer votre CV Club Freelance.",
    "F\xE9licitations!! Votre profil est rempli \xE0 {0}%, vous pouvez maintenant g\xE9n\xE9rer votre CV Club Freelance.": function(
      a
    ) {
      return [
        "F\xE9licitations ! Votre profil est rempli \xE0 ",
        a("0"),
        "%, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest."
      ];
    },
    "F\xE9licitations!! Votre profil est rempli \xE0 {0}%, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest.": function(
      a
    ) {
      return [
        "F\xE9licitations!! Votre profil est rempli \xE0 ",
        a("0"),
        "%, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest."
      ];
    },
    "F\xE9licitations, votre profil est compl\xE9t\xE9 \xE0 100% ! D\xE9couvrez d\xE8s maintenant nos offres recommand\xE9es pour votre profil.":
      "F\xE9licitations, votre profil est compl\xE9t\xE9 \xE0 100% ! D\xE9couvrez d\xE8s maintenant nos offres recommand\xE9es pour votre profil.",
    German: "Allemand",
    "Gestion de projet & Coaching Agile": "Gestion de projet & Coaching Agile",
    "Gestion des collaborateurs": "Gestion des collaborateurs",
    "Gestionnaire de Paie": "Gestionnaire de Paie",
    "Google Adwords": "Google Adwords",
    "Google Analytics": "Google Analytics",
    "Google Chrome": "Google Chrome",
    "Google tag manager": "Google tag manager",
    "Gouvernance Informatique": "Gouvernance Informatique",
    "Grand Groupe (500+)": "Grand Groupe (500+)",
    "Grande entreprise (101-150)": "Grande entreprise (101-150)",
    "Grande entreprise (101-500)": "Grande entreprise (101-500)",
    Greek: "Greque",
    Greque: "Greque",
    "Gr\xE2ce \xE0 notre \xE9quipe d\u2019experts, nous recueillons quotidiennement les insights des entreprises et des candidats sur le march\xE9 des comp\xE9tences IT":
      "Gr\xE2ce \xE0 notre \xE9quipe d\u2019experts, nous recueillons quotidiennement les insights des entreprises et des candidats sur le march\xE9 des comp\xE9tences IT et Finance",
    Guide: "Guide",
    "Guides utilisateurs": "Guides utilisateurs",
    "G\xE9nerer votre CV": "G\xE9nerer votre CV",
    "G\xE9n\xE9rez d\xE8s maintenant votre CV Mindquest et diffusez le gr\xE2ce au lien partageable !":
      "G\xE9n\xE9rez d\xE8s maintenant votre CV Mindquest et diffusez le gr\xE2ce au lien partageable !",
    "G\xE9rer vos Feuilles de temps": "G\xE9rer vos Feuilles de temps",
    "G\xE9rez vos Feuilles de temps": "G\xE9rez vos Feuilles de temps",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace perso.":
      "G\xE9rez vos alertes et vos candidatures depuis votre espace perso.",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace personel.":
      "G\xE9rez vos alertes et vos candidatures depuis votre espace perso.",
    "G\xE9rez vos documents administratifs":
      "G\xE9rez vos documents administratifs",
    "G\xE9rez vos missions et vos contrats depuis votre espace client.":
      "G\xE9rez vos missions et vos contrats depuis votre espace client.",
    "Help Center": "Help Center",
    Herefish: "Herefish",
    Heures: "Heures",
    "Heures & Astreintes": "Heures & Astreintes",
    "Heures (taux normal)": "Heures (taux normal)",
    "Heures Totales": "Heures Totales",
    "Heures normales": "Heures normales",
    "Heures par jour (moyenne)": "Heures par jour (moyenne)",
    "Heures sp\xE9ciales": "Heures sp\xE9ciales",
    "Heures suppl\xE9mentaires": "Heures suppl\xE9mentaires",
    "Heures totales": "Heures totales",
    Hindi: "Hindi",
    Historique: "Historique",
    Home: "Home",
    "Horizon 2050": "Horizon 2050",
    "Hors Informatique": "Hors Informatique",
    Hotjar: "Hotjar",
    Hours: "Hours",
    "How can we help?":
      "Connecter les meilleurs talents aux meilleures offres IT et Finance",
    "How can you access, amend, or take back the personal information that you have given us and what are your rights?":
      "How can you access, amend, or take back the personal information that you have given us and what are your rights?",
    "How do we collect your personal data?":
      "How do we collect your personal data?",
    "How do we store and transfer your data internationally?":
      "How do we store and transfer your data internationally?",
    "How do we use your personal data?": "How do we use your personal data?",
    "How long do we keep your personal data for?":
      "How long do we keep your personal data for?",
    "How to contact us about your personal information":
      "How to contact us about your personal information",
    "However, since some of the Company\u2019s partners and service providers may be located outside the European Union, personal data collected in this way may be transferred to countries which are not members of the European Union, whose legislation on the protection of personal data differs from the European Union. If the recipient country or countries do not ensure a level of data  protection  equivalent  to  the  European  Union, the  company  undertakes  to  take  all appropriate safeguards, either on the basis of an adequacy decision or, in the absence of such a decision,  on  the  basis  of  appropriate  safeguards  such  as  the  standard  contractual  clauses adopted.":
      "Cependant, certains des partenaires et prestataires de la Soci\xE9t\xE9 pouvant \xEAtre situ\xE9s en dehors de l\u2019Union Europ\xE9enne, les Donn\xE9es \xE0 caract\xE8re personnel ainsi collect\xE9es peuvent faire l\u2019objet d'un transfert vers des pays non-membres de l\u2019Union Europ\xE9enne, dont la l\xE9gislation en mati\xE8re de protection des donn\xE9es personnelles diff\xE8re de celle de l\u2019Union Europ\xE9enne, \xE9tant pr\xE9cis\xE9 que, dans l\u2019hypoth\xE8se o\xF9 le ou les pays destinataires n\u2019assureraient pas un niveau de protection des donn\xE9es \xE9quivalent \xE0 celui de l\u2019Union Europ\xE9enne, la Soci\xE9t\xE9 s\u2019engage \xE0 prendre toutes les garanties appropri\xE9es, soit sur la base d\u2019une d\xE9cision d\u2019ad\xE9quation soit, en l\u2019absence d\u2019une telle d\xE9cision,  sur  la  base  de  garanties  appropri\xE9es  \xE0  l\u2019instar  des  clauses  contractuelles  types adopt\xE9es dont la copie peut \xEAtre demand\xE9e aux coordonn\xE9es ci-dessus.",
    "However, since some of the Company\u2019s partners and service providers may be located outside the European Union, personal data collected in this way may be transferred to countries which are not members of the European Union, whose legislation on the protection of personal data differs from the European Union. If the recipient country or countries do not ensure a level of data protection equivalent to the European Union, the company undertakes to take all appropriate safeguards, either on the basis of an adequacy decision or, in the absence of such a decision, on the basis of appropriate safeguards such as the standard contractual clauses adopted.":
      "Cependant, certains des partenaires et prestataires de la Soci\xE9t\xE9 pouvant \xEAtre situ\xE9s en dehors de l\u2019Union Europ\xE9enne, les Donn\xE9es \xE0 caract\xE8re personnel ainsi collect\xE9es peuvent faire l\u2019objet d'un transfert vers des pays non-membres de l\u2019Union Europ\xE9enne, dont la l\xE9gislation en mati\xE8re de protection des donn\xE9es personnelles diff\xE8re de celle de l\u2019Union Europ\xE9enne, \xE9tant pr\xE9cis\xE9 que, dans l\u2019hypoth\xE8se o\xF9 le ou les pays destinataires n\u2019assureraient pas un niveau de protection des donn\xE9es \xE9quivalent \xE0 celui de l\u2019Union Europ\xE9enne, la Soci\xE9t\xE9 s\u2019engage \xE0 prendre toutes les garanties appropri\xE9es, soit sur la base d\u2019une d\xE9cision d\u2019ad\xE9quation soit, en l\u2019absence d\u2019une telle d\xE9cision,  sur  la  base  de  garanties  appropri\xE9es  \xE0  l\u2019instar  des  clauses  contractuelles  types adopt\xE9es dont la copie peut \xEAtre demand\xE9e aux coordonn\xE9es ci-dessus.",
    "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.":
      "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.",
    "However, you have the possibility to refuse these cookies by setting your browser. In such a case, the site might suffer partial malfunctions.":
      "N\xE9anmoins,  vous  avez  la  possibilit\xE9  de  refuser  ces  cookies  en  param\xE9trant  votre  navigateur. Dans une telle hypoth\xE8se, le site pourrait subir des dysfonctionnements partiels.",
    "H\xE9bergeur du Site Internet": "H\xE9bergeur du Site Internet",
    "I'm looking for Jobs": "Je cherche des missions",
    "I'm looking for Talent": "Je cherche des freelances",
    "IFRS specialist": "IFRS specialist",
    "INFORMATION ON COOKIES": "INFORMATION SUR LES COOKIES",
    "INVALID RECAPTCHA": "INVALID RECAPTCHA",
    "INVERIFIED ACCOUNT": "INVERIFIED ACCOUNT",
    "IP address National Insurance number or country equivalent;":
      "IP address National Insurance number or country equivalent;",
    "IT / Digital": "IT / Digital",
    "IT <0/> Finance": "IT <0/> Finance",
    "IT Consultants": "IT Consultants",
    "IT Deciders articles - Risks and mistakes to avoid - Mindquest Connect":
      "IT Deciders articles - Risks and mistakes to avoid - Mindquest Connect",
    "IT Decision Maker": "IT Decision Maker",
    "IT Decision Maker Articles - Recruiting IT Consultants - Mindquest Connect":
      "IT Decision Maker Articles - Recruiting IT Consultants - Mindquest Connect",
    "IT Decision Makers Articles - CIO Issues - Mindquest Connect":
      "IT Decision Makers Articles - CIO Issues - Mindquest Connect",
    "IT Decision maker": "IT Decision maker",
    "IT Decision-makers": "IT Decision-makers",
    "IT Governance": "Gouvernance Informatique",
    "IT Infrastructure job descriptions - Mindquest Connect":
      "IT Infrastructure job descriptions - Mindquest Connect",
    "IT and tech news of the week, every week. These are stories to keep in mind.":
      "IT and tech news of the week, every week. These are stories to keep in mind.",
    "IT career advice at zero price. Get the contract or job you always wanted.":
      "IT career advice at zero price. Get the contract or job you always wanted.",
    "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.":
      "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.",
    "IT decision maker": "IT decision maker",
    "IT ou Finance": "IT ou Finance",
    "IT ou Freelance": "IT ou Freelance",
    "IT's Who is Who": "IT's Who is Who",
    "Identification data: surnames, forenames, date and place of birth, copy of your identity document, work permit;":
      "Donn\xE9es  d\u2019identification :  noms,  pr\xE9noms,  date  et  lieu  de  naissance,  copie  de  votre pi\xE8ce d\u2019identit\xE9, autorisation de travail;",
    "Identifiez des nouveaux talents pour votre entreprise.":
      "Identifiez de nouveaux talents pour votre entreprise.",
    "If  personal  data  of  a  mandatory  nature  are  not  communicated,  Company  will  not  be  able  to reply to your requests, if any.":
      "En l\u2019absence de communication des donn\xE9es personnelles \xE0 caract\xE8re obligatoire, la Soci\xE9t\xE9 ne pourra pas r\xE9pondre \xE0 vos demandes, le cas \xE9ch\xE9ant.",
    "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;":
      "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;",
    "If personal data of a mandatory nature are not communicated, Company will not be able to reply to your requests, if any.":
      "En l\u2019absence de communication des donn\xE9es personnelles \xE0 caract\xE8re obligatoire, la Soci\xE9t\xE9 ne pourra pas r\xE9pondre \xE0 vos demandes, le cas \xE9ch\xE9ant.",
    "If the Company amends this Personal Data Protection Policy, it will publish the new version on its website and update the \u201CLatest update\u201D date at the top of this Personal Data Protection Policy.":
      "Si  la  Soci\xE9t\xE9  apporte  une  modification  \xE0  la  pr\xE9sente  Politique  de  protection  des  donn\xE9es  \xE0 caract\xE8re  personnel,  elle  publiera  la  nouvelle  version  sur  son  Site  et  actualisera  la  date  de \xAB Derni\xE8re mise \xE0 jour \xBB figurant en haut de la pr\xE9sente Politique de protection des Donn\xE9es \xE0 caract\xE8re personnel.",
    "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.":
      "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.",
    "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.":
      "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.",
    "If you fail to provide personal data":
      "If you fail to provide personal data",
    "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.":
      "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.",
    "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.":
      "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.",
    "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.":
      "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.",
    "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and":
      "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and",
    "Il est possible de supprimer uniquement votre compte ou supprimer toutes vos informations de notre base de donn\xE9es. Cependant, cela nous emp\xEAchera de vous proposer des talents IT \xE0 l\u2019avenir.":
      "Il est possible de supprimer uniquement votre compte ou supprimer toutes vos informations de notre base de donn\xE9es. Cependant, cela nous emp\xEAchera de vous proposer des talents IT \xE0 l\u2019avenir.",
    "Il est possible de supprimer uniquement votre compte ou supprimer toutes vos informations de notre base de donn\xE9es. Cette derni\xE8re action nous emp\xEAchera de vous contacter pour de nouvelles opportunit\xE9s.":
      "Il est possible de supprimer uniquement votre compte ou supprimer toutes vos informations de notre base de donn\xE9es. Cette derni\xE8re action nous emp\xEAchera de vous contacter pour de nouvelles opportunit\xE9s.",
    "Il n'y a pas de r\xE9sultat pour votre recherche. Ajoutez les filtres ou ajoutez des comp\xE9tences.":
      "Il n'y a pas de r\xE9sultat pour votre recherche. Ajoutez les filtres ou ajoutez des comp\xE9tences.",
    "Il n'ya pas de num\xE9ro de bon de commande indiqu\xE9":
      "Il n'ya pas de num\xE9ro de bon de commande indiqu\xE9",
    "Il n\u2019y a pas de projets pour les filtrer":
      "Il n\u2019y a pas de projets pour les filtrer",
    "Il n\u2019y a pas de sourcer avec cet Id":
      "Il n\u2019y a pas de Talent Community Specialist avec cet Id",
    "Il n\u2019y a pas de talents qualifi\xE9s par nos \xE9quipes pour le moment.":
      "Il n\u2019y a pas de talents qualifi\xE9s par nos \xE9quipes pour le moment.",
    "Il n\u2019y a pas de temps enregistr\xE9 ce jour.":
      "Il n\u2019y a pas de temps enregistr\xE9 ce jour.",
    "Il n\u2019y a pas des missions disponible a ce moment pour ce sourceur":
      "Il n\u2019y a pas de mission ouverte pour ce Talent Community Specialist",
    "Il n\u2019y a pas des missions similaires":
      "Il n\u2019y a pas de missions similaires",
    "Il n\u2019y a pas des posts pour le moment dans cette section":
      "Il n\u2019y a pas de posts pour le moment dans cette section",
    "Il n\u2019y a pas des ressources disponibles pour le moment":
      "Il n\u2019y a pas de ressource disponible pour le moment",
    "Il n\u2019y a pas encore de ressources.":
      "Il n\u2019y a pas encore de ressources.",
    "Il n\u2019y a pas encore de retour": "Il n\u2019y a pas encore de retour",
    "Il s'agit du dernier num\xE9ro de bon de commande li\xE9 \xE0 votre contrat connu dans notre syst\xE8me. S'il n'y en a pas vous pouvez ignorer ce champ.":
      "Il s'agit du dernier num\xE9ro de bon de commande li\xE9 \xE0 votre contrat connu dans notre syst\xE8me. S'il n'y en a pas vous pouvez ignorer ce champ.",
    "Il vous reste {remainingApplications}/3 candidatures possibles.": function(
      a
    ) {
      return [
        "Il vous reste ",
        a("remainingApplications"),
        "/3 candidatures possibles."
      ];
    },
    "Il vous reste {remainingApplications}/3 candidatures possibles.Lorsqu'un emploi pour lequel vous avez postul\xE9 est ferm\xE9, vous recevez une candidature suppl\xE9mentaire": function(
      a
    ) {
      return [
        "Il vous reste ",
        a("remainingApplications"),
        "/3 candidatures possibles.Lorsqu'un emploi pour lequel vous avez postul\xE9 est ferm\xE9, vous recevez une candidature suppl\xE9mentaire"
      ];
    },
    "Il y'a pas encore des articles pour cette cat\xE9gorie.":
      "Il y'a pas encore d'article pour cette cat\xE9gorie.",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances":
      "Ils ont acc\xE9l\xE9r\xE9 leurs projets IT et Finance gr\xE2ce \xE0 Mindquest et sa communaut\xE9",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances.":
      "Ils acc\xE9l\xE8rent sur leurs projets IT et Finance gr\xE2ce \xE0 des talents Mindquest",
    "Ils ont r\xE9ussi leurs projets finance avec Mindquest":
      "Ils ont r\xE9ussi leurs projets finance avec Mindquest",
    Importer: "Importer",
    "Importer mon CV": "Importer mon CV",
    "Importer mon fichier": "Importer mon fichier",
    "Importer votre profil LinkedIn": "Importer votre profil LinkedIn",
    "Importer votre profil LinkedIn<0><1/><2>Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci</2><3/></0>":
      "Importer votre profil LinkedIn<0><1/><2>Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci</2><3/></0>",
    "Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci":
      "Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci",
    "Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)":
      "Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)",
    "Impossible de r\xE9cup\xE9rer vos informations":
      "Impossible de r\xE9cup\xE9rer vos informations",
    "In  addition,  you  have  the  possibility  at  any  time  to  delete or  disable  most  cookies  in  your browser.":
      "Par ailleurs, vous avez la possibilit\xE9 \xE0 tout moment de supprimer ou d\xE9sactiver la plupart des cookies au niveau de votre navigateur.",
    "In  order  to  comply  with  the  legal  and  regulatory  obligations  relating  to  conservation and archiving.":
      "Afin de respecter les obligations l\xE9gales et r\xE9glementaires en mati\xE8re de conservation et d\u2019archivage.",
    "In accordance with Article 5 of the General Data Protection Regulation No 2016/679 (GDPR), personal data must be:":
      "Conform\xE9ment \xE0 l\u2019article 5 du R\xE8glement g\xE9n\xE9ral sur la protection des donn\xE9es n\xB0 2016/679 (RGPD), les Donn\xE9es \xE0 caract\xE8re personnel doivent \xEAtre:",
    "In accordance with Article 6 of the GDPR, personal data shall be lawfully processed if such processing meets at least one of the following characteristics:":
      "Conform\xE9ment \xE0 l\u2019article 6 du RGPD, les Donn\xE9es \xE0 caract\xE8re personnel sont trait\xE9es licitement si ce traitement r\xE9pond \xE0 au moins une des caract\xE9ristiques ci-dessous:",
    "In accordance with the rules on the protection of personal data, and in particular the General Data Protection   Regulation   No   2016/679   (GDPR),   everyone   has   the   right   of   access, rectification, restriction, deletion, where applicable portability of data concerning him or her, and the right to object, possibly subject to legitimate and compelling reasons, to the processing of information and personal data relating to him or her, to be exercised at any time by means of the data amendment request form: <0>https://mindquest.io/contact </0>":
      "Conform\xE9ment  \xE0  la  r\xE8glementation  relative  \xE0  la  protection  des  donn\xE9es  personnelles  et notamment  le  R\xE8glement  g\xE9n\xE9ral  sur  la  protection  des  donn\xE9es  n\xB0  2016/679  (RGPD),  toute personne  dispose  d\u2019un  droit  d\u2019acc\xE8s,  de  rectification,  de  limitation,  de  suppression,  le  cas \xE9ch\xE9ant   de   portabilit\xE9   des   donn\xE9es   la   concernant,   ainsi   que   d\u2019un   droit   d\u2019opposition, \xE9ventuellement sous r\xE9serve de motifs l\xE9gitimes et imp\xE9rieux, au traitement des informations et donn\xE9es \xE0 caract\xE8re personnel le concernant, \xE0 exercer \xE0 tout moment par le formulaire de demande de modification des donn\xE9es : <0>https://fr.mindquest.io/contact</0>",
    "In accordance with the rules on the protection of personal data, and in particular the General Data Protection Regulation No 2016/679 (GDPR), everyone has the right of access, rectification, restriction, deletion, where applicable portability of data concerning him or her, and the right to object, possibly subject to legitimate and compelling reasons, to the processing of information and personal data relating to him or her, to be exercised at any time by means of the data amendment request form: <0>https://mindquest.io/contact </0>":
      "Conform\xE9ment  \xE0  la  r\xE8glementation  relative  \xE0  la  protection  des  donn\xE9es  personnelles  et notamment  le  R\xE8glement  g\xE9n\xE9ral  sur  la  protection  des  donn\xE9es  n\xB0  2016/679  (RGPD),  toute personne  dispose  d\u2019un  droit  d\u2019acc\xE8s,  de  rectification,  de  limitation,  de  suppression,  le  cas \xE9ch\xE9ant   de   portabilit\xE9   des   donn\xE9es   la   concernant,   ainsi   que   d\u2019un   droit   d\u2019opposition, \xE9ventuellement sous r\xE9serve de motifs l\xE9gitimes et imp\xE9rieux, au traitement des informations et donn\xE9es \xE0 caract\xE8re personnel le concernant, \xE0 exercer \xE0 tout moment par le formulaire de demande de modification des donn\xE9es: <0>https://mindquest.io/contact </0>",
    "In addition, you have the possibility at any time to delete or disable most cookies in your browser.":
      "Par ailleurs, vous avez la possibilit\xE9 \xE0 tout moment de supprimer ou d\xE9sactiver la plupart des cookies au niveau de votre navigateur.",
    "In any event, such data shall be collected and processed on the basis of a legal obligation, a legitimate interest of the Company and/or the consent of the individual concerned.":
      "En tout \xE9tat de cause, ces donn\xE9es sont collect\xE9es et trait\xE9es sur le fondement d\u2019une obligation l\xE9gale, d\u2019un int\xE9r\xEAt l\xE9gitime de la Soci\xE9t\xE9 et/ou du consentement de la Personne concern\xE9e.",
    "In general, the Company keeps the personal data of the persons concerned in the territory of the European Union.":
      "En g\xE9n\xE9ral, la Soci\xE9t\xE9 conserve les Donn\xE9es \xE0 caract\xE8re personnel des Personnes concern\xE9es sur le territoire de l\u2019Union europ\xE9enne.",
    "In order to comply with the legal and regulatory obligations relating to conservation and archiving.":
      "Afin de respecter les obligations l\xE9gales et r\xE9glementaires en mati\xE8re de conservation et d\u2019archivage.",
    "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.":
      "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.",
    "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.":
      "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.",
    "In some circumstances you can ask us to delete your data: see Request erasure below for further information.":
      "In some circumstances you can ask us to delete your data: see Request erasure below for further information.",
    "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;":
      "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;",
    Indifferent: "Indifferent",
    Indifférent: "Indiff\xE9rent",
    "Indiquer le ou les projets sur lesquels vous avez travaill\xE9":
      "Indiquer le ou les projets sur lesquels vous avez travaill\xE9",
    "Indiquer les t\xE2ches que vous avez effectu\xE9es":
      "Indiquer les t\xE2ches que vous avez effectu\xE9es",
    "Indiquez des informations de contexte comme la phase du projet, la composition de l\u2019\xE9quipe ou l\u2019organisation interne...":
      "Indiquez des informations de contexte comme la phase du projet, la composition de l\u2019\xE9quipe ou l\u2019organisation interne...",
    Individuel: "Individuel",
    "Ind\xE9pendants : tout savoir sur le pr\xE9l\xE8vement forfaitaire lib\xE9ratoire":
      "Ind\xE9pendants : tout savoir sur le pr\xE9l\xE8vement forfaitaire lib\xE9ratoire",
    "Information Security": "S\xE9curit\xE9 des Syst\xE8mes d'Information",
    "Informations personnelles": "Informations personnelles",
    "Informations sur le client": "Informations sur le client",
    "Informations sur le signataire": "Informations sur le signataire",
    Infra: "Infra",
    "Infrastructure & Cloud": "Infrastructure & Cloud",
    "Infrastructure & Cloud computing": "Infrastructure & Cloud",
    "Infrastructure IT": "Infrastructure IT",
    "Infrastructure et Cloud": "Infrastructure et Cloud",
    "Inscription - Je suis un Talent": "Inscription - Je suis un Talent",
    "Inscription - Je suis une Entreprise":
      "Inscription - Je suis une Entreprise",
    "Inscription compl\xE8te": "Inscription compl\xE8te",
    "Inscription en ligne": "Inscription en ligne",
    "Inscription express": "Inscription express",
    "Inscrivez-vous et recevez tous les articles":
      "Inscrivez-vous et recevez tous nos nouveaux articles",
    "Insights pour professionels IT et entreprises":
      "Insights pour professionels IT, Finance et entreprises",
    "Ins\xE9rez votre mot de passe": "Ins\xE9rez votre mot de passe",
    "Intelligence Artificielle": "Intelligence Artificielle",
    "Intelligence artificielle": "Intelligence artificielle",
    "Interesting Reads": "Interesting Reads",
    "Internal cookies: are cookies created and used by the website;":
      "Cookies internes : sont des cookies cr\xE9es et utilis\xE9s par le site internet;",
    Intitulé: "Intitul\xE9 de poste",
    Introduction: "Introduction",
    Intéressé: "Int\xE9ress\xE9",
    "Int\xE9ress\xE9(e) par des offres en, <0>Direction Financi\xE8re externalis\xE9, Analyse financi\xE8re, </0><1/><2>Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA,</2><3/>nous vous pr\xE9sentons des projets s\xE9lectionn\xE9s pour votre expertise pr\xE8s de chez vous.":
      "Int\xE9ress\xE9(e) par des offres en, <0>Direction Financi\xE8re externalis\xE9e, Analyse financi\xE8re, </0><1/><2>Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA,</2><3/>nous vous pr\xE9sentons des projets s\xE9lectionn\xE9s pour votre expertise pr\xE8s de chez vous.",
    "Int\xE9ress\xE9(e) par des offres en<0>Direction financi\xE8re externalis\xE9, Analyse financi\xE8re, </0><1/><2>Management de transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA,</2><3/>nous vous pr\xE9sentons des projets s\xE9lectionn\xE9s pour votre expertise pr\xE8s de chez vous.":
      "Int\xE9ress\xE9(e) par des offres en<0>Direction financi\xE8re externalis\xE9, Analyse financi\xE8re, </0><1/><2>Management de transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA,</2><3/>nous vous pr\xE9sentons des projets s\xE9lectionn\xE9s pour votre expertise pr\xE8s de chez vous.",
    "Invalid or expired token": "Invalid or expired token",
    "IoT & AI/ML & Blockchain": "Iot / AI / Blockchain",
    "IoT / AI / Blockchain": "IoT / AI / Blockchain",
    "It should be noted that the controller is, in accordance with the applicable legislation, the entity which defines and limits the data to be collected and the purposes of processing.":
      "A  toutes  fins  utiles,  il  est  rappel\xE9  que  le  Responsable  de  traitement  est,  conform\xE9ment  \xE0  la l\xE9gislation applicable, l\u2019entit\xE9 qui d\xE9finit et limite les donn\xE9es \xE0 collecter ainsi que les finalit\xE9s de traitement.",
    Italian: "Italien",
    Italien: "Italien",
    "J'accepte": "J'accepte",
    "J'ai compris": "J'ai compris",
    Japanese: "Japonais",
    Japonais: "Japonais",
    "Je cherche": "Je cherche",
    "Je cherche un consultant IT": "Je recherche un talent",
    "Je cherche une mission": "Je cherche une mission",
    "Je confirme n'avoir effectu\xE9 aucun jour pour ce mois":
      "Je confirme n'avoir effectu\xE9 aucun jour pour ce mois",
    "Je m\u2019abonne": "S'abonner",
    "Je poste un besoin": "Je poste un besoin",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par":
      "Je pr\xE9f\xE8re \xEAtre contact\xE9 par",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par :":
      "Je pr\xE9f\xE8re \xEAtre contact\xE9 par :",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par:":
      "Je pr\xE9f\xE8re \xEAtre contact\xE9 par:",
    "Je recherche une opportunit\xE9": "Je recherche une opportunit\xE9",
    "Je recherche une opportunit\xE9 dans l'IT":
      "Je recherche une opportunit\xE9 dans l'IT ou la Finance",
    "Je recrute un talent IT": "Je recrute un talent",
    "Je recrute un talent dans l'IT":
      "Je recrute un talent dans l'IT ou la Finance",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Mindquest Talent":
      "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Mindquest Talent",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages de Mindquest (webinaires, \xE9v\xE9nements, nouvelles fonctionnalit\xE9s, offres exclusives communaut\xE9s, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages de Mindquest (webinaires, \xE9v\xE9nements, nouvelles fonctionnalit\xE9s, offres exclusives communaut\xE9s, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations de Mindquest (services, fonctionnalit\xE9s, webinaires, \xE9v\xE9nements, etc.)":
      "Je souhaite recevoir les derni\xE8res informations de Mindquest (services, fonctionnalit\xE9s, webinaires, \xE9v\xE9nements, etc.)",
    "Je souhaite recevoir les derni\xE8res informations, offres ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations, offres ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je suis": "Je suis",
    "Je suis ESN": "Mon entreprise est une ESN / SSII",
    "Je suis tr\xE8s contente de l'agilit\xE9 de vos services et du sens des missions/m\xE9tiers pour coller aux besoins clients et aux capacit\xE9s prestataires.":
      "Je suis tr\xE8s contente de l'agilit\xE9 de vos services et du sens des missions/m\xE9tiers pour coller aux besoins clients et aux capacit\xE9s prestataires.",
    "Je suis tr\xE8s contente de l'agilit\xE9 de vos services et du sens des missions/m\xE9tiers pour coller aux besoins clients et aux capacit\xE9s prestataires. Un beau travail d'\xE9quipe. Une \xE9coute fine\"":
      "Je suis tr\xE8s contente de l'agilit\xE9 de vos services et du sens des missions/m\xE9tiers pour coller aux besoins clients et aux capacit\xE9s prestataires. Un beau travail d'\xE9quipe. Une \xE9coute fine\"",
    "Je suis tr\xE8s contente de l'agilit\xE9 de vos services et du sens des missions/m\xE9tiers pour coller aux besoins clients et aux capacit\xE9s prestataires. Un beau travail d'\xE9quipe. Une \xE9coute fine.":
      "Je suis tr\xE8s contente de l'agilit\xE9 de vos services et du sens des missions/m\xE9tiers pour coller aux besoins clients et aux capacit\xE9s prestataires. Un beau travail d'\xE9quipe. Une \xE9coute fine.",
    "Je suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able":
      "Je suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able",
    "Je suis tr\xE8s satisfaite de Mindquest. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able":
      "Je suis tr\xE8s satisfaite de Mindquest. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able",
    "Je suis un Talent": "Je suis un Talent",
    "Je suis un d\xE9cideur IT": "Je suis un recruteur",
    "Je suis un freelance": "Candidats",
    "Je suis un talent": "Je suis un talent",
    "Je suis une Entreprise": "Je suis une Entreprise",
    "Je suis une entreprise": "Entreprises",
    "Job Descriptions": "Job Descriptions",
    "Job Search advice": "Job Search advice",
    "Job application advice": "Job application advice",
    "Job application advice -  Mindquest Connect":
      "Job application advice -  Mindquest Connect",
    "Job search advice -  Mindquest Connect":
      "Job search advice -  Mindquest Connect",
    "John Doe": "John Doe",
    "Jour f\xE9ri\xE9 s\xE9lectionn\xE9": "Jour f\xE9ri\xE9 s\xE9lectionn\xE9",
    "Jour plein": "Jour plein",
    "Jours f\xE9ri\xE9s et weekends sont automatiquement d\xE9s\xE9lectionn\xE9s des plages. Si vous avez travaill\xE9 un jour f\xE9ri\xE9 ou un weekend, vous devez l\u2019ajouter individuellement.":
      "Jours f\xE9ri\xE9s et weekends sont automatiquement d\xE9s\xE9lectionn\xE9s des plages. Si vous avez travaill\xE9 un jour f\xE9ri\xE9 ou un weekend, vous devez l\u2019ajouter individuellement.",
    "Jours supprim\xE9s": "Jours supprim\xE9s",
    Junior: "Junior",
    "Junior (0 - 3)": "Junior (0 - 3)",
    "Junior (0-3)": "Junior (0-3)",
    Juridique: "Juridique",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s importants peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Jusqu\u2019\xE0 10 mots-cl\xE9s importants peuvent \xEAtre s\xE9lectionn\xE9s.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s indispensables peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Jusqu\u2019\xE0 10 mots-cl\xE9s indispensables peuvent \xEAtre s\xE9lectionn\xE9s.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s secondaires peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Jusqu\u2019\xE0 10 mots-cl\xE9s secondaires peuvent \xEAtre s\xE9lectionn\xE9s.",
    "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Club Freelance":
      "J\u2019accepte <0>la politique de confidentialit\xE9 </0> Mindquest",
    "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Mindquest ainsi que <1>les conditions g\xE9n\xE9rales d\u2019utilisation </1> .":
      "J\u2019accepte <0>la politique de confidentialit\xE9 </0> Mindquest ainsi que <1>les conditions g\xE9n\xE9rales d\u2019utilisation </1> .",
    "J\u2019accepte la": "J\u2019accepte la",
    "J\u2019occupe actuellement ce poste":
      "J\u2019occupe actuellement ce poste",
    "Kept for no longer than is necessary for the purposes for which the data are processed;":
      "Conserv\xE9es pendant une dur\xE9e n\u2019exc\xE9dant pas celle n\xE9cessaire au regard des finalit\xE9s pour lesquelles elles sont trait\xE9es;",
    Kiosk: "Kiosk",
    "L'action que vous tentez d'appliquer exige que vous rentriez votre mot de passe.":
      "L'action que vous tentez d'appliquer exige que vous rentriez votre mot de passe.",
    "La  Plateforme  est  accessible  gratuitement  en  tout  lieu  et  en  tout  temps  dans  les  conditions d\xE9crites ci-avant \xE0 tout Utilisateur ayant un acc\xE8s \xE0 un poste informatique permettant l\u2019acc\xE8s \xE0 la Plateforme.":
      "La  Plateforme  est  accessible  gratuitement  en  tout  lieu  et  en  tout  temps  dans  les  conditions d\xE9crites ci-avant \xE0 tout Utilisateur ayant un acc\xE8s \xE0 un poste informatique permettant l\u2019acc\xE8s \xE0 la Plateforme.",
    "La  Plateforme  offre  aux  Talents  la  possibilit\xE9  de  r\xE9pondre  aux  offres  d\u2019emploi  et/ou  aux missions propos\xE9es par les Clients.":
      "La  Plateforme  offre  aux  Talents  la  possibilit\xE9  de  r\xE9pondre  aux  offres  d\u2019emploi  et/ou  aux missions propos\xE9es par les Clients.",
    "La  Plateforme  peut  occasionnellement  contenir  des  liens  vers  les  sites  Internet  de  nos partenaires ou de soci\xE9t\xE9s tierces.":
      "La  Plateforme  peut  occasionnellement  contenir  des  liens  vers  les  sites  Internet  de  nos partenaires ou de soci\xE9t\xE9s tierces.",
    "La  Soci\xE9t\xE9  garantit  la  mise  \xE0  disposition  de  la  Plateforme  dans  des  conditions  d\u2019utilisation normales.":
      "La  Soci\xE9t\xE9  garantit  la  mise  \xE0  disposition  de  la  Plateforme  dans  des  conditions  d\u2019utilisation normales.",
    "La  Soci\xE9t\xE9  n\u2019a  pas  souscrit  de  police  d\u2019assurance  concernant  les  activit\xE9s  des Utilisateurs utilisant la Plateforme et ce, dans le cadre de leurs relations avec les Clients.":
      "La  Soci\xE9t\xE9  n\u2019a  pas  souscrit  de  police  d\u2019assurance  concernant  les  activit\xE9s  des Utilisateurs utilisant la Plateforme et ce, dans le cadre de leurs relations avec les Clients.",
    "La  Soci\xE9t\xE9  se  r\xE9serve  la  possibilit\xE9  de  modifier  tout  ou  partie  des  pr\xE9sentes. La  Soci\xE9t\xE9 informera ainsi les Utilisateurs par la mise en ligne d\u2019une version mise \xE0 jour des pr\xE9sentes.":
      "La  Soci\xE9t\xE9  se  r\xE9serve  la  possibilit\xE9  de  modifier  tout  ou  partie  des  pr\xE9sentes. La  Soci\xE9t\xE9 informera ainsi les Utilisateurs par la mise en ligne d\u2019une version mise \xE0 jour des pr\xE9sentes.",
    "La  Soci\xE9t\xE9  se  r\xE9serve  le  droit  de  permettre  aux  Clients  d\u2019effectuer  des  commentaires  et  une \xE9valuation concernant  les  Consultants.  Cette  \xE9valuation  est  uniquement  r\xE9alis\xE9e  \xE0  des  fins internes et priv\xE9es \xE0 la Soci\xE9t\xE9.":
      "La  Soci\xE9t\xE9  se  r\xE9serve  le  droit  de  permettre  aux  Clients  d\u2019effectuer  des  commentaires  et  une \xE9valuation concernant  les  Consultants.  Cette  \xE9valuation  est  uniquement  r\xE9alis\xE9e  \xE0  des  fins internes et priv\xE9es \xE0 la Soci\xE9t\xE9.",
    "La Plateforme est accessible gratuitement en tout lieu et en tout temps dans les conditions d\xE9crites ci-avant \xE0 tout Utilisateur ayant un acc\xE8s \xE0 un poste informatique permettant l\u2019acc\xE8s \xE0 la Plateforme.":
      "La Plateforme est accessible gratuitement en tout lieu et en tout temps dans les conditions d\xE9crites ci-avant \xE0 tout Utilisateur ayant un acc\xE8s \xE0 un poste informatique permettant l\u2019acc\xE8s \xE0 la Plateforme.",
    "La Plateforme est une solution informatique accessible en ligne depuis tout poste informatique ou t\xE9l\xE9phone pr\xE9alablement configur\xE9 et connect\xE9 au r\xE9seau Internet":
      "La Plateforme est une solution informatique accessible en ligne depuis tout poste informatique ou t\xE9l\xE9phone pr\xE9alablement configur\xE9 et connect\xE9 au r\xE9seau Internet",
    "La Plateforme n\u2019est accessible qu\u2019aux Utilisateurs s\u2019\xE9tant pr\xE9alablement inscrits et ayant d\xE9fini un identifiant, un mot de passe personnel et ayant valid\xE9 leur inscription.":
      "La Plateforme n\u2019est accessible qu\u2019aux Utilisateurs s\u2019\xE9tant pr\xE9alablement inscrits et ayant d\xE9fini un identifiant, un mot de passe personnel et ayant valid\xE9 leur inscription.",
    "La Plateforme offre aux Talents la possibilit\xE9 de r\xE9pondre aux offres d\u2019emploi et/ou aux missions propos\xE9es par les Clients.":
      "La Plateforme offre aux Talents la possibilit\xE9 de r\xE9pondre aux offres d\u2019emploi et/ou aux missions propos\xE9es par les Clients.",
    "La Plateforme offre \xE9galement aux Clients la possibilit\xE9 de publier des offres, de proposer des missions et de consulter les profils des Talents (profils ayant \xE9t\xE9 pseudonymis\xE9es au pr\xE9alable par la Soci\xE9t\xE9). Si un Client est int\xE9ress\xE9 par le profil d\u2019un Talent, il en informe la Soci\xE9t\xE9 qui organisera la mise en la relation, notamment par le biais d\u2019un entretien.":
      "La Plateforme offre \xE9galement aux Clients la possibilit\xE9 de publier des offres, de proposer des missions et de consulter les profils des Talents (profils ayant \xE9t\xE9 pseudonymis\xE9es au pr\xE9alable par la Soci\xE9t\xE9). Si un Client est int\xE9ress\xE9 par le profil d\u2019un Talent, il en informe la Soci\xE9t\xE9 qui organisera la mise en la relation, notamment par le biais d\u2019un entretien.",
    "La Plateforme peut occasionnellement contenir des liens vers les sites Internet de nos partenaires ou de soci\xE9t\xE9s tierces.":
      "La Plateforme peut occasionnellement contenir des liens vers les sites Internet de nos partenaires ou de soci\xE9t\xE9s tierces.",
    "La Soci\xE9t\xE9 garantit la mise \xE0 disposition de la Plateforme dans des conditions d\u2019utilisation normales.":
      "La Soci\xE9t\xE9 garantit la mise \xE0 disposition de la Plateforme dans des conditions d\u2019utilisation normales.",
    "La Soci\xE9t\xE9 invite tout Utilisateur qui souhaite proposer ses services par le biais de la Plateforme \xE0  recourir  aux  services  d\u2019assurance  d\u2019une  compagnie  d\u2019assurance et  y  souscrire  un  contrat permettre de garantir sa responsabilit\xE9 civile professionnelle.":
      "La Soci\xE9t\xE9 invite tout Utilisateur qui souhaite proposer ses services par le biais de la Plateforme \xE0  recourir  aux  services  d\u2019assurance  d\u2019une  compagnie  d\u2019assurance et  y  souscrire  un  contrat permettre de garantir sa responsabilit\xE9 civile professionnelle.",
    "La Soci\xE9t\xE9 invite tout Utilisateur qui souhaite proposer ses services par le biais de la Plateforme \xE0 recourir aux services d\u2019assurance d\u2019une compagnie d\u2019assurance et y souscrire un contrat permettre de garantir sa responsabilit\xE9 civile professionnelle.":
      "La Soci\xE9t\xE9 invite tout Utilisateur qui souhaite proposer ses services par le biais de la Plateforme \xE0 recourir aux services d\u2019assurance d\u2019une compagnie d\u2019assurance et y souscrire un contrat permettre de garantir sa responsabilit\xE9 civile professionnelle.",
    "La Soci\xE9t\xE9 met \xE0 disposition une plateforme de mise en relation entre candidats \xE0 un emploi ou une mission et clients/donneurs d\u2019ordres (la \xAB <0>Plateforme</0> \xBB).":
      "La Soci\xE9t\xE9 met \xE0 disposition une plateforme de mise en relation entre candidats \xE0 un emploi ou une mission et clients/donneurs d\u2019ordres (la \xAB <0>Plateforme</0> \xBB).",
    "La Soci\xE9t\xE9 n\u2019a pas souscrit de police d\u2019assurance concernant les activit\xE9s des Utilisateurs utilisant la Plateforme et ce, dans le cadre de leurs relations avec les Clients.":
      "La Soci\xE9t\xE9 n\u2019a pas souscrit de police d\u2019assurance concernant les activit\xE9s des Utilisateurs utilisant la Plateforme et ce, dans le cadre de leurs relations avec les Clients.",
    "La Soci\xE9t\xE9 se r\xE9serve la possibilit\xE9 de modifier tout ou partie des pr\xE9sentes. La Soci\xE9t\xE9 informera ainsi les Utilisateurs par la mise en ligne d\u2019une version mise \xE0 jour des pr\xE9sentes.":
      "La Soci\xE9t\xE9 se r\xE9serve la possibilit\xE9 de modifier tout ou partie des pr\xE9sentes. La Soci\xE9t\xE9 informera ainsi les Utilisateurs par la mise en ligne d\u2019une version mise \xE0 jour des pr\xE9sentes.",
    "La Soci\xE9t\xE9 se r\xE9serve le droit de permettre aux Clients d\u2019effectuer des commentaires et une \xE9valuation concernant les Consultants. Cette \xE9valuation est uniquement r\xE9alis\xE9e \xE0 des fins internes et priv\xE9es \xE0 la Soci\xE9t\xE9.":
      "La Soci\xE9t\xE9 se r\xE9serve le droit de permettre aux Clients d\u2019effectuer des commentaires et une \xE9valuation concernant les Consultants. Cette \xE9valuation est uniquement r\xE9alis\xE9e \xE0 des fins internes et priv\xE9es \xE0 la Soci\xE9t\xE9.",
    "La Soci\xE9t\xE9 se r\xE9serve \xE9galement le droit de suspendre tout acc\xE8s \xE0 la Plateforme.":
      "La Soci\xE9t\xE9 se r\xE9serve \xE9galement le droit de suspendre tout acc\xE8s \xE0 la Plateforme.",
    "La date de d\xE9but doit \xEAtre toujours ant\xE9rieur \xE0 la date de fin":
      "La date de d\xE9but doit \xEAtre toujours ant\xE9rieur \xE0 la date de fin",
    "La date de fin doit \xEAtre toujours ult\xE9rieur \xE0 la date de d\xE9but":
      "La date de fin doit \xEAtre toujours ult\xE9rieur \xE0 la date de d\xE9but",
    "La garantie de paiement \xE0": "Un coaching tout au long des entretiens",
    "La garantie de paiement \xE0 30 jours":
      "Un coaching tout au long des entretiens",
    "La liste des mots non pris en compte":
      "La liste des mots non pris en compte",
    "La localisation": "La localisation",
    "La mission se situe \xE0 {0} de cette adresse en {1} selon Google Maps": function(
      a
    ) {
      return [
        "La mission se situe \xE0 ",
        a("0"),
        " de cette adresse en ",
        a("1"),
        " selon Google Maps"
      ];
    },
    "La strat\xE9gie des talents": "La strat\xE9gie des talents",
    "La taille de votre fichier d\xE9passe":
      "La taille de votre fichier d\xE9passe",
    "La taille du fichier est trop petite, veuillez s\xE9lectionner un fichier de taille sup\xE9rieur \xE0":
      "La taille du fichier est trop petite, veuillez s\xE9lectionner un fichier de taille sup\xE9rieur \xE0",
    "Laissez nous un message, notre \xE9quipe vous recontactera dans les plus brefs d\xE9lais.":
      "Laissez nous un message, notre \xE9quipe vous recontactera dans les plus brefs d\xE9lais.",
    "Lancement de Mindquest": "Lancement de Mindquest",
    "Lancer le matching": "Lancer le matching",
    "Languages;": "Languages;",
    "Langue du compte (Par d\xE9fault)": "Langue du compte (Par d\xE9fault)",
    "Langues Parl\xE9es": "Langues Parl\xE9es",
    "Langues parl\xE9es": "Langues parl\xE9es",
    "Le Timesheet a \xE9t\xE9 enregistr\xE9 avec succ\xE8s en tant que brouillon.":
      "Le Timesheet a \xE9t\xE9 enregistr\xE9 avec succ\xE8s en tant que brouillon.",
    "Le Timesheet du mois de {m}/{y} pour le contrat {pID} attend votre validation": function(
      a
    ) {
      return [
        "Le Timesheet du mois de ",
        a("m"),
        "/",
        a("y"),
        " pour le contrat ",
        a("pID"),
        " attend votre validation"
      ];
    },
    "Le Timesheet {month}/{year} de contrat numero: {placementID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "Le Timesheet ",
        a("month"),
        "/",
        a("year"),
        " de contrat numero: ",
        a("placementID"),
        " est ouvert au dashboard de votre consultant"
      ];
    },
    "Le Timesheet {m}/{y} de contrat numero: {pID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "Le Timesheet ",
        a("m"),
        "/",
        a("y"),
        " de contrat numero: ",
        a("pID"),
        " est ouvert au dashboard de votre consultant"
      ];
    },
    "Le blog": "Ressources",
    "Le budget / package": "Le budget / package salarial",
    "Le client": "Le client",
    "Le client Mindquest a acc\xE8s \xE0 vos projets":
      "Le client Mindquest a acc\xE8s \xE0 vos projets",
    "Le client Mindquest a acc\xE8s \xE0 vos th\xE8mes":
      "Le client Mindquest a acc\xE8s \xE0 vos th\xE8mes",
    "Le client Mindquest y a acc\xE8s": "Le client Mindquest y a acc\xE8s",
    "Le client recevra votre Timesheet ce mois-ci":
      "Le client recevra votre Timesheet ce mois-ci",
    "Le consultant IT recevra vos commentaires.":
      "Le consultant IT recevra vos commentaires.",
    "Le consultant IT sera inform\xE9.": "Le consultant IT sera inform\xE9.",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs.":
      "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs.",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs. Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin. Merci.":
      "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs. Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin. Merci.",
    "Le contexte du besoin": "Le contexte",
    "Le match parfait entre les entreprises et les Talents IT ou Finance":
      "Le match parfait entre les entreprises et les Talents IT ou Finance",
    "Le mois de {0} {year} est termin\xE9, n'oubliez pas d'envoyer votre Timesheet": function(
      a
    ) {
      return [
        "Le mois de ",
        a("0"),
        " ",
        a("year"),
        " est termin\xE9, n'oubliez pas d'envoyer votre Timesheet"
      ];
    },
    "Le nombre d'heures doit \xEAtre entre 0 et 24":
      "Le nombre d'heures doit \xEAtre entre 0 et 24",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes":
      "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes sur la p\xE9riode s\xE9lection\xE9e":
      "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes sur la p\xE9riode s\xE9lection\xE9e",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9":
      "Le nombre de candidats envoy\xE9s par nos \xE9quipes que vous avez recrut\xE9s",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9 sur la p\xE9riode s\xE9lectionn\xE9e":
      "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9s sur la p\xE9riode s\xE9lectionn\xE9e",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cette offre",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 vos offres, sur la p\xE9riode s\xE9lection\xE9e":
      "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 vos offres, sur la p\xE9riode s\xE9lectionn\xE9e",
    "Le nombre de minutes doit \xEAtre compris entre 0 et 59":
      "Le nombre de minutes doit \xEAtre compris entre 0 et 59",
    "Le nombre d\u2019appels fait par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "Le nombre d\u2019appels r\xE9alis\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cette offre",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s":
      "Le nombre d\u2019entretiens organis\xE9s avec vos \xE9quipes et les candidats envoy\xE9s",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s, sur la p\xE9riode s\xE9lectionn\xE9e":
      "Le nombre d\u2019entretiens organis\xE9s avec vos \xE9quipes et les candidats envoy\xE9s, sur la p\xE9riode s\xE9lectionn\xE9e",
    "Le nombre limite de projets cr\xE9es a \xE9t\xE9 atteint.":
      "Le nombre limite de projets cr\xE9es a \xE9t\xE9 atteint.",
    "Le nombre limite de th\xE8mes cr\xE9es a \xE9t\xE9 atteint.":
      "Le nombre limite de th\xE8mes cr\xE9es a \xE9t\xE9 atteint.",
    "Le num\xE9ro de PO ne doit pas exc\xE9der 100 charact\xE8res":
      "Le num\xE9ro de PO ne doit pas exc\xE9der 100 charact\xE8res",
    "Le portage salariale": "Le portage salariale",
    "Le profil recherch\xE9 (comp\xE9tences, s\xE9niorit\xE9, certifications...)":
      "Le profil recherch\xE9 (comp\xE9tences, s\xE9niorit\xE9, certifications...)",
    "Le second signataire est en cours d\u2019ajout, il sera visible une fois la synchronisation effectu\xE9e":
      "Le second signataire est en cours d\u2019ajout, il sera visible une fois la synchronisation effectu\xE9e",
    "Le serveur ne r\xE9pond pas": "Le serveur ne r\xE9pond pas",
    "Le service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.":
      "Le service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.",
    "Le service du Mindquest est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.":
      "Le service du Mindquest est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.",
    "Le talent id\xE9al pour r\xE9soudre <0>les d\xE9fis IT</0>. <1/>Nous connectons <2>les meilleurs experts</2> aux <3>meilleures offres </3>pour un match <4>parfait</4> entre entreprises et talents informatiques.":
      "Le talent id\xE9al pour r\xE9soudre <0>les d\xE9fis IT</0>. <1/>Nous connectons <2>les meilleurs experts</2> aux <3>meilleures offres </3>pour un match <4>parfait</4> entre entreprises et talents informatiques.",
    "Le talent id\xE9al pour r\xE9soudre les d\xE9fis IT.":
      "Le talent id\xE9al pour r\xE9soudre les d\xE9fis IT & Finance.",
    "Le talent y a acc\xE8s": "Le talent y a acc\xE8s",
    "Le type de contrat (CDI /Freelance)":
      "Le type de contrat (CDI /Freelance)",
    "Leaving a hard copy CV at a Club Freelance recruitment event, job fair or office;":
      "Leaving a hard copy CV at a Club Freelance recruitment event, job fair or office;",
    "Les  Utilisateurs  reconnaissent  et  acceptent  que  le  respect  de  cette  obligation constitue  une condition  essentielle  sans  laquelle  la  Soci\xE9t\xE9  ne  saurait  accepter de  mettre  \xE0  disposition  des Utilisateurs la Plateforme.":
      "Les  Utilisateurs  reconnaissent  et  acceptent  que  le  respect  de  cette  obligation constitue  une condition  essentielle  sans  laquelle  la  Soci\xE9t\xE9  ne  saurait  accepter de  mettre  \xE0  disposition  des Utilisateurs la Plateforme.",
    "Les  Utilisateurs  sont  inform\xE9s  que  toute  utilisation  de  la  Plateforme et  de  tout  service  qui pourrait y \xEAtre associ\xE9 contraire aux pr\xE9sentes ainsi qu\u2019\xE0 toute r\xE9glementation applicable pourra se traduire par une r\xE9siliation des pr\xE9sentes et ce, sans pr\xE9avis.":
      "Les  Utilisateurs  sont  inform\xE9s  que  toute  utilisation  de  la  Plateforme et  de  tout  service  qui pourrait y \xEAtre associ\xE9 contraire aux pr\xE9sentes ainsi qu\u2019\xE0 toute r\xE9glementation applicable pourra se traduire par une r\xE9siliation des pr\xE9sentes et ce, sans pr\xE9avis.",
    "Les  pr\xE9sentes  conditions  g\xE9n\xE9rales  d'utilisation  (\xAB <0>CGU</0> \xBB)  ont  pour  objet  d\u2019encadrer les modalit\xE9s de mise \xE0 disposition et d\u2019acc\xE8s de la Plateforme par les candidats \xE0 un emploi ou une  mission  afin  d\u2019entrer  en  relation  avec  toute  personne  morale  ou  physique ayant  cr\xE9\xE9  un compte et pouvant proposer une offre d\u2019emploi ou une mission.":
      "Les  pr\xE9sentes  conditions  g\xE9n\xE9rales  d'utilisation  (\xAB <0>CGU</0> \xBB)  ont  pour  objet  d\u2019encadrer les modalit\xE9s de mise \xE0 disposition et d\u2019acc\xE8s de la Plateforme par les candidats \xE0 un emploi ou une  mission  afin  d\u2019entrer  en  relation  avec  toute  personne  morale  ou  physique ayant  cr\xE9\xE9  un compte et pouvant proposer une offre d\u2019emploi ou une mission.",
    "Les CGU doivent \xEAtre accept\xE9es par tout Utilisateur souhaitant acc\xE9der \xE0 la Plateforme. Elles constituent le contrat entre la Soci\xE9t\xE9 et l\u2019Utilisateur. L\u2019acc\xE8s \xE0 la Plateforme par l\u2019Utilisateur signifie son acceptation des pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation.":
      "Les CGU doivent \xEAtre accept\xE9es par tout Utilisateur souhaitant acc\xE9der \xE0 la Plateforme. Elles constituent le contrat entre la Soci\xE9t\xE9 et l\u2019Utilisateur. L\u2019acc\xE8s \xE0 la Plateforme par l\u2019Utilisateur signifie son acceptation des pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation.",
    "Les Talents id\xE9aux pour r\xE9soudre vos d\xE9fis Finance, sans effort de recherche":
      "Les Talents id\xE9aux pour r\xE9soudre vos d\xE9fis Finance, sans effort de recherche",
    "Les Utilisateurs reconnaissent et acceptent que le respect de cette obligation constitue une condition essentielle sans laquelle la Soci\xE9t\xE9 ne saurait accepter de mettre \xE0 disposition des Utilisateurs la Plateforme.":
      "Les Utilisateurs reconnaissent et acceptent que le respect de cette obligation constitue une condition essentielle sans laquelle la Soci\xE9t\xE9 ne saurait accepter de mettre \xE0 disposition des Utilisateurs la Plateforme.",
    "Les Utilisateurs reconnaissent et acceptent qu\u2019en cas de non-respect des CGU, leur acc\xE8s \xE0 la Plateforme  pourra  \xEAtre  suspendu et/ou  d\xE9finitivement  supprim\xE9  sans  pr\xE9avis  ni  indemnit\xE9  et sans pr\xE9judice de tout dommage-int\xE9r\xEAt que la Soci\xE9t\xE9 serait en droit de demander.":
      "Les Utilisateurs reconnaissent et acceptent qu\u2019en cas de non-respect des CGU, leur acc\xE8s \xE0 la Plateforme  pourra  \xEAtre  suspendu et/ou  d\xE9finitivement  supprim\xE9  sans  pr\xE9avis  ni  indemnit\xE9  et sans pr\xE9judice de tout dommage-int\xE9r\xEAt que la Soci\xE9t\xE9 serait en droit de demander.",
    "Les Utilisateurs reconnaissent et acceptent qu\u2019en cas de non-respect des CGU, leur acc\xE8s \xE0 la Plateforme pourra \xEAtre suspendu et/ou d\xE9finitivement supprim\xE9 sans pr\xE9avis ni indemnit\xE9 et sans pr\xE9judice de tout dommage-int\xE9r\xEAt que la Soci\xE9t\xE9 serait en droit de demander.":
      "Les Utilisateurs reconnaissent et acceptent qu\u2019en cas de non-respect des CGU, leur acc\xE8s \xE0 la Plateforme pourra \xEAtre suspendu et/ou d\xE9finitivement supprim\xE9 sans pr\xE9avis ni indemnit\xE9 et sans pr\xE9judice de tout dommage-int\xE9r\xEAt que la Soci\xE9t\xE9 serait en droit de demander.",
    "Les Utilisateurs reconnaissent et acceptent qu\u2019ils ne pourront en aucun cas \xEAtre indemnis\xE9s ou obtenir r\xE9paration pour quelque motif que ce soit concernant tout propos ou contenu \xE9chang\xE9s sur la Plateforme qu\u2019ils en soient \xE0 l\u2019origine ou les destinataires.":
      "Les Utilisateurs reconnaissent et acceptent qu\u2019ils ne pourront en aucun cas \xEAtre indemnis\xE9s ou obtenir r\xE9paration pour quelque motif que ce soit concernant tout propos ou contenu \xE9chang\xE9s sur la Plateforme qu\u2019ils en soient \xE0 l\u2019origine ou les destinataires.",
    "Les Utilisateurs sont inform\xE9s que toute utilisation de la Plateforme et de tout service qui pourrait y \xEAtre associ\xE9 contraire aux pr\xE9sentes ainsi qu\u2019\xE0 toute r\xE9glementation applicable pourra se traduire par une r\xE9siliation des pr\xE9sentes et ce, sans pr\xE9avis.":
      "Les Utilisateurs sont inform\xE9s que toute utilisation de la Plateforme et de tout service qui pourrait y \xEAtre associ\xE9 contraire aux pr\xE9sentes ainsi qu\u2019\xE0 toute r\xE9glementation applicable pourra se traduire par une r\xE9siliation des pr\xE9sentes et ce, sans pr\xE9avis.",
    "Les Utilisateurs s\u2019engagent \xE0 faire une utilisation de la Plateforme conforme \xE0 son objet et aux pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation.":
      "Les Utilisateurs s\u2019engagent \xE0 faire une utilisation de la Plateforme conforme \xE0 son objet et aux pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation.",
    "Les acteurs IT parlent de nous": "Les acteurs parlent de nous",
    "Les candidatures sont limit\xE9es \xE0 3 postes ouverts simultan\xE9ment":
      "Les candidatures sont limit\xE9es \xE0 3 postes ouverts simultan\xE9ment",
    "Les collaborateurs": "Collaborateurs",
    "Les dates de d\xE9marrages et de fin contrat": "La date de d\xE9marrage",
    "Les derni\xE8res nouvelles dans le secteur de l'IT et de la Tech":
      "Les derni\xE8res nouvelles dans le secteur de l'IT et de la Tech",
    "Les diff\xE9rents litiges entre Utilisateurs devront se r\xE9gler entre eux hors la pr\xE9sence de la Soci\xE9t\xE9 et sans utilisation de la Plateforme.":
      "Les diff\xE9rents litiges entre Utilisateurs devront se r\xE9gler entre eux hors la pr\xE9sence de la Soci\xE9t\xE9 et sans utilisation de la Plateforme.",
    "Les jours f\xE9ri\xE9s et les jours du Weekend doivent etre s\xE9l\xE9ctionn\xE9 un par un et doivent etre de type suppl\xE9mentaire ou astreinte.":
      "Les jours f\xE9ri\xE9s et les jours du Weekend doivent etre s\xE9l\xE9ctionn\xE9 un par un et doivent etre de type suppl\xE9mentaire ou astreinte.",
    "Les jours f\xE9ri\xE9s et les jours du Weekend doivent \xEAtre s\xE9lectionn\xE9s un par un et doivent \xEAtre de type suppl\xE9mentaire ou astreinte.":
      "Les jours f\xE9ri\xE9s et les jours du Weekend doivent \xEAtre s\xE9lectionn\xE9s un par un et doivent \xEAtre de type suppl\xE9mentaire ou astreinte.",
    "Les jours f\xE9ri\xE9s et les jours du Weekend doivent \xEAtre s\xE9lectionn\xE9s un par un.":
      "Les jours f\xE9ri\xE9s et les jours du Weekend doivent \xEAtre s\xE9lectionn\xE9s un par un.",
    "Les meilleurs talents Finance en freelance ou en CDI avec Mindquest, cabinet de recrutement finance. Rencontrez les candidats les plus qualifi\xE9s":
      "Les meilleurs talents Finance en freelance ou en CDI avec Mindquest, cabinet de recrutement finance. Rencontrez les candidats les plus qualifi\xE9s",
    "Les mission \xE0 r\xE9aliser": "Les missions \xE0 r\xE9aliser",
    "Les modifications que vous avez apport\xE9es ne seront peut-\xEAtre pas enregistr\xE9es.":
      "Les modifications que vous avez apport\xE9es ne seront peut-\xEAtre pas enregistr\xE9es.",
    "Les projets d\xE9finis par le talent":
      "Les projets d\xE9finis par le talent",
    "Les pr\xE9sentes CGU sont applicables \xE0 compter de leur acceptation par les Utilisateurs et pendant toute la dur\xE9e d\u2019utilisation de la Plateforme par l\u2019Utilisateur.":
      "Les pr\xE9sentes CGU sont applicables \xE0 compter de leur acceptation par les Utilisateurs et pendant toute la dur\xE9e d\u2019utilisation de la Plateforme par l\u2019Utilisateur.",
    "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1> CLUB FREELANCE LIMITED</1>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG, Royaume-Uni, son adresse de courrier \xE9lectronique est <2>contact@mindquest.io</2> et son num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473, (ci-apr\xE8s la \xAB <3>Soci\xE9t\xE9</3> \xBB).":
      "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1> CLUB FREELANCE LIMITED</1>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG, Royaume-Uni, son adresse de courrier \xE9lectronique est <2>contact@mindquest.io</2> et son num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473, (ci-apr\xE8s la \xAB <3>Soci\xE9t\xE9</3> \xBB).",
    "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1>CLUB  FREELANCE  LIMITED</1>,  soci\xE9t\xE9  enregistr\xE9e  en  Angleterre  et  au  Pays  de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London    SE16    4DG,    Royaume-Uni,    son    adresse de    courrier    \xE9lectronique    est  <2>contact@mindquest.io</2>  et  son  num\xE9ro  individuel  d\u2019identification  TVA britannique  GB19 4905473, (ci-apr\xE8s la \xAB  <3>Soci\xE9t\xE9</3> \xBB).":
      "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1>CLUB  FREELANCE  LIMITED</1>,  soci\xE9t\xE9  enregistr\xE9e  en  Angleterre  et  au  Pays  de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London    SE16    4DG,    Royaume-Uni,    son    adresse de    courrier    \xE9lectronique    est  <2>contact@mindquest.io</2>  et  son  num\xE9ro  individuel  d\u2019identification  TVA britannique  GB19 4905473, (ci-apr\xE8s la \xAB  <3>Soci\xE9t\xE9</3> \xBB).",
    "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1>CLUB FREELANCE LIMITED</1>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG, Royaume-Uni, son adresse de courrier \xE9lectronique est <2>contact@mindquest.io</2> et son num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473, (ci-apr\xE8s la \xAB <3>Soci\xE9t\xE9</3> \xBB).":
      "Les pr\xE9sentes Conditions G\xE9n\xE9rales d\u2019Utilisation (ci-apr\xE8s les \xAB <0>CGU</0> \xBB) sont propos\xE9es par la soci\xE9t\xE9<1>CLUB FREELANCE LIMITED</1>, soci\xE9t\xE9 enregistr\xE9e en Angleterre et au Pays de Galles sous le num\xE9ro 9050953, dont le si\xE8ge social est Biscuit Factory, 100 Drummond Rd, London SE16 4DG, Royaume-Uni, son adresse de courrier \xE9lectronique est <2>contact@mindquest.io</2> et son num\xE9ro individuel d\u2019identification TVA britannique GB19 4905473, (ci-apr\xE8s la \xAB <3>Soci\xE9t\xE9</3> \xBB).",
    "Les pr\xE9sentes conditions g\xE9n\xE9rales d'utilisation (\xAB <0>CGU</0> \xBB) ont pour objet d\u2019encadrer les modalit\xE9s de mise \xE0 disposition et d\u2019acc\xE8s de la Plateforme par les candidats \xE0 un emploi ou une mission afin d\u2019entrer en relation avec toute personne morale ou physique ayant cr\xE9\xE9 un compte et pouvant proposer une offre d\u2019emploi ou une mission.":
      "Les pr\xE9sentes conditions g\xE9n\xE9rales d'utilisation (\xAB <0>CGU</0> \xBB) ont pour objet d\u2019encadrer les modalit\xE9s de mise \xE0 disposition et d\u2019acc\xE8s de la Plateforme par les candidats \xE0 un emploi ou une mission afin d\u2019entrer en relation avec toute personne morale ou physique ayant cr\xE9\xE9 un compte et pouvant proposer une offre d\u2019emploi ou une mission.",
    "Les pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation sont soumises \xE0 la l\xE9gislation fran\xE7aise.":
      "Les pr\xE9sentes conditions g\xE9n\xE9rales d\u2019utilisation sont soumises \xE0 la l\xE9gislation fran\xE7aise.",
    "Les risques & erreurs \xE0 \xE9viter":
      "Les risques & erreurs \xE0 \xE9viter",
    "Les talents du vivier": "Talents",
    "Les th\xE8mes d\xE9finis par le talent":
      "Les th\xE8mes d\xE9finis par le talent",
    "Les {0} articles les plus lus cette semaine": function(a) {
      return ["Les ", a("0"), " articles les plus lus cette semaine"];
    },
    "Les \xE9l\xE9ments de contexte comme la phase du projet, la composition des \xE9quipes, ou encore l\u2019organisation interne nous aident mieux comprendre vos recherches":
      "Les \xE9l\xE9ments de contexte comme les types de projets, la composition des \xE9quipes, ou encore les valeurs de l\u2019entreprise nous aident \xE0 mieux comprendre vos recherches",
    "Lien non valide": "Lien non valide",
    "Lieu de travail": "Lieu de travail",
    "Lifetime of cookies": "Dur\xE9e de vie des cookies",
    "Limite applications to 3 simulteanous opened jobs":
      "Limite applications to 3 simulteanous opened jobs",
    "Limite de projets atteint": "Limite de projets atteint",
    "Limite de th\xE8mes atteint": "Limite de th\xE8mes atteint",
    "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;":
      "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;",
    "LinkedIn Insights": "LinkedIn Insights",
    "Lire l\u2019article": "Lire l\u2019article",
    "Livre blancs & Webinars": "Livre blancs & Webinars",
    Localisation: "Localisation",
    "Localisations strat\xE9giques": "Bureaux europ\xE9ens",
    "Location of employment or workplace;":
      "Location of employment or workplace;",
    Login: "Login",
    Logistic: "Logistic",
    Logout: "Se d\xE9connecter",
    Londres: "Londres",
    "Longueur non valide": "Longueur non valide",
    "Lorsqu'un emploi pour lequel vous avez postul\xE9":
      "Lorsqu'un emploi pour lequel vous avez postul\xE9",
    "Lorsqu'un emploi pour lequel vous avez postul\xE9 est ferm\xE9, vous pouvez postuler une nouvelle fois":
      "Lorsqu'un emploi pour lequel vous avez postul\xE9 est ferm\xE9, vous pouvez postuler une nouvelle fois",
    Légal: "L\xE9gal",
    "L\u2019Utilisateur  doit  \xEAtre  \xE2g\xE9  d\u2019au  moins  18  ans  et  \xEAtre  capable  juridiquement  de  contracter et d\u2019utiliser le Site conform\xE9ment aux pr\xE9sentes. L\u2019Utilisateur est tenu de fournir des informations exactes qu\u2019il s\u2019engage \xE0 mettre \xE0 jour r\xE9guli\xE8rement.":
      "L\u2019Utilisateur  doit  \xEAtre  \xE2g\xE9  d\u2019au  moins  18  ans  et  \xEAtre  capable  juridiquement  de  contracter et d\u2019utiliser le Site conform\xE9ment aux pr\xE9sentes. L\u2019Utilisateur est tenu de fournir des informations exactes qu\u2019il s\u2019engage \xE0 mettre \xE0 jour r\xE9guli\xE8rement.",
    "L\u2019Utilisateur doit \xEAtre \xE2g\xE9 d\u2019au moins 18 ans et \xEAtre capable juridiquement de contracter et d\u2019utiliser le Site conform\xE9ment aux pr\xE9sentes. L\u2019Utilisateur est tenu de fournir des informations exactes qu\u2019il s\u2019engage \xE0 mettre \xE0 jour r\xE9guli\xE8rement.":
      "L\u2019Utilisateur doit \xEAtre \xE2g\xE9 d\u2019au moins 18 ans et \xEAtre capable juridiquement de contracter et d\u2019utiliser le Site conform\xE9ment aux pr\xE9sentes. L\u2019Utilisateur est tenu de fournir des informations exactes qu\u2019il s\u2019engage \xE0 mettre \xE0 jour r\xE9guli\xE8rement.",
    "L\u2019Utilisateur est seul responsable de tout usage qui pourrait \xEAtre fait de son identifiant et mot de passe, et seul garant de leur confidentialit\xE9, ainsi que de toute utilisation de son compte.":
      "L\u2019Utilisateur est seul responsable de tout usage qui pourrait \xEAtre fait de son identifiant et mot de passe, et seul garant de leur confidentialit\xE9, ainsi que de toute utilisation de son compte.",
    "L\u2019Utilisateur s\u2019engage \xE0 ne reproduire aucun des \xE9l\xE9ments de la Plateforme.":
      "L\u2019Utilisateur s\u2019engage \xE0 ne reproduire aucun des \xE9l\xE9ments de la Plateforme.",
    "L\u2019acc\xE8s au compte Utilisateur cr\xE9\xE9 est prot\xE9g\xE9 par un identifiant et un mot de passe choisi par l\u2019Utilisateur lors de son inscription sur le Site.":
      "L\u2019acc\xE8s au compte Utilisateur cr\xE9\xE9 est prot\xE9g\xE9 par un identifiant et un mot de passe choisi par l\u2019Utilisateur lors de son inscription sur le Site.",
    "L\u2019ajout d\u2019un nouveau signataire va ecraser l\u2019ancien..":
      "L\u2019ajout d\u2019un nouveau signataire va ecraser l\u2019ancien..",
    "L\u2019ensemble des offres ajout\xE9s dans notre syst\xE8me pour votre entreprise, sur la p\xE9riode s\xE9lectionn\xE9e":
      "L\u2019ensemble des offres ajout\xE9es dans notre syst\xE8me pour votre entreprise, sur la p\xE9riode s\xE9lectionn\xE9e",
    "L\u2019ensemble des \xE9l\xE9ments composant la Plateforme, en ce compris l\u2019interface de la Plateforme ainsi  que  son  arborescence, sont  la  propri\xE9t\xE9  exclusive  de  la  Soci\xE9t\xE9  ;  ce  que  les  Utilisateurs acceptent.":
      "L\u2019ensemble des \xE9l\xE9ments composant la Plateforme, en ce compris l\u2019interface de la Plateforme ainsi  que  son  arborescence, sont  la  propri\xE9t\xE9  exclusive  de  la  Soci\xE9t\xE9  ;  ce  que  les  Utilisateurs acceptent.",
    "L\u2019ensemble des \xE9l\xE9ments composant la Plateforme, en ce compris l\u2019interface de la Plateforme ainsi que son arborescence, sont la propri\xE9t\xE9 exclusive de la Soci\xE9t\xE9 ; ce que les Utilisateurs acceptent.":
      "L\u2019ensemble des \xE9l\xE9ments composant la Plateforme, en ce compris l\u2019interface de la Plateforme ainsi que son arborescence, sont la propri\xE9t\xE9 exclusive de la Soci\xE9t\xE9 ; ce que les Utilisateurs acceptent.",
    "L\u2019exp\xE9rience Club Freelance racont\xE9e par les Freelances informatiques et d\xE9cideurs IT":
      "L\u2019exp\xE9rience Mindquest (Club Freelance) racont\xE9e par les talents et d\xE9cideurs IT et les meilleurs t\xE9moignages",
    MAX_NORMAL_TIME_PER_DAY:
      "D\xE9passement du temps normal maximum autoris\xE9 par jour",
    MAX_SPECIAL_TIME_PER_DAY:
      "D\xE9passement du temps sp\xE9cial maximum autoris\xE9 par jour",
    MAX_THEMES_PER_DAY:
      "D\xE9passement du nombre maximum de th\xE8me autoris\xE9 par jour",
    MAX_TIME_PER_DAY: "D\xE9passement du temps maximum autoris\xE9 par jour",
    "MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.":
      "MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.",
    "Ma Certification": "Ma Certification",
    "Ma Formation": "Ma Formation",
    "Ma formation": "Ma formation",
    "Ma pr\xE9sentation": "Ma pr\xE9sentation",
    "Ma recherche": "Ma recherche",
    "Management of the customer relationship;":
      "La gestion de la relation client;",
    "Managing applications submitted by the Talents;":
      "La gestion des candidatures d\xE9pos\xE9es par les Talents;",
    "Managing the Company\u2019s platform user account;":
      "La gestion du compte des utilisateurs de la plateforme de la Soci\xE9t\xE9;",
    "Manifestez votre int\xE9r\xEAt et nos \xE9quipes reviendrons vers vous avec plus d'informations":
      "Manifestez votre int\xE9r\xEAt et nos \xE9quipes reviendrons vers vous avec plus d'informations",
    "Market research.": "Les \xE9tudes de march\xE9.",
    "Marketing activities": "Marketing activities",
    "Max 24h": "Max 24h",
    "Max 60m": "Max 60m",
    "Maximisez vos chances en indiquant un intitul\xE9 clair et identifiable":
      "Maximisez vos chances en indiquant un intitul\xE9 clair et identifiable",
    "Maximisez vos changes en indiquant un intitul\xE9 clair et identifiable":
      "Maximisez vos changes en indiquant un intitul\xE9 clair et identifiable",
    "Mentions l\xE9gales": "Mentions l\xE9gales",
    Merci: "Merci",
    "Merci d'avoir partag\xE9'": "Merci d'avoir partag\xE9'",
    "Merci d'indiquer votre activit\xE9": "Merci d'indiquer votre activit\xE9",
    "Merci de confirmer que vous n'avez pas travaill\xE9 ce mois":
      "Merci de confirmer que vous n'avez pas travaill\xE9 ce mois",
    "Merci de consulter votre email": "Merci de consulter votre bo\xEEte mail",
    "Merci de d'indiquer le raison du rejet":
      "Merci d'indiquer la raison du rejet",
    "Merci de saisir votre mot de passe afin de confirmer votre action.":
      "Merci de saisir votre mot de passe afin de confirmer votre action.",
    "Merci de v\xE9rifier les champs erron\xE9s":
      "Merci de v\xE9rifier les champs erron\xE9s",
    "Merci de v\xE9rifier les informations":
      "Merci de v\xE9rifier les informations",
    "Merci de v\xE9rifier vos informations":
      "Merci de v\xE9rifier vos informations",
    "Merci!": "Merci!",
    "Merci, c\u2019est tout bon !": "Merci, c\u2019est tout bon !",
    "Merci, nos \xE9quipes vont \xE9tudier votre demande et revenir vers vous dans les plus brefs d\xE9lais.":
      "Merci, nos \xE9quipes vont \xE9tudier votre demande et revenir vers vous dans les plus brefs d\xE9lais.",
    "Mes Certifications": "Mes Certifications",
    "Mes Collaborateurs": "Mes Collaborateurs",
    "Mes Coordonnn\xE9es": "Mes Coordonnn\xE9es",
    "Mes Coordonn\xE9es": "Mes Coordonn\xE9es",
    "Mes Formations": "Mes Formations",
    "Mes Softskills": "Mes Softskills",
    "Mes alertes": "Mes alertes",
    "Mes candidatures": "Mes candidatures",
    "Mes coordonn\xE9es": "Mes coordonn\xE9es",
    "Mes informations": "Mes informations",
    "Mes offres archiv\xE9es": "Mes offres archiv\xE9es",
    "Mes offres en cours": "Mes offres en cours",
    "Mes param\xE8tres": "Mes param\xE8tres",
    "Mes recherches de talent IT": "Mes recherches de talent IT",
    "Mes talents": "Mes talents",
    "Mes viviers de talents": "Mes viviers de talents",
    Message: "Message",
    "Message envoy\xE9": "Message envoy\xE9",
    "Message trop court,plus d\xE9tails sera utile pour nous":
      "Merci d'indiquer plus d'informations",
    "Message:": "Message:",
    "Mettre en favori": "Mettre en favori",
    "Mettre \xE0 jour mon adresse email": "Mettre \xE0 jour mon adresse email",
    "Microsoft Edge": "Microsoft Edge",
    "Microsoft Technologies": "Technologies Microsoft",
    "Mieux que Mindquest je n'ai pas vu\"":
      "Mieux que Mindquest je n'ai pas vu\"",
    "Mieux que Mindquest je n'ai pas vu.":
      "Mieux que Mindquest je n'ai pas vu.",
    Mindquest: "Mindquest",
    "Mindquest - Connecter les meilleurs talents aux meilleures offres IT":
      "Connecter les meilleurs talents aux meilleures offres IT et Finance",
    "Mindquest - Cr\xE9er un compte entreprise":
      "Mindquest - Cr\xE9er un compte entreprise",
    "Mindquest - Cr\xE9er un compte talent":
      "Mindquest - Cr\xE9er un compte talent",
    "Mindquest - D\xE9couvrez le match parfait entre entreprises et talents IT":
      "Mindquest - D\xE9couvrez le match parfait entre entreprises et talents IT",
    "Mindquest - Etre recontact\xE9 par l'un de nos expert en recrutement":
      "Mindquest - Etre recontact\xE9 par l'un de nos expert en recrutement",
    "Mindquest - Login to my Mindquest space":
      "Mindquest - Login to my Mindquest space",
    "Mindquest - Nos conditions G\xE9n\xE9rales d'Utilisation":
      "Mindquest - Nos conditions G\xE9n\xE9rales d'Utilisation",
    "Mindquest - Not Found": "Mindquest - Not Found",
    "Mindquest - Notre politique de confidentialit\xE9":
      "Mindquest - Notre politique de confidentialit\xE9",
    "Mindquest - Nous contacter": "Mindquest - Nous contacter",
    "Mindquest - Plan du site": "Mindquest - Plan du site",
    "Mindquest - Se connecter \xE0 mon espace Mindquest":
      "Mindquest - Se connecter \xE0 mon espace Mindquest",
    "Mindquest - Trouvez le talent id\xE9al pour r\xE9soudre vos d\xE9fis IT":
      "Mindquest - Trouvez le talent id\xE9al pour r\xE9soudre vos d\xE9fis IT & Finance",
    "Mindquest - Trouvez votre futur talent IT avec notre algorithme SMATCH":
      "Mindquest - Trouvez votre futur talent IT ou Finance avec notre algorithme SMATCH",
    "Mindquest - Trouvez votre prochain job IT en CDI ou freelance":
      "Trouvez votre prochain job IT ou Finance en CDI ou Freelance",
    "Mindquest - Trouvez votre prochain job, et acc\xE9l\xE9rez dans votre carri\xE8re IT":
      "Mindquest - Trouvez votre prochain job, et acc\xE9l\xE9rez dans votre carri\xE8re IT",
    "Mindquest Connect": "Mindquest Connect",
    "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance. Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9, nous vous avons d\xE9nich\xE9 les meilleures offres.":
      "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance. Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9, nous vous avons d\xE9nich\xE9 les meilleures offres.",
    "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance.<0> Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9,</0>  nous vous avons d\xE9nich\xE9 <1> les meilleures offres.</1>":
      "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance.<0> Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9,</0>  nous vous avons d\xE9nich\xE9 <1> les meilleures offres.</1>",
    "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance.<0>Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9,</0> nous vous avons d\xE9nich\xE9 <1> les meilleures offres.</1>":
      "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance.<0>Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9,</0> nous vous avons d\xE9nich\xE9 <1> les meilleures offres.</1>",
    "Mindquest connecte les talents aux meilleures offres IT et accompagne les entreprises dans le recrutement du talent id\xE9al pour r\xE9soudre les d\xE9fis IT. Talents CDI ou consultants freelance.":
      "Mindquest connecte les talents aux meilleures offres IT et accompagne les entreprises dans le recrutement du talent id\xE9al pour r\xE9soudre les d\xE9fis IT. Talents CDI ou consultants freelance.",
    "Mindquest dispose d\u2019un vivier de talents important et des meilleurs outils de recherche pour trouver l'<0>expert qualifi\xE9 pour vos besoins </0>en CDI ou en Freelance.":
      "Mindquest dispose d\u2019un vivier de talents important et des meilleurs outils de recherche pour trouver l'<0>expert qualifi\xE9 pour vos besoins </0>en CDI ou en Freelance.",
    "Mindquest et <0>Groupe Y </0>se sont associ\xE9s pour d\xE9velopper des formations pour les m\xE9tiers de la Finance.":
      "Mindquest et <0>Groupe Y </0>se sont associ\xE9s pour d\xE9velopper des formations pour les m\xE9tiers de la Finance.",
    "Mindquest propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.":
      "Mindquest propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.",
    "Mindquest vous permet d\u2019associer plusieurs crit\xE8res au sein d\u2018une m\xEAme alerte gr\xE2ce au format Bool\xE9en \u201COU\u201D/\u201COR\u201D. Vous recevrez par email les opportunit\xE9s correspondant \xE0 une OU plusieurs s\xE9lections par crit\xE8re.":
      "Mindquest vous permet d\u2019associer plusieurs crit\xE8res au sein d\u2018une m\xEAme alerte gr\xE2ce au format Bool\xE9en \u201COU\u201D/\u201COR\u201D. Vous recevrez par email les opportunit\xE9s correspondant \xE0 une OU plusieurs s\xE9lections par crit\xE8re.",
    "Mindquest | Dashboard": "Mindquest | Dashboard",
    "Mindquest | Favoris": "Mindquest | Favoris",
    "Mindquest | Ma formation": "Mindquest | Ma formation",
    "Mindquest | Ma recherche": "Mindquest | Ma recherche",
    "Mindquest | Mes Coordonn\xE9es": "Mindquest | Mes Coordonn\xE9es",
    "Mindquest | Mes Param\xE8tres": "Mindquest | Mes Param\xE8tres",
    "Mindquest | Mes alertes": "Mindquest | Mes alertes",
    "Mindquest | Mes candidatures": "Mindquest | Mes candidatures",
    "Mindquest | Mon CV": "Mindquest | Mon CV",
    "Mindquest | Mon exp\xE9rience": "Mindquest | Mon exp\xE9rience",
    "Mindquest | Mon m\xE9tier": "Mindquest | Mon m\xE9tier",
    "Mindquest | Offres recommand\xE9es": "Mindquest | Offres recommand\xE9es",
    "Mindquest, dispose d\u2019un vivier de talents important et des meilleurs outils de recherche pour trouver l'<0>expert qualifi\xE9 pour vos besoins </0>en CDI ou en Freelance.":
      "Mindquest, dispose d\u2019un vivier de talents important et des meilleurs outils de recherche pour trouver l'<0>expert qualifi\xE9 pour vos besoins </0>en CDI ou en Freelance.",
    "Mindquest,<0/>Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances informatique.":
      "Mindquest,<0/>Connecter les meilleurs talents aux meilleures offres informatiques ou Finance pour un match parfait entre entreprises et talents.",
    Minutes: "Minutes",
    "Mises \xE0 jour produit": "Mises \xE0 jour produit",
    Mission: "Mission",
    "Mission Freelance Finance": "Mission Freelance Finance",
    "Mission pour Freelances":
      "Le match parfait entre entreprises et talents IT ou Finance",
    Missions: "Missions",
    "Missions Freelance IT": "Missions Freelance IT",
    "Missions recommand\xE9es": "Missions recommand\xE9es",
    "Mobile Development": "D\xE9veloppement Mobile",
    Mobilité: "Mobilit\xE9",
    Modifier: "Modifier",
    "Modifier le Timesheet": "Modifier le Timesheet",
    "Modifier mon alerte avec ces crit\xE8res":
      "Modifier mon alerte avec ces crit\xE8res",
    "Modifier votre profil": "Modifier votre profil",
    "Mod\xE8les de CV Club Freelance": "Mod\xE8les de CV Mindquest",
    "Mod\xE8les de CV Mindquest": "Mod\xE8les de CV Mindquest",
    "Mon CV": "Mon CV",
    "Mon adresse email": "Mon adresse email",
    "Mon besoin": "Mon besoin",
    "Mon entreprise": "Mon entreprise",
    "Mon exp\xE9rience": "Mon exp\xE9rience",
    "Mon m\xE9tier": "Mon m\xE9tier",
    "Mon profil": "Mon profil",
    "More articles": "Voir plus d\u2019articles",
    "Mot de passe": "Mot de passe",
    "Mot de passe actuel est invalide": "Mot de passe actuel est invalide",
    "Mot de passe erron\xE9": "Mot de passe erron\xE9",
    "Mot de passe oubli\xE9": "Mot de passe oubli\xE9",
    "Moteurs de recherche": "Moteurs de recherche",
    "Mots-cl\xE9s importants": "Mots-cl\xE9s importants",
    "Mots-cl\xE9s indispensables*": "Mots-cl\xE9s indispensables*",
    "Moyenne entreprise (51-100)": "Moyenne entreprise (51-100)",
    "Mutuelle, formation, cr\xE9dit bancaire... d\xE9couvrez les offres privil\xE8gi\xE9es dont vous pouvez b\xE9n\xE9ficier aupr\xE8s de nos partenaires.":
      "Mutuelle, formation, cr\xE9dit bancaire... d\xE9couvrez les offres privil\xE8gi\xE9es dont vous pouvez b\xE9n\xE9ficier aupr\xE8s de nos partenaires.",
    "M\xE9thode recommand\xE9e": "M\xE9thode recommand\xE9e",
    "N/A": "N/A",
    "NOTRE POLITIQUE DE CONFIDENTIALIT\xC9":
      "NOTRE POLITIQUE DE CONFIDENTIALIT\xC9",
    Name: "Nom",
    "Naviguer horizentalement ou basculez votre \xE9cran en position paysage pour consulter toutes les statistiques":
      "Naviguer horizentalement ou basculez votre \xE9cran en position paysage pour consulter toutes les statistiques",
    "Nb de viviers": "Nombre de viviers",
    "Ne cherchez plus !": "Ne cherchez plus !",
    Neuvoo: "Neuvoo",
    Newsletter: "Newsletter",
    Newsstand: "Newsstand",
    "Niveau d'exp\xE9rience": "Niveau d'exp\xE9rience",
    "Niveau de s\xE9curit\xE9": "Niveau de s\xE9curit\xE9",
    "No fee usually required": "No fee usually required",
    Nom: "Nom",
    "Nom de famille": "Nom de famille",
    "Nom de la certification": "Nom de la certification",
    "Nom de l\u2019entreprise": "Nom de l\u2019entreprise",
    "Nom du diplome": "Nom du diplome",
    "Nom du projet": "Nom du projet",
    "Nom du r\xE9f\xE9rent": "Nom du r\xE9f\xE9rent",
    "Nom du th\xE8me": "Nom du th\xE8me",
    "Nom du viver": "Nom du vivers",
    "Nom du vivier": "Nom du vivier",
    "Nom et pr\xE9nom": "Nom et pr\xE9nom",
    "Nombre de jours total:": "Nombre de jours total:",
    "Nombre non valide": "Nombre non valide",
    Non: "Non",
    "Non Merci": "Non Merci",
    "Non assign\xE9": "Non assign\xE9",
    "Non disponible": "Non disponible",
    "Non mentionn\xE9": "Non mentionn\xE9",
    "Non pr\xE9cis": "Non pr\xE9cis\xE9",
    "Non renseign\xE9": "Non renseign\xE9",
    "Non-IT": "Hors Informatique",
    Normal: "Normal",
    "Nos Partennaires": "Nos Partennaires",
    "Nos actualit\xE9s": "Nos actualit\xE9s",
    "Nos articles pour candidats \xE0 la recherche d'opportunit\xE9s":
      "Nos articles pour candidats \xE0 la recherche d'opportunit\xE9s",
    "Nos articles pour talents et experts de la finance":
      "Nos articles pour talents et experts de la finance",
    "Nos bureaux": "Nos bureaux",
    "Nos chiffres clefs": "Nos chiffres clefs",
    "Nos conseils en vid\xE9o": "Nos conseils en vid\xE9o",
    "Nos conseils pour les freelance": "Nos conseils pour les candidats",
    "Nos conseils pour recruter en freelance":
      "Nos conseils pour recruter dans l'IT ou la finance",
    "Nos domaines d\u2019expertise et communaut\xE9s":
      "Nos domaines d\u2019expertise et communaut\xE9s",
    "Nos fiches m\xE9tiers - Mindquest Connect":
      "Nos fiches m\xE9tiers - Mindquest Connect",
    "Nos partenaires": "Nos partenaires",
    "Nos partenaires et nous d\xE9posons des cookies afin d'assurer la s\xE9curit\xE9, am\xE9liorer votre exp\xE9rience digitale et afficher des publicit\xE9s et contenus personnalis\xE9s Vous pouvez accepter ou refuser ces diff\xE9rentes op\xE9rations. Par ailleurs, conform\xE9ment \xE0 la l\xE9gislation fran\xE7aise, les cookies de mesure d'audience peuvent \xEAtre exempt\xE9s du recueil du consentement de l'internaute. En l'esp\xE8ce et sur nos sites, nos cookies Web Analytics sont dispens\xE9s du recueil du consentement. G\xE9rer vos pr\xE9f\xE9rences AT Internet":
      "Nos partenaires et nous d\xE9posons des cookies afin d'assurer la s\xE9curit\xE9, am\xE9liorer votre exp\xE9rience digitale et afficher des publicit\xE9s et contenus personnalis\xE9s Vous pouvez accepter ou refuser ces diff\xE9rentes op\xE9rations. Par ailleurs, conform\xE9ment \xE0 la l\xE9gislation fran\xE7aise, les cookies de mesure d'audience peuvent \xEAtre exempt\xE9s du recueil du consentement de l'internaute. En l'esp\xE8ce et sur nos sites, nos cookies Web Analytics sont dispens\xE9s du recueil du consentement. G\xE9rer vos pr\xE9f\xE9rences AT Internet",
    "Nos partenaires portage": "Nos partenaires portage",
    "Nos ressources pour freelance finance et experts salari\xE9s":
      "Nos ressources pour freelance finance et experts salari\xE9s",
    "Nos ressources pour les experts finance et les entreprises qui recrutent":
      "Nos ressources pour les experts finance et les entreprises qui recrutent",
    "Nos ressources pour les talents Finance":
      "Nos ressources pour les talents Finance",
    "Nos ressources pour les talents IT": "Nos ressources pour les talents IT",
    "Nos r\xE9f\xE9rences clients": "Nos r\xE9f\xE9rences clients",
    "Nos \xE9quipes innovation et support travaillent \xE0 l\u2019optimisation constante de nos outils et process pour vous proposer la meilleure exp\xE9rience possible.":
      "Nos \xE9quipes innovation et support travaillent \xE0 l\u2019optimisation constante de nos outils et process pour vous proposer la meilleure exp\xE9rience possible.",
    "Nos \xE9quipes sont en contact permanent avec entreprises et freelances, permettant une connaissance pointue du march\xE9":
      "Nos \xE9quipes sont en contact permanent avec entreprises et Talents IT, d'o\xF9 une connaissance pointue du march\xE9",
    "Nos \xE9quipes vous accompagnent pour recruter & int\xE9grer les meilleurs freelances IT pour vos projets. Solution compl\xE8te de Talent Management et conseils personnalis\xE9s":
      "Nos \xE9quipes vous accompagnent pour recruter & int\xE9grer les meilleurs talents IT pour vos projets. Solution compl\xE8te de Talent Management et conseils personnalis\xE9s",
    "Not Found": "Not Found",
    "Not Mentioned": "Not Mentioned",
    "Not found": "Not found",
    "Notamment, les Utilisateurs s\u2019interdisent:":
      "Notamment, les Utilisateurs s\u2019interdisent:",
    "Note: ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet":
      "Note: ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet",
    "Notre accompagnement": "Notre accompagnement",
    "Notre accompagnent": "Notre accompagnent",
    "Notre algorithme de matching SMATCH est en pleine de recherche du profils parfait au sein de nos communaut\xE9s":
      "Notre algorithme de matching SMATCH est en pleine de recherche du profils parfait au sein de nos communaut\xE9s",
    "Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communaut\xE9s":
      "Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communaut\xE9s",
    "Notre communaut\xE9 au coeur de notre service.":
      "Notre communaut\xE9 au coeur de notre service.",
    "Notre mission est d\u2019une part d\u2019aider nos entreprises clientes \xE0 attirer et fid\xE9liser les meilleurs talents pour r\xE9pondre \xE0 leurs besoins strat\xE9giques, et d\u2019autre part d\u2019accompagner nos consultants dans leur carri\xE8re en les aidant \xE0 trouver les missions les plus adapt\xE9es \xE0 leur profil et leurs aspirations.":
      "Avec une plateforme d\u2019emplois num\xE9riques ouverte, soutenue par un groupe dynamique de recruteurs experts en technologie, Mindquest combine la rapidit\xE9 et la commodit\xE9 des derniers outils num\xE9riques avec l\u2019expertise humaine pour offrir une exp\xE9rience fluide et efficace.",
    "Notre newsletter bimensuelle comporte nos derniers articles, nos plus belles missions et du contenu sp\xE9cialement s\xE9lectionn\xE9 pour vous":
      "Inscrivez-vous \xE0 notre newsletter, Mission Control Center, pour recevoir chaque jeudi un r\xE9sum\xE9 des meilleures infos tech & IT, nos derniers articles et nos plus belles offres d\u2019emploi du moment\u202F!",
    "Notre performance en chiffres": "Nos performances en chiffres",
    "Notre service de facturation fera correspondre les heures normales et sp\xE9ciales avec les tarifs d\xE9finis dans votre contrat":
      "Notre service de facturation fera correspondre les heures normales et sp\xE9ciales avec les tarifs d\xE9finis dans votre contrat",
    "Notre vision du march\xE9": "Notre vision du march\xE9",
    "Notre \xE9quipe Mindquest \xE9changera avec le consultant afin":
      "Notre \xE9quipe Mindquest \xE9changera avec le consultant afin",
    "Notre \xE9quipe Mindquest \xE9changera avec le consultant afin de   v\xE9rifier sa disponibilt\xE9. Nous reviendrons vers vous dans le plus breff d\xE9lais.":
      "Notre \xE9quipe Mindquest \xE9changera avec le consultant afin de   v\xE9rifier sa disponibilt\xE9. Nous reviendrons vers vous dans les plus brefs d\xE9lais",
    "Nous accordons une grande place \xE0 l\u2019humain et nouons des relations de long terme avec nos clients et nos consultants.":
      "Chaque offre d\u2019emploi est g\xE9r\xE9e par un duo de professionnels. C\u2019est-\xE0-dire un responsable de comptes connaissant les entreprises, leur secteur et leurs enjeux et un sp\xE9cialiste en acquisition de talents, experts sur un domaine technologique. Ces derniers travaillent ensemble pour fournir un service d\u2019accompagnement complet aux entreprises et aux candidats. Ceci de la publication de l\u2019offre de mission et de la recherche d\u2019emploi \xE0 l\u2019offboarding.",
    "Nous avons apport\xE9 des modifications \xE0 nos conditions d'utilisation, ainsi qu'\xE0 notre<0/>politique de confidentialit\xE9 le 03 f\xE9vrier 2022. vous pouvez les consulter sur ces pages: <1/><2/><3>Nouvelles conditions g\xE9n\xE9rales d\u2019utilisation </3> <4>Nouvelle politique de confidentialit\xE9 </4>":
      "Nous avons apport\xE9 des modifications \xE0 nos conditions d'utilisation, ainsi qu'\xE0 notre<0/>politique de confidentialit\xE9 le 03 f\xE9vrier 2022. vous pouvez les consulter sur ces pages: <1/><2/><3>Nouvelles conditions g\xE9n\xE9rales d\u2019utilisation </3> <4>Nouvelle politique de confidentialit\xE9 </4>",
    "Nous avons bien pris en compte votre demande. Les ajustements seront effectu\xE9s par nos \xE9quipes dans un d\xE9lai de 30 jours. Merci d\u2019avoir utilis\xE9 les services de Mindquest.":
      "Nous avons bien pris en compte votre demande. Les ajustements seront effectu\xE9s par nos \xE9quipes dans un d\xE9lai de 30 jours. Merci d\u2019avoir utilis\xE9 les services de Mindquest.",
    "Nous avons cr\xE9\xE9 un \xE9cosyst\xE8me complet de partenaires au service des talents et des entreprises pour un accompagnement personnalis\xE9 de A \xE0 Z.":
      "Nous avons cr\xE9\xE9 un \xE9cosyst\xE8me complet de partenaires au service des talents et des entreprises pour un accompagnement personnalis\xE9 de A \xE0 Z.",
    "Nous b\xE9n\xE9ficions d\u2019une connaissance approfondie des process m\xE9tiers par secteur d\u2019activit\xE9, et des comp\xE9tences techniques par domaine d\u2019expertise":
      "Nous b\xE9n\xE9ficions d\u2019une connaissance approfondie des process m\xE9tiers par secteur d\u2019activit\xE9, et des comp\xE9tences techniques par domaine d\u2019expertise",
    "Nous connectons les meilleurs experts aux meilleures offres pour un match parfait entre entreprises et talents informatiques.":
      "Nous connectons les meilleurs experts aux meilleures offres pour un match parfait entre entreprises et talents informatiques ou finance.",
    "Nous contacter": "Nous contacter",
    "Nous couvrons tous les m\xE9tiers de la technologie et de l\u2019informatique":
      "Nous couvrons tous les m\xE9tiers de la tech, l\u2019IT et de la Finance",
    "Nous identifions les esprits brillants et les associons avec les marques les plus innovantes.":
      "Nous accompagnons les meilleurs consultants IT et les entreprises les plus innovantes dans la r\xE9ussite de leurs projets",
    "Nous ne construisons pas des bases de donn\xE9es, nous construisons des communaut\xE9s. D\u2019o\xF9 des relations de proximit\xE9 avec nos consultants ind\xE9pendants, et une connaissance pointue des profils disponibles sur le march\xE9.":
      "Nous ne construisons pas des bases de donn\xE9es, nous construisons des communaut\xE9s. D\u2019o\xF9 des relations de proximit\xE9 avec nos candidats, et une connaissance pointue des profils disponibles sur le march\xE9.",
    "Nous nous diff\xE9rencions par un service d\u2019excellence qui allie <0>expertise relationnelle, compr\xE9hension m\xE9tier et plateforme digitale </0>pour mettre en relation les profils de candidats <1>hautement qualifi\xE9s</1> et les clients n\xE9cessitant des comp\xE9tences.":
      "Nous nous diff\xE9rencions par un service d\u2019excellence qui allie <0>expertise relationnelle, compr\xE9hension m\xE9tier et plateforme digitale </0>pour mettre en relation les profils de candidats <1>hautement qualifi\xE9s</1> et les clients n\xE9cessitant des comp\xE9tences.",
    "Nous nous diff\xE9rencions par un service d\u2019excellence qui allie<0>expertise relationnelle, compr\xE9hension m\xE9tier et plateforme digitale </0>pour mettre en relation les profils de candidats <1>hautement qualifi\xE9s</1> et les clients n\xE9cessitant des comp\xE9tences.":
      "Nous nous diff\xE9rencions par un service d\u2019excellence qui allie<0>expertise relationnelle, compr\xE9hension m\xE9tier et plateforme digitale </0>pour mettre en relation les profils de candidats <1>hautement qualifi\xE9s</1> et les clients n\xE9cessitant des comp\xE9tences.",
    "Nous n\u2019avons pas pu g\xE9n\xE9rer le fichier demand\xE9":
      "Nous n\u2019avons pas pu g\xE9n\xE9rer le fichier demand\xE9",
    "Nous publions de nouvelles offres tous les jours, cr\xE9ez une alerte sur vos crit\xE8res de recherche pour \xEAtre le premier inform\xE9 de celles qui vous correspondent":
      "Nous publions de nouvelles offres tous les jours, cr\xE9ez une alerte sur vos crit\xE8res de recherche pour \xEAtre le premier inform\xE9 de celles qui vous correspondent",
    "Nous rejoindre": "Nous rejoindre",
    "Nous sommes d\xE9sol\xE9, l'offre que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9s, l'offre que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous sommes d\xE9sol\xE9, l'opportunit\xE9 que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9s, l'opportunit\xE9 que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous sommes d\xE9sol\xE9, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponib le":
      "Nous sommes d\xE9sol\xE9, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponib le",
    "Nous sommes d\xE9sol\xE9, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9s, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous sommes d\xE9sol\xE9, le candidat que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9, le candidat que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin.":
      "Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin.",
    "Nous vous recommandons fortement d\u2019utiliser le format Word.":
      "Nous vous recommandons fortement d\u2019utiliser le format Word",
    "Nous y r\xE9pondrons dans les plus brefs d\xE9lais":
      "Nous y r\xE9pondrons dans les plus brefs d\xE9lais",
    "Nouveau Timesheet en attente": "Nouveau Timesheet en attente",
    "Nouveau compte-rendu disponible pour le contrat {placementId}": function(
      a
    ) {
      return [
        "Nouveau compte-rendu disponible pour le contrat ",
        a("placementId")
      ];
    },
    "Nouveau valideur pour le contrat {placementId}": function(a) {
      return ["Nouveau valideur pour le contrat ", a("placementId")];
    },
    "Nouveaux profils": "Nouveaux profils",
    "Nouvelle politique de confidentialit\xE9 et GCU":
      "Nouvelle politique de confidentialit\xE9 et GCU",
    Nouvelles: "Nouvelles",
    "Num\xE9ro de bon de commande": "Num\xE9ro de bon de commande",
    "Num\xE9ro non valide": "Num\xE9ro invalide",
    Néerlandais: "N\xE9erlandais",
    "OS & Software development":
      "D\xE9veloppement Logiciels et Syst\xE8mes d'Exploitation ",
    OU: "OU",
    "Obtenir ton lien partageable": "Obtenir ton lien partageable",
    "Obtenir un lien partageable": "Obtenir un lien partageable",
    "Obtenir votre lien partageable": "Obtenir votre lien partageable",
    "Obtenues sur cette mission": "Obtenues sur cette mission",
    "Offer clients recruitment services (permanent jobs or contracts);":
      "Proposer des services de recrutement aux Clients (freelances ou en CDI);",
    Offre: "Offre",
    "Offre Finance": "Offre Finance",
    "Offre finance": "Offre finance",
    Offres: "Offres",
    "Offres Finance": "Offres Finance",
    "Offres archiv\xE9es": "Offres archiv\xE9es",
    "Offres en cours": "Offres en cours",
    "Offres post\xE9es": "Offres post\xE9es",
    "Offres recommand\xE9es": "Offres recommand\xE9es",
    "Offres similaires": "Offres similaires",
    Ok: "Ok",
    "On parle de nous": "On parle de nous",
    "On parle de nous - Communiqu\xE9s de Presse - Mindquest Connect":
      "On parle de nous - Communiqu\xE9s de Presse - Mindquest Connect",
    "On parle de nous - Mindquest Connect":
      "On parle de nous - Mindquest Connect",
    "On parle de nous - Nos actualit\xE9s - Mindquest Connect":
      "On parle de nous - Nos actualit\xE9s - Mindquest Connect",
    "On parle de nous - T\xE9moignages - Mindquest Connect":
      "On parle de nous - T\xE9moignages - Mindquest Connect",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre mission":
      "On vous accompagne tout au long du process de recrutement ",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre nouveau job !":
      "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre nouveau job !",
    "One of the GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.":
      "One of the GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.",
    "Oops!": "Oops!",
    "Optimisation of navigation": "Optimisation de la navigation",
    "Organisme de d\xE9livrance": "Organisme de d\xE9livrance",
    "Origin of cookies": "Provenance des cookies",
    "Other information relevant to help us provide recruitment services;":
      "Other information relevant to help us provide recruitment services;",
    Ou: "Ou",
    Oui: "Oui",
    "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafraichir la page dans un moment":
      "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafraichir la page dans un moment",
    "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafra\xEEchir la page dans un moment":
      "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafra\xEEchir la page dans un moment",
    "Our Clients may share personal information about you with us;":
      "Our Clients may share personal information about you with us;",
    "Our Tech Magazine -  Mindquest Connect":
      "Our Tech Magazine -  Mindquest Connect",
    "Our job descriptions - Mindquest Connect":
      "Our job descriptions - Mindquest Connect",
    "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.":
      "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.",
    "Ouvert aux opportunit\xE9s": "Ouvert aux opportunit\xE9s",
    Ouverte: "Ouverte",
    Ouverts: "Ouverts",
    "Ouverture du Sourcing Hub \xE0 Barcelone":
      "Ouverture du Sourcing Hub \xE0 Barcelone",
    "Ouverture du bureau commercial \xE0 paris":
      "Ouverture du bureau commercial \xE0 Paris",
    "Ouvrir le CV en PDF": "Ouvrir le CV en PDF",
    "PHARMA & CHEMICALS": "PHARMA & CHEMICALS",
    "PLAN DU SITE": "PLAN DU SITE",
    PMO: "PMO",
    "PMO BI": "PMO BI",
    Pages: "Pages",
    Paie: "Paie",
    Par: "Par",
    "Par ailleurs, la Soci\xE9t\xE9, en tant qu\u2019op\xE9rateur ind\xE9pendant n\u2019intervenant pas dans le cadre d\u2019une relation entre le Client et un Utilisateur, ne saurait \xEAtre responsable de toute difficult\xE9 pouvant survenir entre un Utilisateur et un Client \xE0 ce stade de leur collaboration.":
      "Par ailleurs, la Soci\xE9t\xE9, en tant qu\u2019op\xE9rateur ind\xE9pendant n\u2019intervenant pas dans le cadre d\u2019une relation entre le Client et un Utilisateur, ne saurait \xEAtre responsable de toute difficult\xE9 pouvant survenir entre un Utilisateur et un Client \xE0 ce stade de leur collaboration.",
    "Par an": "Par an (Format 00 000)",
    "Par jour": "En euros",
    Paramètres: "Param\xE8tres",
    Parfait: "Parfait",
    "Parfait, merci.": "Parfait, merci.",
    'Parfait, merci."': 'Parfait, merci."',
    Paris: "Paris",
    "Part-time": "Part-time",
    Partager: "Partager",
    "Partager l\u2019article": "Partager l\u2019article",
    Partenaires: "Partenaires",
    "Partenaires Freelance": "Partenaires Freelance",
    "Partenaires portage salarial": "Partenaires portage salarial",
    "Pas d'avis \xE9quipe pour ce talent!":
      "Pas d'avis \xE9quipe pour ce talent!",
    "Pas de collaborateur": "Pas de collaborateur",
    "Pas de collaborateur pour ce vivier":
      "Pas de collaborateur pour ce vivier",
    "Pas de commentaires pour ce talent!":
      "Pas de commentaires pour ce talent!",
    "Pas de mod\xE8le de facture?": "Pas de mod\xE8le de facture?",
    "Pas de num\xE9ro de bon de commande indiqu\xE9":
      "Pas de num\xE9ro de bon de commande indiqu\xE9",
    "Pas de ressources actuellement.": "Pas de ressources actuellement.",
    "Pas de r\xE9f\xE9rence pour ce talent!":
      "Pas de r\xE9f\xE9rence pour ce talent!",
    "Pas de talent pour ce vivier": "Pas de talent pour ce vivier",
    "Pas de vivier": "Pas de vivier",
    "Pas d\u2019activit\xE9 jusqu\u2019\xE0 maintenant":
      "Pas d\u2019activit\xE9 jusqu\u2019\xE0 maintenant",
    "Pas d\u2019activit\xE9 pendant la periode s\xE9lectionn\xE9e":
      "Pas d\u2019activit\xE9 pendant la periode s\xE9lectionn\xE9e",
    "Passer cette \xE9tape": "Passer cette \xE9tape",
    "Payroll manager": "Payroll manager",
    "Pensez \xE0 consulter vos courriers ind\xE9sirables":
      "Pensez \xE0 consulter vos courriers ind\xE9sirables",
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {pID} de {m}/{y}": function(
      a
    ) {
      return [
        "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: ",
        a("pID"),
        " de ",
        a("m"),
        "/",
        a("y")
      ];
    },
    "Pens\xE9s pour les freelances.":
      "Accompagner les talents IT & Finance vers le succ\xE8s",
    "Performance cookies": "Cookies de performance",
    Permanent: "Permanent",
    "Persistent cookies: remain registered on your computer even when you leave your browser (are deleted after a deadline that is determined according to the purpose of cookies, the user also has the possibility to delete them manually).":
      "Cookies  persistants :  restent  enregistr\xE9s  sur  votre  ordinateur  m\xEAme  lorsque vous quittez votre navigateur (sont supprim\xE9s \xE0 l\u2019expiration d\u2019un d\xE9lai qui est d\xE9termin\xE9  en  fonction  de  la  finalit\xE9  vis\xE9e  par  les  cookies,  l\u2019utilisateur  a \xE9galement la possibilit\xE9 de les supprimer manuellement).",
    "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;":
      "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;",
    "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;":
      "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;",
    "Personal data and/or information will be kept only:":
      "Les Donn\xE9es \xE0 caract\xE8re personnel et/ou informations seront conserv\xE9es uniquement:",
    "Personal data that we receive from other sources.":
      "Personal data that we receive from other sources.",
    "Personal data that you, as the Candidate or Client, give to us; and":
      "Personal data that you, as the Candidate or Client, give to us; and",
    "Personal data we receive from other sources":
      "Personal data we receive from other sources",
    "Personal data you give to us": "Personal data you give to us",
    "Petite entreprise (1-50)": "Petite entreprise (1-50)",
    Phone: "Mobile",
    Place: "Place",
    Placement: "Recrutements",
    Placements: "Recrutements",
    "Placements r\xE9ussis chez nos clients":
      "Recrutements r\xE9ussis chez nos clients",
    "Plage de date": "Plage de date",
    "Plages d'heures s\xE9lectionn\xE9es :":
      "Plages d'heures s\xE9lectionn\xE9es :",
    "Plan du site": "Plan du site",
    "Plateforme & Centre d'aide": "Plateforme & Centre d'aide",
    "Please  note  that  these  websites  have  their  own  confidentiality  policy  and  that  the Company accepts no responsibility for the use by these websites and the information collected when you click on these links. The Company invites you to read the confidentiality policies of these sites before sending them your personal data.":
      "Veuillez noter que ces sites internet ont leur propre politique de confidentialit\xE9 et que la Soci\xE9t\xE9 d\xE9cline toute responsabilit\xE9 quant \xE0 l\u2019utilisation faite par ces sites des informations collect\xE9es lorsque vous cliquez sur ces liens. La Soci\xE9t\xE9 vous invite \xE0 prendre connaissance de politiques de confidentialit\xE9 de ces sites avant de leur transmettre vos donn\xE9es personnelles.",
    "Please note that these websites have their own confidentiality policy and that the Company accepts no responsibility for the use by these websites and the information collected when you click on these links. The Company invites you to read the confidentiality policies of these sites before sending them your personal data.":
      "Veuillez noter que ces sites internet ont leur propre politique de confidentialit\xE9 et que la Soci\xE9t\xE9 d\xE9cline toute responsabilit\xE9 quant \xE0 l\u2019utilisation faite par ces sites des informations collect\xE9es lorsque vous cliquez sur ces liens. La Soci\xE9t\xE9 vous invite \xE0 prendre connaissance de politiques de confidentialit\xE9 de ces sites avant de leur transmettre vos donn\xE9es personnelles.",
    "Please read the following carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.":
      "Please read the following carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.",
    "Plus d\u2019expertise": "Plus de domaines d'expertise",
    Podcast: "Podcast",
    "Podcast Interviews": "Podcast Interviews",
    "Podcast Interviews - Mindquest Connect":
      "Podcast Interviews - Mindquest Connect",
    Polish: "Polonais",
    "Politique de confidentialit\xE9": "Politique de confidentialit\xE9",
    Polonais: "Polonais",
    "Portage Salarial": "Portage Salarial",
    "Portage salarial": "Portage salarial",
    "Portfolio, CV en ligne, site personnel":
      "Portfolio, CV en ligne, site personnel",
    Portugais: "Portugais",
    Portuguese: "Portugais",
    "Poste actuel": "Poste actuel",
    "Poster un besoin via le formulaire": "Poster un besoin via le formulaire",
    "Poster un besoin via une fiche de poste":
      "Poster un besoin via une fiche de poste",
    "Poster une offre": "Poster une offre",
    "Poster vos offres et suivez vos recutements en ligne":
      "Poster vos offres et suivez vos recutements en ligne",
    "Postez vos offres et suivez vos recutements en ligne":
      "Postez vos offres et suivez vos recutements en ligne",
    Postuler: "Postuler",
    "Postuler en un clic et suivre votre candidature":
      "Postuler en un clic et suivre votre candidature",
    "Postulez en un clic et suivre votre candidature":
      "Postulez en un clic et suivre votre candidature",
    Postulé: "Postul\xE9",
    "Pour   pouvoir   acc\xE9der   aux   services,   l\u2019Utilisateur   doit   cr\xE9er   un   compte   en   s\u2019inscrivant gratuitement sur le site":
      "Pour   pouvoir   acc\xE9der   aux   services,   l\u2019Utilisateur   doit   cr\xE9er   un   compte   en   s\u2019inscrivant gratuitement sur le site",
    "Pour   pouvoir   acc\xE9der   aux   services,   l\u2019Utilisateur   doit   cr\xE9er   un   compte   en   s\u2019inscrivant gratuitement sur le site.":
      "Pour   pouvoir   acc\xE9der   aux   services,   l\u2019Utilisateur   doit   cr\xE9er   un   compte   en   s\u2019inscrivant gratuitement sur le site.",
    "Pour activer les fonctionnalit\xE9s de timesheet, vous devez contractualiser avec nos services.":
      "Pour activer les fonctionnalit\xE9s de timesheet, vous devez contractualiser avec nos services.",
    "Pour activer les fonctionnalit\xE9s du Timesheets, vous devez contractualiser avec nos services.":
      "Pour activer les fonctionnalit\xE9s du Timesheets, vous devez contractualiser avec nos services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s de timesheet, vous devez d\xE9marrer une mission via nos services.":
      "Pour d\xE9bloquer les fonctionnalit\xE9s de timesheet, vous devez d\xE9marrer une mission via nos services.",
    'Pour enregistrer les donn\xE9es saisies, fermez cette fen\xEAtre en cliquant sur "annuler" puis cliquez sur le bouton "enregistrer" \xE0 la fin du formulaire.':
      'Pour enregistrer les donn\xE9es saisies, fermez cette fen\xEAtre en cliquant sur "annuler" puis cliquez sur le bouton "enregistrer" \xE0 la fin du formulaire.',
    "Pour faciliter vos \xE9changes avec nous, n\u2019h\xE9sitez pas \xE0 cr\xE9er un compte":
      "Pour faciliter vos \xE9changes avec nous, n\u2019h\xE9sitez pas \xE0 cr\xE9er un compte",
    "Pour indiquer que vous avez effectivement travaill\xE9 ce jour et \xE0 votre TJM habituel, cliquez sur continuer.":
      "Pour indiquer que vous avez effectivement travaill\xE9 ce jour et \xE0 votre TJM habituel, cliquez sur continuer.",
    "Pour manifester un int\xE9r\xEAt pour ce profil il vous faut un compte client.":
      "Pour manifester un int\xE9r\xEAt pour ce profil il vous faut un compte client.",
    "Pour ne manquer aucun article": "Pour ne manquer aucun article",
    "Pour ne pas ralentir la facturation, v\xE9rifiez bien vos donn\xE9es":
      "Pour ne pas ralentir la facturation, v\xE9rifiez bien vos donn\xE9es",
    "Pour obtenir imm\xE9diatement des nouveaux talents":
      "Pour obtenir tr\xE8s rapidement des profils adapt\xE9s \xE0 mes besoins",
    "Pour obtenir imm\xE9diatement des offres personnalis\xE9es.":
      "Pour obtenir imm\xE9diatement des offres personnalis\xE9es",
    "Pour pouvoir acc\xE9der aux services, l\u2019Utilisateur doit cr\xE9er un compte en s\u2019inscrivant gratuitement sur le site.":
      "Pour pouvoir acc\xE9der aux services, l\u2019Utilisateur doit cr\xE9er un compte en s\u2019inscrivant gratuitement sur le site.",
    "Pour recevoir des profils, soumettez une offre.":
      "Pour recevoir des profils, soumettez une offre.",
    "Pour trouvez des talent, ajouter des talents \xE0 ce vivier.":
      "Pour trouver des talents, ajouter des talents \xE0 ce vivier.",
    "Pour trouvez des viviers, soumettez un vivier.":
      "Pour trouver des viviers, soumettez un vivier.",
    "Pourquoi nous rejoindre ?": "Pourquoi nous rejoindre ?",
    "Practical Tips for IT decision maker - Mindquest Connect":
      "Practical Tips for IT decision maker - Mindquest Connect",
    "Practical Tips for Tech & IT Talent - Mindquest Connect":
      "Practical Tips for Tech & IT Talent - Mindquest Connect",
    "Premier profils re\xE7u sous une semaine en moyenne pour du CDI":
      "Premier profils re\xE7us sous une semaine en moyenne pour du CDI",
    "Prendre un RDV": "Prendre un RDV",
    "Press review": "Press review",
    "Press review - Mindquest Connect": "Press review - Mindquest Connect",
    "Principales t\xE2ches r\xE9alis\xE9es (facultatif)":
      "Principales t\xE2ches r\xE9alis\xE9es (facultatif)",
    Printed: "Printed",
    "Privacy Policy - Club Freelance":
      "Politique de confidentialit\xE9 - Mindquest",
    "Privacy policy": "Politique de confidentialit\xE9",
    "Processed lawfully, fairly and transparently in relation to the person concerned;":
      "Trait\xE9es de mani\xE8re licite, loyale et transparente au regard de la Personne concern\xE9e;",
    "Processing is necessary for compliance with a legal obligation;":
      "Le traitement est n\xE9cessaire au respect d\u2019une obligation l\xE9gale;",
    "Processing is necessary for the performance of a contract;":
      "Le traitement est n\xE9cessaire \xE0 l\u2019ex\xE9cution d\u2019un contrat;",
    "Processing is necessary for the performance of a task carried out in the public interest;":
      "Le traitement est n\xE9cessaire \xE0 l\u2019ex\xE9cution d\u2019une mission d\u2019int\xE9r\xEAt public;",
    "Processing is necessary to protect the vital interests of the data subject;":
      "Le  traitement  est  n\xE9cessaire  \xE0  la  sauvegarde  des  int\xE9r\xEAts  vitaux  de  la  Personne concern\xE9e;",
    'Processus de recrutement est clair et le suivi est tr\xE8s bien fait"':
      'Processus de recrutement est clair et le suivi est tr\xE8s bien fait"',
    "Processus de recrutement est clair et le suivi est tr\xE8s bien fait.":
      "Processus de recrutement est clair et le suivi est tr\xE8s bien fait.",
    "Prochain rendez-vous": "Prochain rendez-vous",
    "Product Owner": "Product Owner",
    Productivité: "Productivit\xE9",
    Produit: "Produit",
    "Professionnels de la Finance": "Professionnels de la Finance",
    "Professionnels de la Finance -  Mindquest Connect":
      "Professionnels de la Finance -  Mindquest Connect",
    "Profil Pseudonymis\xE9": "Profil Pseudonymis\xE9",
    "Profil anonymis\xE9": "Profil anonymis\xE9",
    "Profil correspondant parfaitement \xE0 notre besoin.":
      "Profil correspondant parfaitement \xE0 notre besoin.",
    'Profil correspondant parfaitement \xE0 notre besoin."':
      'Profil correspondant parfaitement \xE0 notre besoin."',
    "Profil recherch\xE9": "Profil recherch\xE9",
    Profile: "Profil",
    "Profils refus\xE9s": "Profils refus\xE9s",
    "Profils \xE0 rencontrer": "Profils \xE0 rencontrer",
    "Profitez de notre plateforme de Talent Management":
      "Profitez de notre plateforme de Talent Management",
    "Profitez des avantages que nous avons n\xE9goci\xE9s pour vous":
      "Profitez des avantages que nous avons n\xE9goci\xE9s pour vous",
    "Project Management": "Project Management",
    "Project Management job descriptions - Mindquest Connect":
      "Project Management job descriptions - Mindquest Connect",
    "Project management & Agile Coaching": "Gestion de Projet & Coaching Agile",
    Projet: "Projet",
    "Projet (facultatif)": "Projet (facultatif)",
    "Projet (optionnel)": "Projet (optionnel)",
    "Projet :": "Projet :",
    Projets: "Projets",
    "Propose IT assignments or CDI permanent job offers to Talents Consultants;":
      "Proposer des missions informatiques ou des offres en CDI aux Consultants;",
    Proposition: "Proposition",
    "Protected in their integrity and kept confidential.":
      "Prot\xE9g\xE9es dans leur int\xE9grit\xE9 et gard\xE9es confidentielles.",
    "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;":
      "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;",
    "Pr\xE9ciser la date": "Pr\xE9ciser la date",
    "Pr\xE9ciser la recherche": "Pr\xE9ciser la recherche",
    "Pr\xE9ciser le nombre d'heures": "Pr\xE9ciser le nombre d'heures",
    "Pr\xE9ciser le nombre de minutes": "Pr\xE9ciser le nombre de minutes",
    "Pr\xE9ciser le type": "Pr\xE9ciser le type",
    Prénom: "Pr\xE9nom",
    "Pr\xE9nom Nom": "Pr\xE9nom Nom",
    "Pr\xE9nom du r\xE9f\xE9rent": "Pr\xE9nom du r\xE9f\xE9rent",
    "Pr\xE9nom et Nom": "Pr\xE9nom et Nom",
    "Pr\xE9sent sur le vivier": "Pr\xE9sent sur le vivier",
    Présentiel: "Pr\xE9sentiel",
    "Pr\xEAt \xE0 trouver une mission ?":
      "Pr\xEAt(e) \xE0 donner un coup de boost \xE0 votre carri\xE8re?",
    "Pr\xEAt \xE0 trouver une nouvelle mission ?":
      "Pr\xEAt(e) \xE0 acc\xE9l\xE9rer sur vos projets ?",
    "Publi\xE9 le": "Publi\xE9 le",
    Purpose: "Finalit\xE9",
    Période: "P\xE9riode",
    "QA & Testing": "Test & Assurance Qualit\xE9",
    "Que faut-il clarifier ?": "Que faut-il clarifier ?",
    "Quel est votre domaine ?": "Quel est votre domaine d'expertise ?",
    "Quel est votre statut": "Quel est votre statut",
    "Quelle est votre statut": "Quelle est votre statut",
    "Quels contenus aimeriez vous recevoir":
      "Quels contenus aimeriez vous recevoir ?",
    "Qui sommes nous?": "Qui sommes nous ?",
    "Qui sommes-nous ?": "Qui sommes-nous ?",
    "Qui vous correspond": "D\xE9couvrez toutes nos opportunit\xE9s",
    "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.":
      "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.",
    Quicktip: "Quicktip",
    "Quotidien de freelance": "Quotidien de freelance",
    "RAF externalis\xE9": "RAF externalis\xE9",
    "RAF \xE0 temps partag\xE9": "RAF \xE0 temps partag\xE9",
    "Rafra\xEEchissez la page": "Rafra\xEEchissez la page",
    Rappel: "Rappel",
    "Ravis de vous revoir": "Ravis de vous (re)voir",
    "ReCAPCHA non valid": "ReCAPCHA non valid",
    "Recevez des offres adapt\xE9es \xE0 vos crit\xE8res":
      "Vous recevez uniquement des offres adapt\xE9es \xE0 vos crit\xE8res ",
    "Recevez par email les futures opportunit\xE9s selon un ou plusieurs crit\xE8res configur\xE9s.":
      "Recevez par email les futures opportunit\xE9s selon un ou plusieurs crit\xE8res configur\xE9s.",
    "Recevoir la newsletter Club Freelance.":
      "Recevoir tous les 15 jours la newsletter tech & IT de Mindquest (en anglais)",
    "Recevoir notre newsletter bimensuelle sp\xE9ciale Tech & IT (conseils pratiques, dossiers sp\xE9ciaux, s\xE9lection de missions, etc.).":
      "Recevoir notre newsletter bimensuelle sp\xE9ciale Tech & IT (conseils pratiques, dossiers sp\xE9ciaux, s\xE9lection de missions, etc.).",
    Recherche: "Recherche",
    "Recherche assit\xE9e": "Recherche assit\xE9e",
    Rechercher: "Rechercher",
    "Rechercher un article sur le blog - Mindquest Connect":
      "Rechercher un article sur le blog - Mindquest Connect",
    "Rechercher un consultant": "Rechercher un consultant",
    "Rechercher un domaine, une comp\xE9tence...":
      "Rechercher un domaine, une comp\xE9tence...",
    "Rechercher un nouveau talent": "Rechercher un consultant",
    "Rechercher un secteur d\u2019activit\xE9":
      "Rechercher un secteur d\u2019activit\xE9",
    "Rechercher un talent": "Rechercher un talent",
    "Rechercher un talent...": "Rechercher un talent...",
    "Rechercher un vivier...": "Rechercher un vivier...",
    "Rechercher une ville, un domaine, une comp\xE9tence...":
      "Rechercher une ville, un domaine, une comp\xE9tence...",
    "Rechercher...": "Rechercher...",
    "Recherchez les consultants gr\xE2ce au contenu de votre fiche de poste ou en ajoutant directement votre mots cl\xE9s IT":
      "Recherchez les consultants gr\xE2ce au contenu de votre fiche de poste ou en ajoutant directement votre mots cl\xE9s IT",
    "Recherchez un article au sein de notre s\xE9lection pour Freelance informatiques et D\xE9cideurs IT":
      "Recherchez un article au sein de notre s\xE9lection pour Talents et D\xE9cideurs IT",
    "Recherchez un article sur le blog parmi notre s\xE9lection de contenus, actualit\xE9s, conseil pratiques et ressources pour Talents et D\xE9cideurs IT.":
      "Recherchez un article sur le blog parmi notre s\xE9lection de contenus, actualit\xE9s, conseil pratiques et ressources pour Talents et D\xE9cideurs IT.",
    Recrutement: "Recrutement",
    "Recrutement & IT": "Recrutement & IT",
    "Recrutement Finance": "Recrutement Finance",
    "Recrutement IT": "Recrutement IT",
    "Recrutement finance": "Recrutement finance",
    "Recruter un consultant informatique":
      "Recruter un consultant informatique",
    "Recruter un expert en CDI": "Recruter un expert en CDI",
    "Recruter un expert externailis\xE9": "Recruter un expert externailis\xE9",
    "Recruter un expert externalis\xE9": "Recruter un expert externalis\xE9",
    "Recruter un expert financier en Freelance ou CDI - Mindquest Finance":
      "Recruter un expert financier en Freelance ou CDI - Mindquest Finance",
    "Recruter un talent CDI ou Freelance":
      "Recruter un talent CDI ou Freelance",
    "Recruter un talent CDI ou freelance":
      "Recruter un talent CDI ou freelance",
    "Recruter un talent Finance": "Recruter un talent Finance",
    "Recruter un talent Freelance IT ou CDI":
      "Recruter un talent Freelance IT ou CDI",
    "Recruter un talent IT": "Recruter un talent IT",
    "Recruter un talent IT / Digital": "Recruter un talent IT / Digital",
    "Recruteur IT": "Recruteur IT",
    "Recrutez les meilleurs talents tech & IT":
      "Recrutez les meilleurs talents tech & Finance",
    "Recuter un expert externalis\xE9": "Recuter un expert externalis\xE9",
    "References from past employers;": "References from past employers;",
    Rejeter: "Rejeter",
    "Rejeter Timesheet": "Rejeter Timesheet",
    "Rejeter le timesheet": "Rejeter le timesheet",
    Rejetée: "Rejet\xE9e",
    Rejetés: "Rejet\xE9s",
    "Rejoignez la communaut\xE9 des Freelances IT":
      "Rejoindre une communaut\xE9 de top talents",
    "Rejoignez le Club": "Rejoignez Mindquest",
    "Rejoignez le club": "Rejoignez Mindquest",
    "Rejoignez le club et trouvez<0/>votre prochaine mission freelance IT":
      "Rejoignez Mindquest et trouvez<0/>votre prochaine opportunit\xE9 IT",
    "Rejoignez le club pour recevoir<0/>des offres adapt\xE9es \xE0 vos envies.":
      "Rejoignez Mindquest pour recevoir<0/>des offres adapt\xE9es \xE0 vos envies.",
    "Rejoindre Club Freelance": "Rejoindre Mindquest",
    "Rejoindre Mindquest Connect et trouver les meilleurs id\xE9es de IT professionnel":
      "Recevez le meilleur du contenu pour consultants et d\xE9cideurs IT tous les mois ( articles, veilles, s\xE9lections de jobs ou profils IT)",
    "Rejoindre Mission Control Center et trouver les meilleurs id\xE9es de IT professionnel":
      "Rejoindre Mission Control Center et trouver les meilleurs id\xE9es de IT professionnel",
    "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte entreprise pour trouver le talent id\xE9al pour r\xE9soudre les d\xE9fis IT - Le match parfait entre entreprises et talents IT.":
      "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte entreprise pour trouver le talent id\xE9al pour r\xE9soudre les d\xE9fis IT & Finance - Le match parfait entre entreprises et talents IT.",
    "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte talent pour \xEAtre connecter aux meilleurs offres IT, en CDI ou freelance - Le match parfait entre entreprises et talents IT.":
      "Rejoindre la communaut\xE9 Mindquest - Cr\xE9er un compte talent pour \xEAtre connecter aux meilleurs offres IT, en CDI ou freelance - Le match parfait entre entreprises et talents IT.",
    "Rejoindre le club": "Rejoindre Mindquest",
    "Rejoindre le club en 3 minutes": "Rejoindre Mindquest en 3 minutes",
    "Rejoindre une communaut\xE9 Mindquest":
      "Rejoindre une communaut\xE9 Mindquest",
    "Rejoindre une communaut\xE9 de freelances":
      "Rejoindre une communaut\xE9 de top Talents",
    "Rejoindre une communaut\xE9<0/>de freelances":
      "Rejoindre une communaut\xE9 de top Talents",
    Remarques: "Remarques",
    Remote: "Remote",
    "Remote partiel": "Remote partiel",
    "Remote uniquement": "Remote uniquement",
    "Remote/Pr\xE9sentiel": "Remote/Pr\xE9sentiel",
    "Remote/R\xE9gie": "Remote/Pr\xE9sentiel",
    "Remplir la timesheet": "Remplir le timesheet",
    "Remplir le Timesheet": "Remplir le Timesheet",
    "Rencontrez Marine, experte m\xE9tier finance":
      "Rencontrez Marine, experte m\xE9tier finance",
    Renommer: "Renommer",
    "Renommer le vivier": "Renommer le vivier",
    Renvoyer: "Renvoyer",
    "Renvoyer l\u2019email de validation":
      "Renvoyer l\u2019email de validation",
    "Rercuter un expert externalis\xE9": "Rercuter un expert externalis\xE9",
    "Responsable Achat IT": "Responsable Achat IT",
    "Responsable TMA RUN": "Responsable TMA RUN",
    "Responsable communicaiton financi\xE8re":
      "Responsable communicaiton financi\xE8re",
    Ressources: "Ressources",
    "Ressources Finance": "Ressources Finance",
    "Ressources IT": "Ressources IT",
    "Ressources pour les Freelances IT": "Ressources pour les Freelances IT",
    "Ressources pour les d\xE9cideurs": "Ressources pour les d\xE9cideurs",
    "Ressources pour les pro": "Ressources pour les pro",
    "Ressources pour les talents IT": "Ressources pour les talents IT",
    "Restez connect\xE9s aux actualit\xE9s et derniers conseils pour Consultants Tech & IT - Mindquest Connect":
      "Restez connect\xE9s aux actualit\xE9s et derniers conseils pour Consultants Tech & IT - Mindquest Connect",
    "Restez connect\xE9s aux actualit\xE9s et derniers conseils pour D\xE9cideurs IT - Mindquest Connect":
      "Restez connect\xE9s aux actualit\xE9s et derniers conseils pour D\xE9cideurs IT - Mindquest Connect",
    "Restez connect\xE9s avec le secteur de Tech & IT":
      "Restez connect\xE9s avec le secteur de Tech & IT",
    "Restez connect\xE9s avec le secteur de Tech & IT - Mindquest Connect":
      "Restez connect\xE9s avec le secteur de Tech & IT - Mindquest Connect",
    Retour: "Retour",
    "Retour au dashboard": "Retour au dashboard",
    "Retour au site": "Retour au site",
    "Retour d\u2019exp\xE9rience": "Retour d\u2019exp\xE9rience",
    "Retour \xE0 la page recherche": "Retour \xE0 la page recherche missions",
    "Retourner a la mission": "Retourner a la mission",
    "Retrouver les offres recommand\xE9es pour vous":
      "Retrouver les offres recommand\xE9es pour vous",
    "Retrouver vos contrats": "Retrouver vos contrats",
    "Retrouvez des offres s\xE9lectionn\xE9s pour vous":
      "Retrouvez des offres s\xE9lectionn\xE9es pour vous",
    "Retrouvez des talents s\xE9lectionn\xE9s pour vous":
      "Retrouvez des talents s\xE9lectionn\xE9s pour vous",
    "Retrouvez les candiats s\xE9lectionn\xE9s par nos \xE9quipes":
      "Retrouvez les candiats s\xE9lectionn\xE9s par nos \xE9quipes",
    "Retrouvez les candidats s\xE9lectionn\xE9s par nos \xE9quipes":
      "Retrouvez les candidats s\xE9lectionn\xE9s par nos \xE9quipes",
    "Retrouvez les offres recommand\xE9es pour vous":
      "Retrouvez les offres recommand\xE9es pour vous",
    "Retrouvez nos actualit\xE9s, nos fiches m\xE9tiers, notre s\xE9lection d'articles pour talents et consultants IT, d\xE9cideurs et recruteurs IT et tous nos webinars et ressources pour recruter le profil ou trouver la mission informatique id\xE9al.":
      "Retrouvez nos actualit\xE9s, nos fiches m\xE9tiers, notre s\xE9lection d'articles pour talents et consultants IT, d\xE9cideurs et recruteurs IT et tous nos webinars et ressources pour recruter le profil ou trouver la mission informatique id\xE9al.",
    "Retrouvez nos offres pour consultants IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Retrouvez nos offres pour consultants IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...",
    "Retrouvez tous les replays de nos webinars pour d\xE9cideurs et consultants IT pr\xE9sent\xE9s par Mindquest et ses partenaires comme l'Expert-Comptable.":
      "Retrouvez tous les replays de nos webinars pour d\xE9cideurs et consultants IT pr\xE9sent\xE9s par Mindquest et ses partenaires comme l'Expert-Comptable.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers d'ERP & SAP.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers d'ERP & SAP.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers d'Infrastructure IT.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers d'Infrastructure IT.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers de Data & Business Intelligence.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers de Data & Business Intelligence.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers de Project Management.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers de Project Management.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers du Digital.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers du Digital.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers du D\xE9veloppement.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers sur les comp\xE9tences, missions et particularit\xE9s des m\xE9tiers du D\xE9veloppement.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers tech & IT (D\xE9veloppement, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers tech & IT (D\xE9veloppement, Infra, ERP & SAP, Project Management, Data & Business Intelligence, Digital).",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers tech & IT.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos fiches m\xE9tiers tech & IT.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, D\xE9veloppement, Intelligence Artificielle, Cybers\xE9curit\xE9, Recrutement & IT, Data & ERP, Cloud)...":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, D\xE9veloppement, Intelligence Artificielle, Cybers\xE9curit\xE9, Recrutement & IT, Data & ERP, Cloud)...",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9lections d'articles pour Webinars & Ressources.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9lections d'articles pour Webinars & Ressources.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Freelances informatiques":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur comment devenir freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et les derniers t\xE9moignagnes de nos collaborateurs.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et les derniers t\xE9moignagnes de nos collaborateurs.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers communiqu\xE9s de presse.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers communiqu\xE9s de presse.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers services...":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre les derni\xE8res actualit\xE9s de notre marque et nos derniers services...",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages dans la rubrique On parle de nous.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages dans la rubrique On parle de nous.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour conna\xEEtre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour conna\xEEtre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur comment devenir freelance sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur comment devenir freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur la comptabilit\xE9 en freelance sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur la comptabilit\xE9 en freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur le d\xE9veloppement de sa carri\xE8re en CDI ou freelance sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur le d\xE9veloppement de sa carri\xE8re en CDI ou freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur le quotidien d'un freelance sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants IT sur le quotidien d'un freelance sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur comment se financer sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur comment se financer sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur l'admin & le l\xE9gal sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur l'admin & le l\xE9gal sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur la fiscalit\xE9, les imp\xF4ts \xE0 la retraite sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur la fiscalit\xE9, les imp\xF4ts \xE0 la retraite sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur le portage salarial sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur le portage salarial sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur nos partenaires sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et freelances IT sur nos partenaires sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et talents en freelance dans l'informatique.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour consultants et talents en freelance dans l'informatique.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT (Strat\xE9gie des talents, Recruter un consultant informatique, Risques & erreurs \xE0 \xE9viter, L\xE9gal, Enjeux DSI...).":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT (Strat\xE9gie des talents, Recruter un consultant informatique, Risques & erreurs \xE0 \xE9viter, L\xE9gal, Enjeux DSI...).",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur la strat\xE9gie des talents sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur la strat\xE9gie des talents sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le l\xE9gal sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le recrutement de consultants et talents informatiques sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur le recrutement de consultants et talents informatiques sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur les enjeux DSI sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur les enjeux DSI sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur les risques et les erreurs \xE0 \xE9viter sur notre blog Mindquest Connect.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT sur les risques et les erreurs \xE0 \xE9viter sur notre blog Mindquest Connect.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour d\xE9cideurs IT.Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour connaitre nos actualit\xE9s, nos communiqu\xE9s de presse et nos t\xE9moignages.",
    "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour talents et experts de la finance \xE0 la recherche d'emploi dans la finance.":
      "Retrouvez toute l'actualit\xE9 de Mindquest et notre s\xE9lection d'articles pour talents et experts de la finance \xE0 la recherche d'emploi dans la finance.",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT":
      "Retrouvez toute l\u2019actualit\xE9 de Mindquest et nos s\xE9lections d\u2019articles pour Freelances informatiques et d\xE9cideurs IT",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT.":
      "Retrouvez toute l\u2019actualit\xE9 de Mindquest et nos s\xE9lections d\u2019articles pour freelances informatiques et d\xE9cideurs IT",
    "Retrouvez toutes les replays de nos webinars pour d\xE9cideurs et consultants IT pr\xE9sent\xE9s par Mindquest et ses partenaires comme l'Expert-Comptable.":
      "Retrouvez tous les replays de nos webinars pour d\xE9cideurs et consultants IT pr\xE9sent\xE9s par Mindquest et ses partenaires comme l'Expert-Comptable.",
    "Retrouvez toutes nos ressources et contenus pour d\xE9cideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)":
      "Retrouvez toutes nos ressources et contenus pour d\xE9cideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)",
    "Retrouvez toutes nos ressources et contenus pour d\xE9cideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).":
      "Retrouvez toutes nos ressources et contenus pour d\xE9cideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).",
    "Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)":
      "Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...)",
    "Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).":
      "Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).",
    "Retrouvez vos contrats": "Retrouvez vos contrats",
    "Revenir \xE0 mes mots cl\xE9s": "Revenir \xE0 mes mots cl\xE9s",
    Revenue: "Chiffre d'affaire",
    "Revues de Presse : DSI - Mindquest Connect":
      "Revues de Presse : DSI - Mindquest Connect",
    "Revues de presse": "Revues de presse",
    "Right to work status / citizenship;":
      "Right to work status / citizenship;",
    "Risk Manager": "Risk Manager",
    Risque: "Risque",
    "Risques & erreurs \xE0 \xE9viter": "Risques & erreurs \xE0 \xE9viter",
    Romanian: "Roumain",
    Roumain: "Roumain",
    Russe: "Russe",
    Russian: "Russe",
    "Rythme de travail": "Rythme de travail",
    "R\xE9activit\xE9 pour fournir des consultants au bon niveau pour les missions.":
      "R\xE9activit\xE9 pour fournir des consultants au bon niveau pour les missions.",
    'R\xE9activit\xE9 pour fournir des consultants au bon niveau pour les missions."':
      'R\xE9activit\xE9 pour fournir des consultants au bon niveau pour les missions."',
    "R\xE9activit\xE9 pour proposer des consultants au bon niveau pour les missions.":
      "R\xE9activit\xE9 pour proposer des consultants au bon niveau pour les missions.",
    Récence: "Date de publication",
    "R\xE9cup\xE9rez des mots-cl\xE9s IT":
      "R\xE9cup\xE9rez des mots-cl\xE9s IT",
    Référence: "Contact de R\xE9f\xE9rence",
    "R\xE9f\xE9rence du bon de commande": "R\xE9f\xE9rence du bon de commande",
    "R\xE9f\xE9rence mis \xE0 jour avec succ\xE8s":
      "R\xE9f\xE9rence mis \xE0 jour avec succ\xE8s",
    Références: "R\xE9f\xE9rences",
    Régie: "Pr\xE9sentiel",
    "R\xE9gie uniquement": "Pr\xE9sentiel uniquement",
    Réinitialiser: "R\xE9initialiser",
    "R\xE9initialiser votre mot de passe":
      "R\xE9initialisez votre mot de passe",
    "R\xE9initialisez votre mot de passe":
      "R\xE9initialisez votre mot de passe",
    "R\xE9seaux sociaux": "R\xE9seaux sociaux",
    "S'abonner": "S'abonner",
    "S'abonner \xE0 notre newsletter IT": "S'abonner \xE0 notre newsletter",
    "S'inscrire": "S'inscrire",
    "S1:": "S1:",
    "S2:": "S2:",
    SAP: "SAP",
    "SAP SD MM Project Manager": "SAP SD MM Project Manager",
    SASU: "SASU",
    SMS: "SMS",
    "Saisir le mot de passe actuel": "Saisir le mot de passe actuel",
    "Saisir votre mot de passe": "Saisir votre mot de passe",
    "Saisissez le mot de passe actuel": "Saisissez le mot de passe actuel",
    Salaire: "Salaire",
    "Salaire Annuel": "Salaire Annuel",
    "Salaire annuel": "Salaire annuel",
    Salarié: "Salari\xE9",
    "Salari\xE9s Finance": "Salari\xE9s Finance",
    "Salut {firstName} {lastName}": function(a) {
      return ["Salut ", a("firstName"), " ", a("lastName")];
    },
    "Sans  pr\xE9judice  de  ce  qui  pr\xE9c\xE8de,  la  Soci\xE9t\xE9  demeure  en droit  de  solliciter  des  dommages int\xE9r\xEAts.":
      "Sans  pr\xE9judice  de  ce  qui  pr\xE9c\xE8de,  la  Soci\xE9t\xE9  demeure  en droit  de  solliciter  des  dommages int\xE9r\xEAts.",
    "Sans pr\xE9judice de ce qui pr\xE9c\xE8de, la Soci\xE9t\xE9 demeure en droit de solliciter des dommages int\xE9r\xEAts.":
      "Sans pr\xE9judice de ce qui pr\xE9c\xE8de, la Soci\xE9t\xE9 demeure en droit de solliciter des dommages int\xE9r\xEAts.",
    "Sans pr\xE9judice de ce qui pr\xE9c\xE8de, l\u2019Utilisateur est inform\xE9 qu\u2019il demeure libre de ses choix et qu\u2019en tout \xE9tat de cause, la Soci\xE9t\xE9 ne saurait assumer aucun risque en ce qui le concerne.":
      "Sans pr\xE9judice de ce qui pr\xE9c\xE8de, l\u2019Utilisateur est inform\xE9 qu\u2019il demeure libre de ses choix et qu\u2019en tout \xE9tat de cause, la Soci\xE9t\xE9 ne saurait assumer aucun risque en ce qui le concerne.",
    "Satisfaction surveys;": "Les enqu\xEAtes de satisfaction;",
    Sauvegarder: "Sauvegarder",
    "Score Smatch": "Score Smatch",
    "Se connecter": "Se connecter",
    "Se d\xE9connecter": "Se d\xE9connecter",
    "Se financer": "Se financer",
    "Se souvenir de moi": "Se souvenir de moi",
    "Search for a blog post from our selection of content, news, practical advice and resources for IT Talent and Decision Makers.":
      "Search for a blog post from our selection of content, news, practical advice and resources for IT Talent and Decision Makers.",
    "Search for all our articles and resources":
      "Rechercher des articles, des vid\xE9os et des ressources",
    Secteur: "Secteur",
    "Secteur d\u2019activit\xE9": "Secteur d\u2019activit\xE9",
    "Selected third parties including:": "Selected third parties including:",
    "Sending of e-mails": "Envoi de courriels",
    "Sends emails": "Envoie de courriels",
    Senior: "Senior",
    "Senior (7 - 10)": "Senior (7 - 10)",
    "Senior (7-10)": "Senior (7-10)",
    "Service  providers:  provision  of  services  on  behalf  of  the  Company  (for  example: maintenance of the website, host, system management and security);":
      "Prestataires :  r\xE9alisation  de  prestations  de  services  pour  le  compte  de  la  Soci\xE9t\xE9  (par exemple : maintenance du site internet, h\xE9bergeur, gestion et s\xE9curit\xE9 des syst\xE8mes);",
    "Service parfait.": "Service parfait.",
    "Service providers: provision of services on behalf of the Company (for example: maintenance of the website, host, system management and security);":
      "Prestataires :  r\xE9alisation  de  prestations  de  services  pour  le  compte  de  la  Soci\xE9t\xE9  (par exemple : maintenance du site internet, h\xE9bergeur, gestion et s\xE9curit\xE9 des syst\xE8mes) ;",
    'Service tr\xE8s bon"': 'Service tr\xE8s bon"',
    "Service tr\xE8s bon.": "Service tr\xE8s bon.",
    Services: "Services",
    "Services et offres": "Services et offres",
    "Services pour les entreprises": "Services pour les entreprises",
    "Services pour les talents Finance": "Services pour les talents Finance",
    "Services pour les talents IT": "Services pour les talents IT",
    "Session cookies: are stored on your computer and are deleted when you leave your browser;":
      "Cookies  de  session :  sont  enregistr\xE9s  sur  votre  ordinateur  et  sont  supprim\xE9s lorsque vous quittez votre navigateur;",
    "Sex/Gender;": "Sex/Gender;",
    "Short sci-fi stories posted every month to promote an open debate on the future of tech.":
      "Short sci-fi stories posted every month to promote an open debate on the future of tech.",
    "Si nous ne sommes pas en mesure de vous accompagner dans cette recherche, vous serez inform\xE9s par email.":
      "Si nous ne sommes pas en mesure de vous accompagner dans cette recherche, vous serez inform\xE9 par email.",
    "Si vous avez travaill\xE9 \xE0 un taux major\xE9 (astreintes, heures suppl\xE9mentaires major\xE9es...), merci d'utiliser l'espace heures et astreintes.":
      "Si vous avez travaill\xE9 \xE0 un taux major\xE9 (astreintes, heures suppl\xE9mentaires major\xE9es...), merci d'utiliser l'espace heures et astreintes.",
    "Si vous n\u2019\xEAtes pas redirig\xE9 dans 3mn, merci de nous contacter":
      "Si vous n\u2019\xEAtes pas redirig\xE9 dans 3mn, merci de nous contacter",
    "Si vous \xEAtes interress\xE9 par ce profil ou un candidat similaire, nous vous invitons \xE0 soumettre une nouvelle offre ou \xE0 nous contacter":
      "Si vous \xEAtes interress\xE9 par ce profil ou un candidat similaire, nous vous invitons \xE0 soumettre une nouvelle offre ou \xE0 nous contacter",
    "Signataire principal": "Signataire principal",
    Signataires: "Signataires",
    "Signataires en attente": "Signataires en attente",
    "Signataires secondaires": "Signataires secondaires",
    Signatures: "Signatures",
    Signé: "Sign\xE9",
    "Sinon cliquez sur continuer.": "Sinon cliquez sur continuer.",
    "Sit back and relax. We're here to help you build the dream team for business success.":
      "Sit back and relax. We're here to help you build the dream team for business success.",
    "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.":
      "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.",
    "Site analysis cookie": "Cookie d\u2019analyse de site",
    "Si\xE8ge social : 2 rue Kellermann - 59100 Roubaix, France":
      "Si\xE8ge social : 2 rue Kellermann - 59100 Roubaix, France",
    "Soci\xE9t\xE9 OVH": "Soci\xE9t\xE9 OVH",
    "Some personal data are required, while others are optional, to access or benefit from certain products and services of the Company.":
      "Certaines  donn\xE9es  personnelles  sont  obligatoires,  d\u2019autres  facultatives,  pour  acc\xE9der  ou b\xE9n\xE9ficier de certains produits et services de la Soci\xE9t\xE9.",
    "Souhaitez-vous ouvrir le vivier aux membres de votre \xE9quipe ?":
      "Souhaitez-vous ouvrir le vivier aux membres de votre \xE9quipe ?",
    "Souhaitez-vous ouvrir le vivier \xE0 vos collaborateurs ?":
      "Souhaitez-vous ouvrir le vivier \xE0 vos collaborateurs ?",
    "Souhaitez-vous ouvrir le vivier \xE0 vos collaborateurs ? ":
      "Souhaitez-vous ouvrir le vivier \xE0 vos collaborateurs ? ",
    Soumettre: "Soumettre",
    "Soumettre au client": "Soumettre au client",
    "Soumettre un besoin en recrutement": "Soumettre un besoin en recrutement",
    "Soumettre un besoin en recrutement IT":
      "Soumettre un besoin en recrutement IT",
    "Sourcing Hub": "Sourcing Hub",
    "Sourcing Manager": "Sourcing Manager",
    "Soyez aussi complet que possible. La mise en valeur de vos comp\xE9tences et de votre profil augmentera vos chances de rencontrer l\u2019emploi de vos r\xEAves":
      "Soyez aussi complet que possible. La mise en valeur de vos comp\xE9tences et de votre profil augmentera vos chances de rencontrer l\u2019emploi de vos r\xEAves",
    "Soyez recontact\xE9 par l'un de nos expert en recrutement : un expert Mindquest vous appellera pour \xE9changer sur votre besoin et vous accompagner dans la cr\xE9ation de votre compte entreprise.":
      "Soyez recontact\xE9 par l'un de nos expert en recrutement : un expert Mindquest vous appellera pour \xE9changer sur votre besoin et vous accompagner dans la cr\xE9ation de votre compte entreprise.",
    Spanish: "Espagnol",
    Spécialité: "M\xE9tier",
    Spécialités: "Sp\xE9cialit\xE9s",
    Statistiques: "Statistiques",
    "Stay connected to the latest news and tips for Tech & IT Consultants - Mindquest Connect":
      "Stay connected to the latest news and tips for Tech & IT Consultants - Mindquest Connect",
    "Stay connected with the Tech & IT sector - Mindquest Connect":
      "Stay connected with the Tech & IT sector - Mindquest Connect",
    "Stay tuned to the latest news and tips for IT Decision Makers - Mindquest Connect":
      "Stay tuned to the latest news and tips for IT Decision Makers - Mindquest Connect",
    "Strat\xE9gie des talents": "Strat\xE9gie des talents",
    "Strat\xE9gie et management": "Strat\xE9gie et management",
    Subscribe: "Je m\u2019abonne",
    "Subscribe to our IT newsletter": "Restez connect\xE9 \xE0 Mindquest",
    "Subscribe to our weekly newsletter to get the latest articles and technology news":
      "Inscrivez-vous et recevez tous les articles",
    "Subsidiaries: for more details on their location, click on this <0>link</0> .":
      "Filiales : pour plus de d\xE9tails sur leur localisation cliquer sur ce <0>lien</0>.",
    "Subsidiaries: for more details on their location, click on this link.":
      "Filiales : pour plus de d\xE9tails sur leur localisation cliquer sur ce lien.",
    "Success Stories": "T\xE9moignages",
    "Successful professional matches":
      "Placements r\xE9alis\xE9s avec succ\xE8s",
    Succès: "Succ\xE8s",
    Suggérer: "Sugg\xE9rer",
    "Sugg\xE9rer une fonctionnalit\xE9": "Sugg\xE9rer une fonctionnalit\xE9",
    "Suite \xE0 la validation du Timesheet, le compte-rendu de mois {m}/{y} est disponible": function(
      a
    ) {
      return [
        "Suite \xE0 la validation du Timesheet, le compte-rendu de mois ",
        a("m"),
        "/",
        a("y"),
        " est disponible"
      ];
    },
    "Suite \xE0 la validation du Timesheet, le compte-rendu de mois {m}/{y} pour le contrat {pID} est disponible": function(
      a
    ) {
      return [
        "Suite \xE0 la validation du Timesheet, le compte-rendu de mois ",
        a("m"),
        "/",
        a("y"),
        " pour le contrat ",
        a("pID"),
        " est disponible"
      ];
    },
    "Suivez toutes vos candidatures": "Suivez toutes vos candidatures",
    "Suivez toutes vos missions": "Suivez tous vos recrutements IT",
    "Suivez vos candidatures dans votre espace personel":
      "Vous suivez vos candidatures dans votre espace personnel",
    "Suivre les derni\xE8res infos Mindquest":
      "Suivre les derni\xE8res infos Mindquest",
    "Suivre les derni\xE8res infos et avantages Mindquest":
      "Suivre les derni\xE8res infos et avantages Mindquest",
    "Suivre les newsletters Mindquest": "Suivre les newsletters Mindquest",
    Supplémentaire: "Suppl\xE9mentaire",
    Support: "Support",
    "Suppression du compte ou de vos donn\xE9es":
      "Suppression du compte ou de vos donn\xE9es",
    "Suppression en cours": "Suppression en cours",
    Supprimer: "Supprimer",
    "Supprimer la plage de jours": "Supprimer la plage de jours",
    "Supprimer la plage de jours/heures": "Supprimer la plage de jours/heures",
    "Supprimer le profil et vos donn\xE9es personnelles":
      "Supprimer le profil et vos donn\xE9es personnelles",
    "Supprimer le projet": "Supprimer le projet",
    "Supprimer le th\xE8me": "Supprimer le th\xE8me",
    "Supprimer le vivier": "Supprimer le vivier",
    "Supprimer mon compte et mes donn\xE9es":
      "Supprimer mon compte et mes donn\xE9es",
    "Supprimer uniquement votre profil Mindquest":
      "Supprimer uniquement votre profil Mindquest",
    "Sur quoi avez-vous travaill\xE9 ?": "Sur quoi avez-vous travaill\xE9 ?",
    "Sur un profil pseudonymis\xE9, le pr\xE9nom et votre photo sont les uniques \xE9l\xE9ments visibles<0/>sur votre identit\xE9":
      "Sur un profil pseudonymis\xE9, le pr\xE9nom et votre photo sont les uniques \xE9l\xE9ments visibles<0/>sur votre identit\xE9",
    "Surnames, forenames, gender, professional e-mail address;":
      "Noms, pr\xE9noms, sexe, adresse \xE9lectronique professionnelle;",
    "Syst\xE8mes Embarqu\xE9s": "Syst\xE8mes Embarqu\xE9s",
    "S\xE9curit\xE9 des Syst\xE8mes d'Information":
      "S\xE9curit\xE9 des Syst\xE8mes d'Information",
    "S\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil. Accompagnement personnalis\xE9. Paiements \xE0 30 jours. Pour tous les Freelances du digital, de la tech et de l\u2019IT":
      "S\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil. Accompagnement personnalis\xE9. Paiements \xE0 30 jours. Pour tous les talents du digital, de la tech et de l\u2019IT",
    Sélectionner: "S\xE9lectionner",
    "S\xE9lectionner depuis la liste ou utiliser la detection automatique":
      "S\xE9lectionner depuis la liste ou utiliser la d\xE9tection automatique",
    "S\xE9lectionner des certifications": "S\xE9lectionner des certifications",
    "S\xE9lectionner des comp\xE9tences": "S\xE9lectionner des comp\xE9tences",
    "S\xE9lectionner des domaines": "S\xE9lectionner des domaines",
    "S\xE9lectionner des langues": "S\xE9lectionner des langues",
    "S\xE9lectionner des secteurs": "S\xE9lectionner des secteurs",
    "S\xE9lectionner des sp\xE9cialit\xE9s": "S\xE9lectionner des m\xE9tiers",
    "S\xE9lectionner l'exp\xE9rience demand\xE9e":
      "S\xE9lectionner l'exp\xE9rience demand\xE9e",
    "S\xE9lectionner la Langue par d\xE9fault de votre compte":
      "S\xE9lectionner la Langue par d\xE9fault de votre compte",
    "S\xE9lectionner un projet": "S\xE9lectionner un projet",
    "S\xE9lectionner un secteur": "S\xE9lectionner un secteur",
    "S\xE9lectionner un th\xE8me": "S\xE9lectionner un th\xE8me",
    "S\xE9lectionnez la zone avant d'ajouter les mots-cl\xE9s":
      "S\xE9lectionnez la zone avant d'ajouter les mots-cl\xE9s",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV":
      "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser":
      "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser.":
      "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser.",
    "S\u2019abonner \xE0 la newsletter": "S\u2019abonner \xE0 la newsletter",
    "S\u2019abonner \xE0 la newsletter des experts Finance":
      "S\u2019abonner \xE0 la newsletter des experts Finance",
    "TOP 10 experts' articles": "TOP 10 experts' articles",
    "TOP 10 experts' articles -  Mindquest Connect":
      "TOP 10 experts' articles -  Mindquest Connect",
    Taches: "Taches",
    "Taille de l\u2019entreprise": "Taille de l\u2019entreprise",
    "Taille maximale du fichier \xE0 t\xE9l\xE9charger":
      "Taille maximale du fichier \xE0 t\xE9l\xE9charger",
    Talent: "Talent",
    "Talent Community Specialist": "Talent Community Specialist",
    Talents: "Talents",
    "Talents recommand\xE9s": "Talents recommand\xE9s",
    "Taux journalier moyen": "Taux journalier moyen",
    "Tech Magazine": "Tech Magazine",
    "Tech and pop culture merge for a quick break from work at any time of the day.":
      "Tech and pop culture merge for a quick break from work at any time of the day.",
    "Tech company": "Tech company",
    "Tech magazine": "Tech magazine",
    "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;":
      "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;",
    "Technologies Microsoft": "Technologies Microsoft",
    "Temps partiel": "Temps partiel",
    "Temps plein": "Temps plein",
    "Test & Assurance Qualit\xE9": "Test & Assurance Qualit\xE9",
    'The  Company  and  its  providers  use cookies  to  improve  your  digital  experience  and  ensure better navigation security. You can accept or refuse these different operations at any time by clicking on "accept" or "refuse" in the cookie configuration panel.':
      'Club Freelance et ses prestataires utilisent des cookies aux fins d\u2019am\xE9liorer votre exp\xE9rience digitale et d\'assurer une meilleure s\xE9curit\xE9 de navigation. Vous pouvez accepter ou refuser \xE0 tout  moment  ces  diff\xE9rentes  op\xE9rations  en  cliquant  sur  "accepter"  ou  "refuser"  au  sein  du bandeau de configuration des cookies.',
    "The  Company  may  share  personal  data  collected  for  the  purposes  indicated  below  with  the following recipients:":
      "La  Soci\xE9t\xE9  est  susceptible  de  partager  les  Donn\xE9es  \xE0  caract\xE8re  personnel  collect\xE9es  pour  les finalit\xE9s indiqu\xE9es ci-dessous avec les destinataires suivants:",
    'The Company and its providers use cookies to improve your digital experience and ensure better navigation security. You can accept or refuse these different operations at any time by clicking on "accept" or "refuse" in the cookie configuration panel.':
      'Club Freelance et ses prestataires utilisent des cookies aux fins d\u2019am\xE9liorer votre exp\xE9rience digitale et d\'assurer une meilleure s\xE9curit\xE9 de navigation. Vous pouvez accepter ou refuser \xE0 tout  moment  ces  diff\xE9rentes  op\xE9rations  en  cliquant  sur  "accepter"  ou  "refuser"  au  sein  du bandeau de configuration des cookies',
    "The Company can offer you advertising content that can be based on your interests.":
      "Club Freelance peut vous proposer du contenu publicitaire pouvant bas\xE9 sur vos centres d\u2019int\xE9r\xEAts.",
    "The Company may share personal data collected for the purposes indicated below with the following recipients:":
      "La  Soci\xE9t\xE9  est  susceptible  de  partager  les  Donn\xE9es  \xE0  caract\xE8re  personnel  collect\xE9es  pour  les finalit\xE9s indiqu\xE9es ci-dessous avec les destinataires suivants:",
    "The Company reserves the right to make any amendment to this Personal Data Policy at any time in accordance with this clause.":
      "La Soci\xE9t\xE9 se r\xE9serve le droit d\u2019apporter toute modification \xE0 la pr\xE9sente Politique des donn\xE9es \xE0 caract\xE8re personnel \xE0 tout moment, conform\xE9ment \xE0 la pr\xE9sente clause.",
    "The Company therefore invites you to consult this page regularly.":
      "La Soci\xE9t\xE9 vous invite donc \xE0 consulter cette page r\xE9guli\xE8rement.",
    "The Company, as the Controller, requests and collects personal data concerning the individual concerned  when  the  latter wishes  to  acquire  the  products  and/or  services  offered  by  the Company.":
      "La Soci\xE9t\xE9, en tant que Responsable de traitement, demande et collecte les Donn\xE9es \xE0 caract\xE8re personnel  concernant  la  Personne  concern\xE9e  lorsque  celle-ci  souhaite  acqu\xE9rir  les  produits et/ou services propos\xE9s par la Soci\xE9t\xE9.",
    "The Company, as the Controller, requests and collects personal data concerning the individual concerned when the latter wishes to acquire the products and/or services offered by the Company.":
      "La Soci\xE9t\xE9, en tant que Responsable de traitement, demande et collecte les Donn\xE9es \xE0 caract\xE8re personnel  concernant  la  Personne  concern\xE9e  lorsque  celle-ci  souhaite  acqu\xE9rir  les  produits et/ou services propos\xE9s par la Soci\xE9t\xE9.",
    "The Company\u2019s communication campaigns;":
      "Les campagnes de communication de la Soci\xE9t\xE9;",
    "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.":
      "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.",
    "The main purpose of collecting, storing and processing this information and personal data is to:":
      "La collecte, le stockage et le traitement de ces informations et Donn\xE9es \xE0 caract\xE8re personnel ont principalement pour finalit\xE9:",
    "The manner in which the Company processes personal data, as defined below, which it collects  and  which  the  data  subjects,  as  defined  below, provide  with  their  consent  to enable the supply of, inter alia, the Company\u2019s products and services;":
      "La mani\xE8re dont la Soci\xE9t\xE9 traite les Donn\xE9es \xE0 caract\xE8re personnel, telles que d\xE9finies ci-apr\xE8s, qu\u2019elle collecte et que les Personnes concern\xE9es, telles que d\xE9finies ci-apr\xE8s, fournissent avec leur consentement pour permettre la fourniture des produits et services de la Soci\xE9t\xE9 notamment;",
    "The manner in which the Company processes personal data, as defined below, which it collects and which the data subjects, as defined below, provide with their consent to enable the supply of, inter alia, the Company\u2019s products and services;":
      "La mani\xE8re dont la Soci\xE9t\xE9 traite les Donn\xE9es \xE0 caract\xE8re personnel, telles que d\xE9finies ci-apr\xE8s, qu\u2019elle collecte et que les Personnes concern\xE9es, telles que d\xE9finies ci-apr\xE8s, fournissent avec leur consentement pour permettre la fourniture des produits et services de la Soci\xE9t\xE9 notamment;",
    "The name of the processor, as defined below;":
      "Le nom du Responsable de traitement, tel que d\xE9fini ci-apr\xE8s;",
    "The person concerned has given his or her consent;":
      "La Personne concern\xE9e a donn\xE9 son consentement;",
    "The processing is in pursuit of the legitimate interests of the controller.":
      "Le traitement r\xE9pond \xE0 la poursuite d\u2019int\xE9r\xEAts l\xE9gitimes du Responsable de traitement.",
    "The purpose of this policy is to present to data subjects:":
      "Cette Politique a pour but de pr\xE9senter aux personnes concern\xE9es:",
    "The right of access to the personal data Club Freelance processes on you:":
      "The right of access to the personal data Club Freelance processes on you:",
    "The right to complain": "The right to complain",
    "The right to data portability in certain circumstances:":
      "The right to data portability in certain circumstances:",
    "The right to erasure of your personal data in certain circumstances:":
      "The right to erasure of your personal data in certain circumstances:",
    "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:":
      "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:",
    "The right to rectification of your personal data:":
      "The right to rectification of your personal data:",
    "The right to withdraw consent:": "The right to withdraw consent:",
    "The rights of data subjects, as defined below;":
      "Les droits des Personnes concern\xE9es, telles que d\xE9finies ci-apr\xE8s;",
    "The site may occasionally contain links to the websites of our partners or third companies.":
      "Le Site peut occasionnellement contenir des liens vers les sites internet de nos partenaires ou de soci\xE9t\xE9s tierces.",
    "Their IT and human expertise led to the birth of Club Freelance. It can also help you.":
      "Their IT and human expertise led to the birth of Club Freelance. It can also help you.",
    "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:":
      "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:",
    "These  elements  can  be  grouped  into  two  categories  according  to  the  data  subjects, namely Talents and Clients.":
      "Ces \xE9l\xE9ments peuvent \xEAtre regroup\xE9s en deux cat\xE9gories selon les personnes concern\xE9es \xE0 savoir d\u2019une part les Talents et d\u2019autre part les Clients.",
    "These companies trust us with their IT talent needs":
      "Ils ont acc\xE9l\xE9r\xE9 leurs projets IT gr\xE2ce \xE0 Mindquest et sa communaut\xE9",
    "These cookies allow us to assess the performance of the site and measure the audience. They also make it possible to find out which pages the users visit on our website. This information is collected by these cookies in anonymised form.":
      "Ces cookies nous permettent d\u2019\xE9valuer les performances du site et de mesurer l\u2019audience. Ils permettent  \xE9galement  de  savoir  quelles  pages  les  utilisateurs  visitent  sur  notre  site.  Ces informations sont collect\xE9es par ces cookies sous formes anonymis\xE9es.",
    "These cookies are essential for the functioning of the site. In accordance with the applicable law, cookies strictly necessary for the operation of the website are exempted from collecting users\u2019 consent.":
      "Ces cookies sont essentiels au fonctionnement du site. Conform\xE9ment \xE0 la l\xE9gislation fran\xE7aise, les cookies strictement n\xE9cessaires au fonctionnement du site internet sont exempt\xE9s du recueil du consentement des utilisateurs.",
    "These elements can be grouped into two categories according to the data subjects, namely Talents and Clients.":
      "Ces \xE9l\xE9ments peuvent \xEAtre regroup\xE9s en deux cat\xE9gories selon les personnes concern\xE9es \xE0 savoir d\u2019une part les Talents et d\u2019autre part les Clients.",
    "Third  party  cookies:  cookies  created  and  used  on  the  website  by  external providers.":
      "Cookies tiers: cookies cr\xE9es et utilis\xE9s sur le site internet par des fournisseurs externes.",
    "Third party cookies: cookies created and used on the website by external providers.":
      "Cookies tiers : cookies cr\xE9es et utilis\xE9s sur le site internet par des fournisseurs externes.",
    "Third-party links": "Third-party links",
    "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.":
      "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.",
    "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.":
      "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.",
    "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).":
      "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).",
    "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.":
      "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.",
    "This information is necessary for the Company to perform the services properly and to enable it to comply with its legal obligations.":
      "Ces informations sont n\xE9cessaires \xE0 la Soci\xE9t\xE9 pour la bonne ex\xE9cution des prestations et pour lui permettre de respecter ses obligations l\xE9gales.",
    "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.":
      "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.",
    "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.":
      "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.",
    "Thus, the collection and processing of personal data carried out by the Company in the course of  the  operation  of  its  business  and  use  of  the  goods  and  services, including  the  website operated   by   the   Company   at<0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019),   are   governed   by this confidentiality policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).":
      "Ainsi,  la  collecte  et  le  traitement  des  donn\xE9es  \xE0  caract\xE8re  personnel  effectu\xE9s  par  la  Soci\xE9t\xE9 dans le cadre de l\u2019exploitation de son activit\xE9 et de l\u2019utilisation des produits et services en ce compris le site Internet que la Soci\xE9t\xE9 exploite \xE0 l\u2019adresse Cocoa Studios, Unit 401, The Biscuit Factory,  100  Drummond  Road,  London,  London,  United  Kingdom,  SE16  4DG  (le  \xAB <1>Site</1> \xBB) sont r\xE9gis par la pr\xE9sente politique de confidentialit\xE9 (ci-apr\xE8s d\xE9sign\xE9e la \xAB <2>Politique<2> \xBB).",
    "Thus, the collection and processing of personal data carried out by the Company in the course of the operation of its business and use of the goods and services, including the website operated by the Company at<0>https://mindquest.io</0> (\u2018the <1>Site</1> \u2019), are governed by this confidentiality policy (hereinafter referred to as \u2018the <2>Policy</2>\u2019).":
      "Ainsi,  la  collecte  et  le  traitement  des  donn\xE9es  \xE0  caract\xE8re  personnel  effectu\xE9s  par  la  Soci\xE9t\xE9 dans le cadre de l\u2019exploitation de son activit\xE9 et de l\u2019utilisation des produits et services en ce compris le site Internet que la Soci\xE9t\xE9 exploite \xE0 l\u2019adresse Cocoa Studios, Unit 401, The Biscuit Factory,  100  Drummond  Road,  London,  London,  United  Kingdom,  SE16  4DG  (le  \xAB <1>Site</1> \xBB) sont r\xE9gis par la pr\xE9sente politique de confidentialit\xE9 (ci-apr\xE8s d\xE9sign\xE9e la \xAB <2>Politique</2> \xBB). ",
    Thème: "Th\xE8me",
    "Time limit to respond": "Time limit to respond",
    Timesheet: "Timesheet",
    "Timesheet Accept\xE9": "Timesheet Accept\xE9",
    "Timesheet Ouvert": "Timesheet Ouvert",
    "Timesheet Rejet\xE9": "Timesheet Rejet\xE9",
    "Timesheet Soumis": "Timesheet Soumis",
    "Timesheet enregistr\xE9": "Timesheet enregistr\xE9",
    "Timesheet envoy\xE9": "Timesheet envoy\xE9",
    "Timesheet envoy\xE9 au client": "Timesheet envoy\xE9 au client",
    "Timesheet rejet\xE9e": "Timesheet rejet\xE9",
    "Timesheet valid\xE9": "Timesheet valid\xE9",
    "Timesheet vide": "Timesheet vide",
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires": function(
      a
    ) {
      return [
        "Timesheet ",
        a("m"),
        "/",
        a("y"),
        " du contrat num\xE9ro: ",
        a("pID"),
        " a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires"
      ];
    },
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "Timesheet ",
        a("m"),
        "/",
        a("y"),
        " du contrat num\xE9ro: ",
        a("pID"),
        " a \xE9t\xE9 valid\xE9 par le client"
      ];
    },
    Timesheets: "Timesheets",
    "Tips & Tricks": "Tips & Tricks",
    "Tips for your daily life as a freelancer":
      "Tips for your daily life as a freelancer",
    "Tips for your daily life as a freelancer - Mindquest Connect":
      "Tips for your daily life as a freelancer - Mindquest Connect",
    Titre: "Intitul\xE9 de poste",
    "To answer any questions you have about a job or the job market;":
      "To answer any questions you have about a job or the job market;",
    "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;":
      "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;",
    "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);":
      "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);",
    "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;":
      "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;",
    "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;":
      "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;",
    "To contact you about candidates for jobs with whom Club Freelance has a relationship;":
      "To contact you about candidates for jobs with whom Club Freelance has a relationship;",
    "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;":
      "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;",
    "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.":
      "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.",
    "To enable the Company to pursue the processing purposes specified herein;":
      "Pour  permettre  \xE0  la  Soci\xE9t\xE9  de  poursuivre  les  finalit\xE9s  de  traitement  pr\xE9cis\xE9es  aux pr\xE9sentes;",
    "To establish, exercise or defend any legal claims.":
      "To establish, exercise or defend any legal claims.",
    "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.":
      "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.",
    "To fill an open vacancy at your company;":
      "To fill an open vacancy at your company;",
    "To fulfil contractual obligations;":
      "Pour remplir des obligations contractuelles;",
    "To fulfill any aspect your company\u2019s contract with Club Freelance;":
      "To fulfill any aspect your company\u2019s contract with Club Freelance;",
    "To help find you a job;": "To help find you a job;",
    "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.":
      "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.",
    "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;":
      "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;",
    "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);":
      "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);",
    "To provide you or your company with information about the job market;":
      "To provide you or your company with information about the job market;",
    "To provide you with information about the job market;":
      "To provide you with information about the job market;",
    "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;":
      "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;",
    "To set cookies on your browser, you will find below the browsers:":
      "Pour param\xE9trer les cookies sur votre navigateur, vous trouverez ci-dessous les navigateurs:",
    "Today Web UI Kit is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book\u2026":
      "Today Web UI Kit is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book\u2026",
    "Top Ten": "Top Ten",
    Total: "Total",
    "Toujours \xE0 l'\xE9coute des besoins et professionnel.":
      "Toujours \xE0 l'\xE9coute des besoins et professionnel.",
    Tous: "Tous",
    "Tous  les  frais  support\xE9s  par  l'Utilisateur  pour  acc\xE9der  au  service  (mat\xE9riel  informatique, logiciels, connexion Internet, etc.) sont \xE0 sa charge. La Soci\xE9t\xE9 ne prend pas en charge ces co\xFBts d\u2019acc\xE8s.":
      "Tous  les  frais  support\xE9s  par  l'Utilisateur  pour  acc\xE9der  au  service  (mat\xE9riel  informatique, logiciels, connexion Internet, etc.) sont \xE0 sa charge. La Soci\xE9t\xE9 ne prend pas en charge ces co\xFBts d\u2019acc\xE8s.",
    "Tous droits r\xE9serv\xE9s Club Freelance Limited - soci\xE9te de recrutement sp\xE9cialis\xE9e dans l'informatique.":
      "Tous droits r\xE9serv\xE9s Club Freelance Limited - soci\xE9te de recrutement sp\xE9cialis\xE9e dans l'informatique.",
    "Tous les frais support\xE9s par l'Utilisateur pour acc\xE9der au service (mat\xE9riel informatique, logiciels, connexion Internet, etc.) sont \xE0 sa charge. La Soci\xE9t\xE9 ne prend pas en charge ces co\xFBts d\u2019acc\xE8s.":
      "Tous les frais support\xE9s par l'Utilisateur pour acc\xE9der au service (mat\xE9riel informatique, logiciels, connexion Internet, etc.) sont \xE0 sa charge. La Soci\xE9t\xE9 ne prend pas en charge ces co\xFBts d\u2019acc\xE8s.",
    "Tous les projets": "Tous les projets",
    "Tous les talents": "Tous les talents",
    "Tous les th\xE8mes": "Tous les th\xE8mes",
    "Tous les types d'heures": "Tous les types d'heures",
    "Tous nos conseils pour les freelances.":
      "Tous nos conseils pour les Talents IT",
    "Tous vos collaborateurs": "Tous vos collaborateurs",
    "Tous vos projets": "Tous vos projets",
    "Tous vos th\xE8mes": "Tous vos th\xE8mes",
    "Toutefois, en cas de d\xE9faut dans la s\xE9curit\xE9 des donn\xE9es et de la Plateforme, la Soci\xE9t\xE9 ne saurait \xEAtre  tenue  \xE0  aucun  dommage-int\xE9r\xEAt  ou  r\xE9paration  \xE0 l\u2019\xE9gard  de  quiconque ;  ce  que  les Utilisateurs reconnaissent et acceptent.":
      "Toutefois, en cas de d\xE9faut dans la s\xE9curit\xE9 des donn\xE9es et de la Plateforme, la Soci\xE9t\xE9 ne saurait \xEAtre  tenue  \xE0  aucun  dommage-int\xE9r\xEAt  ou  r\xE9paration  \xE0 l\u2019\xE9gard  de  quiconque ;  ce  que  les Utilisateurs reconnaissent et acceptent.",
    "Toutefois, en cas de d\xE9faut dans la s\xE9curit\xE9 des donn\xE9es et de la Plateforme, la Soci\xE9t\xE9 ne saurait \xEAtre tenue \xE0 aucun dommage-int\xE9r\xEAt ou r\xE9paration \xE0 l\u2019\xE9gard de quiconque ; ce que les Utilisateurs reconnaissent et acceptent.":
      "Toutefois, en cas de d\xE9faut dans la s\xE9curit\xE9 des donn\xE9es et de la Plateforme, la Soci\xE9t\xE9 ne saurait \xEAtre tenue \xE0 aucun dommage-int\xE9r\xEAt ou r\xE9paration \xE0 l\u2019\xE9gard de quiconque ; ce que les Utilisateurs reconnaissent et acceptent.",
    "Toutefois, la Soci\xE9t\xE9 se r\xE9serve le droit, sans pr\xE9avis, ni indemnit\xE9, de fermer temporairement ou d\xE9finitivement la Plateforme notamment pour effectuer une mise \xE0 jour, des op\xE9rations de maintenance, des modifications ou changements sur les m\xE9thodes op\xE9rationnelles, les serveurs et les heures d\u2019accessibilit\xE9, sans que cette liste ne soit limitative.":
      "Toutefois, la Soci\xE9t\xE9 se r\xE9serve le droit, sans pr\xE9avis, ni indemnit\xE9, de fermer temporairement ou d\xE9finitivement la Plateforme notamment pour effectuer une mise \xE0 jour, des op\xE9rations de maintenance, des modifications ou changements sur les m\xE9thodes op\xE9rationnelles, les serveurs et les heures d\u2019accessibilit\xE9, sans que cette liste ne soit limitative.",
    "Toutes les offres archiv\xE9es": "Toutes les offres archiv\xE9es",
    "Toutes les offres en cours": "Toutes les offres en cours",
    "Toutes nos missions": "Toutes nos missions",
    Travaillées: "Travaill\xE9es",
    Trier: "Trier",
    "Triez les comp\xE9tances par ordre d\u2019importance":
      "Triez les comp\xE9tences par ordre d\u2019importance",
    "Trouver un consultant IT": "Trouver un consultant IT",
    "Trouver un emploi Finance": "Trouver un emploi Finance",
    "Trouver un emploi IT / Digital": "Trouver un emploi IT / Digital",
    "Trouver un freelance": "Trouver un Talent",
    "Trouver un talent": "Trouver un talent",
    "Trouver une mission": "Consulter nos offres",
    "Trouver une mission Freelance Finance":
      "Trouver une mission Freelance Finance",
    "Trouver une mission Freelance IT": "Trouver une mission Freelance IT",
    "Trouver une mission Freelance ou un CDI":
      "Trouver une mission Freelance ou un CDI",
    "Trouver une mission freelance": "Consulter nos offres",
    "Trouver une offre": "Consulter nos offres",
    "Trouvez d\xE8s maintenant les profils les plus pertinents gr\xE2ce \xE0 notre algorithme de matching SMATCH":
      "Trouvez d\xE8s maintenant les profils les plus pertinents gr\xE2ce \xE0 notre algorithme de matching SMATCH",
    "Trouvez le job IT qui vous correspond vraiment":
      "Trouvez le job IT ou Finance qui vous correspond vraiment",
    "Trouvez les plus belles offres IT et les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Trouvez les plus belles offres IT et les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...",
    "Trouvez votre consultant IT avec notre algorithme de matching SMATCH":
      "Trouvez votre consultant IT avec notre algorithme de matching SMATCH",
    "Trouvez votre consultant IT avec notre<0/>algorithme de matching SMATCH":
      "Trouvez votre consultant IT avec notre<0/>algorithme de matching SMATCH",
    "Trouvez votre futur talent IT en utilisant notre<0/>algorithme de matching SMATCH":
      "Trouvez votre futur talent IT en utilisant notre<0/>algorithme de matching SMATCH",
    "Trouvez votre prochain job IT":
      "Trouvez votre prochain job IT ou Finance en CDI ou Freelance",
    "Tr\xE8s bon accompagnement": "Tr\xE8s bon accompagnement",
    'Tr\xE8s bon accompagnement"': 'Tr\xE8s bon accompagnement"',
    "Tr\xE8s content de travailler avec Mindquest sur le recrutement de mes ressources externes. Rapide, efficace et profils de qualit\xE9.":
      "Tr\xE8s content de travailler avec Mindquest sur le recrutement de mes ressources externes. Rapide, efficace et profils de qualit\xE9.",
    'Tr\xE8s content de travailler avec Mindquest sur le recrutement de mes ressources externes. Rapide, efficace et profils de qualit\xE9."':
      'Tr\xE8s content de travailler avec Mindquest sur le recrutement de mes ressources externes. Rapide, efficace et profils de qualit\xE9."',
    "Tr\xE8s grande \xE9coute du besoin et profil des candidats tr\xE8s adapt\xE9.":
      "Tr\xE8s grande \xE9coute du besoin et profil des candidats tr\xE8s adapt\xE9.",
    Trésorier: "Tr\xE9sorier",
    Turkish: "Turque",
    Turque: "Turque",
    Type: "Type",
    "Type de Contrat": "Type de Contrat",
    "Type de contract": "Type de contract",
    "Type de contrat": "Type de contrat",
    "Type de diplome": "Type de diplome",
    "Type d\u2019heures effectu\xE9es": "Type d\u2019heures effectu\xE9es",
    Tâche: "T\xE2che",
    Tâches: "T\xE2ches",
    "T\xE2ches r\xE9alis\xE9es": "T\xE2ches r\xE9alis\xE9es",
    "T\xE9l. +33 (0)8 99 70 17 61": "T\xE9l. +33 (0)8 99 70 17 61",
    Télécharger: "T\xE9l\xE9charger",
    "T\xE9l\xE9charger ce mod\xE9le": "T\xE9l\xE9charger ce mod\xE9le",
    "T\xE9l\xE9charger la ressource": "T\xE9l\xE9charger la ressource",
    "T\xE9l\xE9charger le guide d\u2019utilisation": "Guide utilisateur",
    "T\xE9l\xE9charger le template de facture de Mindquest":
      "T\xE9l\xE9charger le template de facture de Mindquest",
    "T\xE9l\xE9charger ton CV favori": "T\xE9l\xE9charger your CV favori",
    "T\xE9l\xE9charger votre CV favori": "T\xE9l\xE9charger votre CV favori",
    Télécomunication: "T\xE9l\xE9comunication",
    Téléphone: "T\xE9l\xE9phone",
    "T\xE9l\xE9phone : +33 652 72 16 50": "T\xE9l\xE9phone : +33 652 72 16 50",
    Télétravail: "T\xE9l\xE9travail",
    Témoignages: "T\xE9moignages",
    URGENT: "URGENT",
    "URL LinkedIn": "URL LinkedIn",
    "UX /UI & Graphic Design": "Design Graphique & UX/UI",
    Un: "Un",
    "Un Account Manager sp\xE9cialis\xE9 sur votre secteur vous rappelle pour pr\xE9ciser votre besoin":
      "Un Account Manager vous rappelle pour pr\xE9ciser votre besoin",
    "Un Key Account Manager d\xE9di\xE9": "Un Key Account Manager d\xE9di\xE9",
    "Un Key Account Manager d\xE9di\xE9 vous guidera dans vos projets":
      "Un Key Account Manager d\xE9di\xE9 vous guidera dans vos projets",
    "Un accompagnement personnalis\xE9 par nos \xE9quipes":
      "Un accompagnement personnalis\xE9 par nos \xE9quipes",
    "Un accompagnement<0/>personnalis\xE9 par nos \xE9quipes":
      "Un accompagnement<0/>personnalis\xE9 par nos \xE9quipes",
    "Un apprenant": "Un apprenant",
    "Un candidat": "Un candidat",
    "Un community specialist vous appellera pour compl\xE9ter votre profil.":
      "Un Talent community specialist vous appellera pour compl\xE9ter votre profil.",
    "Un compte a d\xE9j\xE0 \xE9t\xE9 cr\xE9\xE9 avec cet email":
      "Un compte a d\xE9j\xE0 \xE9t\xE9 cr\xE9\xE9 avec cet email",
    "Un conseiller vous appelera pour compl\xE9ter votre profil.":
      "Un conseiller vous appellera pour compl\xE9ter votre profil.",
    "Un cv actualis\xE9 au format Word facilitera les \xE9changes avec nos \xE9quipes":
      "Un CV actualis\xE9 au format Word facilitera les \xE9changes avec nos \xE9quipes",
    "Un e-mail de validation vous a \xE9t\xE9 envoy\xE9 pour v\xE9rifier votre compte.":
      "Un e-mail de validation vous a \xE9t\xE9 envoy\xE9 pour v\xE9rifier votre compte.",
    "Un email de confirmation vous a \xE9t\xE9 envoy\xE9 sur votre nouvelle adresse. Une fois confirm\xE9, le changement sera effectif dans un d\xE9lais de 24 heures":
      "Un email de confirmation vous a \xE9t\xE9 envoy\xE9 sur votre nouvelle adresse. Une fois confirm\xE9, le changement sera effectif dans un d\xE9lais de 24 heures",
    "Un expert Mindquest vous appelera pour compl\xE9ter votre profil.":
      "Un expert Mindquest vous appelera pour compl\xE9ter votre profil.",
    "Un intitul\xE9 de poste": "Un intitul\xE9 de poste clair",
    "Un probleme de t\xE9l\xE9chargement de contrat":
      "Un probleme de t\xE9l\xE9chargement de contrat",
    "Un probleme est survenu, veuillez essayer plus tard":
      "Un probl\xE8me est survenu, veuillez essayer plus tard",
    "Un probl\xE8me est survenu": "Un probl\xE8me est survenu",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de besoin":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de besoin",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l'abonnement":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de l'abonnement",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l\u2019abonnement":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de l\u2019abonnement",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de votre candidature":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de votre candidature",
    "Un probl\xE8me est survenu lors de la cr\xE9ation d\u2019alerte":
      "Un probl\xE8me est survenu lors de la cr\xE9ation d\u2019alerte",
    "Un responsable de compte vous recontactera":
      "Un responsable de compte vous recontactera",
    "Un r\xE9seau de partenaires pour notre communaut\xE9":
      "Un r\xE9seau de partenaires pour notre communaut\xE9",
    "Un r\xE9seau de partenaires pour un accompagnement complet":
      "Un r\xE9seau de partenaires pour un accompagnement complet",
    "Un seul article trouv\xE9": "Un seul article trouv\xE9",
    "Un talent": "Un talent",
    "Under Construction": "En cours de construction",
    "Une Solution compl\xE8te de talent management":
      "Une solution compl\xE8te de talent management digitalis\xE9e",
    "Une approche unique": "Une approche unique",
    "Une communaut\xE9 au coeur de notre service.":
      "Notre communaut\xE9, au c\u0153ur de notre service",
    "Une double expertise": "Une double expertise",
    "Une entreprise / Un recruteur": "Une entreprise / Un recruteur",
    "Une erreur est survenue, veuillez r\xE9essayer ult\xE9rieurement":
      "Une erreur est survenue, veuillez r\xE9essayer ult\xE9rieurement",
    "Une erreur s\u2019est produite, veuillez r\xE9essayer":
      "Une erreur s\u2019est produite, veuillez r\xE9essayer",
    "Une expertise technique des interlocuteurs qui permet une recherche de candidats tr\xE8s pointue !":
      "Une expertise technique des interlocuteurs qui permet une recherche de candidats tr\xE8s pointue !",
    'Une expertise technique des interlocuteurs qui permet une recherche de candidats tr\xE8s pointue !"':
      'Une expertise technique des interlocuteurs qui permet une recherche de candidats tr\xE8s pointue !"',
    "Une fois le candidat choisi, nous vous accompagnons dans le process d\u2019onboarding et tout au long de la mission":
      "Une fois le candidat choisi, nous vous accompagnons dans le process d\u2019onboarding",
    "Une fois le timesheet rejet\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Une fois le timesheet rejet\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.",
    "Une fois le timesheet valid\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Une fois le timesheet valid\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.",
    "Une formation cybers\xE9curit\xE9": "Une formation cybers\xE9curit\xE9",
    "Une mission": "Une mission",
    "Une organisation agile": "Une organisation agile",
    "Une puissante Market Intelligence": "Une puissante Market Intelligence",
    "Une question d'ordre juridique ?": "Une question d'ordre juridique ?",
    "Une recherche trop pr\xE9cise peut vous faire passer \xE0 c\xF4t\xE9 d\u2019une opportunit\xE9 en or. Ces informations nous aident \xE9galement \xE0 vous recommander de nouveaux projets.":
      "Une recherche trop pr\xE9cise peut vous faire passer \xE0 c\xF4t\xE9 d\u2019une opportunit\xE9 en or. Ces informations nous aident \xE9galement \xE0 vous recommander de nouveaux projets.",
    "Une shortlist des meilleurs candidats pour tous vos besoins IT":
      "Une shortlist des meilleurs candidats pour tous vos besoins IT",
    "Une s\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil":
      "Une s\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil",
    "Une s\xE9lection d\u2019offres<0/>adapt\xE9es \xE0 votre profil":
      "Une s\xE9lection d\u2019offres<0/>adapt\xE9es \xE0 votre profil",
    "Une vision 360 du march\xE9": "Une vision 360 du march\xE9",
    "Une vraie pr\xE9paration, un accompagnement en entretien qui permet d'\xEAtre serein et rassurant face au client, de grands sourires et surtout beaucoup de bienveillance":
      "Une vraie pr\xE9paration, un accompagnement en entretien qui permet d'\xEAtre serein et rassurant face au client, de grands sourires et surtout beaucoup de bienveillance",
    "Une vraie pr\xE9paration, un accompagnement en entretien qui permet d'\xEAtre serein et rassurant face au client, de grands sourires et surtout beaucoup de bienveillance.":
      "Une vraie pr\xE9paration, un accompagnement en entretien qui permet d'\xEAtre serein et rassurant face au client, de grands sourires et surtout beaucoup de bienveillance.",
    "Updated on 1 February 2022": "Mise \xE0 jour le 1er f\xE9vrier 2022",
    "Uploader ma fiche de poste": "Charger ma fiche de poste",
    "Uploader mon CV": "Charger mon CV",
    "Uploader mon CV<0><1/><2>Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)</2><3/></0>":
      "Uploader mon CV<0><1/><2>Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)</2><3/></0>",
    "Utilisez cette case pour indiquer des heures suppl\xE9mentaires ou un tarif major\xE9 \xE0 votre client et \xE0 notre service de facturation":
      "Utilisez cette case pour indiquer des heures suppl\xE9mentaires ou un tarif major\xE9 \xE0 votre client et \xE0 notre service de facturation",
    "Utilisez les champs ci-dessus pour ajouter des heures":
      "Utilisez les champs ci-dessus pour ajouter des heures",
    "Utilisez l\u2019espace \u201CAjouter des heures\u201D au-dessus du calendrier pour ajouter d\u2019autres types d\u2019heures":
      "Utilisez l\u2019espace \u201CAjouter des heures\u201D au-dessus du calendrier pour ajouter d\u2019autres types d\u2019heures",
    "Utilisez notre algorithme SMATCH pour recruter les meilleurs talents Tech & IT en CDI ou Freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...).":
      "Utilisez notre algorithme SMATCH pour recruter les meilleurs talents Tech & IT en CDI ou Freelance (Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, ERP, SAP, Oracle, Infra...).",
    Valider: "Valider",
    "Valider Timesheet": "Valider Timesheet",
    "Valider le timesheet": "Valider le timesheet",
    "Validez les feuilles de temps en un clic":
      "Validez les feuilles de temps en un clic",
    "Validez les feuilles de timagesemps en un clic":
      "Validez les feuilles de timagesemps en un clic",
    Validés: "Valid\xE9s",
    "Valorisez votre expertise en finance, suivez le chemin de la r\xE9ussite":
      "Valorisez votre expertise en Finance et trouvez l'opportunit\xE9 en Freelance ou CDI qui vous correspond",
    "Veillez entrer un email valide": "Veillez entrer un email valide",
    "Veuillez accepter la politique de confidentialit\xE9":
      "Veuillez accepter la politique de confidentialit\xE9",
    "Veuillez accepter notre politique de confidentialit\xE9":
      "Veuillez accepter notre politique de confidentialit\xE9",
    "Veuillez ajouter une d\xE9scription de la mission":
      "Veuillez ajouter une description de la mission",
    "Veuillez enregistrer avant de passer \xE0 l\u2019\xE9tape suivante":
      "Veuillez enregistrer avant de passer \xE0 l\u2019\xE9tape suivante",
    "Veuillez entrer un email valide": "Veuillez entrer un email valide",
    "Veuillez entrer une address valide": "Veuillez entrer une adresse valide",
    "Veuillez entrer votre mot de passe pour continuer":
      "Veuillez entrer votre mot de passe pour continuer",
    "Veuillez entrer votre nom": "Veuillez entrer votre nom",
    "Veuillez entrer votre pr\xE9nom": "Veuillez entrer votre pr\xE9nom",
    "Veuillez indiquer votre localisation":
      "Veuillez indiquer votre localisation",
    "Veuillez noter que ce commentaire sera visible par le consultant":
      "Veuillez noter que ce commentaire sera visible par le consultant",
    "Veuillez noter que ces sites internet ont leur propre politique de confidentialit\xE9 et que la Soci\xE9t\xE9 d\xE9cline toute responsabilit\xE9 quant \xE0 l\u2019utilisation faite par ces sites des informations collect\xE9es lorsque vous cliquez sur ces liens. La Soci\xE9t\xE9 vous invite \xE0 prendre connaissance de politiques de confidentialit\xE9 de ces sites avant de leur transmettre vos donn\xE9es personnelles.":
      "Veuillez noter que ces sites internet ont leur propre politique de confidentialit\xE9 et que la Soci\xE9t\xE9 d\xE9cline toute responsabilit\xE9 quant \xE0 l\u2019utilisation faite par ces sites des informations collect\xE9es lorsque vous cliquez sur ces liens. La Soci\xE9t\xE9 vous invite \xE0 prendre connaissance de politiques de confidentialit\xE9 de ces sites avant de leur transmettre vos donn\xE9es personnelles.",
    "Veuillez patienter ...": "Veuillez patienter ...",
    "Veuillez selectionner au moin un filtre pour cr\xE9er une alerte":
      "Veuillez s\xE9lectionner au moin un filtre pour cr\xE9er une alerte",
    "Veuillez valider la ReCaptcha": "Veuillez valider la ReCaptcha",
    "Veuillez valider la certification ouverte avant l\u2019enregistrement":
      "Veuillez valider la certification ouverte avant l\u2019enregistrement",
    "Veuillez valider la formation ouverte avant l\u2019enregistrement":
      "Veuillez valider la formation ouverte avant l\u2019enregistrement",
    "Veuillez valider l\u2019exp\xE9rience ouverte avant l\u2019enregistrement":
      "Veuillez valider l\u2019exp\xE9rience ouverte avant l\u2019enregistrement",
    "Videos qui peuvent vous int\xE9resser":
      "Videos qui peuvent vous int\xE9resser",
    Vidéos: "Vid\xE9os",
    Ville: "Ville",
    "Ville de r\xE9sidence": "Ville de r\xE9sidence",
    "Viviers Mindquest": "Viviers Mindquest",
    "Voir Mon CV": "Voir mon CV",
    "Voir ce vivier": "Voir ce vivier",
    "Voir ces top talents": "Voir ces top talents",
    "Voir la FAQ": "Voir la FAQ",
    "Voir la video": "Voir la vid\xE9o",
    "Voir le Timesheet": "Voir le Timesheet",
    "Voir le profil": "Voir le profil",
    "Voir le profil complet": "Voir le profil complet",
    "Voir les mod\xE8les": "Voir les mod\xE8les",
    "Voir les offres": "Voir les offres",
    "Voir l\u2019offre": "Voir l\u2019offre",
    "Voir plus de vid\xE9os": "Voir plus de vid\xE9os",
    "Voir ses offres": "Voir ses offres",
    "Voir tous les talents": "Voir tous les talents",
    "Voir toutes les offres en cours": "Voir toutes les offres en cours",
    "Voir toutes les talents": "Voir tous les talents",
    "Voir toutes mes missions": "Voir toutes mes offres recommand\xE9es",
    "Vos collaborateur": "Vos collaborateur",
    "Vos coordonn\xE9es nous permettront de vous contacter d\xE8s lors qu\u2019une opportunit\xE9 correspond \xE0 votre profil.":
      "Vos coordonn\xE9es nous permettront de vous contacter d\xE8s lors qu\u2019une opportunit\xE9 correspond \xE0 votre profil.",
    "Vos donn\xE9es LinkedIn ont \xE9t\xE9 import\xE9es avec succ\xE8s. Veuillez les enregistrer.":
      "Vos donn\xE9es LinkedIn ont \xE9t\xE9 import\xE9es avec succ\xE8s. Veuillez les enregistrer.",
    "Vos futures connexions \xE0 votre espace personnel se feront avec votre nouvelle adresse email":
      "Vos futures connexions \xE0 votre espace personnel se feront avec votre nouvelle adresse email",
    "Vos projets Finance m\xE9ritent l\u2019excellence":
      "Vos projets Finance m\xE9ritent l\u2019excellence, trouvez l'expert financier qu'il vous faut",
    "Votre CV est en cours de g\xE9n\xE9ration . . .":
      "Votre CV est en cours de g\xE9n\xE9ration . . .",
    "Votre CV favori sera accessible directement depuis votre Dashboard":
      "Votre CV favori sera accessible directement depuis votre Dashboard",
    "Votre Profil pseudonymis\xE9 sera accecible aux d\xE9cideurs IT vous s\xE9lectionnant<0/>au sein de leurs viviers talents. Ils pourront visualiser vos exp\xE9riences et les<1/>autres contenus professionnels que vous avec ajout\xE9s sur votre profil.":
      "Votre Profil pseudonymis\xE9 sera accessible aux d\xE9cideurs IT vous s\xE9lectionnant<0/>au sein de leurs viviers talents. Ils pourront visualiser vos exp\xE9riences et les<1/>autres contenus professionnels que vous avec ajout\xE9s sur votre profil.",
    "Votre Suggestion sera revue": "Votre Suggestion sera revue",
    "Votre Talent Community Specialist vous rappelle pour comprendre vos besoins":
      "Un Talent Community Specialist vous rappelle pour pr\xE9ciser votre recherche ",
    "Votre Timesheet du mois n\u2019est pas ouvert?":
      "Votre Timesheet du mois n\u2019est pas ouvert ?",
    "Votre communaut\xE9, notre expertise":
      "Votre communaut\xE9, notre expertise",
    "Votre dashboard est en cours de cr\xE9ation et sera accessible dans quelques instants.":
      "Votre dashboard est en cours de cr\xE9ation et sera accessible dans quelques instants.",
    "Votre demande a bien \xE9t\xE9 envoy\xE9e pour validation!":
      "Votre demande a bien \xE9t\xE9 envoy\xE9e pour validation !",
    "Votre demande de collaboration a bien \xE9t\xE9 renvoy\xE9e":
      "Votre demande de collaboration a bien \xE9t\xE9 renvoy\xE9e",
    "Votre demande est bien enregistr\xE9e":
      "Votre demande est bien enregistr\xE9e",
    "Votre domaine d\u2019expertise": "Domaine d\u2019expertise",
    "Votre futur collaborateur recevra un email permettant de se connecter ou de s'inscrire afin d'avoir acc\xE8s \xE0 vos diff\xE9rents viviers. N'oubliez pas d'activer en collaboratif, les viviers que vous souhaitez partager.":
      "Votre futur collaborateur recevra un email permettant de se connecter ou de s'inscrire afin d'avoir acc\xE8s \xE0 vos diff\xE9rents viviers. N'oubliez pas d'activer en collaboratif, les viviers que vous souhaitez partager.",
    "Votre lien a expir\xE9, merci de faire une nouvelle demande via":
      "Votre lien a expir\xE9, merci de faire une nouvelle demande via",
    "Votre message": "Votre message",
    "Votre parcours est unique, notre accompagnement aussi":
      "Votre parcours est unique, notre accompagnement aussi",
    "Votre parcours est unique,<0/> notre accompagnement aussi":
      "Votre parcours est unique,<0/> notre accompagnement aussi",
    "Votre partenaire pour donner de l\u2019\xE9lan \xE0 vos projets ou \xE0 votre carri\xE8re Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. D\xE9couvrez nos Conditions G\xE9n\xE9rales d'Utilisation.":
      "Votre partenaire pour donner de l\u2019\xE9lan \xE0 vos projets ou \xE0 votre carri\xE8re Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. D\xE9couvrez nos Conditions G\xE9n\xE9rales d'Utilisation.",
    "Votre partenaire pour donner de l\u2019\xE9lan \xE0 vos projets ou \xE0 votre carri\xE8re Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. D\xE9couvrez notre politique de confidentialit\xE9.":
      "Votre partenaire pour donner de l\u2019\xE9lan \xE0 vos projets ou \xE0 votre carri\xE8re Tech & IT. CDI ou contrat : Le match parfait entre entreprises et talents IT. D\xE9couvrez notre politique de confidentialit\xE9.",
    "Votre profil est complet \xE0 {0}%, Pour g\xE9n\xE9rer votre CV Mindquest, terminez de remplir les informations indispensables.": function(
      a
    ) {
      return [
        "Votre profil est complet \xE0 ",
        a("0"),
        "%, Pour g\xE9n\xE9rer votre CV Mindquest, terminez de remplir les informations indispensables."
      ];
    },
    "Votre profil est maintenant public": "Votre profil est maintenant public",
    "Votre profil est presque complet. Un profil compl\xE9t\xE9 \xE0 100% permettra \xE0 nos recruteurs de vous proposer les offres les plus adapt\xE9s.":
      "Votre profil est presque complet. Un profil compl\xE9t\xE9 \xE0 100% permettra \xE0 nos recruteurs de vous proposer les offres les plus adapt\xE9s.",
    "Votre profil est rempli \xE0": "Votre profil est rempli \xE0",
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance.": function(
      a
    ) {
      return [
        "Votre profil est rempli \xE0 ",
        a("0"),
        "%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest."
      ];
    },
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance. Ajoutez votre": function(
      a
    ) {
      return [
        "Votre profil est rempli \xE0 ",
        a("0"),
        "%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest. Ajoutez votre"
      ];
    },
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance. Ajoutez votre {1}": function(
      a
    ) {
      return [
        "Votre profil est rempli \xE0 ",
        a("0"),
        "%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest. Ajoutez votre ",
        a("1")
      ];
    },
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest.": function(
      a
    ) {
      return [
        "Votre profil est rempli \xE0 ",
        a("0"),
        "%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest."
      ];
    },
    "Votre profil n'est plus public": "Votre profil n'est plus public",
    "Votre profil n'est rempli termin\xE9 , compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance.":
      "Votre profil n'est rempli termin\xE9 , compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest.",
    "Votre qu\xEAte de talents se d\xE9bloque":
      "Votre qu\xEAte de talents se d\xE9bloque",
    "Votre statut": "Votre statut",
    "Votre token est expir\xE9 ou invalide":
      "Votre token est expir\xE9 ou invalide",
    "Voudriez-vous laisser un message ?": "Voudriez-vous laisser un message ?",
    "Voulez vous contribuer?": "Voulez-vous contribuer ?",
    "Voulez-vous ajouter des informations sur votre projet?":
      "Voulez-vous ajouter des informations sur votre projet?",
    'Vous allez sugg\xE9rer la comp\xE9tence "{suggestedSkill}", est-ce que correct ?<0/>Merci de v\xE9rifier que celle-ci est bien orthographi\xE9e.<1/><2/>Cette comp\xE9tence sera revue par nos \xE9quipe et pourra \xEAtre ajout\xE9e dans les prochains jours.<3/><4/>Merci de votre suggestion': function(
      a
    ) {
      return [
        'Vous allez sugg\xE9rer la comp\xE9tence "',
        a("suggestedSkill"),
        '", est-ce que correct ?<0/>Merci de v\xE9rifier que celle-ci est bien orthographi\xE9e.<1/><2/>Cette comp\xE9tence sera revue par nos \xE9quipe et pourra \xEAtre ajout\xE9e dans les prochains jours.<3/><4/>Merci de votre suggestion'
      ];
    },
    'Vous allez sugg\xE9rer la comp\xE9tence "{suggestedSkill}", est-ce que correct ?<0/>Merci de v\xE9rifier que celle-ci est bien orthographi\xE9e.<1/><2/>Cette comp\xE9tence sera revue par nos \xE9quipes et pourra \xEAtre ajout\xE9e dans les prochains jours.<3/><4/>Merci de votre suggestion': function(
      a
    ) {
      return [
        'Vous allez sugg\xE9rer la comp\xE9tence "',
        a("suggestedSkill"),
        '", est-ce que correct ?<0/>Merci de v\xE9rifier que celle-ci est bien orthographi\xE9e.<1/><2/>Cette comp\xE9tence sera revue par nos \xE9quipes et pourra \xEAtre ajout\xE9e dans les prochains jours.<3/><4/>Merci de votre suggestion'
      ];
    },
    "Vous avez des besoins en, <0>Direction financi\xE8re externalis\xE9, Analyse Financi\xE8re, <1/>Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA, </0><2/>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.<3/>":
      "Vous avez des besoins en, <0>Direction financi\xE8re externalis\xE9, Analyse Financi\xE8re, <1/>Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA, </0><2/>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.<3/>",
    "Vous avez des besoins en, <0>Direction financi\xE8re externalis\xE9, Analyse Financi\xE8re, <1/>Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA,Consolidation, Audit, M&A </0><2/>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.<3/>":
      "Vous avez des besoins en, <0>Direction financi\xE8re externalis\xE9, Analyse Financi\xE8re, <1/>Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA,Consolidation, Audit, M&A </0><2/>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.<3/>",
    "Vous avez des besoins en, <0>Direction financi\xE8re externalis\xE9e, Analyse Financi\xE8re, Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA, Consolidation, Audit, M&A </0>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.<1/>":
      "Vous avez des besoins en, <0>Direction financi\xE8re externalis\xE9e, Analyse Financi\xE8re, Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA, Consolidation, Audit, M&A </0>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.<1/>",
    "Vous avez des besoins en,<0>Direction financi\xE8re externalis\xE9 , Analyse Financi\xE8re, Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA, </0><1/><2>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.</2><3/>":
      "Vous avez des besoins en,<0>Direction financi\xE8re externalis\xE9 , Analyse Financi\xE8re, Management de Transition, Comptabilit\xE9, Contr\xF4le de gestion, Consulting, AMOA, </0><1/><2>en Freelance ou en CDI, nous vous pr\xE9sentons des talents selectionn\xE9s pour votre entreprise.</2><3/>",
    "Vous avez d\xE9j\xE0 confirm\xE9 votre demande, elle sera trait\xE9e dans les plus brefs d\xE9lais":
      "Vous avez d\xE9j\xE0 confirm\xE9 votre demande, elle sera trait\xE9e dans les plus brefs d\xE9lais",
    "Vous avez d\xE9j\xE0 postul\xE9 pour cette mission":
      "Vous avez d\xE9j\xE0 postul\xE9 pour cette mission",
    "Vous avez d\xE9passer le nombre limit\xE9 de vivier":
      "Vous avez d\xE9passer le nombre limit\xE9 de vivier",
    "Vous avez finalis\xE9 votre inscription":
      "Vous avez finalis\xE9 votre inscription",
    "Vous avez oubli\xE9 d\u2019\xE9crire votre message !":
      "Vous avez oubli\xE9 d\u2019\xE9crire votre message !",
    "Vous avez un besoin en comp\xE9tences informatiques (talent it, consultant informatique) ? Vous cherchez une mission ou un job ? N\u2019h\xE9sitez pas \xE0 nous contacter.":
      "Vous avez un besoin en comp\xE9tences informatiques (talent it, consultant informatique) ? Vous cherchez une mission ou un job ? N\u2019h\xE9sitez pas \xE0 nous contacter.",
    "Vous avez un besoin informatique ? Vous cherchez une mission ? N\u2019h\xE9sitez pas \xE0 nous contacter.":
      "Vous avez un besoin en comp\xE9tences informatiques ? Vous cherchez une mission ? N\u2019h\xE9sitez pas \xE0 nous contacter.",
    "Vous cherchez un talent ou une opportunit\xE9 IT ? Nous connectons les meilleurs profils aux meilleurs offres pour un match parfait entre entreprise et talents IT.":
      "Vous cherchez un talent ou une opportunit\xE9 IT ? Nous connectons les meilleurs profils aux meilleurs offres pour un match parfait entre entreprise et talents IT.",
    "Vous cr\xE9ez votre profil et acc\xE9dez \xE0 votre espace personnel pour poster votre premier besoin, en seulement quelques minutes":
      "Vous cr\xE9ez votre profil Mindquest en seulement 3 minutes ",
    "Vous devez compl\xE8ter votre inscription pour pouvoir postuler \xE0 cette mission":
      "Vous devez compl\xE8ter votre inscription pour pouvoir postuler \xE0 cette mission",
    "Vous devez compl\xE9ter votre inscription pour postuler \xE0 cette offre":
      "Vous devez compl\xE9ter votre inscription pour postuler \xE0 cette offre",
    "Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin":
      "Vous devez vous connecter avec un compte entreprise pour pouvoir poster un besoin",
    "Vous devez vous connecter avec un compte entreprise pour continuer":
      "Vous devez vous connecter avec un compte entreprise pour continuer",
    "Vous devez vous connecter avec un compte entreprise pour continuer.":
      "Vous devez vous connecter avec un compte entreprise pour continuer.",
    "Vous devez vous connecter pour pouvoir postuler":
      "Vous devez vous connecter pour pouvoir postuler",
    "Vous n'avez pas re\xE7u votre code ?":
      "Vous n'avez pas re\xE7u votre code ?",
    'Vous ne pouvez int\xE9grer que 15 talents \xE0 votre vivier par d\xE9faut "Mes recherches de talent IT"':
      'Vous ne pouvez int\xE9grer que 15 talents \xE0 votre vivier par d\xE9faut "Mes recherches de talent IT"',
    "Vous ne pouvez pas acc\xE9der \xE0 ces fonctionnalit\xE9s avec un compte Talent.":
      "Vous ne pouvez pas acc\xE9der \xE0 ces fonctionnalit\xE9s avec un compte Talent.",
    "Vous ne pouvez pas ajouter une exp\xE9rience vide":
      "Vous ne pouvez pas ajouter une exp\xE9rience vide",
    "Vous ne pouvez pas d\xE9placer la carte vers cette colonne":
      "Vous ne pouvez pas d\xE9placer la carte vers cette colonne",
    "Vous ne pouvez pas modifier cette opportunit\xE9":
      "Vous ne pouvez pas modifier cette opportunit\xE9",
    "Vous ne puvez pas ajouter votre email \xE0 la liste des collaborateurs":
      "Vous ne pouvez pas ajouter votre email \xE0 votre liste de collaborateurs",
    "Vous n\u2019avez pas encore de document":
      "Vous n\u2019avez pas encore de document",
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Continuer"':
      'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Continuer"',
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Suivant"':
      'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Suivant"',
    "Vous pouvez d\xE9sormais remplir votre Timesheet {m}/{y} du contrat num\xE9ro: {pID}": function(
      a
    ) {
      return [
        "Vous pouvez d\xE9sormais remplir votre Timesheet ",
        a("m"),
        "/",
        a("y"),
        " du contrat num\xE9ro: ",
        a("pID")
      ];
    },
    "Vous pouvez g\xE9rer l\u2019ensemble du process de s\xE9lection et les entretiens via votre espace client, et en mode collaboratif avec votre \xE9quipe":
      "Vous pouvez g\xE9rer l\u2019ensemble du process de s\xE9lection et les entretiens via votre espace client, et en mode collaboratif avec votre \xE9quipe",
    "Vous pouvez maintenant suivre le candidat":
      "Vous pouvez maintenant suivre le candidat",
    "Vous pouvez maintenant suivre le candidat {candidateBhId}": function(a) {
      return ["Vous pouvez maintenant suivre le candidat ", a("candidateBhId")];
    },
    "Vous pouvez maintenant suivre le candidat {id}": function(a) {
      return ["Vous pouvez maintenant suivre le candidat ", a("id")];
    },
    "Vous pouvez suivre vos candidatures dans votre profil":
      "Vous pouvez suivre vos candidatures dans votre profil",
    "Vous pouvez supprimer ou renommer vos projets":
      "Vous pouvez supprimer ou renommer vos projets",
    "Vous pouvez supprimer ou renommer vos th\xE8mes":
      "Vous pouvez supprimer ou renommer vos th\xE8mes",
    "Vous pouvez voir votre alerte dans votre profil":
      "Retrouvez et modifiez votre alerte dans votre profil",
    "Vous recevez une shortlist de candidats qualifi\xE9s (en moyenne sous 48h)":
      "Vous recevez une shortlist de candidats qualifi\xE9s (en moyenne sous 48h)",
    "Vous recherchez des profils de consultants IT exp\xE9riment\xE9s ? Trouvez les d\xE8s maintenant avec notre algorithme de matching SMATCH":
      "Vous recherchez des profils de consultants IT exp\xE9riment\xE9s ? Trouvez les d\xE8s maintenant avec notre algorithme de matching SMATCH",
    "Vous risquez de perdre vos donn\xE9es":
      "Vous risquez de perdre vos donn\xE9es",
    "Vous serez redig\xE9 automatiquement vers votre dashboard.":
      "Vous serez redig\xE9 automatiquement vers votre dashboard",
    "Vous souhaitez connaitre la disponibilit\xE9 de":
      "Vous souhaitez connaitre la disponibilit\xE9 de",
    "Vous souhaitez coopter quelqu\u2019un de votre entourage ?":
      "Vous souhaitez coopter quelqu\u2019un de votre entourage ?",
    "Vous souhaitez en savoir plus sur notre fonctionnement ?":
      "Vous souhaitez recevoir des profils IT ou Finance exp\xE9riment\xE9s ?",
    "Vous souhaitez faire monter en comp\xE9tences vos \xE9quipes ? Contactez-vous.":
      "Vous souhaitez faire monter en comp\xE9tences vos \xE9quipes ? Contactez-vous.",
    "Vous souhaitez nous faire part de votre besoin de recrutement IT ou finanace ?":
      "Vous souhaitez nous faire part de votre besoin de recrutement IT ou finanace ?",
    "Vous souhaitez nous faire part de votre besoin de recrutement IT ou finance ?":
      "Vous souhaitez nous faire part de votre besoin de recrutement IT ou finance ?",
    "Vous souhaitez rejoindre Club Freelance ?":
      "Connecter les meilleurs talents aux meilleures offres IT et Finance",
    "Vous souhaitez \xEAtre accompagn\xE9(e) en portage salarial ? D\xE9couvrez les solutions de nos partenaires.":
      "Vous souhaitez \xEAtre accompagn\xE9(e) en portage salarial ? D\xE9couvrez les solutions de nos partenaires.",
    "Vous \xEAtes d\xE9j\xE0 abonn\xE9 aux newsletter Mindquest Connect":
      "Vous \xEAtes d\xE9j\xE0 abonn\xE9 aux newsletter Mindquest Connect",
    "Vous \xEAtes d\xE9j\xE0 abonn\xE9 \xE0 la newsletter Mindquest Connect":
      "Vous \xEAtes d\xE9j\xE0 abonn\xE9 \xE0 la newsletter Mindquest Connect",
    "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mindquest Connect":
      "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mindquest Connect",
    "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mission Control Center":
      "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mission Control Center",
    "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Club Freelance":
      "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Mindquest",
    "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Mindquest":
      "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Mindquest",
    "Vous \xEAtes freelance ?":
      "Vous \xEAtes \xE0 l\u2019\xE9coute de nouvelles opportunit\xE9s dans l\u2019IT ou la Finance?",
    "Vous \xEAtes sur le point de soumettre un Timesheet vide.":
      "Vous \xEAtes sur le point de soumettre un Timesheet vide.",
    "Vous \xEAtes sur le point de soumettre votre Timesheet {0} au client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le modifier. Merci de v\xE9rifier vos informations avant l\u2019envoi.": function(
      a
    ) {
      return [
        "Vous \xEAtes sur le point de soumettre votre Timesheet ",
        a("0"),
        " au client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le modifier. Merci de v\xE9rifier vos informations avant l\u2019envoi."
      ];
    },
    "Vous \xEAtes sur le point de soumettre votre Timesheet {0} client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le le modifier. Merci de v\xE9rifier vos in formations avant l\u2019envoi.": function(
      a
    ) {
      return [
        "Vous \xEAtes sur le point de soumettre votre Timesheet ",
        a("0"),
        " client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le le modifier. Merci de v\xE9rifier vos in formations avant l\u2019envoi."
      ];
    },
    "Vous \xEAtes sur le point d\u2019envoyer <0>{0} travaill\xE9s</0>": function(
      a
    ) {
      return [
        "Vous \xEAtes sur le point d\u2019envoyer <0>",
        a("0"),
        " travaill\xE9s</0>"
      ];
    },
    "Vous \xEAtes sur le point d\u2019envoyer <0>{0}</0>": function(a) {
      return ["Vous \xEAtes sur le point d\u2019envoyer <0>", a("0"), "</0>"];
    },
    "Vue mois": "Vue mois",
    "Vue projet": "Vue projet",
    "Vue th\xE8me": "Vue th\xE8me",
    "Vue type": "Vue type",
    "V\xE9rifier la disponibilit\xE9": "V\xE9rifier la disponibilit\xE9",
    "Want to join Club Freelance ?": "Envie de rejoindre Mindquest ?",
    "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:":
      "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:",
    "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.":
      "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.",
    "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.":
      "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.",
    "We collect your personal data in two primary ways:":
      "We collect your personal data in two primary ways:",
    "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.":
      "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.",
    "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.":
      "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.",
    "We may also disclose your personal information to third parties:":
      "We may also disclose your personal information to third parties:",
    "We may collect, store, and use the following categories of personal information about you:":
      "We may collect, store, and use the following categories of personal information about you:",
    "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.":
      "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.",
    "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.":
      "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.",
    "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;":
      "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;",
    "We may obtain this information from you, from our Client or from public sources and this includes:":
      "We may obtain this information from you, from our Client or from public sources and this includes:",
    "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.":
      "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.",
    "We only bill if you find your freelancer":
      "Notre service est gratuit jusqu\u2019\xE0 l\u2019onboarding du candidat",
    "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.":
      "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.",
    "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.":
      "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.",
    "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.":
      "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.",
    "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.":
      "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.",
    "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:":
      "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
    "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.":
      "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.",
    "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.":
      "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.",
    "Web Development": "D\xE9veloppement Web",
    "Webinars & Ressources": "Webinars & Ressources",
    "Webinars & Ressources - Contenu pour Consultants IT - Mindquest Connect":
      "Webinars & Ressources - Contenu pour Consultants IT - Mindquest Connect",
    "Webinars & Ressources - Contenu pour D\xE9cideurs IT - Mindquest Connect":
      "Webinars & Ressources - Contenu pour D\xE9cideurs IT - Mindquest Connect",
    "Webinars & Ressources - Mindquest Connect":
      "Webinars & Ressources - Mindquest Connect",
    "Webinars & Ressources - Replays - Mindquest Connect":
      "Webinars & Ressources - Replays - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Cloud - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Cloud - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Cybers\xE9curit\xE9 - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Cybers\xE9curit\xE9 - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : DSI - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : DSI - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Data & ERP - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Data & ERP - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : D\xE9veloppement - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : D\xE9veloppement - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Intelligence Artificielle - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Intelligence Artificielle - Mindquest Connect",
    "Webinars & Ressources - Revues de Presse : Recrutement & IT - Mindquest Connect":
      "Webinars & Ressources - Revues de Presse : Recrutement & IT - Mindquest Connect",
    "Webinars & replay": "Webinars & replay",
    "Website user": "Website user",
    "Website users": "Website users",
    "Week-end et Jours f\xE9ri\xE9s seront d\xE9selectionn\xE9s de la plage":
      "Week-end et Jours f\xE9ri\xE9s seront d\xE9selectionn\xE9s de la plage",
    "What are our legitimate business interests in collecting and retaining your personal data?":
      "What are our legitimate business interests in collecting and retaining your personal data?",
    "What kind of Personal Data do we collect?":
      "What kind of Personal Data do we collect?",
    "What measures we put in place to safeguard your personal data?":
      "What measures we put in place to safeguard your personal data?",
    "What we may need from you": "What we may need from you",
    WhatsApp: "WhatsApp",
    "When a job you\u2019ve applied for is closed, you get one more application":
      "When a job you\u2019ve applied for is closed, you get one more application",
    "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.":
      "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.",
    "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.":
      "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.",
    "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.":
      "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.",
    "Who do we share your personal data with?":
      "Who do we share your personal data with?",
    "Without  them,  the  Company  might  not  be  able  to  provide  all the  goods  and/or  services requested and ordered by the individual concerned.":
      "Sans elles, la Soci\xE9t\xE9 pourrait ne pas \xEAtre en mesure de fournir tous les produits et/ou services demand\xE9s et command\xE9s par la Personne concern\xE9e.",
    "Without them, the Company might not be able to provide all the goods and/or services requested and ordered by the individual concerned.":
      "Sans elles, la Soci\xE9t\xE9 pourrait ne pas \xEAtre en mesure de fournir tous les produits et/ou services demand\xE9s et command\xE9s par la Personne concern\xE9e.",
    "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.":
      "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.",
    "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.":
      "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.",
    "You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.":
      "You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.",
    "Your referees may disclose personal information about you;":
      "Your referees may disclose personal information about you;",
    "Zone g\xE9ographique souhait\xE9e": "Zone g\xE9ographique souhait\xE9e",
    "Zones g\xE9ographiques souhait\xE9es":
      "Zones g\xE9ographiques souhait\xE9es",
    "a ajout\xE9 le talent": "a ajout\xE9 le talent",
    "a cr\xE9e le vivier": "a cr\xE9e le vivier",
    "a supprim\xE9 le talent": "a supprim\xE9 le talent",
    "a supprim\xE9 le vivier": "a supprim\xE9 le vivier",
    actionCookie: "actionCookie",
    actualité: "Actualit\xE9",
    "adresse email": "Entrer votre adresse email",
    "ajout d'un nouveau signataire principal":
      "ajout d'un nouveau signataire principal",
    "ajout d'un nouveau signataire secondaire":
      "ajout d'un nouveau signataire secondaire",
    "ajoutez un commentaire...": "ajoutez un commentaire...",
    "analytics and search engine providers that assist us in the improvement and optimisation of our site;":
      "analytics and search engine providers that assist us in the improvement and optimisation of our site;",
    "any administrative or judicial authority shall: upon request under the applicable law.":
      "toute autorit\xE9 administrative ou judiciaire : sur demande en vertu du droit applicable.",
    août: "ao\xFBt",
    au: "au",
    "au client . veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois .<0/>Note : ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet":
      "au client . veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois .<0/>Note : ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet",
    "aucun r\xE9sultat ne correspond \xE0 vos crit\xE8res de recherche":
      "aucun r\xE9sultat ne correspond \xE0 vos crit\xE8res de recherche",
    "aucun timesheet disponible": "aucun timesheet disponible",
    avril: "avril",
    "blog, club freelance, Conseils pratiques, consultant IT, freelance informatique":
      "blog, Mindquest, Conseils pratiques, consultant IT, freelance informatique",
    "blog, club freelance, Conseils pratiques, entreprise IT, freelance informatique":
      "blog, Mindquest, Conseils pratiques, entreprise IT, freelance informatique",
    "blog, club freelance, D\xE9cryptages": "blog, Mindquest, D\xE9cryptages",
    "blog, club freelance, Success stories, freelance informatique":
      "blog, Mindquest, Success stories, freelance informatique",
    "blog, club freelance, actualit\xE9s, freelance informatique, actualit\xE9 freelance":
      "blog, Mindquest, actualit\xE9s, freelance informatique, actualit\xE9 freelance",
    "blog, club freelance, recherche, freelance informatique":
      "blog, Mindquest, recherche, freelance informatique",
    "blog, club freelance, talents IT, actualit\xE9s, freelances IT":
      "blog, Mindquest, talents IT, actualit\xE9s, talents IT",
    candidat: "Candidat",
    "ces mots cl\xE9s seront pris en compte lors de la prochaine mise \xE0 jour.":
      "ces mots cl\xE9s seront pris en compte lors de la prochaine mise \xE0 jour.",
    "champ requis": "champ requis",
    "chercher par id, consultant": "chercher par id, consultant",
    "chercher par id, entreprise ou signataire":
      "chercher par id, entreprise ou signataire",
    "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;":
      "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;",
    "club freelance, privacy policy": "Mindquest, privacy policy",
    "code de confirmation": "code de confirmation",
    "code invalide": "code invalide",
    comments: "comments",
    completed: "Sign\xE9",
    "compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance. Ajoutez votre":
      "compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest. Ajoutez votre",
    "composent la communaut\xE9 <0/> Mindquest":
      "composent la communaut\xE9 <0/> Mindquest",
    "composent la communaut\xE9 Mindquest":
      "composent la communaut\xE9 Mindquest",
    confirmer: "confirmer",
    "conseils pratiques": "Conseils pratiques",
    consultant: "Consultant",
    "contact, club freelance, freelance informatique":
      "contact, Mindquest, freelance informatique",
    "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.":
      "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.",
    "c\u2019est le seul moyen de vous rejoindre!":
      "C\u2019est le seul moyen de vous joindre !",
    "dans le plus breff d\xE9lais.": "dans le plus breff d\xE9lais.",
    "dashboard, club freelance, entreprise, talents, recrutement,":
      "dashboard, Mindquest, entreprise, talents, recrutement,",
    "dd/MM/yyyy": "dd/MM/yyyy",
    "de Mindquest": "de Mindquest",
    "de ventes": "de ventes",
    "de v\xE9rifier sa disponibilt\xE9. Nous reviendrons vers vous":
      "de v\xE9rifier sa disponibilt\xE9. Nous reviendrons vers vous",
    "demande d\xE9ja confirm\xE9": "demande d\xE9ja confirm\xE9",
    disponibilite: "Ouvert aux opportunit\xE9s",
    "disponibilit\xE9 \xE0 confirmer": "disponibilit\xE9 \xE0 confirmer",
    "disponible dans un jour": "disponible dans un jour",
    "disponible dans {diffDays} jours": function(a) {
      return ["disponible dans ", a("diffDays"), " jours"];
    },
    "disponible dans {diffMonths} mois": function(a) {
      return ["disponible dans ", a("diffMonths"), " mois"];
    },
    "disponible dans {diffYears} ans": function(a) {
      return ["disponible dans ", a("diffYears"), " ans"];
    },
    "disponible imm\xE9diatement": "disponible imm\xE9diatement",
    décembre: "d\xE9cembre",
    décryptages: "d\xE9cryptages",
    "email invalide": "Email invalide",
    "en attente": "en attente",
    entreprise: "entreprise",
    "entrer la taille de votre entreprise":
      "S\xE9lectionnez la taille de votre entreprise",
    "entrer votre nom": "Entrez votre nom",
    "entrer votre pr\xE9nom": "Entrez votre pr\xE9nom",
    "entrez l\u2019email de r\xE9ception": "Votre email",
    "envoi du feuille de temps": "envoi du feuille de temps",
    "est ferm\xE9, vous recevez une candidature suppl\xE9mentaire":
      "est ferm\xE9, vous recevez une candidature suppl\xE9mentaire",
    "et de v\xE9rifier vos emails": "et de v\xE9rifier vos emails",
    "et qualifier son profil ?": "et qualifier son profil ?",
    "et trouvez votre prochaine mission IT":
      "Trouvez votre prochain job, et acc\xE9l\xE9rez dans votre carri\xE8re",
    "euros / jour": "euros / jour",
    fermée: "ferm\xE9e",
    filters: "filters",
    firstVisitOrigin: "firstVisitOrigin",
    fr: "fr",
    février: "f\xE9vrier",
    heures: "heures",
    "https://monsite.fr": "https://monsite.fr",
    "https://www.linkedin.com/in/user/": "https://www.linkedin.com/in/user/",
    "il n'y a pas de r\xE9sultat pour votre recherche, ajouter les filtres ou ajoutez des comp\xE9tences.":
      "il n'y a pas de r\xE9sultat pour votre recherche, ajouter les filtres ou ajoutez des comp\xE9tences.",
    "il n\u2019y a pas d\u2019historique disponible pour le moment":
      "il n\u2019y a pas d\u2019historique disponible pour le moment",
    inconnue: "inconnue",
    indéterminé: "ind\xE9termin\xE9",
    "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).":
      "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).",
    "informatique et consultants IT": "informatique et consultants IT",
    "int\xE9ress\xE9 par ce candidat": "Voir le profil",
    j: "j",
    "j'ai compris": "J'ai compris",
    janvier: "janvier",
    "jdoe@company.com": "jdoe@company.com",
    "jj/mm/aaaa": "jj/mm/aaaa",
    jour: "jour",
    "jour travaill\xE9": "jour travaill\xE9",
    jours: "jours",
    "jours travaill\xE9s": "jours travaill\xE9s",
    juillet: "juillet",
    juin: "juin",
    "j\u2019ai compris": "J\u2019ai compris",
    "l'ajout du nouveau signataire secondaire a \xE9t\xE9 effectu\xE9 avec succ\xE8s":
      "l'ajout du nouveau signataire secondaire a \xE9t\xE9 effectu\xE9 avec succ\xE8s",
    "la feuille de temps a \xE9t\xE9 soumise au client pour validation":
      "la feuille de temps a \xE9t\xE9 soumise au client pour validation",
    "la feuille de temps est enregistr\xE9e en brouillon, vous pouvez la modifier \xE0 tout moment":
      "la feuille de temps est enregistr\xE9e en brouillon, vous pouvez la modifier \xE0 tout moment",
    "la feuille de temps est rejet\xE9e": "la feuille de temps est rejet\xE9e",
    "la feuille de temps est valid\xE9e": "la feuille de temps est valid\xE9e",
    "la feuille de temps n'a pas \xE9t\xE9 enregistr\xE9e":
      "la feuille de temps n'a pas \xE9t\xE9 enregistr\xE9e",
    "la suppression du signataire secondaire est faite avec succ\xE8s":
      "la suppression du signataire secondaire est faite avec succ\xE8s",
    "le fichier vous a \xE9t\xE9 envoy\xE9 par email":
      "Le fichier vous a \xE9t\xE9 envoy\xE9 par email",
    "le probl\xE8me sera bient\xF4t bient\xF4t r\xE9solu":
      "le probl\xE8me sera bient\xF4t bient\xF4t r\xE9solu",
    "le probl\xE8me sera bient\xF4t r\xE9solu":
      "Le probl\xE8me sera bient\xF4t r\xE9solu",
    "login or password invalid": "login or password invalid",
    "login ou mot de passe invalide": "login ou mot de passe invalide",
    "login, club freelance, account, connexion":
      "login, Mindquest, account, connexion",
    mai: "mai",
    mars: "mars",
    "mindquest, CGU, conditions g\xE9n\xE9rales d'utilisation":
      "mindquest, CGU, conditions g\xE9n\xE9rales d'utilisation",
    "mindquest, privacy policy": "mindquest, privacy policy",
    missions: "missions",
    "missions freelance, Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle":
      "missions freelance, Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle",
    "missions, Talent Community Specialist, Freelance informatique":
      "missions, Talent Community Specialist, Freelance informatique",
    mois: "mois",
    "monemail@email.com": "monemail@email.com",
    "monemail@mail.com": "monemail@mail.com",
    "mot de passe actuel est invalide": "mot de passe actuel est invalide",
    "mot de passe incorrect": "Mot de passe incorrect",
    normal: "normal",
    "nouveau mot de passe est invalide": "nouveau mot de passe est invalide",
    novembre: "novembre",
    "num\xE9ro de t\xE9l\xE9phone non valide":
      "num\xE9ro de t\xE9l\xE9phone non valide",
    octobre: "octobre",
    "offre disponible": "offre disponible",
    "offres disponibles": "offres disponibles",
    optionnel: "optionnel",
    ou: "ou",
    "ou inscrivez-vous maintenant": "ou inscrivez-vous maintenant",
    ouvert: "ouvert",
    "par cat\xE9gorie: {0}": function(a) {
      return ["par cat\xE9gorie: ", a("0")];
    },
    "par email": "par email",
    "par tag: {0}": function(a) {
      return ["par tag: ", a("0")];
    },
    "plus d\u2019expertises": "plus d\u2019expertises",
    "politique de confidentialit\xE9": "politique de confidentialit\xE9",
    "politique de confidentialit\xE9 des donn\xE9es":
      "politique de confidentialit\xE9 des donn\xE9es",
    "pour poursuivre votre inscription.": "pour poursuivre votre inscription.",
    "profil ajout\xE9": "profil ajout\xE9",
    "profil trouv\xE9": "profil trouv\xE9",
    "profils ajout\xE9": "profils ajout\xE9",
    "profils ajout\xE9s": "profils ajout\xE9s",
    "profils trouv\xE9": "profils trouv\xE9",
    "profils trouv\xE9s": "profils trouv\xE9s",
    "publier mon profil<0/>(pseudonymis\xE9)":
      "publier mon profil<0/>(pseudonymis\xE9)",
    rechercher: "rechercher",
    "rejet du feuille de temps": "rejet du feuille de temps",
    rejeter: "rejeter",
    rejeté: "rejet\xE9",
    rejetée: "rejet\xE9e",
    "revenir \xE0 la page d\u2019accueil":
      "Revenir \xE0 la page d\u2019accueil",
    "r\xE9essayer svp": "R\xE9essayez svp",
    résultats: "r\xE9sultats",
    "s'abonner": "S'abonner",
    "selon vos mots-cl\xE9s": "selon vos mots-cl\xE9s",
    sent: "En attente",
    septembre: "septembre",
    "sign up, club freelance, communaut\xE9, compte, Freelances IT":
      "sign up, Mindquest, communaut\xE9, compte, talents IT",
    "signataire principal": "signataire principal",
    soumettre: "Soumettre",
    special: "sp\xE9cial",
    "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;":
      "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;",
    success: "success",
    "suppression du signataire secondaire":
      "suppression du signataire secondaire",
    "techniques utilis\xE9s sur cette mission":
      "techniques utilis\xE9s sur cette mission",
    "tips and tricks": "tips and tricks",
    tous: "tous",
    "transport en commun": "transport en commun",
    type: "type",
    "un compte entreprise.": "un compte entreprise.",
    "user dashboard": "user dashboard",
    "validation du feuille de temps": "validation du feuille de temps",
    valider: "valider",
    validé: "valid\xE9",
    "veuillez ajouter vos soft skills s\xE9par\xE9es par des virgules.":
      "veuillez ajouter vos soft skills s\xE9par\xE9es par des virgules.",
    "vivier de": "Vivier de",
    voiture: "voiture",
    "votre compte est maintenant valid\xE9":
      "Votre compte est maintenant valid\xE9",
    "votre mot cl\xE9 doit contenir 3 caract\xE8res au minimum":
      "votre mot cl\xE9 doit contenir 3 caract\xE8res au minimum",
    "votre responsable de compte vous contactera dans les plus brefs d\xE9lais.":
      "Votre responsable de compte vous contactera dans les plus brefs d\xE9lais",
    "vous a \xE9t\xE9 envoy\xE9": "vous a \xE9t\xE9 envoy\xE9",
    "vous avez confirm\xE9 votre demande, le changement sera effectif dans un d\xE9lais de 24 heures":
      "vous avez confirm\xE9 votre demande, le changement sera effectif dans un d\xE9lais de 24 heures",
    "vous avez d\xE9ja confirm\xE9 votre demande":
      "vous avez d\xE9ja confirm\xE9 votre demande",
    "vous devez ajouter un commentaire": "vous devez ajouter un commentaire",
    "vous devez fixer les champs erron\xE9s avant":
      "vous devez fixer les champs erron\xE9s avant",
    "vous devez pr\xE9ciser la taille de votre entreprise":
      "Vous devez pr\xE9ciser la taille de votre entreprise",
    "vous devez pr\xE9ciser le code postal avant de lancer le matching":
      "vous devez pr\xE9ciser le code postal avant de lancer le matching",
    "vous devez pr\xE9ciser le rythme de travail":
      "Vous devez pr\xE9ciser le rythme de travail",
    "vous devez pr\xE9ciser le type du contrat":
      "Vous devez pr\xE9ciser le type de contrat",
    "vous devez s\xE9lectionner des mot-cl\xE9s avant de lancer le matching":
      "vous devez s\xE9lectionner des mot-cl\xE9s avant de lancer le matching",
    "w D\xE9couvrez tous nos conseils pour travailler efficacement avec les Freelances IT":
      "w D\xE9couvrez tous nos conseils pour travailler efficacement avec les Freelances IT",
    "warning!": "warning!",
    "{0, plural, one {# Article \xE0 lire} other {# Articles \xE0 lire}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Article \xE0 lire"],
          other: ["#", " Articles \xE0 lire"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other { # Articles to read}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Articles to read"],
          other: [" ", "#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other {# Articles to read}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Articles to read"],
          other: ["#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other {}}": function(a) {
      return [a("0", "plural", { one: ["#", " Articles to read"], other: "" })];
    },
    "{0, plural, one {# jour} other {# jours}}": function(a) {
      return [
        a("0", "plural", { one: ["#", " jour"], other: ["#", " jours"] })
      ];
    },
    "{0, plural, one {# mois} other {# mois}}": function(a) {
      return [a("0", "plural", { one: ["#", " mois"], other: ["#", " mois"] })];
    },
    "{0, plural, one {Resultat} other {resultats}}": function(a) {
      return [a("0", "plural", { one: "Resultat", other: "resultats" })];
    },
    "{0, plural, one {R\xE9sultat} other {r\xE9sultats}}": function(a) {
      return [a("0", "plural", { one: "R\xE9sultat", other: "r\xE9sultats" })];
    },
    "{0, plural, one {{1}} other {{2}}}": function(a) {
      return [a("0", "plural", { one: [a("1")], other: [a("2")] })];
    },
    "{0}": function(a) {
      return [a("0")];
    },
    "{btnText}": function(a) {
      return [a("btnText")];
    },
    "{candidateStatus}": function(a) {
      return [a("candidateStatus")];
    },
    "{days, plural, one {# jour} other {# jours}}": function(a) {
      return [
        a("days", "plural", { one: ["#", " jour"], other: ["#", " jours"] })
      ];
    },
    "{months, plural, one {# mois} other {# mois}}": function(a) {
      return [
        a("months", "plural", { one: ["#", " mois"], other: ["#", " mois"] })
      ];
    },
    "{nResults} r\xE9sultats sont trouv\xE9s": function(a) {
      return [a("nResults"), " r\xE9sultats sont trouv\xE9s"];
    },
    "{sortBy}": function(a) {
      return [a("sortBy")];
    },
    "{sortPool}": function(a) {
      return [a("sortPool")];
    },
    "{type} qui peuvent vous int\xE9resser": function(a) {
      return [a("type"), " qui peuvent vous int\xE9resser"];
    },
    "\xC0 l\u2019\xE9tude": "\xC0 l\u2019\xE9tude",
    "\xC0 partir de {0}": function(a) {
      return ["\xC0 partir de ", a("0")];
    },
    "\xC0 plein temps": "\xC0 temps plein",
    "\xC0 propos": "\xC0 propos",
    "\xC0 propos de Mindquest": "\xC0 propos de Mindquest",
    "\xC0 temps partiel": "\xC0 temps partiel",
    "\xC0 t\xE9l\xE9charger gratuitement":
      "\xC0 t\xE9l\xE9charger gratuitement",
    "\xC9num\xE9rez toutes vos exp\xE9riences professionnelles pertinentes afin que nous puissions nous faire une id\xE9e plus pr\xE9cise de votre profil":
      "\xC9num\xE9rez toutes vos exp\xE9riences professionnelles pertinentes afin que nous puissions nous faire une id\xE9e plus pr\xE9cise de votre profil",
    "\xC9quipes innovation & support": "\xC9quipes innovation & support",
    "\xC9tape 1: Int\xE9grez votre fiche de poste":
      "\xC9tape 1: Int\xE9grez votre fiche de poste",
    "\xC9tape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues":
      "\xC9tape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues",
    "\xC9tape suivante": "\xC9tape suivante",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce certificat?":
      "\xCAtes-vous s\xFBr de vouloir supprimer ce certificat?",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce collaborateur ?":
      "\xCAtes-vous s\xFBr de vouloir supprimer ce collaborateur ?",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce vivier?":
      "\xCAtes-vous s\xFBr de vouloir supprimer ce vivier?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cet exp\xE9rience?":
      "\xCAtes-vous s\xFBr de vouloir supprimer cet exp\xE9rience?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette exp\xE9rience?":
      "\xCAtes-vous s\xFBr de vouloir supprimer cette exp\xE9rience?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette formation?":
      "\xCAtes-vous s\xFBr de vouloir supprimer cette formation?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette plage de jours/heures ?":
      "\xCAtes-vous s\xFBr de vouloir supprimer cette plage de jours/heures ?",
    "\xCAtes-vous s\xFBr de vouloir supprimer le projet ainsi que les plages de journ\xE9es associ\xE9es ?":
      "\xCAtes-vous s\xFBr de vouloir supprimer le projet ainsi que les plages de journ\xE9es associ\xE9es ?",
    "\xCAtes-vous s\xFBr de vouloir supprimer le th\xE8me ainsi que les plages de journ\xE9es associ\xE9es ?":
      "\xCAtes-vous s\xFBr de vouloir supprimer le th\xE8me ainsi que les plages de journ\xE9es associ\xE9es ?",
    "\xCAtre contact\xE9 par :": "\xCAtre contact\xE9 par :",
    "\xCAtre rappel\xE9 par un conseiller":
      "\xCAtre rappel\xE9 par un conseiller",
    "\xCAtre rappel\xE9 par un expert": "\xCAtre rappel\xE9 par un expert",
    à: "\xE0",
    "\xE0 partir de": "\xE0 partir de",
    "\xE0 partir du": "\xE0 partir du",
    "\xEAtre rappel\xE9 par un expert": "\xEAtre rappel\xE9 par un expert",
    "\u201CClub Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C":
      "\u201CMindquest propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C",
    "\u201CJe suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C":
      "\u201CJe suis tr\xE8s satisfaite de Mindquest. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C",
    "\u201CJe suis tr\xE8s satisfaite de Mindquest. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C":
      "\u201CJe suis tr\xE8s satisfaite de Mindquest. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C",
    "\u201CLe service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C":
      "\u201CLe service de Mindquest est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires : aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C",
    "\u201CLe service du Mindquest est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C":
      "\u201CLe service du Mindquest est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C",
    "\u201CMindquest propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C":
      "\u201CMindquest propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C"
  }
};
