const countriesData = [
  {
    flag: "./flags/af.svg",
    countryName: "Afghanistan (افغانستان)",
    codeCountry: "af",
    codePhoneNumber: "+93",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/ax.svg",
    countryName: "Åland Islands",
    codeCountry: "ax",
    codePhoneNumber: "+358",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/al.svg",
    countryName: "Albania (Shqipëri)",
    codeCountry: "al",
    codePhoneNumber: "+355",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/dz.svg",
    countryName: "Algeria (الجزائر)",
    codeCountry: "dz",
    codePhoneNumber: "+213",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/as.svg",
    countryName: "American Samoa",
    codeCountry: "as",
    codePhoneNumber: "+1",
    formatPhoneNumber: "6840124567"
  },
  {
    flag: "./flags/ad.svg",
    countryName: "Andorra",
    codeCountry: "ad",
    codePhoneNumber: "+376",
    formatPhoneNumber: "123567"
  },
  {
    flag: "./flags/ao.svg",
    countryName: "Angola",
    codeCountry: "ao",
    codePhoneNumber: "+244",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/ai.svg",
    countryName: "Anguilla",
    codeCountry: "ai",
    codePhoneNumber: "+1",
    formatPhoneNumber: "2640124567"
  },
  {
    flag: "./flags/aq.svg",
    countryName: "Antarctica",
    codeCountry: "aq",
    codePhoneNumber: "+672",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/ag.svg",
    countryName: "Antigua and Barbuda",
    codeCountry: "ag",
    codePhoneNumber: "+1",
    formatPhoneNumber: "2680124567"
  },
  {
    flag: "./flags/ar.svg",
    countryName: "Argentina",
    codeCountry: "ar",
    codePhoneNumber: "+54",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/am.svg",
    countryName: "Armenia (Հայաստան)",
    codeCountry: "am",
    codePhoneNumber: "+374",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/aw.svg",
    countryName: "Aruba",
    codeCountry: "aw",
    codePhoneNumber: "+297",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/au.svg",
    countryName: "Australia",
    codeCountry: "au",
    codePhoneNumber: "+61",
    formatPhoneNumber: " 12356791011"
  },
  {
    flag: "./flags/at.svg",
    countryName: "Austria (Österreich)",
    codeCountry: "at",
    codePhoneNumber: "+43",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/az.svg",
    countryName: "Azerbaijan (Azərbaycan)",
    codeCountry: "az",
    codePhoneNumber: "+994",
    formatPhoneNumber: "12456891112"
  },
  {
    flag: "./flags/bs.svg",
    countryName: "Bahamas",
    codeCountry: "bs",
    codePhoneNumber: "+1",
    formatPhoneNumber: "2420124567"
  },
  {
    flag: "./flags/bh.svg",
    countryName: "Bahrain (البحرين)",
    codeCountry: "bh",
    codePhoneNumber: "+973",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/bd.svg",
    countryName: "Bangladesh (বাংলাদেশ)",
    codeCountry: "bd",
    codePhoneNumber: "+880",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/bb.svg",
    countryName: "Barbados",
    codeCountry: "bb",
    codePhoneNumber: "+1",
    formatPhoneNumber: "246012-4567"
  },
  {
    flag: "./flags/by.svg",
    countryName: "Belarus (Беларусь)",
    codeCountry: "by",
    codePhoneNumber: "+375",
    formatPhoneNumber: "12456891112"
  },
  {
    flag: "./flags/be.svg",
    countryName: "Belgium (België)",
    codeCountry: "be",
    codePhoneNumber: "+32",
    formatPhoneNumber: "12356891112"
  },
  {
    flag: "./flags/bz.svg",
    countryName: "Belize",
    codeCountry: "bz",
    codePhoneNumber: "+501",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/bj.svg",
    countryName: "Benin (Bénin)",
    codeCountry: "bj",
    codePhoneNumber: "+229",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/bm.svg",
    countryName: "Bermuda",
    codeCountry: "bm",
    codePhoneNumber: "+1",
    formatPhoneNumber: "4410124567"
  },
  {
    flag: "./flags/bt.svg",
    countryName: "./flags/Bhutan (འབྲུག)",
    codeCountry: "bt",
    codePhoneNumber: "+975",
    formatPhoneNumber: "1345789"
  },
  {
    flag: "./flags/bo.svg",
    countryName: "Bolivia",
    codeCountry: "bo",
    codePhoneNumber: "+591",
    formatPhoneNumber: "134578910"
  },
  {
    flag: "./flags/ba.svg",
    countryName: "Bosnia and Herzegovina (Босна и Херцеговина)",
    codeCountry: "ba",
    codePhoneNumber: "+387",
    formatPhoneNumber: "124567"
  },
  {
    flag: "./flags/bw.svg",
    countryName: "Botswana",
    codeCountry: "bw",
    codePhoneNumber: "+267",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/bv.svg",
    countryName: "Bouvet Island",
    codeCountry: "bv",
    codePhoneNumber: "+47",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/br.svg",
    countryName: "Brazil (Brasil)",
    codeCountry: "br",
    codePhoneNumber: "+55",
    formatPhoneNumber: "1245679101112"
  },
  {
    flag: "./flags/io.svg",
    countryName: "British Indian Ocean Territory",
    codeCountry: "io",
    codePhoneNumber: "+246",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/vg.svg",
    countryName: "British Virgin Islands",
    codeCountry: "vg",
    codePhoneNumber: "+1",
    formatPhoneNumber: "2840124567"
  },
  {
    flag: "./flags/bn.svg",
    countryName: "Brunei",
    codeCountry: "bn",
    codePhoneNumber: "+673",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/bg.svg",
    countryName: "Bulgaria (България)",
    codeCountry: "bg",
    codePhoneNumber: "+359",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/bf.svg",
    countryName: "Burkina Faso",
    codeCountry: "bf",
    codePhoneNumber: "+226",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/bi.svg",
    countryName: "Burundi (Uburundi)",
    codeCountry: "bi",
    codePhoneNumber: "+257",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/kh.svg",
    countryName: "Cambodia (កម្ពុជា)",
    codeCountry: "kh",
    codePhoneNumber: "+855",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/cm.svg",
    countryName: "Cameroon (Cameroun)",
    codeCountry: "cm",
    codePhoneNumber: "+237",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/ca.svg",
    countryName: "Canada",
    codeCountry: "ca",
    codePhoneNumber: "+1",
    formatPhoneNumber: "23478911121314"
  },
  {
    flag: "./flags/cv.svg",
    countryName: "Cape Verde (Kabu Verdi)",
    codeCountry: "cv",
    codePhoneNumber: "+238",
    formatPhoneNumber: "1235689"
  },
  {
    flag: "./flags/bq.svg",
    countryName: "Caribbean Netherlands",
    codeCountry: "bq",
    codePhoneNumber: "+599",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/ky.svg",
    countryName: "Cayman Islands",
    codeCountry: "ky",
    codePhoneNumber: "+1",
    formatPhoneNumber: "3450124567"
  },
  {
    flag: "./flags/cf.svg",
    countryName: "Central African Republic (République centrafricaine)",
    codeCountry: "cf",
    codePhoneNumber: "+236",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/td.svg",
    countryName: "Chad (Tchad)",
    codeCountry: "td",
    codePhoneNumber: "+235",
    formatPhoneNumber: "1245781011"
  },
  {
    flag: "./flags/cl.svg",
    countryName: "Chile",
    codeCountry: "cl",
    codePhoneNumber: "+56",
    formatPhoneNumber: "13456891011"
  },
  {
    flag: "./flags/cn.svg",
    countryName: "China (中国)",
    codeCountry: "cn",
    codePhoneNumber: "+86",
    formatPhoneNumber: "124567891011"
  },
  {
    flag: "./flags/cx.svg",
    countryName: "Christmas Island",
    codeCountry: "cx",
    codePhoneNumber: "+61",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/cc.svg",
    countryName: "Cocos (Keeling) Islands",
    codeCountry: "cc",
    codePhoneNumber: "+61",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/co.svg",
    countryName: "Colombia",
    codeCountry: "co",
    codePhoneNumber: "+57",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/km.svg",
    countryName: "Comoros (جزر القمر)",
    codeCountry: "km",
    codePhoneNumber: "+269",
    formatPhoneNumber: "1245678"
  },
  {
    flag: "./flags/cd.svg",
    countryName: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    codeCountry: "cd",
    codePhoneNumber: "+243",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/cg.svg",
    countryName: "Congo (Republic) (Congo-Brazzaville)",
    codeCountry: "cg",
    codePhoneNumber: "+242",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/ck.svg",
    countryName: "Cook Islands",
    codeCountry: "ck",
    codePhoneNumber: "+682",
    formatPhoneNumber: "12456"
  },
  {
    flag: "./flags/cr.svg",
    countryName: "Costa Rica",
    codeCountry: "cr",
    codePhoneNumber: "+506",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/ci.svg",
    countryName: "Côte dIvoire",
    codeCountry: "ci",
    codePhoneNumber: "+225",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/hr.svg",
    countryName: "Croatia (Hrvatska)",
    codeCountry: "hr",
    codePhoneNumber: "+385",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/cu.svg",
    countryName: "Cuba",
    codeCountry: "cu",
    codePhoneNumber: "+53",
    formatPhoneNumber: "134578910"
  },
  {
    flag: "./flags/cw.svg",
    countryName: "Curaçao",
    codeCountry: "cw",
    codePhoneNumber: "+599",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/cy.svg",
    countryName: "Cyprus (Κύπρος)",
    codeCountry: "cy",
    codePhoneNumber: "+357",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/cz.svg",
    countryName: "Czech Republic (Česká republika)",
    codeCountry: "cz",
    codePhoneNumber: "+420",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/dk.svg",
    countryName: "Denmark (Danmark)",
    codeCountry: "dk",
    codePhoneNumber: "+45",
    formatPhoneNumber: "1245781011"
  },
  {
    flag: "./flags/dj.svg",
    countryName: "Djibouti",
    codeCountry: "dj",
    codePhoneNumber: "+253",
    formatPhoneNumber: "1245781011"
  },
  {
    flag: "./flags/dm.svg",
    countryName: "Dominica",
    codeCountry: "dm",
    codePhoneNumber: "+1",
    formatPhoneNumber: "7670124567"
  },
  {
    flag: "./flags/do.svg",
    countryName: "Dominican Republic (República Dominicana)",
    codeCountry: "do",
    codePhoneNumber: "+1",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/ec.svg",
    countryName: "Ecuador",
    codeCountry: "ec",
    codePhoneNumber: "+593",
    formatPhoneNumber: "134578910"
  },
  {
    flag: "./flags/eg.svg",
    countryName: "Egypt (مصر)",
    codeCountry: "eg",
    codePhoneNumber: "+20",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/sv.svg",
    countryName: "El Salvador",
    codeCountry: "sv",
    codePhoneNumber: "+503",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/gq.svg",
    countryName: "Equatorial Guinea (Guinea Ecuatorial)",
    codeCountry: "gq",
    codePhoneNumber: "+240",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/r.svg",
    countryName: "Eritrea",
    codeCountry: "er",
    codePhoneNumber: "+291",
    formatPhoneNumber: "1345789"
  },
  {
    flag: "./flags/ee.svg",
    countryName: "Estonia (Eesti)",
    codeCountry: "ee",
    codePhoneNumber: "+372",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/et.svg",
    countryName: "Ethiopia",
    codeCountry: "et",
    codePhoneNumber: "+251",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/fk.svg",
    countryName: "Falkland Islands (Islas Malvinas)",
    codeCountry: "fk",
    codePhoneNumber: "+500",
    formatPhoneNumber: "12345"
  },
  {
    flag: "./flags/fo.svg",
    countryName: "Faroe Islands (Føroyar)",
    codeCountry: "fo",
    codePhoneNumber: "+298",
    formatPhoneNumber: "123567"
  },
  {
    flag: "./flags/fj.svg",
    countryName: "Fiji",
    codeCountry: "fj",
    codePhoneNumber: "+679",
    formatPhoneNumber: "1245678"
  },
  {
    flag: "./flags/fi.svg",
    countryName: "Finland (Suomi)",
    codeCountry: "fi",
    codePhoneNumber: "+358",
    formatPhoneNumber: "1245679101112"
  },
  {
    flag: "./flags/fr.svg",
    countryName: "France",
    codeCountry: "fr",
    codePhoneNumber: "+33",
    formatPhoneNumber: "12456791011"
  },
  {
    flag: "./flags/gf.svg",
    countryName: "French Guiana (Guyane française)",
    codeCountry: "gf",
    codePhoneNumber: "+594",
    formatPhoneNumber: "1234578910"
  },
  {
    flag: "./flags/pf.svg",
    countryName: "French Polynesia (Polynésie française)",
    codeCountry: "pf",
    codePhoneNumber: "+689",
    formatPhoneNumber: "124578"
  },
  {
    flag: "./flags/tf.svg",
    countryName: "French Southern and Antarctic Lands",
    codeCountry: "tf",
    codePhoneNumber: "+262",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/ga.svg",
    countryName: "Gabon",
    codeCountry: "ga",
    codePhoneNumber: "+241",
    formatPhoneNumber: "13467910"
  },
  {
    flag: "./flags/gm.svg",
    countryName: "Gambia",
    codeCountry: "gm",
    codePhoneNumber: "+220",
    formatPhoneNumber: "1235689"
  },
  {
    flag: "./flags/ge.svg",
    countryName: "Georgia (საქართველო)",
    codeCountry: "ge",
    codePhoneNumber: "+995",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/de.svg",
    countryName: "Germany (Deutschland)",
    codeCountry: "de",
    codePhoneNumber: "+49",
    formatPhoneNumber: " 123567891011"
  },
  {
    flag: "./flags/gh.svg",
    countryName: "Ghana (Gaana)",
    codeCountry: "gh",
    codePhoneNumber: "+233",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/gi.svg",
    countryName: "Gibraltar",
    codeCountry: "gi",
    codePhoneNumber: "+350",
    formatPhoneNumber: "12356789"
  },
  {
    flag: "./flags/gr.svg",
    countryName: "Greece (Ελλάδα)",
    codeCountry: "gr",
    codePhoneNumber: "+30",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/gl.svg",
    countryName: "Greenland (Kalaallit Nunaat)",
    codeCountry: "gl",
    codePhoneNumber: "+299",
    formatPhoneNumber: "124578"
  },
  {
    flag: "./flags/gd.svg",
    countryName: "Grenada",
    codeCountry: "gd",
    codePhoneNumber: "+1",
    formatPhoneNumber: "4730124567"
  },
  {
    flag: "./flags/gp.svg",
    countryName: "Guadeloupe",
    codeCountry: "gp",
    codePhoneNumber: "+590",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/gu.svg",
    countryName: "Guam",
    codeCountry: "gu",
    codePhoneNumber: "+1",
    formatPhoneNumber: "6710124567"
  },
  {
    flag: "./flags/gt.svg",
    countryName: "Guatemala",
    codeCountry: "gt",
    codePhoneNumber: "+502",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/gg.svg",
    countryName: "Guernsey",
    codeCountry: "gg",
    codePhoneNumber: "+44",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/gn.svg",
    countryName: "Guinea (Guinée)",
    codeCountry: "gn",
    codePhoneNumber: "+224",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/gw.svg",
    countryName: "Guinea-Bissau (Guiné Bissau)",
    codeCountry: "gw",
    codePhoneNumber: "+245",
    formatPhoneNumber: "1345678"
  },
  {
    flag: "./flags/gy.svg",
    countryName: "Guyana",
    codeCountry: "gy",
    codePhoneNumber: "+592",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/ht.svg",
    countryName: "Haiti",
    codeCountry: "ht",
    codePhoneNumber: "+509",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/hm.svg",
    countryName: "Heard Island and McDonald Islands",
    codeCountry: "hm",
    codePhoneNumber: "+672",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/hn.svg",
    countryName: "Honduras",
    codeCountry: "hn",
    codePhoneNumber: "+504",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/hk.svg",
    countryName: "Hong Kong (香港)",
    codeCountry: "hk",
    codePhoneNumber: "+852",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/hu.svg",
    countryName: "Hungary (Magyarország)",
    codeCountry: "hu",
    codePhoneNumber: "+36",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/is.svg",
    countryName: "Iceland (Ísland)",
    codeCountry: "is",
    codePhoneNumber: "+354",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/in.svg",
    countryName: "India (भारत)",
    codeCountry: "in",
    codePhoneNumber: "+91",
    formatPhoneNumber: "123457891011"
  },
  {
    flag: "./flags/id.svg",
    countryName: "Indonesia",
    codeCountry: "id",
    codePhoneNumber: "+62",
    formatPhoneNumber: "1245689"
  },
  {
    flag: "./flags/ir.svg",
    countryName: "Iran (ایران)",
    codeCountry: "ir",
    codePhoneNumber: "+98",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/iq.svg",
    countryName: "Iraq (العراق)",
    codeCountry: "iq",
    codePhoneNumber: "+964",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/ie.svg",
    countryName: "Ireland",
    codeCountry: "ie",
    codePhoneNumber: "+353",
    formatPhoneNumber: "1245678910"
  },
  {
    flag: "./flags/im.svg",
    countryName: "Isle of Man",
    codeCountry: "im",
    codePhoneNumber: "+44",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/it.svg",
    countryName: "Italy (Italia)",
    codeCountry: "it",
    codePhoneNumber: "+39",
    formatPhoneNumber: "1235678910"
  },
  {
    flag: "./flags/jm.svg",
    countryName: "Jamaica",
    codeCountry: "jm",
    codePhoneNumber: "+1",
    formatPhoneNumber: "8760124567"
  },
  {
    flag: "./flags/jp.svg",
    countryName: "Japan (日本)",
    codeCountry: "jp",
    codePhoneNumber: "+81",
    formatPhoneNumber: "12356891011"
  },
  {
    flag: "./flags/je.svg",
    countryName: "Jersey",
    codeCountry: "je",
    codePhoneNumber: "+44",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/jo.svg",
    countryName: "Jordan (الأردن)",
    codeCountry: "jo",
    codePhoneNumber: "+962",
    formatPhoneNumber: "13456891011"
  },
  {
    flag: "./flags/kz.svg",
    countryName: "Kazakhstan (Казахстан)",
    codeCountry: "kz",
    codePhoneNumber: "+7",
    formatPhoneNumber: "1235679101213"
  },
  {
    flag: "./flags/ke.svg",
    countryName: "Kenya",
    codeCountry: "ke",
    codePhoneNumber: "+254",
    formatPhoneNumber: "1235678910"
  },
  {
    flag: "./flags/ki.svg",
    countryName: "Kiribati",
    codeCountry: "ki",
    codePhoneNumber: "+686",
    formatPhoneNumber: "12456"
  },
  {
    flag: "./flags/xk.svg",
    countryName: "Kosovo",
    codeCountry: "xk",
    codePhoneNumber: "+383",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/kw.svg",
    countryName: "Kuwait (الكويت)",
    codeCountry: "kw",
    codePhoneNumber: "+965",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/kg.svg",
    countryName: "Kyrgyzstan (Кыргызстан)",
    codeCountry: "kg",
    codePhoneNumber: "+996",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/la.svg",
    countryName: "Laos (ລາວ)",
    codeCountry: "la",
    codePhoneNumber: "+856",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/lv.svg",
    countryName: "Latvia (Latvija)",
    codeCountry: "lv",
    codePhoneNumber: "+371",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/lb.svg",
    countryName: "Lebanon (لبنان)",
    codeCountry: "lb",
    codePhoneNumber: "+961",
    formatPhoneNumber: "1345789"
  },
  {
    flag: "./flags/ls.svg",
    countryName: "Lesotho",
    codeCountry: "ls",
    codePhoneNumber: "+266",
    formatPhoneNumber: "134578910"
  },
  {
    flag: "./flags/lr.svg",
    countryName: "Liberia",
    codeCountry: "lr",
    codePhoneNumber: "+231",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/ly.svg",
    codeCountry: "ly",
    countryName: "Libya (ليبيا)",
    codePhoneNumber: "+218",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/li.svg",
    codeCountry: "li",
    countryName: "Liechtenstein",
    codePhoneNumber: "+423",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/lt.svg",
    codeCountry: "lt",
    countryName: "Lithuania (Lietuva)",
    codePhoneNumber: "+370",
    formatPhoneNumber: "123568910"
  },
  {
    flag: "./flags/lu.svg",
    codeCountry: "lu",
    countryName: "Luxembourg",
    codePhoneNumber: "+352",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/mo.svg",
    codeCountry: "mo",
    countryName: "Macau (澳門)",
    codePhoneNumber: "+853",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/mk.svg",
    codeCountry: "mk",
    countryName: "Macedonia (FYROM) (Македонија)",
    codePhoneNumber: "+389",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/mg.svg",
    codeCountry: "mg",
    countryName: "Madagascar (Madagasikara)",
    codePhoneNumber: "+261",
    formatPhoneNumber: "12457891011"
  },
  {
    flag: "./flags/mw.svg",
    codeCountry: "mw",
    countryName: "Malawi",
    codePhoneNumber: "+265",
    formatPhoneNumber: "13456891011"
  },
  {
    flag: "./flags/my.svg",
    codeCountry: "my",
    countryName: "Malaysia",
    codePhoneNumber: "+60",
    formatPhoneNumber: "1245679101112"
  },
  {
    flag: "./flags/mv.svg",
    codeCountry: "mv",
    countryName: "Maldives",
    codePhoneNumber: "+960",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/mt.svg",
    countryName: "Malta",
    codeCountry: "mt",
    codePhoneNumber: "+356",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/ml.svg",
    countryName: "Mali",
    codeCountry: "ml",
    codePhoneNumber: "+223",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/mh.svg",
    countryName: "Marshall Islands",
    codeCountry: "mh",
    codePhoneNumber: "+692",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/mq.svg",
    countryName: "Martinique",
    codeCountry: "mq",
    codePhoneNumber: "+596",
    formatPhoneNumber: "12356891112"
  },
  {
    flag: "./flags/mr.svg",
    countryName: "Mauritania (موريتانيا)",
    codeCountry: "mr",
    codePhoneNumber: "+222",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/mu.svg",
    countryName: "Mauritius (Moris)",
    codeCountry: "mu",
    codePhoneNumber: "+230",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/yt.svg",
    countryName: "Mayotte",
    codeCountry: "yt",
    codePhoneNumber: "+262",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/mx.svg",
    countryName: "Mexico (México)",
    codeCountry: "mx",
    codePhoneNumber: "+52",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/fm.svg",
    countryName: "Micronesia",
    codeCountry: "fm",
    codePhoneNumber: "+691",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/md.svg",
    countryName: "Moldova (Republica Moldova)",
    codeCountry: "md",
    codePhoneNumber: "+373",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/mc.svg",
    countryName: "Monaco",
    codeCountry: "mc",
    codePhoneNumber: "+377",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/mn.svg",
    countryName: "Mongolia (Монгол)",
    codeCountry: "mn",
    codePhoneNumber: "+976",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/me.svg",
    countryName: "Montenegro (Crna Gora)",
    codeCountry: "me",
    codePhoneNumber: "+382",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/ms.svg",
    countryName: "Montserrat",
    codeCountry: "ms",
    codePhoneNumber: "+1",
    formatPhoneNumber: "6640124567"
  },
  {
    flag: "./flags/ma.svg",
    countryName: "Morocco (المغرب)",
    codeCountry: "ma",
    codePhoneNumber: "+212",
    formatPhoneNumber: "12456791011"
  },
  {
    flag: "./flags/mz.svg",
    countryName: "Mozambique (Moçambique)",
    codeCountry: "mz",
    codePhoneNumber: "+258",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/mm.svg",
    countryName: "Myanmar (Burma) (မြန်မာ)",
    codeCountry: "mm",
    codePhoneNumber: "+95",
    formatPhoneNumber: "123567"
  },
  {
    flag: "./flags/na.svg",
    countryName: "Namibia (Namibië)",
    codeCountry: "na",
    codePhoneNumber: "+264",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/nr.svg",
    countryName: "Nauru",
    codeCountry: "nr",
    codePhoneNumber: "+674",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/np.svg",
    countryName: "Nepal (नेपाल)",
    codeCountry: "np",
    codePhoneNumber: "+977",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/nl.svg",
    countryName: "Netherlands (Nederland)",
    codeCountry: "nl",
    codePhoneNumber: "+31",
    formatPhoneNumber: "124567891011"
  },
  {
    flag: "./flags/nc.svg",
    countryName: "New Caledonia (Nouvelle-Calédonie)",
    codeCountry: "nc",
    codePhoneNumber: "+687",
    formatPhoneNumber: "124567"
  },
  {
    flag: "./flags/nz.svg",
    countryName: "New Zealand",
    codeCountry: "nz",
    codePhoneNumber: "+64",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/ni.svg",
    countryName: "Nicaragua",
    codeCountry: "ni",
    codePhoneNumber: "+505",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/ne.svg",
    countryName: "Niger (Nijar)",
    codeCountry: "ne",
    codePhoneNumber: "+227",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/ng.svg",
    countryName: "Nigeria",
    codeCountry: "ng",
    codePhoneNumber: "+234",
    formatPhoneNumber: "1245689"
  },
  {
    flag: "./flags/nu.svg",
    countryName: "Niue",
    codeCountry: "nu",
    codePhoneNumber: "+683",
    formatPhoneNumber: "1234"
  },
  {
    flag: "./flags/nf.svg",
    countryName: "Norfolk Island",
    codeCountry: "nf",
    codePhoneNumber: "+672",
    formatPhoneNumber: "123567"
  },
  {
    flag: "./flags/kp.svg",
    countryName: "North Korea (조선 민주주의 인민 공화국)",
    codeCountry: "kp",
    codePhoneNumber: "+850",
    formatPhoneNumber: "123567"
  },
  {
    flag: "./flags/mp.svg",
    countryName: "Northern Mariana Islands",
    codeCountry: "mp",
    codePhoneNumber: "+1",
    formatPhoneNumber: "6700124567"
  },
  {
    flag: "./flags/no.svg",
    countryName: "Norway (Norge)",
    codeCountry: "no",
    codePhoneNumber: "+47",
    formatPhoneNumber: "123568910"
  },
  {
    flag: "./flags/om.svg",
    countryName: "Oman (عُمان)",
    codeCountry: "om",
    codePhoneNumber: "+968",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/pk.svg",
    countryName: "Pakistan (پاکستان)",
    codeCountry: "pk",
    codePhoneNumber: "+92",
    formatPhoneNumber: "123567891011"
  },
  {
    flag: "./flags/pw.svg",
    countryName: "Palau",
    codeCountry: "pw",
    codePhoneNumber: "+680",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/ps.svg",
    countryName: "Palestine (فلسطين)",
    codeCountry: "ps",
    codePhoneNumber: "+970",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/pa.svg",
    countryName: "Panama (Panamá)",
    codeCountry: "pa",
    codePhoneNumber: "+507",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/pg.svg",
    countryName: "Papua New Guinea",
    codeCountry: "pg",
    codePhoneNumber: "+675",
    formatPhoneNumber: "123568910"
  },
  {
    flag: "./flags/py.svg",
    countryName: "Paraguay",
    codeCountry: "py",
    codePhoneNumber: "+595",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/pe.svg",
    countryName: "Peru (Perú)",
    codeCountry: "pe",
    codePhoneNumber: "+51",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/ph.svg",
    countryName: "Philippines",
    codeCountry: "ph",
    codePhoneNumber: "+63",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/pn.svg",
    countryName: "Pitcairn Islands",
    codeCountry: "pn",
    codePhoneNumber: "+64",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/pl.svg",
    countryName: "Poland (Polska)",
    codeCountry: "pl",
    codePhoneNumber: "+48",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/pt.svg",
    countryName: "Portugal",
    codeCountry: "pt",
    codePhoneNumber: "+351",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/pr.svg",
    countryName: "Puerto Rico",
    codeCountry: "pr",
    codePhoneNumber: "+1",
    formatPhoneNumber: "23478911121314"
  },
  {
    flag: "./flags/qa.svg",
    countryName: "Qatar (قطر)",
    codeCountry: "qa",
    codePhoneNumber: "+974",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/re.svg",
    countryName: "Réunion (La Réunion)",
    codeCountry: "re",
    codePhoneNumber: "+262",
    formatPhoneNumber: "1234578910"
  },
  {
    flag: "./flags/ro.svg",
    countryName: "Romania (România)",
    codeCountry: "ro",
    codePhoneNumber: "+40",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/ru.svg",
    countryName: "Russia (Россия)",
    codeCountry: "ru",
    codePhoneNumber: "+7",
    formatPhoneNumber: "1235679101213"
  },
  {
    flag: "./flags/rw.svg",
    countryName: "Rwanda",
    codeCountry: "rw",
    codePhoneNumber: "+250",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/bl.svg",
    countryName: "Saint Barthélemy (Saint-Barthélemy)",
    codeCountry: "bl",
    codePhoneNumber: "+590",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/sh.svg",
    countryName: "Saint Helena",
    codeCountry: "sh",
    codePhoneNumber: "+290",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/kn.svg",
    countryName: "Saint Kitts and Nevis",
    codeCountry: "kn",
    codePhoneNumber: "+1",
    formatPhoneNumber: "8690124567"
  },
  {
    flag: "./flags/lc.svg",
    countryName: "Saint Lucia",
    codeCountry: "lc",
    codePhoneNumber: "+1",
    formatPhoneNumber: "7580124567"
  },
  {
    flag: "./flags/mf.svg",
    countryName: "Saint Martin (Saint-Martin (partie française))",
    codeCountry: "mf",
    codePhoneNumber: "+590",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/pm.svg",
    countryName: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    codeCountry: "pm",
    codePhoneNumber: "+508",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/vc.svg",
    countryName: "Saint Vincent and the Grenadines",
    codeCountry: "vc",
    codePhoneNumber: "+1",
    formatPhoneNumber: "7840124567"
  },
  {
    flag: "./flags/ws.svg",
    countryName: "Samoa",
    codeCountry: "ws",
    codePhoneNumber: "+685",
    formatPhoneNumber: "124567"
  },
  {
    flag: "./flags/sm.svg",
    countryName: "San Marino",
    codeCountry: "sm",
    codePhoneNumber: "+378",
    formatPhoneNumber: "123467891011"
  },
  {
    flag: "./flags/st.svg",
    countryName: "São Tomé and Príncipe (São Tomé e Príncipe)",
    codeCountry: "st",
    codePhoneNumber: "+239",
    formatPhoneNumber: "1245678"
  },
  {
    flag: "./flags/sa.svg",
    countryName: "Saudi Arabia (المملكة العربية السعودية)",
    codeCountry: "sa",
    codePhoneNumber: "+966",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/sn.svg",
    countryName: "Senegal (Sénégal)",
    codeCountry: "sn",
    codePhoneNumber: "+221",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/rs.svg",
    countryName: "Serbia (Србија)",
    codeCountry: "rs",
    codePhoneNumber: "+381",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/sc.svg",
    countryName: "Seychelles",
    codeCountry: "sc",
    codePhoneNumber: "+248",
    formatPhoneNumber: "1345789"
  },
  {
    flag: "./flags/sl.svg",
    countryName: "Sierra Leone",
    codeCountry: "sl",
    codePhoneNumber: "+232",
    formatPhoneNumber: "12456789"
  },
  {
    flag: "./flags/sg.svg",
    countryName: "Singapore",
    codeCountry: "sg",
    codePhoneNumber: "+65",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/sx.svg",
    countryName: "Sint Maarten",
    codeCountry: "sx",
    codePhoneNumber: "+1",
    formatPhoneNumber: "7210124567"
  },
  {
    flag: "./flags/sk.svg",
    countryName: "Slovakia (Slovensko)",
    codeCountry: "sk",
    codePhoneNumber: "+421",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/si.svg",
    countryName: "Slovenia (Slovenija)",
    codeCountry: "si",
    codePhoneNumber: "+386",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/sb.svg",
    countryName: "Solomon Islands",
    codeCountry: "sb",
    codePhoneNumber: "+677",
    formatPhoneNumber: "12345"
  },
  {
    flag: "./flags/so.svg",
    countryName: "Somalia (Soomaaliya)",
    codeCountry: "so",
    codePhoneNumber: "+252",
    formatPhoneNumber: "1345789"
  },
  {
    flag: "./flags/za.svg",
    countryName: "South Africa",
    codeCountry: "za",
    codePhoneNumber: "+27",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/gs.svg",
    countryName: "South Georgia and the South Sandwich Islands",
    codeCountry: "gs",
    codePhoneNumber: "+500",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/kr.svg",
    countryName: "South Korea (대한민국)",
    codeCountry: "kr",
    codePhoneNumber: "+82",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/ss.svg",
    countryName: "South Sudan (جنوب السودان)",
    codeCountry: "ss",
    codePhoneNumber: "+211",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/es.svg",
    countryName: "Spain (España)",
    codeCountry: "es",
    codePhoneNumber: "+34",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/lk.svg",
    countryName: "Sri Lanka (ශ්‍රී ලංකාව)",
    codeCountry: "lk",
    codePhoneNumber: "+94",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/sd.svg",
    countryName: "Sudan (السودان)",
    codeCountry: "sd",
    codePhoneNumber: "+249",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/sr.svg",
    countryName: "Suriname",
    codeCountry: "sr",
    codePhoneNumber: "+597",
    formatPhoneNumber: "123567"
  },
  {
    flag: "./flags/sj.svg",
    countryName: "Svalbard and Jan Mayen",
    codeCountry: "sj",
    codePhoneNumber: "+47",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/sz.svg",
    countryName: "Swaziland",
    codeCountry: "sz",
    codePhoneNumber: "+268",
    formatPhoneNumber: "124578910"
  },
  {
    flag: "./flags/se.svg",
    countryName: "Sweden (Sverige)",
    codeCountry: "se",
    codePhoneNumber: "+46",
    formatPhoneNumber: "12456891112"
  },
  {
    flag: "./flags/ch.svg",
    countryName: "Switzerland (Schweiz)",
    codeCountry: "ch",
    codePhoneNumber: "+41",
    formatPhoneNumber: "12456891112"
  },
  {
    flag: "./flags/sy.svg",
    countryName: "Syria (سوريا)",
    codeCountry: "sy",
    codePhoneNumber: "+963",
    formatPhoneNumber: "12456791011"
  },
  {
    flag: "./flags/tw.svg",
    countryName: "Taiwan (台灣)",
    codeCountry: "tw",
    codePhoneNumber: "+886",
    formatPhoneNumber: "12346789"
  },
  {
    flag: "./flags/tj.svg",
    countryName: "Tajikistan",
    codeCountry: "tj",
    codePhoneNumber: "+992",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/tz.svg",
    countryName: "Tanzania",
    codeCountry: "tz",
    codePhoneNumber: "+255",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/th.svg",
    countryName: "Thailand (ไทย)",
    codeCountry: "th",
    codePhoneNumber: "+66",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/tl.svg",
    countryName: "Timor-Leste",
    codeCountry: "tl",
    codePhoneNumber: "+670",
    formatPhoneNumber: "1235678"
  },
  {
    flag: "./flags/tg.svg",
    countryName: "Togo",
    codeCountry: "tg",
    codePhoneNumber: "+228",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/tk.svg",
    countryName: "Tokelau",
    codeCountry: "tk",
    codePhoneNumber: "+690",
    formatPhoneNumber: "1234"
  },
  {
    flag: "./flags/to.svg",
    countryName: "Tonga",
    codeCountry: "to",
    codePhoneNumber: "+676",
    formatPhoneNumber: "12345"
  },
  {
    flag: "./flags/tt.svg",
    countryName: "Trinidad and Tobago",
    codeCountry: "tt",
    codePhoneNumber: "+1",
    formatPhoneNumber: "8680124567"
  },
  {
    flag: "./flags/tn.svg",
    countryName: "Tunisia (تونس)",
    codeCountry: "tn",
    codePhoneNumber: "+216",
    formatPhoneNumber: "124568910"
  },
  {
    flag: "./flags/tr.svg",
    countryName: "Turkey (Türkiye)",
    codeCountry: "tr",
    codePhoneNumber: "+90",
    formatPhoneNumber: "1235679101213"
  },
  {
    flag: "./flags/tm.svg",
    countryName: "Turkmenistan",
    codeCountry: "tm",
    codePhoneNumber: "+993",
    formatPhoneNumber: "134578910"
  },
  {
    flag: "./flags/tc.svg",
    countryName: "Turks and Caicos Islands",
    codeCountry: "tc",
    codePhoneNumber: "+1",
    formatPhoneNumber: "6490124567"
  },
  {
    flag: "./flags/tv.svg",
    countryName: "Tuvalu",
    codeCountry: "tv",
    codePhoneNumber: "+688",
    formatPhoneNumber: "12345"
  },
  {
    flag: "./flags/vi.svg",
    countryName: "U.S. Virgin Islands",
    codeCountry: "vi",
    codePhoneNumber: "+1",
    formatPhoneNumber: "3400124567"
  },
  {
    flag: "./flags/ug.svg",
    countryName: "Uganda",
    codeCountry: "ug",
    codePhoneNumber: "+256",
    formatPhoneNumber: "12356791011"
  },
  {
    flag: "./flags/ua.svg",
    countryName: "Ukraine (Україна)",
    codeCountry: "ua",
    codePhoneNumber: "+380",
    formatPhoneNumber: "12456891112"
  },
  {
    flag: "./flags/ae.svg",
    countryName: "United Arab Emirates (الإمارات العربية المتحدة)",
    codeCountry: "ae",
    codePhoneNumber: "+971",
    formatPhoneNumber: "134578910"
  },
  {
    flag: "./flags/gb.svg",
    countryName: "United Kingdom",
    codeCountry: "gb",
    codePhoneNumber: "+44",
    formatPhoneNumber: "123467891011"
  },
  {
    flag: "./flags/us.svg",
    countryName: "United States",
    codeCountry: "us",
    codePhoneNumber: "+1",
    formatPhoneNumber: "23478911121314"
  },
  {
    flag: "./flags/um.svg",
    countryName: "United States Minor Outlying Islands",
    codeCountry: "um",
    codePhoneNumber: "+1",
    formatPhoneNumber: ""
  },
  {
    flag: "./flags/uy.svg",
    countryName: "Uruguay",
    codeCountry: "uy",
    codePhoneNumber: "+598",
    formatPhoneNumber: "1345781011"
  },
  {
    flag: "./flags/uz.svg",
    countryName: "Uzbekistan (Oʻzbekiston)",
    codeCountry: "uz",
    codePhoneNumber: "+998",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/vu.svg",
    countryName: "Vanuatu",
    codeCountry: "vu",
    codePhoneNumber: "+678",
    formatPhoneNumber: "12345"
  },
  {
    flag: "./flags/va.svg",
    countryName: "Vatican City (Città del Vaticano)",
    codeCountry: "va",
    codePhoneNumber: "+39",
    formatPhoneNumber: "1245679101112"
  },
  {
    flag: "./flags/ve.svg",
    countryName: "Venezuela",
    codeCountry: "ve",
    codePhoneNumber: "+58",
    formatPhoneNumber: "1235679101112"
  },
  {
    flag: "./flags/vn.svg",
    countryName: "Vietnam (Việt Nam)",
    codeCountry: "vn",
    codePhoneNumber: "+84",
    formatPhoneNumber: "12456791011"
  },
  {
    flag: "./flags/wf.svg",
    countryName: "Wallis and Futuna",
    codeCountry: "wf",
    codePhoneNumber: "+681",
    formatPhoneNumber: "124567"
  },
  {
    flag: "./flags/eh.svg",
    countryName: "Western Sahara",
    codeCountry: "eh",
    codePhoneNumber: "+212",
    formatPhoneNumber: "124567"
  },
  {
    flag: "./flags/ye.svg",
    countryName: "Yemen (اليمن)",
    codeCountry: "ye",
    codePhoneNumber: "+967",
    formatPhoneNumber: "1345789"
  },
  {
    flag: "./flags/zm.svg",
    countryName: "Zambia",
    codeCountry: "zm",
    codePhoneNumber: "+260",
    formatPhoneNumber: "12456891011"
  },
  {
    flag: "./flags/zw.svg",
    countryName: "Zimbabwe",
    codeCountry: "zw",
    codePhoneNumber: "+263",
    formatPhoneNumber: "1345678"
  }
];

export default countriesData;
