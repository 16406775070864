import React, { Component } from "react";
import * as PropTypes from "prop-types";
import StickyHeader from "./StickyHeaderComponent/StickyHeader";
import { inject, observer } from "mobx-react/index";
@inject("sessionStore")
@observer
class StickyHeaderManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", this.scrollHandler, { passive: true });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    const headerHeight = document.getElementById("__SM__FULL__HEADER")
      .offsetHeight;
    this.setState({ show: window.scrollY >= headerHeight });
  };

  render() {
    const { show } = this.state;
    const {
      title,
      place,
      businessSectors,
      id,
      sessionStore,
      loading
    } = this.props;
    return show ? (
      <StickyHeader
        id={id}
        title={title}
        place={place}
        businessSectors={businessSectors}
        disable={sessionStore.isItDisable}
        loading={loading}
      />
    ) : null;
  }
}

StickyHeaderManager.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  place: PropTypes.string,
  businessSectors: PropTypes.arrayOf(PropTypes.string)
};

StickyHeaderManager.defaultProps = {
  title: "",
  place: "",
  businessSectors: []
};
export default StickyHeaderManager;
