import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import {
  Button,
  SwitchButton3,
  FormCard,
  Validation,
  FormGroup,
  Divider,
  TextInput,
  Icon,
  Row,
  Col
} from "cf-neo-ui";
import Routes from "../layout/routes/index";

import "./styles.scss";
import metaTags from "../../utils/editMetaData";
import validate from "../../utils/validators";

import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import ldJsonLoginSignup from "./ldJsonSignup";
import CompanySection1 from "./CompanySection/CompanySection1";

const queryString = require("query-string");

@inject("sessionStore", "appStore")
@observer
class SignUpSelection extends Component {
  constructor(props) {
    super(props);
    const { location, sessionStore } = props;
    sessionStore.reset();
    this.state = {
      isEmailValid: true,
      emailValidationMessage: ""
    };
    const { type } = queryString.parse(location.search);
    sessionStore.changeSignUpUserRole(
      type === "enterprise" ? "CLIENT" : "CANDIDATE"
    );
  }

  // componentDidMount() {
  //   const { sessionStore } = this.props;

  //   let link = "";
  //   if (typeof window !== "undefined") {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     link = urlParams.get("type");
  //   }
  //   if (link == "consultant") {
  //     sessionStore.changeSignUpUserRole("CANDIDATE");
  //     window.history.pushState(
  //       { type: "consultant" },
  //       "signup consultant",
  //       "?type=consultant"
  //     );
  //   } else {
  //     sessionStore.changeSignUpUserRole("CLIENT");
  //     window.history.pushState(
  //       { type: "client" },
  //       "signup client",
  //       "?type=enterprise"
  //     );
  //   }
  //   window.scroll(0, 0);
  // }

  handleIsFreelance = val => {
    const { sessionStore } = this.props;
    if (val == 2) {
      sessionStore.changeSignUpUserRole("CLIENT");
      window.history.pushState(
        { type: "client" },
        "signup client",
        "?type=enterprise"
      );
    } else {
      sessionStore.changeSignUpUserRole("CANDIDATE");
      window.history.pushState(
        { type: "consultant" },
        "signup consultant",
        "?type=consultant"
      );
    }
  };

  handleExpressSignUp(val) {
    const { sessionStore } = this.props;
    sessionStore.changeExpressSignUp(val);
  }

  handleTalentSignUp(val) {
    const { history } = this.props;
    history.push(Routes.HbLayoutRoutes.AccountCreation.path);
  }
  isFormValid() {
    const { sessionStore } = this.props;
    const { email } = sessionStore;
    let valid = true;
    if (!this.validateEmail(email)) valid = false;
    if (!valid) return false;
    return valid;
  }
  validateEmail(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "email"], i18n);
    this.setState({
      isEmailValid: res.isValid,
      emailValidationMessage: res.message
    });
    return res.isValid;
  }
  emailChangeHandler = e => {
    const { sessionStore } = this.props;

    sessionStore.changeEmail(String(e.target.value).toLowerCase());
    this.validateEmail(e.target.value);
  };

  render() {
    const { i18n, appStore, sessionStore } = this.props;
    const { email } = sessionStore;
    const isMobile = appStore.width <= 1206;
    const meta = staticMetaTags(i18n).signUp;
    const { isEmailValid, emailValidationMessage } = this.state;
    let link = "";
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      link = urlParams.get("type");
    }

    return (
      <div className="session container signup-selection">
        {metaTags(
          urls.signUp(),
          meta.title,
          meta.description,
          sessionStore.signUpUserRole === "CANDIDATE"
            ? meta.consultant.openGraphImage.facebookOpenGraphImageUrl
            : meta.client.openGraphImage.facebookOpenGraphImageUrl,
          sessionStore.signUpUserRole === "CANDIDATE"
            ? meta.consultant.openGraphImage.linkedInOpenGraphImageUrl
            : meta.client.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJsonLoginSignup(i18n)
        )}
        <br />
        <Row>
          <div className="switchButton3">
            <br />
            <SwitchButton3
              defaultTab={link == "enterprise" ? 2 : 1}
              SegmentText1={i18n._(t`Je recherche une opportunité`)}
              SegmentText2={i18n._(t`Je recrute un talent IT`)}
              onClick={() =>
                this.handleIsFreelance(SwitchButton3.getSelectedTab())
              }
            />
          </div>
        </Row>
        {sessionStore.signUpUserRole === "CANDIDATE" ? (
          <div>
            <Row>
              <Col className="content" lg={6} xl={6}>
                <br />
                <div className="center-txt bold">
                  <h1 className="join-us">
                    <Trans>
                      Créer mon compte talent et rejoindre Mindquest
                    </Trans>
                  </h1>
                </div>
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="center-txt text-bold">
                  <Trans>
                    Bénéficiez d’offres sélectionnées pour votre profil.
                  </Trans>
                  <br />
                  <Trans>
                    Gérez vos candidatures et feuilles de temps depuis votre
                    espace personel.
                  </Trans>
                </span>
              </Col>
            </Row>
            <br />

            <FormCard className="formCard no-gutter">
              <Row>
                <Col>
                  <h2 className="text-red">
                    <Trans>Je recherche une opportunité dans l'IT</Trans>
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col xl={1} lg={1} md={1} />
                <Col
                  xl={9}
                  lg={9}
                  md={9}
                  className="form-card-content center"
                  style={{ padding: "50px 50px 10px" }}
                >
                  <div className="align">
                    <span className="email-input-style">
                      <Validation
                        errorMessage={emailValidationMessage}
                        valid={isEmailValid}
                      >
                        <TextInput
                          id="email"
                          type="email"
                          spellCheck="false"
                          className="form-input"
                          placeholder={i18n._(t`Entrer mon email`)}
                          value={email}
                          onChange={this.emailChangeHandler}
                        />
                      </Validation>
                    </span>

                    <span className="next-step-button-style">
                      <Button
                        className="next-step-button"
                        icon="chevron-right"
                        iconPosition="right"
                        onClick={() =>
                          this.isFormValid() && this.handleTalentSignUp(false)
                        }
                      >
                        <Trans>Étape suivante</Trans>
                      </Button>
                    </span>
                  </div>

                  <br />
                  <br />
                </Col>
                <Col xl={1} lg={1} md={1} />
              </Row>
              <Row>
                <Col xl={2} lg={2} md={2} />
                <Col xl={6} lg={6} md={6}>
                  <div className="align">
                    <picture className="tooltip">
                      <img src="/checked.webp" alt="checkmark" />
                      <i />
                    </picture>
                    <div className="signeUpDescrip">
                      <Trans>
                        Ce type de compte convient aux professionnels en mission
                        ou aux candidats en recherche.
                        <br />
                        Si vous souhaitez recruter ou que vous êtes donneur
                        d'ordre, créez plutôt <br />
                      </Trans>
                      <Link to={"/signup?type=enterprise"}>
                        <Trans>un compte entreprise.</Trans>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={2} md={2} />
              </Row>

              <br />
            </FormCard>
          </div>
        ) : (
          <div>
            <Row>
              <Col className="content" lg={6} xl={6}>
                <br />
                <div className="center-txt bold">
                  <h1 className="join-us">
                    <Trans>
                      Créer mon compte entreprise et rejoindre Mindquest
                    </Trans>
                  </h1>
                </div>
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="center-txt text-bold">
                  <Trans>
                    Identifiez des nouveaux talents pour votre entreprise.
                  </Trans>
                  <br />
                  <Trans>
                    Gérez vos missions et vos contrats depuis votre espace
                    client.
                  </Trans>
                </span>
              </Col>
            </Row>
            <br />
            <FormCard className="formCard no-gutter">
              <Row>
                <Col>
                  <h2 className="text-red">
                    <Trans>Je recrute un talent dans l'IT</Trans>
                  </h2>
                </Col>
              </Row>
              <br />
              <Row>
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  className="form-card-content center"
                  noGutter
                >
                  <div className="timer center">
                    <Icon
                      type="clock"
                      size="tiny"
                      color="#8d0417"
                      color2="#d3354a"
                      filled={false}
                    />
                    <span className="center-txt text-gray"> 3 min</span>
                    <br />
                  </div>
                  <div className="center">
                    <Link to={Routes.HbLayoutRoutes.MyCompany.path}>
                      <Button
                        icon="chevron-right"
                        iconPosition="right"
                        onClick={() => this.handleExpressSignUp(false)}
                      >
                        <Trans>Inscription en ligne</Trans>
                      </Button>
                    </Link>
                  </div>
                  <br />
                  <div className="center-txt text-gray">
                    <Trans>
                      Pour obtenir immédiatement des nouveaux talents
                    </Trans>
                  </div>
                </Col>
                <Divider
                  orientation={isMobile ? "horizontal" : "vertical"}
                  subText={i18n._(t`OU`)}
                  circleBg="white"
                />

                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  className="form-card-content center"
                  noGutter
                >
                  <div className="timer center">
                    <Icon
                      type="clock"
                      size="tiny"
                      color="#8d0417"
                      color2="#d3354a"
                      filled={false}
                    />
                    <span className="center-txt text-gray">
                      {" "}
                      <Trans>1 min</Trans>
                    </span>
                    <br />
                  </div>
                  <div className="center">
                    <Link to={Routes.HbLayoutRoutes.CalledByAdvisor.path}>
                      <Button
                        icon="chevron-right"
                        variant="secondary"
                        onClick={() => this.handleExpressSignUp(true)}
                      >
                        <Trans>Être rappelé par un expert</Trans>
                      </Button>
                    </Link>
                  </div>
                  <br />
                  <div className="center-txt text-gray">
                    <Trans>
                      Un expert Mindquest vous appelera pour compléter votre
                      profil.
                    </Trans>
                  </div>
                </Col>
              </Row>
            </FormCard>
          </div>
        )}
      </div>
    );
  }
}

SignUpSelection.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    signUpUserRole: PropTypes.string,
    reset: PropTypes.func,
    changeSignUpUserRole: PropTypes.func,
    changeExpressSignUp: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(SignUpSelection));
