import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  TextInput,
  Textarea,
  Button,
  Validation,
  toaster,
  H2,
  H5
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Mutation } from "react-apollo";
import AddressMaps from "./address-maps/AddressMaps";
import configsCF from "../../configs/club-freelance-globals";
import classes from "./contact.module.scss";
import CONTACTER from "./mutations";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import runtimeVars from "../../configs/runTimeVars";
import ldJson from "./ldJson";

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("sessionStore")
@observer
class Contact extends Component {
  constructor(props) {
    super(props);
    this.captchaRef = null;
    const { sessionStore } = this.props;
    this.state = {
      firstName: sessionStore.authToken ? sessionStore.firstName : "",
      isfirstNameValid: true,
      lastName: sessionStore.authToken ? sessionStore.lastName : "",
      islastNameValid: true,
      email: sessionStore.authToken ? sessionStore.email : "",
      isEmailValid: true,
      emailErrorMessage: "",
      enterprise: "",
      message: "",
      isMessageValid: true,
      messageErrorMessage: "",
      to: {
        email: "",
        name: ""
      },
      isReCAPTCHAValid: true,
      mission: {
        id: "",
        title: ""
      }
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
    const { sessionStore } = this.props;
    if (sessionStore.contactPageEmail && sessionStore.contactPageEmail.email)
      this.setState({
        to: {
          name: sessionStore.contactPageEmail.name,
          email: sessionStore.contactPageEmail.email
        }
      });
    else
      this.setState({
        to: {
          email: configsCF.contactEmail,
          name: ""
        }
      });
    sessionStore.setContactPageEmail(null);
    if (this.captchaRef) this.captchaRef.reset();
    if (
      sessionStore.contactPageMission &&
      sessionStore.contactPageMission.id &&
      sessionStore.contactPageMission.title
    ) {
      this.setState({
        mission: {
          id: sessionStore.contactPageMission.id,
          title: sessionStore.contactPageMission.title
        }
      });
    }
  }

  componentWillUnmount() {
    const { sessionStore } = this.props;
    sessionStore.setContactPageMission(null);
  }

  blurHandler = (e, field) => {
    const obj = {};
    obj[field] = e.target.value;
    this.setState(obj);
  };

  changeHandler = e => this.setState({ message: e.target.value });

  reCaptchaError = () => {};

  validator = () => {
    let valid = true;
    const newState = { ...this.state };
    const { i18n } = this.props;
    if (!newState.firstName) {
      newState.isfirstNameValid = false;
      valid = false;
    } else newState.isfirstNameValid = true;
    if (!newState.lastName) {
      newState.islastNameValid = false;
      valid = false;
    } else newState.islastNameValid = true;
    if (!newState.email) {
      newState.isEmailValid = false;
      valid = false;
      newState.emailErrorMessage = i18n._(
        t`c’est le seul moyen de vous rejoindre!`
      );
    } else if (!EMAIL_REG.test(newState.email)) {
      newState.emailErrorMessage = i18n._(
        t`Veuillez entrer une address valide`
      );
      newState.isEmailValid = false;
      valid = false;
    } else newState.isEmailValid = true;
    if (!newState.message) {
      newState.isMessageValid = false;
      valid = false;
      newState.messageErrorMessage = i18n._(
        t`Vous avez oublié d’écrire votre message !`
      );
    } else if (newState.message.length < 50) {
      newState.isMessageValid = false;
      valid = false;
      newState.messageErrorMessage = i18n._(
        t`Message trop court,plus détails sera utile pour nous`
      );
    } else newState.isMessageValid = true;
    this.setState(newState);
    return valid;
  };

  send = mutation => {
    const { sessionStore } = this.props;
    const {
      firstName,
      lastName,
      email,
      message,
      enterprise,
      to,
      mission
    } = this.state;
    if (!this.validator()) return;
    if (this.captchaRef && this.captchaRef.getValue()) {
      const mail = {
        from: {
          email,
          name: `${firstName} ${lastName}`,
          enterprise
        },
        to: {
          email: to.email,
          name: to.name
        },
        message,
        mission: {
          id: mission.id,
          title: mission.title
        }
      };
      mutation({ variables: { input: mail } });
      this.captchaRef.reset();
      sessionStore.setContactPageMission(null);
    } else this.setState({ isReCAPTCHAValid: false });
  };

  render() {
    const { i18n } = this.props;
    const meta = staticMetaTags(i18n).contact;
    const {
      firstName,
      isfirstNameValid,
      lastName,
      islastNameValid,
      email,
      isEmailValid,
      emailErrorMessage,
      enterprise,
      isMessageValid,
      messageErrorMessage,
      message,
      to,
      isReCAPTCHAValid
    } = this.state;

    return (
      <div className={classes.container}>
        {metaTags(
          urls.contact(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson
        )}
        {to.name ? (
          <H2 className={classes.header}>
            <Trans>Contacter</Trans>
            {` ${to.name}`}
          </H2>
        ) : (
          <h1 className={classes.header}>
            <Trans>Nous contacter</Trans>
          </h1>
        )}
        <div className={classes.card}>
          <div className={classes.image} />
          <div className={classes.cardBody}>
            <div className={classes.form}>
              <H2 className={classes.intro}>
                <Trans>
                  Laissez nous un message, notre équipe vous recontactera dans
                  les plus brefs délais.
                </Trans>
              </H2>
              <div className={classes.row}>
                <div className={classes.col}>
                  <H5 className={classes.label}>
                    <Trans>Nom</Trans>
                  </H5>
                  <Validation
                    errorMessage={i18n._(t`Veuillez entrer votre nom`)}
                    valid={islastNameValid}
                  >
                    <TextInput
                      defaultValue={lastName}
                      placeholder={i18n._(t`Nom de famille`)}
                      onBlur={e => this.blurHandler(e, "lastName")}
                      name="lastName"
                      spellCheck="false"
                    />
                  </Validation>
                </div>
                <div className={classes.col}>
                  <H5 className={classes.label}>
                    <Trans>Prénom</Trans>
                  </H5>
                  <Validation
                    errorMessage={i18n._(t`Veuillez entrer votre prénom`)}
                    valid={isfirstNameValid}
                  >
                    <TextInput
                      defaultValue={firstName}
                      placeholder={i18n._(t`Prénom`)}
                      onBlur={e => this.blurHandler(e, "firstName")}
                      name="firstName"
                      spellCheck="false"
                    />
                  </Validation>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.col}>
                  <H5 className={classes.label}>
                    <Trans>E-mail</Trans>
                  </H5>
                  <Validation
                    errorMessage={emailErrorMessage}
                    valid={isEmailValid}
                  >
                    <TextInput
                      defaultValue={email}
                      placeholder={i18n._(t`monemail@mail.com`)}
                      onBlur={e => this.blurHandler(e, "email")}
                      name="email"
                      spellCheck="false"
                      type="email"
                    />
                  </Validation>
                </div>

                <div className={classes.col}>
                  <H5 className={classes.label}>
                    <Trans>Entreprise</Trans>
                  </H5>
                  <TextInput
                    defaultValue={enterprise}
                    placeholder={i18n._(t`Entreprise`)}
                    onBlur={e => this.blurHandler(e, "enterprise")}
                    spellCheck="false"
                    type="text"
                  />
                </div>
              </div>
              <div className={classes.textarea}>
                <H5 className={classes.label}>
                  <Trans>Message</Trans>
                </H5>
                <Validation
                  errorMessage={messageErrorMessage}
                  valid={isMessageValid}
                >
                  <Textarea
                    className={classes.message}
                    placeholder={i18n._(t`Votre message`)}
                    onBlur={e => this.blurHandler(e, "message")}
                    onChange={this.changeHandler}
                    rows="6"
                    value={message}
                  />
                </Validation>
              </div>
              <div
                className={`${classes.recaptcha} ${
                  isReCAPTCHAValid ? "" : classes.nonValidRecaptcha
                }`}
              >
                <div />
                <div className={classes.showOnXs}>
                  <ReCAPTCHA
                    ref={el => {
                      this.captchaRef = el;
                    }}
                    size="compact"
                    onChange={() => this.setState({ isReCAPTCHAValid: true })}
                    onErrored={this.reCaptchaError}
                    sitekey={runtimeVars.APP_CAPTCHA_KEY}
                  />
                </div>
                <div className={classes.hideOnXs}>
                  <ReCAPTCHA
                    className={classes.hideOnXs}
                    ref={el => {
                      this.captchaRef = el;
                    }}
                    size="normal"
                    onChange={() => this.setState({ isReCAPTCHAValid: true })}
                    onErrored={this.reCaptchaError}
                    sitekey={runtimeVars.APP_CAPTCHA_KEY}
                  />
                </div>
              </div>
              <Mutation
                mutation={CONTACTER}
                onCompleted={data => {
                  if (
                    data.sendContactEmail &&
                    data.sendContactEmail.status === "ok"
                  )
                    this.setState({
                      message: "",
                      isMessageValid: true,
                      messageErrorMessage: ""
                    });
                  toaster.success({
                    title: i18n._(t`Email Envoyé`),
                    description: i18n._(
                      t`Nous y répondrons dans les plus brefs délais`
                    )
                  });
                }}
                onError={() => {
                  toaster.error({
                    title: `${i18n._(t`Erreur`)} !`,
                    description: i18n._(t`réessayer svp`)
                  });
                }}
              >
                {contact => (
                  <Button
                    name={i18n._(t`Envoyer`)}
                    size="small"
                    icon="chevron-right"
                    iconColor="#92091B"
                    onClick={() => this.send(contact)}
                    className={classes.sendButton}
                  >
                    <Trans>Envoyer</Trans>
                  </Button>
                )}
              </Mutation>
            </div>
          </div>
        </div>
        <AddressMaps />
      </div>
    );
  }
}
Contact.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    contactPageEmail: PropTypes.object,
    setContactPageEmail: PropTypes.func,
    authToken: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(Contact);
