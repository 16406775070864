import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { t, Trans } from "@lingui/macro";
import { Row, Col, FormCard, Table } from "cf-neo-ui";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import { NavLink, withRouter } from "react-router-dom";
import "./styles.scss";
import classes from "./siteMap.module.scss";
import BlogCategories from "../../utils/BlogCategories";

@inject("sessionStore", "appStore")
@observer
class siteMap extends Component {
  render() {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const meta = staticMetaTags(i18n).siteMap;
    const blogCategories = BlogCategories(i18n);
    const blog = Object.values(blogCategories);
    return (
      <div className="session container privacy-policy">
        {metaTags(
          urls.siteMap(),
          meta.title,
          meta.description,
          null,
          null,
          meta.keywords
        )}
        <br />
        <Row>
          <Col className="content" lg={6} xl={6}>
            <div className="center-txt bold">
              <h1 className="privacy-policy-title">
                <Trans>PLAN DU SITE</Trans>
              </h1>
            </div>
            <br />
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter">
          <Row className="with-padding">
            <Col className="center">
              <br />

              <ul>
                <li>
                  <NavLink className={classes.text} exact to="/">
                    <Trans>Accueil</Trans>
                  </NavLink>
                </li>
                <br />
                <li>
                  <span className={classes.text}>
                    <Trans>Candidat</Trans>
                  </span>
                  <ul>
                    <li>
                      <NavLink className={classes.text} exact to="/consultant">
                        <Trans>Pourquoi nous rejoindre ?</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className={classes.text} exact to="/missions">
                        <Trans>Toutes nos missions</Trans>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span className={classes.text}>
                    <Trans>Entreprises</Trans>
                  </span>
                  <ul>
                    <li>
                      <NavLink className={classes.text} exact to="/enterprise">
                        <Trans>Notre accompagnement</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className={classes.text} exact to="/candidates">
                        <Trans>Trouver un talent</Trans>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <NavLink className={classes.text} exact to="/about">
                    <Trans>A propos de Mindquest</Trans>
                  </NavLink>
                </li>
                <br />
                <li>
                  <NavLink className={classes.text} exact to="/blog">
                    <Trans>Ressources</Trans>
                  </NavLink>
                  {currentLanguage === "fr"
                    ? blog &&
                      blog.map(category => {
                        return category.lang === "fr-en" ||
                          category.lang === "fr" ? (
                          <li key={category.name}>
                            <NavLink
                              className={classes.text}
                              exact
                              to={category.url}
                            >
                              <Trans>{category.title}</Trans>
                            </NavLink>
                            <>
                              {category.subCategories.map(subCategory => {
                                return subCategory.lang === "fr-en" ||
                                  subCategory.lang === "fr" ? (
                                  <ul>
                                    <li>
                                      <NavLink
                                        className={classes.text}
                                        exact
                                        to={subCategory.url}
                                      >
                                        <Trans>{subCategory.title}</Trans>
                                      </NavLink>
                                      <>
                                        {subCategory.subCategories &&
                                          subCategory.subCategories.map(
                                            subSubCategory => {
                                              return subSubCategory.lang ===
                                                "fr-en" ||
                                                subSubCategory.lang === "fr" ? (
                                                <ul>
                                                  <li>
                                                    <NavLink
                                                      className={classes.text}
                                                      exact
                                                      to={subSubCategory.url}
                                                    >
                                                      <Trans>
                                                        {subSubCategory.title}
                                                      </Trans>
                                                    </NavLink>
                                                  </li>
                                                </ul>
                                              ) : null;
                                            }
                                          )}
                                      </>
                                    </li>
                                  </ul>
                                ) : null;
                              })}
                            </>
                          </li>
                        ) : null;
                      })
                    : currentLanguage === "en"
                    ? blog &&
                      blog.map(category => {
                        return category.lang === "fr-en" ||
                          category.lang === "en" ? (
                          <li key={category.name}>
                            <NavLink
                              className={classes.text}
                              exact
                              to={category.url}
                            >
                              <Trans>{category.title}</Trans>
                            </NavLink>
                            <>
                              {category.subCategories.map(subCategory => {
                                return subCategory.lang === "fr-en" ||
                                  subCategory.lang === "en" ? (
                                  <ul>
                                    <li>
                                      <NavLink
                                        className={classes.text}
                                        exact
                                        to={subCategory.url}
                                      >
                                        <Trans>{subCategory.title}</Trans>
                                      </NavLink>
                                      <>
                                        {subCategory.subCategories &&
                                          subCategory.subCategories.map(
                                            subSubCategory => {
                                              return subSubCategory.lang ===
                                                "fr-en" ||
                                                subSubCategory.lang === "en" ? (
                                                <ul>
                                                  <li>
                                                    <NavLink
                                                      className={classes.text}
                                                      exact
                                                      to={subSubCategory.url}
                                                    >
                                                      <Trans>
                                                        {subSubCategory.title}
                                                      </Trans>
                                                    </NavLink>
                                                  </li>
                                                </ul>
                                              ) : null;
                                            }
                                          )}
                                      </>
                                    </li>
                                  </ul>
                                ) : null;
                              })}
                            </>
                          </li>
                        ) : null;
                      })
                    : null}
                </li>
                <br />
                <li>
                  <span className={classes.text}>
                    <Trans>Offre finance</Trans>
                  </span>
                  <ul>
                    <li>
                      <NavLink
                        className={classes.text}
                        exact
                        to="/talent-finance"
                      >
                        <Trans>Talent</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={classes.text}
                        exact
                        to="/service-entreprise-finance"
                      >
                        <Trans>Entreprise</Trans>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span className={classes.text}>
                    <Trans>Pages</Trans>
                  </span>
                  <ul>
                    <li>
                      <NavLink className={classes.text} exact to="/login">
                        <Trans>Connexion ou se connecter</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={classes.text}
                        exact
                        to="/signup?type=consultant"
                      >
                        <Trans>Inscription - Je suis un Talent</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={classes.text}
                        exact
                        to="/signup?type=enterprise"
                      >
                        <Trans>Inscription - Je suis une Entreprise</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className={classes.text} exact to="/contact">
                        <Trans>Nous contacter</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={classes.text}
                        exact
                        to="/privacy-policy"
                      >
                        <Trans>Politique de confidentialité</Trans>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={classes.text}
                        exact
                        to="/terms-and-conditions-of-use"
                      >
                        <Trans>
                          Conditions Générales d’Utilisation et mentions légales
                        </Trans>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

siteMap.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    backRef: PropTypes.string,
    setBackRef: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(siteMap));
