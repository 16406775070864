import { t } from "@lingui/macro";
import Routes from "../containers/layout/routes";
import domaineExpertise from "./domaineExpertise";
const menuItems = (history, i18n, language, missionsSearchStore) => {
  const {
    Home,
    consultant,
    MissionsPage,
    client,
    about,
    SearchCandidatesPage
  } = Routes.HbfLayoutRoutes;
  const { contact } = Routes.HbLayoutRoutes;
  const {
    changeEmploymentType,
    addCategory,
    initCategories
  } = missionsSearchStore;
  const menuItemsFr = [
    {
      key: "apropos",
      name: i18n._(t`A propos`),
      clickable: false,
      onClick: (e, key, path) => {},
      icon: "chevron-down",
      child: [
        {
          nameList: i18n._(t`Mindquest`),
          listContent: [
            {
              key: "Services et offres",
              name: i18n._(t`Services et offres`),
              icon: "verified-user",
              onClick: () => {
                history.push(about.path);
              }
            },
            {
              key: "Nous rejoindre",
              name: i18n._(t`Nous rejoindre`),
              icon: "Rocket",
              onClick: () => {
                language == "fr"
                  ? window.open(
                      "https://www.welcometothejungle.com/fr/companies/club-freelance",
                      "_blank"
                    )
                  : window.open(
                      "https://www.welcometothejungle.com/en/companies/club-freelance",
                      "_blank"
                    );
              }
            },
            {
              key: "Nous contacter",
              name: i18n._(t`Nous contacter`),
              icon: "Email",
              onClick: () => {
                history.push(contact.path);
              }
            },
            {
              key: "RSE et Engagements",
              name: i18n._(t`RSE et Engagements`),
              icon: "Csr",
              onClick: () =>
                window.open("https://rse.mindquest.io/hub", "_blank")
            },
            {
              button: i18n._(t`Rejoindre une communauté Mindquest`),
              onClickButton: () => {
                history.push("signup?type=consultant");
              }
            }
          ]
        }
      ]
    },
    {
      key: "Recrutement IT ",
      name: i18n._(t`Recrutement IT`),
      onClick: (e, key, path) => {},
      clickable: false,
      icon: "chevron-down",
      child: [
        {
          nameList: i18n._(t`Entreprise`),
          listContent: [
            {
              key: "Recruter un talent Freelance IT ou CDI",
              name: i18n._(t`Recruter un talent Freelance IT ou CDI`),
              icon: "laptop",
              onClick: () => {
                history.push(SearchCandidatesPage.path);
              }
            },
            {
              key: "Services pour les entreprises",
              name: i18n._(t`Services pour les entreprises`),
              icon: "star",
              onClick: () => {
                history.push(client.path);
              }
            },
            // {
            //   key: "Communautés IT",
            //   name: i18n._(t`Communautés IT`),
            //   icon: "users",
            //   onClick: () => {
            //     history.push("/blog/it-decision-makers");
            //   }
            // },
            {
              key: "Ressources pour les décideurs",
              name: i18n._(t`Ressources pour les décideurs`),
              icon: "toolbox",
              onClick: () => {
                history.push("/blog/it-decision-makers");
              }
            },
            {
              button: i18n._(t`Soumettre un besoin en recrutement IT`),
              onClickButton: () => {
                history.push("/signup?type=enterprise");
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Candidat`),
          listContent: [
            {
              key: "Missions Freelance IT",
              name: i18n._(t`Missions Freelance IT`),
              icon: "chess-Knight",
              onClick: () => {
                history.push("/missions?employmentType=Contract");
                changeEmploymentType("Contract");
                initCategories();
              }
            },
            {
              key: "Emplois IT en CDI",
              name: i18n._(t`Emplois IT en CDI`),
              icon: "chess-rook",
              onClick: () => {
                history.push("/missions?employmentType=Permanent");
                changeEmploymentType("Permanent");
                initCategories();
              }
            },

            {
              key: "Services pour les talents IT",
              name: i18n._(t`Services pour les talents IT`),
              icon: "star",
              onClick: () => {
                history.push(consultant.path);
                initCategories();
              }
            },
            {
              key: "Communautés IT",
              name: i18n._(t`Communautés IT`),
              icon: "users",
              onClick: () => {
                window.open(
                  "https://visit.mindquest.io/communautes-it",
                  "_blank"
                );
                initCategories();
              }
            },
            {
              key: "Ressources pour les Freelances IT",
              name: i18n._(t`Ressources pour les Freelances IT`),
              icon: "toolbox",
              onClick: () => {
                history.push("/blog/freelance-tech");
                initCategories();
              }
            },
            {
              key: "Partenaires portage salarial",
              name: i18n._(t`Partenaires portage salarial`),
              icon: "folder-open",
              onClick: () => {
                window.open(
                  "https://visite.mindquest.io/nos-partenaires-portage-salarial",
                  "_blank"
                );
                initCategories();
              }
            },
            {
              button: i18n._(t`Trouver une mission Freelance ou un CDI`),
              onClickButton: () => {
                history.push(MissionsPage.path);
                initCategories();
              }
            }
          ]
        }
      ]
    },
    {
      key: "Recrutement Finance",
      name: i18n._(t`Recrutement Finance`),
      icon: "chevron-down",
      clickable: false,
      child: [
        {
          nameList: i18n._(t`Entreprise`),
          listContent: [
            {
              key: "Recruter un talent Finance",
              name: i18n._(t`Recruter un talent Finance`),
              icon: "chart-line",
              onClick: () => {
                history.push("/signup?type=enterprise");
              }
            },
            {
              key: "Services pour les entreprises",
              name: i18n._(t`Services pour les entreprises`),
              icon: "star",
              onClick: () => {
                history.push("/service-entreprise-finance");
              }
            },
            {
              key: "Communautés Finance",
              name: i18n._(t`Communautés Finance`),
              icon: "users",
              onClick: (e, key, path) => {
                window.open(
                  "https://www.linkedin.com/groups/7479510/",
                  "_blank"
                );
              }
            },
            {
              key: "Ressources pour les décideurs",
              name: i18n._(t`Ressources pour les décideurs`),
              icon: "toolbox",
              onClick: (e, key, path) => {
                history.push("/blog/it-decision-makers");
              }
            },
            {
              button: i18n._(t`Soumettre un besoin en recrutement `),
              onClickButton: () => {
                window.open(
                  "https://visite.mindquest.io/finance-entreprise",
                  "_blank"
                );
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Candidat`),
          listContent: [
            {
              key: "Offres Finance",
              name: i18n._(t`Offres Finance`),
              icon: "chart-line",
              onClick: () => {
                history.push("/missions?categories=Finance");

                addCategory(domaineExpertise(i18n, "Finance", "key"));
                changeEmploymentType("");
              }
            },
            {
              key: "Services pour les talents Finance",
              name: i18n._(t`Services pour les talents Finance`),
              icon: "star",
              onClick: () => {
                history.push("/talent-finance");
              }
            },
            {
              key: "Communautés Finance",
              name: i18n._(t`Communautés Finance`),
              icon: "users",
              onClick: () => {
                window.open(
                  "https://www.linkedin.com/groups/7479510/",
                  "_blank"
                );
              }
            },
            {
              key: "Ressources pour les pro",
              name: i18n._(t`Ressources pour les pro`),
              icon: "toolbox",
              onClick: () => {
                history.push("/blog/finance-professionals");
              }
            },
            {
              key: "Partenaires portage salarial",
              name: i18n._(t`Partenaires portage salarial`),
              icon: "folder-open",
              onClick: () => {
                window.open(
                  "https://visite.mindquest.io/nos-partenaires-portage-salarial",
                  "_blank"
                );
              }
            },
            {
              button: i18n._(t`Trouver une mission Freelance ou un CDI`),
              onClickButton: () => {
                history.push(MissionsPage.path);
              }
            }
          ]
        }
      ]
    },

    {
      key: "Ressources",
      name: i18n._(t`Ressources`),
      icon: "chevron-down",
      clickable: false,
      child: [
        {
          nameList: i18n._(t`Décideur / Recruteur`),
          listContent: [
            {
              key: "Ressources IT",
              name: i18n._(t`Ressources IT`),
              icon: "laptop",
              onClick: () => {
                history.push("/blog/it-decision-makers");
              }
            },
            {
              key: "Ressources Finance",
              name: i18n._(t`Ressources Finance`),
              icon: "chart-line",
              onClick: () => {
                history.push("/blog/finance-professionals");
              }
            },
            {
              key: "Recrutement",
              name: i18n._(t`Recrutement`),
              icon: "users",
              onClick: () => {
                history.push(
                  "blog/it-decision-makers/hiring-an-it-consultant/"
                );
              }
            },
            {
              button: i18n._(t`Consulter les guides`),
              onClickButton: () => {
                history.push("/blog/it-resources");
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Talent`),
          listContent: [
            {
              key: "Ressources IT",
              name: i18n._(t`Ressources IT`),
              icon: "laptop",
              onClick: () => {
                history.push("/blog/freelance-tech");
              }
            },
            {
              key: "Ressources Finance",
              name: i18n._(t`Ressources Finance`),
              icon: "chart-line",
              onClick: () => {
                history.push("/blog/finance-professionals");
              }
            },
            {
              key: "Employabilité",
              name: i18n._(t`Employabilité`),
              icon: "users",
              onClick: () => {
                history.push(
                  "/blog/freelance-tech/growing-your-career-permanent-and-freelance"
                );
              }
            },
            {
              key: "Freelance",
              name: i18n._(t`Freelance`),
              icon: "chess-Knight",
              onClick: () => {
                history.push("/blog/freelance-tech/daily-freelance");
              }
            },
            ,
            {
              key: "Partenaires",
              name: i18n._(t`Partenaires`),
              icon: "folder-open",
              onClick: () => {
                window.open(
                  "https://visite.mindquest.io/nos-partenaires",
                  "_blank"
                );
              }
            },
            {
              button: i18n._(t`Consulter les guides`),
              onClickButton: () => {
                history.push("/blog/it-resources");
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Plateforme & Centre d'aide`),

          listContent: [
            {
              key: "Guides utilisateurs",
              name: i18n._(t`Guides utilisateurs`),
              icon: "info",
              onClick: () => {
                window.open(
                  "https://mindquest.eb-help.com/help/collections/guide-utilistateurs",
                  "_blank"
                );
              }
            },
            {
              key: "Support",
              name: i18n._(t`Support`),
              icon: "life-ring",
              onClick: () => {
                window.open(
                  "https://mindquest.eb-help.com/help/collections/support",
                  "_blank"
                );
              }
            },
            {
              key: "Mises à jour produit",
              name: i18n._(t`Mises à jour produit`),
              icon: "fire",
              onClick: () => {
                window.open(
                  "https://mindquest.sleekplan.app/changelog",
                  "_blank"
                );
              }
            },
            {
              key: "Suggérer une fonctionnalité",
              name: i18n._(t`Suggérer une fonctionnalité`),
              icon: "finance-lock",
              onClick: () => {
                window.open("https://mindquest.sleekplan.app/", "_blank");
              }
            },
            {
              button: i18n._(t`Découvrir les fonctionnalités`),
              onClickButton: () => {
                window.open(
                  "https://mindquest.eb-help.com/help/collections/guide-utilistateurs",
                  "_blank"
                );
              }
            }
          ]
        }
      ]
    }
  ];
  const menuItemsEn = [
    {
      key: "apropos",
      name: i18n._(t`A propos`),
      clickable: false,
      onClick: (e, key, path) => {},
      icon: "chevron-down",
      child: [
        {
          nameList: i18n._(t`Mindquest`),
          listContent: [
            {
              key: "Services et offres",
              name: i18n._(t`Services et offres`),
              icon: "verified-user",
              onClick: () => {
                history.push(about.path);
              }
            },
            {
              key: "Nous rejoindre",
              name: i18n._(t`Nous rejoindre`),
              icon: "Rocket",
              onClick: () => {
                language == "fr"
                  ? window.open(
                      "https://www.welcometothejungle.com/fr/companies/club-freelance",
                      "_blank"
                    )
                  : window.open(
                      "https://www.welcometothejungle.com/en/companies/club-freelance",
                      "_blank"
                    );
              }
            },
            {
              key: "Nous contacter",
              name: i18n._(t`Nous contacter`),
              icon: "Email",
              onClick: () => {
                history.push(contact.path);
              }
            },
            {
              key: "RSE et Engagements",
              name: i18n._(t`CSR and Engagements`),
              icon: "Csr",
              onClick: () =>
                window.open("https://rse.mindquest.io/hub", "_blank")
            },
            {
              button: i18n._(t`Rejoindre une communauté Mindquest`),
              onClickButton: () => {
                history.push("signup?type=consultant");
              }
            }
          ]
        }
      ]
    },
    {
      key: "Recrutement IT",
      name: i18n._(t`Recrutement IT`),
      onClick: (e, key, path) => {},
      clickable: false,
      icon: "chevron-down",
      child: [
        {
          nameList: i18n._(t`Entreprise`),
          listContent: [
            {
              key: "Recruter un talent Freelance IT ou CDI",
              name: i18n._(t`Recruter un talent Freelance IT ou CDI`),
              icon: "laptop",
              onClick: () => {
                history.push(SearchCandidatesPage.path);
              }
            },
            {
              key: "Services pour les entreprises",
              name: i18n._(t`Services pour les entreprises`),
              icon: "star",
              onClick: () => {
                history.push(client.path);
              }
            },
            // {
            //   key: "Communautés IT",
            //   name: i18n._(t`Communautés IT`),
            //   icon: "users",
            //   onClick: () => {
            //     history.push("/blog/it-decision-makers");
            //   }
            // },
            {
              key: "Ressources pour les décideurs",
              name: i18n._(t`Ressources pour les décideurs`),
              icon: "toolbox",
              onClick: () => {
                history.push("/blog/it-decision-makers");
              }
            },
            {
              button: i18n._(t`Soumettre un besoin en recrutement IT`),
              onClickButton: () => {
                history.push("/signup?type=enterprise");
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Candidat`),
          listContent: [
            {
              key: "Missions Freelance IT",
              name: i18n._(t`Missions Freelance IT`),
              icon: "chess-Knight",
              onClick: () => {
                history.push("/missions?employmentType=Contract");
                changeEmploymentType("Contract");
                initCategories();
              }
            },
            {
              key: "Emplois IT en CDI",
              name: i18n._(t`Emplois IT en CDI`),
              icon: "chess-rook",
              onClick: () => {
                history.push("/missions?employmentType=Permanent");
                changeEmploymentType("Permanent");
                initCategories();
              }
            },

            {
              key: "Services pour les talents IT",
              name: i18n._(t`Services pour les talents IT`),
              icon: "star",
              onClick: () => {
                history.push(consultant.path);
                initCategories();
              }
            },
            {
              key: "Ressources pour les Freelances IT",
              name: i18n._(t`Ressources pour les Freelances IT`),
              icon: "toolbox",
              onClick: () => {
                history.push("/blog/freelance-tech");
                initCategories();
              }
            },
            {
              key: "Partenaires portage salarial",
              name: i18n._(t`Partenaires portage salarial`),
              icon: "folder-open",
              onClick: () => {
                window.open(
                  "https://visite.mindquest.io/nos-partenaires-portage-salarial",
                  "_blank"
                );
                initCategories();
              }
            },
            {
              button: i18n._(t`Trouver une mission Freelance ou un CDI`),
              onClickButton: () => {
                history.push(MissionsPage.path);
                initCategories();
              }
            }
          ]
        }
      ]
    },
    {
      key: "Recrutement Finance",
      name: i18n._(t`Recrutement Finance`),
      icon: "chevron-down",
      clickable: false,
      child: [
        {
          nameList: i18n._(t`Entreprise`),
          listContent: [
            {
              key: "Recruter un talent Finance",
              name: i18n._(t`Recruter un talent Finance`),
              icon: "chart-line",
              onClick: () => {
                history.push("/signup?type=enterprise");
              }
            },
            {
              key: "Services pour les entreprises",
              name: i18n._(t`Services pour les entreprises`),
              icon: "star",
              onClick: () => {
                history.push("/service-entreprise-finance");
              }
            },
            {
              key: "Ressources pour les décideurs",
              name: i18n._(t`Ressources pour les décideurs`),
              icon: "toolbox",
              onClick: (e, key, path) => {
                history.push("/blog/it-decision-makers");
              }
            },
            {
              button: i18n._(t`Soumettre un besoin en recrutement `),
              onClickButton: () => {
                history.push("/signup?type=enterprise");
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Candidat`),
          listContent: [
            {
              key: "Offres Finance",
              name: i18n._(t`Offres Finance`),
              icon: "chart-line",
              onClick: () => {
                history.push("/missions?categories=Finance");

                addCategory(domaineExpertise(i18n, "Finance", "key"));
                changeEmploymentType("");
              }
            },
            {
              key: "Services pour les talents Finance",
              name: i18n._(t`Services pour les talents Finance`),
              icon: "star",
              onClick: () => {
                history.push("/talent-finance");
              }
            },
            {
              button: i18n._(t`Trouver une mission Freelance ou un CDI`),
              onClickButton: () => {
                history.push(MissionsPage.path);
              }
            }
          ]
        }
      ]
    },

    {
      key: "Ressources",
      name: i18n._(t`Ressources`),
      icon: "chevron-down",
      clickable: false,
      child: [
        {
          nameList: i18n._(t`Décideur / Recruteur`),
          listContent: [
            {
              key: "Ressources IT",
              name: i18n._(t`Ressources IT`),
              icon: "laptop",
              onClick: () => {
                history.push("/blog/it-decision-makers");
              }
            },
            {
              key: "Recrutement",
              name: i18n._(t`Recrutement`),
              icon: "users",
              onClick: () => {
                history.push(
                  "blog/it-decision-makers/hiring-an-it-consultant/"
                );
              }
            },
            {
              button: i18n._(t`Consulter les guides`),
              onClickButton: () => {
                history.push("/blog");
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Talent`),
          listContent: [
            {
              key: "Ressources IT",
              name: i18n._(t`Ressources IT`),
              icon: "laptop",
              onClick: () => {
                history.push("/blog/freelance-tech");
              }
            },
            {
              key: "Employabilité",
              name: i18n._(t`Employabilité`),
              icon: "users",
              onClick: () => {
                history.push(
                  "/blog/freelance-tech/growing-your-career-permanent-and-freelance"
                );
              }
            },
            {
              key: "Freelance",
              name: i18n._(t`Freelance`),
              icon: "chess-Knight",
              onClick: () => {
                history.push(
                  "/blog/freelance-tech/tips-for-your-daily-life-as-a-freelancer"
                );
              }
            },
            ,
            {
              key: "Partenaires",
              name: i18n._(t`Partenaires`),
              icon: "folder-open",
              onClick: () => {
                window.open(
                  "https://visite.mindquest.io/nos-partenaires",
                  "_blank"
                );
              }
            },
            {
              button: i18n._(t`Consulter les guides`),
              onClickButton: () => {
                history.push("/blog");
              }
            }
          ]
        },
        {
          nameList: i18n._(t`Plateforme & Centre d'aide`),

          listContent: [
            {
              key: "Mises à jour produit",
              name: i18n._(t`Mises à jour produit`),
              icon: "fire",
              onClick: () => {
                window.open(
                  "https://mindquest.sleekplan.app/changelog",
                  "_blank"
                );
              }
            },
            {
              key: "Suggérer une fonctionnalité",
              name: i18n._(t`Suggérer une fonctionnalité`),
              icon: "finance-lock",
              onClick: () => {
                window.open("https://mindquest.sleekplan.app/", "_blank");
              }
            }
          ]
        }
      ]
    }
  ];

  if (language == "fr") {
    return menuItemsFr;
  } else {
    return menuItemsEn;
  }
};
export default menuItems;
