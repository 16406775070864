import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Button, Icon, Checkbox } from "cf-neo-ui";
import classes from "./FinanceModal.module.scss";
import routes from "../../routes";
import theme from "../../../../configs/theme";
import { inject, observer } from "mobx-react";

@inject("sessionStore", "appStore")
@observer
class FinanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy: false
    };
  }

  subscribeHandler = () => {
    const { register } = this.props;
    register();
  };

  render() {
    const { i18n } = this.props;
    const { privacy } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.headText}>
            <Trans>
              Encore une petite étape avant de recevoir la newsletter des
              professionnels de la finance
            </Trans>
          </div>

          <div className={classes.actionSection}>
            <Checkbox
              className={classes.actionItem}
              value="accept"
              label={
                <span>
                  <Trans>J’accepte la</Trans>
                  <a
                    href={routes.HbLayoutRoutes.PrivacyPolicy.path}
                    type="text/html"
                    target="_blank"
                    rel="noopener noreferrer"
                    name={i18n._(t`politique de confidentialité`)}
                    style={{ paddingLeft: "4px" }}
                  >
                    <Trans>politique de confidentialité des données</Trans>
                  </a>
                  <Trans>de Mindquest</Trans>
                </span>
              }
              onClickButton={() => this.setState({ privacy: !privacy })}
            />
            <Button
              disabled={!privacy}
              className={classes.actionItem}
              onClick={this.subscribeHandler}
            >
              <Trans>Confirmer</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

FinanceModal.propTypes = {
  register: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(FinanceModal);
