import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import cookie from "react-cookies";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import gql from "graphql-tag";
import { verify } from "jsonwebtoken";
import ReactGA from "react-ga";

import {
  Icon,
  FormCard,
  Button,
  Row,
  Col,
  ImportFile,
  Divider,
  Validation,
  TextInput,
  toaster,
  Container,
  Spinner,
  FormGroup
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import "./styles.scss";
import validate from "../../utils/validators";
import { GET_USER_INFO } from "../../components/hoc/queries";
import metaTags from "../../utils/editMetaData";
import { cookieRemoveOpt } from "../../configs/domainConfigs";

// eslint-disable-next-line camelcase

export const lINKEDIN_SCRAPER = gql`
  mutation getLinkedInInfoMutation(
    $LinkedInURL: String
    $email: String!
    $cv: Upload
    $siteLanguage: String
  ) {
    getLinkedInInfo(
      input: {
        LinkedInURL: $LinkedInURL
        email: $email
        cv: $cv
        siteLanguage: $siteLanguage
      }
    ) {
      message
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class myProfile extends Component {
  constructor(props) {
    super(props);
    this.source = "unknown";
    this.state = {
      isLinkValid: true,
      linkValidationMessage: ""
    };
    this.onNextStepHandler = this.onNextStepHandler.bind(this);
    this.onIgnoreStepHandler = this.onIgnoreStepHandler.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  componentWillMount() {
    const { sessionStore } = this.props;
    sessionStore.changeLinkedInURL("");
  }

  componentDidMount() {
    const { host } = window.location;
    const webDomain = host.split(".").pop();
    if (webDomain === "com") this.source = "Website FR";
    if (webDomain === "uk") this.source = "Website UK";
    ReactGA.initialize("UA-56544271-1");
    ReactGA.pageview(window.location.pathname);
  }

  onIgnoreStepHandler() {
    const { history, sessionStore } = this.props;
    const { changeMyCVPageFilled } = sessionStore;
    changeMyCVPageFilled(false);
    sessionStore.resetFields(["LinkedInURL", "cv"]);
    history.push(Routes.Hb2LayoutRoutes.MyInformation.path);
  }

  onNextStepHandler() {
    const valid = this.isFormValid();
    if (!valid) return;
    localStorage.setItem("Experiences", JSON.stringify([]));
    localStorage.removeItem("Experiences");
    const { history, sessionStore } = this.props;
    const { changeMyCVPageFilled } = sessionStore;
    changeMyCVPageFilled(true);
    history.push(Routes.Hb2LayoutRoutes.MyInformation.path);
  }

  ScrapedfirstNameChangeHandler = ScrapedfirstName => {
    const { sessionStore } = this.props;
    sessionStore.changeScrapedfirstName(ScrapedfirstName);
  };
  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };
  ScrapedlastNameChangeHandler = ScrapedlastName => {
    const { sessionStore } = this.props;
    sessionStore.changeScrapedlastName(ScrapedlastName);
  };

  ScrapedLocationChangeHandler = ScrapedLocation => {
    const { sessionStore } = this.props;
    sessionStore.changeScrapedLocation(ScrapedLocation);
  };
  linkChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeLinkedInURL(e.target.value);
    this.validateLink(e.target.value);
  };

  LinkedInScraperMutationCompletedHandler = () => {
    cookie.remove("firstVisitOrigin", cookieRemoveOpt);
    // const { Confirm } = Routes.HbLayoutRoutes;
    const { sessionStore, history } = this.props;
    sessionStore.reset();
    history.push(Confirm.path);
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const { customText20, cv } = sessionStore;
    let valid = true;
    if (customText20 != undefined && !this.validateLink(customText20))
      valid = false;
    if (cv && customText20 === "") valid = true;

    return valid;
  }

  validateLink(value) {
    const { i18n } = this.props;
    var res = validate(value, ["link"], i18n);
    let verifLink = value.includes("linkedin");
    res.isValid = res.isValid ? verifLink : res.isValid;
    this.setState({
      isLinkValid: res.isValid,
      linkValidationMessage: res.message
    });
    return res.isValid;
  }

  handleUpload(fileUploaded) {
    const { sessionStore } = this.props;
    sessionStore.changeCv(fileUploaded);
  }

  render() {
    const { i18n, appStore, sessionStore } = this.props;
    const { linkValidationMessage, isLinkValid } = this.state;
    const isMobile = appStore.width <= 1206;
    const { changeStep, cv, authToken, customText20, account } = sessionStore;
    const { currentLanguage } = appStore;
    const siteLanguage = currentLanguage;
    changeStep(3);
    let isValid = false;
    if (customText20) isValid = true;
    if (cv && cv.name) isValid = true;

    var url = "";
    if (typeof window !== "undefined") {
      url = window.location.href;
    }
    return (
      <div className="session signup-profile">
        {metaTags(url, null, null, null, null, null, null, null, false)}

        <br />
        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Mon profil</Trans>
            </h1>
          </Col>
        </Row>
        <br />
        <FormCard className="formCard no-gutter">
          <Row>
            <Col xl={5} lg={5} md={5} className="center form-card-content">
              <Container>
                <div
                  className="timer center"
                  style={{ transform: "rotate(180deg)", textAlign: "center" }}
                >
                  <Icon
                    type="download"
                    size="tiny"
                    color="#8d0417"
                    color2="#d3354a"
                    filled={false}
                  />
                  <br />
                </div>
                <div className="center-txt text-bold">
                  <Trans>Uploader mon CV</Trans>
                  <picture className="tooltip2">
                    <img src="/assets/images/tooltip.webp" alt="tooltip" />
                    <div className="right">
                      <Trans>
                        En chargeant votre CV, vos informations seront
                        préremplies. Un CV au format Word offrira de meilleurs
                        résultats
                      </Trans>
                    </div>
                    <i />
                  </picture>

                  <br />
                  <div className="text-gray">
                    <Trans>Méthode recommandée</Trans>
                  </div>
                  <br />
                </div>
                <div className="center" style={{ width: "100%" }}>
                  <ImportFile
                    defaultAvatar="/defaultAvatar.webp"
                    fileName={cv && cv.name}
                    onChange={(e, fileUploaded) =>
                      this.handleUpload(e, fileUploaded)
                    }
                    textButton={`${i18n._(t`Fermer`)} `}
                    accept=".doc,.docx,.pdf"
                    withDropFile
                    dropFileProps={{
                      text: i18n._(t`...ou simplement deposer votre CV ici`),
                      style: { height: "200px" },
                      onFilesAccepted: files => this.handleUpload(files[0])
                    }}
                    maxSize={1e6}
                    message1={i18n._(
                      t`Ce format de fichier n’est pas pris en charge. Veuillez choisir l’une des options suivantes :`
                    )}
                    message2={i18n._(
                      t`Nous vous recommandons fortement d’utiliser le format Word.`
                    )}
                    message3={`${i18n._(
                      t`Taille maximale du fichier à télécharger`
                    )} `}
                    message4={`${i18n._(
                      t`La taille du fichier est trop petite, veuillez sélectionner un fichier de taille supérieur à`
                    )} `}
                  >
                    <Trans>Importer mon CV</Trans>
                  </ImportFile>
                </div>
                <br />
              </Container>
            </Col>
            <Divider
              orientation={isMobile ? "horizontal" : "vertical"}
              subText={`${i18n._("OU")} `}
              circleBg="white"
            />
            <Col xl={5} lg={5} md={5} className="form-card-content center">
              <FormGroup>
                <div className="timer center" style={{ textAlign: "center" }}>
                  <Icon
                    type="linked-in"
                    size="tiny"
                    color="rgba(141,4,23,1)"
                    color2="rgba(211,53,74,1)"
                  />
                  <br />
                </div>

                <div className="center-txt text-bold">
                  <Trans>Importer votre profil LinkedIn</Trans>
                  <picture className="tooltip2">
                    <img src="/assets/images/tooltip.webp" alt="tooltip" />
                    <div className="right">
                      <Trans>
                        Collez l’URL de votre profil Linkedin pour préremplir
                        votre profil
                      </Trans>
                    </div>
                    <i />
                  </picture>

                  <br />
                  <div className="text-gray">
                    <Trans>
                      Copier et coller l'URL de votre profil LinkedIn
                    </Trans>
                  </div>
                  <br />
                </div>
                <Validation
                  errorMessage={linkValidationMessage}
                  valid={isLinkValid}
                >
                  <TextInput
                    id="addOn"
                    type="url"
                    spellCheck="false"
                    className="form-input center"
                    style={{ width: "100%" }}
                    placeholder={i18n._(t`https://www.linkedin.com/in/user/`)}
                    value={customText20}
                    onChange={this.linkChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
        </FormCard>
        <br />
        <div className="next-buttons">
          <Mutation
            mutation={lINKEDIN_SCRAPER}
            variables={{
              LinkedInURL: customText20,
              email: account.email,
              cv: cv,
              siteLanguage
            }}
            refetchQueries={[
              {
                query: GET_USER_INFO,
                variables: { token: authToken }
              }
            ]}
            onCompleted={() => {
              this.onNextStepHandler();
            }}
            onError={errors => {
              if (errors && errors.graphQLErrors) {
                errors.graphQLErrors.forEach(({ message }) => {
                  if (message) {
                    toaster.error({
                      title: i18n._(t`Erreur`),
                      description: i18n._(
                        t`Impossible de récupérer vos informations`
                      )
                    });
                  }
                });
              }
            }}
          >
            {(mutation, { loading }) => (
              <span>
                {loading ? (
                  <Button disabled>
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  </Button>
                ) : (
                  <Button
                    icon="chevron-right"
                    disabled={!isValid}
                    onClick={() => this.isFormValid() && mutation()}
                  >
                    <Trans>Étape suivante</Trans>
                  </Button>
                )}
              </span>
            )}
          </Mutation>
          <Button variant="secondary" onClick={this.onIgnoreStepHandler}>
            <Trans>Passer cette étape</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

myProfile.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeScrapedfirstName: PropTypes.func,
    changeScrapedlastName: PropTypes.func,
    changeScrapedLocation: PropTypes.func,
    changeScrapedOccupation: PropTypes.func,
    changeScrapedSkills: PropTypes.func,
    changeScrapedExperiences: PropTypes.func,
    changeScrapedSpokenLanguages: PropTypes.func,
    changeAccount: PropTypes.func,
    reset: PropTypes.func,
    authToken: PropTypes.string,
    changeStep: PropTypes.func,
    changeLinkedInURL: PropTypes.func,
    changeMyCVPageFilled: PropTypes.func,
    changeCv: PropTypes.func,
    resetFields: PropTypes.func,
    cv: PropTypes.object,
    isAuthenticatedByLinkedin: PropTypes.bool,
    expressSignUp: PropTypes.bool,
    customText20: PropTypes.string,
    ScrapedfirstName: PropTypes.string,
    ScrapedlastName: PropTypes.string,
    ScrapedLocation: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(myProfile));
