import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { AutoComplete, TagList } from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import { symmetricDifference } from "../../../../utils/helpers";
import { toJS } from "mobx";

@inject("missionsSearchStore")
@observer
class SkillsWidget extends Component {
  constructor(props) {
    super(props);
    props.missionsSearchStore.initSkillOptions(props.options);
  }

  render() {
    const {
      i18n,
      missionsSearchStore,
      skills,
      closeTagHandler,
      addSkill
    } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <AutoComplete
          key={skills?.slice().length}
          restrictedToList
          clearOnSelect
          options={missionsSearchStore.skillOptions.slice()}
          maxSuggestion={6}
          min={1}
          iconColor="black"
          iconColor2="black"
          icon="briefcase"
          iconPosition="left"
          placeholder={i18n._(t`Domaine, Compétences`)}
          onSelect={addSkill}
          radius={5}
        />
        <div>
          <TagList
            tags={[...new Set(skills)]}
            key={skills?.slice().length}
            variant="secondary"
            closable
            onClose={closeTagHandler}
          />
        </div>
      </div>
    );
  }
}

SkillsWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.PropTypes.shape({
    skills: PropTypes.shape({}),
    skillOptions: PropTypes.shape({}),
    removeSkill: PropTypes.func,
    setSkillOptions: PropTypes.func,
    addSkill: PropTypes.func,
    initSkillOptions: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  addSkill: PropTypes.func,
  closeTagHandler: PropTypes.func
};

export default withI18n()(SkillsWidget);
