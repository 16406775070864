import React, { Component } from "react";
import { Mutation, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import { UPDATE_TIMESHEET } from "../mutations";
import { Icon } from "cf-neo-ui";
import publicIp from "public-ip";
import classes from "./style.module.scss";
import { SINGLE_TIME_SHEETS_QUERY } from "../queries";
import locals from "../../../helpers/locals";
import roundDownDays from "../../../utils/roundDownDays";
import {
  TextInput,
  Textarea,
  Button,
  toaster,
  PopUp,
  Spinner,
  Validation
} from "cf-neo-ui";
@inject("newTimesheetStore", "appStore")
@observer
class sendCandidateTimesheet extends Component {
  constructor(props) {
    super(props);
    const { newTimesheetStore } = this.props;
    const { timesheet } = newTimesheetStore;
    this.state = {
      name: "",
      email: "",
      isCommentValid: true,
      commentErrorComment: "",
      commentValidationMessage: "",
      messages: [],
      purchaseOrder: timesheet.purchaseOrder,
      tasks: "",
      projects: "",
      comment: "",
      disabledPurchaseorder: true,
      isPurchaseOrderValid: true,
      PurchaseOrderMessage: ""
    };
    publicIp
      .v4()
      .then(res => {
        this.ip = res;
      })
      .catch(() => {});
  }
  validPurchaseOrder = value => {
    const { i18n } = this.props;

    const res = validate(value, [{ maxlength: 100 }], i18n);
    this.setState({
      isPurchaseOrderValid: res.isValid,
      PurchaseOrderMessage: i18n._(
        t`Le numéro de PO ne doit pas excéder 100 charactères`
      )
    });
    return res.isValid;
  };
  handleProjectsChange = event => {
    this.setState({ projects: event.target.value });
  };
  handlePurchaseOrderChange = event => {
    this.setState({ purchaseOrder: event.target.value });
  };
  handleTasksChange = event => {
    this.setState({ tasks: event.target.value });
  };

  changeCommentHandler = event => {
    this.setState({ comment: event.target.value });
  };
  activatepurchaseOrderfield = () => {
    this.setState({ disabledPurchaseorder: false });
  };

  convertTodays = (totalMinutes, i18n) => {
    const totals = roundDownDays(totalMinutes);
    var days = totals.toString().split(".")[0];
    const hoursString = totals.toString().split(".")[1];
    const hours = parseFloat(0 + "." + hoursString);
    days =
      hours >= 0.75
        ? parseInt(days) + 1
        : hours > 0
        ? parseInt(days) + 0.5
        : parseInt(days) + 0;
    const daysTerm =
      days <= 1 ? i18n._(t`jour travaillé`) : i18n._(t`jours travaillés`);

    return `${this.padToTwoDigits(days)} ${daysTerm}`;
  };
  padToTwoDigits = num => num.toString().padStart(2, "0");

  send = mutation => {
    const { comment } = this.state;
    const { i18n, newTimesheetStore } = this.props;
    const { openClosePopUps, changeIsEdited } = newTimesheetStore;
    if (!this.validator()) return;
    else {
      openClosePopUps("sendCandidateTimesheet");
      mutation();
      changeIsEdited(false);
      return null;
    }
  };
  validator = value => {
    let valid = true;
    const newState = { ...this.state };
    const { i18n } = this.props;
    if (!newState.comment) {
      newState.isCommentValid = false;
      valid = false;
      newState.commentErrorComment = i18n._(t`Ajouter un commentaire`);
    }

    this.setState(newState);
    return valid;
  };

  render() {
    const {
      projects,
      purchaseOrder,
      tasks,
      isCommentValid,
      commentErrorComment,
      comment,
      disabledPurchaseorder
    } = this.state;
    const { newTimesheetStore, device, appStore, i18n } = this.props;
    const { currentLanguage } = appStore;
    const {
      openClosePopUps,
      timesheet,
      workPlan,
      themes,
      totalHours
    } = newTimesheetStore;
    return (
      <PopUp
        className={classes.popup}
        mobile={device === "mobile"}
        onClose={() => {
          openClosePopUps("sendCandidateTimesheet");
        }}
      >
        <div
          style={{
            position: "relative",
            width: device === "mobile" ? "100%" : "480px",
            backgroundColor: "f2f2f2",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              lineHeight: "19px",
              display: "block"
            }}
          >
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {" "}
              <div
                style={{
                  padding: "6px"
                }}
              >
                <Icon
                  type="Bell"
                  size="small"
                  color="#8d0417"
                  color2="#d3354a"
                />
              </div>
              <h6
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "20px",
                  fontStyle: "normal",
                  color: "#d3354a",
                  textAlign: "center",
                  lineHeight: "20px",
                  margin: "8px 0"
                }}
              >
                {" "}
                <Trans>Merci d'indiquer votre activité</Trans>
              </h6>{" "}
            </div>
            <div className={classes.titleheader}>
              <p style={{ margin: "12px 0" }}>
                <Trans>Avant de soumettre votre Timesheet</Trans>{" "}
                {locals[currentLanguage].months.short[
                  timesheet.month - 1
                ].toUpperCase()}{" "}
                {timesheet.year}{" "}
                <Trans>
                  au client . veuillez s'il vous plait indiquer en quelques
                  lignes les actions effectuées ce mois .
                  <br />
                  Note : ce bilan d'activité sera visible par le client sur
                  votre compte-rendu de Timesheet
                </Trans>
              </p>

              {workPlan && workPlan.length === 0 ? (
                <div style={{ color: "#d3354a" }}>
                  <br />
                  <Trans>
                    Vous êtes sur le point de soumettre un Timesheet vide.
                  </Trans>
                </div>
              ) : (
                <div style={{ padding: "0px", textAlign: "center" }}>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      color: "#d3354a",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Trans>
                      Vous êtes sur le point d’envoyer{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          paddingLeft: "3px"
                        }}
                      >
                        {this.convertTodays(totalHours, i18n)}
                      </span>
                    </Trans>
                  </p>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Trans>
                      {" "}
                      Pour ne pas ralentir la facturation, vérifiez bien vos
                      données{" "}
                    </Trans>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontStyle: "normal",
                  color: "#d3354a",
                  fontWeight: "bolder",
                  margin: "12px 0"
                }}
              >
                <Trans>Projet (optionnel)</Trans>
              </p>
              <TextInput
                className={classes.textInput}
                onChange={this.handleProjectsChange}
                placeholder={i18n._(
                  t`Indiquer le ou les projets sur lesquels vous avez travaillé`
                )}
                name="lastName"
                spellCheck="false"
              />
            </div>
            {/*

            // [timesheet] add control to field suggest PO : max 100 char
            // i comment this maybe we needed in the future its about PO of the CANDIDATE 
              <div className={classes.col}>

                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    fontStyle: "normal",
                    color: "#d3354a",
                    fontWeight: "bolder",
                    display: "flex",
                    margin: "12px 0"
                  }}
                >
                  <Trans>Numéro de bon de commande</Trans>
                  <div className={classes.tooltip}>
                    <Icon
                      type="info"
                      width={16}
                      color="#8d0417"
                      color2="#d3354a"
                    />
                    <span className={classes.tooltipText}>
                      <Trans>
                        {" "}
                        Il s'agit du dernier numéro de bon de commande lié à
                        votre contrat connu dans notre système. S'il n'y en a
                        pas vous pouvez ignorer ce champ.
                      </Trans>
                    </span>
                  </div>
                </p>
                <TextInput
                  className={classes.textInput}
                  onChange={this.handlePurchaseOrderChange}
                  placeholder={i18n._(
                    t`Il n'ya pas de numéro de bon de commande indiqué`
                  )}
                  spellCheck="false"
                  disabled={disabledPurchaseorder}
                  defaultValue={purchaseOrder}
                  style={{
                    backgroundColor: disabledPurchaseorder
                      ? "#D0D0D0"
                      : "white",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: "normal"
                  }}
                />

                <div style={{ display: "flex" }}>
                  <Trans>
                    <p style={{ margin: "12px 0" }}>Ce numéro est manquant ou erroné ? </p>
                    <p
                      style={{
                        color: "#d3354a",
                        cursor: "pointer",
                        textDecoration: "underline",
                        margin: "12px 0"
                      }}
                      onClick={() => this.activatepurchaseOrderfield()}
                    >
                      Je le suggère
                    </p>
                  </Trans>
                </div>
              </div>

                    */}

            <div className={classes.col}>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontStyle: "normal",
                  color: "#d3354a",
                  fontWeight: "bolder",
                  margin: "12px 0"
                }}
              >
                <Trans>Activités principales (optionnel)</Trans>
              </p>
              <TextInput
                className={classes.textInput}
                onChange={this.handleTasksChange}
                placeholder={i18n._(
                  t`Indiquer les tâches que vous avez effectuées`
                )}
                name="lastName"
                spellCheck="false"
              />
            </div>
            <div className={classes.textarea}>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  fontStyle: "normal",
                  color: "#d3354a",
                  fontWeight: "bolder",
                  textDecoration: "underline",
                  margin: "12px 0"
                }}
              >
                <Trans>Compte-rendu d'activité (obligatoire)</Trans>
              </p>
              <Validation
                errorMessage={commentErrorComment}
                valid={this.isCommentValid}
              >
                <Textarea
                  className={classes.message}
                  placeholder={i18n._(
                    t`Détaillez-votre activité du mois (visible par le client)`
                  )}
                  onChange={this.changeCommentHandler}
                  rows="6"
                  value={comment}
                  style={{ height: "100px" }}
                />
              </Validation>
            </div>
          </div>
          <div className={classes.buttons}>
            <Button
              variant="tertiary"
              size="small"
              onClick={() => openClosePopUps("sendCandidateTimesheet")}
              className={classes.button}
            >
              <Trans>Annuler</Trans>
            </Button>

            <Mutation
              mutation={UPDATE_TIMESHEET}
              variables={{
                id: timesheet.id,
                action: "send",
                ip: this.ip,
                workPlan,
                themes,
                messages: [
                  { text: comment, type: "comment" },
                  tasks && { text: tasks, type: "tasks" },
                  projects && { text: projects, type: "projects" },
                  purchaseOrder && {
                    text: purchaseOrder,
                    type: "purchaseOrder"
                  }
                ].filter(n => n)
              }}
              refetchQueries={[
                {
                  query: SINGLE_TIME_SHEETS_QUERY,
                  variables: { id: timesheet.id }
                }
              ]}
              onCompleted={e => {
                this.setState({ messages: [] });
                openClosePopUps("timesheetSentSuccessfully");
              }}
              onError={e =>
                toaster.error({
                  title: `${i18n._(t`Erreur`)} !`,
                  description: i18n._(t`réessayer svp`)
                })
              }
            >
              {(mutation, { loading }) => (
                <span className={classes.submitButton}>
                  {loading ? (
                    <Button disabled>
                      <Spinner
                        style={{ marginLeft: "50px", position: "fixed" }}
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    </Button>
                  ) : (
                    <Button
                      className={classes.submitButton}
                      icon="chevron-right"
                      iconPosition="right"
                      size="small"
                      onClick={() => {
                        this.send(mutation);
                      }}
                    >
                      <Trans>Envoyer</Trans>
                    </Button>
                  )}
                </span>
              )}
            </Mutation>
          </div>
        </div>
      </PopUp>
    );
  }
}

sendCandidateTimesheet.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    workPlan: PropTypes.shape({}),
    timesheet: PropTypes.shape({}),
    normalHours: PropTypes.number,
    specialHours: PropTypes.number,
    themes: PropTypes.array
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  device: PropTypes.string,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};
export default withApollo(withI18n()(withRouter(sendCandidateTimesheet)));
