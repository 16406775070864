import React, { PureComponent } from "react";
import { BlogCardDisplay } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { I18nProvider } from "@lingui/react";
import classes from "./BlogCardsDisplay.module.scss";
import decode from "../../../utils/Utf8Text";
import catalogEn from "../../../locales/en/messages";
import catalogFr from "../../../locales/fr/messages";

const catalogs = {
  en: catalogEn,
  fr: catalogFr
};

@inject("appStore")
@observer
class BlogCardsDisplay extends PureComponent {
  formatDate = (date, currentLanguage) => {
    moment.locale(currentLanguage);
    return moment(date).format("DD MMMM YYYY");
  };

  navigate = article => {
    const { history } = this.props;

    // Replace common HTML entities and special characters
    const titleWithEntitiesReplaced = article.title
      .replace(/&rsquo;/g, "'")
      .replace(/’/g, "'")
      .replace(/'/g, "-");

    // Replace accented characters with non-accented equivalents
    const titleWithoutAccents = titleWithEntitiesReplaced
      .replace(/[àáâãäå]/g, "a")
      .replace(/[ç]/g, "c")
      .replace(/[èéêë]/g, "e")
      .replace(/[ìíîï]/g, "i")
      .replace(/[ñ]/g, "n")
      .replace(/[òóôõö]/g, "o")
      .replace(/[ùúûü]/g, "u")
      .replace(/[ýÿ]/g, "y");

    const urlFriendlyTitle = encodeURIComponent(
      titleWithoutAccents
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "") // Supprime les caractères spéciaux sauf les tirets
    );

    history.push(
      `/blog/${this.isRessource(article) ? "resources" : "news"}/${
        article.id
      }/${urlFriendlyTitle}`
    );
  };

  action = categories => {
    for (const item of categories) {
      if (item.name === "video") return "play";
    }
    return "";
  };

  isRessource = article => {
    let test = false;
    article.categories.map(category => {
      if (["ressources", "resources"].includes(category.name.toLowerCase()))
        test = true;
    });
    return test;
  };

  render() {
    const { articles, appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <I18nProvider language={currentLanguage} catalogs={catalogs}>
        <div className={classes.container}>
          {articles.map(article => (
            <BlogCardDisplay
              className={classes.blogCard}
              key={article.id}
              poster={
                article.featured_media && article.featured_media.source_url
                  ? article.featured_media.source_url
                  : "/mq.webp"
              }
              caption={
                Array.isArray(article.categories) && article.categories.length
                  ? decode(article.categories[0].name)
                  : "non précie"
              }
              smallTitle={
                article.date
                  ? this.formatDate(article.date, currentLanguage)
                  : "date non precis"
              }
              description={decode(article.title)}
              action={this.action(article.categories)}
              href={`/blog/${
                this.isRessource(article) ? "resources" : "news"
              }/${article.id}`}
              cardClick={() => this.navigate(article)}
            />
          ))}
        </div>
      </I18nProvider>
    );
  }
}

BlogCardsDisplay.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      poster: PropTypes.string,
      media: PropTypes.string,
      action: PropTypes.string,
      caption: PropTypes.string,
      smallTitle: PropTypes.string,
      description: PropTypes.string
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

BlogCardsDisplay.defaultProps = {
  articles: []
};

export default withRouter(BlogCardsDisplay);
