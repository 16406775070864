import { observable, action } from "mobx";
import cookie from "react-cookies";
import { cookieRemoveOpt } from "../configs/domainConfigs";
import runtimeVars from "../configs/runTimeVars";

const { AUTH_TOKEN } = runtimeVars;

const extractAuth = () => {
  if (typeof window === "undefined") return "";
  return cookie.load(AUTH_TOKEN) || localStorage.getItem(AUTH_TOKEN) || "";
};

const getTimestamp = basic_date => {
  if (basic_date) {
    const { day, month, year } = basic_date;
    const mergedDate = new Date(year, month - 1, day);
    return mergedDate.getTime();
  } else return null;
};

const defaultValues = {
  title: "",
  viviertitle: "",
  status: "",
  id: null,
  experience: 1,
  categories: [],
  skills: [],
  creatorID: [],
  talentPoolFilter: "",
  sourceTalentPool: "",
  talentPool: {},
  talentFilter: "",
  saveCaseEducation: false,
  shared: false,
  saveCaseInformation: false,
  saveCaseJob: false,
  saveCaseExperience: false,
  generalSaveCase: null,
  saveCaseResume: false,
  customTextBlock2: "",
  poolName: "",
  customTextBlock4: "",
  customTextBlock5: "",
  specialties: [],
  spokenLanguages: [],
  link: "",
  customTextBlock3: "",
  companyName: "",
  companyID: 0,
  activityArea: [],
  sizeOfTheCompany: 0,
  isESN: null,
  currentPosition: "",
  firstName: "",
  resumeModel: "Modèle Freelance classique",
  lastName: "",

  remote: "Indifferent",
  availability: null,
  publicProfil: false,
  toChangePoolName: false,
  searchAddress: {},
  addressSouhaite: {},
  address: {},
  addressToShow: "",
  searchAddressToShow: "",
  dayRate: null,
  mobility: null,
  salary: null,
  customText20: "",
  ScrapedfirstName: "",
  ScrapedlastName: "",
  ScrapedLocation: "",
  confirmationCode: "",
  employmentPreference: "Permanent",
  display: true,
  message: "",
  siteLanguage: "",
  clienName: "",
  clientEmail: "",
  clientPhoneNumber: "",
  isItDisable: false
};

export default class SessionStore {
  /* OBSERVABLE FIELDS */
  @observable authToken;

  @observable isItDisable;

  @observable candidate;

  @observable account;

  @observable _id;

  @observable signUpUserRole; // CANDIDATE OR CLIENT

  @observable step;

  @observable saveCaseInformation;

  @observable saveCaseJob;

  @observable shared;

  @observable saveCaseEducation;

  @observable saveCaseExperience;

  @observable generalSaveCase;

  @observable saveCaseResume;

  @observable expressSignUp;

  @observable isAuthenticatedByLinkedin;

  @observable cv;

  @observable pictureUrl;

  /* MyJob */
  @observable title;
  @observable viviertitle;

  @observable ScrapedfirstName;

  @observable ScrapedlastName;

  @observable ScrapedLocation;

  @observable ScrapedOccupation;

  @observable ScrapedSkills;

  @observable addressSouhaite;

  @observable ScrapedSpokenLanguages;

  @observable confirmationCode;

  @observable experience;

  @observable categories;

  @observable skills;

  @observable creatorID;

  @observable customTextBlock2;

  @observable poolName;

  @observable customTextBlock4;

  @observable customTextBlock5;

  @observable specialties;

  @observable spokenLanguages;

  @observable link = "";

  @observable customTextBlock3;

  @observable myJobPageFilled;

  @observable status;

  @observable siteLanguage;

  /* scrappedLinkedinProfile */
  @observable scrappedLinkedinProfile;

  /* MyEducation */
  @observable candidateEducation;

  @observable iscandidateEducationValid;

  @observable candidateCertification;

  @observable iscandidateCertificationValid;

  /* MyExperience */
  @observable candidateWorkHistory;

  @observable ScrapedExperiences;

  @observable BusinessSector;

  @observable skillExperience;

  @observable specialty;

  @observable isScrapedExperiencesAdded;

  @observable isCandidateWorkHistoryValid;

  /* MyInformation */

  @observable profilePhoto;
  @observable talentPoolFilter;
  @observable sourceTalentPool;
  @observable talentPool;
  @observable talentFilter;

  @observable firstName;

  @observable resumeModel;

  @observable lastName;

  @observable address;

  @observable addressToShow;

  @observable email;
  @observable emailCollaborator;

  @observable phonenumber;

  @observable customText20;

  @observable password;

  @observable preferredContact;

  @observable heardFrom;

  @observable receiveNewsletterTalent;

  @observable receiveNewsletterClient;

  @observable lastInfo;

  @observable acceptTerms;

  /* search */

  @observable remote;

  @observable availability;

  @observable publicProfil;
  @observable toChangePoolName;

  @observable searchAddress;

  @observable searchAddressToShow;

  @observable dayRate;

  @observable mobility;

  @observable backRef;

  @observable backRefCandidateSearch;

  @observable contactPageEmail;

  @observable contactPageMission;

  @observable jobOrderReferer;

  @observable i18n;

  @observable employmentPreference;

  @observable salary;

  /* sign up company */

  @observable id;

  @observable client;

  @observable companyName;

  @observable companyID;

  @observable activityArea;

  @observable sizeOfTheCompany;

  @observable isESN;

  @observable path;

  // @observable accountClient;

  @observable currentPosition;

  @observable display;

  @observable message;

  @observable clientName;

  @observable clientEmail;

  @observable clientPhoneNumber;

  constructor(initialState = defaultValues) {
    this.authToken = initialState.authToken || extractAuth();
    this.candidate = initialState.candidate || null;
    this.display = initialState.display || true;
    this.client = initialState.client || null;
    this.account = initialState.account || null;
    this._id = initialState._id || "";
    this.id = initialState.id || null;
    this.signUpUserRole = initialState.signUpUserRole || "CANDIDATE"; // CANDIDATE OR CLIENT
    this.step = initialState.step || 0;
    this.saveCaseEducation = initialState.saveCaseEducation || false;
    this.shared = initialState.shared || false;
    this.talent = initialState.talent || null;
    this.saveCaseInformation = initialState.saveCaseInformation || false;
    this.saveCaseJob = initialState.saveCaseJob || false;
    this.saveCaseExperience = initialState.saveCaseExperience || false;
    this.saveCaseSearch = initialState.saveCaseSearch || false;
    this.saveCaseResume = initialState.saveCaseResume || false;
    this.expressSignUp = initialState.expressSignUp || false;
    this.myJobPageFilled = initialState.myJobPageFilled || false;
    this.isAuthenticatedByLinkedin =
      initialState.isAuthenticatedByLinkedin || false;
    this.cv = initialState.cv || {};
    this.pictureUrl = initialState.pictureUrl || "";
    this.title = initialState.title || "";
    this.viviertitle = initialState.viviertitle || "";
    this.talentPoolFilter = initialState.talentPoolFilter || "";
    this.sourceTalentPool = initialState.sourceTalentPool || "";
    this.talentPool = initialState.talentPool || {};
    this.talentFilter = initialState.talentFilter || "";
    this.status = initialState.status || "";
    this.siteLanguage = initialState.siteLanguage || "";
    this.ScrapedfirstName = initialState.ScrapedfirstName || "";
    this.ScrapedlastName = initialState.ScrapedlastName || "";
    this.ScrapedLocation = initialState.ScrapedLocation || "";
    this.ScrapedOccupation = initialState.ScrapedOccupation || "";
    this.ScrapedSkills = initialState.ScrapedSkills || [];
    this.ScrapedSpokenLanguages = initialState.ScrapedSpokenLanguages || [];
    this.confirmationCode = initialState.confirmationCode || "";
    this.experience = initialState.experience || 0;
    this.categories = initialState.categories || [];
    this.skills = initialState.skills || [];
    this.creatorID = initialState.creatorID || [];
    this.customTextBlock2 = initialState.customTextBlock2 || "";
    this.poolName = initialState.poolName || "";
    this.customTextBlock4 = initialState.customTextBlock4 || "";
    this.customTextBlock5 = initialState.customTextBlock5 || "";
    this.specialties = initialState.specialties || [];
    this.spokenLanguages = initialState.spokenLanguages || [];
    this.link = initialState.link || "";
    this.customTextBlock3 = initialState.customTextBlock3 || "";
    this.candidateWorkHistory = initialState.candidateWorkHistory || [];
    this.candidateCertification = initialState.candidateCertification || [];
    this.candidateEducation = initialState.candidateEducation || [];
    this.scrappedLinkedinProfile = initialState.scrappedLinkedinProfile || [];
    this.ScrapedExperiences = initialState.ScrapedExperiences || [];
    this.BusinessSector = initialState.BusinessSector || [];
    this.skillExperience = initialState.skillExperience || [];
    this.specialty = initialState.specialty || [];
    this.isScrapedExperiencesAdded =
      initialState.isScrapedExperiencesAdded || true;
    this.isCandidateWorkHistoryValid =
      initialState.isCandidateWorkHistoryValid || true;
    this.iscandidateCertificationValid =
      initialState.iscandidateCertificationValid || true;
    this.iscandidateEducationValid =
      initialState.iscandidateEducationValid || true;
    this.profilePhoto = initialState.profilePhoto || {};
    this.firstName = initialState.firstName || "";
    this.resumeModel = initialState.resumeModel || "Modèle Freelance classique";
    this.lastName = initialState.lastName || "";
    this.address = initialState.address || {};
    this.addressSouhaite = initialState.addressSouhaite || {};
    this.addressToShow = initialState.addressToShow || "";
    this.email = initialState.email || "";
    this.emailCollaborator = initialState.emailCollaborator || "";
    this.phonenumber = initialState.phonenumber || "";
    this.customText20 = initialState.customText20 || "";
    this.password = initialState.password || "";
    this.preferredContact = initialState.preferredContact || "email";
    this.heardFrom = initialState.heardFrom;
    this.receiveNewsletterTalent =
      initialState.receiveNewsletterTalent || undefined;
    this.receiveNewsletterClient =
      initialState.receiveNewsletterClient || undefined;
    this.lastInfo = initialState.lastInfo || undefined;
    this.acceptTerms = initialState.acceptTerms || false;
    this.remote = initialState.remote || "Indifferent";
    this.employmentPreference =
      initialState.employmentPreference || "Permanent";
    this.availability = initialState.availability || null;
    this.publicProfil = initialState.publicProfil || false;
    this.toChangePoolName = initialState.toChangePoolName || false;
    this.searchAddress = initialState.searchAddress || {};
    this.searchAddressToShow = initialState.searchAddressToShow || "";
    this.dayRate = initialState.dayRate || null;
    this.salary = initialState.salary || null;
    this.mobility = initialState.mobility || null;
    this.backRef = initialState.backRef || null;
    this.backRefCandidateSearch = initialState.backRefCandidateSearch || null;
    this.contactPageEmail = {
      email:
        (initialState.contactPageEmail &&
          initialState.contactPageEmail.email) ||
        "",
      name:
        (initialState.contactPageEmail && initialState.contactPageEmail.name) ||
        ""
    };
    this.contactPageMission = {
      id:
        (initialState.contactPageMission &&
          initialState.contactPageMission.id) ||
        "",
      title:
        (initialState.contactPageMission &&
          initialState.contactPageMission.title) ||
        ""
    };
    this.path = initialState.path || "";
    this.jobOrderReferer = initialState.jobOrderReferer || null;
    this.i18n = initialState.i18n || null;

    /* sign up company */
    this.companyName = initialState.companyName || "";
    this.companyID = initialState.companyID || "";
    this.sizeOfTheCompany = initialState.sizeOfTheCompany || 0;
    this.activityArea = initialState.activityArea || [];
    this.isESN = initialState.isESN || false;
    this.currentPosition = initialState.currentPosition || "";
    this.message = initialState.message || "";

    this.clientName = initialState.clientName || "";
    this.clientEmail = initialState.clientEmail || "";
    this.clientPhoneNumber = initialState.clientPhoneNumber || "";
    this.isItDisable = initialState.isItDisable || false;
  }

  /* start action for sign up company */

  @action
  changeClient = client => {
    this.client = client;
    this.initClient();
  };

  @action
  initClient = () => {
    this.signUpUserRole = "CLIENT"; // CANDIDATE OR CLIENT
    this.step = 0;
    this.expressSignUp = false;
    this.id = this.client.id;
    this._id = this.client._id;
    this.sizeOfTheCompany = this.client.sizeOfTheCompany;
    this.activityArea = this.client.activityArea || [];
    this.companyName = this.client.companyName;
    this.companyID = this.client.companyID;
    this.isESN = this.client.isESN;
    this.currentPosition = this.client.currentPosition;
    this.firstName = this.client.firstName;
    this.lastName = this.client.lastName;
    this.email = this.client.email;
    this.phonenumber = this.client.phonenumber;
    this.pictureUrl = this.client.profilePhoto;
    this.profilePhoto = this.client.profilePhoto;
    this.preferredContact = this.client.preferredContact;
    this.heardFrom = this.client.heardFrom;
    this.receiveNewsletterTalent = this.client.receiveNewsletterTalent;
    this.receiveNewsletterClient = this.client.receiveNewsletterClient;
    this.lastInfo = this.client.lastInfo;
    this.acceptTerms = false;
  };
  /* Page company */

  @action
  changeDisplay = display => {
    this.display = display;
  };

  @action
  changeCompanyName = companyName => {
    this.companyName = companyName;
  };

  @action
  changeCompanyID = companyID => {
    this.companyID = companyID;
  };

  @action
  changePath = path => {
    this.path = path;
  };

  @action
  changeIsESN = val => {
    this.isESN = val;
  };

  @action
  changeSizeOfTheCompany = sizeOfTheCompany => {
    this.sizeOfTheCompany = sizeOfTheCompany;
  };

  @action
  addActivityArea = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html

    const arr = this.activityArea.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.activityArea = arr;
    }
  };

  @action
  removeActivityArea = value => {
    this.activityArea.remove(value);
  };

  /* page my information company */
  @action
  changeCurrentPosition = currentPosition => {
    this.currentPosition = currentPosition;
  };

  @action
  changefirstName = firstName => {
    this.firstName = firstName;
  };

  @action
  changeResumeModel = resumeModel => {
    this.resumeModel = resumeModel;
  };

  @action
  changelastName = lastName => {
    this.lastName = lastName;
  };
  @action
  changetalentPoolFilter = talentPoolFilter => {
    this.talentPoolFilter = talentPoolFilter;
  };
  @action
  changesourceTalentPool = sourceTalentPool => {
    this.sourceTalentPool = sourceTalentPool;
  };
  changetalentPool = talentPool => {
    this.talentPool = talentPool;
  };
  @action
  changetalentFilter = talentFilter => {
    this.talentFilter = talentFilter;
  };

  /* end action for sign up company */

  /* this one just for apply btn */
  @action
  setIsItDisable(value) {
    this.isItDisable = value;
  }
  /* ********************* */
  @action
  setI18n = i18n => {
    this.i18n = i18n;
  };

  @action
  setJobOrderReferer = url => {
    this.jobOrderReferer = url || "website/direct Url";
  };

  @action
  setContactPageEmail = obj => {
    this.contactPageEmail = obj;
  };
  @action
  setContactPageMission = obj => {
    this.contactPageMission = obj;
  };

  @action
  setBackRef = backRef => {
    this.backRef = backRef;
  };

  @action
  setBackRefCandidateSearch = backRef => {
    this.backRefCandidateSearch = backRef;
  };

  @action
  changeAccount = account => {
    this.account = account;
  };

  @action
  changeCandidate = candidate => {
    this.candidate = candidate;
    this.initCandidate();
  };

  changeSignUpUserRole = value => {
    this.signUpUserRole = value;
  };

  changeIsAuthenticatedByLinkedin = value => {
    this.isAuthenticatedByLinkedin = value;
  };

  getUserID = () => {
    return this.account.role == "CANDIDATE"
      ? this.candidate.id
      : this.client.id;
  };

  @action
  changeId = _id => {
    this._id = _id;
  };

  @action
  changeAuthToken = token => {
    this.authToken = token;
  };

  @action
  changeConfirmationCode = confirmationCode => {
    this.confirmationCode = confirmationCode;
  };

  @action
  changeExpressSignUp = val => {
    this.expressSignUp = val;
  };

  @action
  changeStep = step => {
    this.step = step;
  };

  @action
  changeSaveCaseEducation = saveCaseEducation => {
    this.saveCaseEducation = saveCaseEducation;
  };
  @action
  changeShared = shared => {
    this.shared = shared;
  };
  @action
  changeTalent = talent => {
    this.talent = talent;
  };
  @action
  changeSaveCaseInformation = saveCaseInformation => {
    this.saveCaseInformation = saveCaseInformation;
  };
  @action
  changeSaveCaseExperience = saveCaseExperience => {
    this.saveCaseExperience = saveCaseExperience;
  };
  @action
  changeSaveCaseJob = saveCaseJob => {
    this.saveCaseJob = saveCaseJob;
  };
  @action
  changeGeneralSaveCase = generalSaveCase => {
    this.generalSaveCase = generalSaveCase;
  };
  @action
  changeSaveCaseResume = saveCaseResume => {
    this.saveCaseResume = saveCaseResume;
  };

  @action
  changeCv = cv => {
    this.cv = cv;
  };
  /* MyJob */

  @action
  changeTitle = title => {
    this.title = title;
  };
  @action
  changeTitleVivier = titleVivier => {
    this.titleVivier = titleVivier;
  };

  @action
  changeStatus = status => {
    this.status = status;
  };

  @action
  changeSiteLanguage = siteLanguage => {
    this.siteLanguage = siteLanguage;
  };

  @action
  changeExperience = experience => {
    this.experience = experience;
  };

  @action
  removeCategory = value => {
    this.categories.remove(value);
  };

  @action
  addCategory = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html

    const arr = this.categories.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.categories = arr;
    }
  };

  @action
  removeSkill = value => {
    this.skills.remove(value);
  };

  @action
  addSkill = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    const arr = this.skills.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.skills = arr;
    }
  };
  @action
  addcreatorID = value => {
    this.creatorID = value;
  };

  @action
  removeSpecialties = value => {
    this.specialties.remove(value);
  };

  @action
  addSpecialties = value => {
    const arr = this.specialties.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.specialties = arr;
    }
  };

  @action
  removeSpokenLanguage = item => {
    for (const element of this.spokenLanguages) {
      if (element.text === item) {
        this.spokenLanguages.remove(element);
      }
    }
  };

  @action
  addSpokenLanguage = item => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    const arr = this.spokenLanguages.slice();
    const found = arr.find(element => element.value === item.value);
    if (!found && item.value) {
      arr.push(item);
      this.spokenLanguages = arr;
    }
  };

  @action
  changeLink = link => {
    this.link = link;
  };
  @action
  changePresentation = customTextBlock3 => {
    this.customTextBlock3 = customTextBlock3;
  };
  /* search */
  @action
  changecustomTextBlock2 = customTextBlock2 => {
    this.customTextBlock2 = customTextBlock2;
  };

  @action
  changepoolName = poolName => {
    this.poolName = poolName;
  };

  @action
  changeDesiredGeoAreas = customTextBlock4 => {
    this.customTextBlock4 = customTextBlock4;
  };

  @action
  changeRemote = remote => {
    this.remote = remote;
  };

  @action
  changeAvailability = availability => {
    this.availability = availability;
  };

  @action
  changetoChangePoolName = toChangePoolName => {
    this.toChangePoolName = toChangePoolName;
  };
  @action
  changePublicProfil = publicProfil => {
    this.publicProfil = publicProfil;
  };
  @action
  changeSearchAddress = searchAddress => {
    this.searchAddress = searchAddress;
  };

  @action
  companyAddressEntreprise = companyAdress => {
    this.companyAdress = companyAdress;
  };

  @action
  changeAddressToShow = addressToShow => {
    this.addressToShow = addressToShow;
  };

  @action
  changeDayRate = dayRate => {
    this.dayRate = dayRate;
  };

  @action
  changeMobility = mobility => {
    this.mobility = mobility;
  };

  @action
  changeEmploymentPreference = employmentPreference => {
    this.employmentPreference = employmentPreference;
  };

  @action
  changeMyJobPageFilled = myJobPageFilled => {
    this.myJobPageFilled = myJobPageFilled;
  };

  @action
  changeSalary = salary => {
    this.salary = salary;
  };

  /* MyInformation */

  @action
  changeProfilePhoto = profilePhoto => {
    this.profilePhoto = profilePhoto;
  };

  @action
  changePictureUrl = pictureUrl => {
    this.pictureUrl = pictureUrl;
  };

  @action
  changeAddress = address => {
    this.address = address;
  };

  @action
  changeAddressSouhaite = addressSouhaite => {
    this.addressSouhaite = addressSouhaite;
  };
  changeAddressToShow = addressToShow => {
    this.addressToShow = addressToShow;
  };

  @action
  changeSearchAddress = searchAddress => {
    this.searchAddress = searchAddress;
  };

  @action
  changeSearchAddressToShow = searchAddressToShow => {
    this.searchAddressToShow = searchAddressToShow;
  };

  @action
  changeEmail = email => {
    this.email = email;
  };
  @action
  changeEmailCollaborator = emailCollaborator => {
    this.emailCollaborator = emailCollaborator;
  };

  @action
  changePhonenumber = phonenumber => {
    this.phonenumber = phonenumber;
  };
  @action
  changeLinkedInURL = customText20 => {
    this.customText20 = customText20;
  };

  @action
  changePassword = password => {
    this.password = password;
  };

  @action
  changeScrapedfirstName = ScrapedfirstName => {
    this.ScrapedfirstName = ScrapedfirstName;
  };

  @action
  changeScrapedlastName = ScrapedlastName => {
    this.ScrapedlastName = ScrapedlastName;
  };

  @action
  changeScrapedLocation = ScrapedLocation => {
    this.ScrapedLocation = ScrapedLocation;
  };

  @action
  changeScrapedOccupation = ScrapedOccupation => {
    this.ScrapedOccupation = ScrapedOccupation;
  };

  @action
  changeScrapedSkills = ScrapedSkills => {
    this.ScrapedSkills = ScrapedSkills;
  };

  @action
  changeScrapedExperiences = ScrapedExperiences => {
    this.ScrapedExperiences = ScrapedExperiences;
  };

  @action
  removeALLBusinessSector = () => {
    this.BusinessSector = [];
  };

  @action
  removeBusinessSector = value => {
    this.BusinessSector.remove(value);
  };

  @action
  addBusinessSector = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html

    const arr = this.BusinessSector.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.BusinessSector = arr;
    }
  };

  @action
  addspecialty = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html

    const arr = this.specialty.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.specialty = arr;
    }
  };

  @action
  removespecialty = value => {
    this.specialty.remove(value);
  };

  @action
  removeALLspecialty = () => {
    this.specialty = [];
  };

  @action
  addskillExperience = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html

    const arr = this.skillExperience.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.skillExperience = arr;
    }
  };

  @action
  removeskillExperience = value => {
    this.skillExperience.remove(value);
  };

  @action
  removeALLskillExperience = () => {
    this.skillExperience = [];
  };

  @action
  changeIsScrapedExperiencesAdded = isScrapedExperiencesAdded => {
    this.isScrapedExperiencesAdded = isScrapedExperiencesAdded;
  };

  @action
  changeScrapedSpokenLanguages = ScrapedSpokenLanguages => {
    this.ScrapedSpokenLanguages = ScrapedSpokenLanguages;
  };

  @action
  changePreferredContact = preferredContact => {
    this.preferredContact = preferredContact;
  };

  @action
  changeHeardFrom = heardFrom => {
    this.heardFrom = heardFrom;
  };

  @action
  changeReceiveNewsletterTalent = receiveNewsletterTalent => {
    this.receiveNewsletterTalent = receiveNewsletterTalent;
  };
  @action
  changeReceiveNewsletterClient = receiveNewsletterClient => {
    this.receiveNewsletterClient = receiveNewsletterClient;
  };
  @action
  changeLastInfo = lastInfo => {
    this.lastInfo = lastInfo;
  };

  @action
  changeAcceptTerms = acceptTerms => {
    this.acceptTerms = acceptTerms;
  };

  @action
  changeCandidateWorkHistory = candidateWorkHistory => {
    // Sort CandidateWorkHistory array by endDate and then startDate
    candidateWorkHistory.startDate = parseFloat(candidateWorkHistory.startDate);
    candidateWorkHistory.endDate = parseFloat(candidateWorkHistory.endDate);
    const sortedCandidateWorkHistory = candidateWorkHistory
      .slice()
      .sort((a, b) => {
        let dateA;
        let dateB;
        if (a.isNew === true || b.isNew === true) {
          return -1;
        }
        if (!a.endDate && !b.endDate) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          a.startDate ? (dateA = new Date(a.startDate)) : (dateA = new Date());
          b.startDate ? (dateB = new Date(b.startDate)) : (dateB = new Date());

          return dateB - dateA;
        }
        a.endDate ? (dateA = new Date(a.endDate)) : (dateA = new Date());
        b.endDate ? (dateB = new Date(b.endDate)) : (dateB = new Date());

        return dateB - dateA;
      });
    this.candidateWorkHistory = sortedCandidateWorkHistory;
    this.isCandidateWorkHistoryValid = true;
    for (let key = 0; key < this.candidateWorkHistory.length; key += 1) {
      const experience = this.candidateWorkHistory[key];
      if (experience.isNew) {
        this.isCandidateWorkHistoryValid = false;
      }
    }

    if (this.candidateWorkHistory.length !== 0 && !this.account.isCompleted) {
      if (typeof window !== "undefined") {
        localStorage.setItem(
          "Experiences",
          JSON.stringify(this.candidateWorkHistory)
        );
      }
    }
  };

  @action
  changecandidateEducation = candidateEducation => {
    // Sort candidateCertification array by endDate and then startDate
    candidateEducation.startDate = parseFloat(candidateEducation.startDate);
    candidateEducation.endDate = parseFloat(candidateEducation.endDate);

    const sortedcandidateEducation = candidateEducation.slice().sort((a, b) => {
      let dateA;
      let dateB;
      if (a.isNew === true || b.isNew === true) {
        return -1;
      }
      if (!a.endDate && !b.endDate) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        a.startDate ? (dateA = new Date(a.startDate)) : (dateA = new Date());
        b.startDate ? (dateB = new Date(b.startDate)) : (dateB = new Date());

        return dateB - dateA;
      }

      a.endDate ? (dateA = new Date(a.endDate)) : (dateA = new Date());
      b.endDate ? (dateB = new Date(b.endDate)) : (dateB = new Date());

      return dateB - dateA;
    });
    this.candidateEducation = sortedcandidateEducation;
    this.iscandidateEducationValid = true;
    for (let key = 0; key < this.candidateEducation.length; key += 1) {
      const education = this.candidateEducation[key];
      if (education.isNew) {
        this.iscandidateEducationValid = false;
      }
    }

    if (this.candidateEducation.length !== 0 && !this.account.isCompleted) {
      if (typeof window !== "undefined") {
        localStorage.setItem(
          "Education",
          JSON.stringify(this.candidateEducation)
        );
      }
    }
  };

  @action
  changecandidateCertification = candidateCertification => {
    // Sort candidateCertification array by endDate and then startDate
    candidateCertification.startDate = parseFloat(
      candidateCertification.startDate
    );
    candidateCertification.endDate = parseFloat(candidateCertification.endDate);

    const sortedCandidateCertification = candidateCertification
      .slice()
      .sort((a, b) => {
        let dateA;
        let dateB;
        if (a.isNew === true || b.isNew === true) {
          return -1;
        }
        if (!a.endDate && !b.endDate) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          a.startDate ? (dateA = new Date(a.startDate)) : (dateA = new Date());
          b.startDate ? (dateB = new Date(b.startDate)) : (dateB = new Date());

          return dateB - dateA;
        }

        a.endDate ? (dateA = new Date(a.endDate)) : (dateA = new Date());
        b.endDate ? (dateB = new Date(b.endDate)) : (dateB = new Date());

        return dateB - dateA;
      });
    this.candidateCertification = sortedCandidateCertification;
    this.iscandidateCertificationValid = true;
    for (let key = 0; key < this.candidateCertification.length; key += 1) {
      const certification = this.candidateCertification[key];
      if (certification.isNew) {
        this.iscandidateCertificationValid = false;
      }
    }

    if (this.candidateCertification.length !== 0) {
      if (typeof window !== "undefined") {
        localStorage.setItem(
          "Certification",
          JSON.stringify(this.candidateCertification)
        );
      }
    }
  };

  @action
  changeScrappedLinkedinProfile = scrappedLinkedinProfile => {
    this.scrappedLinkedinProfile = scrappedLinkedinProfile;
    if (typeof window !== "undefined") {
      // if (localstorage !== undefined) {
      // localStorage.removeItem("Experiences");
      let scrapper = JSON.parse(localStorage.getItem("Experiences"))
        ? JSON.parse(localStorage.getItem("Experiences"))
        : [];
      let candidateWorkHistoryVar = [];
      if (
        scrapper.length === 0 &&
        scrappedLinkedinProfile.experiences &&
        this.candidateWorkHistory.length === 0 &&
        !this.account.isCompleted
      ) {
        let keyscrapped = 0;
        scrappedLinkedinProfile.experiences.forEach(wkHistory => {
          keyscrapped = keyscrapped + 1;
          candidateWorkHistoryVar.push({
            id: keyscrapped.toString(),
            title: wkHistory.title,
            companyName: wkHistory.company,
            startDate: getTimestamp(wkHistory.starts_at),
            endDate: getTimestamp(wkHistory.ends_at),
            companyAddress: { countryID: wkHistory.location },
            summary: wkHistory.description
          });
        });
        if (keyscrapped === scrappedLinkedinProfile.experiences.length) {
          localStorage.setItem(
            "Experiences",
            JSON.stringify(candidateWorkHistoryVar)
          );
        }
      }
      //  }
    }
  };

  @action
  changeIsCandidateWorkHistoryValid = isCandidateWorkHistoryValid => {
    this.isCandidateWorkHistoryValid = !isCandidateWorkHistoryValid;
  };

  @action
  changeIscandidateEducationValid = isCandidateEducationValid => {
    this.iscandidateEducationValid = !isCandidateEducationValid;
  };

  @action
  changeIscandidateCertificationValid = isCandidateCertificationValid => {
    this.iscandidateCertificationValid = !isCandidateCertificationValid;
  };

  @action
  logout = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem(AUTH_TOKEN);
      cookie.remove(AUTH_TOKEN, cookieRemoveOpt);
      this.changeAuthToken("");
      this.reset();
    }
  };

  @action
  initCandidate = () => {
    this.signUpUserRole = "CANDIDATE"; // CANDIDATE OR CLIENT
    this.step = 0;
    this.saveCaseEducation = false;
    this.shared = false;
    this.talent = null;
    (this.saveCaseInformation = false),
      (this.saveCaseJob = false),
      (this.saveCaseExperience = false),
      (this.saveCaseSearch = false),
      (this.saveCaseResume = false),
      (this.link = "");
    this.customTextBlock3 = "";
    this.expressSignUp = false;
    this.isAuthenticatedByLinkedin = false;
    this.pictureUrl = this.candidate.profilePhoto;
    /* MyJob */
    this.title = this.candidate.occupation;
    this.status = this.candidate.customText15;
    this.experience = this.candidate.experience;
    this.categories = this.candidate.categories || [];
    this.spokenLanguages =
      (this.candidate.spokenLanguages &&
        this.candidate.spokenLanguages.map(language => {
          return {
            value: language,
            text: language
          };
        })) ||
      [];
    this.skills = this.candidate.skills || [];
    this.creatorID = this.creatorID || [];
    this.customTextBlock2 = this.candidate.customTextBlock2;
    this.poolName = this.candidate.poolName;
    this.customTextBlock3 = this.candidate.customTextBlock3;
    this.customTextBlock4 = this.candidate.customTextBlock4;
    this.customTextBlock5 = this.candidate.customTextBlock5;
    this.specialties = this.candidate.specialties;
    this.link = this.candidate.companyURL;
    /* MyExperience */
    // this.candidateWorkHistory = [];
    this.isCandidateWorkHistoryValid = true;
    this.iscandidateCertificationValid = true;
    this.iscandidateEducationValid = true;
    this.profilePhoto = this.candidate.profilePhoto;
    this.firstName = this.candidate.firstName;
    this.lastName = this.candidate.lastName;
    if (this.candidate.address) {
      this.address = { ...this.candidate.address };
      delete this.address.__typename;
    }
    if (this.candidate.addressSouhaite) {
      this.addressSouhaite = { ...this.candidate.addressSouhaite };
      delete this.addressSouhaite.__typename;
    }

    this._id = this.candidate._id;
    this.email = this.candidate.email;
    this.phonenumber = this.candidate.phone;
    this.customText20 = this.candidate.customText20;
    this.preferredContact = this.candidate.preferredContact;
    this.heardFrom = this.candidate.heardFrom;
    this.receiveNewsletterTalent = this.candidate.receiveNewsletterTalent;
    this.receiveNewsletterClient = this.candidate.receiveNewsletterClient;
    this.lastInfo = this.candidate.lastInfo;
    this.acceptTerms = false;
    /* search */
    this.remote = this.candidate.customText21;
    this.employmentPreference = this.candidate.employmentPreference;
    this.availability = this.candidate.dateAvailable;
    this.publicProfil = this.candidate.publicProfil;
    this.toChangePoolName = this.candidate.toChangePoolName;
    if (this.candidate.searchAddress) {
      this.searchAddress = { ...this.candidate.searchAddress };
      delete this.searchAddress.__typename;
    }
    this.dayRate = this.candidate.dayRateLow;
    this.salary = this.candidate.salary;
    this.mobility = this.candidate.customInt10;
  };

  @action
  changeClientName = clientName => {
    this.clientName = clientName;
  };

  @action
  changeClientEmail = clientEmail => {
    this.clientEmail = clientEmail;
  };

  @action
  changeClientPhoneNumber = clientPhoneNumber => {
    this.clientPhoneNumber = clientPhoneNumber;
  };

  @action
  reset = () => {
    this.authToken = "";
    this._id = "";
    this.id = null;
    this.candidate = null;
    this.account = null;
    // this.signUpUserRole = "CANDIDATE"; // CANDIDATE OR CLIENT
    this.step = 0;
    this.saveCaseEducation = false;
    this.shared = false;
    this.talent = null;
    (this.saveCaseInformation = false),
      (this.saveCaseJob = false),
      (this.saveCaseExperience = false),
      (this.saveCaseSearch = false),
      (this.saveCaseResume = false),
      (this.expressSignUp = false);
    this.isAuthenticatedByLinkedin = false;
    this.pictureUrl = "";
    /* MyJob */
    this.title = "";
    this.status = "";
    this.siteLanguage = "";
    this.ScrapedfirstName = "";
    this.ScrapedlastName = "";
    this.ScrapedLocation = "";
    this.ScrapedOccupation = "";
    this.ScrapedSkills = [];
    this.ScrapedSpokenLanguages = [];
    this.confirmationCode = "";
    this.experience = 0;
    this.categories = [];
    this.skills = [];
    this.creatorID = [];
    this.customTextBlock2 = "";
    this.poolName = "";
    this.customTextBlock4 = "";
    this.customTextBlock5 = "";
    this.spokenLanguages = [];
    this.link = "";
    this.customTextBlock3 = "";
    /* MyExperience */
    this.candidateWorkHistory = [];
    this.candidateEducation = [];
    this.candidateCertification = [];
    this.scrappedLinkedinProfile = {};
    this.ScrapedExperiences = [];
    this.isScrapedExperiencesAdded = true;
    this.BusinessSector = [];
    this.specialty = [];
    this.skillExperience = [];
    this.isCandidateWorkHistoryValid = true;
    this.iscandidateEducationValid = true;
    this.iscandidateCertificationValid = true;
    this.profilePhoto = {};
    this.firstName = "";
    this.resumeModel = "";
    this.lastName = "";
    this.address = {};
    this.addressSouhaite = {};
    this.addressToShow = "";
    this.email = "";
    this.emailCollaborator = "";
    this.phonenumber = "";
    this.customText20 = "";
    this.password = "";
    this.preferredContact = "sms";
    this.heardFrom = "";
    this.receiveNewsletterTalent = undefined;
    this.receiveNewsletterClient = undefined;
    this.lastInfo = undefined;
    this.acceptTerms = false;
    /* cv */
    this.cv = {};
    /* search */
    this.remote = "Indifferent";
    this.availability = null;
    this.publicProfil = false;
    this.toChangePoolName = false;
    this.searchAddress = {};
    this.searchAddressToShow = "";
    this.dayRate = null;
    this.salary = null;
    this.mobility = null;
    this.employmentPreference = "Permanent";

    /* my company */
    this.client = null;
    this.companyName = "";
    this.companyID = 0;
    this.sizeOfTheCompany = 0;
    this.activityArea = [];
    this.isESN = false;

    this.currentPosition = "";

    this.clientName = "";
    this.clientEmail = "";
    this.clientPhoneNumber = "";
  };

  @action
  changeCode = code => {
    this.code = code;
  };

  @action
  changeMessage = message => {
    this.message = message;
  };

  @action
  loadLinkedinInfo = data => {
    const parsedData = JSON.parse(data);
    const { firstName, lastName, email, profilePicture } = parsedData;
    if (this.email === "") this.email = email;
    this.pictureUrl = profilePicture;
    this.firstName = firstName;
    this.lastName = lastName;
  };
  @action
  changeMyCVPageFilled = myCVPageFilled => {
    this.myCVPageFilled = myCVPageFilled;
  };
  @action
  resetField = fieldName => {
    this[fieldName] = defaultValues[fieldName];
  };

  @action
  resetFields = arrayOfFieldNames =>
    arrayOfFieldNames.forEach(filedName => this.resetField(filedName));
}
