import React, { Component } from "react";
import { Mutation, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  Validation,
  FormGroup,
  Button,
  PopUp,
  TotalHoursForMobile
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import { UPDATE_TIMESHEET } from "../mutations";
import { SINGLE_TIME_SHEETS_QUERY } from "../queries";
import publicIp from "public-ip";
import convertTodays from "../../../utils/convertTodays";

@inject("newTimesheetStore")
@observer
class ValidateTimesheetPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
    publicIp
      .v4()
      .then(res => {
        this.ip = res;
      })
      .catch(() => {});
  }

  isValid = () => true;

  render() {
    const { message } = this.state;
    const { newTimesheetStore, device, i18n } = this.props;
    const {
      openClosePopUps,
      timesheet,
      normalHours,
      specialHours,
      workPlan
    } = newTimesheetStore;
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => {
          this.setState({ message: "" });
          openClosePopUps("sendTimesheet");
        }}
      >
        <div
          style={{
            position: "relative",
            height: "360px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "26px"
            }}
          >
            <Trans>Envoyer Timesheet</Trans>
          </div>
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#242424",
              marginBottom: "15px"
            }}
          >
            {/* todo */}
            Une fois le timesheet envoyé,bla bla bla bla bla bla ( ce texte doit
            etre modifié)
          </div>
          <div
            style={{
              position: "relative",
              padding: "10px 30px"
            }}
          >
            <TotalHoursForMobile
              normalHours={`${Math.floor(normalHours / 60)}`}
              normalDays={convertTodays(normalHours, i18n)}
              specialDays={convertTodays(specialHours, i18n)}
              specialHours={`${Math.floor(specialHours / 60)}`}
              normalHoursText={i18n._(t`Heures normales`)}
              specialHoursText={i18n._(t`Heures spéciales`)}
            />
          </div>
          <FormGroup style={{ paddingTop: "30px" }}>
            <label
              style={{
                position: "relative",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                color: "#242424"
              }}
            >
              <Trans>Message</Trans>
            </label>
            <Validation errorMessage="" valid={true}>
              <textarea
                className={styles.textArea}
                id="addMessage"
                placeholder={i18n._(t`Voudriez-vous laisser un message ?`)}
                value={message}
                onChange={e => this.setState({ message: e.target.value })}
                style={{ paddingLeft: "10px", marginTop: "10px" }}
              />
            </Validation>
          </FormGroup>
          <div>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#000000"
              }}
            >
              <Trans>* Ce commentaire sera visible par le client </Trans>
            </span>
          </div>
          <div
            style={{
              width: "100%",
              padding: "20px 0"
            }}
          >
            <Mutation
              mutation={UPDATE_TIMESHEET}
              variables={{
                id: timesheet.id,
                action: "send", //draft, send, validate, approve, reject
                ip: this.ip,
                workPlan,
                messages: [
                  message && { text: message, type: "message" }
                ].filter(n => n)
              }}
              refetchQueries={[
                {
                  query: SINGLE_TIME_SHEETS_QUERY,
                  variables: { id: timesheet.id }
                }
              ]}
              onCompleted={() => {
                this.setState({ message: "" });
                openClosePopUps("timesheetSentSuccessfully");
              }}
              onError={errors => {}}
            >
              {(mutation, { loading }) => (
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    if (this.isValid()) {
                      openClosePopUps("sendTimesheet");
                      return mutation();
                    }
                    return null;
                  }}
                  style={{ width: "100%" }}
                >
                  <Trans>Envoyer</Trans>
                </Button>
              )}
            </Mutation>
          </div>
        </div>
      </PopUp>
    );
  }
}

ValidateTimesheetPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    workPlan: PropTypes.shape({}),
    timesheet: PropTypes.shape({}),
    normalHours: PropTypes.number,
    specialHours: PropTypes.number
  }).isRequired,
  device: PropTypes.string
};
export default withApollo(withI18n()(withRouter(ValidateTimesheetPopUp)));
