import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "./finance.module.scss";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import Section5 from "./components/section5";
import Section6 from "./components/section6";
import Section7 from "./components/section7";
import Section8 from "./components/section8";
import Section9 from "./components/section9";
import Section10 from "./components/section10";
import Section11 from "./components/section11";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import ldJson from "./ldJson";

import { Col } from "cf-neo-ui";

@inject("sessionStore", "appStore", "modalStore", "postANeedStore")
@observer
class FinanceClient extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { i18n } = this.props;

    const meta = staticMetaTags(i18n).financeClient;

    return (
      <div className={classes.container}>
        {metaTags(
          urls.financeClient(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          ldJson
        )}
        <Section1></Section1>
        <Section2></Section2>
        <Section3></Section3>
        <Section4></Section4>
        <Section5></Section5>
        <Section6></Section6>
        <Section7></Section7>
        <Section8></Section8>
        {/* <Section9></Section9> */}
        <Section10></Section10>
        <Section11></Section11>
      </div>
    );
  }
}

FinanceClient.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(FinanceClient));
