import countryZipCodes from "./countryZipCodes.json";

/**
 * Get the zip code for a given country.
 * @param {string} countryShortName - The name of the country (case-insensitive).
 * @returns {string|null} - The zip code for the country, or null if not found.
 */
const DEFAULT_COUNTRY_SHORT_NAME = "FR";

function getZipCode(countryShortName = DEFAULT_COUNTRY_SHORT_NAME) {
  const normalizedCountryShortName =
    countryShortName?.toUpperCase?.() || DEFAULT_COUNTRY_SHORT_NAME;

  // Try to find a match using either English or French names
  const zipCode = countryZipCodes[normalizedCountryShortName];

  // Return the result or null if not found
  return zipCode || "";
}
export default getZipCode;
