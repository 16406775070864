import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { TextInput, DatePicker, Icon, Pagination } from "cf-neo-ui";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import moment from "moment";
import classes from "./styles.module.scss";
import DisplayStatusCards from "./status-cards-list/DisplayStatusCards";
import runtimeVars from "../../../../configs/runTimeVars";
import LoadingMissions from "../../../../components/LoadingMissions/LoadingMissions";
import { isOnePage } from "../../../../utils/helpers";
import { TIME_SHEETS_QUERY } from "../../queries";
import isEmail from "../../../../utils/isEmail";
import OpaqueScreen from "../../OpaqueScreen";

const PossibleStatus = [null, "open", "pending", "approved", "rejected"];

const isInt = value => value == Number.parseInt(value, 10);

@inject("sessionStore", "appStore", "signataireStore")
@observer
class InnerTabs extends Component {
  constructor(props) {
    super(props);
    try {
      this.isClient = props.sessionStore.account.role === "CLIENT";
    } catch (e) {
      this.isClient = false;
    }

    try {
      this.isCandidate = props.sessionStore.account.role === "CANDIDATE";
    } catch (e) {
      this.isCandidate = false;
    }

    this.state = {
      status: null,
      startDate: null,
      endDate: null,
      search: null,
      ids: null,
      skip: 0,
      pageSize: this.setPageSize(props.appStore.width),
      cardsCount: this.setPageSize(props.appStore.width),
      currentPage: 1,
      email: null
    };
    this.tempSearch = "";
    this.total = 0;
  }

  changeStatus = n => {
    this.total = 0;
    this.setState({ skip: 0, currentPage: 1, status: PossibleStatus[n] });
  };

  search = () => {
    if (!this.tempSearch)
      this.setState({ search: null, ids: null, email: null });
    else this.setState({ skip: 0, currentPage: 1 });

    if (isInt(this.tempSearch))
      return this.setState({
        ids: [Number.parseInt(this.tempSearch, 10)],
        email: null,
        search: null
      });
    if (isEmail(this.tempSearch))
      return this.setState({ email: this.tempSearch, ids: null, search: null });
    if (this.tempSearch.length > 2)
      return this.setState({ search: this.tempSearch, ids: null, email: null });
    return null;
  };

  renderPagination = length => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };
    return <Pagination {...paginationProps} />;
  };

  setPageSize = width => {
    if (width >= 1840) return 5;
    if (width >= 1440) return 4;
    if (width >= 1024) return 3;
    if (width >= 768) return 3;
    return 2;
  };

  setTotalTimesheet = timesheet => {
    if (timesheet) this.total = timesheet.total;
    return this.total;
  };

  getQueryVariables = () => {
    const {
      pageSize,
      skip,
      startDate,
      endDate,
      status,
      search,
      ids,
      email
    } = this.state;

    const filter = {};

    if (skip) filter.skip = skip;
    if (pageSize) filter.limit = pageSize;
    if (startDate) filter.periodStart = startDate;
    if (endDate) filter.periodEnd = endDate;
    if (ids) filter.placementIDs = ids;
    if (search) filter.name = search;
    if (status) filter.status = status;
    if (email) filter.email = email;

    return filter;
  };

  reset = () => {
    this.setState({
      startDate: null,
      endDate: null,
      search: null
    });
  };

  showResult = (timesheets, status) => {
    const { startDate, endDate, search, ids, email } = this.state;
    if (!timesheets || timesheets.length === 0) {
      if (
        startDate !== null ||
        endDate !== null ||
        search !== null ||
        ids !== null ||
        email !== null
      )
        return (
          <div>
            <Trans>
              aucun résultat ne correspond à vos critères de recherche
            </Trans>
          </div>
        );
      return (
        <div>
          <Trans>aucun timesheet disponible</Trans>
        </div>
      );
    }
    return <DisplayStatusCards cards={timesheets} />;
  };

  render() {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const {
      status,
      startDate,
      endDate,
      search,
      cardsCount,
      pageSize
    } = this.state;
    let longueur = null;
    return (
      <div className={classes.container}>
        <div className={classes.tabs}>
          <button
            type="button"
            className={classes.tabItem}
            style={{
              color: status === PossibleStatus[0] ? "#C40B24" : "unset"
            }}
            onClick={() => this.changeStatus(0)}
          >
            <Trans>Tous</Trans>
          </button>

          {this.isCandidate && (
            <button
              type="button"
              className={classes.tabItem}
              style={{
                color: status === PossibleStatus[1] ? "#C40B24" : "unset"
              }}
              onClick={() => this.changeStatus(1)}
            >
              <Trans>Ouverts</Trans>
            </button>
          )}
          <button
            type="button"
            className={classes.tabItem}
            style={{
              color: status === PossibleStatus[2] ? "#C40B24" : "unset"
            }}
            onClick={() => this.changeStatus(2)}
          >
            <Trans>En attente</Trans>
          </button>
          <button
            type="button"
            className={classes.tabItem}
            style={{
              color: status === PossibleStatus[3] ? "#C40B24" : "unset"
            }}
            onClick={() => this.changeStatus(3)}
          >
            <Trans>Validés</Trans>
          </button>
          <button
            type="button"
            className={classes.tabItem}
            style={{
              color: status === PossibleStatus[4] ? "#C40B24" : "unset"
            }}
            onClick={() => this.changeStatus(4)}
          >
            <Trans>Rejetés</Trans>
          </button>
          <div className={classes.searchBar}>
            <div className={classes.tooltip}>
              <TextInput
                size="small"
                placeholder={
                  this.isClient
                    ? i18n._(t`chercher par id, consultant`)
                    : i18n._(t`chercher par id, entreprise ou signataire`)
                }
                icon="search"
                radius={50}
                defaultValue={search || ""}
                onChange={e => {
                  this.tempSearch = e.target.value;
                }}
                onEnterPressed={this.search}
                clickableIcon
                onIconClicked={this.search}
              />
              <span className={classes.tooltipText}>
                <Trans>
                  votre mot clé doit contenir 3 caractères au minimum
                </Trans>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.dateFilter}>
          <span>
            <Trans>Période</Trans>:
          </span>
          <div className={classes.dateFilter}>
            <div className={classes.dateFilter}>
              <span className={classes.dateFilterLabel}>
                <Trans>Du</Trans>
              </span>
              <DatePicker
                placeholder={i18n._(t`jj/mm/aaaa`)}
                dateFormat="DD/MM/YYYY"
                defaultDate={
                  startDate ? moment(startDate).format("DD/MM/YYYY") : ""
                }
                value={!startDate ? "" : moment(startDate).format("DD/MM/YYYY")}
                onChange={v => {
                  if (v) this.setState({ startDate: moment(v).valueOf() });
                }}
                locale={currentLanguage || runtimeVars.APP_LANG}
                size="small"
              />
            </div>
            <div className={classes.dateFilter}>
              <span className={classes.dateFilterLabel}>
                <Trans>Au</Trans>
              </span>
              <DatePicker
                placeholder={i18n._(t`jj/mm/aaaa`)}
                dateFormat="DD/MM/YYYY"
                defaultDate={
                  endDate ? moment(endDate).format("DD/MM/YYYY") : ""
                }
                value={!endDate ? "" : moment(endDate).format("DD/MM/YYYY")}
                onChange={v => {
                  if (v) this.setState({ endDate: moment(v).valueOf() });
                }}
                locale={currentLanguage || runtimeVars.APP_LANG}
                size="small"
              />
            </div>
          </div>
          <div
            className={classes.reset}
            title={i18n._(t`Réinitialiser`)}
            onClick={this.reset}
          >
            <Icon type="fat-close" color="#808080" />
          </div>
        </div>
        <div className={classes.queryContent}>
          <Query
            query={TIME_SHEETS_QUERY}
            variables={this.getQueryVariables()}
            fetchPolicy="no-cache"
          >
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <LoadingMissions
                    cardsCount={cardsCount}
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={6}
                    colClassName={classes.favedMissionsCard}
                    containerClassName={classes.marTop}
                  />
                );

              if (error) {
                return (
                  <div className="error_container">
                    <span>
                      {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                    </span>
                    <img
                      style={{
                        margin: 20
                      }}
                      src={"/assets/svg/download.svg"}
                      alt="error"
                    />
                  </div>
                );
              }
              const { timesheets, islocked } = data?.timesheets;

              if (timesheets)
                longueur = this.setTotalTimesheet(data?.timesheets);
              return (
                <div>
                  {this.showResult(timesheets, status)}
                  {!timesheets ||
                  timesheets.length === 0 ||
                  isOnePage(pageSize, longueur) ? null : (
                    <div className={classes.pagination}>
                      {this.renderPagination(longueur)}
                    </div>
                  )}
                  {islocked ? <OpaqueScreen /> : null}
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

InnerTabs.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired,
  signataireStore: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    SignatoryList: PropTypes.array,
    addSignatory: PropTypes.func,
    changeSignatory: PropTypes.func
  }).isRequired
};

export default withI18n()(InnerTabs);
