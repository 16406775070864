import React from "react";
import MainBlog from "../../blog/main/MainBlog";
import News from "../../blog/news/News";
import ConsultantAdvices from "../../blog/advices-consultant/Advices";
import EnterpriseAdvices from "../../blog/advices-enterprise/Advices";
import Search from "../../blog/search/Search";
import SingleBlog from "../../blog/blog-single-page/BlogSinglePage";
import Filter from "../../blog/filter-by/FilterBy";
import Resources from "../../blog/resources/Resources";
import Decryption from "../../blog/decryption/Decryption";
import SuccessStory from "../../blog/success-stories/SuccessStory";

import BlogCategory from "../../blog/blogCategory";
/** !!! For any modification in this file, please re-do it in src/utils/urls.js */

const BlogLayoutRoutes = {
  Blog: { path: "/blog", component: MainBlog },

  // old blog
  SingleBlogWithTitle: { path: "/blog/news/:id/:title", component: SingleBlog },
  SingleBlog: { path: "/blog/news/:id", component: SingleBlog },
  News: { path: "/blog/news", component: News },
  AdvicesConsultant: {
    path: "/blog/advices/consultant",
    component: ConsultantAdvices
  },
  AdvicesEnterprise: {
    path: "/blog/advices/enterprise",
    component: EnterpriseAdvices
  },
  Decryption: { path: "/blog/decryption", component: Decryption },
  SuccessStories: { path: "/blog/stories", component: SuccessStory },
  Search: { path: "/blog/search", component: Search },
  Filter: { path: "/blog/filter", component: Filter },
  Resources: { path: "/blog/resources/:id", component: Resources },
  "tips-and-tricks": {
    path: "/blog/career-spa/tips-and-tricks",
    component: () => <Category category="tips-and-tricks" />
  },
  "its-who-is-who": {
    path: "/blog/career-spa/its-who-is-who",
    component: () => <Category category="its-who-is-who" />
  },
  "business-lounge": {
    path: "/blog/business-lounge",
    component: () => <Category category="business-lounge" />
  },
  Newsstand: {
    path: "/blog/kiosk/Newsstand",
    component: () => <Category category="Newsstand" />
  },
  "captains-log": {
    path: "/blog/kiosk/captains-log",
    component: () => <Category category="captains-log" />
  },
  "interesting-reads": {
    path: "/blog/kiosk/interesting-reads",
    component: () => <Category category="interesting-reads" />
  },
  "horizon-2050": {
    path: "/blog/horizon-2050",
    component: () => <Category category="horizon-2050" />
  },
  "behind-screen": {
    path: "/blog/behind-screen",
    component: () => <Category category="behind-screen" />
  },
  BlogCategory: {
    path: "/blog/:category",
    component: BlogCategory
  },
  SubCategory: {
    path: "/blog/:category/:subCategory",
    component: BlogCategory
  },
  SsubCategory: {
    path: "/blog/:category/:subCategory/:ssubCategory",
    component: BlogCategory
  }
};

export default BlogLayoutRoutes;
