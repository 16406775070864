import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { TagList, ProgressBar } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import Location from "../../../../../components/location/location";
import classes from "../SideFilters.module.scss";

@inject("missionsSearchStore")
@observer
class LocationWidget extends Component {
  selectLocationHandler = (address, formattedAddress) => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.addLocation(formattedAddress);
  };

  closeTagHandler = label => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.removeLocation(label);
  };

  withinprogressBar = val => {
    const { missionsSearchStore } = this.props;
    setTimeout(() => {
      missionsSearchStore.setRadius(val);
    }, 4000);
  };

  render() {
    const { missionsSearchStore } = this.props;
    return (
      <div>
        <Location getLocation={this.selectLocationHandler} />
        <TagList
          tags={missionsSearchStore.cityLabels}
          variant="secondary"
          closable
          className={classes.tagList}
          onClose={this.closeTagHandler}
        />
        {missionsSearchStore.cityLabels.length ? (
          <span>
            <p className={classes.widgetSubtitle}>
              <Trans>Dans un rayon de</Trans>
            </p>
            <ProgressBar
              min={0}
              max={200}
              step={10}
              defaultValue={200}
              unit="Km"
              onChange={val => this.withinprogressBar(val)}
              fix={true}
            />
          </span>
        ) : null}
      </div>
    );
  }
}

LocationWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.PropTypes.shape({
    cityLabels: PropTypes.shape({}),
    addLocation: PropTypes.func,
    removeLocation: PropTypes.func,
    setRadius: PropTypes.func
  }).isRequired
};

export default LocationWidget;
