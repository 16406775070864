import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col } from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import LeftSlide from "./LeftSlide/LeftSlide";
import MissionDescription from "./MissionDescription/MissionDescription";
import Suggestion from "./similar-job-orders/SimilarJobOrders";

@inject("appStore", "sessionStore")
@observer
class SingleMissionBody extends Component {
  render() {
    const {
      loading,
      sourcer,
      content,
      id,
      appStore,
      customText7,
      sessionStore
    } = this.props;

    return (
      <Row noGape>
        {appStore.width >= 1024 && (
          <Col noGutter xl={3} lg={3} md={3} sm={12} xs={6}>
            <LeftSlide
              loading={loading}
              sourcer={sourcer}
              id={id}
              customText7={customText7}
              disable={sessionStore.isItDisable}
            />
          </Col>
        )}
        <Col noGutter xl={9} lg={9} md={9} sm={12} xs={6}>
          <Row noGape>
            <Col noGutter lg={12} md={12} sm={12}>
              <MissionDescription
                content={content}
                loading={loading}
                customText7={customText7}
                id={id}
                disable={sessionStore.isItDisable}
              />
              {appStore.width < 1024 && (
                <LeftSlide
                  loading={loading}
                  sourcer={sourcer}
                  id={id}
                  customText7={customText7}
                  disable={sessionStore.isItDisable}
                />
              )}
            </Col>
            <Col noGutter lg={12} md={12} sm={12}>
              <Suggestion
                loading={loading}
                sourcer={sourcer}
                customText7={customText7}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

SingleMissionBody.wrappedComponent.propTypes = {
  loading: PropTypes.bool,
  sourcer: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    description: PropTypes.string,
    linkedIn: PropTypes.string
  }),
  content: PropTypes.shape({
    context: PropTypes.string,
    mission: PropTypes.string,
    requiredProfile: PropTypes.string,
    benefits: PropTypes.string,
    contact: PropTypes.string
  }),
  customText7: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired
};
SingleMissionBody.defaultProps = {
  loading: true,
  content: {
    context: "",
    mission: "",
    requiredProfile: "",
    benefits: "",
    contact: ""
  },
  sourcer: {
    avatar: "",
    name: "",
    position: "",
    email: "",
    phoneNumber: "",
    description: "",
    linkedIn: ""
  }
};

export default SingleMissionBody;
