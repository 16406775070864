import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { MonthlyTimesheetForMobile, TotalHoursForMobile } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import convertTodays from "../../../utils/convertTodays";

@inject("newTimesheetStore", "appStore", "sessionStore")
@observer
class MonthVue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      normalHours: 0,
      specialHours: 0
    };
  }
  DeleteAllDaysOfTimesheet() {
    const { newTimesheetStore } = this.props;
    let { deleteAllDays } = newTimesheetStore;
    deleteAllDays();
  }
  render() {
    const { newTimesheetStore, appStore, i18n, sessionStore } = this.props;
    const { currentLanguage } = appStore;
    const { account } = sessionStore;
    const {
      workPlan,
      normalHours,
      specialHours,
      timesheet,
      getDayDetails,
      openClosePopUps,
      holidays,
      warnings
    } = newTimesheetStore;

    const finalWorkPlan = [];

    workPlan.map(element => {
      const res = finalWorkPlan.find(
        el => el.day === element.day && el.theme.name === element.theme.name
      );
      if (!res) finalWorkPlan.push(element);
    });
    return (
      <div style={{ padding: "0 15px" }}>
        <MonthlyTimesheetForMobile
          local={currentLanguage === "en" ? "gb" : "fr"}
          month={timesheet.month}
          year={timesheet.year}
          iconTooltip="precise hours"
          defaultSelectedDays={finalWorkPlan}
          warnings={warnings}
          getSelectedDay={day => {
            if (day) {
              getDayDetails(day);
              openClosePopUps("dayDetails");
            }
          }}
          holidays={holidays}
          isCandidateandCanDelete={
            sessionStore.account.role === "CANDIDATE" &&
            ["open", "rejected"].includes(timesheet.status)
          }
          deleteAllDays={() => this.DeleteAllDaysOfTimesheet()}
          deletedtext={i18n._(t`Effacer tout`)}
        />
        <TotalHoursForMobile
          normalHours={`${Math.floor(normalHours / 60)}`}
          normalDays={convertTodays(normalHours, i18n)}
          specialHours={`${Math.floor(specialHours / 60)}`}
          specialDays={convertTodays(specialHours, i18n)}
          normalHoursText={i18n._(t`Heures normales`)}
          specialHoursText={i18n._(t`Heures spéciales`)}
        />
      </div>
    );
  }
}

MonthVue.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    timesheetPopUps: PropTypes.shape({
      addTheme: PropTypes.string
    }),
    openClosePopUps: PropTypes.func,
    getDayDetails: PropTypes.func,
    themes: PropTypes.shape({}),
    workPlan: PropTypes.shape({}),
    warnings: PropTypes.shape({}),
    timesheet: PropTypes.shape({}),
    normalHours: PropTypes.number,
    specialHours: PropTypes.number,
    totalHours: PropTypes.number
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }).isRequired
};
export default withApollo(withI18n()(withRouter(MonthVue)));
