import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Button, Icon } from "cf-neo-ui";
import "./styles.scss";
import Routes from "../../layout/routes";

@inject("modalStore", "sessionStore")
@observer
class ErrorPassword extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  closeModal = () => {
    const { modalStore } = this.props;
    const { changeErrorPassword } = modalStore;
    changeErrorPassword(false);
  };

  render() {
    return (
      <div
        style={{
          maxWidth: "500px",
          padding: "20px",
          boxSizing: "border-box",
          background: "white",
          borderRadius: "5px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20px"
          }}
        >
          <h4 style={{ color: "#8D0417" }}>
            <Trans>Error</Trans>
          </h4>
          <button className="close-modal" onClick={this.closeModal}>
            <Icon type="fat-close" width={16} height={16} color="#8F8F8F" />
          </button>
        </div>
        <div>
          <p>
            <span>
              <Trans>
                Votre lien a expiré, merci de faire une nouvelle demande via
              </Trans>{" "}
              <span className="iconWithText">
                <img src="favicon.ico" className="iconStyle" />
                <a href="https://mindquest.io/login" style={{ color: "blue" }}>
                  <Trans>Mindquest - Login to my Mindquest space</Trans>
                </a>
              </span>{" "}
              <Trans>et de vérifier vos emails</Trans>
            </span>
          </p>
        </div>
      </div>
    );
  }
}

ErrorPassword.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(ErrorPassword));
