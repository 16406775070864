import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import domaineExpertise from "../../configs/domaineExpertise";
import {
  Row,
  Col,
  AutoComplete,
  TagList,
  Validation,
  Button,
  FormCard,
  FormGroup,
  TextInput,
  Select,
  Tag,
  Spinner,
  InputSkeleton
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import validate from "../../utils/validators";
import languageList from "../../configs/spokenLanguages";
import "./styles.scss";
import { GET_USER_INFO } from "../../components/hoc/queries";
import ReactGA from "react-ga";
import metaTags from "../../utils/editMetaData";

const GET_CATEGORIES = gql`
  {
    categories {
      name
    }
  }
`;
const GET_SKILLS = gql`
  {
    skills {
      name
    }
  }
`;

const GET_SPECIALTIES = gql`
  {
    specialties {
      name
    }
  }
`;

export const UPDATE_MYJOB_CANDIDATE = gql`
  mutation updateMyJobCandidate(
    $_id: ID
    $title: String
    $experience: Int
    $specialties: [String]
    $categories: [String]
    $skills: [String]
    $spokenLanguages: [String]
    $cv: Upload
    $status: String
    $link: String
  ) {
    updateMyJobCandidate(
      input: {
        _id: $_id
        title: $title
        experience: $experience
        specialties: $specialties
        categories: $categories
        skills: $skills
        spokenLanguages: $spokenLanguages
        cv: $cv
        status: $status
        link: $link
      }
    ) {
      account {
        id
        name
        path
        step
      }
      candidate {
        occupation
        companyURL
        categories
        skills
        experience
        spokenLanguages
      }
    }
  }
`;

@inject("sessionStore")
@observer
class MyJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTitleValid: true,
      isCategoriesValid: true,
      isSkillsValid: true,
      isSpecialtiesValid: true,
      isLinkValid: true,
      isSpokenLanguagesValid: true,
      titleValidationMessage: "",
      categoriesValidationMessage: "",
      skillsValidationMessage: "",
      linkValidationMessage: "",
      spokenLanguagesValidationMessage: "",
      specialtiesValidationMessage: ""
    };
    this.onNextStepHandler = this.onNextStepHandler.bind(this);
    this.onIgnoreStepHandler = this.onIgnoreStepHandler.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    ReactGA.initialize("UA-56544271-1");
    ReactGA.pageview("/signup-my-job");
  }
  componentDidMount() {
    ReactGA.initialize("UA-56544271-1");
    ReactGA.pageview("/signup-my-job");
  }
  componentWillMount() {
    ReactGA.initialize("UA-56544271-1");
    ReactGA.pageview("/signup-my-job");
  }
  onIgnoreStepHandler() {
    const { history, sessionStore } = this.props;
    const { changeMyJobPageFilled } = sessionStore;

    changeMyJobPageFilled(false);

    sessionStore.resetFields([
      "title",
      "spokenLanguages",
      "experience",
      "link",
      "specialties"
    ]);
    history.push(Routes.Hb2LayoutRoutes.MyExperience.path);
  }

  onNextStepHandler() {
    const valid = this.isFormValid();
    if (!valid) return;
    const { history, sessionStore } = this.props;
    const { changeMyJobPageFilled } = sessionStore;
    changeMyJobPageFilled(true);
    history.push(Routes.Hb2LayoutRoutes.MyExperience.path);
  }

  titleChangeHandler = e => {
    const { sessionStore } = this.props;
    const res = this.validateTitle(e.target.value);
    if (res) sessionStore.changeTitle(e.target.value);
  };

  experienceChangeHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.changeExperience(item.value);
  };

  statusChangeHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.changeStatus(item.text);
  };

  spokenLanguagesAddHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.addSpokenLanguage(item);
    sessionStore.spokenLanguages.map(language => language.value);
  };

  spokenLanguagesRemoveHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.removeSpokenLanguage(item);
  };

  categoryAddHandler = value => {
    const { i18n, sessionStore } = this.props;
    sessionStore.addCategory(domaineExpertise(i18n, value, "key"));
    sessionStore.categories;
  };

  categoryRemoveHandler = value => {
    const { i18n, sessionStore } = this.props;
    sessionStore.removeCategory(domaineExpertise(i18n, value, "key"));
  };

  skillAddHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addSkill(value);
    sessionStore.skills;
  };

  skillRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeSkill(value);
  };

  specialtyAddHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addSpecialties(value);
    sessionStore.specialties;
  };

  specialtyRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeSpecialties(value);
    this.validateSpecialties(sessionStore.specialties);
  };

  linkChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeLink(e.target.value);
    this.validateLink(e.target.value);
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const {} = sessionStore;
    let valid = true;

    return valid;
  }

  validateTitle(value) {
    const { i18n } = this.props;
    const res = validate(value, [{ maxlength: 90 }, { minlength: 0 }], i18n);
    this.setState({
      isTitleValid: res.isValid,
      titleValidationMessage: res.message
    });
    return res.isValid;
  }

  validateLink(value) {
    const { i18n } = this.props;
    const res = validate(value, ["link", { maxlength: 100 }], i18n);
    this.setState({
      isLinkValid: res.isValid,
      linkValidationMessage: res.message
    });
    return res.isValid;
  }

  validateCategories(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        "required",
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Non renseigné`)
        }
      ],
      i18n
    );
    this.setState({
      isCategoriesValid: res.isValid,
      categoriesValidationMessage: res.message
    });
    return res.isValid;
  }

  validateSpokenLanguages(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        "required",
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Non renseigné`)
        }
      ],
      i18n
    );
    this.setState({
      isSpokenLanguagesValid: res.isValid,
      spokenLanguagesValidationMessage: res.message
    });
    return res.isValid;
  }

  validateSkills(value) {
    // be aware: any modification done here must be copied to skills validation in ./myInformation.jsx
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 2, maxlength: 10 },
          msg: i18n._(t`Choisissez entre deux et dix compétences`)
        }
      ],
      i18n
    );
    this.setState({
      isSkillsValid: res.isValid,
      skillsValidationMessage: res.message
    });
    return res.isValid;
  }

  validateSpecialties(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 0 },
          msg: i18n._(t`Choisissez au moins une spécialité`)
        }
      ],
      i18n
    );
    this.setState({
      isSpecialtiesValid: res.isValid,
      specialtiesValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const { sessionStore, i18n } = this.props;
    const {
      isTitleValid,
      isCategoriesValid,
      isSkillsValid,
      isLinkValid,
      isSpokenLanguagesValid,
      titleValidationMessage,
      categoriesValidationMessage,
      skillsValidationMessage,
      linkValidationMessage,
      spokenLanguagesValidationMessage,
      isSpecialtiesValid,
      specialtiesValidationMessage
    } = this.state;
    const {
      _id,
      changeStep,
      title,
      link,
      categories,
      experience,
      skills,
      specialties,
      authToken,
      spokenLanguages,
      status,
      scrappedLinkedinProfile
    } = sessionStore;
    changeStep(5);
    var url = "";
    if (typeof window !== "undefined") {
      url = window.location.href;
    }
    const categoriesToShow = [];
    categories.map(cat => categoriesToShow.push(domaineExpertise(i18n, cat)));

    return (
      <div className="session ">
        {metaTags(url, null, null, null, null, null, null, null, false)}

        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Mon métier</Trans>
            </h1>
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter with-padding">
          <Row>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="positionName">
                  <Trans>Intitulé</Trans>
                </label>
                <Validation
                  errorMessage={titleValidationMessage}
                  valid={isTitleValid}
                >
                  <TextInput
                    id="positionName"
                    type="text"
                    style={{ "padding-right": "inherit" }}
                    className="form-input"
                    placeholder={i18n._(
                      t`Ex : Architecte Java, Consultant SAP`
                    )}
                    iconColor="#D3354A"
                    value={title}
                    onChange={this.titleChangeHandler}
                  />
                  <div>
                    {scrappedLinkedinProfile.occupation &&
                    (title === "" || title == null) ? (
                      <Tag
                        text={scrappedLinkedinProfile.occupation}
                        variant="tertiary"
                        className="tag-list"
                        style={{ color: "#8d0417" }}
                        closable={false}
                        clickable="true"
                        onClickTag={() => {
                          if (
                            this.validateTitle(
                              scrappedLinkedinProfile.occupation
                            )
                          )
                            sessionStore.changeTitle(
                              scrappedLinkedinProfile.occupation
                            );
                          //this.titleChangeHandler(scrappedLinkedinProfile.occupation);
                        }}
                      />
                    ) : null}
                  </div>
                </Validation>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="experienceYears">
                  <Trans>Expérience</Trans>
                </label>
                <Select
                  style={{ "margin-top": "14px" }}
                  id="experienceYears"
                  options={{
                    groupA: [
                      {
                        text: `${i18n._(t`Junior`)}(0-3)`,
                        value: 1,
                        ...(experience === 1 && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Confirmé`)} (3-7)`,
                        value: 2,
                        ...(experience === 2 && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Senior`)} (7-10)`,
                        value: 3,
                        ...(experience === 3 && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Expert`)} (10+)`,
                        value: 4,
                        ...(experience === 4 && {
                          selected: true
                        })
                      }
                    ]
                  }}
                  onSelect={this.experienceChangeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <Trans>Domaines d’expertise</Trans>
                </label>
                <div>
                  <Query query={GET_CATEGORIES}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;
                      const allCategories = [];
                      data.categories.map(cat =>
                        allCategories.push(domaineExpertise(i18n, cat.name))
                      );
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="expertise"
                            clearOnSelect
                            options={allCategories}
                            iconColor="#8d0417"
                            iconColor2="#d3354a"
                            icon="bars"
                            placeholder={i18n._(t`Sélectionner des domaines`)}
                            onSelect={val => this.categoryAddHandler(val)}
                            valid={isCategoriesValid}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <TagList
                  tags={categoriesToShow}
                  variant="secondary"
                  closable
                  className="tag-list"
                  onClose={val => this.categoryRemoveHandler(val)}
                />
              </FormGroup>
            </Col>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="specialities">
                  <Trans>Spécialités</Trans>
                </label>
                <div>
                  <Query query={GET_SPECIALTIES}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;

                      const { specialties: specialtiesData } = data;
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="specialities"
                            autoComplete="off"
                            clearOnSelect
                            options={specialtiesData.map(item => item.name)}
                            iconColor="#8d0417"
                            iconColor2="#d3354a"
                            icon="bars"
                            placeholder={i18n._(
                              t`Sélectionner des spécialités`
                            )}
                            onSelect={val => this.specialtyAddHandler(val)}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>

                <TagList
                  tags={specialties}
                  variant="secondary"
                  closable
                  className="tag-list"
                  onClose={val => this.specialtyRemoveHandler(val)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="skills">
                  <Trans>Compétences</Trans>
                </label>
                <div>
                  <Query query={GET_SKILLS}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;
                      var allSkills = [];
                      data.skills.map(skill => {
                        allSkills.push(skill.name);
                        allSkills = [...new Set(allSkills)];
                      });
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="skills"
                            clearOnSelect
                            autoComplete="off"
                            options={allSkills}
                            iconColor="#8d0417"
                            iconColor2="#d3354a"
                            icon="bars"
                            placeholder={i18n._(
                              t`Sélectionner des compétences`
                            )}
                            onSelect={val => this.skillAddHandler(val)}
                            valid={isSkillsValid}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={skillsValidationMessage}
                  valid={isSkillsValid}
                >
                  <TagList
                    tags={skills}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.skillRemoveHandler(val)}
                  />
                  <div>
                    {scrappedLinkedinProfile.skills &&
                      scrappedLinkedinProfile.skills.map(scrapedSkill => {
                        if (!skills.includes(scrapedSkill)) {
                          return (
                            <Tag
                              text={scrapedSkill}
                              className="scraping-tag-list"
                              closable="true"
                              clickable="true"
                              onClickTag={() => {
                                this.skillAddHandler(scrapedSkill);
                              }}
                            />
                          );
                        }
                      })}
                  </div>
                </Validation>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="spokenLanguages">
                  <Trans>Langues parlées</Trans>
                </label>
                <div>
                  <Select
                    id="spokenLanguages"
                    options={{
                      groupA: languageList(i18n)
                    }}
                    onSelect={this.spokenLanguagesAddHandler}
                    placeholder={i18n._(t`Sélectionner des langues`)}
                    valid={isSpokenLanguagesValid}
                  />
                </div>
                <div>
                  {scrappedLinkedinProfile.spokenLanguages &&
                    scrappedLinkedinProfile.spokenLanguages.map(scrapedLang => {
                      // if (spokenLanguages.length > 0) {
                      //   spokenLanguages.map(language => {

                      //     if (language.text != scrapedLang) {
                      //       return (
                      //         <Tag
                      //           text={scrapedLang}
                      //           className="scraping-tag-list"
                      //           closable="true"
                      //           clickable="true"
                      //           onClickTag={() => {
                      //             this.spokenLanguagesAddHandler({
                      //               text: scrapedLang,
                      //               value: scrapedLang
                      //             });
                      //           }}
                      //         />
                      //       );
                      //     }
                      //   });
                      // } else {
                      return (
                        <Tag
                          text={scrapedLang}
                          className="scraping-tag-list"
                          closable="true"
                          clickable="true"
                          onClickTag={() => {
                            this.spokenLanguagesAddHandler({
                              text: scrapedLang,
                              value: scrapedLang
                            });
                          }}
                        />
                      );
                      // }
                    })}
                </div>
                {/* <Validation
                  errorMessage={spokenLanguagesValidationMessage}
                  valid={isSpokenLanguagesValid}
                > */}
                <TagList
                  style={{ marginTop: "30px" }}
                  tags={spokenLanguages.map(language => i18n._(language.text))}
                  variant="secondary"
                  closable
                  className="tag-list"
                  onClose={val => this.spokenLanguagesRemoveHandler(val)}
                />
                {/* </Validation> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="addOn">
                  <Trans>Portfolio, CV en ligne, site personnel</Trans>
                </label>
                <Validation
                  errorMessage={linkValidationMessage}
                  valid={isLinkValid}
                >
                  <TextInput
                    id="addOn"
                    type="url"
                    spellCheck="false"
                    className="form-input"
                    placeholder={i18n._(t`https://monsite.fr`)}
                    value={link}
                    onChange={this.linkChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="status">
                  <Trans>Votre statut</Trans>
                </label>
                <Select
                  id="status"
                  style={{ "margin-top": "15px" }}
                  placeholder={status}
                  // placeholder={i18n._(t`Quelle est votre statut`)}
                  value={status}
                  options={{
                    groupA: [
                      { text: "    ", value: 1 },
                      {
                        text: `${i18n._(t`Autoentrepreneur`)}`,
                        value: 2
                      },
                      {
                        text: `${i18n._(t`SASU`)}`,
                        value: 3
                      },
                      {
                        text: `${i18n._(t`EURL`)}`,
                        value: 4
                      },
                      { text: `${i18n._(t`Portage Salarial`)}`, value: 5 },
                      { text: `${i18n._(t`Autre`)}`, value: 6 }
                    ]
                  }}
                  onSelect={this.statusChangeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
        </FormCard>
        <br />

        <div className="next-buttons">
          <Mutation
            mutation={UPDATE_MYJOB_CANDIDATE}
            variables={{
              _id,
              title,
              experience,
              specialties,
              categories,
              skills,
              spokenLanguages: spokenLanguages.map(item => item.value),
              link,
              status
            }}
            refetchQueries={[
              {
                query: GET_USER_INFO,
                variables: { token: authToken }
              }
            ]}
            onCompleted={data => {
              // history.push(ConfirmCode.path);
              this.onNextStepHandler(data);
            }}
          >
            {(mutation, { loading }) => (
              <span>
                {loading ? (
                  <Button disabled>
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  </Button>
                ) : (
                  <Button icon="chevron-right" onClick={() => mutation()}>
                    <Trans>Étape suivante</Trans>
                  </Button>
                )}
              </span>
            )}
          </Mutation>
          <Button variant="secondary" onClick={this.onIgnoreStepHandler}>
            <Trans>Passer cette étape</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

MyJob.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeTitle: PropTypes.func,
    changeLink: PropTypes.func,
    changeMyJobPageFilled: PropTypes.func,
    // isCandidateWorkHistoryValid: PropTypes.bool,
    // changeCandidateWorkHistory: PropTypes.func,
    changeStep: PropTypes.func,
    changeExperience: PropTypes.func,
    addSpokenLanguage: PropTypes.func,
    removeSpokenLanguage: PropTypes.func,
    statusChangeHandler: PropTypes.func,
    addCategory: PropTypes.func,
    removeCategory: PropTypes.func,
    addSkill: PropTypes.func,
    removeSkill: PropTypes.func,
    addSpecialties: PropTypes.func,
    removeSpecialties: PropTypes.func,
    // candidateWorkHistory: PropTypes.arrayOf(PropTypes.number).isRequired,
    spokenLanguages: PropTypes.shape({}),
    categories: PropTypes.shape({}),
    skills: PropTypes.shape({}),
    title: PropTypes.string,
    status: PropTypes.string,
    link: PropTypes.string,
    specialties: PropTypes.shape({}),
    resetFields: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MyJob));
