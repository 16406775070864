import gql from "graphql-tag";

const latestPostsQuery = gql`
  query latestPosts(
    $page: Int
    $perPage: Int
    $categoryIds: String
    $excludeCategories: String
    $tagIds: String
    $search: String
    $excludeBlog: String
    $include: String
    $language: String
    $orderBy: String
  ) {
    posts(
      filter: {
        page: $page
        per_page: $perPage
        orderby: $orderBy
        categories: $categoryIds
        categories_exclude: $excludeCategories
        tags: $tagIds
        search: $search
        exclude: $excludeBlog
        include: $include
        language: $language
      }
    ) {
      id
      title
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      date
      featured_media {
        id
        source_url
        thumbnail
      }
      featured_image2_url
      featured_media_id
      modified
      total
      totalPages
      related_posts
      meta_keys
      meta_description
      download_link
    }
  }
`;

const SinglePostQuery = gql`
  query SinglePost($id: ID!, $language: String) {
    post(id: $id, language: $language) {
      id
      title
      author {
        name
        description
        avatar_url
      }
      content
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      date
      modified
      featured_media {
        id
        source_url
      }
      featured_image2_url
      featured_media_id
      download_link
      related_posts
      meta_keys
      meta_description
      meta_question
      meta_response
    }
  }
`;
// we will change here les id :)
const categoriesRefFr = {
  featured: "39",
  videos: "35",
  resources: "37",
  successStories: "36",
  advicesConsultant: "15",
  advicesEnterprise: "13",
  decryption: "4",
  news: "21",

  //candidate-advices
  CandidateAdvices: "537",
  // new blog decisionMakers
  DecisionMakers: "487",
  TalentStrategy: "491",
  RecruitITConsultant: "492",
  Risks: "493",
  Legal: "494",
  Dsi: "495",
  // new blog freelanceTech
  freelanceTech: "488",
  BecomeFreelancer: "496",
  DailyFreelance: "497",
  Finance: "498",
  Career: "499",
  AdminAndLegal: "500",
  Accounting: "501",
  Taxation: "502",
  WagePortage: "503",
  OurPartners: "504",
  // new blog buisinessFile
  buisinessFile: "157",
  Developpement: "505",
  Infra: "506",
  Erp: "507",
  ProjectManagement: "508",
  BusinessIntelligence: "509",
  Digital: "510",
  //new blog aboutus
  AboutUs: "489",
  actuality: "511",
  pressRelease: "512",
  temoignagesAboutUS: "513",
  //new blog webinars
  Webinars: "490",
  WebconsultantIT: "514",
  FinancePro: "538",
  Replay: "516",
  webmakersIT: "515",
  PressReview: "517",
  PressReviewDsi: "518",
  PressReviewDevelppement: "519",
  PressReviewBusinessIntelligence: "520",
  PressReviewCybersecurity: "521",
  PressReviewRecrutement: "522",
  PressReviewErp: "523",
  PressReviewCloud: "524"
};

const categoriesRefEn = {
  featured: "9",
  videos: "10",
  resources: "11",
  "tips-and-tricks": "2",
  "its-who-is-who": "3",
  "business-lounge": "4",
  Newsstand: "5",
  "captains-log": "6",
  "interesting-reads": "7",
  "horizon-2050": "8",
  "behind-screen": "679",

  // new blog decisionMakers
  DecisionMakers: "751",
  TalentStrategy: "756",
  RecruitITConsultant: "757",
  Risks: "758",
  Dsi: "759",
  // new blog freelanceTech
  freelanceTech: "752",
  jobSearchAdviceEn: "760",
  jobAppAdviceEn: "761",
  tipsDailyEn: "762",
  Career: "763",
  UmbrellaCompany: "764",
  OurPartners: "765",
  // new blog job Description
  BuisinessFile: "753",
  Developpement: "766",
  Infra: "767",
  Erp: "768",
  ProjectManagement: "769",
  BusinessIntelligence: "770",
  Digital: "771",
  //new blog aboutus
  AboutUs: "754",
  captainLogAboutUS: "772",
  podcastAboutUS: "773",
  Testimonials: "774",
  //new blog tech magazine
  techmagazineEn: "755",
  pressReviewEn: "775",
  cybersecurityEn: "776",
  topTenEn: "777"
};

//const categoriesRefFr = categoriesRefEn;

export { latestPostsQuery, categoriesRefFr, categoriesRefEn, SinglePostQuery };
