import { t } from "@lingui/macro";

const BlogCategories = (i18n = null) => {
  if (i18n) {
    return {
      DecisionMakers: {
        name: "DecisionMakers",
        title: i18n._(t`Décideur IT`),
        msg: i18n._(
          t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT.`
        ),
        url: "/blog/it-decision-makers",
        id: { fr: 487, en: 751 },
        lang: "fr-en",
        subCategories: [
          {
            name: "TalentStrategy",
            title: i18n._(t`La stratégie des talents`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur la stratégie des talents sur notre blog Mindquest Connect.`
            ),
            url: "/blog/it-decision-makers/talent-strategy",
            lang: "fr-en",
            id: { fr: 491, en: 756 }
          },
          {
            name: "RecruitITConsultant",
            title: i18n._(t`Recruter un consultant informatique`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur le recrutement de consultants et talents informatiques sur notre blog Mindquest Connect.`
            ),
            url: "/blog/it-decision-makers/hiring-an-it-consultant",
            lang: "fr-en",
            id: { fr: 492, en: 757 }
          },
          {
            name: "Risks",
            title: i18n._(t`Les risques & erreurs à éviter`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur les risques et les erreurs à éviter sur notre blog Mindquest Connect.`
            ),
            url: "/blog/it-decision-makers/tips-and-errors-to-avoid",
            lang: "fr-en",
            id: { fr: 493, en: 758 }
          },
          {
            name: "Legal",
            title: i18n._(t`Légal`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur le légal sur notre blog Mindquest Connect.`
            ),
            url: "/blog/it-decision-makers/legal",
            lang: "fr",
            id: { fr: 494 }
          },
          {
            name: "Dsi",
            title: i18n._(t`Enjeux DSI`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour décideurs IT sur les enjeux DSI sur notre blog Mindquest Connect.`
            ),
            url: "/blog/it-decision-makers/dsi-challenges",
            lang: "fr-en",
            id: { fr: 495, en: 759 }
          }
        ]
      },
      freelanceTech: {
        name: "freelanceTech",
        title: i18n._(t`Consultant IT`),
        msg: i18n._(
          t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et talents en freelance dans l'informatique.`
        ),
        url: "/blog/freelance-tech",
        id: { fr: 488, en: 752 },
        lang: "fr-en",
        subCategories: [
          {
            name: "BecomeFreelancer",
            title: i18n._(t`Devenir Freelance`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos séléctions d'articles pour Freelances informatiques`
            ),
            url: "/blog/freelance-tech/become-freelancer",
            lang: "fr",
            id: { fr: 496 }
          },
          {
            name: "DailyFreelance",
            title: i18n._(t`Quotidien de freelance`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants IT sur comment devenir freelance sur notre blog Mindquest Connect.`
            ),
            url: "/blog/freelance-tech/daily-freelance",
            lang: "fr",
            id: { fr: 497 }
          },
          {
            name: "Finance",
            title: i18n._(t`Se financer`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur comment se financer sur notre blog Mindquest Connect.`
            ),
            url: "/blog/freelance-tech/finance",
            lang: "fr",
            id: { fr: 498 }
          },
          {
            name: "Career",
            title: i18n._(t`Développer sa carrière: CDI & freelance`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants IT sur le développement de sa carrière en CDI ou freelance sur notre blog Mindquest Connect.`
            ),
            url:
              "/blog/freelance-tech/growing-your-career-permanent-and-freelance",
            lang: "fr-en",
            id: { fr: 499, en: 763 }
          },
          {
            name: "AdminAndLegal",
            title: i18n._(t`Admin & Légal`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur l'admin & le légal sur notre blog Mindquest Connect.`
            ),
            url: "/blog/freelance-tech/admin-and-legal",
            lang: "fr",
            id: { fr: 500 }
          },
          {
            name: "Accounting",
            title: i18n._(t`Comptabilité`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants IT sur la comptabilité en freelance sur notre blog Mindquest Connect.`
            ),
            url: "/blog/freelance-tech/accounting",
            lang: "fr",
            id: { fr: 501 }
          },
          {
            name: "Taxation",
            title: i18n._(t`Fiscalité, impôts & retraite`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur la fiscalité, les impôts à la retraite sur notre blog Mindquest Connect.`
            ),
            url: "/blog/freelance-tech/taxtation-and-retirement",
            lang: "fr",
            id: { fr: 502 }
          },
          {
            name: "WagePortage",
            title: i18n._(t`Portage salarial`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur le portage salarial sur notre blog Mindquest Connect.`
            ),
            url: "/blog/freelance-tech/wage-portage",
            lang: "fr",
            id: { fr: 503 }
          },
          {
            name: "OurPartners",
            title: i18n._(t`Nos partenaires`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et freelances IT sur nos partenaires sur notre blog Mindquest Connect.`
            ),
            url: "/blog/freelance-tech/our-partners",
            lang: "fr",
            id: { fr: 504 }
          },
          {
            name: "jobSearchAdviceEn",
            title: i18n._(t`Job Search advice`),
            msg: i18n._(
              t`Find all the news from Mindquest and our advice on IT recruitment and Job Hunting.`
            ),
            url: "/blog/freelance-tech/job-search-advice",
            lang: "en",
            id: { en: 760 }
          },
          {
            name: "jobAppAdviceEn",
            title: i18n._(t`Job application advice`),
            msg: i18n._(
              t`Find all the latest news from Mindquest and our selection of articles about job application advices for IT Consultants.`
            ),
            url: "/blog/freelance-tech/job-application-advice",
            lang: "en",
            id: { en: 761 }
          },
          {
            name: "tipsDailyEn",
            title: i18n._(t`Tips for your daily life as a freelancer`),
            msg: i18n._(
              t`Find all the latest news from Mindquest and our selection of articles for IT Consultants about growing your career as permanent & freelance.`
            ),
            url:
              "/blog/freelance-tech/tips-for-your-daily-life-as-a-freelancer",
            lang: "en",
            id: { en: 762 }
          }
        ]
      },
      CandidateAdvices: {
        name: "CandidateAdvices",
        title: i18n._(t`Conseils Candidat`),
        msg: i18n._(
          t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour consultants et talents en freelance dans l'informatique.`
        ),
        url: "/blog/candidate-advices",
        id: { fr: 537 },
        lang: "fr",
        subCategories: []
      },
      FinancePro: {
        name: "FinancePro",
        title: i18n._(t`Professionnels de la Finance`),
        msg: i18n._(
          t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour talents et experts de la finance à la recherche d'emploi dans la finance.`
        ),
        url: "/blog/finance-professionals",
        id: { fr: 538 },
        lang: "fr",
        subCategories: []
      },
      BuisinessFile: {
        name: "BuisinessFile",
        title: i18n._(t`Fichiers Métiers`),
        msg: i18n._(
          t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers tech & IT.`
        ),
        url: "/blog/job-description",
        id: { fr: 157, en: 753 },
        lang: "fr-en",
        subCategories: [
          {
            name: "Developpement",
            title: i18n._(t`Développement`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers du Développement.`
            ),
            url: "/blog/job-description/web-developper",
            lang: "fr-en",
            id: { fr: 505, en: 766 }
          },
          {
            name: "Infra",
            title: i18n._(t`Infrastructure IT`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers d'Infrastructure IT.`
            ),
            url: "/blog/job-description/it-infrastructure",
            lang: "fr-en",
            id: { fr: 506, en: 767 }
          },
          {
            name: "Erp",
            title: i18n._(t`ERP & SAP`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers d'ERP & SAP.`
            ),
            url: "/blog/job-description/erp-and-sap",
            lang: "fr-en",
            id: { fr: 507, en: 768 }
          },
          {
            name: "ProjectManagement",
            title: i18n._(t`Project Management`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers de Project Management.`
            ),
            url: "/blog/job-description/project-manager",
            lang: "fr-en",
            id: { fr: 508, en: 769 }
          },
          {
            name: "BusinessIntelligence",
            title: i18n._(t`Data & business Intelligence`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers de Data & Business Intelligence.`
            ),
            url: "/blog/job-description/data-and-business-intelligence",
            lang: "fr-en",
            id: { fr: 509, en: 770 }
          },
          {
            name: "Digital",
            title: i18n._(t`Digital`),
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos fiches métiers sur les compétences, missions et particularités des métiers du Digital.`
            ),
            url: "/blog/job-description/digital",
            lang: "fr-en",
            id: { fr: 510, en: 771 }
          }
        ]
      },
      AboutUs: {
        name: "AboutUs",
        msg: i18n._(
          t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaître nos actualités, nos communiqués de presse et nos témoignages.`
        ),
        title: i18n._(t`On parle de nous`),
        url: "/blog/about-us",
        lang: "fr-en",
        id: { fr: 489, en: 754 },
        subCategories: [
          {
            name: "actuality",
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaitre les dernières actualités de notre marque et nos derniers services...`
            ),
            title: i18n._(t`Nos actualités`),
            url: "/blog/about-us/actuality",
            id: { fr: 511 },
            lang: "fr"
          },
          {
            name: "pressRelease",
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaitre les dernières actualités de notre marque et nos derniers communiqués de presse.`
            ),
            title: i18n._(t`Communiqués de presse`),
            url: "/blog/about-us/press-release",
            id: { fr: 512 },
            lang: "fr"
          },
          {
            name: "temoignagesAboutUS",
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et notre sélection d'articles pour connaitre les dernières actualités de notre marque et les derniers témoignagnes de nos collaborateurs.`
            ),
            title: i18n._(t`Témoignages`),
            url: "/blog/about-us/testimonials",
            id: { fr: 513 },
            lang: "fr"
          },
          {
            name: "podcastAboutUS",
            msg: i18n._(
              t`Find all the latest Mindquest news and our interviews on top IT experts with our Podcast Mission Control Center.`
            ),
            title: i18n._(t`Podcast Interviews`),
            url: "/blog/about-us/podcast",
            id: { en: 773 },
            lang: "en"
          },
          {
            name: "captainLogAboutUS",
            msg: i18n._(
              t`Find all the news from Mindquest about on our Captains's Log`
            ),
            title: i18n._(t`Captains’s Log`),
            url: "/blog/about-us/captains-log",
            id: { en: 772 },
            lang: "en"
          }
        ]
      },
      Webinars: {
        name: "Webinars",
        title: i18n._(t`Webinars & Ressources`),
        msg: i18n._(
          t`Retrouvez toute l'actualité de Mindquest et nos séléctions d'articles pour Webinars & Ressources.`
        ),
        url: "/blog/it-resources",
        id: { fr: 490 },
        lang: "fr",
        categoryPath: "resources",
        subCategories: [
          {
            name: "WebconsultantIT",
            msg: i18n._(
              t`Retrouvez toutes nos ressources et contenus pour talents et consultants IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).`
            ),
            title: i18n._(t`Contenu pour consultants IT`),
            url: "/blog/it-resources/content-for-it-consultants",
            lang: "fr",
            id: { fr: 514 }
          },
          {
            name: "webmakersIT",
            msg: i18n._(
              t`Retrouvez toutes nos ressources et contenus pour décideurs et recruteurs IT (replays de nos webinars, fiches pratiques, guides pratiques, livres blancs...).`
            ),
            title: i18n._(t`Contenu pour décideurs IT`),
            url: "/blog/it-resources/content-for-it-makers",
            lang: "fr",
            id: { fr: 515 }
          },
          {
            name: "Replay",
            msg: i18n._(
              t`Retrouvez toutes les replays de nos webinars pour décideurs et consultants IT présentés par Mindquest et ses partenaires comme l'Expert-Comptable.`
            ),
            title: i18n._(t`Webinars & replay`),
            url: "/blog/it-resources/webinars-and-replay",
            lang: "fr",
            id: { fr: 516 }
          },
          {
            name: "PressReview",
            msg: i18n._(
              t`Retrouvez toute l'actualité de Mindquest et nos séléctions d'articles pour Webinars & Ressources`
            ),
            title: i18n._(t`Revues de presse`),
            url: "/blog/it-resources/press-review",
            lang: "fr",
            categoryPath: "press-review",
            id: { fr: 517 },
            subCategories: [
              {
                name: "PressReviewDsi",
                msg: i18n._(
                  t` Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
                ),
                title: i18n._(t`DSI`),
                url: "/blog/it-resources/press-review/dsi",
                lang: "fr",
                id: { fr: 518 }
              },
              {
                name: "PressReviewDevelppement",
                msg: i18n._(
                  t` Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
                ),
                title: i18n._(t`Développement`),
                url: "/blog/it-resources/press-review/development",
                lang: "fr",
                id: { fr: 519 }
              },
              {
                name: "PressReviewBusinessIntelligence",
                msg: i18n._(
                  t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
                ),
                title: i18n._(t`Intelligence Artificielle`),
                url: "/blog/it-resources/press-review/business-intelligence",
                lang: "fr",
                id: { fr: 520 }
              },
              {
                name: "PressReviewCybersecurity",
                msg: i18n._(
                  t`Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
                ),
                title: i18n._(t`Cybersécurité`),
                url: "/blog/it-resources/press-review/cybersecurity",
                lang: "fr",
                id: { fr: 521 }
              },
              {
                name: "PressReviewRecrutement",
                msg: i18n._(
                  t` Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
                ),
                title: i18n._(t`Recrutement & IT`),
                url: "/blog/it-resources/press-review/recruitment-and-it",
                lang: "fr",
                id: { fr: 522 }
              },
              {
                name: "PressReviewErp",
                title: i18n._(t`Data & ERP`),
                msg: i18n._(
                  t` Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
                ),
                url: "/blog/it-resources/press-review/data-and-erp",
                lang: "fr",
                id: { fr: 523 }
              },
              {
                name: "PressReviewCloud",
                title: i18n._(t`Cloud`),
                msg: i18n._(
                  t` Retrouvez toute l'actualité de Mindquest et nos revues de presse sur la tech et l'informatique (DSI, Développement, Intelligence Artificielle, Cybersécurité, Recrutement & IT, Data & ERP, Cloud)...`
                ),
                url: "/blog/it-resources/press-review/cloud",
                lang: "fr",
                id: { fr: 524 }
              }
            ]
          }
        ]
      },
      techmagazineEn: {
        name: "techmagazineEn",
        title: i18n._(t`Tech magazine`),
        msg: i18n._(
          t`Find all the latest news from Mindquest and our selection of articles on our Tech Magazine.`
        ),
        url: "/blog/tech-magazine",
        id: { en: 755 },
        lang: "en",
        subCategories: [
          {
            name: "pressReviewEn",
            title: i18n._(t`Press review`),
            msg: i18n._(
              t`Find all the latest news from Mindquest and our selection of press reviews on our Tech Magazine`
            ),
            url: "/blog/tech-magazine/pressreview",
            lang: "en",
            id: { en: 775 }
          },
          {
            name: "cybersecurityEn",
            title: i18n._(t`Cyber security`),
            msg: i18n._(
              t`Find all the latest news from Mindquest and our selection of articles about cybersecurity on our Tech Magazine`
            ),
            url: "/blog/tech-magazine/cybersecurity",
            lang: "en",
            id: { en: 776 }
          },
          {
            name: "topTenEn",
            msg: i18n._(
              t`Find all the latest Mindquest news and our selection of Tech Magazine articles on the top 10 IT experts lists.`
            ),
            title: i18n._(t`TOP 10 experts' articles`),
            url: "/blog/tech-magazine/top-10-experts-articles",
            lang: "en",
            id: { en: 777 }
          }
        ]
      }
    };
  } else {
    return {
      DecisionMakers: {
        name: "DecisionMakers",
        title: "Décideur IT",
        url: "/blog/it-decision-makers",
        id: { fr: 487, en: 751 },
        lang: "fr-en",
        subCategories: [
          {
            name: "TalentStrategy",
            title: "Stratégie des talents",
            url: "/blog/it-decision-makers/talent-strategy",
            lang: "fr-en",
            id: { fr: 491, en: 756 }
          },
          {
            name: "RecruitITConsultant",
            title: "Recruter un consultant informatique",
            url: "/blog/it-decision-makers/hiring-an-it-consultant",
            lang: "fr-en",
            id: { fr: 492, en: 757 }
          },
          {
            name: "Risks",
            title: "Risques & erreurs à éviter",
            url: "/blog/it-decision-makers/tips-and-errors-to-avoid",
            lang: "fr-en",
            id: { fr: 493, en: 758 }
          },
          {
            name: "Legal",
            title: "Légal",
            url: "/blog/it-decision-makers/legal",
            lang: "fr",
            id: { fr: 494 }
          },
          {
            name: "Dsi",
            title: "Enjeux DSI",
            url: "/blog/it-decision-makers/dsi-challenges",
            lang: "fr-en",
            id: { fr: 495, en: 759 }
          }
        ]
      },
      freelanceTech: {
        name: "freelanceTech",
        title: "Consultant IT",
        url: "/blog/freelance-tech",
        id: { fr: 488, en: 752 },
        lang: "fr-en",
        subCategories: [
          {
            name: "BecomeFreelancer",
            title: "Devenir Freelance",
            url: "/blog/freelance-tech/become-freelancer",
            lang: "fr",
            id: { fr: 496 }
          },
          {
            name: "DailyFreelance",
            title: "Quotidien de freelance",
            url: "/blog/freelance-tech/daily-freelance",
            lang: "fr",
            id: { fr: 497 }
          },
          {
            name: "Finance",
            title: "Se financer",
            url: "/blog/freelance-tech/finance",
            lang: "fr",
            id: { fr: 498 }
          },
          {
            name: "Career",
            title: "Développer sa carrière: CDI & freelance",
            url:
              "/blog/freelance-tech/growing-your-career-permanent-and-freelance",
            lang: "fr-en",
            id: { fr: 499, en: 763 }
          },
          {
            name: "AdminAndLegal",
            title: "Admin & légal",
            url: "/blog/freelance-tech/admin-and-legal",
            lang: "fr",
            id: { fr: 500 }
          },
          {
            name: "Accounting",
            title: "Comptabilité",
            url: "/blog/freelance-tech/accounting",
            lang: "fr",
            id: { fr: 501 }
          },
          {
            name: "Taxation",
            title: "Fiscalité, impôts & retraite",
            url: "/blog/freelance-tech/taxtation-and-retirement",
            lang: "fr",
            id: { fr: 502 }
          },
          {
            name: "WagePortage",
            title: "Portage salarial",
            url: "/blog/freelance-tech/wage-portage",
            lang: "fr",
            id: { fr: 503 }
          },
          {
            name: "OurPartners",
            title: "Nos partenaires",
            url: "/blog/freelance-tech/our-partners",
            lang: "fr",
            id: { fr: 504 }
          },
          {
            name: "jobSearchAdviceEn",
            title: "Job Search advice",
            url: "/blog/freelance-tech/job-search-advice",
            lang: "en",
            id: { en: 760 }
          },
          {
            name: "jobAppAdviceEn",
            title: "Job application advice",
            url: "/blog/freelance-tech/job-application-advice",
            lang: "en",
            id: { en: 761 }
          },
          {
            name: "tipsDailyEn",
            title: "Tips for your daily life as a freelancer",
            url:
              "/blog/freelance-tech/tips-for-your-daily-life-as-a-freelancer",
            lang: "en",
            id: { en: 762 }
          }
        ]
      },
      CandidateAdvices: {
        name: "CandidateAdvices",
        title: "Conseils Candidat",
        url: "/blog/candidate-advices",
        id: { fr: 537 },
        lang: "fr",
        subCategories: []
      },
      FinancePro: {
        name: "FinancePro",
        title: "Professionnels de la Finance",
        url: "/blog/finance-professionals",
        id: { fr: 538 },
        lang: "fr",
        subCategories: []
      },
      BuisinessFile: {
        name: "BuisinessFile",
        title: "Fiches Métiers",
        url: "/blog/job-description",
        id: { fr: 157, en: 753 },
        lang: "fr-en",
        subCategories: [
          {
            name: "Developpement",
            title: "Fichiers Métiers: Développement",
            url: "/blog/job-description/web-developper",
            lang: "fr-en",
            id: { fr: 505, en: 766 }
          },
          {
            name: "Infra",
            title: "Infra",
            url: "/blog/job-description/it-infrastructure",
            lang: "fr-en",
            id: { fr: 506, en: 767 }
          },
          {
            name: "Erp",
            title: "ERP & SAP",
            url: "/blog/job-description/erp-and-sap",
            lang: "fr-en",
            id: { fr: 507, en: 768 }
          },
          {
            name: "ProjectManagement",
            title: "Project Management",
            url: "/blog/job-description/project-manager",
            lang: "fr-en",
            id: { fr: 508, en: 769 }
          },
          {
            name: "BusinessIntelligence",
            title: "Data & business Intelligence",
            url: "/blog/job-description/data-and-business-intelligence",
            lang: "fr-en",
            id: { fr: 509, en: 770 }
          },
          {
            name: "Digital",
            title: "Digital",
            url: "/blog/job-description/digital",
            lang: "fr-en",
            id: { fr: 510, en: 771 }
          }
        ]
      },
      AboutUs: {
        name: "AboutUs",
        title: "On parle de nous",
        url: "/blog/about-us",
        lang: "fr-en",
        id: { fr: 489, en: 754 },
        subCategories: [
          {
            name: "actuality",
            title: "Nos actualités",
            url: "/blog/about-us/actuality",
            id: { fr: 511 },
            lang: "fr"
          },
          {
            name: "pressRelease",
            title: "Communiqués de presse",
            url: "/blog/about-us/press-release",
            id: { fr: 512 },
            lang: "fr"
          },
          {
            name: "temoignagesAboutUS",
            title: "Témoignages",
            url: "/blog/about-us/testimonials",
            id: { fr: 513 },
            lang: "fr"
          },
          {
            name: "podcastAboutUS",
            title: "Podcast",
            url: "/blog/about-us/podcast",
            id: { en: 773 },
            lang: "en"
          },
          {
            name: "captainLogAboutUS",
            title: "Captains’s Log",
            url: "/blog/about-us/captains-log",
            id: { en: 772 },
            lang: "en"
          }
        ]
      },
      Webinars: {
        name: "Webinars",
        title: "Webinars & Ressources",
        url: "/blog/it-resources",
        id: { fr: 490 },
        lang: "fr",
        categoryPath: "resources",
        subCategories: [
          {
            name: "WebconsultantIT",
            title: "Contenu pour consultants IT",
            url: "/blog/it-resources/content-for-it-consultants",
            lang: "fr",
            id: { fr: 514 }
          },
          {
            name: "webmakersIT",
            title: "Contenu pour décideurs IT",
            url: "/blog/it-resources/content-for-it-makers",
            lang: "fr",
            id: { fr: 515 }
          },
          {
            name: "Replay",
            title: "Webinars & replay",
            url: "/blog/it-resources/webinars-and-replay",
            lang: "fr",
            id: { fr: 516 }
          },
          {
            name: "PressReview",
            title: "Revues de presse",
            url: "/blog/it-resources/press-review",
            lang: "fr",
            categoryPath: "press-review",
            id: { fr: 517 },
            subCategories: [
              {
                name: "PressReviewDsi",
                title: "DSI",
                url: "/blog/it-resources/press-review/dsi",
                lang: "fr",
                id: { fr: 518 }
              },
              {
                name: "PressReviewDevelppement",
                title: "Développement",
                url: "/blog/it-resources/press-review/development",
                lang: "fr",
                id: { fr: 519 }
              },
              {
                name: "PressReviewBusinessIntelligence",
                title: "Intelligence artificielle",
                url: "/blog/it-resources/press-review/business-intelligence",
                lang: "fr",
                id: { fr: 520 }
              },
              {
                name: "PressReviewCybersecurity",
                title: "Cybersécurité",
                url: "/blog/it-resources/press-review/cybersecurity",
                lang: "fr",
                id: { fr: 521 }
              },
              {
                name: "PressReviewRecrutement",
                title: "Recrutement & IT",
                url: "/blog/it-resources/press-review/recruitment-and-it",
                lang: "fr",
                id: { fr: 522 }
              },
              {
                name: "PressReviewErp",
                title: "Data & ERP",
                url: "/blog/it-resources/press-review/data-and-erp",
                lang: "fr",
                id: { fr: 523 }
              },
              {
                name: "PressReviewCloud",
                title: "Cloud",
                url: "/blog/it-resources/press-review/cloud",
                lang: "fr",
                id: { fr: 524 }
              }
            ]
          }
        ]
      },
      techmagazineEn: {
        name: "techmagazineEn",
        title: "Tech magazine",
        url: "/blog/tech-magazine",
        id: { en: 755 },
        lang: "en",
        subCategories: [
          {
            name: "pressReviewEn",
            title: "Press review",
            url: "/blog/tech-magazine/pressreview",
            lang: "en",
            id: { en: 775 }
          },
          {
            name: "cybersecurityEn",
            title: "Cyber security",
            url: "/blog/tech-magazine/cybersecurity",
            lang: "en",
            id: { en: 776 }
          },
          {
            name: "topTenEn",
            title: "Top Ten",
            url: "/blog/tech-magazine/top-10-experts-articles",
            lang: "en",
            id: { en: 777 }
          }
        ]
      }
    };
  }
};

export default BlogCategories;
