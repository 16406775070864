import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import uniqid from "uniqid";
import {
  Row,
  Col,
  TextInput,
  Radio,
  RadioGroup,
  FormGroup,
  Button,
  Validation,
  DatePicker,
  toaster,
  Spinner,
  CheckboxGroup,
  Checkbox
} from "cf-neo-ui";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import validate from "../../../utils/validators";
import Location from "../../../components/location/location";
import "./styles.scss";
import { GET_USER_INFO } from "../../../components/hoc/queries";

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation(
    $_id: ID!
    $availability: Float
    $remote: String
    $searchAddress: AddressInput
    $dayRate: Float
    $salary: Float
    $mobility: Float
    $employmentPreference: String
  ) {
    updateCandidate(
      input: {
        _id: $_id
        availability: $availability
        remote: $remote
        searchAddress: $searchAddress
        dayRate: $dayRate
        salary: $salary
        mobility: $mobility
        employmentPreference: $employmentPreference
      }
    ) {
      account {
        id
        name
      }
      candidate {
        dayRateLow
        dayRate
        salary
        salaryLow
        employmentPreference
        dateAvailable
        customText21
        customInt10
        searchAddress {
          address1
          city
          state
          countryID
          lat
          lng
        }
      }
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class MySearch extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = props;
    const { employmentPreference } = sessionStore;
    let employmentPreferenceArray = [];
    if (employmentPreference)
      employmentPreferenceArray = employmentPreference.split(",");

    let isPermanent = false;
    let isContract = false;
    let isPartTime = false;
    employmentPreferenceArray.map(item => {
      if (item === "Permanent") isPermanent = true;
      if (item === "Contract") isContract = true;
      if (item === "Part-time") isPartTime = true;
      return null;
    });
    this.state = {
      isPermanent,
      isContract,
      isPartTime,
      isAvailabilityValid: true,
      isSearchAddressValid: true,
      isDayRateValid: true,
      isSalaryValid: true,
      isMobilityValid: true,
      isEmploymentPreferenceValid: true,
      availabilityValidationMessage: "",
      searchAddressValidationMessage: "",
      dayRateValidationMessage: "",
      mobilityValidationMessage: "",
      salaryValidationMessage: "",
      employmentPreferenceValidationMessage: "",
      saveCase: false
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  employmentPreferenceChangeHandler = v => {
    if (Array.isArray(v)) {
      const { sessionStore } = this.props;
      sessionStore.changeEmploymentPreference(v.toString());
      sessionStore.changeGeneralSaveCase(6);

      this.validateEmploymentPreference(v);
      this.setState({ isPermanent: false });
      this.setState({ isContract: false });
      this.setState({ isPartTime: false });
      v.map(item => {
        if (item === "Permanent") this.setState({ isPermanent: true });
        if (item === "Contract") this.setState({ isContract: true });
        if (item === "Part-time") this.setState({ isPartTime: true });
        return null;
      });
    }
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  availabilityChangeHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.changeAvailability(value);
    this.validateAvailability(value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(6);
  };

  remoteChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeRemote(v);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(6);
  };

  getLocationHandler = (address, formattedAddress) => {
    const { sessionStore } = this.props;
    sessionStore.changeSearchAddress({
      address1: address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    });
    this.validateSearchAddress(address);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(6);
  };

  searchAddressChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeSearchAddress({ address1: v });
    this.validateSearchAddress(v);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(6);
  };

  salaryChangeHandler = event => {
    const { sessionStore } = this.props;
    sessionStore.changeSalary(event.target.value);
    this.validateSalary(event.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(6);
  };

  dayRateChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeDayRate(e.target.value);
    this.validateDayRate(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(6);
  };

  mobilityChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeMobility(e.target.value);
    this.validateMobility(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(6);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const { isPermanent, isContract, isPartTime } = this.state;
    const {
      availability,
      searchAddress,
      dayRate,
      salary,
      mobility,
      employmentPreference
    } = sessionStore;
    let valid = true;
    if (!this.validateAvailability(availability)) valid = false;
    if (!this.validateEmploymentPreference(employmentPreference)) valid = false;
    if (!this.validateSearchAddress(searchAddress.address1)) valid = false;
    if (isPermanent || isPartTime)
      if (!this.validateSalary(salary)) valid = false;
    if (isContract) if (!this.validateDayRate(dayRate)) valid = false;
    if (!this.validateMobility(mobility)) valid = false;
    return valid;
  }

  validateAvailability(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isAvailabilityValid: res.isValid,
      availabilityValidationMessage: res.message
    });
    return res.isValid;
  }

  validateEmploymentPreference(value) {
    const { i18n } = this.props;
    if (value && value.length === 0) {
      this.setState({
        isEmploymentPreferenceValid: false,
        employmentPreferenceValidationMessage: i18n._(t`Non renseigné`)
      });
      return false;
    }
    this.setState({
      isEmploymentPreferenceValid: true,
      employmentPreferenceValidationMessage: ""
    });
    return true;
  }

  validateSearchAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    let mesg = "";
    if (!res.isValid) mesg = i18n._(t`Non renseigné`);
    this.setState({
      isSearchAddressValid: res.isValid,
      searchAddressValidationMessage: mesg
    });
    return res.isValid;
  }

  validateDayRate(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 12 }],
      i18n
    );
    this.setState({
      isDayRateValid: res.isValid,
      dayRateValidationMessage: res.message
    });
    return res.isValid;
  }

  validateSalary(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 12 }],
      i18n
    );
    this.setState({
      isSalaryValid: res.isValid,
      salaryValidationMessage: res.message
    });
    return res.isValid;
  }

  validateMobility(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "number", { maxlength: 5 }], i18n);
    this.setState({
      isMobilityValid: res.isValid,
      mobilityValidationMessage: res.message
    });
    return res.isValid;
  }

  updateCandidateCompletedHandler() {
    const { i18n, sessionStore } = this.props;
    toaster.success({
      title: i18n._(t`Ma recherche`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(null);
    // appStore.refreshLayout();
  }

  render() {
    const { i18n, appStore, sessionStore } = this.props;
    const isMobile = appStore.width <= 550;
    const {
      isAvailabilityValid,
      isSearchAddressValid,
      isDayRateValid,
      isSalaryValid,
      isPermanent,
      isContract,
      isPartTime,
      isMobilityValid,
      isEmploymentPreferenceValid,
      availabilityValidationMessage,
      searchAddressValidationMessage,
      dayRateValidationMessage,
      salaryValidationMessage,
      mobilityValidationMessage,
      employmentPreferenceValidationMessage,
      saveCase
    } = this.state;
    const availabilityKey = uniqid();
    const {
      _id,
      availability,
      remote,
      searchAddress,
      searchAddressToShow,
      dayRate,
      salary,
      mobility,
      employmentPreference,
      authToken
    } = sessionStore;
    let employmentPreferenceArray = [];
    if (employmentPreference)
      employmentPreferenceArray = employmentPreference.split(",");
    const meta = staticMetaTags(i18n).search;
    return (
      <div className="profile">
        {metaTags(
          urls.mySearch(),
          meta.title,
          meta.description,
          meta.openGraphImage.facebookOpenGraphImageUrl,
          meta.openGraphImage.linkedInOpenGraphImageUrl,
          meta.keywords,
          null,
          null,
          false
        )}
        <br />

        <div className="formCard no-gutter with-padding">
          <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-label"
                  htmlFor="employmentPreferenceSearch"
                >
                  <Trans>Type de contrat</Trans>
                </label>
                <Validation
                  errorMessage={employmentPreferenceValidationMessage}
                  valid={isEmploymentPreferenceValid}
                >
                  <CheckboxGroup
                    name="CheckGrpContactType"
                    defaultSelected={employmentPreferenceArray}
                    onChange={this.employmentPreferenceChangeHandler}
                    orientation={isMobile ? "vertical" : "horizontal"}
                    className="contract-radio-group"
                  >
                    <Checkbox label={i18n._(t`CDI`)} value="Permanent" />
                    <Checkbox
                      label={i18n._(t`FreelanceType`)}
                      value="Contract"
                    />
                    <Checkbox label={i18n._(t`Part-time`)} value="Part-time" />
                  </CheckboxGroup>
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="periode1">
                  <Trans>Disponibilité</Trans>
                </label>
                <Validation
                  errorMessage={availabilityValidationMessage}
                  valid={isAvailabilityValid}
                >
                  <DatePicker
                    id="periode1"
                    key={availabilityKey}
                    dateFormat="DD/MM/YYYY"
                    defaultDate={
                      availability
                        ? moment.unix(availability / 1000).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={value =>
                      this.availabilityChangeHandler(moment(value).valueOf())
                    }
                    acceptWhenOutsideClick
                    autoComplete="off"
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="work-type">
                  <Trans>Remote/Régie</Trans>
                </label>
                <RadioGroup
                  id="work-type"
                  name="RadioGrp"
                  defaultSelected={remote}
                  onChange={this.remoteChangeHandler}
                  orientation={isMobile ? "vertical" : "horizontal"}
                  className="remote-radio-group"
                >
                  <Radio label={i18n._(t`Remote`)} value="Remote" />
                  <Radio label={i18n._(t`Régie`)} value="Onsite" />
                  <Radio
                    label={i18n._(t`Remote partiel`)}
                    value="Part-time remote"
                  />
                  <Radio label={i18n._(t`Indifférent`)} value="Indifferent" />
                </RadioGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="wanted-place">
                  <Trans>Localisation</Trans>
                </label>
                <Validation
                  errorMessage={searchAddressValidationMessage}
                  valid={isSearchAddressValid}
                >
                  <Location
                    id="wanted-place"
                    onChange={this.searchAddressChangeHandler}
                    value={searchAddress.address1}
                    getLocation={(address, formattedAddress) =>
                      this.getLocationHandler(address, formattedAddress)
                    }
                    valid={isSearchAddressValid}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="mobility">
                  <Trans>Mobilité</Trans>
                </label>
                <Validation
                  errorMessage={mobilityValidationMessage}
                  valid={isMobilityValid}
                >
                  <TextInput
                    id="mobility"
                    type="number"
                    className="form-input"
                    placeholder={i18n._(t`Dans un rayon max de XX km`)}
                    value={mobility}
                    onChange={this.mobilityChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {(isPermanent || isPartTime) && (
              <Col>
                <FormGroup>
                  <label className="form-label" htmlFor="salarySearch">
                    <Trans>Salaire annuel</Trans>
                  </label>
                  <Validation
                    errorMessage={salaryValidationMessage}
                    valid={isSalaryValid}
                  >
                    <TextInput
                      id="Salaire annuel"
                      type="number"
                      placeholder={i18n._(t`Salaire`)}
                      value={salary}
                      onChange={this.salaryChangeHandler}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            )}
            {isContract && (
              <Col>
                <FormGroup>
                  <label className="form-label" htmlFor="dayRateSearch">
                    <Trans>Taux journalier moyen</Trans>
                  </label>
                  <Validation
                    errorMessage={dayRateValidationMessage}
                    valid={isDayRateValid}
                  >
                    <TextInput
                      id="dayRateSearch"
                      type="number"
                      placeholder={i18n._(t`Par jour`)}
                      value={dayRate}
                      onChange={this.dayRateChangeHandler}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            )}
          </Row>

          <br />

          <Row style={{ flexDirection: "row-reverse" }}>
            {" "}
            <div
              className="next-buttons"
              style={{ marginRight: "19px", marginTop: "10px" }}
            >
              <Mutation
                mutation={UPDATE_CANDIDATE_MUTATION}
                variables={{
                  _id,
                  availability,
                  remote,
                  searchAddress,
                  employmentPreference,
                  dayRate: parseFloat(dayRate),
                  salary: parseFloat(salary),
                  mobility: parseFloat(mobility)
                }}
                refetchQueries={[
                  {
                    query: GET_USER_INFO,
                    variables: { token: authToken }
                  }
                ]}
                onCompleted={data => this.updateCandidateCompletedHandler(data)}
                onError={errors => {
                  errors.graphQLErrors.forEach(({ message, data }) => {
                    if (data && data.isCustomError) {
                      this.onErrorHandler(message);
                    }
                  });
                }}
              >
                {(mutation, { loading }) => (
                  <Button
                    disabled={loading || !saveCase}
                    onClick={() => {
                      if (this.isFormValid()) return mutation();
                      return null;
                    }}
                  >
                    {loading ? (
                      <Spinner
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    ) : (
                      <Trans>Enregistrer</Trans>
                    )}
                  </Button>
                )}
              </Mutation>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

MySearch.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeRemote: PropTypes.func,
    resetFields: PropTypes.func,
    changeAvailability: PropTypes.func,
    changeMobility: PropTypes.func,
    changeDayRate: PropTypes.func,
    changeSalary: PropTypes.func,
    changeSearchAddress: PropTypes.func,
    changeStep: PropTypes.func,
    changeEmploymentPreference: PropTypes.func,
    availability: PropTypes.number,
    dayRate: PropTypes.number,
    salary: PropTypes.number,
    mobility: PropTypes.number,
    employmentPreference: PropTypes.arrayOf(PropTypes.string),
    remote: PropTypes.string,
    _id: PropTypes.string,
    searchAddress: PropTypes.shape({}),
    searchAddressToShow: PropTypes.string,
    authToken: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func,
    width: PropTypes.number
  }).isRequired
};
export default withI18n()(MySearch);
