import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Trans } from "@lingui/macro";
import { Col, Button, H2, Icon } from "cf-neo-ui";
import theme from "../../../configs/theme";

@inject(
  "sessionStore",
  "appStore",
  "modalStore",
  "postANeedStore",
  "missionsSearchStore"
)
@observer
class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconColor1: "white",
      iconColor2: "black"
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { history, missionsSearchStore } = this.props;
    const { iconColor1, iconColor2 } = this.state;
    const { changeEmploymentType, addCategory } = missionsSearchStore;

    return (
      <div>
        <H2 className={classes.mainText}>
          <Trans>Votre quête de talents se débloque</Trans>
        </H2>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigCol}
        >
          <Col xl={5} lg={5} md={5} sm={5} xs={6} className={classes.leftSec3}>
            <div className={classes.textSec3Num}>
              <Trans>100 000</Trans>
            </div>
            <div className={classes.textSecTalents}>
              <Trans> Talents </Trans>
            </div>
            <div className={classes.textSec3}>
              <Trans>
                {" "}
                composent la communauté <br /> Mindquest{" "}
              </Trans>
            </div>
          </Col>
          <Col xl={7} lg={7} md={7} sm={7} xs={6}>
            <div className={classes.textInside}>
              <Trans>
                Mindquest dispose d’un vivier de talents important et des
                meilleurs outils de recherche pour trouver l'
                <strong>expert qualifié pour vos besoins </strong>en CDI ou en
                Freelance.
              </Trans>
              <br></br>
              <br></br>

              <Trans>
                {" "}
                Nous nous différencions par un service d’excellence qui allie{" "}
                <strong>
                  expertise relationnelle, compréhension métier et plateforme
                  digitale{" "}
                </strong>
                pour mettre en relation les profils de candidats{" "}
                <strong>hautement qualifiés</strong> et les clients nécessitant
                des compétences.
              </Trans>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </Col>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.blockText}
        >
          <div>
            <Icon
              className={classes.iconChart}
              type="pie-chart"
              filled={true}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span className={classes.sec3}>
              <Trans>
                72% De nos talents Freelances sont prolongés à l’issue de leur
                mission{" "}
              </Trans>
            </span>
          </div>
          <div>
            <Icon
              className={classes.iconChart}
              filled={true}
              type="pie-chart"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span className={classes.sec3}>
              <Trans>46% De nos talents Freelances sont des seniors</Trans>
            </span>
          </div>
          <div>
            <Icon
              className={classes.iconChartStat}
              type="stats-chart"
              filled={true}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span className={classes.sec3}>
              <Trans>1.5 mois de délai de recrutement moyen en CDI </Trans>
            </span>
          </div>
          <div>
            <Icon
              className={classes.iconChartStat}
              type="stats-chart"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span className={classes.sec3}>
              <Trans>
                Premier profils reçu sous une semaine en moyenne pour du CDI
              </Trans>
            </span>
          </div>
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={6} className={classes.blockButton}>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} className={classes.section3}>
            <Button
              size="medium"
              icon="chevron-right"
              iconTop="calc(50% - 10.5px)"
              iconPosition="right"
              iconColor={iconColor2}
              variant="quaternary"
              onMouseEnter={() => this.setState({ iconColor2: "white" })}
              onMouseLeave={() => this.setState({ iconColor2: "black" })}
              className={classes.styleButtonSec2}
              // classNameText={
              //   "min-width: 278px; background: #ffffff; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid #979797; &:hover { background: black; color: white;};> span { margin: 0px; font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
              // }
              onClick={() => history.push("/signup?type=enterprise")}
            >
              <Trans>Recruter un expert externalisé</Trans>
            </Button>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} className={classes.section3}>
            <Button
              size="medium"
              iconColor={iconColor1}
              variant="quaternary"
              onMouseEnter={() => this.setState({ iconColor1: "black" })}
              onMouseLeave={() => this.setState({ iconColor1: "white" })}
              className={classes.styleButton2Sec2}
              // classNameText={
              //   "min-width: 240px; background: linear-gradient(43deg, #202123 0%, #3D3F42 100%); background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px;border: 2px solid white;&:hover { background: white; color: black;}; > span { margin: 0px; font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
              // }
              icon="chevron-right"
              iconTop="calc(50% - 10.5px)"
              /*   onClick={() => {
                history.push(
                  "missions?categories=Finance&employmentType=Permanent"
                );
                addCategory(domaineExpertise(i18n, "Finance", "key"));
                changeEmploymentType("Permanent");
              }} */
              onClick={() => window.open("/signup?type=enterprise")}
            >
              <Trans>Recruter un expert en CDI</Trans>
            </Button>
          </Col>
        </Col>
      </div>
    );
  }
}

Section3.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section3));
