import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { TestimonialCard, TestimonialCardV2 } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import $ from "jquery";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./styles.scss";

@inject("appStore")
@observer
class TestimonialCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { render: false };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    const { testimonials, pLines, appStore } = this.props;
    const { render } = this.state;
    if (!render) return null;
    window.$ = $;
    window.jQuery = $;
    return (
      <div className="testimonial-carousel-styling">
        {testimonials.map(testimonial => (
          <div className="item" key={testimonial.id}>
            <img
              src={testimonial.image}
              onClick={() => testimonial.onClickButton()}
              alt={testimonial.name}
              className="imageBlog"
            />
          </div>
        ))}
        <div className="item" />
      </div>
    );
  }
}

TestimonialCarousel.wrappedComponent.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image: PropTypes.string,
      name: PropTypes.string,
      job: PropTypes.string,
      paragraph: PropTypes.string,
      buttonText: PropTypes.string,
      onClickButton: PropTypes.func
    })
  ),
  pLines: PropTypes.number,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired
};

TestimonialCarousel.defaultProps = {
  testimonials: [],
  pLines: 0
};

export default TestimonialCarousel;
