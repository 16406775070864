import gql from "graphql-tag";

export const UPDATE_Talent_POOL_MUTATION = gql`
  mutation updateTalentPoolMutation(
    $poolName: String!
    $clientID: String
    $clientEmail: String
    $clientName: String
    $sourceTalentPool: String
    $creatorID: String
    $shared: Boolean
    $clientCompanyId: Int
    $talentID: Int
  ) {
    updateTalentPool(
      input: {
        poolName: $poolName
        clientID: $clientID
        clientEmail: $clientEmail
        clientName: $clientName
        sourceTalentPool: $sourceTalentPool
        creatorID: $creatorID
        shared: $shared
        clientCompanyId: $clientCompanyId
        talentID: $talentID
      }
    ) {
      poolName
      clientEmail
      clientID
      sourceTalentPool
      creatorID
      shared
      clientCompanyId
    }
  }
`;
export const DELETE_TALENT_MUTATION = gql`
  mutation deleteTalent(
    $vivierID: String
    $talentID: Int
    $poolName: String!
    $clientID: String
    $creatorID: String
  ) {
    deleteTalent(
      input: {
        vivierID: $vivierID
        talentID: $talentID
        poolName: $poolName
        creatorID: $creatorID
        clientID: $clientID
      }
    ) {
      vivierID
    }
  }
`;
export const SEND_TALENT_INTERNAL_NOTIFICATION_MUTATION = gql`
  mutation sendTalentInternalNotificationMutation(
    $clientName: String
    $clientEmail: String
    $clientPhone: String
    $candidateId: String
    $candidateJobTitle: String
  ) {
    sendTalentInternalNotification(
      input: {
        clientName: $clientName
        clientEmail: $clientEmail
        clientPhone: $clientPhone
        candidateId: $candidateId
        candidateJobTitle: $candidateJobTitle
      }
    ) {
      message
    }
  }
`;
