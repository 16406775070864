import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as PropTypes from "prop-types";
import gql from "graphql-tag";

import {
  Row,
  Col,
  Button,
  FormCard,
  Icon,
  FormGroup,
  TextInput,
  AutoComplete,
  DatePicker,
  Validation,
  toaster,
  RadioGroup,
  InputSkeleton,
  TagList,
  Radio,
  Checkbox,
  Divider,
  Tag
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Mutation, Query } from "react-apollo";
import { observable } from "mobx";
import validate from "../../utils/validators";
import "./styles.scss";
import theme from "../../configs/theme";
import runtimeVars from "../../configs/runTimeVars";

import { GET_BUSINESS_SECTORS } from "../missions-page/queries";
import Location from "../../components/location/location";

const GET_SPECIALTIES = gql`
  {
    specialties {
      name
    }
  }
`;
const GET_SKILLS = gql`
  {
    skills {
      name
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class SingleExperience extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      isCurrent: false,
      isTitleValid: true,
      isCompanyNameValid: true,
      isStartDateValid: true,
      isAddressValid: true,
      isEndDateValid: true,
      isSummaryValid: true,
      isSkillsValid: true,
      isRefNameValid: true,
      isRefPhoneValid: true,
      isRefEmailValid: true,
      isContractTypeValid: true,
      titleValidationMessage: "",
      companyNameValidationMessage: "",
      startDateValidationMessage: "",
      endDateValidationMessage: "",
      summaryValidationMessage: "",
      refNameValidationMessage: "",
      refPhoneValidationMessage: "",
      refEmailValidationMessage: "",
      contractTypeValidationMessage: "",
      contractType: null,
      todayDate: moment(new Date()).valueOf(),
      ...initProps
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newProps = { ...nextProps };
    delete newProps.sessionStore;
    this.setState({ ...newProps });
  }

  specialtyAddHandler = value => {
    const { sessionStore } = this.props;
    // sessionStore.addspecialty(value);

    sessionStore.addspecialty(value);
    const { specialty } = sessionStore;
    this.setState({ specialties: specialty });
  };

  specialtyRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removespecialty(value);

    const { specialty } = sessionStore;
    this.setState({ specialties: specialty });
  };

  BusinessSectorChangeHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addBusinessSector(value);

    const { BusinessSector } = sessionStore;
    this.setState({ businessSectors: BusinessSector });
  };

  businessSectorRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeBusinessSector(value);

    const { BusinessSector } = sessionStore;
    this.setState({ businessSectors: BusinessSector });
  };

  skillAddHandler = value => {
    const { sessionStore } = this.props;

    /* if(this.skills && this.skills.length > 0){
      sessionStore.addskillExperience(this.skills)
    } */
    sessionStore.addskillExperience(value);
    const { skillExperience } = sessionStore;
    this.setState({ skills: skillExperience });
  };

  skillRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeskillExperience(value);
    const { skillExperience } = sessionStore;
    this.setState({ skills: skillExperience });
  };

  getLocationHandler = (address, formattedAddress) => {
    let { companyAddress } = this.state;
    companyAddress = {
      address1: address || formattedAddress.address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    };
    this.setState({ companyAddress });
    this.forceUpdate();
    this.validateAddress(address);
  };

  companyAdressChangeHandler = v => {
    const { companyAddress } = this.state;
    this.setState({ companyAddress });
    this.forceUpdate();
  };

  titleChangeHandler = e => {
    this.setState({ title: e.target.value });
    this.validateTitle(e.target.value);
  };

  onDeleteHandler = () => {
    this.setState({ isEditing: false });
    const { sessionStore } = this.props;
    const { candidateWorkHistory, changeCandidateWorkHistory } = sessionStore;
    const { id } = this.state;
    const arr = candidateWorkHistory.slice();
    if (arr && arr.length === 1) {
      localStorage.removeItem("Experiences");
    }

    for (let key = 0; key < arr.length; key += 1) {
      const experience = arr[key];
      if (experience.id === id) {
        arr.splice(key, 1);
        changeCandidateWorkHistory(arr);
      }
    }
  };

  validateAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, [], i18n);
    let mesg = "";
    if (!res.isValid) mesg = i18n._(t`Non renseigné`);
    this.setState({
      isAddressValid: res.isValid,
      addressValidationMessage: mesg
    });
    return res.isValid;
  }

  onCancelHandler = () => {
    const { isNew } = this.state;

    if (isNew) {
      this.onDeleteHandler();
    } else {
      this.setState({ isEditing: false });
    }
  };

  onEditHandler = () => {
    this.setState({ isEditing: true });
  };

  onSaveHandler = () => {
    const {
      isCurrent,
      title,
      companyName,
      startDate,
      endDate,
      summary,
      refName,
      refPhone,
      refEmail,
      contractType,
      companyAddress,
      skills,
      specialties,
      businessSectors
    } = this.state;
    const { i18n } = this.props;
    if (!this.isFormValid()) {
      return;
    }

    if (
      !(
        title !== undefined ||
        companyName !== undefined ||
        summary !== undefined ||
        refName !== undefined ||
        refPhone !== undefined ||
        refEmail !== undefined ||
        contractType !== undefined ||
        skills !== undefined ||
        specialties !== undefined ||
        businessSectors !== undefined ||
        companyAddress !== undefined
      )
    ) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Vous ne pouvez pas ajouter une expérience vide`)
      });
      return;
    }
    const { sessionStore } = this.props;

    const { candidateWorkHistory, changeCandidateWorkHistory } = sessionStore;

    const newCandidateWorkHistory = candidateWorkHistory.slice();
    const { id } = this.state;
    for (let key = 0; key < newCandidateWorkHistory.length; key += 1) {
      const experience = newCandidateWorkHistory[key];
      if (experience.id === id) {
        newCandidateWorkHistory[key] = {
          id,
          isCurrent,
          title,
          companyName,
          startDate,
          endDate,
          summary,
          refName,
          refPhone,
          refEmail,
          contractType,
          isNew: false,
          isEditing: false,
          skills,
          specialties,
          businessSectors,
          companyAddress
        };
      }

      changeCandidateWorkHistory(newCandidateWorkHistory);
      sessionStore.removeALLskillExperience();
      sessionStore.removeALLspecialty();
      sessionStore.removeALLBusinessSector();
    }
  };

  refEmailChangeHandler = e => {
    this.setState({ refEmail: e.target.value });
    this.validateRefEmail(e.target.value);
  };

  isCurrentChangeHandler = () => {
    const { isCurrent } = this.state;
    if (!isCurrent) {
      this.setState({ endDate: "" });
    }
    this.setState({ isCurrent: !isCurrent });
  };

  contractTypeChangeHandler = value => {
    this.setState({ contractType: value });
    this.validateContractType(value);
  };

  companyNameChangeHandler = e => {
    this.setState({ companyName: e.target.value });
    this.validateCompanyName(e.target.value);
  };

  startDateChangeHandler = value => {
    this.setState({ startDate: value });
    this.validateStartDate(value);
  };

  endDateChangeHandler = value => {
    this.setState({ endDate: value });
    this.validateEndDate(value);
  };

  summaryChangeHandler = e => {
    this.setState({ summary: e.target.value });
    this.validateSummary(e.target.value);
  };

  refNameChangeHandler = e => {
    this.setState({ refName: e.target.value });
    this.validateRefName(e.target.value);
  };

  refPhoneChangeHandler = e => {
    this.setState({ refPhone: e.target.value });
    this.validateRefPhone(e.target.value);
  };

  validateSpecialties(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 0 },
          msg: i18n._(t`Choisissez au moins une spécialité`)
        }
      ],
      i18n
    );
    this.setState({
      isSpecialtiesValid: res.isValid,
      specialtiesValidationMessage: res.message
    });
    return res.isValid;
  }

  validateSkills = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 2, maxlength: 10 },
          msg: i18n._(t`Choisissez entre deux et dix compétences`)
        }
      ],
      i18n
    );

    this.setState({
      isSkillsValid: res.isValid,
      skillsValidationMessage: res.message
    });
    return res.isValid;
  };

  isFormValid = () => {
    const {
      title,
      isCurrent,
      companyName,
      startDate,
      endDate,
      summary,
      refName,
      refPhone,
      refEmail,
      contractType,
      skillExperience
    } = this.state;

    let valid = true;
    // if (!this.validateSkills(skillExperience)) valid = false;

    if (!this.validateTitle(title)) valid = false;
    if (!this.validateCompanyName(companyName)) valid = false;
    if (!this.validateStartDate(startDate)) valid = false;
    if (!this.validateSummary(summary)) valid = false;
    if (!isCurrent) if (!this.validateEndDate(endDate)) valid = false;
    if (!this.validateRefName(refName)) valid = false;
    if (!this.validateRefPhone(refPhone)) valid = false;
    if (!this.validateRefEmail(refEmail)) valid = false;

    return valid;
  };

  validateContractType = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isContractTypeValid: res.isValid,
      contractTypeValidationMessage: res.message
    });
    return res.isValid;
  };

  validateTitle = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", value, { maxlength: 50 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      isTitleValid: res.isValid,
      titleValidationMessage: res.message
    });
    return res.isValid;
  };

  validateCompanyName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isCompanyNameValid: res.isValid,
      companyNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefName = value => {
    const { i18n } = this.props;
    const res = validate(value, ["name", { maxlength: 50 }], i18n);
    this.setState({
      isRefNameValid: res.isValid,
      refNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefEmail = value => {
    const { i18n } = this.props;
    const res = validate(value, ["email"], i18n);
    this.setState({
      isRefEmailValid: res.isValid,
      refEmailValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefPhone = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [{ maxlength: 13 }, { minlength: 8 }, "phoneNumber"],
      i18n
    );
    this.setState({
      isRefPhoneValid: res.isValid,
      refPhoneValidationMessage: res.message
    });
    return res.isValid;
  };

  validateStartDate = value => {
    const { i18n } = this.props;
    const { endDate, todayDate, isCurrent } = this.state;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || Number.isNaN(value) === true) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }
    if (endDate === "" || Number.isNaN(endDate)) {
      if (value <= todayDate) {
        this.setState({
          isStartDateValid: true,
          startDateValidationMessage: ""
        });
        return true;
      }

      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    if (value <= endDate && endDate <= todayDate) {
      this.setState({
        isStartDateValid: true,
        startDateValidationMessage: ""
      });
      return true;
    }

    if (!isCurrent && value >= todayDate && value >= endDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`La date de début doit être toujours antérieur à la date de fin`
        )
      });
      return false;
    }

    if (!isCurrent && value <= todayDate && value >= endDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`La date de début doit être toujours antérieur à la date de fin`
        )
      });
      return false;
    }

    if (value >= todayDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    this.setState({
      isStartDateValid: true,
      startDateValidationMessage: ""
    });
    return true;
  };
  validateEndDate = value => {
    const { i18n } = this.props;
    const { startDate, todayDate } = this.state;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || Number.isNaN(value) === true) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }
    if (startDate === "" || Number.isNaN(startDate)) {
      if (value <= todayDate) {
        this.setState({
          isEndDateValid: true,
          endDateValidationMessage: ""
        });
        return true;
      }

      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    if (value >= todayDate) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    if (startDate <= value && value <= todayDate) {
      this.setState({
        isEndDateValid: true,
        endDateValidationMessage: ""
      });
      return true;
    }

    if (startDate >= value && value <= todayDate) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`La date de fin doit être toujours ultérieur à la date de début`
        )
      });
      return false;
    }

    this.setState({
      isEndDateValid: true,
      endDateValidationMessage: ""
    });
    return true;
  };

  disableStartDays = day => {
    const { endDate, todayDate } = this.state;

    if (endDate) {
      return day > endDate;
    }
    return day > todayDate;
  };

  disableEndDays = day => {
    const { startDate, todayDate } = this.state;
    return day < startDate || day > todayDate;
  };
  validateSummary = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required", { maxlength: 1000 }], i18n);
    this.setState({
      isSummaryValid: res.isValid,
      summaryValidationMessage: res.message
    });
    return res.isValid;
  };
  displayButtons = () => {
    const { showButton } = this.props;
    if (showButton === "true") {
      return (
        <div className="experience-buttons">
          <Button size="small" onClick={this.onDeleteHandler}>
            <Trans>Supprimer</Trans>
          </Button>
          <Button variant="secondary" size="small" onClick={this.onEditHandler}>
            <Trans>Modifier</Trans>
          </Button>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      title,
      isCurrent,
      companyName,
      startDate,
      endDate,
      summary,
      isEditing,
      refName,
      refPhone,
      refEmail,
      isAddressValid,
      companyAddress,
      contractType,
      skills,
      specialties,
      businessSectors,
      isTitleValid,
      isCompanyNameValid,
      isStartDateValid,
      isEndDateValid,
      isSummaryValid,
      isRefNameValid,
      isRefPhoneValid,
      isRefEmailValid,
      isContractTypeValid,
      titleValidationMessage,
      companyNameValidationMessage,
      startDateValidationMessage,
      endDateValidationMessage,
      summaryValidationMessage,
      refNameValidationMessage,
      refPhoneValidationMessage,
      refEmailValidationMessage,
      contractTypeValidationMessage
    } = this.state;

    const { i18n, appStore, sessionStore, showButton } = this.props;

    const orientation = appStore.width <= 348 ? "vertical" : "horizontal";
    const { currentLanguage } = appStore;
    const {
      addressToShow,
      BusinessSector,
      skillExperience,
      specialty,
      scrappedLinkedinProfile
    } = sessionStore;
    const isMobile = appStore.width <= 1350;

    if (
      skills &&
      skills.length > 0 &&
      skillExperience &&
      skillExperience.length === 0 &&
      isEditing
    ) {
      for (let s = 0; s < skills.length; s += 1) {
        sessionStore.addskillExperience(skills[s]);
      }
    }

    if (
      specialties &&
      specialties.length > 0 &&
      specialty &&
      specialty.length === 0 &&
      isEditing
    ) {
      for (let s = 0; s < specialties.length; s += 1) {
        sessionStore.addspecialty(specialties[s]);
      }
    }

    if (
      businessSectors &&
      businessSectors.length > 0 &&
      BusinessSector &&
      BusinessSector.length === 0 &&
      isEditing
    ) {
      for (let s = 0; s < businessSectors.length; s += 1) {
        sessionStore.addBusinessSector(businessSectors[s]);
      }
    }

    return (
      <FormCard className="formCard single-experience-padding">
        <Row>
          <Col>
            {isEditing ? (
              <div className="scrollBar">
                <div>
                  <Row>
                    <Col>
                      <Checkbox
                        name="iscurrent"
                        value="iscurrent"
                        checked={isCurrent}
                        label={i18n._(t`J’occupe actuellement ce poste`)}
                        onChange={this.isCurrentChangeHandler}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label" htmlFor="experienceTitle">
                          <Trans>Titre</Trans>
                        </label>
                        <Validation
                          errorMessage={titleValidationMessage}
                          valid={isTitleValid}
                        >
                          <TextInput
                            id="experienceTitle"
                            className="form-input"
                            icon="briefcase"
                            iconColor={theme.colors.lightMainColor}
                            placeholder=""
                            value={title}
                            onChange={this.titleChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-label"
                          htmlFor="experienceEnterprise"
                        >
                          <Trans>Entreprise</Trans>
                        </label>
                        <Validation
                          errorMessage={companyNameValidationMessage}
                          valid={isCompanyNameValid}
                        >
                          <TextInput
                            id="experienceEnterprise"
                            className="form-input"
                            placeholder=""
                            value={companyName}
                            onChange={this.companyNameChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label" htmlFor="expertise">
                          <Trans>Secteur</Trans>
                        </label>
                        <div>
                          <Query query={GET_BUSINESS_SECTORS}>
                            {({ loading, error, data }) => {
                              if (loading) return <InputSkeleton />;
                              if (error) return `${i18n._(t`Erreur`)} !`;
                              const allbusinessSectors = [];
                              data.businessSectors.map(item =>
                                allbusinessSectors.push(item.name)
                              );
                              return (
                                <div className="withSrcollBar">
                                  <AutoComplete
                                    id="buisinessSector"
                                    clearOnSelect
                                    options={allbusinessSectors}
                                    iconColor="#8d0417"
                                    iconColor2="#d3354a"
                                    icon="bars"
                                    placeholder={i18n._(
                                      t`Sélectionner des secteurs`
                                    )}
                                    onSelect={val =>
                                      this.BusinessSectorChangeHandler(val)
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>

                        <TagList
                          tags={businessSectors}
                          variant="secondary"
                          closable
                          className="tag-list"
                          onClose={val => this.businessSectorRemoveHandler(val)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-label"
                          htmlFor="experienceEnterprise"
                        >
                          <Trans>Localisation</Trans>
                        </label>

                        <Location
                          id="address"
                          onChange={this.companyAdressChangeHandler}
                          value={companyAddress ? companyAddress.countryID : ""}
                          getLocation={(Address, FormattedAddress) =>
                            this.getLocationHandler(Address, FormattedAddress)
                          }
                          valid={isAddressValid}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup>
                      <Col>
                        <label className="form-label" htmlFor="contractType">
                          <Trans>Type de contrat</Trans>
                        </label>

                        <RadioGroup
                          className="contractType-radio-group"
                          name="contractTypeRadioGrp"
                          orientation={orientation}
                          defaultSelected={contractType}
                          onChange={this.contractTypeChangeHandler}
                        >
                          <Radio label={i18n._(t`CDI`)} value="Permanent" />
                          <Radio
                            label={i18n._(t`FreelanceType`)}
                            value="Contract"
                          />
                        </RadioGroup>
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label" htmlFor="experienceStart">
                          <Trans>Date de début</Trans>
                        </label>
                        <Validation
                          errorMessage={startDateValidationMessage}
                          valid={isStartDateValid}
                        >
                          <DatePicker
                            id="experienceStart"
                            disabledDaysMatcher={day =>
                              this.disableStartDays(day)
                            }
                            dateFormat="DD/MM/YYYY"
                            defaultDate={
                              startDate
                                ? moment
                                    .unix(startDate / 1000)
                                    .format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={value =>
                              this.startDateChangeHandler(
                                moment(value).valueOf()
                              )
                            }
                            locale={currentLanguage || runtimeVars.APP_LANG}
                            acceptWhenOutsideClick
                            autoComplete="off"
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    {!isCurrent && (
                      <Col>
                        <FormGroup>
                          <label className="form-label" htmlFor="experienceEnd">
                            <Trans>Date de fin</Trans>
                          </label>
                          <Validation
                            errorMessage={endDateValidationMessage}
                            valid={isEndDateValid}
                          >
                            <DatePicker
                              id="experienceEnd"
                              disabledDaysMatcher={day =>
                                this.disableEndDays(day)
                              }
                              dateFormat="DD/MM/YYYY"
                              defaultDate={
                                endDate
                                  ? moment
                                      .unix(endDate / 1000)
                                      .format("DD/MM/YYYY")
                                  : " "
                              }
                              onChange={value =>
                                this.endDateChangeHandler(
                                  moment(value).valueOf()
                                )
                              }
                              locale={currentLanguage || runtimeVars.APP_LANG}
                              acceptWhenOutsideClick
                              autoComplete="off"
                            />
                          </Validation>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-label"
                          htmlFor="experienceDescription"
                        >
                          <Trans>Déscription de la mission</Trans>
                        </label>
                        <Validation
                          errorMessage={summaryValidationMessage}
                          valid={isSummaryValid}
                        >
                          <textarea
                            id="experienceDescription"
                            onChange={this.summaryChangeHandler}
                            value={summary}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xl={6}>
                      <FormGroup>
                        <label className="form-label" htmlFor="jobExpertise">
                          <Trans>Compétence</Trans>
                        </label>
                        <div>
                          <Query query={GET_SKILLS}>
                            {({ loading, error, data }) => {
                              if (loading) return <InputSkeleton />;
                              if (error)
                                return `${i18n._(t`Erreur`)} !${error.message}`;
                              var allSkills = [];
                              data.skills.map(skill => {
                                allSkills.push(skill.name);
                                allSkills = [...new Set(allSkills)];
                              });
                              return (
                                <div className="withSrcollBar">
                                  <AutoComplete
                                    id="skills"
                                    clearOnSelect
                                    options={allSkills}
                                    iconColor="#8d0417"
                                    iconColor2="#d3354a"
                                    icon="bars"
                                    placeholder={i18n._(
                                      t`Sélectionner des compétences`
                                    )}
                                    onSelect={val => this.skillAddHandler(val)}
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>

                        <TagList
                          tags={skills}
                          variant="secondary"
                          closable
                          className="tag-list"
                          onClose={val => this.skillRemoveHandler(val)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} xl={6}>
                      <FormGroup>
                        <label className="form-label" htmlFor="jobSpecialities">
                          <Trans>Environements technique</Trans>
                        </label>
                        <div>
                          <Query query={GET_SKILLS}>
                            {({ loading, error, data }) => {
                              if (loading) return <InputSkeleton />;
                              if (error)
                                return `${i18n._(t`Erreur`)} !${error.message}`;
                              let specialtiesData = [];
                              data.skills.map(skill => {
                                specialtiesData.push(skill.name);
                                specialtiesData = [...new Set(specialtiesData)];
                              });
                              //const { specialties: specialtiesData } = data;
                              return (
                                <div className="withSrcollBar">
                                  <AutoComplete
                                    id="jobSpecialities"
                                    clearOnSelect
                                    options={specialtiesData.map(item => item)}
                                    iconColor="#FA324A"
                                    iconColor2="#BF142A"
                                    icon="bars"
                                    placeholder={i18n._(
                                      t`Sélectionner des spécialités`
                                    )}
                                    onSelect={val =>
                                      this.specialtyAddHandler(val)
                                    }
                                  />
                                </div>
                              );
                            }}
                          </Query>
                        </div>

                        <TagList
                          tags={specialties}
                          variant="secondary"
                          closable
                          className="tag-list"
                          onClose={val => this.specialtyRemoveHandler(val)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label" htmlFor="experienceRef">
                          <Trans>Référence</Trans>
                        </label>
                        <Row>
                          <Col>
                            <Validation
                              errorMessage={refNameValidationMessage}
                              valid={isRefNameValid}
                            >
                              <TextInput
                                id="experienceRef"
                                className="form-input"
                                placeholder={i18n._(t`Nom et prénom`)}
                                value={refName}
                                onChange={this.refNameChangeHandler}
                              />
                            </Validation>
                          </Col>
                          <Col>
                            <Validation
                              errorMessage={refPhoneValidationMessage}
                              valid={isRefPhoneValid}
                            >
                              <TextInput
                                className="form-input"
                                type="tel"
                                placeholder={i18n._(t`Téléphone`)}
                                value={refPhone}
                                onChange={this.refPhoneChangeHandler}
                              />
                            </Validation>
                          </Col>
                          <Col>
                            <Validation
                              errorMessage={refEmailValidationMessage}
                              valid={isRefEmailValid}
                            >
                              <TextInput
                                className="form-input"
                                placeholder={i18n._(t`Email`)}
                                value={refEmail}
                                onChange={this.refEmailChangeHandler}
                              />
                            </Validation>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="experience-buttons">
                  <Button size="small" onClick={this.onCancelHandler}>
                    <Trans>Annuler</Trans>
                  </Button>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={this.onSaveHandler}
                  >
                    <Trans>Enregistrer</Trans>
                  </Button>
                  <br />
                  <br />
                </div>
              </div>
            ) : (
              <div>
                <Row style={{ marginRight: "-15px", marginLeft: "-15px" }}>
                  <Col lg={5} xl={5} className="info" style={{ border: 0 }}>
                    <div style={{ display: "flex" }}>
                      <Icon
                        className="icon-margin-right"
                        type="flag"
                        size="tiny"
                        width={20}
                        heigth={20}
                        color={theme.colors.mainColor}
                        color2={theme.colors.lightMainColor}
                      />
                      <div
                        style={{
                          color: "#d3354a",
                          "margin-right": "2%",
                          "font-weight": "bold",
                          "font-size": "15px"
                        }}
                      >
                        {title}
                      </div>
                      {contractType === "Contract" ? (
                        <div>(Freelance)</div>
                      ) : null}
                      {contractType === "Permanent" ? <div>(CDI)</div> : null}
                    </div>

                    <Row>
                      <Col lg={5} xl={5}>
                        <div style={{ display: "flex" }}>
                          <picture className="iconCompany">
                            <img src="/iconCompany.webp" alt="company" />
                            <i />
                          </picture>
                          {companyName}
                        </div>
                      </Col>
                      <Col lg={7} xl={7}>
                        <div style={{ display: "flex" }}>
                          <Icon
                            className="iconlocation"
                            type="map-marker"
                            size="tiny"
                            width={20}
                            heigth={20}
                            color={theme.colors.mainColor}
                            color2={theme.colors.lightMainColor}
                          />
                          {companyAddress
                            ? companyAddress.countryID
                              ? companyAddress.countryID
                              : companyAddress.city
                            : null}
                        </div>
                      </Col>
                    </Row>

                    {businessSectors && (
                      <div>
                        {businessSectors.length > 0 && (
                          <div>
                            <Icon
                              className="icon-margin-right"
                              type="briefcase"
                              size="tiny"
                              width={20}
                              heigth={20}
                              color={theme.colors.mainColor}
                              color2={theme.colors.lightMainColor}
                            />
                            {businessSectors.map(secteur => `${secteur}   `)}
                          </div>
                        )}
                      </div>
                    )}
                    {(startDate || endDate) && (
                      <div>
                        <Icon
                          className="icon-margin-right"
                          type="calendar"
                          size="tiny"
                          width={20}
                          heigth={20}
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                        />
                        {startDate
                          ? moment.unix(startDate / 1000).format("DD/MM/YYYY")
                          : ""}{" "}
                        -{" "}
                        {endDate
                          ? moment.unix(endDate / 1000).format("DD/MM/YYYY")
                          : //: moment.unix(Date.now() / 1000).format("DD/MM/YYYY")}
                            i18n._(t`Aujourd’hui`)}
                      </div>
                    )}
                    {refName || refPhone || refEmail ? (
                      <Row
                        style={{
                          display: "inline-block",
                          marginRight: "-15px",
                          marginLeft: "1px",
                          marginTop: "10px"
                        }}
                      >
                        <div style={{ color: "#696969" }}>
                          <Trans>Contact de références</Trans>:
                        </div>

                        {refName && (
                          <span style={{ display: "flex" }}>
                            <span className="reference">
                              <Trans>Nom et prénom</Trans>:
                            </span>{" "}
                            {refName}
                          </span>
                        )}

                        {refPhone && (
                          <span style={{ display: "flex" }}>
                            <span className="reference">
                              <Trans>Téléphone</Trans>:
                            </span>{" "}
                            {refPhone}
                          </span>
                        )}

                        {refEmail && (
                          <span style={{ display: "flex" }}>
                            <span className="reference">
                              <Trans>Email</Trans>:
                            </span>{" "}
                            {refEmail}
                          </span>
                        )}
                      </Row>
                    ) : (
                      <Row
                        style={{
                          marginRight: "-15px",
                          marginLeft: "1px",
                          marginTop: "10px"
                        }}
                      >
                        <span style={{ marginRight: "5px", color: "#696969" }}>
                          <Trans>Contact de références</Trans>:
                        </span>
                        <span>
                          <Trans>Aucun contact indiqué</Trans>
                        </span>
                      </Row>
                    )}
                  </Col>
                  <Divider
                    orientation={isMobile ? "horizontal" : "vertical"}
                    color="red"
                    type="dashed"
                  />
                  <Col lg={6} xl={6}>
                    <div className="summary" style={{ border: 0 }}>
                      {(
                        <text
                          style={{
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            "-webkit-box-orient": "vertical"
                          }}
                        >
                          {summary}
                        </text>
                      ) || (
                        <div>
                          <Trans>
                            Veuillez ajouter une déscription de la mission
                          </Trans>
                        </div>
                      )}
                    </div>

                    <Row>
                      <Col lg={6} xl={6}>
                        <div>
                          <span className="reference">
                            <Trans>Compétences</Trans>
                          </span>
                          <div>
                            {skills && skills.length > 0 ? (
                              skills.map(Skill => {
                                return (
                                  <Tag
                                    text={Skill}
                                    className="scraping-tag-list"
                                    closable={false}
                                    clickable={false}
                                  />
                                );
                              })
                            ) : (
                              <span
                                style={{
                                  color: "rgb(82 81 81)",
                                  marginLeft: "2%",
                                  marginTop: "2%",
                                  fontSize: "small"
                                }}
                              >
                                <Trans>Ajouter vos Compétences </Trans> <br />
                                <Trans>Obtenues sur cette mission </Trans>
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Trans>Environnement technique</Trans>
                          <div>
                            {specialties && specialties.length > 0 ? (
                              specialties.map(spe => {
                                return (
                                  <Tag
                                    style={{ color: "white" }}
                                    text={spe}
                                    className="scraping-tag-list2"
                                    closable={false}
                                    clickable={false}
                                  />
                                );
                              })
                            ) : (
                              <span
                                style={{
                                  color: "rgb(82 81 81)",
                                  marginLeft: "2%",
                                  marginTop: "2%",
                                  fontSize: "small"
                                }}
                              >
                                <Trans>Ajouter les environnements </Trans>{" "}
                                <br />
                                <Trans>
                                  techniques utilisés sur cette mission{" "}
                                </Trans>
                              </span>
                            )}
                          </div>{" "}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />

                <br />
                {this.displayButtons()}
              </div>
            )}
          </Col>
        </Row>
      </FormCard>
    );
  }
}

SingleExperience.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    candidateWorkHistory: PropTypes.shape({}),
    changeCandidateWorkHistory: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired,
  showButton: PropTypes.string,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(SingleExperience);
