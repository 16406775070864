import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Mutation, Query } from "react-apollo";
import { toaster, Button } from "cf-neo-ui";
import { withI18n } from "@lingui/react/index";
import { t, Trans } from "@lingui/macro";
import { CREATE_MISSION_APPLICATION } from "../../mutations";
import { USER_APPLICATIONS_QUERY } from "../../query";
import Routes from "../../../layout/routes";
import routes from "../../../layout/routes/hbLayoutRoutes";
import classes from "./LeftSlide.module.scss";
import DistanceMatrix from "./distance-matrix/DistanceMatrix";

@inject("sessionStore")
@observer
class ApplyBtn extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
    this.state = {
      isVisible: false,
      title: "",
      description: "",
      applied: false
    };
  }

  isBtnDisabled = userApplications => {
    const { id, disable } = this.props;

    return disable || !!userApplications.length;
  };
  toLogin = () => {
    const { id, sessionStore, history } = this.props;
    sessionStore.setBackRef(id);
    history.push(`${Routes.HbLayoutRoutes.Login.path}?origin=apply`);
  };

  mutationCompleted = () => {
    this.setState({ applied: true });
  };

  displayApplyBtnn = i18n => {
    const { sessionStore, id, loading: loadingGeneral, style } = this.props;
    const { applied } = this.state;
    const variables = applied
      ? { active: true }
      : { jobOrderId: parseInt(id, 10) };
    if (sessionStore.authToken && sessionStore.account.role === "CANDIDATE") {
      return loadingGeneral ? null : (
        <Query
          query={USER_APPLICATIONS_QUERY}
          variables={{ ...variables }}
          fetchPolicy="network-only"
        >
          {({ loading, error: queryError, data }) => {
            if (queryError) return null;
            if (loading) return null;

            const { userApplications } = data;
            if (applied) {
              const { sessionStore, i18n } = this.props;
              const num = (userApplications && userApplications.length) || 0;
              const remainingApplications = Math.max(0, 3 - num);
              let toastDescription = i18n._(
                t`Vous pouvez suivre vos candidatures dans votre profil`
              );

              if (remainingApplications >= 0) {
                toastDescription += `${i18n._(
                  t`Il vous reste ${remainingApplications}/3 candidatures possibles.Lorsqu'un emploi pour lequel vous avez postulé est fermé, vous recevez une candidature supplémentaire`
                )}`;
              }
              toaster.success({
                title: i18n._(t`Candidature créée avec succès`),
                description: toastDescription,
                id: "application_success"
              });
              sessionStore.setJobOrderReferer(null);
            }
            return (
              <Mutation
                mutation={CREATE_MISSION_APPLICATION}
                onCompleted={() => this.mutationCompleted()}
                onError={error => {
                  const toastObj = {
                    title: i18n._(t`Erreur de création de candidature`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de votre candidature`
                    )
                  };
                  if (
                    error.graphQLErrors[0].message ===
                    "Vous avez déjà postulé pour cette mission"
                  ) {
                    toastObj.title = i18n._(
                      t`Vous avez déjà postulé pour cette mission`
                    );
                    toastObj.description = i18n._(
                      t`Vous pouvez suivre vos candidatures dans votre profil`
                    );
                  } else if (
                    error.graphQLErrors[0].message ===
                    'Unexpected error value: "Unauthorized"'
                  ) {
                    toastObj.title = i18n._(t`Accès non autorisé`);
                    toastObj.description = i18n._(
                      t`Vous devez compléter votre inscription pour postuler à cette offre`
                    );
                  } else if (
                    error.graphQLErrors[0].message ===
                      "Application more than 3" ||
                    'Unexpected error value: "Application more than 3"'
                  ) {
                    toastObj.title = i18n._(
                      t`Les candidatures sont limitées à 3 postes ouverts simultanément`
                    );
                    toastObj.description = i18n._(
                      t`Lorsqu'un emploi pour lequel vous avez postulé est fermé, vous pouvez postuler une nouvelle fois`
                    );
                  }

                  this.setState({
                    title: toastObj.title,
                    description: toastObj.description,
                    isVisible: true
                  });
                  //toaster.error(toastObj);
                }}
                ignoreResults
                refetchQueries={[
                  {
                    query: USER_APPLICATIONS_QUERY,
                    variables: { active: true }
                  }
                ]}
              >
                {createMissionApplication => (
                  <Button
                    disabled={this.isBtnDisabled(userApplications)}
                    style={{ width: `${style}%` }}
                    onClick={() => {
                      createMissionApplication({
                        variables: {
                          jobOrderId: id,
                          origin: sessionStore.jobOrderReferer || "direct url"
                        }
                      });
                      sessionStore.setIsItDisable(true);
                    }}
                    icon="chevron-right"
                    name={i18n._(t`Postuler`)}
                  >
                    {this.isBtnDisabled(userApplications) ? (
                      <Trans>Postulé</Trans>
                    ) : (
                      <Trans>Postuler</Trans>
                    )}
                  </Button>
                )}
              </Mutation>
            );
          }}
        </Query>
      );
    } else if (
      sessionStore.authToken &&
      sessionStore.account.role === "CLIENT"
    ) {
      return (
        <Button
          name={i18n._(t`Postuler`)}
          style={{ width: `${style}%` }}
          icon="chevron-right"
          disabled={true}
        >
          <Trans>Postuler</Trans>
        </Button>
      );
    }
    return (
      <Button
        name={i18n._(t`Postuler`)}
        style={{ width: `${style}%` }}
        onClick={this.toLogin}
        icon="chevron-right"
      >
        <Trans>Postuler</Trans>
      </Button>
    );
  };

  render() {
    const { i18n } = this.props;

    return <section>{this.displayApplyBtnn(i18n)}</section>;
  }
}

export default withI18n()(withRouter(ApplyBtn));
