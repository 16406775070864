import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
  FormCard,
  SwitchButton,
  DatePicker,
  toaster,
  Button,
  Modal
} from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { ApolloConsumer, Mutation, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import uniqid from "uniqid";
import PublicProfileModal from "../../../../components/PublicProfileModal/PublicProfileModal";
import { UPDATE_CANDIDATE_MUTATION } from "./queries";
import { GET_USER_INFO } from "../../../../components/hoc/queries";
import { LOGOUT_MUTATION } from "../../hbfLayout/mutation";

@inject(
  "newTimesheetStore",
  "sessionStore",
  "appStore",
  "candidatesSearchStore"
)
@observer
class PublicProfile extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = this.props;
    this.state = {
      isProfileActivationModalVisible: false,
      publicProfil: sessionStore.publicProfil,
      switchAllowed: true
    };
  }

  closeModal = () => {
    this.setState({ isProfileActivationModalVisible: false });
  };

  mutationCompleted = () => {
    const { i18n } = this.props;
    toaster.success({
      title: i18n._(t`Profil Pseudonymisé`),
      description: i18n._(t`Votre profil n'est plus public`)
    });
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  modalOpenerHandler = () => {
    this.setState({ isProfileActivationModalVisible: true });
  };

  publicProfileDisableHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changePublicProfil(false);
    this.setState({ publicProfil: false });
  };
  handleSwitch = async mutation => {
    const { newTimesheetStore, i18n } = this.props;
    const { publicProfil } = this.state;
    const { isEdited } = newTimesheetStore;
    if (isEdited) {
      const confirmed = window.confirm(
        i18n._(
          t`Les modifications que vous avez apportées ne seront peut-être pas enregistrées.`
        )
      );
      if (!confirmed) {
        this.setState({ switchAllowed: false });
        return; // If not confirmed, exit
      }
    }
    // Reset switchAllowed to true
    this.setState({ switchAllowed: true });
    if (publicProfil === false) {
      await this.modalOpenerHandler();
    } else if (publicProfil === true) {
      await this.publicProfileDisableHandler();
      return mutation();
    }
  };

  render() {
    const { isProfileActivationModalVisible, publicProfil } = this.state;
    const publishProfileKey = uniqid();
    const { sessionStore, candidatesSearchStore, apolloClient } = this.props;
    const { _id, authToken, logout } = sessionStore;
    const { clearSearch } = candidatesSearchStore;
    return (
      <div className="dispo-card">
        <Modal
          centred
          withCloseButton={false}
          isVisible={isProfileActivationModalVisible}
          position="fixed"
        >
          <PublicProfileModal close={this.closeModal} />
        </Modal>
        <ApolloConsumer>
          {apolloClient => (
            <Mutation
              mutation={UPDATE_CANDIDATE_MUTATION}
              variables={{
                _id,
                publicProfil: false
              }}
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
              onCompleted={this.mutationCompleted}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message }) => {
                  if (message === "UNAUTHENTICATED") {
                    apolloClient.mutate({
                      mutation: LOGOUT_MUTATION,
                      variables: { token: sessionStore.authToken }
                    });
                    logout();
                    clearSearch();
                  }
                  if (message) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {mutation => (
                <FormCard style={{ zIndex: "1" }}>
                  <div className="title">
                    <Trans>
                      publier mon profil
                      <br />
                      (pseudonymisé)
                    </Trans>
                  </div>
                  <div className="switch-btn">
                    <SwitchButton
                      key={publishProfileKey}
                      checked={publicProfil}
                      onSwitch={() => this.handleSwitch(mutation)}
                    />
                  </div>
                </FormCard>
              )}
            </Mutation>
          )}
        </ApolloConsumer>
      </div>
    );
  }
}

PublicProfile.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    publicProfil: PropTypes.bool,
    changePublicProfil: PropTypes.func,
    authToken: PropTypes.string,
    logout: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeProfileActivationPopUpVisibility: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired
};

export default withApollo(withI18n()(PublicProfile));
