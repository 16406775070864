import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { t, Trans } from "@lingui/macro";
import { Button, Col, H2, Row } from "cf-neo-ui";
@inject("sessionStore", "appStore", "modalStore", "postANeedStore")
@observer
class Section2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconColor1: "white",
      iconColor2: "black"
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { history } = this.props;
    const { iconColor1, iconColor2 } = this.state;

    return (
      <div>
        <H2 className={classes.mainText}>
          <Trans>
            Les Talents idéaux pour résoudre vos défis Finance, sans effort de
            recherche
          </Trans>
        </H2>

        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          id="bigCol"
          className={classes.bigCol}
        >
          <Col xl={8} lg={8} md={12} sm={12} xs={6}>
            <div className={classes.textInside}>
              <Trans>
                Vous avez des besoins en,{" "}
                <strong>
                  {" "}
                  Direction financière externalisée, Analyse Financière,
                  Management de Transition, Comptabilité, Contrôle de gestion,
                  Consulting, AMOA, Consolidation, Audit, M&A{" "}
                </strong>
                en Freelance ou en CDI, nous vous présentons des talents
                selectionnés pour votre entreprise.
                <br />
              </Trans>
            </div>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={6}
              className={classes.blockButton}
            >
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={6}
                className={classes.buttonSec2}
              >
                <Button
                  size="medium"
                  icon="chevron-right"
                  iconTop="calc(50% - 10.5px)"
                  iconPosition="right"
                  iconColor={iconColor2}
                  className={classes.styleButtonSec2}
                  variant="quaternary"
                  onMouseEnter={() => this.setState({ iconColor2: "white" })}
                  onMouseLeave={() => this.setState({ iconColor2: "black" })}
                  onClick={() => history.push("/signup?type=enterprise")}
                >
                  <Trans>Recruter un expert externalisé</Trans>
                </Button>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={6}
                className={classes.buttonSec2}
              >
                <Button
                  size="medium"
                  icon="chevron-right"
                  iconTop="calc(50% - 10.5px)"
                  iconColor={iconColor1}
                  variant="quaternary"
                  onMouseEnter={() => this.setState({ iconColor1: "black" })}
                  onMouseLeave={() => this.setState({ iconColor1: "white" })}
                  className={classes.styleButton2Sec2}
                  onClick={() => window.open("/signup?type=enterprise")}
                >
                  <Trans>Recruter un expert en CDI</Trans>
                </Button>
              </Col>
            </Col>
          </Col>

          <Col xl={4} lg={4} md={12} sm={12} xs={6}>
            <picture>
              <source type="images/png" srcSet="/assets/images/sec2.png" />
              <img
                className={classes.projectsImg}
                src="/assets/images/sec2.png"
                alt="illustration_graphique_finance"
              />
            </picture>
          </Col>
        </Col>
      </div>
    );
  }
}

Section2.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section2));
