import { t } from "@lingui/macro";

const domaineExpertise = (i18n, category, result = "value") => {
  const categories = {
    "CRM Software": i18n._(t`CRM Software`),
    "Data Science & BI": i18n._(t`Data Science & BI`),
    "Embedded Sytems": i18n._(t`Embedded Sytems`),
    "ERP / EDI": i18n._(t`ERP / EDI`),
    "Information Security": i18n._(t`Information Security`),
    "Infrastructure & Cloud computing": i18n._(
      t`Infrastructure & Cloud computing`
    ),
    "IoT & AI/ML & Blockchain": i18n._(t`IoT & AI/ML & Blockchain`),
    "IT Governance": i18n._(t`IT Governance`),
    "Microsoft Technologies": i18n._(t`Microsoft Technologies`),
    "Mobile Development": i18n._(t`Mobile Development`),
    "Non-IT": i18n._(t`Non-IT`),
    "OS & Software development": i18n._(t`OS & Software development`),
    "Project management & Agile Coaching": i18n._(
      t`Project management & Agile Coaching`
    ),
    "QA & Testing": i18n._(t`QA & Testing`),
    SAP: i18n._(t`SAP`),
    "UX /UI & Graphic Design": i18n._(t`UX /UI & Graphic Design`),
    "Web Development": i18n._(t`Web Development`),
    Finance: i18n._(t`Finance`)
  };
  if (result == "value") {
    return categories[category] ? categories[category] : category;
  } else {
    const key = Object.keys(categories).find(
      key => categories[key] === category
    );
    return key ? key : category;
  }
};
export default domaineExpertise;
