import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { BlogCardDisplay } from "cf-neo-ui";

import $ from "jquery";
import carouselConfigs from "./CarouselConfigs";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Styles.scss";
import { inject } from "mobx-react";

@inject("appStore")
class SugCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  navigate = id => {
    const { history } = this.props;
    history.push(`/blog/news/${id}`);
  };

  formatDate = (date, currentLanguage) => {
    moment.locale(currentLanguage);
    return moment(date).format("DD MMMM YYYY");
  };

  action = categories => {
    for (const item of categories) {
      if (item.name === "video") return "play";
    }
    return "";
  };

  carousel = () => {
    const { items, appStore } = this.props;
    const { render } = this.state;
    const { currentLanguage } = appStore;
    if (!render) return null;
    if (typeof window !== "undefined") {
      window.$ = $;
      window.jQuery = $;
    }
    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    return (
      <div className="Suggestion-Carousel-Blog-Articles">
        <OwlCarousel {...carouselConfigs}>
          {items.map(item => (
            <div className="item" key={item.id}>
              <BlogCardDisplay
                poster={
                  item.featured_media && item.featured_media.source_url
                    ? item.featured_media.source_url
                    : "/mq.webp"
                }
                caption={
                  Array.isArray(item.categories) && item.categories.length
                    ? item.categories[0].name
                    : "non précie"
                }
                smallTitle={
                  item.date
                    ? this.formatDate(item.date, currentLanguage)
                    : "date non precis"
                }
                description={item.title}
                action={this.action(item.categories)}
                href={`/blog/news/${item.id}/${item.title}`}
                cardClick={() => this.navigate(item.id)}
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
    );
  };

  render() {
    const { items } = this.props;
    return !Array.isArray(items) || !items.length ? null : this.carousel();
  }
}

SugCarousel.propTypes = {
  items: PropTypes.arrayOf({}),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

SugCarousel.defaultProps = {
  items: []
};

export default withRouter(SugCarousel);
