import React, { Component } from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { BlogCardDisplay, BlogCardDisplaySkeleton } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import {
  categoriesRefEn,
  categoriesRefFr,
  latestPostsQuery
} from "../../../../../query";
import ArticlesSugCarousel from "./SugCarousel";
import classes from "./SugDisplayArticles.module.scss";
import decode from "../../../../../../../utils/Utf8Text";
import { t } from "@lingui/macro";

@inject("appStore")
@observer
class SugDisplayArticles extends Component {
  loading = () => {
    return (
      <div className={classes.container}>
        <BlogCardDisplaySkeleton className={classes.article} />
        <BlogCardDisplaySkeleton className={classes.article} />
        <BlogCardDisplaySkeleton className={classes.article} />
      </div>
    );
  };

  navigate = id => {
    const { history } = this.props;
    history.push(`/blog/news/${id}`);
  };

  formatDate = (date, currentLanguage) => {
    moment.locale(currentLanguage);
    return moment(date).format("DD MMMM YYYY");
  };

  action = categories => {
    for (const item of categories) {
      if (item.name === "video") return "play";
    }
    return "";
  };

  render() {
    const { currentBlog, appStore, relatedPosts, i18n } = this.props;

    const { width, currentLanguage } = appStore;
    return relatedPosts.length ? (
      <Query
        query={latestPostsQuery}
        variables={{
          include: relatedPosts.slice(0, 3).join(","),
          language: currentLanguage
        }}
      >
        {({ loading, error, data }) => {
          if (error)
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          if (loading) {
            return width > 780 ? this.loading() : <BlogCardDisplaySkeleton />;
          }
          return width > 780 ? (
            <div className={classes.container}>
              {data.posts.map(article => (
                <BlogCardDisplay
                  className={classes.article}
                  key={article.id}
                  poster={
                    article.featured_media && article.featured_media.thumbnail
                      ? article.featured_media.thumbnail
                      : "/mq.webp"
                  }
                  caption={
                    Array.isArray(article.categories) &&
                    article.categories.length
                      ? decode(article.categories[0].name)
                      : "non précie"
                  }
                  smallTitle={
                    article.date
                      ? decode(this.formatDate(article.date, currentLanguage))
                      : "date non precis"
                  }
                  description={decode(article.title)}
                  action={this.action(article.categories)}
                  href={`/blog/news/${article.id}/${article.title}`}
                  cardClick={() => this.navigate(article.id, article.title)}
                />
              ))}
            </div>
          ) : (
            <ArticlesSugCarousel items={data.posts} />
          );
        }}
      </Query>
    ) : (
      <Query
        query={latestPostsQuery}
        variables={{
          page: 1,
          excludeCategories:
            currentLanguage === "fr"
              ? `${categoriesRefFr.videos},${categoriesRefFr.resources}`
              : `${categoriesRefEn.videos},${categoriesRefEn.resources}`,
          perPage: 3,
          excludeBlog: currentBlog.id,
          language: currentLanguage
        }}
      >
        {({ loading, error, data }) => {
          if (error)
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          if (loading) {
            return appStore.width > 780 ? (
              this.loading()
            ) : (
              <BlogCardDisplaySkeleton />
            );
          }
          return appStore.width > 780 ? (
            <div className={classes.container}>
              {data.posts.map(article => (
                <BlogCardDisplay
                  className={classes.article}
                  key={article.id}
                  poster={
                    article.featured_media && article.featured_media.thumbnail
                      ? article.featured_media.thumbnail
                      : "/mq.webp"
                  }
                  caption={
                    Array.isArray(article.categories) &&
                    article.categories.length
                      ? article.categories[0].name
                      : "non précie"
                  }
                  smallTitle={
                    article.date
                      ? this.formatDate(article.date, currentLanguage)
                      : "date non precis"
                  }
                  description={article.title}
                  action={this.action(article.categories)}
                  href={`/blog/news/${article.id}`}
                  cardClick={() => this.navigate(article.id)}
                />
              ))}
            </div>
          ) : (
            <ArticlesSugCarousel items={data.posts} />
          );
        }}
      </Query>
    );
  }
}

SugDisplayArticles.wrappedComponent.propTypes = {
  currentBlog: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(["video", "book", "article"])
  }).isRequired,
  relatedPosts: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired
};

SugDisplayArticles.defaultProps = {
  relatedPosts: []
};

export default withRouter(SugDisplayArticles);
