import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Trans } from "@lingui/macro";
import { Col, H2, Button } from "cf-neo-ui";
@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { history } = this.props;

    return (
      <div className={classes.section3}>
        <H2 className={classes.mainText}>
          <Trans>
            130 Clients grand compte nous font confiance, ce n’est pas un hasard
          </Trans>
        </H2>

        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.blockIconSec4}
        >
          <Col xl={2} lg={2} md={2} sm={2} xs={3} className={classes.imgSec4}>
            <picture className={classes.social}>
              <source type="images/png" srcSet="/assets/images/biogaran.png" />
              <img
                className={classes.imgEntreprise}
                src="/assets/images/biogaran.png"
                alt="client_logo_biogaran"
              />
            </picture>
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} xs={3} className={classes.imgSec4}>
            <picture className={classes.airliquid}>
              <source
                type="images/png"
                srcSet="/assets/images/airliquide.png"
              />
              <img
                className={classes.imgEntreprise}
                src="/assets/images/airliquide.png"
                alt="client_logo_airliquide"
              />
            </picture>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={3} className={classes.imgSec4}>
            <picture className={classes.social}>
              <source type="images/png" srcSet="/assets/images/coyote.png" />
              <img
                className={classes.imgEntreprise}
                src="/assets/images/coyote.png"
                alt="client_logo_coyote"
              />
            </picture>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={3} className={classes.imgSec4}>
            <picture className={classes.social}>
              <source type="images/png" srcSet="/assets/images/imerys.png" />
              <img
                className={classes.imgEntreprise}
                src="/assets/images/imerys.png"
                alt="client_logo_imerys"
              />
            </picture>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={3} className={classes.imgSec4}>
            <picture className={classes.microsoft}>
              <source type="images/png" srcSet="/assets/images/microsoft.png" />
              <img
                className={classes.imgEntreprise}
                src="/assets/images/microsoft.png"
                alt="client_logo_microsoft"
              />
            </picture>
          </Col>
        </Col>
        <Col xl={5} lg={6} md={6} sm={6} xs={6}>
          <Button
            size="medium"
            icon="chevron-right"
            iconTop="calc(50% - 10.5px)"
            iconPosition="right"
            iconColor="black"
            variant="quaternary"
            className={classes.styleButtonSec2}
            // classNameText={
            //   "height:60px; background: #ffffff; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid #979797; > span { margin: 0px; font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
            // }
            onClick={() =>
              window.open("https://visite.mindquest.io/finance-entreprise")
            }
          >
            <Trans>
              Accélérez votre processus de recutrement dès maintenant
            </Trans>
          </Button>
        </Col>
      </div>
    );
  }
}

Section4.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section4));
