import React, { Component } from "react";
import { Mutation, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Validation, FormGroup, Button, PopUp } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import "../styles.scss";
import { inject, observer } from "mobx-react";
import styles from "./styles.module.scss";
import locals from "../../../helpers/locals";
import { SEND_MESSAGE_TIMESHEET } from "../mutations";

@inject("newTimesheetStore", "appStore", "sessionStore")
@observer
class MoreInformationPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: true
    };
  }

  validate = () => {
    const { message } = this.state;
    const { newTimesheetStore } = this.props;
    const { addDescriptionToPeriod, rangeDetails } = newTimesheetStore;
    addDescriptionToPeriod(rangeDetails, message, rangeDetails.themeName);
    this.setState({ message: true });
  };

  render() {
    const { message } = this.state;
    const {
      newTimesheetStore,
      device,
      appStore,
      sessionStore,
      i18n
    } = this.props;
    const { account } = sessionStore;
    const {
      openClosePopUps,
      rangeDetails,
      timesheet,
      editable
    } = newTimesheetStore;
    const { currentLanguage } = appStore;
    return (
      <PopUp
        mobile={device === "mobile"}
        onClose={() => {
          this.setState({ message: true }), openClosePopUps("moreInformation");
        }}
      >
        <div
          style={{
            position: "relative",
            height:
              account.role == "CANDIDATE" && editable(account.role)
                ? "380px"
                : "210px",
            width: device === "mobile" ? "100%" : "380px"
          }}
        >
          <div
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              color: "#242424",
              marginBottom: "26px"
            }}
          >
            {rangeDetails.start !== rangeDetails.end
              ? `${rangeDetails.start} ${
                  locals[currentLanguage].months.short[timesheet.month - 1]
                } - ${rangeDetails.end} ${
                  locals[currentLanguage].months.short[timesheet.month - 1]
                }`
              : `${rangeDetails.start} ${
                  locals[currentLanguage].months.short[timesheet.month - 1]
                }`}
          </div>
          <ul
            style={{
              listStyleType: "none",
              position: "relative",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              color: "#242424"
            }}
          >
            <li style={{ marginBottom: "10px", marginLeft: "-40px" }}>
              <Trans>Projet </Trans>:{" "}
              <span style={{ color: "#8d0417" }}>{rangeDetails.themeName}</span>
            </li>
            <li style={{ marginBottom: "10px", marginLeft: "-40px" }}>
              <Trans>Nombre de jours total:</Trans>{" "}
              <span style={{ color: "#8d0417" }}>{`${rangeDetails.end -
                rangeDetails.start +
                1} ${
                rangeDetails.end - rangeDetails.start + 1 > 1
                  ? i18n._(t`jours`)
                  : i18n._(t`jour`)
              }`}</span>
            </li>
            <li style={{ marginBottom: "10px", marginLeft: "-40px" }}>
              <Trans>Heures Totales</Trans>:{" "}
              <span style={{ color: "#8d0417" }}>{`${Math.floor(
                rangeDetails.total / 60
              )}h${rangeDetails.total % 60}m`}</span>
            </li>
            <li style={{ marginBottom: "10px", marginLeft: "-40px" }}>
              <Trans>Heures par jour (moyenne)</Trans>:{" "}
              <span style={{ color: "#8d0417" }}>{`${Math.floor(
                rangeDetails.total /
                  (rangeDetails.end - rangeDetails.start + 1) /
                  60
              )}h${(rangeDetails.total /
                (rangeDetails.end - rangeDetails.start + 1)) %
                60}m`}</span>
            </li>
          </ul>
          {account.role == "CANDIDATE" && editable(account.role) && (
            <FormGroup>
              <label
                style={{
                  position: "relative",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "#242424"
                }}
              >
                <Trans>Ajouter une annotation:</Trans>
              </label>
              <Validation errorMessage="" valid={true}>
                <textarea
                  className={styles.textArea}
                  id="addMessage"
                  placeholder={i18n._(
                    t`Ajoutez votre commentaire puis validez`
                  )}
                  value={message == true ? rangeDetails.description : message}
                  onChange={e => this.setState({ message: e.target.value })}
                  style={{ paddingLeft: "10px", marginTop: "10px" }}
                />
              </Validation>
              <label
                style={{
                  position: "relative",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "11px",
                  color: "#cb1a31"
                }}
              >
                <Trans>
                  Cette annotation sera visible par le client et dans le module
                  de chat mais pas sur le compte-rendu de Timesheet
                </Trans>
              </label>
            </FormGroup>
          )}
          {account.role == "CANDIDATE" && editable(account.role) && (
            <div
              style={{
                float: "left",
                padding: "20px 20px 20px 0"
              }}
            >
              <span
                style={{
                  paddingTop: "20px",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "normal",
                  color: "#8f8f8f",
                  marginTop: "20px"
                }}
                onClick={() => {
                  openClosePopUps("moreInformation");
                  openClosePopUps("deleteRange");
                }}
              >
                <Trans>Supprimer la plage de jours</Trans>
              </span>
            </div>
          )}
          <Mutation
            mutation={SEND_MESSAGE_TIMESHEET}
            variables={{
              timesheetID: timesheet.id,
              messages: [
                { text: message, type: "comment" },
                { text: rangeDetails.type, type: "type" },
                { text: rangeDetails.period, type: "period" },
                { text: rangeDetails.hours, type: "hours" },
                { text: "", type: "description" },
                { text: rangeDetails.themeName, type: "themeName" },
                { text: rangeDetails.themeColor, type: "themeColor" }
              ]
            }}
            onCompleted={() => {
              openClosePopUps("moreInformation");
              this.setState({ message: message });
            }}
            onError={errors => {
              errors.graphQLErrors.forEach(({ message, data }) => {
                if (message === "UNAUTHENTICATED") {
                  logout();
                  clearSearch();
                }
                if (data && data.isCustomError) {
                  this.onErrorHandler(message);
                }
              });
            }}
          >
            {(mutation, { loading }) => (
              <div
                style={{
                  float: "right",
                  padding: "20px 0"
                }}
              >
                <Button
                  disabled={["pending", "approved"].includes(timesheet.status)}
                  variant="primary"
                  size="small"
                  onClick={() => {
                    this.validate();
                    return mutation();
                  }}
                >
                  <Trans>Valider</Trans>
                </Button>
              </div>
            )}
          </Mutation>
        </div>
      </PopUp>
    );
  }
}

MoreInformationPopUp.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    editable: PropTypes.func,
    rangeDetails: PropTypes.shape({}),
    timesheet: PropTypes.shape({})
  }).isRequired,
  device: PropTypes.string,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }).isRequired
};
export default withApollo(withI18n()(withRouter(MoreInformationPopUp)));
