import React, { Component } from "react";
import { Mutation, withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Col, Row, Button, Spinner, Icon, IconCircle } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import locals from "../../../helpers/locals";
import { UPDATE_TIMESHEET } from "../mutations";
import { SINGLE_TIME_SHEETS_QUERY } from "../queries";
import { TimesheetChat } from "cf-neo-ui";
import publicIp from "public-ip";
import { Link } from "react-router-dom";
@inject("newTimesheetStore", "appStore", "sessionStore")
@observer
class HeaderForDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    publicIp
      .v4()
      .then(res => {
        this.ip = res;
      })
      .catch(() => {});
  }
  isValid = () => {
    return true;
  };
  render() {
    const {
      newTimesheetStore,
      appStore,
      device,
      sessionStore,
      displayChat,
      history
    } = this.props;
    const { currentLanguage } = appStore;
    const {
      timesheet,
      workPlan,
      openClosePopUps,
      canSave,
      notReady,
      themes,
      changeIsEdited
    } = newTimesheetStore;
    const { showChat } = this.state;
    const { account } = sessionStore;
    return (
      <Row>
        <Col xs={6} sm={12} md={12} lg={12} xl={12}>
          {device === "mobile" ? (
            <div style={{ marginBottom: "20px" }}>
              <div
                style={{
                  textAlign: "start",
                  position: "relative",
                  top: "27px"
                }}
              >
                {" "}
                <div
                  onClick={() => {
                    if (showChat) {
                      this.setState({ showChat: false });
                      displayChat(false);
                    } else {
                      history.push(`/dashboard/timesheets`);
                    }
                  }}
                >
                  {" "}
                  <Icon
                    type="back"
                    size="extra-large"
                    color="#8d0417"
                    color2="#d3354a"
                  />{" "}
                </div>{" "}
              </div>
              {!showChat && (
                <div
                  style={{
                    textAlign: "end",
                    position: "relative",
                    width: "40px",
                    float: "right"
                  }}
                >
                  <Icon
                    displayMessages={() => {
                      this.setState({ showChat: true });
                      displayChat(true);
                    }}
                    type="comment"
                    size="medium"
                    color="#8d0417"
                    color2="#d3354a"
                    filled={false}
                  />
                </div>
              )}
              <div style={{ textAlign: "center", marginLeft: "44px" }}>
                <span
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor:
                      timesheet.status === "approved"
                        ? "#127D4B"
                        : timesheet.status === "rejected"
                        ? "#d33536"
                        : timesheet.status === "pending"
                        ? "#C4790B"
                        : timesheet.status === "open"
                        ? "#0B51C4"
                        : "#0B51C4",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight: "2px",
                    verticalAlign: "middle"
                  }}
                />
                <span
                  style={{
                    display: "inline-flex",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "26px",
                    letterSpacing: "0.3px",
                    color: "#1E293B"
                  }}
                >
                  {account.role === "CLIENT" &&
                    `${timesheet.candidate.firstName} ${timesheet.candidate.lastName} -`}{" "}
                  <text
                    style={{
                      width: "73px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                      cursor: "pointer"
                    }}
                    title={timesheet.title}
                  >
                    {timesheet.title}
                  </text>
                </span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  letterSpacing: "0.3px",
                  color: "#1E293B",
                  marginLeft: "40px"
                }}
              >
                {locals[currentLanguage].months.short[
                  timesheet.month - 1
                ].toUpperCase()}{" "}
                {timesheet.year}
              </div>
            </div>
          ) : (
            <div style={{ marginBottom: "20px", marginTop: "-10px" }}>
              <Row>
                <Col xs={6} sm={12} md={4} lg={4} xl={4} />
                <Col xs={6} sm={12} md={4} lg={4} xl={4}>
                  <div
                    style={{
                      textAlign: "center",
                      width: "fit-content",
                      maxWidth: "350px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      margin: "auto"
                    }}
                  >
                    <span
                      style={{
                        height: "20px",
                        width: "20px",
                        backgroundColor: "#0B51C4",
                        backgroundColor:
                          timesheet.status === "approved"
                            ? "#127D4B"
                            : timesheet.status === "rejected"
                            ? "#d33536"
                            : timesheet.status === "pending"
                            ? "#C4790B"
                            : timesheet.status === "open"
                            ? "#0B51C4"
                            : "#0B51C4",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "2px",
                        verticalAlign: "middle"
                      }}
                    />
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "26px",
                        letterSpacing: "0.3px",
                        color: "#1E293B",
                        verticalAlign: "middle",
                        cursor: "pointer"
                      }}
                    >
                      {account.role === "CLIENT" &&
                        `${timesheet.candidate?.firstName.toUpperCase()} ${timesheet.candidate?.lastName.toUpperCase()} -`}{" "}
                      <text title={timesheet.title}>{timesheet.title}</text>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      letterSpacing: "0.3px",
                      color: "#1E293B"
                    }}
                  >
                    {locals[currentLanguage].months.short[
                      timesheet.month - 1
                    ].toUpperCase()}{" "}
                    {timesheet.year}
                  </div>
                </Col>
                <Col xs={6} sm={12} md={4} lg={4} xl={4}>
                  <div>
                    <div
                      style={{
                        float: "right",
                        margin: "0 0 0 0"
                      }}
                    >
                      {account.role === "CANDIDATE" ? (
                        <Button
                          variant="primary"
                          size="small"
                          disabled={
                            ["pending", "approved"].includes(
                              timesheet.status
                            ) || !canSave
                          }
                          onClick={() =>
                            openClosePopUps("sendCandidateTimesheet")
                          }
                          style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            letterSpacing: "0.3px",
                            background:
                              "linear-gradient(51.52deg, #8D0417 5.72%, #D3354A 96.63%)",
                            boxShadow: "0px 4px 4px rgba(136, 136, 136, 0.25)",
                            borderRadius: "5px",
                            height: "33px"
                          }}
                        >
                          <Trans>Envoyer</Trans>
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          size="small"
                          disabled={
                            ["open", "rejected", "approved"].includes(
                              timesheet.status
                            ) || notReady
                          }
                          onClick={() => openClosePopUps("validateTimesheet")}
                          style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            letterSpacing: "0.3px",
                            background:
                              "linear-gradient(51.52deg, #8D0417 5.72%, #D3354A 96.63%)",
                            boxShadow: "0px 4px 4px rgba(136, 136, 136, 0.25)",
                            borderRadius: "5px",
                            height: "33px"
                          }}
                        >
                          <Trans>valider</Trans>
                        </Button>
                      )}
                    </div>
                    <div
                      style={{
                        float: "right",
                        margin: "0 1%"
                      }}
                    >
                      {account.role === "CANDIDATE" ? (
                        <Mutation
                          mutation={UPDATE_TIMESHEET}
                          variables={{
                            id: timesheet.id,
                            action: "draft",
                            ip: this.ip,
                            workPlan,
                            themes
                          }}
                          refetchQueries={[
                            {
                              query: SINGLE_TIME_SHEETS_QUERY,
                              variables: { id: timesheet.id }
                            }
                          ]}
                          onCompleted={() =>
                            openClosePopUps("timesheetSavedSuccessfully")
                          }
                          onError={errors => {}}
                        >
                          {(mutation, { loading }) => (
                            <Button
                              variant="secondary"
                              size="small"
                              disabled={
                                loading ||
                                ["pending", "approved"].includes(
                                  timesheet.status
                                ) ||
                                !canSave
                              }
                              onClick={() => {
                                if (this.isValid()) {
                                  changeIsEdited(false);
                                  return mutation();
                                }
                                return null;
                              }}
                              style={{
                                background: "#FFFFFF",
                                boxShadow:
                                  "0px 4px 4px rgba(136, 136, 136, 0.25)",
                                borderRadius: "5px",
                                border: "1px solid #8D0417",
                                color: "#8D0417",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "12px",
                                letterSpacing: "0.3px",
                                height: "33px"
                              }}
                            >
                              <Trans>Enregistrer</Trans>
                            </Button>
                          )}
                        </Mutation>
                      ) : (
                        <Button
                          variant="secondary"
                          size="small"
                          disabled={["open", "rejected", "approved"].includes(
                            timesheet.status
                          )}
                          onClick={() => openClosePopUps("rejectTimesheet")}
                          style={{
                            background: "#FFFFFF",
                            boxShadow: "0px 4px 4px rgba(136, 136, 136, 0.25)",
                            borderRadius: "5px",
                            border: "1px solid #8D0417",
                            color: "#8D0417",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            letterSpacing: "0.3px",
                            height: "33px"
                          }}
                        >
                          <Trans>rejeter</Trans>
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}

          <div
            style={{
              height: "2px",
              width: "100%",
              background:
                "linear-gradient(51.52deg, #8D0417 5.72%, #D3354A 96.63%)"
            }}
          />
        </Col>
      </Row>
    );
  }
}

HeaderForDesktop.wrappedComponent.propTypes = {
  newTimesheetStore: PropTypes.shape({
    openClosePopUps: PropTypes.func,
    canSave: PropTypes.bool,
    notReady: PropTypes.bool,
    timesheet: PropTypes.shape({}),
    workPlan: PropTypes.array,
    themes: PropTypes.array
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  device: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};
export default withApollo(withI18n()(withRouter(HeaderForDesktop)));
