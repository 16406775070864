const roundDownDays = totalMinutes => {
  // Calculate the total number of days
  const totalDays = totalMinutes / (8 * 60);

  // Check if the decimal part is less than 0.25
  if (totalDays - Math.floor(totalDays) < 0.25) {
    // If yes, round down the number of days
    return Math.floor(totalDays);
  } else {
    // Otherwise, return the totalDays
    return totalDays;
  }
};
export default roundDownDays;
