import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Trans } from "@lingui/macro";
import { Col, H2, Button } from "cf-neo-ui";
@inject("sessionStore", "appStore", "modalStore", "missionsSearchStore")
@observer
class Section9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div className={classes.section3}>
        <H2 className={classes.mainText}>
          <Trans>Formez vos équipes avec Mindquest et Groupe Y </Trans>
        </H2>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.blockIcon}
        >
          <div className={classes.textSec9}>
            <Trans>
              Mindquest et <strong>Groupe Y </strong>se sont associés pour
              développer des formations pour les métiers de la Finance.
            </Trans>
            <br />
            <br />
            <Trans>
              {" "}
              Vous souhaitez faire monter en compétences vos équipes ?
              Contactez-vous.
            </Trans>
          </div>
        </Col>
        <Col xl={9} lg={9} md={9} sm={9} xs={6} className={classes.blockinputs}>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={6}
            className={classes.widgetBody}
          >
            <Button
              size="medium"
              variant="quaternary"
              classNameText={
                "width: 90%; background: #ffffff; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid #979797"
              }
              onClick={() => history.push("/signup?type=enterprise")}
            >
              <Trans>Recruter un talent CDI ou Freelance</Trans>
            </Button>
          </Col>
          <Col
            xl={2}
            lg={2}
            md={2}
            sm={12}
            xs={6}
            className={classes.widgetBody}
          >
            <Button
              size="medium"
              variant="quaternary"
              classNameText={
                "width:90%; background: transparent ; background-size: 101% 101%;color: #FFF; box-shadow: 0px 4px 4px 0px rgba(136, 136, 136, 0.25);border-radius: 5px;&:hover { background: white; color: black;}"
              }
              onClick={() => history.push("/?cal=marine-danjou")}
            >
              <Trans>Prendre un RDV</Trans>
            </Button>
          </Col>
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={6}
            className={classes.widgetBody}
          >
            <Button
              size="medium"
              variant="quaternary"
              icon="whatsapp"
              iconPosition="left"
              iconTop="calc(50% - 15.5px)"
              width="30"
              height="30"
              iconColor="white"
              classNameText={
                "width: 93%; background: #25D366 ; background-size: 101% 101%;color: #FFF; box-shadow: none;border-radius: 5px"
              }
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=33757903093&text&type=phone_number&app_absent=0",
                  "_blank"
                )
              }
            >
              <Trans>Contacter via Whatsapp</Trans>
            </Button>
          </Col>
        </Col>
      </div>
    );
  }
}

Section9.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section9));
