import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ApolloConsumer, Mutation, withApollo } from "react-apollo";
import { Button, Col, FormCard, Row, Icon, Spinner, toaster } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import theme from "../../configs/theme";
import classes from "../PublicProfileModal/PublicProfileModal.scss";
import { SUGGEST_SKILL } from "./utils/mutations/mutation";

@inject("sessionStore", "appStore", "modalStore")
@observer
class SuggestedSkillModal extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  mutationCompleted = () => {
    const { i18n, close } = this.props;
    close();
    toaster.success({
      title: i18n._(t`Compétence suggérée`),
      description: i18n._(t`Votre Suggestion sera revue`)
    });
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  closeModal = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const { suggestedSkill, source, appStore } = this.props;

    const { currentLanguage } = appStore;

    return (
      <div className="single-candidate-page-modal-session single-candidate-page-modal-container single-candidate-page-modal-signup-selection">
        <div
          className={classes.headerProfilModal}
          style={{ borderRadius: "0px", borderBottom: "1px solid #dadee3" }}
        >
          <div className="suggestSkillModalHeader">
            <a className="suggestSkillCloseIcon">
              <Icon
                onClick={this.closeModal}
                size="tiny"
                type="close"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </a>
          </div>
        </div>
        <FormCard style={{ borderRadius: "0px" }}>
          <Row>
            <Col
              className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center"
              style={{ textAlign: "center", marginTop: "35px" }}
            >
              <Trans>
                Vous allez suggérer la compétence "{suggestedSkill}", est-ce que
                correct ?
                <br />
                Merci de vérifier que celle-ci est bien orthographiée.
                <br />
                <br />
                Cette compétence sera revue par nos équipes et pourra être
                ajoutée dans les prochains jours.
                <br />
                <br />
                Merci de votre suggestion
              </Trans>
            </Col>
          </Row>
          <Row>
            <Col className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center">
              <div
                className="single-candidate-page-modal-center"
                style={{ marginTop: "-25px" }}
              >
                <ApolloConsumer>
                  {apolloClient => (
                    <Mutation
                      mutation={SUGGEST_SKILL}
                      variables={{
                        suggestedSkill,
                        source
                      }}
                      onCompleted={this.mutationCompleted}
                      onError={errors => {
                        errors.graphQLErrors.forEach(({ message }) => {
                          if (message) {
                            this.onErrorHandler(message);
                          }
                        });
                      }}
                    >
                      {(mutation, { loading }) => (
                        <Button
                          onClick={async () => {
                            return mutation();
                          }}
                        >
                          {loading ? (
                            <Spinner
                              type="pointed-circular"
                              color="#FFFFFF"
                              size={12}
                            />
                          ) : (
                            <Trans>Suggérer</Trans>
                          )}
                        </Button>
                      )}
                    </Mutation>
                  )}
                </ApolloConsumer>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

SuggestedSkillModal.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  close: PropTypes.func,
  modalStore: PropTypes.shape({
    changeProfileActivationPopUpVisibility: PropTypes.func
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(SuggestedSkillModal));
