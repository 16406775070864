import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import classes from "../finance.module.scss";
import { Trans } from "@lingui/macro";
import { Col, Button, H2 } from "cf-neo-ui";

@inject("sessionStore", "appStore", "modalStore", "postANeedStore")
@observer
class Section3 extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { history } = this.props;

    return (
      <div className={classes.section3}>
        <H2 className={classes.mainTextSec5}>
          <Trans>Rencontrez Marine, experte métier finance</Trans>
        </H2>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={6}
          className={classes.blockSection5}
        >
          <Col xl={3} lg={3} md={3} sm={3} xs={3} className={classes.marie}>
            <picture>
              <source type="images/png" srcSet="/assets/images/marie.png" />
              <img
                className={classes.marieImg}
                src="/assets/images/marie.png"
                alt="Marine_Danjou_Mindquest_Finance"
              />
            </picture>
            <div className={classes.socialSec5}>
              <picture
                className={classes.picture}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/marinedanjou/",
                    "_blank"
                  )
                }
              >
                <source
                  type="images/png"
                  srcSet="/assets/images/linkedin.png"
                />
                <img
                  className={classes.pictureSec5}
                  src="/assets/images/linkedin.png"
                  alt="Linkedin_Mindquest_Finance"
                />
              </picture>
              <picture
                className={classes.picture}
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=33757903093&text&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
              >
                <source
                  type="images/png"
                  srcSet="/assets/images/whatsapp.png"
                />
                <img
                  className={classes.pictureSec5}
                  src="/assets/images/whatsapp.png"
                  alt="Whatsapp_Marine_Danjou_Mindquest"
                />
              </picture>
            </div>
          </Col>
          <Col xl={9} lg={9} md={9} sm={9} xs={3} className={classes.textBody}>
            <div className={classes.textInsideSec5}>
              <Trans>
                <strong>Quand as tu rejoins Mindquest ?</strong>
                <br />
                J'ai rejoins Mindquest en 2023 dans nos bureaux de Barcelone
                <br />
                <br />
                <strong>Quel est ton métier au quotidien ?</strong>
                <br />
                J'accompagne les experts finance et les entreprises dans la
                recherche des meilleures opportunités et des meilleurs talents.
                Je m'assure de trouver la bonne expertise pour solutionner un
                besoin client.
              </Trans>
              <br></br>
              <br></br>
            </div>
            <Button
              size="medium"
              variant="quaternary"
              // classNameText={
              //   "background: #ffffff; background-size: 101% 101%;color: black; box-shadow: none;border-radius: 5px;border: 2px solid #979797; > span { margin: 0px;;font-family: Montserrat; margin: 0px; font-size: 14px; font-style: normal; font-weight: 600;line-height: 26px; letter-spacing: 0.3px;}"
              // }
              icon="chevron-right"
              iconTop="calc(50% - 10.5px)"
              iconColor="black"
              iconColor2="black"
              color="white"
              radius={0}
              className={classes.buttonOffre}
              onClick={() => {
                history.push("/offers/178296");
              }}
            >
              <Trans>Contacter Marine pour un besoin</Trans>
            </Button>
          </Col>
        </Col>
      </div>
    );
  }
}

Section3.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(Section3));
