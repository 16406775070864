import gql from "graphql-tag";

const TIME_SHEETS_QUERY = gql`
  query Timesheets(
    $limit: Int
    $skip: Int
    $timesheetIDs: [Int]
    $placementIDs: [Int]
    $periodStart: Float
    $periodEnd: Float
    $month: Int
    $year: Int
    $status: [timesheetSupportedStatuses]
    $name: String
    $email: String
  ) {
    timesheets(
      filter: {
        limit: $limit
        skip: $skip
        timesheetIDs: $timesheetIDs
        placementIDs: $placementIDs
        periodStart: $periodStart
        periodEnd: $periodEnd
        month: $month
        year: $year
        status: $status
        name: $name
        email: $email
      }
    ) {
      timesheets {
        id
        title
        client {
          id
          firstName
          lastName
          name
          email
          companyName
        }
        candidate {
          id
          firstName
          lastName
          name
          email
          city
        }
        placementID
        createdAt
        month
        year
        updatedAt
        status
        workPlan {
          day
          hours
          minutes
          type
          theme {
            name
            color
          }
          description
        }
        approvingClient {
          id
          firstName
          lastName
          name
          email
          companyName
        }
      }
      total
      islocked
    }
  }
`;

const SINGLE_TIME_SHEETS_QUERY = gql`
  query Timesheet($id: Int!) {
    timesheet(id: $id) {
      id
      title
      validationAllowed
      client {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      candidate {
        id
        firstName
        lastName
        name
        email
        city
      }
      placementID
      createdAt
      month
      year
      updatedAt
      status
      workplan {
        day
        minutes
        theme {
          name
          color
        }
        type
        location
        description
      }
      totals {
        normalTime
        specialTime
        extraTime
        remoteTime
      }
      conversation {
        timesheetID
        dateAdded
        dateLastUpdate
        messages {
          _id
          text
          date
          speaker
        }
        comment
      }
      approvingClient {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      approvingClient2 {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      holidays {
        month
        year
        country
        holidays {
          day
          name
        }
      }
      themes {
        name
        color
      }
      clientCorporation {
        id
        name
      }
      #      to verify if should be deleted or not
      #      dateAdded
      #      dateLastUpdate
      #      statusMaker
      #      signature
      #      country {
      #        code
      #        name
      #      }
    }
  }
`;

export { TIME_SHEETS_QUERY, SINGLE_TIME_SHEETS_QUERY };
