import urls from "../../../utils/urls";
import runtime from "../../../configs/runTimeVars";

const ldJson = (i18n, post) => [
  {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    image:
      (post.featured_media && post.featured_media.source_url) ||
      `${urls.home}/mq.webp`,
    url: urls.singleBlog(post.id, runtime.APP_LANG, post.title),
    headline: post.title,
    dateCreated: post.date,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": urls.singleBlog(post.id, runtime.APP_LANG)
    },
    datePublished: post.date,
    dateModified: post.modified,
    author: {
      "@type": "Person",
      name: post.author.name
    },
    creator: {
      "@type": "Person",
      name: post.author.name
    },
    inLanguage: runtime.APP_LANG,
    publisher: {
      "@type": "Organization",
      name: "Mindquest",
      logo: {
        "@type": "ImageObject",
        url: `${urls.home()}/mq.webp`
      }
    }
  },
  faq(post)
];

function faq(post) {
  if (post.meta_question)
    return {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      mainEntity: meta_main_entity(post)
    };
}
function meta_main_entity(post) {
  let mainEntity = [];
  let questions = post.meta_question.split("\r\n");
  let response = post.meta_response.split("\r\n");
  for (let index = 0; index < questions.length; index++) {
    let faq_obj = {
      "@type": "Question",
      name: questions[index],
      acceptedAnswer: {
        "@type": "Answer",
        text: response[index]
      }
    };
    mainEntity.push(faq_obj);
  }
  return mainEntity;
}
export default ldJson;
